import {
  QuestionnaireTopicTypes,
  RopaProcessOverview,
  RopaQuestionnaireTopicForBuilder
} from './ropaSlice'
import {
  isFieldAvailable,
  isFieldAvailableAndAssigned,
  isFieldAvailableAndRequired
} from './queries'
import {
  CustomTopicKey,
  DataElementsQuestionnaire,
  DataRetentionQuestionnaire,
  DataSubjectsQuestionnaire,
  FieldProperty,
  ProcessingActivityRoleTypes,
  ProcessorEnum,
  RopaDataFlowDataSource,
  RopaDefineProcessSteps,
  RopaProcessV2,
  RopaQuestion,
  RopaQuestionnaireV2,
  RopaSystemTopics,
  SafeguardsQuestionnaire,
  TransfersQuestionnaire
} from './ropaSliceV2'
import { deepClone, getTicketOrProcessStats } from './collaboratorUtils'
import { EMAIL_REGEX_RFC2822, URL_TICKETS } from '../../constants'
import { randHex } from '../../utils/mathUtils'
import { useIntl } from 'react-intl'
import React from 'react'
import dayjs from 'dayjs'

const topicsMetadata = {
  dataTransferDetails: {
    optional: false,
    _isComplete: true,
    _type: 'dataTransferDetails',
    topicId: 'dataTransferDetails',
    descriptionDeleted: true
  },
  retentionPeriod: {
    optional: false,
    dropdownField: 'retentionPolicy',
    _type: 'retentionPeriod',
    topicId: 'retentionPeriod'
  },
  processDetails: {
    optional: false,
    _isComplete: true,
    _type: 'processDetails',
    topicId: 'processDetails',
    descriptionDeleted: true
  },
  safetyMeasures: {
    optional: false,
    dropdownField: 'safetyMeasures',
    _type: 'safetyMeasures',
    topicId: 'safetyMeasures'
  }
}

const isTopicCompleted = ({ name, topic }) => {
  if (name === QuestionnaireTopicTypes.attributes) {
    return !!topic.attributeIds?.length
  }
  if (name === QuestionnaireTopicTypes.entityTypes) {
    return !!topic.entityIds?.length
  }
  if (name === QuestionnaireTopicTypes.purpose) {
    return !!topic.type
  }
  if (name === QuestionnaireTopicTypes.retentionPeriod) {
    return !!topic.retentionPolicy
  }
  if (name === QuestionnaireTopicTypes.safetyMeasures) {
    return !!topic.safetyMeasures
  }
  if (name === QuestionnaireTopicTypes.dataLink) {
    return !!topic.sourceDataSource.length && !!topic.targetDataSource.length
  }
  return topicsMetadata[name]._isComplete
}

export const mapRopaQuestionnaireTopicsToUI = (questionnaire, _isComplete = false) => {
  const predefinedTopics =
    Object.entries(questionnaire)
      .filter(([_type]) => _type !== 'topics')
      .map((entry: any) => ({
        ...entry[1],
        ...topicsMetadata[entry[0]],
        topicId: entry[1].topicId || topicsMetadata[entry[0]].topicId,
        _isComplete: isTopicCompleted({ name: entry[0], topic: entry[1] })
      })) || []
  const customTopics =
    questionnaire.topics?.map((t) => ({ ...t, _type: 'custom', _isComplete })) || []

  return [...predefinedTopics, ...customTopics]
}

export const mapRopaQuestionnaireTopicsToApi = ({ topics, removeIds = false }) => {
  const cleanUpTopics = (topics) => {
    return topics.map((topic) => {
      const { topicId, ...values } = topic

      if (removeIds) {
        const questions =
          values.questions?.map(
            ({ statement, responseType, responses, isOptional, value = '' }) => {
              return {
                statement,
                responseType,
                responses,
                isOptional,
                value
              } // remove questionId
            }
          ) || []

        return { ...values, questions } // remove topicId and questionId from questions array
      } else {
        return { topicId, ...values }
      }
    })
  }

  const systemTopics = cleanUpTopics(topics.filter((t) => t._type !== 'custom')).map((t) => [
    t._type,
    t
  ])
  const customTopics = cleanUpTopics(topics.filter((t) => t._type === 'custom'))

  const questionnaire = Object.fromEntries(systemTopics)
  questionnaire.topics = customTopics

  return questionnaire
}

export const TranslateTopicName = (
  topic: RopaQuestionnaireTopicForBuilder
): React.ReactElement | string => {
  const { _type, name } = topic

  const intl = useIntl()

  if (_type === QuestionnaireTopicTypes.attributes) {
    return intl.formatMessage({ id: 'ropa.questionnaire.attributeSets' })
  }
  if (_type === QuestionnaireTopicTypes.classificationObjects) {
    return intl.formatMessage({ id: 'ropa.questionnaire.classification' })
  }
  if (_type === QuestionnaireTopicTypes.entityTypes) {
    return intl.formatMessage({ id: 'ropa.questionnaire.entityTypes' })
  }
  if (_type === QuestionnaireTopicTypes.purpose) {
    return intl.formatMessage({ id: 'ropa.questionnaire.purpose' })
  }
  if (_type === QuestionnaireTopicTypes.retentionPeriod) {
    return intl.formatMessage({ id: 'ropa.questionnaire.retention' })
  }
  if (_type === QuestionnaireTopicTypes.dataLink) {
    return intl.formatMessage({ id: 'ropa.questionnaire.dataLink' })
  }
  if (_type === QuestionnaireTopicTypes.safetyMeasures) {
    return intl.formatMessage({ id: 'ropa.questionnaire.safetyMeasures' })
  }
  if (_type === QuestionnaireTopicTypes.dataTransferDetails) {
    return intl.formatMessage({ id: 'ropa.questionnaire.dataTransfer' })
  }
  if (_type === QuestionnaireTopicTypes.custom) {
    return name || intl.formatMessage({ id: 'ropa.questionnaire.topic.custom' })
  }

  return intl.formatMessage({ id: 'ropa.questionnaire.topic.custom' })
}

export function capitalizeFirstLetter(word: string): string {
  // Check if the word is not empty
  if (word.length === 0) {
    return word
  }

  // Capitalize the first letter and concatenate the rest of the word
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}

export const EMPTY_PROCESSOR_DETAILS = {
  name: '',
  email: '',
  address: '',
  contact: '',
  roleType: ProcessingActivityRoleTypes.organization
}

export const getDefaultProcess = (
  mapKeyToTranslation,
  {
    departmentsList,
    purposes,
    legalBasisList,
    subjectCategoriesList,
    subjectSpecialCategoriesList,
    safetyMeasures
  }
) => {
  return {
    processDetails: {
      processingActivityDetails: [
        {
          managerType: ProcessorEnum.Controller,
          details: [EMPTY_PROCESSOR_DETAILS]
        }
      ]
    },
    questionnaire: {
      processDetails: {
        optional: false,
        questions: [],
        processDetailsQuestionProperties: {
          nameProperties: [
            {
              key: 'label',
              value: [mapKeyToTranslation('ropa.defineProcess.name')]
            },
            {
              key: 'optional',
              value: ['false']
            },
            {
              key: 'isDeleted',
              value: ['false']
            }
          ],
          emailProperties: [
            {
              key: 'label',
              value: [mapKeyToTranslation('ropa.defineProcess.email')]
            },
            {
              key: 'optional',
              value: ['false']
            },
            {
              key: 'isDeleted',
              value: ['false']
            }
          ],
          addressProperties: [
            {
              key: 'label',
              value: [mapKeyToTranslation('ropa.defineProcess.address')]
            },
            {
              key: 'optional',
              value: ['true']
            },
            {
              key: 'isDeleted',
              value: ['false']
            }
          ],
          contactProperties: [
            {
              key: 'label',
              value: [mapKeyToTranslation('ropa.defineProcess.contact')]
            },
            {
              key: 'optional',
              value: ['true']
            },
            {
              key: 'isDeleted',
              value: ['false']
            }
          ]
        },
        processDetailsProperties: [
          {
            key: 'name',
            value: [mapKeyToTranslation('ropa.stepMenu.processDetails')]
          },
          {
            key: 'description',
            value: [mapKeyToTranslation('ropa.stepMenu.processDetails.help')]
          },
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.defineProcess.controllers.info.help')]
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'isDeleted',
            value: ['false']
          }
        ],
        nameProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.defineProcess.processName')]
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'isDeleted',
            value: ['false']
          }
        ],
        descriptionProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('processDefinitions.field.description')]
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'isDeleted',
            value: ['false']
          }
        ],
        processGroupsProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('process.definitionType.processGroup')]
          },
          {
            key: 'options',
            value: departmentsList?.map(({ id }) => id) || []
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'isDeleted',
            value: ['false']
          }
        ],
        automatedProcessingDescriptionProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.defineProcess.automatedProcessing.description')]
          },
          {
            key: 'optional',
            value: ['true']
          },
          {
            key: 'isDeleted',
            value: ['false']
          }
        ],
        automatedProcessingProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.defineProcess.automatedProcessing')]
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'isDeleted',
            value: ['false']
          }
        ],
        purposeProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.defineProcess.purpose')]
          },
          {
            key: 'options',
            value: purposes?.map(({ id }) => id) || []
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'isDeleted',
            value: ['false']
          }
        ],
        lawfulBasisProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.defineProcess.lawfulBasis')]
          },
          {
            key: 'options',
            value: legalBasisList?.map(({ id }) => id) || []
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'isDeleted',
            value: ['false']
          }
        ]
      },
      dataElements: {
        name: mapKeyToTranslation('ropa.stepMenu.dataElements'),
        description: mapKeyToTranslation('ropa.stepMenu.dataElements.help'),
        optional: false
      },
      dataRetention: {
        name: mapKeyToTranslation('ropa.stepMenu.dataRetention'),
        description: mapKeyToTranslation('ropa.dataRetention.help'),
        optional: false
      },
      dataSubjects: {
        optional: false,
        name: mapKeyToTranslation('ropa.stepMenu.dataSubjects'),
        description: mapKeyToTranslation('ropa.stepMenu.dataSubjects.help'),
        questions: [],
        dataSubjectProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.questionnaire.field.dataSubjects')]
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'options',
            value: subjectCategoriesList?.map(({ id }) => id) || []
          },
          {
            key: 'isDeleted',
            value: ['false']
          }
        ],
        specialDataSubjectProperties: [
          { key: 'label', value: ['Special Data subjects'] },
          { key: 'optional', value: ['false'] },
          { key: 'options', value: ['Yes', 'No'] },
          {
            key: 'isDeleted',
            value: ['false']
          }
        ],

        specialDataSubjectsProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.questionnaire.field.selectSpecialDataSubjects')]
          },
          { key: 'optional', value: ['false'] },
          {
            key: 'options',
            value: subjectSpecialCategoriesList?.map(({ id }) => id) || []
          },
          {
            key: 'isDeleted',
            value: ['false']
          }
        ],

        legalBasisProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.questionnaire.field.specialLegalBasis')]
          },
          { key: 'optional', value: ['false'] },
          {
            key: 'options',
            value: legalBasisList?.map(({ id }) => id) || []
          },
          {
            key: 'isDeleted',
            value: ['false']
          }
        ]
      },
      safeguards: {
        name: mapKeyToTranslation('ropa.stepMenu.safeguards'),
        description: mapKeyToTranslation('ropa.stepMenu.safeguards.description'),
        optional: false,
        questions: [],
        safeguardProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.stepMenu.safeguards')]
          },
          { key: 'optional', value: ['false'] },
          {
            key: 'options',
            value: safetyMeasures?.map(({ id }) => id) || []
          },
          {
            key: 'isDeleted',
            value: ['false']
          }
        ]
      },
      transfers: {
        name: mapKeyToTranslation('ropa.stepMenu.transfers'),
        description: mapKeyToTranslation('ropa.stepMenu.transfers.description'),
        optional: false,
        questions: [],
        thirdParty: {
          thirdPartyProperties: [
            {
              key: 'label',
              value: [mapKeyToTranslation('ropa.transfers.thirdPartyLabel')]
            },
            { key: 'optional', value: ['false'] },
            {
              key: 'isDeleted',
              value: ['false']
            }
          ],
          details: [
            {
              name: '',
              nameProperties: [
                {
                  key: 'label',
                  value: [mapKeyToTranslation('ropa.defineProcess.name')]
                },
                {
                  key: 'optional',
                  value: ['false']
                },
                {
                  key: 'isDeleted',
                  value: ['false']
                }
              ],
              email: '',
              emailProperties: [
                {
                  key: 'label',
                  value: [mapKeyToTranslation('ropa.defineProcess.email')]
                },
                {
                  key: 'optional',
                  value: ['false']
                },
                {
                  key: 'isDeleted',
                  value: ['false']
                }
              ],
              address: '',
              addressProperties: [
                {
                  key: 'label',
                  value: [mapKeyToTranslation('ropa.questionnaire.address')]
                },
                {
                  key: 'optional',
                  value: ['true']
                },
                {
                  key: 'isDeleted',
                  value: ['false']
                }
              ],
              safeguards: [],
              safeguardsProperties: [
                {
                  key: 'label',
                  value: [mapKeyToTranslation('ropa.questionnaire.safeguards')]
                },
                {
                  key: 'options',
                  value: safetyMeasures?.map(({ id }) => id) || []
                },
                {
                  key: 'optional',
                  value: ['false']
                },
                {
                  key: 'isDeleted',
                  value: ['false']
                }
              ]
            }
          ]
        },
        crossBorder: {
          crossBorderProperties: [
            {
              key: 'label',
              value: [mapKeyToTranslation('ropa.transfers.crossBorderLabel')]
            },
            { key: 'optional', value: ['false'] },
            {
              key: 'isDeleted',
              value: ['false']
            }
          ],
          details: [
            {
              organisation: '',
              organisationProperties: [
                {
                  key: 'label',
                  value: [mapKeyToTranslation('ropa.defineProcess.companyName')]
                },
                {
                  key: 'optional',
                  value: ['false']
                },
                {
                  key: 'isDeleted',
                  value: ['false']
                }
              ],
              email: '',
              emailProperties: [
                {
                  key: 'label',
                  value: [mapKeyToTranslation('ropa.defineProcess.email')]
                },
                {
                  key: 'optional',
                  value: ['false']
                },
                {
                  key: 'isDeleted',
                  value: ['false']
                }
              ],
              country: '',
              countryProperties: [
                {
                  key: 'label',
                  value: [mapKeyToTranslation('ropa.defineProcess.country')]
                },

                {
                  key: 'optional',
                  value: ['false']
                },
                {
                  key: 'isDeleted',
                  value: ['false']
                }
              ],
              safeguards: [],
              safeguardsProperties: [
                {
                  key: 'label',
                  value: [mapKeyToTranslation('ropa.questionnaire.safeguards')]
                },
                {
                  key: 'options',
                  value: safetyMeasures?.map(({ id }) => id) || []
                },
                {
                  key: 'optional',
                  value: ['false']
                },
                {
                  key: 'isDeleted',
                  value: ['false']
                }
              ]
            }
          ]
        }
      }
    }
  }
}

export function uniq(a) {
  const seen = {}
  return a.filter(function (item) {
    return seen?.[item] ? false : (seen[item] = true)
  })
}

const objectIdPattern = /^[0-9a-fA-F]{24}$/
export const isObjectId = (object, key?) =>
  typeof object === 'string' ? objectIdPattern.test(object) : objectIdPattern.test(object?.[key])

export const getProcessStats = (ropaProcess) => {
  const ropaTopics = ropaProcess?.ropaTopics
  let totalQuestions = 0
  let totalQuestionsAnswered = 0

  if (isFieldAvailable(ropaProcess, 'nameProperties')) {
    totalQuestions++
    if ((ropaProcess.name || '')?.trim()) totalQuestionsAnswered++
  }
  if (isFieldAvailable(ropaProcess, 'descriptionProperties')) {
    totalQuestions++
    if ((ropaProcess.description || '')?.trim()) totalQuestionsAnswered++
  }
  if (isFieldAvailable(ropaProcess, 'purposeProperties')) {
    totalQuestions++
    if (ropaProcess.purpose?.count) totalQuestionsAnswered++
  }
  if (isFieldAvailable(ropaProcess, 'processGroupsProperties')) {
    totalQuestions++
    if (ropaProcess.processGroups?.count) totalQuestionsAnswered++
  }
  if (isFieldAvailable(ropaProcess, 'automatedProcessingProperties')) {
    totalQuestions++
    if (ropaProcess.automatedProcessing === true || ropaProcess.automatedProcessing === false)
      totalQuestionsAnswered++

    if (isFieldAvailable(ropaProcess, 'automatedProcessingDescriptionProperties')) {
      totalQuestions++
      if (ropaProcess.automatedProcessingDescription) totalQuestionsAnswered++
    }
  }
  if (isFieldAvailable(ropaProcess, 'lawfulBasisProperties')) {
    totalQuestions++
    if (ropaProcess.lawfulBasis?.count) totalQuestionsAnswered++
  }

  const totalProcessQuestions = ropaProcess?.processQuestions?.count || 0
  if (totalProcessQuestions > 0) {
    totalQuestions += totalProcessQuestions
    ropaProcess?.processQuestions?.edges?.forEach(({ node }) => {
      if (node.questionResponse?.length) totalQuestionsAnswered++
    })
  }

  ropaProcess.processDetails?.processingActivityDetails?.forEach(({ details }) => {
    details.forEach((detail) => {
      if (isFieldAvailable(detail, 'nameProperties')) {
        totalQuestions++
        detail.name && totalQuestionsAnswered++
      }
      if (isFieldAvailable(detail, 'emailProperties')) {
        totalQuestions++
        detail.email && totalQuestionsAnswered++
      }
      if (isFieldAvailable(detail, 'contactProperties')) {
        totalQuestions++
        detail.contact && totalQuestionsAnswered++
      }
      if (isFieldAvailable(detail, 'addressProperties')) {
        totalQuestions++
        detail?.address && totalQuestionsAnswered++
      }
    })
  })

  //data elements
  const dataElements = ropaTopics?.dataElements
  if (dataElements) {
    totalQuestions += 3 //3 hardcoded questions From Data Elements (cannot be deleted)
    if (dataElements?.associatedDatasources?.count) {
      totalQuestionsAnswered++
    }
    if (dataElements?.specialElements?.length) {
      totalQuestionsAnswered++
    }
    if (dataElements?.attributes?.count) {
      totalQuestionsAnswered++
    }
    const dataElementsTotalCustomQuestions = dataElements?.questions?.count || 0
    if (dataElementsTotalCustomQuestions) {
      totalQuestions += dataElementsTotalCustomQuestions
      totalQuestionsAnswered +=
        dataElements?.questions?.edges?.map(({ node }) => (node.questionResponse?.length || 0) > 0)
          .length || 0
    }
  }

  //data Subjects
  const dataSubjects = ropaTopics?.dataSubjects
  if (dataSubjects) {
    if (isFieldAvailable(dataSubjects, 'dataSubjectProperties')) {
      totalQuestions++
      if (dataSubjects?.dataSubjects?.count) totalQuestionsAnswered++
    }
    if (isFieldAvailable(dataSubjects, 'specialDataSubjectsProperties')) {
      //add an additional 1 considering radio button as a question
      totalQuestions += 2
      if (dataSubjects?.specialDataSubjects?.count) totalQuestionsAnswered += 2
    }
    if (isFieldAvailable(dataSubjects, 'legalBasisProperties')) {
      totalQuestions++
      if (dataSubjects?.legalBasis?.count) totalQuestionsAnswered++
    }
    const dataSubjectsTotalCustomQuestions = dataSubjects?.questions?.count || 0
    if (dataSubjectsTotalCustomQuestions > 0) {
      totalQuestions += dataSubjectsTotalCustomQuestions
      totalQuestionsAnswered +=
        dataSubjects?.questions?.edges?.map(({ node }) => (node.questionResponse?.length || 0) > 0)
          .length || 0
    }
  }

  //data retention
  const dataRetention = ropaTopics?.dataRetention
  if (dataRetention) {
    totalQuestions += 1 //1 hardcoded question for retention
    const retentionTotalCustomQuestions = dataRetention?.questions?.count || 0
    if (retentionTotalCustomQuestions) {
      totalQuestions++
      totalQuestionsAnswered +=
        dataRetention?.questions?.edges?.map(({ node }) => (node.questionResponse?.length || 0) > 0)
          .length || 0
    }
  }

  //safeguards
  const safeguards = ropaTopics?.safeguards
  if (safeguards) {
    if (isFieldAvailable(safeguards, 'safeguardProperties')) {
      totalQuestions++
      if (safeguards?.safeguards?.count) totalQuestionsAnswered++
    }
    const safeguardsTotalCustomQuestions = safeguards?.questions?.count || 0
    if (safeguardsTotalCustomQuestions) {
      totalQuestions++
      totalQuestionsAnswered +=
        safeguards?.questions?.edges?.map(({ node }) => (node.questionResponse?.length || 0) > 0)
          .length || 0
    }
  }

  //transfers
  const transfers = ropaTopics?.transfers
  if (transfers) {
    const thirdParty = transfers?.thirdParty
    if (thirdParty?.isThirdParty) {
      totalQuestions++
      totalQuestionsAnswered++
      thirdParty?.details?.map((detail) => {
        const { name, email, address, safeguards } = detail
        if (isFieldAvailable(detail, 'nameProperties')) {
          totalQuestions++
          if (name.trim()) {
            totalQuestionsAnswered++
          }
          if (email.trim()) {
            totalQuestionsAnswered++
          }
          if (address.trim()) {
            totalQuestionsAnswered++
          }
          if (safeguards?.length) {
            totalQuestionsAnswered++
          }
        }
        if (isFieldAvailable(detail, 'emailProperties')) {
          totalQuestions++
        }
        if (isFieldAvailable(detail, 'addressProperties')) {
          totalQuestions++
        }
        if (isFieldAvailable(detail, 'safeguardsProperties')) {
          totalQuestions++
        }
      })
    }

    const crossBorder = transfers?.crossBorder
    if (crossBorder?.isCrossBorder) {
      totalQuestions++
      totalQuestionsAnswered++
      crossBorder?.details?.map((detail) => {
        const { organisation, email, country, safeguards } = detail
        if (isFieldAvailable(detail, 'emailProperties')) {
          totalQuestions++
          if (email.trim()) {
            totalQuestionsAnswered++
          }
          if (country.trim()) {
            totalQuestionsAnswered++
          }
          if (organisation.trim()) {
            totalQuestionsAnswered++
          }
          if (safeguards?.length) {
            totalQuestionsAnswered++
          }
        }
        if (isFieldAvailable(detail, 'organisationProperties')) {
          totalQuestions++
        }
        if (isFieldAvailable(detail, 'countryProperties')) {
          totalQuestions++
        }
        if (isFieldAvailable(detail, 'safeguardsProperties')) {
          totalQuestions++
        }
      })
    }
  }

  //custom topics
  ropaTopics?.customTopics.map((topic) => {
    totalQuestions += topic.questions?.count || 0
    totalQuestionsAnswered +=
      topic.questions?.edges?.filter(({ node }) => (node.questionResponse?.length || 0) > 0)
        .length || 0
  })

  return {
    percentage: (totalQuestionsAnswered / totalQuestions) * 100,
    totalQuestions,
    totalQuestionsAnswered
  }
}

export const getCloneProcessPayload = (process: RopaProcessV2, intl) => {
  const clonedProcess = JSON.parse(
    JSON.stringify({
      ...process,
      id: '',
      owner: ''
    })
  ) as RopaProcessV2
  if (process.name) {
    clonedProcess.name = process.name + ` ${intl.formatMessage({ id: 'ropa.process.text.copy' })}`
  }
  if (process.description) {
    clonedProcess.description =
      process.description + ` ${intl.formatMessage({ id: 'ropa.process.text.copy' })}`
  }
  if (process.questionnaire?.processDetails && clonedProcess.questionnaire?.processDetails) {
    clonedProcess.questionnaire.processDetails = {
      ...clonedProcess.questionnaire.processDetails,
      answeredQuestions: 0,
      collaboratorDetails: []
    }
  }
  if (process.questionnaire?.dataElements && clonedProcess.questionnaire?.dataElements) {
    clonedProcess.questionnaire.dataElements = {
      ...clonedProcess.questionnaire.dataElements,
      answeredQuestions: 0,
      collaboratorDetails: []
    }
  }
  if (process.questionnaire?.dataSubjects && clonedProcess.questionnaire?.dataSubjects) {
    clonedProcess.questionnaire.dataSubjects = {
      ...clonedProcess.questionnaire.dataSubjects,
      answeredQuestions: 0,
      collaboratorDetails: []
    }
  }
  if (process.questionnaire?.dataRetention && clonedProcess.questionnaire?.dataRetention) {
    clonedProcess.questionnaire.dataRetention = {
      ...clonedProcess.questionnaire.dataRetention,
      answeredQuestions: 0,
      collaboratorDetails: []
    }
  }
  if (process.questionnaire?.safeguards && clonedProcess.questionnaire?.safeguards) {
    clonedProcess.questionnaire.safeguards = {
      ...clonedProcess.questionnaire.safeguards,
      answeredQuestions: 0,
      collaboratorDetails: []
    }
  }
  if (process.questionnaire?.transfers && clonedProcess.questionnaire?.transfers) {
    clonedProcess.questionnaire.transfers = {
      ...clonedProcess.questionnaire.transfers,
      crossBorder: {
        ...(clonedProcess.questionnaire.transfers.crossBorder || {})
      },
      thirdParty: {
        ...(clonedProcess.questionnaire.transfers.thirdParty || {})
      },
      answeredQuestions: 0,
      collaboratorDetails: []
    }
  }
  if (process.questionnaire?.customTopics?.length && clonedProcess.questionnaire?.customTopics) {
    clonedProcess.questionnaire.customTopics = clonedProcess.questionnaire.customTopics.map(
      (topic) => {
        topic.questions = topic.questions?.map((q) => ({
          ...q,
          answeredQuestions: 0,
          collaboratorDetails: []
        }))
        return topic
      }
    )
  }
  if (
    process.processDetails?.processingActivityDetails?.length &&
    clonedProcess?.processDetails?.processingActivityDetails?.length
  ) {
    clonedProcess.processDetails.processingActivityDetails = [
      {
        managerType: ProcessorEnum.Controller,
        details: clonedProcess.processDetails?.processingActivityDetails?.[0].details || []
      }
    ]
  }
  if (
    process.questionnaire?.processDetails?.processQuestions?.length &&
    clonedProcess.questionnaire?.processDetails?.processQuestions?.length
  ) {
    clonedProcess.questionnaire.processDetails.processQuestions =
      clonedProcess.questionnaire.processDetails?.processQuestions || []
  }
  return clonedProcess
}

export const getCustomQuestionsAndAnswersCount = (customQuestions?: RopaQuestion[]) => {
  let questions = 0,
    answers = 0
  customQuestions?.forEach((quest) => {
    questions += 1
    if (quest.questionResponse?.length) answers += 1
  })
  return { questions, answers }
}

export const getTotalProcessDetailsQuestions = (
  ropaProcess: RopaProcessV2
): TotalQuestionReturnType => {
  let totalQuestions = 0
  let totalQuestionsAnswered = 0
  if (isFieldAvailableAndRequired(ropaProcess.questionnaire?.processDetails, 'nameProperties')) {
    totalQuestions++
    if ((ropaProcess.name || '')?.trim()) totalQuestionsAnswered++
  }
  if (
    isFieldAvailableAndRequired(ropaProcess.questionnaire?.processDetails, 'descriptionProperties')
  ) {
    totalQuestions++
    if ((ropaProcess.description || '')?.trim()) totalQuestionsAnswered++
  }
  if (isFieldAvailableAndRequired(ropaProcess.questionnaire?.processDetails, 'purposeProperties')) {
    totalQuestions++
    if (ropaProcess.purpose?.length) totalQuestionsAnswered++
  }
  if (
    isFieldAvailableAndRequired(
      ropaProcess.questionnaire?.processDetails,
      'processGroupsProperties'
    )
  ) {
    totalQuestions++
    if (ropaProcess.processGroups?.length) totalQuestionsAnswered++
  }
  if (
    isFieldAvailableAndRequired(
      ropaProcess.questionnaire?.processDetails,
      'automatedProcessingProperties'
    )
  ) {
    totalQuestions++
    if (ropaProcess.automatedProcessing === true || ropaProcess.automatedProcessing === false)
      totalQuestionsAnswered++

    if (
      isFieldAvailable(
        ropaProcess.questionnaire?.processDetails,
        'automatedProcessingDescriptionProperties'
      )
    ) {
      totalQuestions =
        isFieldAvailableAndRequired(
          ropaProcess.questionnaire?.processDetails,
          'automatedProcessingProperties'
        ) && ropaProcess.automatedProcessing === true
          ? totalQuestions + 1
          : ropaProcess.automatedProcessingDescription
          ? totalQuestions + 1
          : totalQuestions
      if (ropaProcess.automatedProcessingDescription) totalQuestionsAnswered++
    }
  }
  if (
    isFieldAvailableAndRequired(ropaProcess.questionnaire?.processDetails, 'lawfulBasisProperties')
  ) {
    totalQuestions++
    if (ropaProcess.lawfulBasis?.length) totalQuestionsAnswered++
  }

  const { questions, answers } = getCustomQuestionsAndAnswersCount(
    ropaProcess.questionnaire?.processDetails?.processQuestions
  )
  totalQuestions += questions
  totalQuestionsAnswered += answers
  if (
    isFieldAvailableAndRequired(
      ropaProcess.questionnaire?.processDetails,
      'processDetailsProperties'
    )
  ) {
    totalQuestions++
    totalQuestionsAnswered++

    ropaProcess.processDetails?.processingActivityDetails?.forEach(({ details }) => {
      details.forEach((detail) => {
        if (
          isFieldAvailableAndRequired(
            ropaProcess?.questionnaire?.processDetails?.processDetailsQuestionProperties,
            'nameProperties'
          )
        ) {
          totalQuestions++
          detail.name && totalQuestionsAnswered++
        }
        if (
          isFieldAvailableAndRequired(
            ropaProcess?.questionnaire?.processDetails?.processDetailsQuestionProperties,
            'emailProperties'
          )
        ) {
          totalQuestions++
          detail.email && totalQuestionsAnswered++
        }
        if (
          isFieldAvailableAndRequired(
            ropaProcess?.questionnaire?.processDetails?.processDetailsQuestionProperties,
            'contactProperties'
          )
        ) {
          totalQuestions++
          detail.contact && totalQuestionsAnswered++
        }
        if (
          isFieldAvailableAndRequired(
            ropaProcess?.questionnaire?.processDetails?.processDetailsQuestionProperties,
            'addressProperties'
          )
        ) {
          totalQuestions++
          detail?.address && totalQuestionsAnswered++
        }
      })
    })
  }

  return { totalQuestions, totalQuestionsAnswered }
}

type TotalQuestionReturnType = { totalQuestions: number; totalQuestionsAnswered: number }
const getTotalDataElementsQuestions = (
  dataElements: DataElementsQuestionnaire,
  isTicket?
): TotalQuestionReturnType => {
  let totalQuestions = 0
  let totalQuestionsAnswered = 0
  const hasDataElementsAttributesQuestion = isTicket
    ? dataElements?.attributeProperties?.find(({ key }) => key == 'isAssigned')?.value?.[0] ===
      'true'
    : true
  if (dataElements) {
    if (hasDataElementsAttributesQuestion) {
      totalQuestions += 1 // attributes
      if (dataElements?.specialElements?.length) {
        totalQuestions += 2 // for lawful basis
        totalQuestionsAnswered++
        if (dataElements?.lawfulBases?.length) {
          totalQuestionsAnswered++
        }
      }
      if (dataElements?.attributes?.length) {
        totalQuestionsAnswered++
      }
    }

    const { questions, answers } = getCustomQuestionsAndAnswersCount(dataElements?.questions)
    totalQuestions += questions
    totalQuestionsAnswered += answers
  }
  return { totalQuestions, totalQuestionsAnswered }
}

const getTotalDataSubjectsQuestions = (
  dataSubjects: DataSubjectsQuestionnaire,
  isTicket?
): TotalQuestionReturnType => {
  let totalQuestions = 0
  let totalQuestionsAnswered = 0
  const existsFn = isTicket ? isFieldAvailableAndAssigned : isFieldAvailable

  if (dataSubjects) {
    if (existsFn(dataSubjects, 'dataSubjectProperties')) {
      totalQuestions++
      if (dataSubjects?.categories?.length) totalQuestionsAnswered++
    }
    const hasSpecialDataSubjectRadioQuestion = existsFn(
      dataSubjects,
      'specialDataSubjectProperties'
    )
    if (hasSpecialDataSubjectRadioQuestion) {
      totalQuestions += 1
      totalQuestionsAnswered += 1 // considering radio button as a question
    }
    const calculateSpecialDataSubjectsQuestions = isTicket
      ? true
      : hasSpecialDataSubjectRadioQuestion
      ? dataSubjects.specialDataSubject
      : false

    if (calculateSpecialDataSubjectsQuestions && existsFn(dataSubjects, 'legalBasisProperties')) {
      const calc = hasSpecialDataSubjectRadioQuestion ? dataSubjects.specialDataSubject : true
      if (calc) {
        totalQuestions++
        if (dataSubjects?.lawfulBasis?.length) totalQuestionsAnswered++
      }
    }
    if (
      calculateSpecialDataSubjectsQuestions &&
      existsFn(dataSubjects, 'specialDataSubjectsProperties')
    ) {
      const calc = hasSpecialDataSubjectRadioQuestion ? dataSubjects.specialDataSubject : true
      if (calc) {
        totalQuestions++
        if (dataSubjects?.specialCategories?.length) totalQuestionsAnswered++
      }
    }
    const { questions, answers } = getCustomQuestionsAndAnswersCount(dataSubjects?.questions)
    totalQuestions += questions
    totalQuestionsAnswered += answers
  }
  return { totalQuestions, totalQuestionsAnswered }
}

const getTotalDataRetentionQuestions = (
  dataRetention: DataRetentionQuestionnaire
): TotalQuestionReturnType => {
  let totalQuestions = 0
  let totalQuestionsAnswered = 0
  if (dataRetention) {
    totalQuestions += 1 //1 hardcoded question for retention
    if (dataRetention.details?.length && dataRetention.details?.length > 0) totalQuestionsAnswered++
    const { questions, answers } = getCustomQuestionsAndAnswersCount(dataRetention?.questions)
    totalQuestions += questions
    totalQuestionsAnswered += answers
  }
  return { totalQuestions, totalQuestionsAnswered }
}

const getTotalSafeguardsQuestions = (
  safeguards: SafeguardsQuestionnaire,
  isTicket?
): TotalQuestionReturnType => {
  let totalQuestions = 0
  let totalQuestionsAnswered = 0
  const existsFn = isTicket ? isFieldAvailableAndAssigned : isFieldAvailable
  if (safeguards) {
    if (existsFn(safeguards, 'safeguardProperties')) {
      totalQuestions++

      if (safeguards?.safeguards?.length) totalQuestionsAnswered++
    }
    const { questions, answers } = getCustomQuestionsAndAnswersCount(safeguards?.questions)
    totalQuestions += questions
    totalQuestionsAnswered += answers
  }
  return { totalQuestions, totalQuestionsAnswered }
}

const getTotalTransfersQuestions = (
  transfers: TransfersQuestionnaire,
  isTicket?
): TotalQuestionReturnType => {
  let totalQuestions = 0
  let totalQuestionsAnswered = 0
  const existsFn = isTicket ? isFieldAvailableAndAssigned : isFieldAvailable
  if (transfers) {
    const thirdParty = transfers?.thirdParty
    if (
      thirdParty?.isThirdParty !== undefined &&
      existsFn(transfers.thirdParty, 'thirdPartyProperties')
    ) {
      totalQuestions++
      totalQuestionsAnswered++
      thirdParty?.isThirdParty &&
        thirdParty?.details?.map((detail) => {
          const { name, email, address, safeguards } = detail

          if (isFieldAvailableAndRequired(detail, 'nameProperties')) {
            totalQuestions++
            if (name?.trim()) {
              totalQuestionsAnswered++
            }
          }

          if (isFieldAvailableAndRequired(detail, 'emailProperties')) {
            totalQuestions++
            if (email?.trim()) {
              totalQuestionsAnswered++
            }
          }
          if (isFieldAvailableAndRequired(detail, 'addressProperties')) {
            totalQuestions++
            if (address?.trim()) {
              totalQuestionsAnswered++
            }
          }
          if (isFieldAvailableAndRequired(detail, 'safeguardsProperties')) {
            totalQuestions++
            if (safeguards?.length) {
              totalQuestionsAnswered++
            }
          }
        })
    }

    const crossBorder = transfers?.crossBorder
    if (
      crossBorder?.isCrossBorder !== undefined &&
      existsFn(transfers.crossBorder, 'crossBorderProperties')
    ) {
      totalQuestions++
      totalQuestionsAnswered++
      crossBorder?.isCrossBorder &&
        crossBorder?.details?.map((detail) => {
          const { organisation, email, country, safeguards } = detail

          if (isFieldAvailableAndRequired(detail, 'emailProperties')) {
            totalQuestions++
            if (email?.trim()) {
              totalQuestionsAnswered++
            }
          }

          if (isFieldAvailableAndRequired(detail, 'organisationProperties')) {
            totalQuestions++

            if (organisation?.trim()) {
              totalQuestionsAnswered++
            }
          }
          if (isFieldAvailableAndRequired(detail, 'countryProperties')) {
            totalQuestions++
            if (country?.trim()) {
              totalQuestionsAnswered++
            }
          }
          if (isFieldAvailableAndRequired(detail, 'safeguardsProperties')) {
            totalQuestions++
            if (safeguards?.length) {
              totalQuestionsAnswered++
            }
          }
        })
    }
    const { questions, answers } = getCustomQuestionsAndAnswersCount(transfers?.questions)
    totalQuestions += questions
    totalQuestionsAnswered += answers
  }
  return { totalQuestions, totalQuestionsAnswered }
}
/**TODO: Hack to be fixed */
const getTotalDataFlowQuestions = (): TotalQuestionReturnType => {
  return { totalQuestions: 1, totalQuestionsAnswered: 1 }
}
export const mapTopicsToQuestionnaire = (process: RopaProcessV2, node) => {
  process.questionnaire = process.questionnaire || {}
  if (node?.ropaTopics?.dataElements) {
    process.questionnaire.dataElements = {
      lawfulBasisProperties: node?.ropaTopics?.dataElements?.lawfulBasisProperties,
      attributeProperties: node?.ropaTopics?.dataElements?.attributeProperties,
      optional: node?.ropaTopics?.dataElements?.optional,
      name: node?.ropaTopics?.dataElements?.name,
      description: node?.ropaTopics?.dataElements?.description,
      questions: node?.ropaTopics?.dataElements?.questions?.edges?.map((edge) => edge?.node) || [],
      attributes:
        node?.ropaTopics?.dataElements?.attributes?.edges?.map(({ node }) => node.id) || [],
      specialElements:
        node?.ropaTopics?.dataElements?.specialElements?.map((specialElement) => ({
          attributeName: specialElement.attributeName,
          sensitivity: specialElement.sensitivity
        })) || [],
      associatedDatasources:
        node?.ropaTopics?.dataElements?.associatedDatasources?.edges?.map(({ node }) => node.id) ||
        [],
      lawfulBases:
        node?.ropaTopics?.dataElements?.lawfulBases?.edges?.map(({ node }) => node.id) || [],
      id: node?.ropaTopics?.dataElements?.id || ''
    }
  }
  if (node?.ropaTopics?.dataSubjects) {
    process.questionnaire.dataSubjects = {
      dataSubjectProperties: node?.ropaTopics?.dataSubjects?.dataSubjectProperties || [],
      specialDataSubjectProperties:
        node?.ropaTopics?.dataSubjects?.specialDataSubjectProperties || [],
      specialDataSubjectsProperties:
        node?.ropaTopics?.dataSubjects?.specialDataSubjectsProperties || [],
      legalBasisProperties: node?.ropaTopics?.dataSubjects?.legalBasisProperties || [],
      optional: node?.ropaTopics?.dataSubjects?.optional,
      name: node?.ropaTopics?.dataSubjects?.name,
      description: node?.ropaTopics?.dataSubjects?.description,
      questions: node?.ropaTopics?.dataSubjects?.questions?.edges?.map((edge) => edge?.node) || [],
      categories:
        node?.ropaTopics?.dataSubjects?.dataSubjects?.edges?.map(({ node }) => node.id) || [],
      specialCategories:
        node?.ropaTopics?.dataSubjects?.specialDataSubjects?.edges?.map(({ node }) => node.id) ||
        [],
      lawfulBasis:
        node?.ropaTopics?.dataSubjects?.legalBases?.edges?.map(({ node }) => node.id) || [],
      specialDataSubject: node?.ropaTopics?.dataSubjects?.specialDataSubject,
      id: node?.ropaTopics?.dataSubjects?.id || ''
    }
  }
  if (node?.ropaTopics?.dataRetention) {
    process.questionnaire.dataRetention = {
      dataRetentionProperties: node?.ropaTopics?.dataRetention?.dataRetentionProperties || [],
      optional: node?.ropaTopics?.dataRetention?.optional,
      name: node?.ropaTopics?.dataRetention?.name,
      description: node?.ropaTopics?.dataRetention?.description,
      questions: node?.ropaTopics?.dataRetention?.questions?.edges?.map((edge) => edge?.node) || [],
      details:
        node?.ropaTopics?.dataRetention?.dataRetentionInfo?.edges?.map(({ node }) => ({
          id: node.id,
          durationType: node.durationType,
          durationCount: node.durationCount,
          triggerEvent: node.triggerEvent,
          attributeSets: node.attributeSet?.edges?.map(({ node }) => node.id) || []
        })) || [],
      id: node?.ropaTopics?.dataRetention?.id || ''
    }
  }
  if (node?.ropaTopics?.safeguards) {
    process.questionnaire.safeguards = {
      safeguardProperties: node?.ropaTopics?.safeguards?.safeguardProperties || [],
      optional: node?.ropaTopics?.safeguards?.optional,
      name: node?.ropaTopics?.safeguards?.name,
      description: node?.ropaTopics?.safeguards?.description,
      questions: node?.ropaTopics?.safeguards?.questions?.edges?.map((edge) => edge?.node) || [],
      safeguards: node?.ropaTopics?.safeguards?.safeguards?.edges?.map(({ node }) => node.id) || [],
      id: node?.ropaTopics?.safeguards?.id || ''
    }
  }
  if (node?.ropaTopics?.transfers) {
    process.questionnaire.transfers = {
      optional: node?.ropaTopics?.transfers?.optional,
      name: node?.ropaTopics?.transfers?.name,
      description: node?.ropaTopics?.transfers?.description,
      questions: node?.ropaTopics?.transfers?.questions?.edges?.map((edge) => edge?.node) || [],
      crossBorder: {
        isCrossBorder: node?.ropaTopics?.transfers?.crossBorder?.isCrossBorder,
        crossBorderProperties:
          node?.ropaTopics?.transfers?.crossBorder?.crossBorderProperties || [],
        details: node?.ropaTopics?.transfers?.crossBorder?.details?.map((detail) => ({
          safeguards: detail.safeguards?.edges?.map(({ node }) => node.id),
          safeguardsProperties: detail.safeguardsProperties || [],
          organisation: detail.organisation,
          organisationProperties: detail.organisationProperties || [],
          email: detail.email,
          emailProperties: detail.emailProperties || [],
          country: detail.country,
          countryProperties: detail.countryProperties || []
        }))
      },
      thirdParty: {
        thirdPartyProperties: node?.ropaTopics?.transfers?.thirdParty?.thirdPartyProperties,
        isThirdParty: node?.ropaTopics?.transfers?.thirdParty?.isThirdParty,
        details: node?.ropaTopics?.transfers?.thirdParty?.details?.map((detail) => ({
          safeguards: detail.safeguards?.edges?.map(({ node }) => node.id),
          safeguardsProperties: detail.safeguardsProperties,
          email: detail.email,
          emailProperties: detail.emailProperties,
          name: detail.name,
          nameProperties: detail.nameProperties,
          address: detail.address,
          addressProperties: detail.addressProperties
        }))
      },
      id: node?.ropaTopics?.transfers?.id || ''
    }
  }
  if (node?.ropaTopics?.dataFlow) {
    process.questionnaire.dataFlow = {
      id: node?.ropaTopics?.dataFlow?.id || '',
      isAssigned: !!node?.ropaTopics?.dataFlow?.isAssigned
    }
  }
  return process
}

export const getFirstTopicOfTicket = (questionnaire?: RopaQuestionnaireV2) => {
  if (questionnaire?.dataElements) {
    return RopaDefineProcessSteps.dataElements
  } else if (questionnaire?.dataSubjects) {
    return RopaDefineProcessSteps.dataSubjects
  } else if (questionnaire?.dataRetention) {
    return RopaDefineProcessSteps.dataRetention
  } else if (questionnaire?.safeguards) {
    return RopaDefineProcessSteps.safeguards
  } else if (questionnaire?.transfers) {
    return RopaDefineProcessSteps.transfers
  } else if (questionnaire?.customTopics?.length) {
    return questionnaire.customTopics[0].id
  }
  return 0
}

export const SYSTEM_TOPICS = [
  'processDetails',
  'dataElements',
  'dataSubjects',
  'dataRetention',
  'safeguards',
  'transfers'
]

export const TotalQuestionsEvaluatorMapper = {
  processDetails: getTotalProcessDetailsQuestions,
  dataElements: getTotalDataElementsQuestions,
  dataSubjects: getTotalDataSubjectsQuestions,
  dataRetention: getTotalDataRetentionQuestions,
  safeguards: getTotalSafeguardsQuestions,
  transfers: getTotalTransfersQuestions,
  dataFlow: getTotalDataFlowQuestions
}

export const getNextStep = (current: string, questionnaire?: RopaQuestionnaireV2) => {
  if (isObjectId(current) && questionnaire?.customTopics?.length) {
    const currIdx = questionnaire?.customTopics.findIndex((topic) => topic.id === current)
    if (currIdx !== -1 && questionnaire?.customTopics.length > currIdx + 1) {
      return questionnaire?.customTopics[currIdx + 1].id
    } else return getFirstTopicOfTicket(questionnaire)
  } else {
    let currIdx = +current // current starts from 1
    while (currIdx <= SYSTEM_TOPICS.length) {
      const nextIdx = currIdx
      const nextTopic = SYSTEM_TOPICS[nextIdx]
      if (questionnaire?.[nextTopic]) {
        return nextIdx + 1 // since active tab starts with 1
      }
      currIdx += 1
    }
    if (questionnaire?.customTopics?.length) {
      return questionnaire.customTopics[0].id
    }
    return 0
  }
}

export const updateQuestionStatsAndReturnProcess = (process) => {
  if (process) {
    const isTicket = window?.location.pathname.includes(URL_TICKETS)
    const deepCloneProcess = JSON.parse(JSON.stringify(process)) as RopaProcessV2
    SYSTEM_TOPICS.map((key) => {
      if (deepCloneProcess.questionnaire?.[key]) {
        const evalFn = TotalQuestionsEvaluatorMapper[key]
        if (evalFn) {
          const { totalQuestions, totalQuestionsAnswered } = evalFn(
            key === 'processDetails' ? deepCloneProcess : deepCloneProcess.questionnaire[key],
            isTicket
          )
          deepCloneProcess.questionnaire[key].totalQuestions = totalQuestions
          deepCloneProcess.questionnaire[key].answeredQuestions = totalQuestionsAnswered
        }
      }
    })
    if (deepCloneProcess.questionnaire?.customTopics?.length) {
      deepCloneProcess.questionnaire.customTopics = deepCloneProcess.questionnaire.customTopics.map(
        (topic) => {
          const { answers, questions } = getCustomQuestionsAndAnswersCount(topic.questions)
          topic.totalQuestions = questions
          topic.answeredQuestions = answers
          return topic
        }
      )
    }
    return deepCloneProcess
  } else return process
}
export const removeDuplicates = (items: any[], key?: string) => {
  if (items?.length) {
    if (typeof items[0] === 'string') return [...new Set(items)]
    if (key) {
      const uniqueItemsByKey = items.reduce((unique, o) => {
        if (!unique.some((obj) => obj[key] === o[key])) {
          unique.push(o)
        }
        return unique
      }, [])
      return uniqueItemsByKey
    }
  }
  return []
}

export const getProcessOverview = (
  processOverview: RopaProcessOverview,
  process?: RopaProcessV2
): RopaProcessOverview => {
  const cloneOverview = deepClone(processOverview) as RopaProcessOverview
  if (process) {
    const { questionnaire } = process
    const { answers, questions } = getTicketOrProcessStats(process, false)
    cloneOverview.completionPercentage = (answers / questions) * 100
    if (questionnaire?.dataElements?.attributes) {
      cloneOverview.totalDataElements = questionnaire?.dataElements?.attributes.length
    }
    if (questionnaire?.dataElements?.specialElements) {
      cloneOverview.totalSpecialDataElements = questionnaire?.dataElements?.specialElements.length
    }
    if (questionnaire?.dataSubjects?.categories) {
      cloneOverview.totalDataSubjects = questionnaire?.dataSubjects?.categories.length
    }
    if (questionnaire?.dataSubjects?.specialCategories) {
      cloneOverview.totalSpecialDataSubjects = questionnaire?.dataSubjects?.specialCategories.length
    }

    cloneOverview.isSpecialDataSubjectsEnabled = questionnaire?.dataSubjects?.specialDataSubject
  }

  return cloneOverview
}

export const validateEmail = (email: string) => EMAIL_REGEX_RFC2822.test(email)
export const generateReportName = (dateNow: string, processName: string) => {
  const dateLocal = dayjs(dateNow).local()
  const date = dateLocal.format('YYYY-MM-DD')
  const time = dateLocal.format('hh:mm A')
  const fileName = `LB ${processName} ${date} at ${time}`

  return fileName
}

export const getFieldProperty = (fieldKey: string, properties: FieldProperty[]) => {
  return properties?.find(({ key }) => key == fieldKey)?.value || []
}

export const getStatusTooltip = (status: string) => {
  const statusValue = status ? status.toLowerCase() : ''
  const cellValue = statusValue.replace('_', ' ') || ''
  const tooltip = (cellValue[0]?.toUpperCase() || '') + cellValue.slice(1)

  return tooltip
}

const ONLY_PROCESS_TOPIC_KEYS = [RopaSystemTopics.processDetails, CustomTopicKey]
export const hasOnlyProcessDetailTopic = (process: RopaProcessV2): boolean => {
  const topicsKeys = Object.keys(process.questionnaire || {})

  const hasOnlyProcessDetail = topicsKeys.every((key) => ONLY_PROCESS_TOPIC_KEYS.includes(key))
  if (hasOnlyProcessDetail && process.questionnaire?.customTopics?.length === 0) {
    return true
  }
  return false
}

export const getRopaDataFlowEmptyDataSource = (): RopaDataFlowDataSource => {
  return {
    dataFlowId: randHex(5),
    id: '',
    name: '',
    isValid: false,
    isUpdated: true
  }
}
