import { gql } from 'graphql-request'

export enum EMAIL_NOTIFICATION_FREQUENCY {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export enum RecipientStrategy {
  ALERT_ASSIGNEE = 'ALERT_ASSIGNEE',
  ADMIN = 'ADMIN',
  DATASOURCE_OWNER = 'DATASOURCE_OWNER'
}

export type EmailNotificationConfiguration = {
  companyName: string
  companyLogoUrl: string
  companyContactEmail: string
  frequency: keyof typeof EMAIL_NOTIFICATION_FREQUENCY
  recipients: {
    strategies: RecipientStrategy[]
    additionalRecipients: string[]
  }
  enabled: boolean
}

export function queryEmailNotificationConfiguration() {
  return gql`
    query nc {
      notificationConfiguration(mode: EMAIL, module: ALERT, scope: GLOBAL) {
        edges {
          node {
            mode
            module
            scope
            configuration {
              ... on AlertEmailGlobalNotificationConfigurationOutput {
                companyName
                companyLogoUrl
                companyContactEmail
                frequency
                recipients {
                  strategies
                  additionalRecipients
                }
                enabled
              }
            }
          }
        }
      }
    }
  `
}

export function mapQueryEmailNotificationConfiguration(raw: any) {
  const edge = raw.notificationConfiguration.edges[0]

  return edge.node.configuration as EmailNotificationConfiguration
}

export function mutationUpdateEmailNotificationConfiguration(
  configuration: EmailNotificationConfiguration
) {
  return gql`
    mutation notificationConfiguration {
      updateNotificationConfiguration(
        clientMutationId: "1"
        input: {
          mode: EMAIL
          scope: GLOBAL
          module: ALERT
          configuration: {
            alertEmailConfiguration: {
              globalConditions: {
                frequency: ${configuration.frequency}
                enabled: ${configuration.enabled}
                recipients: {
                  strategies: [${configuration.recipients.strategies.join(', ')}]
                  additionalRecipients: ${JSON.stringify(
                    configuration.recipients.additionalRecipients
                  )}
                }
                companyName: "${configuration.companyName}"
                companyContactEmail: "${configuration.companyContactEmail}"
                companyLogoUrl: "${configuration.companyLogoUrl}"
              }
            }
          }
        }
      ) {
        clientMutationId
      }
    }
  `
}
