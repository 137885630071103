import './modalDelete.less'
import ModalView from '../../../../components/ModalView/modalView'
import React, { useState } from 'react'
import { Message } from 'semantic-ui-react'
import { Warning } from 'phosphor-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Typography } from '@lightbeamai/design-system'

interface IProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const ModalDeleteDataSource = ({ open, onClose, onConfirm }: IProps) => {
  const intl = useIntl()
  const [textAction, setTextAction] = useState('')

  const handleChangeActionText = (event) => setTextAction(event.target.value)
  const isProceedDisabled = textAction.toLowerCase() !== 'delete'

  const handleClose = () => {
    onClose()
    setTextAction('')
  }
  const handleConfirm = () => {
    onConfirm()
    setTextAction('')
  }

  return (
    <ModalView
      isOpened={open}
      title={intl.formatMessage({ id: 'dataSources.delete.confirm.title' })}
      onClose={handleClose}
      onConfirm={handleConfirm}
      className="modal-delete-ds"
      content={
        <div className="content-delete-ds">
          <Message className="xs-m-0 xs-py-16 xs-px-12">
            <Warning color="#F04647" size={18} weight="bold" className="xs-mr-8" />
            <Typography as="span" variant="base">
              <FormattedMessage id="dataSources.delete.message.help" />
            </Typography>
          </Message>
          <p className="confirm-msg xs-m-16">
            <Typography as="span" variant="base">
              <FormattedMessage id="dataSources.delete.message.text" />
            </Typography>
          </p>
          <div className="text-input xs-mx-16 xs-mt-40">
            <label className="xs-mb-4">
              <Typography as="span" variant="base" className="font-500">
                <FormattedMessage id="dataSources.delete.type" />
              </Typography>
            </label>
            <input value={textAction} onChange={handleChangeActionText} />
          </div>
        </div>
      }
      btnCancel={intl.formatMessage({ id: 'dataSources.delete.btn.cancel' })}
      btnConfirm={intl.formatMessage({ id: 'dataSources.delete.btn.confirm' })}
      disabled={isProceedDisabled}
    />
  )
}

export default ModalDeleteDataSource
