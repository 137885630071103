import './loader.less'
import { Dimmer, Loader } from 'semantic-ui-react'
import { FormattedMessage } from 'react-intl'
import React, { useState, useEffect } from 'react'
import { SemanticSIZES } from 'semantic-ui-react/dist/commonjs/generic'

interface IProps {
  showLoader: boolean
  size?: SemanticSIZES
  once?: boolean
  showText?: boolean
  className?: string
}

const LoaderView = (props: IProps): React.ReactElement => {
  const { showLoader = false, size = 'big', className = '', once = false, showText = true } = props

  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [timerId, setTimerId] = useState(0)

  // TODO: find better approach to handle first load
  useEffect(() => {
    showLoader && setTimerId(window.setTimeout(() => setIsFirstLoad(false), 5000))
  }, [showLoader])

  useEffect(() => () => clearTimeout(timerId), [timerId])

  if (showLoader) {
    return (
      <Dimmer
        active={once ? isFirstLoad : true}
        inverted
        className={`custom-loader ${className}`}
        data-test-id="loader-view"
      >
        <Loader size={size}>{showText && <FormattedMessage id="loader.text.loading" />}</Loader>
      </Dimmer>
    )
  } else {
    return <></>
  }
}

export default LoaderView
