import {
  PrivacyOpsReport,
  PrivacyOpsReportsParams,
  PrivacyOpsReportGroup,
  PrivacyOpsFilterParams
} from './privacyOpsReportsSlice'

import { LIMIT_DEFAULT, PrivacyFeatureType, ProcessSortValues } from '../../constants'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { generateReportName } from '../pia/piaUtil'
import { NameIdSummary } from '../pia/piaSlice'
import { gql } from 'graphql-request'

export const queryPrivacyOpsReports = ({ processId, page, filters }: PrivacyOpsReportsParams) => {
  const cursor = getAfterCursor(page || 1, LIMIT_DEFAULT)
  const processIdParam = processId ? `, ropaProcessId: "${processId}"` : ''

  const filtersParameterized = parameterizeArrayofObjects(filters?.filter || [])
  let filterParams = ''
  if (filtersParameterized) {
    filterParams = `, filter:${filtersParameterized}`
  }

  let sortQuery = `, sortField: ${ProcessSortValues.updatedAt}`
  if (filters?.sortBy) {
    sortQuery = `sortField: ${filters?.sortBy}`
    if (filters?.sortBy === ProcessSortValues.name) {
      sortQuery += ', sortByAsc: true'
    }
  }

  return gql`
  {
    ropa(first: ${LIMIT_DEFAULT}, after: "${cursor}" ${sortQuery} ${processIdParam} ${filterParams}) {
      count
      edges {
        node {
          id
          createdAt
          processDetails{
            name
            owner
            processGroups {
              edges {
                node {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`
}
export const mapQueryPrivacyOpsReports = (
  raw: any
): { list: PrivacyOpsReport[]; total: number } => {
  try {
    const reports =
      raw.ropa?.edges?.map(({ node }) => {
        return {
          id: node.id,
          name: generateReportName(node.createdAt || '', node.processDetails?.name || ''),
          processOwner: node.processDetails?.owner || '',
          processName: node.processDetails?.name || '',
          processGroups:
            node?.processDetails?.processGroups?.edges?.map(({ node }) => node.value || '') || [],
          createdAt: node.createdAt || ''
        }
      }) || []
    return { list: reports, total: raw?.ropa?.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryPrivacyOpsReportGroups = (props: PrivacyOpsReportsParams) => {
  const { processId, filters } = props
  const processIdParam = processId ? `, ropaProcessId: "${processId}"` : ''

  const filtersParameterized = parameterizeArrayofObjects(filters?.filter || [])
  let filterParams = ''
  if (filtersParameterized) {
    filterParams = `, filter:${filtersParameterized}`
  }

  return gql`
    {
      ropaReportGroup(${processIdParam} ${filterParams}) {
        count
        edges {
          node {
            count
            processGroupId
            processGroupName
          }
        }
      }
    }
  `
}
export const mapQueryPrivacyOpsReportGroups = (
  raw: any
): { list: PrivacyOpsReportGroup[]; total: number } => {
  try {
    const list =
      raw.ropaReportGroup?.edges?.map(({ node }) => {
        return {
          processGroupId: node.processGroupId,
          processGroupName: node.processGroupName,
          totalReports: node.count
        }
      }) || []
    return { list, total: raw?.ropaReportGroup?.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

// filters
export const queryPrivacyOpsProcessGroups = () => {
  return gql`
    {
      datasourceDataProperty(fieldType: PROCESS_GROUPS) {
        edges {
          node {
            id
            value
          }
        }
      }
    }
  `
}
export const mapQueryPrivacyOpsProcessGroups = (raw: any): NameIdSummary[] => {
  try {
    return (
      raw.datasourceDataProperty?.edges?.map(({ node }) => ({
        id: node.id || '',
        name: node.value || ''
      })) || []
    )
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryPrivacyOpsProcesses = (props: PrivacyOpsFilterParams): string => {
  const { type } = props
  const nodeName = type === PrivacyFeatureType.pia ? 'process' : 'ropaProcess'

  return gql`
    {
      ${nodeName}(booleanFilter: [{ key: IS_SYSTEM_DEFINED, value: false }]) {
        edges {
          node {
            id
            name
            owner
          }
        }
      }
    }
  `
}
export const mapQueryPrivacyOpsProcesses = (
  raw: any,
  params: PrivacyOpsFilterParams
): { owners: string[]; processes: NameIdSummary[] } => {
  try {
    const nodeName = params.type === PrivacyFeatureType.pia ? 'process' : 'ropaProcess'
    const ownersList = (raw[nodeName]?.edges?.map(({ node }) => node.owner || '') || []) as string[]
    const owners = [...new Set(ownersList)]

    const processes =
      raw[nodeName]?.edges?.map(({ node }) => ({ id: node.id, name: node.name })) || []

    return { owners, processes }
  } catch (error) {
    console.error(error)
    throw error
  }
}
