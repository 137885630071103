import IndexFieldDropDown from './indexFieldDropDown'

import React from 'react'

import { Form } from 'semantic-ui-react'
interface IProps {
  indices?: string[]
  dataSourceId: string
  setUpdatedField: (index: string, timestampField: string) => void
}
const IndexField = (props: IProps): React.ReactElement => {
  const { indices, dataSourceId, setUpdatedField } = props
  if (indices?.length == 0) {
    return <></>
  }
  return (
    <>
      {indices?.map((index, pos) => {
        return (
          <div className="form-item" key={pos} data-test-id="datasources-elasticsearch-index-field">
            <Form.Group inline className="step2-dropdown">
              <Form.Field>{index}</Form.Field>
              <Form.Field>
                <IndexFieldDropDown
                  datasourceId={dataSourceId}
                  index={index}
                  key={pos}
                  setUpdatedField={setUpdatedField}
                />
              </Form.Field>
            </Form.Group>
          </div>
        )
      })}
    </>
  )
}

export default IndexField
