import {
  mapQueryDsrDefaultDatasources,
  mapQueryDsrWFEmailTemplates,
  mutationSaveWorkflow,
  queryDsrDefaultDatasources,
  queryDsrWFEmailTemplates,
  queryDsrWorkflows,
  mapDsrWorkflows,
  queryDsrDetailedWorkflowById,
  mapDsrDetailedWorkflowById,
  mutationUpdateWorkflow,
  mutationDeleteWorkflow
} from './queries'
import graphqlService from '../../../services/graphqlService'
import { DsrEmailTemplateTypes } from '../dsrEmailTemplates/dsrEmailTemplatesSlice'
import { DsrRequestType } from '../../../services/graphqlSchemaTypes'
import { API_DSR_REQUEST_TYPES } from '../../../constants'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export enum EmailTemplatesTypes {
  REQUEST_SUBMITTED = 'requestSubmittedEmailTemplates',
  EMAIL_VERIFICATION_SUCCESSFUL = 'emailVerificationEmailTemplates',
  REQUEST_APPROVED = 'requestApprovalEmailTemplates',
  REPORT_SHARED = 'reportSharingEmailTemplates',
  REPORT_PASSWORD = 'reportPasswordEmailTemplates',
  REQUEST_CLOSED = 'closureEmailTemplates'
}

export enum DataDiscoveryActionTypes {
  AUTO_DISCOVER_AND_VALIDATE = 'AUTO_DISCOVER_AND_VALIDATE',
  AUTO_DISCOVER_AND_ERASE = 'AUTO_DISCOVER_AND_ERASE',
  AUTO_DISCOVER_AND_RECTIFY = 'AUTO_DISCOVER_AND_RECTIFY',
  MANUALLY_DISCOVER_AND_VALIDATE = 'MANUALLY_DISCOVER_AND_VALIDATE',
  DISCOVER_ONLY = 'DISCOVER_ONLY'
}

export enum DataDiscoveryActionTakenBy {
  REQUEST_OWNER = 'DSR_OWNER',
  DATA_SOURCE_OWNER = 'DATA_SOURCE_OWNER'
}

export const requestTypeToActions = {
  [DsrRequestType.Access]: [
    DataDiscoveryActionTypes.AUTO_DISCOVER_AND_VALIDATE,
    DataDiscoveryActionTypes.MANUALLY_DISCOVER_AND_VALIDATE,
    DataDiscoveryActionTypes.DISCOVER_ONLY
  ],
  [DsrRequestType.Erasure]: [
    DataDiscoveryActionTypes.AUTO_DISCOVER_AND_VALIDATE,
    DataDiscoveryActionTypes.AUTO_DISCOVER_AND_ERASE,
    DataDiscoveryActionTypes.DISCOVER_ONLY,
    DataDiscoveryActionTypes.MANUALLY_DISCOVER_AND_VALIDATE
  ],
  [DsrRequestType.Rectification]: [
    DataDiscoveryActionTypes.AUTO_DISCOVER_AND_VALIDATE,
    DataDiscoveryActionTypes.DISCOVER_ONLY,
    DataDiscoveryActionTypes.MANUALLY_DISCOVER_AND_VALIDATE
  ],
  [DsrRequestType.DoNotSell]: [
    DataDiscoveryActionTypes.AUTO_DISCOVER_AND_VALIDATE,
    DataDiscoveryActionTypes.MANUALLY_DISCOVER_AND_VALIDATE,
    DataDiscoveryActionTypes.DISCOVER_ONLY
  ],
  [DsrRequestType.Other]: [
    DataDiscoveryActionTypes.AUTO_DISCOVER_AND_VALIDATE,
    DataDiscoveryActionTypes.MANUALLY_DISCOVER_AND_VALIDATE,
    DataDiscoveryActionTypes.DISCOVER_ONLY
  ]
}

export const ACTION_DSR_FETCH_EMAIL_TEMPLATES = 'dsr/fetch/email-templates'
export const fetchDsrWFEmailTemplates = createAsyncThunk(
  ACTION_DSR_FETCH_EMAIL_TEMPLATES,
  async ({
    eventType,
    requestType
  }: {
    eventType: DsrEmailTemplateTypes
    requestType: API_DSR_REQUEST_TYPES
  }) => {
    const resultRaw = await graphqlService.execute(
      queryDsrWFEmailTemplates({ eventType, requestType })
    )
    return { templates: mapQueryDsrWFEmailTemplates(resultRaw), eventType }
  }
)

export const ACTION_DSR_DEFAULT_DATASOURCES_QUERY = 'dsr/wf/fetch/default-datasources'
export const fetchWFDsrDefaultDatasources = createAsyncThunk(
  ACTION_DSR_DEFAULT_DATASOURCES_QUERY,
  async (requestType: DsrRequestType) => {
    const resultRaw = await graphqlService.execute(queryDsrDefaultDatasources())
    return mapQueryDsrDefaultDatasources(resultRaw, requestType)
  }
)

export const ACTION_SAVE_DSR_WORKFLOW = 'dsr/wf/save'
export const saveDsrWorkflow = createAsyncThunk(
  ACTION_SAVE_DSR_WORKFLOW,
  async (workflowData: Workflow, { rejectWithValue }) => {
    try {
      const resultRaw = await graphqlService.execute(mutationSaveWorkflow(workflowData))
      return resultRaw
    } catch (error: any) {
      return rejectWithValue({ statusMessage: error?.errors[0]?.message || '' })
    }
  }
)

export const ACTION_DELETE_WORKFLOW = 'dsr/wf/delete'
export const deleteDsrWorkflow = createAsyncThunk(
  ACTION_DELETE_WORKFLOW,
  async (workflowId: string) => {
    await graphqlService.execute(mutationDeleteWorkflow(workflowId))
  }
)

export const ACTION_UPDATE_DSR_WORKFLOW = 'dsr/wf/update'
export const updateDsrWorkflow = createAsyncThunk(
  ACTION_UPDATE_DSR_WORKFLOW,
  async (workflowData: Workflow, { rejectWithValue }) => {
    try {
      const resultRaw = await graphqlService.execute(mutationUpdateWorkflow(workflowData))
      return resultRaw
    } catch (error: any) {
      return rejectWithValue({ statusMessage: error?.errors[0]?.message || '' })
    }
  }
)

type EmailIdVerification = {
  sendAcknowledgement: boolean
  requestSubmittedEmailTemplateId?: string
  emailVerificationSuccessEmailTemplateId?: string
  isNextStepAutomated?: boolean
  requestSubmittedEmailTemplateError?: boolean
  emailVerificationSuccessEmailTemplateError?: boolean
}

export type DsrWFDefaultDatasource = {
  id: string
  name: string
  type: string
  owner: string
  actionToBeTaken: ''
  actionToBeTakenBy: ''
}

type stepRequestApproval = {
  approveRequest: boolean
  sendApprovalEmail: boolean
  requestApprovalEmailTemplateId?: string
  requestApprovalEmailTemplateError?: boolean
}

type stepDataDiscoveryValidation = {
  dsrDefaultDatasources: dsrWFDefaultDatasourceType[]
}

type stepReportGeneration = {
  shareReportWithDataSubject: boolean
  shareReportEmailTemplateId?: string
  reportPasswordEmailTemplateId?: string
  automaticallyCloseRequest: boolean
  sendClosureEmail: boolean
  closureEmailTemplateId?: string
  closureEmailTemplateError?: boolean
  reportPasswordEmailTemplateError?: boolean
  reportSharingEmailTemplateError?: boolean
}

export type Workflow = {
  workflowAutomationName?: string
  workflowAutomationNameError?: boolean
  workflowAutomationId?: string
  requestType?: DsrRequestType
  currenUserEmailId: string
  stepEmailIdVerification: EmailIdVerification
  stepRequestApproval: stepRequestApproval
  stepDataDiscoveryValidation: stepDataDiscoveryValidation
  stepReportGeneration: stepReportGeneration
}

export type DetailedWorkflow = Workflow & {
  updatedAt: string
  createdAt: string
  createdBy: string
  associatedFormsCount: number
}

type EmailTemplates = {
  id: string
  name: string
}

export const EmailTemplateStateKeys = {
  [DsrEmailTemplateTypes.requestSubmitted]: 'requestSubmittedEmailTemplates',
  [DsrEmailTemplateTypes.emailVerificationSuccess]: 'emailVerificationEmailTemplates',
  [DsrEmailTemplateTypes.requestApproved]: 'requestApprovalEmailTemplates',
  [DsrEmailTemplateTypes.reportShared]: 'reportSharingEmailTemplates',
  [DsrEmailTemplateTypes.reportPassword]: 'reportPasswordEmailTemplates',
  [DsrEmailTemplateTypes.requestClosed]: 'closureEmailTemplates'
}

export type dsrWFDefaultDatasourceType = {
  id: string
  name: string
  type: string
  owner: string
  actionToBeTaken: DataDiscoveryActionTypes
  actionToBeTakenBy: DataDiscoveryActionTakenBy
  dsrErasureSupported?: boolean
  dsrRecitificationSupported?: boolean
  dsEnabled?: boolean
}

export enum DsrWorkflowSaveStatus {
  SUCCESS = 'SUCCESS',
  UPDATE_SUCCESS = 'UPDATE_SUCCESS',
  ERROR = 'ERROR'
}

type dsrWorkflowAutomationState = {
  activeWorkflow: Workflow
  detailedActiveWorkflow?: DetailedWorkflow
  workflows: {
    list: WorkflowListItem[]
    totalCount: number
  }
  stepData: {
    stepEmailIdVerification: {
      requestSubmittedEmailTemplates: EmailTemplates[]
      emailVerificationEmailTemplates: EmailTemplates[]
    }
    stepRequestApproval: {
      requestApprovalEmailTemplates: EmailTemplates[]
    }
    stepDataDiscoveryValidation: {
      dsrDefaultDatasources: dsrWFDefaultDatasourceType[]
    }
    stepReportGeneration: {
      reportSharingEmailTemplates: EmailTemplates[]
      reportPasswordEmailTemplates: EmailTemplates[]
      closureEmailTemplates: EmailTemplates[]
    }
  }
  saveDsrWorkflowStatus?: DsrWorkflowSaveStatus
  currentCreatedWorkflowId?: string
  isDeleteWorkflowModalOpened?: boolean
  isUpdateWorkflowModalOpened?: boolean
  workflowToDeleteId?: string
  workflowToUpdateId?: string
  requestTypeOfWorkflowToUpdate?: RequestType
}

const activeWorkflowInitialState: Workflow = {
  workflowAutomationName: '',
  workflowAutomationNameError: false,
  currenUserEmailId: '',
  workflowAutomationId: '',
  stepEmailIdVerification: {
    sendAcknowledgement: true,
    requestSubmittedEmailTemplateError: false,
    emailVerificationSuccessEmailTemplateError: false
  },
  stepRequestApproval: {
    approveRequest: false,
    sendApprovalEmail: false,
    requestApprovalEmailTemplateError: false
  },
  stepDataDiscoveryValidation: {
    dsrDefaultDatasources: []
  },
  stepReportGeneration: {
    shareReportWithDataSubject: false,

    automaticallyCloseRequest: false,
    sendClosureEmail: false,
    closureEmailTemplateError: false,
    reportPasswordEmailTemplateError: false,
    reportSharingEmailTemplateError: false
  }
}

const initialState: dsrWorkflowAutomationState = {
  activeWorkflow: activeWorkflowInitialState,
  workflows: {
    list: [],
    totalCount: 0
  },
  stepData: {
    stepEmailIdVerification: {
      requestSubmittedEmailTemplates: [],
      emailVerificationEmailTemplates: []
    },
    stepRequestApproval: {
      requestApprovalEmailTemplates: []
    },
    stepDataDiscoveryValidation: {
      dsrDefaultDatasources: []
    },
    stepReportGeneration: {
      reportSharingEmailTemplates: [],
      reportPasswordEmailTemplates: [],
      closureEmailTemplates: []
    }
  },
  isDeleteWorkflowModalOpened: false,
  isUpdateWorkflowModalOpened: false,
  workflowToDeleteId: '',
  workflowToUpdateId: ''
}

export type RequestType = 'ACCESS' | 'ERASURE' | 'RECTIFICATION' | 'DO_NOT_SELL' | 'OTHER'

export type DataSubjectRequestForm = {
  id: string
  formGroupId: string
  name: string
}

export type WorkflowListItem = {
  id: string
  name: string
  isDeleted: boolean
  createdAt: string
  lastUpdated: string
  createdBy: string
  requestType: RequestType
  dataSubjectRequestForms: {
    count: number
    list: DataSubjectRequestForm[]
  }
}

export const ACTION_FETCH_WORKFLOWS = 'get-workflows'
export const fetchWorkflows = createAsyncThunk(
  ACTION_FETCH_WORKFLOWS,
  async ({ page, pageSize }: { page: number; pageSize: number }) => {
    const resultRaw = await graphqlService.execute(queryDsrWorkflows(page, pageSize))

    return mapDsrWorkflows(resultRaw)
  }
)

export const ACTION_FETCH_DETAILED_ACTIVE_WORKFLOW = 'get-detailed-active-workflow'
export const fetchDetailedActiveWorkflow = createAsyncThunk(
  ACTION_FETCH_DETAILED_ACTIVE_WORKFLOW,
  async (workflowId: string) => {
    const resultRaw = await graphqlService.execute(queryDsrDetailedWorkflowById(workflowId))

    return mapDsrDetailedWorkflowById(resultRaw)
  }
)

const dsrWorkflowAutomationSlice = createSlice({
  name: 'dsrWorkflowAutomation',
  initialState,
  reducers: {
    setActiveWorkflow: (state, { payload }) => {
      state.activeWorkflow = payload
    },
    setActionToBeTaken: (state, { payload }) => {
      // Make a shallow copy of the dsrDefaultDatasources array
      const updatedDatasources = state.activeWorkflow?.stepDataDiscoveryValidation?.dsrDefaultDatasources.map(
        (ds) => {
          if (ds.id === payload.id) {
            let updatedDatasource = { ...ds, actionToBeTaken: payload.actionToBeTaken }

            // Check the condition for actionToBeTaken and set actionToBeTakenBy if it matches
            if (
              payload.actionToBeTaken === DataDiscoveryActionTypes.MANUALLY_DISCOVER_AND_VALIDATE ||
              payload.actionToBeTaken === DataDiscoveryActionTypes.DISCOVER_ONLY
            ) {
              updatedDatasource = {
                ...updatedDatasource,
                actionToBeTakenBy: DataDiscoveryActionTakenBy.DATA_SOURCE_OWNER
              }
            }

            return updatedDatasource
          }
          // Otherwise, return the original object
          return ds
        }
      )

      // Update the state immutably
      if (updatedDatasources) {
        state.activeWorkflow.stepDataDiscoveryValidation.dsrDefaultDatasources = updatedDatasources
      }
    },
    setActionToBeTakenBy: (state, { payload }) => {
      const updatedDatasources = state.activeWorkflow?.stepDataDiscoveryValidation?.dsrDefaultDatasources.map(
        (ds) => {
          if (ds.id === payload.id) {
            const updatedDatasource = { ...ds, actionToBeTakenBy: payload.actionToBeTakenBy }
            return updatedDatasource
          }
          // Otherwise, return the original object
          return ds
        }
      )

      // Update the state immutably
      if (updatedDatasources) {
        state.activeWorkflow.stepDataDiscoveryValidation.dsrDefaultDatasources = updatedDatasources
      }
    },
    setWorkflowAutomationName: (state, { payload }) => {
      state.activeWorkflow.workflowAutomationName = payload
    },
    setRequestSubmittedEmailTemplateId: (state, { payload }) => {
      state.activeWorkflow.stepEmailIdVerification.requestSubmittedEmailTemplateId = payload
    },
    setEmailVerificationSuccessEmailTemplateId: (state, { payload }) => {
      state.activeWorkflow.stepEmailIdVerification.emailVerificationSuccessEmailTemplateId = payload
    },
    setEmailIdVerificationAcknowledgement: (state, { payload }) => {
      state.activeWorkflow.stepEmailIdVerification.sendAcknowledgement = payload
    },
    setRequestApprovalEmailTemplateId: (state, { payload }) => {
      state.activeWorkflow.stepRequestApproval.requestApprovalEmailTemplateId = payload
    },
    setApproveRequest: (state, { payload }) => {
      state.activeWorkflow.stepRequestApproval.approveRequest = payload
    },
    setSendApprovalEmail: (state, { payload }) => {
      state.activeWorkflow.stepRequestApproval.sendApprovalEmail = payload
    },
    setShareReportWithDataSubject: (state, { payload }) => {
      state.activeWorkflow.stepReportGeneration.shareReportWithDataSubject = payload
    },
    setShareReportEmailTemplateId: (state, { payload }) => {
      state.activeWorkflow.stepReportGeneration.shareReportEmailTemplateId = payload
    },
    setReportPasswordEmailTemplateId: (state, { payload }) => {
      state.activeWorkflow.stepReportGeneration.reportPasswordEmailTemplateId = payload
    },
    setAutomaticallyCloseRequest: (state, { payload }) => {
      state.activeWorkflow.stepReportGeneration.automaticallyCloseRequest = payload
    },
    setSendClosureEmail: (state, { payload }) => {
      state.activeWorkflow.stepReportGeneration.sendClosureEmail = payload
    },
    setClosureEmailTemplateId: (state, { payload }) => {
      state.activeWorkflow.stepReportGeneration.closureEmailTemplateId = payload
    },
    setActiveWorkflowDefaultData: (state, { payload }) => {
      state.activeWorkflow.requestType = payload.requestType
      state.activeWorkflow.currenUserEmailId = payload.currenUserEmailId
    },
    resetActiveDsrWorkflow: (state) => {
      state.activeWorkflow = activeWorkflowInitialState
      state.saveDsrWorkflowStatus = initialState.saveDsrWorkflowStatus
    },
    setRequestSubmittedEmailTemplateError: (state, { payload }) => {
      state.activeWorkflow.stepEmailIdVerification.requestSubmittedEmailTemplateError = payload
    },
    setEmailVerificationSuccessEmailTemplateError: (state, { payload }) => {
      state.activeWorkflow.stepEmailIdVerification.emailVerificationSuccessEmailTemplateError = payload
    },
    setRequestApprovalEmailTemplateError: (state, { payload }) => {
      state.activeWorkflow.stepRequestApproval.requestApprovalEmailTemplateError = payload
    },
    setClosureEmailTemplateError: (state, { payload }) => {
      state.activeWorkflow.stepReportGeneration.closureEmailTemplateError = payload
    },
    setReportPasswordEmailTemplateError: (state, { payload }) => {
      state.activeWorkflow.stepReportGeneration.reportPasswordEmailTemplateError = payload
    },
    setReportSharingEmailTemplateError: (state, { payload }) => {
      state.activeWorkflow.stepReportGeneration.reportSharingEmailTemplateError = payload
    },
    setWorkflowToDeleteId: (state, { payload }) => {
      state.isDeleteWorkflowModalOpened = payload.isDeleteWorkflowModalOpened
      state.workflowToDeleteId = payload.id
    },
    setWorkflowToUpdateId: (state, { payload }) => {
      state.isUpdateWorkflowModalOpened = payload.isUpdateWorkflowModalOpened
      state.workflowToUpdateId = payload.id
      state.requestTypeOfWorkflowToUpdate = payload.requestTypeOfWorkflowToUpdate
    },
    setWorkflowAutomationNameError: (state, { payload }) => {
      state.activeWorkflow.workflowAutomationNameError = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDsrWFEmailTemplates.fulfilled, (state, action) => {
      const { templates, eventType } = action.payload
      const stateKey = EmailTemplateStateKeys[eventType]

      if (stateKey) {
        const stepKey = Object.keys(state.stepData).find((step) =>
          Object.keys(state.stepData[step]).includes(stateKey)
        )

        if (stepKey) {
          state.stepData[stepKey][stateKey] = templates
        }
      }
    })
    builder.addCase(fetchWFDsrDefaultDatasources.fulfilled, (state, { payload }) => {
      state.activeWorkflow.stepDataDiscoveryValidation.dsrDefaultDatasources = payload
    })
    builder.addCase(saveDsrWorkflow.fulfilled, (state, { payload }) => {
      if (payload?.createDsrWorkflowConfig?.dsrWorkflowConfigId) {
        state.saveDsrWorkflowStatus = DsrWorkflowSaveStatus.SUCCESS
        state.currentCreatedWorkflowId = payload?.createDsrWorkflowConfig?.dsrWorkflowConfigId
      } else if (payload?.updateDsrWorkflowConfig?.dsrWorkflowConfigId) {
        state.saveDsrWorkflowStatus = DsrWorkflowSaveStatus.UPDATE_SUCCESS
      } else {
        state.saveDsrWorkflowStatus = DsrWorkflowSaveStatus.ERROR
      }
    })
    builder.addCase(updateDsrWorkflow.fulfilled, (state, { payload }) => {
      if (payload?.updateDsrWorkflowConfig?.dsrWorkflowConfigId) {
        state.currentCreatedWorkflowId = payload?.updateDsrWorkflowConfig?.dsrWorkflowConfigId
        state.saveDsrWorkflowStatus = DsrWorkflowSaveStatus.UPDATE_SUCCESS
      } else {
        state.saveDsrWorkflowStatus = DsrWorkflowSaveStatus.ERROR
      }
    })
    builder.addCase(fetchWorkflows.fulfilled, (state, { payload }) => {
      state.workflows = {
        list: payload.list,
        totalCount: payload.totalCount
      }
    })
    builder.addCase(fetchDetailedActiveWorkflow.fulfilled, (state, { payload }) => {
      if (payload) {
        state.activeWorkflow = payload
        state.detailedActiveWorkflow = payload
      }
    })
  }
})

export const {
  setActiveWorkflow,
  setActionToBeTaken,
  setActionToBeTakenBy,
  setWorkflowAutomationName,
  setRequestSubmittedEmailTemplateId,
  setEmailVerificationSuccessEmailTemplateId,
  setEmailIdVerificationAcknowledgement,
  setRequestApprovalEmailTemplateId,
  setApproveRequest,
  setSendApprovalEmail,
  setShareReportWithDataSubject,
  setShareReportEmailTemplateId,
  setReportPasswordEmailTemplateId,
  setAutomaticallyCloseRequest,
  setSendClosureEmail,
  setClosureEmailTemplateId,
  setActiveWorkflowDefaultData,
  resetActiveDsrWorkflow,
  setRequestSubmittedEmailTemplateError,
  setEmailVerificationSuccessEmailTemplateError,
  setRequestApprovalEmailTemplateError,
  setClosureEmailTemplateError,
  setReportPasswordEmailTemplateError,
  setReportSharingEmailTemplateError,
  setWorkflowToDeleteId,
  setWorkflowToUpdateId,
  setWorkflowAutomationNameError
} = dsrWorkflowAutomationSlice.actions

export default dsrWorkflowAutomationSlice.reducer
