import { DefaultAssigneeFormValues, DsrSettingsDefaultDatasource } from './dsrSettingsSlice'
import { API_DSR_REQUEST_TYPES } from '../../../constants'
import { gql } from 'graphql-request'

export const queryDsrSettings = (): string => {
  return gql`
    {
      dsrSetting {
        edges {
          node {
            defaultFormText
            notificationEmails
            autoAssignConfig {
              requestType
              assigneeEmail
            }
          }
        }
      }
    }
  `
}

export const mapQueryDsrSettings = (
  raw: any
): {
  placeholderText: string
  notificationEmails: string[]
  defaultAssignee: DefaultAssigneeFormValues
} => {
  try {
    const settings = raw.dsrSetting?.edges[0]?.node
    const defaultAssignee = settings?.autoAssignConfig?.reduce(
      (
        acc: { [key: string]: string },
        { requestType, assigneeEmail }: { requestType: string; assigneeEmail: string }
      ) => {
        acc[requestType] = assigneeEmail
        return acc
      },
      {
        [API_DSR_REQUEST_TYPES.ACCESS]: '',
        [API_DSR_REQUEST_TYPES.ERASURE]: '',
        [API_DSR_REQUEST_TYPES.RECTIFICATION]: '',
        [API_DSR_REQUEST_TYPES.DO_NOT_SELL]: '',
        [API_DSR_REQUEST_TYPES.OTHER]: ''
      }
    )
    return {
      placeholderText: settings?.defaultFormText || '',
      notificationEmails: settings.notificationEmails || [],
      defaultAssignee
    }
  } catch (e) {
    console.error(e)
    return { placeholderText: '', notificationEmails: [], defaultAssignee: {} }
  }
}

export const mutationSaveDsrFormPlaceholder = (text: string) => {
  return gql`
    mutation {
        addDsrEmptyFormMessage(
            clientMutationId: "addDsrEmptyFormMessage",
            defaultFormText: "${text}"
        ) {
          clientMutationId
        }
    }
    `
}

export const mutationSaveDsrNotificationEmails = (notificationEmails: string[]) => {
  return gql`
    mutation {
      dsrNotificationSettings(
        clientMutationId: "test"
        notificationEmails: ${JSON.stringify(notificationEmails)}
      ) {
        clientMutationId
      }
    }
  `
}

export const mutationSaveDsrDefaultAssignee = (assignee) => {
  return gql`
        mutation{
      updateDsrAutoAssignConfiguration(
        clientMutationId:"defaultAssignee",
        configInput:[{requestType:${API_DSR_REQUEST_TYPES.ACCESS},assigneeEmail:"${
    assignee[API_DSR_REQUEST_TYPES.ACCESS]
  }"},{requestType:${API_DSR_REQUEST_TYPES.ERASURE},assigneeEmail:"${
    assignee[API_DSR_REQUEST_TYPES.ERASURE]
  }"},{requestType:${API_DSR_REQUEST_TYPES.RECTIFICATION},assigneeEmail:"${
    assignee[API_DSR_REQUEST_TYPES.RECTIFICATION]
  }"},{requestType:${API_DSR_REQUEST_TYPES.DO_NOT_SELL},assigneeEmail:"${
    assignee[API_DSR_REQUEST_TYPES.DO_NOT_SELL]
  }"},{requestType:${API_DSR_REQUEST_TYPES.OTHER},assigneeEmail:"${
    assignee[API_DSR_REQUEST_TYPES.OTHER]
  }"}]
      ){
        clientMutationId
      }
    }
  `
}

export const queryGetDsrDefaultAssignee = (): string => {
  return gql`

  dsrSetting{
    edges{
      node{
        autoAssignConfig{
          requestType
          assigneeEmail
        }

      }
    }
  }
`
}

export const queryGetDsrDefaultDatasources = (): string => {
  return gql`
    {
      dsrSetting {
        edges {
          node {
            defaultDsrDatasource {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
      datasources {
        edges {
          node {
            id
            name
            type
            createdBy
          }
        }
      }
    }
  `
}

export const mapQueryDsrDefaultDatasources = (
  raw: any
): {
  defaultDatasources: DsrSettingsDefaultDatasource[]
  includedDatasourcesInDsrFlow: string[]
} => {
  try {
    const settings = raw.dsrSetting?.edges[0]?.node
    const defaultDatasourceIds =
      settings?.defaultDsrDatasource.edges.map(({ node }) => String(node.id)) || []

    const datasources = raw.datasources?.edges || []
    const defaultDatasources = datasources
      .map(({ node }) => {
        const datasource = node
        return {
          id: datasource.id,
          name: datasource.name,
          type: datasource.type,
          owner: datasource.createdBy
        }
      })
      .filter(({ type }) => type !== 'GENERIC')

    return {
      defaultDatasources,
      includedDatasourcesInDsrFlow: defaultDatasourceIds
    }
  } catch (e) {
    console.error(e)
    return { defaultDatasources: [], includedDatasourcesInDsrFlow: [] }
  }
}

export const mutationSaveDsrDefaultDatasources = (updatedDefaultDatasources: string[]) => {
  return gql`
    mutation{
      updateDsrDefaultDatasourceConfiguration(
        clientMutationId:"muatateDefaultDatasource",
        datasourceIds:[${updatedDefaultDatasources.map((id) => `"${id}"`)}]
      ){
        clientMutationId
      }
    }
  `
}
