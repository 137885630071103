import { mapQueryObjectTypeAndPolicies, queryObjectTypeAndPolicies } from './queries'
import graphqlService from '../../services/graphqlService'
import { ATTRIBUTE_IDS, CATEGORY } from '../../constants'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface ObjectTypeAndPoliciesParams {
  datasourceId: string
  filters: {
    [ATTRIBUTE_IDS]?: string
    [CATEGORY]?: string
  }
}

export const ACTION_DATABASES_AND_OBJECT_TYPE = 'dashboard/databasesAndObjectTypeInfo'
export const fetchObjectTypeAndPolicies = createAsyncThunk(
  ACTION_DATABASES_AND_OBJECT_TYPE,
  async (props: ObjectTypeAndPoliciesParams) => {
    const resultRaw = await graphqlService.execute(queryObjectTypeAndPolicies(props))
    return mapQueryObjectTypeAndPolicies(resultRaw)
  }
)

export enum DataSourceParentObjectTypes {
  customer = 'CUSTOMER',
  organization = 'ORGANIZATION',
  incidents = 'INCIDENT'
}

type ObjectTypeAndPoliciesState = {
  policiesCount?: number
  parentObjectCount?: number
  parentObjectType?: DataSourceParentObjectTypes
}

export const initialState: ObjectTypeAndPoliciesState = {}

const objectTypeAndPoliciesSlice = createSlice({
  name: 'objectTypeAndPolicies',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(fetchObjectTypeAndPolicies.fulfilled, (state, { payload }) => {
      state.policiesCount = payload.policiesCount
      state.parentObjectType = payload.parentObjectType
      state.parentObjectCount = payload.parentObjectCount
    })
  }
})

export const { reset } = objectTypeAndPoliciesSlice.actions

export default objectTypeAndPoliciesSlice.reducer
