import { isAirByteDatasource } from './datasourcesList'
import PrintPageHeader from '../../components/header'
import { HistoricalStats, fetchDeltaFilesPerDatasource, stats } from '../../printSlice'
import TrendPage from '../components/trendPageTemplate'
import { RootState } from '../../../rootReducer'
import { DATA_SOURCE_TYPES } from '../../../constants'
import { filterCurrentDayData } from '../../../utils/timeUtil'
import {
  getDeltaReportAggregation,
  getDeltaReportAggregationTableData,
  percentageDeltaCalculator
} from '../deltaReportUtil'
import dayjs from 'dayjs'
import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
interface IProps {
  historicalStats?: HistoricalStats
  fetchDeltaFilesPerDatasource: (datasourceId: string) => void
  filesperDs?: {
    [datasourceId: string]: stats[]
  }
  subText?: string
  datasourceId?: string
  datasourceName?: string
  datasourceType?: DATA_SOURCE_TYPES
}
const SensitiveFiles = ({
  historicalStats,
  fetchDeltaFilesPerDatasource,
  filesperDs,
  subText = '',
  datasourceId = '',
  datasourceName,
  datasourceType
}: IProps): React.ReactElement => {
  useEffect(() => {
    datasourceId && fetchDeltaFilesPerDatasource(datasourceId)
  }, [datasourceId, fetchDeltaFilesPerDatasource])
  const showDeleted = datasourceType ? !isAirByteDatasource(datasourceType) : true
  const historicalFileStats = useMemo(() => {
    return (datasourceId ? filesperDs?.[datasourceId] : historicalStats?.fileStats) || []
  }, [filesperDs, historicalStats?.fileStats, datasourceId])

  const agreegatedFileStats = getDeltaReportAggregation(historicalFileStats)
  const aggreegatedPercentageChange = percentageDeltaCalculator(
    agreegatedFileStats.startTotal,
    agreegatedFileStats.total
  )
  const fileStats = filterCurrentDayData(historicalFileStats)
  const chartData = fileStats.map((stats) => {
    return {
      name:
        dayjs(stats.startTimestamp).format('D MMM') +
        ' - ' +
        dayjs(stats.endTimestamp).format('D MMM'),
      value: stats.total || 0
    }
  })
  const barData = fileStats.map((stats) => {
    return {
      name:
        dayjs(stats.startTimestamp).format('D MMM') +
        ' - ' +
        dayjs(stats.endTimestamp).format('D MMM'),
      added: stats.added,
      deleted: stats.deleted
    }
  })

  const tableData = getDeltaReportAggregationTableData(fileStats, showDeleted)

  return (
    <div className="sensitive-files delta-print-page" id={'sensitive-files' + datasourceId}>
      <PrintPageHeader
        text="Total Files"
        {...(subText
          ? { subText }
          : datasourceId
          ? {
              subText: (
                <>
                  Datasource: <strong>{datasourceName}</strong>
                </>
              )
            }
          : {})}
        {...(subText || datasourceId ? { className: 'align-horizontal' } : {})}
      />
      <TrendPage
        chartData={chartData || []}
        tableData={tableData}
        barData={barData || []}
        trendWidgetData={[
          {
            count: agreegatedFileStats.total,
            text: 'Current files',
            percentage: aggreegatedPercentageChange
          },
          { count: agreegatedFileStats.added, text: 'Files Added', bulletColor: '#13C2C2' },
          {
            count: showDeleted ? agreegatedFileStats.deleted : null,
            text: 'Files Deleted',
            bulletColor: '#CF1322'
          }
        ]}
        tableHeaders={['Date Range', 'Start of range', 'Added', 'Deleted', 'End of range']}
        legendText="Total Files"
        areaTitleText="Trend for total files"
        barTitleText="Delta changes for PII files"
        barLegendPrefix="Files"
      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  filesperDs: state.print.deltaFilesPerDatasource
})

const mapDispatchToProps = {
  fetchDeltaFilesPerDatasource
}

export default connect(mapStateToProps, mapDispatchToProps)(SensitiveFiles)
