type StorageItem = {
  value: any
  isPresistent: boolean
}

const presistentKeys: Record<string, boolean> = {}

// Save an item to localStorage with optional protection
const setItem = (key: string, value: any, shouldBePresistent = false): void => {
  const isPresistent = presistentKeys[key] || shouldBePresistent
  const item: StorageItem = {
    value,
    isPresistent
  }
  localStorage.setItem(key, JSON.stringify(item))

  if (isPresistent) {
    presistentKeys[key] = true
  }
}

// Retrieve an item from localStorage
const getItem = (key: string): any | null => {
  const itemString = localStorage.getItem(key)
  if (itemString) {
    try {
      const item: StorageItem = JSON.parse(itemString)
      return item.value
    } catch {
      return itemString
    }
  }
  return null
}

// Remove an item from localStorage
const removeItem = (key: string): void => {
  if (!presistentKeys[key]) {
    localStorage.removeItem(key)
  }
}

// Clear localStorage, but preserve presistent items
const clear = (): void => {
  const keysToPreserve: { key: string; value: StorageItem }[] = []

  // First, store presistent keys' values temporarily
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (key) {
      const itemString = localStorage.getItem(key)
      if (itemString) {
        try {
          const item: StorageItem = JSON.parse(itemString)
          if (item?.isPresistent) {
            keysToPreserve.push({ key, value: item })
          }
        } catch {
          continue
        }
      }
    }
  }

  // Clear everything from localStorage
  localStorage.clear()

  // Re-insert presistent items
  keysToPreserve.forEach(({ key, value }) => {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value))
    }
  })
}

// Check if an item is presistent
const isPresistent = (key: string): boolean => {
  return presistentKeys[key] || false
}

// Localstorage keys with optional protection
export const LocalStorageKeys = {
  AccessToken: {
    key: 'accessToken',
    isPresistent: false
  },
  IsDocClassificationEnabled: {
    key: 'isDocClassificationEnabled',
    isPresistent: true
  },
  ClassificationFilter: {
    key: 'classificationFilter',
    isPresistent: true
  }
}

// Initialize protection statuses from the constant
const initializePresistentKeys = (): void => {
  Object.values(LocalStorageKeys).forEach(({ key, isPresistent }) => {
    if (isPresistent) {
      presistentKeys[key] = true
    }
  })
}

// Initialize protection statuses on load
initializePresistentKeys()

// Exported functions to use the service
export const localStorageService = {
  setItem,
  getItem,
  removeItem,
  clear,
  isPresistent
}
