export type DsrWorldRegion = {
  name: string
}

export const dsrListWorldRegions: DsrWorldRegion[] = [
  { name: 'Africa' },
  { name: 'Asia' },
  { name: 'Europe' },
  { name: 'North America' },
  { name: 'Oceania' },
  { name: 'South America' }
]
