import { createSlice } from '@reduxjs/toolkit'

export interface IGlobalState {
  pageName?: string
  pageSize?: number
}

export const initialState: IGlobalState = {
  pageName: ''
}

const contextSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setActivePage: (state, action) => {
      state.pageName = action.payload
    },
    resetActivePage: () => initialState
  }
})

export const { setActivePage, resetActivePage } = contextSlice.actions

export default contextSlice.reducer
