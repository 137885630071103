import {
  NoScanListParams,
  DeleteFromNoScanListParams,
  NoScanItem,
  OutlookNoScanItem
} from './noScanSlice'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { LIMIT_DEFAULT } from '../../constants'
import { gql } from 'graphql-request'

export const queryNoScanList = (params: NoScanListParams): string => {
  const { page, pageSize = LIMIT_DEFAULT, ...listFilters } = params
  const cursor = getAfterCursor(page, pageSize)
  const filterString = listFilters.filter ? parameterizeArrayofObjects(listFilters.filter) : ''
  const paramString = filterString ? `filter: ${filterString}` : ''

  return gql`
      {
        noScanList(first: ${pageSize}, after: "${cursor}", ${paramString}) {
          count,
          edges {
            node {
              id
              name
              owner
              objectLink
              groupName
              updateTimestamp
              ticketStoreProject{
                edges{
                  node{
                    projectName
                    lead {
                      accountId
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
}

export const mapQueryNoScanList = (raw: any): { list: NoScanItem[]; total: number } => {
  try {
    const list = raw.noScanList.edges?.map(({ node: item }) => ({
      id: item.id || '',
      name: item.name || '',
      owner: item.owner || '',
      objectLink: item.objectLink || '',
      groupName: item.groupName || '',
      updateTimestamp: item.updateTimestamp || '',
      projectName: item.ticketStoreProject?.edges[0]?.node?.projectName || '',
      addedBy: item.ticketStoreProject?.edges[0]?.node?.lead.name || ''
    }))

    return { list, total: raw.noScanList.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryRemoveFromNoScanList = (params: DeleteFromNoScanListParams): any => {
  const query = gql`
    mutation {
      removeFromNoScanList(
        actionInput: {
          clientMutationId: "removeFromNoScanList",
          ids: ${JSON.stringify(params.ids)},
          datasourceId: ${JSON.stringify(params.datasourceId)}
        }
      ) {
        clientMutationId
        status
      }
    }
  `

  return query
}

export const queryOutlookNoScanList = (params: NoScanListParams): string => {
  const { page, ...listFilters } = params
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  const filterString = listFilters.filter ? parameterizeArrayofObjects(listFilters.filter) : ''
  const paramString = filterString ? `filter: ${filterString}` : ''

  return gql`
    {
      noScanList(first: ${LIMIT_DEFAULT}, after: "${cursor}", ${paramString}) {
        count,
        edges{
          node{
            id
            mailProjection{
              sender
              recipient
              subject
              folder
              addedOn
            }
          }
        }
      }
    }
  `
}

export const mapQueryOutlookNoScanList = (
  raw: any
): { list: OutlookNoScanItem[]; total: number } => {
  try {
    const list = raw.noScanList.edges?.map(({ node: item }) => ({
      id: item.id || '',
      sender: item.mailProjection?.sender || '',
      recipient: item.mailProjection?.recipient || '',
      subject: item.mailProjection?.subject || '',
      folder: item.mailProjection?.folder || '',
      addedOn: item.mailProjection?.addedOn || ''
    }))

    return { list, total: raw.noScanList.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}
