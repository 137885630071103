import React, { SyntheticEvent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '@lightbeamai/design-system'
import { CaretLeft as IconCaretLeft } from 'phosphor-react'

interface IProps {
  showBackButton?: boolean
  isBackDisabled?: boolean
  isNextDisabled?: boolean
  isCancelDisabled?: boolean
  submitText?: string
  backText?: string
  showNextButton?: boolean
  cancelText?: string
  centerButtons?: boolean
  renderBtnSubmit?: () => void
  onGoBack: () => void
  onGoForward?: (event?: SyntheticEvent) => void
  onCancel?: () => void
  submitTooltip?: React.ReactElement
}

const ControlPane = (props: IProps): React.ReactElement => {
  const {
    showBackButton = true,
    showNextButton = true,
    isBackDisabled = false,
    isNextDisabled = false,
    isCancelDisabled = false,
    submitText = '',
    backText = '',
    cancelText = '',
    centerButtons = false,
    onGoBack,
    onGoForward,
    onCancel,
    renderBtnSubmit,
    submitTooltip
  } = props

  const intl = useIntl()

  const btnBackText = backText
    ? backText
    : intl.formatMessage({ id: 'dataSourceSettings.btn.back' })

  const btnSubmitText = submitText
    ? submitText
    : intl.formatMessage({ id: 'dataSourceSettings.btn.next' })

  const btnCancelText = cancelText
    ? cancelText
    : intl.formatMessage({ id: 'dataSourceSettings.btn.cancel' })

  return (
    <div
      className={`register-control ${centerButtons ? 'edit-settings' : ''}`}
      data-test-id="dssettings-control-pane"
    >
      {showBackButton && (
        <Button
          variant="secondary"
          size={32}
          htmlType="button"
          className="previous xs-m-0"
          onClick={onGoBack}
          disabled={isBackDisabled}
          icon={<IconCaretLeft size="16" weight="bold" />}
          data-test-id="ds-settings-btn-back"
        >
          {btnBackText}
        </Button>
      )}
      <div className={`btn-group ${centerButtons ? 'center-content' : ''}`}>
        {onCancel && (
          <Button
            variant="secondary"
            size={32}
            htmlType="button"
            className="cancel"
            onClick={onCancel}
            disabled={isCancelDisabled}
            data-test-id="ds-settings-btn-cancel"
          >
            {btnCancelText}
          </Button>
        )}
        {showNextButton && (
          <>
            {renderBtnSubmit ? (
              renderBtnSubmit()
            ) : onGoForward ? (
              <>
                <Button
                  variant="primary"
                  size={32}
                  className="next xs-m-0"
                  onClick={onGoForward}
                  disabled={isNextDisabled}
                  data-test-id="ds-settings-btn-next"
                >
                  {btnSubmitText}
                </Button>
                {submitTooltip ? submitTooltip : ''}
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ControlPane
