import type {
  DataSourceMixedSettings,
  DataSourceMixedSettingsParams,
  ClouldResourceData,
  SotOption,
  SotOptionParams
} from './slice'
import {
  DATA_SOURCE_ID,
  DATA_SOURCE_TYPE,
  mapDsStructuredTypeNameToConfigName
} from '../../constants'
import { gql } from 'graphql-request'

export const queryDataSourceMixedSettings = (params: DataSourceMixedSettingsParams): string => gql`
  {
    datasources(id: "${params.datasourceId}") {
      edges {
        node {
          id
          name
          state
          lastModifiedTime
          createdBy
          schedulePolicy
          type
          canCreateEntities
          isSourceOfTruth
          labels{
            edges{
              node{
                id
                name
                labelSetId
                labelSetName
              }
            }
          }
          scanConditions {
            ... on StructuredDatasourceScanCondition{
              database
              schema
            }
            ... on BigQueryDatasourceScanCondition {
              projectId
              datasetId
            }
            ... on LookerDatasourceScanCondition {
              projectId
              modelName
            }
            ...on AirbyteDatasourceScanCondition {
              syncMode
              streamName
              canCreateEntities
            }
          }
          configuration {
            __typename
            ... on MsSQLConfig {
              ssl
            }
            ... on ServiceNowConfig{
              startDate
            }
            ... on HubSpotConfig{
              startDate
            }
            ... on MarketoConfig{
              startDate
            }
            ... on RemoteDataSourceConfig{
              datasourceType
            }
            ... on OfflineSchemaUploadConfig{
              baseDatasourceId
              folderURI
              baseDatasourceScanConditions{
                filterType
                objectType
                objectValues
              }
            }
          }
        }
      }
    }
  }
`

export const mapQueryDataSourceMixedSettings = (raw: any): DataSourceMixedSettings => {
  try {
    const ds = raw.datasources.edges[0].node

    return {
      id: ds.id,
      name: ds.name || '',
      status: ds.state,
      lastModifiedTime: ds.lastModifiedTime,
      createdBy: ds.createdBy,
      schedulePolicy: ds.schedulePolicy,
      canCreateEntities: ds.canCreateEntities || false,
      isSourceOfTruth: ds.isSourceOfTruth || false,
      scanConditions: ds.scanConditions,
      sotConfiguration: ds.sotConfiguration || [],
      databases:
        ds.scanConditions
          ?.filter(({ database }) => !!database)
          .map(({ database }) => ({ databaseName: database })) || [],
      schemas:
        ds.scanConditions?.filter(({ database }) => !!database).map(({ schema }) => schema) || [],
      labels: ds?.labels?.edges?.map((edge) => edge?.node) || [],
      configuration: {
        datasourceType: ds.type,
        location: ds.configuration.location,
        name: ds.configuration.__typename,
        [mapDsStructuredTypeNameToConfigName[ds.configuration.__typename]]: {
          username: ds.configuration?.username || '',
          password: ds.configuration?.password || '',
          host: ds.configuration?.host || '',
          port: ds.configuration?.port || '',
          accountName: ds.configuration?.accountName || '',
          warehouse: ds.configuration?.warehouse || '',
          serviceName: ds.configuration?.serviceName || '',
          startDate: ds.configuration?.startDate || '',
          subdomain: ds.configuration?.subdomain || '',
          email: ds.configuration?.credentials?.email || '',
          apiToken: ds.configuration?.credentials?.apiToken || '',
          accessToken: ds.configuration?.credentials?.accessToken || '',
          ssl: ds.configuration?.ssl || null,
          datasourceType: ds.configuration?.datasourceType || '',
          baseDatasourceId: ds.configuration?.baseDatasourceId || '',
          folderURI: ds.configuration?.folderURI || '',
          baseDatasourceScanConditions: ds.configuration?.baseDatasourceScanConditions || ''
        }
      }
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryCloudResourcesById = (cloudResourceId: string): string => gql`
  {
    cloudResource(filter: [
      { key: CLOUD_RESOURCE_ID, values: ["${cloudResourceId}"] },
    ], booleanFilter: [
      { key: IS_SUPPORTED, value: true }
    ]) {
      edges {
        node {
          id
          datasourceType
          region
          resourceMetadata {
            hostname
            port
            domain
            url
          }
        }
      }
    }
  }
`
export const mapQueryCloudResourcesById = (raw: any): ClouldResourceData[] => {
  try {
    return raw.cloudResource.edges.map((edge) => {
      const metadata = edge.node.resourceMetadata

      const resouceData: ClouldResourceData = {
        datasourceType: edge.node.datasourceType,
        region: edge.node.region,
        resourceMetadata: {
          hostname: metadata?.hostname,
          port: metadata?.port,
          domain: metadata?.domain,
          url: metadata?.url
        }
      }

      return resouceData
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }
}

export const queryDataSourceProcessingStatus = (params: { [DATA_SOURCE_ID]: string }): string => {
  return gql`
  {
    datasources (id: "${params[DATA_SOURCE_ID]}") {
      edges {
        node {
          datasourceProgress(filter: {key: STATUS, values: [PENDING, RUNNING]}){
            count
          }
        },
      },
    },
  }
`
}
// TODO: add type for graphql response
export const mapQueryDataSourceProcessingStatus = (raw: any): boolean => {
  return raw.datasources.edges[0].node.datasourceProgress.count > 0
}

export const querySotOptions = (params: SotOptionParams): string => {
  const agrs = params[DATA_SOURCE_ID]
    ? `id: "${params[DATA_SOURCE_ID]}"`
    : `types: [${params[DATA_SOURCE_TYPE]}]`

  return gql`
  {
    datasources(${agrs}) {
      edges {
        node {
          type
          sotConfiguration {
            ... on AirbyteSOTConfiguration {
              streamName
            }
          }
        }
      }
    }
  }`
}
export const mapQuerySotOptions = (raw: any): SotOption[] => {
  try {
    return raw.datasources?.edges[0]?.node?.sotConfiguration?.map(({ streamName }) => ({
      streamName
    }))
  } catch (error) {
    console.error(error)
    throw error
  }
}
