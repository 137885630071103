import {
  Schema,
  SchemaCompact,
  SchemaCompactTable,
  SchemasCompactParams,
  SchemasListParams,
  SchemaTablesParams
} from './schemasSlice'
import { SCHEMA_ID } from '../../constants'
import { gql } from 'graphql-request'

export const querySchemasCompact = (params: SchemasCompactParams): string => {
  const rootQuery = params.databaseId
    ? `databases(id: "${params.databaseId}")`
    : `datasources(id: "${params.datasourceId}")`

  return gql`
    {
      ${rootQuery} {
        edges {
          node {
            schemas {
              edges {
                node {
                  id
                  name
                  tables {
                    count
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

// TODO: add type for graphql response
export const mapQuerySchemasCompact = (raw: any): SchemaCompact[] => {
  const root = raw.databases || raw.datasources

  return root.edges[0].node.schemas.edges.map(({ node: schema }) => ({
    schemaId: schema.id,
    schemaName: schema.name,
    isCollapsed: true,
    tablesCount: schema.tables.count
  }))
}

export const querySchemasList = (params: SchemasListParams): string => {
  const rootQuery = params.databaseId
    ? `databases(id: "${params.databaseId}")`
    : `datasources(id: "${params.datasourceId}")`

  return gql`
  {
    ${rootQuery} {
      edges {
        node {
          schemas {
            count
            edges {
              node {
                id
                name
                lastModifiedOn
                tables {
                  count
                }
                columnsPii: columns(toggleFilter: { key: IS_SENSITIVE }) {
                  count
                }
              }
            }
          }
        }
      }
    }
  }
`
}

// TODO: add type for graphql response
export const mapQuerySchemasList = (raw: any): { list: Schema[]; total: number } => {
  const root = raw.databases || raw.datasources

  const schemas = root?.edges[0]?.node?.schemas?.edges?.map(({ node: schema }) => {
    return {
      schemaId: schema.id,
      schemaName: schema.name,
      lastModified: schema.lastModifiedOn,
      tablesCount: schema.tables.count || 0,
      columnsSensitiveCount: schema.columnsPii?.count || 0
    }
  })

  return { list: schemas, total: root.edges[0].node.schemas.count }
}

export const querySchemaTables = (params: SchemaTablesParams): string => gql`
  {
    schemas(id: "${params[SCHEMA_ID]}") {
      edges {
        node {
          id
          name
          tables {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

// TODO: add type for graphql response
export const mapQuerySchemaTables = (
  raw: any
): { list: SchemaCompactTable[]; schemaId: string } => {
  const tables = raw.schemas.edges[0].node.tables.edges.map(({ node: table }) => ({
    tableId: table.id,
    tableName: table.name
  }))

  return { list: tables, schemaId: raw.schemas.edges[0].node.id }
}
