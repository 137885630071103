import {
  Database,
  DatabaseByPiiTables,
  DatabaseOwnersParams,
  DatabasesListParams,
  ReviewDatabaseParams,
  WidgetDatabaseByPiiTablesParams
} from './databasesSlice'
import {
  AlertStatuses,
  API_FILTER_ALERTS,
  DATA_SOURCE_ID,
  DATABASE_ID,
  DATASOURCES_LIMIT_DEFAULT,
  GRAPHQL_API_FILTERS,
  RISK_LEVELS,
  SEVERITY_LEVEL
} from '../../constants'
import { mapDatabaseGraphQlStatus } from '../../utils/structuredDataUtil'
import { ScanStatus } from '../../interfaces'
import { gql } from 'graphql-request'

export const queryDatabases = (params: DatabasesListParams): string => {
  return gql`
  {
    datasources (id: "${params[DATA_SOURCE_ID]}") {
      edges {
        node {
          databases(first: ${DATASOURCES_LIMIT_DEFAULT}) {
            count,
            edges {
              node {
                id
                name
                columnsNoScan: columns(booleanFilter: {key: ${GRAPHQL_API_FILTERS.isNoScan}, value: true}) {
                  count
                }
                columnsPii: columns(toggleFilter: { key: ${GRAPHQL_API_FILTERS.isSensitive} }) {
                  count
                }
              },
            },
          },
          datasourceProgress {
            edges {
              node {
                status,
                configuration {
                  schema,
                  database,
                  tables {
                    total,
                    failed,
                    running,
                    completed,
                  },
                  columns {
                    total,
                    failed,
                    running,
                    completed,
                  },
                },
              },
            },
          },
        },
      },
    },
  }
`
}

// TODO: add type for graphql response
export const mapQueryDatabases = (raw: any): { list: Database[]; total: number } => {
  const progressByDatabase: { [databaseName: string]: Database } = {}

  raw.datasources.edges[0].node.datasourceProgress.edges.forEach(({ node: schema }) => {
    if (!progressByDatabase[schema.configuration.database]) {
      progressByDatabase[schema.configuration.database] = {
        databaseName: schema.configuration.database,
        databaseId: '',
        columnsSensitiveCount: 0,
        schemasCount: 0,
        schemasCompletedCount: 0,
        tablesCount: 0,
        tablesCompletedCount: 0,
        columnsCount: 0,
        columnsCompletedCount: 0,
        alertsCount: 0,
        noScanCount: 0
      }
    }

    const db = progressByDatabase[schema.configuration.database]
    const prevStatus = db.status || mapDatabaseGraphQlStatus(schema.status)
    const currentStatus = mapDatabaseGraphQlStatus(schema.status)
    const isSchemaCompleted = currentStatus === ScanStatus.completed

    const updatedDb = {
      ...db,
      status: prevStatus !== ScanStatus.completed ? prevStatus : currentStatus,
      risk: RISK_LEVELS.noRisk,
      alertsCount: 0,
      schemasCount: db.schemasCount + 1,
      schemasCompletedCount: (db?.schemasCompletedCount || 0) + +isSchemaCompleted,
      tablesCount: db.tablesCount + schema.configuration.tables.total,
      tablesCompletedCount:
        db.tablesCompletedCount +
        schema.configuration.tables.completed +
        schema.configuration.tables.failed,
      columnsCount: db.columnsCount + schema.configuration.columns.total,
      columnsCompletedCount:
        db.columnsCompletedCount +
        schema.configuration.columns.completed +
        schema.configuration.columns.failed
    }

    progressByDatabase[db.databaseName] = updatedDb
  })

  const databasesRaw = raw.datasources.edges[0].node.databases.edges
  const list = Object.values(progressByDatabase).map((progressDb) => {
    const foundDb = databasesRaw.find(({ node }) => node.name === progressDb.databaseName)
    return {
      ...progressDb,
      databaseId: foundDb?.node?.id || '',
      columnsSensitiveCount: foundDb?.node?.columnsPii?.count || 0,
      noScanCount: foundDb?.node?.columnsNoScan?.count || 0
    }
  })

  return { list, total: raw.datasources.edges[0].node.databases.count }
}

export const queryDatabaseAlerts = (databaseId: string): string => {
  return gql`
    {
      alert(
        first: 1,
        severity: ${SEVERITY_LEVEL.critical},
        filter: [
        { key: ${GRAPHQL_API_FILTERS.databaseIds}, values: ["${databaseId}"] },
        { key: ${API_FILTER_ALERTS.STATUS}, values: "${AlertStatuses.active}" }
      ]) {
        count
      }
    }
  `
}

export const mapQueryDatabasesAlerts = (raw: any): number => {
  return raw.subAlert?.count || 0
}

export const queryDatabasesPermitList = (datasourceId: string): string => {
  return gql`
    {
      permitList(filter: { key: DATASOURCE_IDS, values: "${datasourceId}" }) {
        edges {
          node {
            table {
              count
              edges {
                node {
                  database {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                  columns(classification: PII) {
                    count
                  }
                  entities {
                    count
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const queryReviewDatabase = (params: ReviewDatabaseParams): any => {
  return gql`
  mutation{
    reviewStructObjects(reviewStructObjectsInput:{parentTableId:"", databaseId:"${params.databaseId}"}){
      success
    }
  }
  `
}

export const queryDatabasesByPiiTables = (params: WidgetDatabaseByPiiTablesParams): string => {
  return gql`
  {
    datasources (id: "${params[DATA_SOURCE_ID]}") {
      edges {
        node {
          databases(first: ${DATASOURCES_LIMIT_DEFAULT}) {
            edges {
              node {
                id
                name
                piiTables: tables(classification: PII) {
                  count
                }
              }
            }
          }
        }
      }
    }
  }
`
}

export const mapQueryDatabasesByPiiTables = (raw: any): DatabaseByPiiTables[] => {
  try {
    return raw.datasources?.edges[0]?.node.databases.edges.map(({ node: db }) => ({
      databaseId: db.id,
      database: db.name,
      piiTablesCount: db.piiTables?.count || 0
    }))
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const mutationSaveDatabaseOwners = (params: DatabaseOwnersParams) => {
  return gql`
    mutation {
      createDatabaseOwners(
        input: {
          databaseUrn: "${params[DATABASE_ID]}",
          enabled: ${params.notificationEnabled},
          databaseOwnerEmails: ${JSON.stringify(params.notificationOwners)}
        }
      ) { success }
    }
  `
}
