import * as React from 'react'
import { Route } from 'react-router-dom'

interface IProps {
  exact?: boolean
  path: string
  date?: string
  component: React.ComponentType<any>
}

const LoggedOutRoute = ({ component: Component, date, ...otherProps }: IProps): JSX.Element => {
  return (
    <Route
      {...otherProps}
      component={(props) => <Component {...props} {...(date ? { date } : {})} />}
    />
  )
}

export default LoggedOutRoute
