import {
  DateRange,
  DateRangeChartData,
  DateRangeOption,
  buildCMDateRangeGraphQLQuery,
  getMonthlyDateRanges,
  getWeeklyDateRanges,
  mapCMDateRangeGraphQLResponse
} from './utils'
import {
  ConsentManagementConsent,
  ConsentsByEntity,
  ConsentsByKey,
  ConsentsByRegion
} from '../consentManagementSlice'
import {
  ConsentOptStatus,
  EntityIdentifyStatus,
  SystemAlertQueryStatus,
  UNKNOWN
} from '../constants'
import { gql } from 'graphql-request'

export const queryConsentManagementOverViewSummary = (datasourceTypes: string[]): string => {
  return gql`
    query consentManagementTopSummary {
     preferenceCenter{
        count
        edges{
          node{
            id
            name
            consentHistoryGroupByRequestId{
              count
            }
          }
        }
      }
      consentManagementSettings {
          edges {
            node {
              datasource {
                count
                edges {
                  node {
                    id
                  }
                }
              }
            }
          }
        }

      optInConsents: consentHistoryGroupByRequestId(filter: [{ key: OPT_STATUS, values: ["${
        ConsentOptStatus.Subscribed
      }"] }]) {
        count
      }
      optOutConsents: consentHistoryGroupByRequestId(filter: [{ key: OPT_STATUS, values: ["${
        ConsentOptStatus.UnSubscribed
      }"] }]) {
        count
      }
      systemAlerts: notification(filter: [{key:MODULE, values: ["${
        SystemAlertQueryStatus.status
      }"]}]){
        count
      }
      ${
        datasourceTypes?.length > 0
          ? `totalConsentsfromDatasources:consentHistoryGroupByRequestId(filter:[{key:CREATED_BY,values:${JSON.stringify(
              datasourceTypes
            )}}]){count}`
          : ''
      }
    }
  `
}

export const mapConsentManagementOverViewSummary = (raw: any) => {
  const preferenceCenterCount = raw?.preferenceCenter?.count
  const systemAlerts = raw?.systemAlerts?.count
  const totalConsentsfromDatasources = raw?.totalConsentsfromDatasources?.count

  const preferenceCenters = raw?.preferenceCenter?.edges
  const preferenceCenterTotalConsentCount = preferenceCenters?.reduce((sum: number, edge: any) => {
    return sum + edge?.node?.consentHistoryGroupByRequestId?.count
  }, 0)

  return {
    dataSourcesCount: raw?.consentManagementSettings?.edges?.reduce((sum: number, edge: any) => {
      return sum + (edge?.node?.datasource?.count || 0)
    }, 0),
    optInConsentsCount: raw?.optInConsents?.count || 0,
    optOutConsentsCount: raw?.optOutConsents?.count || 0,
    preferenceCenterCount: preferenceCenterCount || 0,
    totalConsentsCount: preferenceCenterTotalConsentCount || 0,
    totalSystemAlerts: systemAlerts || 0,
    totalViolations: 0,
    totalConsentsfromDatasources: totalConsentsfromDatasources || 0
  }
}

export const queryConsentManagementConsentsByRegion = (): string => {
  return gql`
    query consentManagementConsentsByRegion {
      optInLocation: consentHistoryGroupByLocation {
        edges {
          node {
            location
            consentHistory(filter: [{ key: OPT_STATUS, values: ["${ConsentOptStatus.Subscribed}"] }]) {
              count
            }
          }
        }
      }

      optOutLocation: consentHistoryGroupByLocation {
        edges {
          node {
            location
            consentHistory(filter: [{ key: OPT_STATUS, values: ["${ConsentOptStatus.UnSubscribed}"] }]) {
              count
            }
          }
        }
      }
    }
  `
}

export const mapConsentManagementConsentsByRegion = (raw: any): ConsentsByRegion[] => {
  const optinEdges = raw.optInLocation?.edges
  const optoutEdges = raw.optOutLocation?.edges

  const locationMap = {}

  optinEdges.forEach((edge: any) => {
    const location = edge?.node?.location || UNKNOWN
    const count = edge?.node?.consentHistory?.count || 0

    if (!locationMap[location]) {
      locationMap[location] = {
        name: location,
        totalOptIns: 0,
        totalOptOuts: 0
      }
    }

    locationMap[location].totalOptIns += count
  })

  optoutEdges.forEach((edge: any) => {
    const location = edge?.node?.location || UNKNOWN
    const count = edge?.node?.consentHistory.count || 0

    if (!locationMap[location]) {
      locationMap[location] = {
        name: location,
        totalOptIns: 0,
        totalOptOuts: 0
      }
    }

    locationMap[location].totalOptOuts += count
  })

  // Convert map to array
  let locationsArray: ConsentsByRegion[] = Object.values(locationMap)

  // Separate "Unknown" location if it exists
  const unknownLocation = locationsArray?.find((location) => location.name === UNKNOWN)
  locationsArray = locationsArray?.filter((location) => location.name !== UNKNOWN)

  // Sort locations by totalOptins and totalOptOut
  locationsArray.sort((a, b) => b.totalOptIns + b.totalOptOuts - (a.totalOptIns + a.totalOptOuts))

  // Handle "Others" category if more than 4 locations (excluding "Unknown")
  if (locationsArray.length > 4) {
    const others: ConsentsByRegion = {
      name: 'Others',
      totalOptIns: 0,
      totalOptOuts: 0
    }

    // Keep the top 4 locations and aggregate the rest into "Others"
    const topLocations = locationsArray?.slice(0, 4)
    const remainingLocations = locationsArray?.slice(4)

    remainingLocations?.forEach((location) => {
      others.totalOptIns += location.totalOptIns
      others.totalOptOuts += location.totalOptOuts
    })

    topLocations?.push(others)
    locationsArray = topLocations
  }

  // Add "Unknown" location if it exists
  if (unknownLocation) {
    locationsArray?.push(unknownLocation)
  }

  return locationsArray || []
}

export const queryConsentManagementConsentByRegion = () => {
  return gql`
    {
      consentHistoryGroupByLocation {
        edges {
          node {
            location
            consentHistory {
              count
            }
          }
        }
      }
    }
  `
}

export const mapConsentManagementConsentByRegion = (raw: any): string[] => {
  const mappedData = raw?.consentHistoryGroupByLocation?.edges
    ?.map(({ node }) => ({
      location: node?.location || 'Unknown',
      count: node?.consentHistory.count
    }))
    ?.sort((a, b) => b?.count - a?.count) // Sort by count descending
    ?.map((item) => item?.location)

  return mappedData
}

export const queryConsentManagementConsentByTrendOverTime = (
  selectedDateRangeOption: string,
  datasourceTypes: string[]
): { trendQuery: string; dateRanges: DateRange[] } => {
  //// Need to be relooked after feature confirmation
  let dateRangeToQuery: DateRange[] = []

  switch (selectedDateRangeOption) {
    case DateRangeOption.Last4Weeks:
      dateRangeToQuery = getWeeklyDateRanges()
      break
    case DateRangeOption.Last4Months:
      dateRangeToQuery = getMonthlyDateRanges(4)
      break
    case DateRangeOption.Last12Months:
      dateRangeToQuery = getMonthlyDateRanges(12)
      break
    default:
      dateRangeToQuery = []
  }

  return {
    trendQuery: buildCMDateRangeGraphQLQuery(dateRangeToQuery, datasourceTypes),
    dateRanges: dateRangeToQuery
  }
}

export const mapConsentManagementConsentByTrendOverTime = (
  response: any,
  dateRanges?: DateRange[]
): DateRangeChartData[] => {
  return mapCMDateRangeGraphQLResponse(response, dateRanges) || []
}

export const queryConsentManagementEntityDistribution = (): string => {
  return gql`
  {
  optInEntityType: consentHistoryGroupByEntityType {
    edges {
      node {
        entityType {
          edges {
            node {
              id
              name
            }
          }
        }
        consentHistory(filter: [{key: OPT_STATUS, values: ["${ConsentOptStatus.Subscribed}"]}]) {
          count
        }
      }
    }
  }
  optOutEntityType: consentHistoryGroupByEntityType {
    edges {
      node {
        entityType {
          edges {
            node {
              id
              name
            }
          }
        }
        consentHistory(filter: [{key: OPT_STATUS, values: ["${ConsentOptStatus.UnSubscribed}"]}]) {
          count
        }
      }
    }
  }
  entityIdentifiedByLB: consentHistory(filter: [{key: ENTITY_IDENTIFIED, values: ["${EntityIdentifyStatus.Identified}"]}]) {
    count
  }
  entityUnIdentifiedByLB: consentHistory(filter: [{key: ENTITY_IDENTIFIED, values: ["${EntityIdentifyStatus.Unidentified}"]}]) {
    count
  }
}
  `
}

type EntityDistributionQuery = {
  entityIdentifiedByLB: number
  entityUnIdentifiedByLB: number
  entitiesDistributionData: ConsentsByEntity[]
}

export const mapConsentManagementEntityDistribution = (raw: any): EntityDistributionQuery => {
  const entityIdentifiedByLB = raw.entityIdentifiedByLB.count
  const entityUnIdentifiedByLB = raw.entityUnIdentifiedByLB.count

  const optinEdges = raw?.optInEntityType?.edges
  const optoutEdges = raw?.optOutEntityType?.edges

  const entityMap = {}

  optinEdges.forEach((edge: any) => {
    const entityNode = edge?.node?.entityType?.edges[0]?.node
    const entityName = entityNode ? entityNode?.name : UNKNOWN
    const entityId = entityNode ? entityNode?.id : ''
    const count = edge?.node?.consentHistory?.count

    if (!entityMap[entityName]) {
      entityMap[entityName] = {
        id: entityId,
        name: entityName,
        totalOptIns: 0,
        totalOptOuts: 0
      }
    }

    entityMap[entityName].totalOptIns += count
  })

  optoutEdges.forEach((edge: any) => {
    const entityNode = edge?.node?.entityType?.edges[0]?.node
    const entityName = entityNode ? entityNode?.name : UNKNOWN
    const entityId = entityNode ? entityNode?.id : ''
    const count = edge?.node?.consentHistory?.count

    if (!entityMap[entityName]) {
      entityMap[entityName] = {
        id: entityId,
        name: entityName,
        totalOptIns: 0,
        totalOptOuts: 0
      }
    }

    entityMap[entityName].totalOptOuts += count
  })

  let entitiesArray: ConsentsByEntity[] = Object.values(entityMap)

  // Separating "Unknown" entity if it exists
  const unknownEntity = entitiesArray?.find((entity) => entity?.name === UNKNOWN)
  entitiesArray = entitiesArray?.filter((entity) => entity?.name !== UNKNOWN)

  // Sorting entities by totalOptins and totalOptOut
  entitiesArray?.sort((a, b) => b.totalOptIns + b.totalOptOuts - (a.totalOptIns + a.totalOptOuts))

  // Handling "Others" category if more than 4 entities (excluding "Unknown")
  if (entitiesArray.length > 4) {
    const others: ConsentsByEntity = {
      name: 'Others',
      totalOptIns: 0,
      totalOptOuts: 0
    }

    // Keeping the top 4 entities and aggregate the rest into "Others"
    const topEntities = entitiesArray?.slice(0, 4)
    const remainingEntities = entitiesArray?.slice(4)

    remainingEntities?.forEach((entity) => {
      others.totalOptIns += entity.totalOptIns
      others.totalOptOuts += entity.totalOptOuts
    })

    topEntities?.push(others)
    entitiesArray = topEntities
  }

  // Adding "Unknown" entity if it exists
  if (unknownEntity) {
    entitiesArray?.push(unknownEntity)
  }

  const data = {
    entityIdentifiedByLB,
    entityUnIdentifiedByLB,
    entitiesDistributionData: entitiesArray
  }

  return data || []
}

export const queryConsentManagementConsentByTypeDatasourceSubscriptionList = (
  datasourceTypes: string[]
): string => {
  return gql`
    {
  communicationPreferences {
    edges {
      node {
        id
        configuration {
          ... on HubspotCommunicationPreference {
          __typename
            name
          }
          ... on CustomCommunicationPreference {
            __typename
            name
          }
        }
        datasource {
          edges {
            node {
              id
              name
              type
            }
          }
        }
        consentHistoryGroupByRequestId(filter: [{key: CREATED_BY, values: ${JSON.stringify(
          datasourceTypes
        )}}]) {
          count
        }
        optInConsents: consentHistoryGroupByRequestId(filter: [{key: OPT_STATUS, values: ["${
          ConsentOptStatus.Subscribed
        }"]},{key: CREATED_BY, values: ${JSON.stringify(datasourceTypes)}}]) {
          count
        }
        optOutConsents: consentHistoryGroupByRequestId(filter: [{key: OPT_STATUS, values: ["${
          ConsentOptStatus.UnSubscribed
        }"]},{key: CREATED_BY, values: ${JSON.stringify(datasourceTypes)}}]) {
          count
        }
      }
    }
  }
}
  `
}

export const mapConsentManagementConsentByTypeDatasourceSubscriptionList = (
  raw: any
): ConsentsByKey[] => {
  const mappedData = raw?.communicationPreferences?.edges?.map((edge) => {
    const {
      configuration,
      datasource,
      consentHistoryGroupByRequestId,
      id,
      optInConsents,
      optOutConsents
    } = edge?.node

    // Assuming there is only one datasource edge as per the provided schema output
    const datasourceNode = datasource?.edges[0]?.node

    return {
      id,
      name: configuration?.name,
      total: consentHistoryGroupByRequestId?.count,
      type: datasourceNode ? datasourceNode?.type : UNKNOWN,
      consentData: [
        { label: ConsentManagementConsent.optIn, value: optInConsents.count },
        { label: ConsentManagementConsent.optOut, value: optOutConsents.count }
      ]
    }
  })

  // Sort by total in descending order
  mappedData.sort((a, b) => b.total - a.total)

  // Take the top 5 Subscription Lists
  const top5 = mappedData?.slice(0, 5)

  return top5 || []
}

export const queryConsentManagementConsentCategoriesByPreferenceCenterId = (
  preferenceCenterId: string,
  preferenceGroupId: string
): string => {
  return gql`
    {
      preferenceCenter(id:"${preferenceCenterId}"){
        edges {
          node {
            name
            formConfig {
              edges {
                node {
                  formConfig {
                    configType
                    config {
                      key
                      values
                    }
                  }
                }
              }
            }
            preferenceConfig {
              preferenceCategoryId
              consentHistoryGroupByRequestId(filter: [{key: PREFERENCE_CENTER, values: ["${preferenceGroupId}"]}]) {
                count
              }
                 optInConsents: consentHistoryGroupByRequestId(filter: [{key: OPT_STATUS, values: ["${ConsentOptStatus.Subscribed}"]},{key: PREFERENCE_CENTER, values: ["${preferenceGroupId}"]}]) {
          count
        }
        optOutConsents: consentHistoryGroupByRequestId(filter: [{key: OPT_STATUS, values: ["${ConsentOptStatus.UnSubscribed}"]},{key: PREFERENCE_CENTER, values: ["${preferenceGroupId}"]}]) {
          count
        }
            }
          }
        }
      }
    }
  `
}

export const mapConsentManagementConsentCategoriesByPreferenceCenterId = (
  raw: any
): ConsentsByKey[] => {
  const mappedData: ConsentsByKey[] = []

  raw?.preferenceCenter?.edges?.forEach((centerEdge) => {
    const centerNode = centerEdge?.node

    centerNode?.preferenceConfig?.forEach((preference) => {
      if (!preference?.preferenceCategoryId) return

      centerNode?.formConfig?.edges?.forEach((formEdge) => {
        const formNode = formEdge?.node

        formNode?.formConfig?.forEach((config) => {
          if (config?.configType === 'preference_config') {
            const configIdEntry = config?.config?.find(
              (entry) => entry?.key === 'preference_config_id'
            )
            if (
              configIdEntry &&
              configIdEntry?.values?.includes(preference?.preferenceCategoryId)
            ) {
              const titleEntry = config?.config?.find((entry) => entry.key === 'title')
              const title = titleEntry ? titleEntry?.values[0] : UNKNOWN

              mappedData.push({
                id: preference?.preferenceCategoryId,
                name: title,
                total: preference?.consentHistoryGroupByRequestId?.count,
                consentData: [
                  {
                    label: ConsentManagementConsent.optIn,
                    value: preference?.optInConsents?.count
                  },
                  {
                    label: ConsentManagementConsent.optOut,
                    value: preference?.optOutConsents?.count
                  }
                ],
                type: centerNode?.name
              })
            }
          }
        })
      })
    })
  })

  // Sort by total in descending order
  mappedData?.sort((a, b) => b.total - a.total)

  // Handle merging into "Others" if there are more than 5 entries
  if (mappedData.length > 5) {
    const top5 = mappedData?.slice(0, 4)
    const others = mappedData?.slice(4)

    const othersTotal = others?.reduce((acc, item) => acc + item.total, 0)
    const othersEntry: ConsentsByKey = {
      name: 'Others',
      total: othersTotal,
      type: 'Various'
    }

    top5?.push(othersEntry)
    return top5 || []
  }

  return mappedData
}

export const queryConsentManagementConsentByPrefenrenceCenterList = (): string => {
  return gql`
    {
      preferenceCenter {
        edges {
          node {
            id
            preferenceCenterGroupId
            name
            consentHistoryGroupByRequestId {
              count
            }
            optInConsents: consentHistoryGroupByRequestId(filter: [{key: OPT_STATUS, values: ["${ConsentOptStatus.Subscribed}"]}]) {
              count
            }
            optOutConsents: consentHistoryGroupByRequestId(filter: [{key: OPT_STATUS, values: ["${ConsentOptStatus.UnSubscribed}"]}]) {
              count
            }
          }
        }
      }
    }
  `
}

export const mapConsentManagementConsentByPrefenrenceCenterList = (raw: any): ConsentsByKey[] => {
  const preferenceCenters = raw.preferenceCenter.edges
  const mappedData: ConsentsByKey[] = []

  preferenceCenters.forEach((edge) => {
    mappedData.push({
      name: edge.node.name,
      id: edge.node.id,
      preferenceGroupId: edge.node.preferenceCenterGroupId,
      total: edge.node.consentHistoryGroupByRequestId.count,
      consentData: [
        { label: ConsentManagementConsent.optIn, value: edge.node.optInConsents.count },
        { label: ConsentManagementConsent.optOut, value: edge.node.optOutConsents.count }
      ]
    })
  })

  mappedData?.sort((a, b) => b.total - a.total)

  return mappedData
}

export const queryConsentManagementDistributionByDatasource = (
  datasourceTypes: string[]
): string => {
  return gql`
    {
      consentManagementSettings {
        edges {
          node {
            datasource {
              edges {
                node {
                  id
                  name
                  type
                  consentHistory(filter: [{key: CREATED_BY, values: ${JSON.stringify(
                    datasourceTypes
                  )}}]) {
                    count
                  }
                  optInConsents: consentHistory(filter: [{key: OPT_STATUS, values: ["${
                    ConsentOptStatus.Subscribed
                  }"],},{key: CREATED_BY, values: ${JSON.stringify(datasourceTypes)}}]) {
                    count
                  }
                  optOutConsents: consentHistory(filter: [{key: OPT_STATUS, values: ["${
                    ConsentOptStatus.UnSubscribed
                  }"]},{key: CREATED_BY, values: ${JSON.stringify(datasourceTypes)}}]) {
                    count
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapConsentManagementDistributionByDatasource = (raw: any): ConsentsByKey[] => {
  const mappedData: ConsentsByKey[] = []

  raw?.consentManagementSettings?.edges?.forEach((settingEdge) => {
    settingEdge?.node?.datasource?.edges?.forEach((dataSourceEdge) => {
      mappedData.push({
        name: dataSourceEdge?.node?.name,
        id: dataSourceEdge?.node?.id,
        total: dataSourceEdge?.node?.consentHistory?.count,
        consentData: [
          {
            label: ConsentManagementConsent.optIn,
            value: dataSourceEdge?.node?.optInConsents?.count
          },
          {
            label: ConsentManagementConsent.optOut,
            value: dataSourceEdge?.node?.optOutConsents?.count
          }
        ]
      })
    })
  })

  // Sort by total in descending order
  mappedData?.sort((a, b) => b.total - a.total)

  // Handle merging into "Others" if there are more than 5 entries
  if (mappedData.length > 5) {
    const top5 = mappedData?.slice(0, 4)
    const others = mappedData?.slice(4)

    const othersTotal = others?.reduce((acc, item) => acc + item?.total, 0)
    const othersEntry: ConsentsByKey = {
      name: 'Others',
      total: othersTotal
    }

    top5?.push(othersEntry)
    return top5 || []
  }

  return mappedData || []
}

export const queryConsentManagementComplainceStausDetails = (): string => {
  return gql`
    {
      consentManagementAlertCountGroupByCompliance {
        edges {
          node {
            compliance
            notificationCount
          }
        }
      }
    }
  `
}

export const mapConsentManagementComplainceStausDetails = (raw: any): ConsentsByKey[] => {
  const mappedData = raw?.consentManagementAlertCountGroupByCompliance?.edges?.map((edge) => ({
    name: edge?.node?.compliance,
    total: edge?.node?.notificationCount
  }))

  return mappedData || []
}
