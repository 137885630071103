import './databases.less'
import {
  ACTION_DATABASES_STATS_LIST_FETCH,
  ConnectionStatsParams,
  fetchDatabasesConnectionStatsList,
  resetLists,
  resetConnectionStatsList
} from './databasesSlice'
import { getDatabasesConnectionStatsListSorted } from './databasesSelector'
import WorkgroupInput from './workgroupInput'
import Table from '../../components/dataTable/dataTable_V2'
import { RootState } from '../../rootReducer'
import { getShowLoader } from '../../reducers/requestReducer'
import { ConnectionStats } from '../../services/api/apiTypes'
import LoaderView from '../../components/Loader/loader'
import PopupInfo from '../../components/PopupInfo/popupInfo'
import { DataSourceMixedParams } from '../dataSourcesMixed/slice'
import SvgDatabase from '../../assets/inlineSvg/database2.svg'
import RadioView from '../../components/RadioView/radioView'
import DropdownAddItem from '../../components/DropdownAddItem/dropdownAddItem'
import useUrlContext from '../../hooks/useUrlContext'
import {
  DATA_SOURCE_ID,
  DATA_SOURCE_TYPE,
  DATA_SOURCE_TYPES,
  DATA_SOURCE_TYPE_API_MAP
} from '../../constants'
import RegularColumn from '../../components/dataTable/components/regularColumn'
import { useIntl } from 'react-intl'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Input } from 'semantic-ui-react'
import { PlusCircle as SvgPlusCircle, Trash } from 'phosphor-react'
import { Typography } from '@lightbeamai/design-system'

export enum ConnectionStatsTabs {
  'all' = 'all',
  'selected' = 'selected'
}

interface IProps {
  configuration: DataSourceMixedParams
  list?: ConnectionStats
  scanList?: string[]
  databaseName?: string
  showLoader: boolean
  isRestricted: boolean
  isEditMode?: boolean
  disabled?: boolean
  onScanListUpdate: (list: string[]) => void
  onTabChange?: (tab: ConnectionStatsTabs) => void
  fetchDatabasesConnectionStatsList: (params: ConnectionStatsParams) => void
  resetConnectionStatsList: () => void
  setWorkGroup: (workgroup: string) => void
  workgroup?: string
}

const DatabasesConnectionStatsList = (props: IProps): React.ReactElement => {
  const {
    configuration,
    list = [],
    scanList = [],
    showLoader,
    isRestricted,
    isEditMode = false,
    disabled = false,
    onScanListUpdate,
    onTabChange,
    fetchDatabasesConnectionStatsList,
    resetConnectionStatsList,
    setWorkGroup,
    workgroup,
    databaseName = ''
  } = props

  const intl = useIntl()
  const context = useUrlContext()
  const datasourceId = context.contextFilters[DATA_SOURCE_ID]
  const urlDatasourceType = context.queryParams[DATA_SOURCE_TYPE]
  const config = useRef(configuration).current

  const { configuration: dsConfiguration } = configuration
  const { datasourceType } = dsConfiguration

  const isGlueDs = datasourceType === DATA_SOURCE_TYPE_API_MAP[DATA_SOURCE_TYPES.glue]
  // handling type from url since internal in config the type is still PostgreSQL DATA_SOURCE_TYPE_API_MAP[DATA_SOURCE_TYPES.cosmosPostgres] = 'PostgreSQL'
  const isCosmosPostgres = urlDatasourceType === DATA_SOURCE_TYPES.cosmosPostgres

  // tabs logic and fetch connection-stats
  const [activeTab, setActiveTab] = useState<ConnectionStatsTabs>(ConnectionStatsTabs.all)
  const handleTabChange = (tab) => {
    setActiveTab(tab)
    onTabChange && onTabChange(tab)
    if (tab === ConnectionStatsTabs.all) {
      onScanListUpdate(list.map((db) => db.database))
    } else {
      onScanListUpdate([])
    }
  }

  useEffect(() => {
    const { scanConditions, ...configWithoutScanConditions } = config
    scanConditions
    fetchDatabasesConnectionStatsList(
      datasourceId ? { id: datasourceId } : configWithoutScanConditions
    )

    return () => resetConnectionStatsList()
  }, [datasourceId, fetchDatabasesConnectionStatsList, resetConnectionStatsList])

  useEffect(() => {
    if (!isEditMode && list.length > 0) {
      onScanListUpdate(list.map((db) => db.database))
    }
  }, [list.length, isEditMode, onScanListUpdate])

  const [isFirstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (isFirstLoad && isRestricted) {
      setFirstLoad(false)
      setActiveTab(ConnectionStatsTabs.selected)
    }
  }, [isFirstLoad, isRestricted])

  // user input list
  const [dbInput, setDbInput] = useState('')
  const dbInputRef = useRef<Input>(null)
  const [isDbInputOpened, setDbInputOpened] = useState(false)

  const openDbInput = () => {
    setDbInputOpened(true)
    // hack to assure that input rendered and received ref
    setTimeout(() => dbInputRef?.current?.focus())
  }
  const showAddDbBtn = () => {
    setDbInputOpened(false)
    setDbInput('')
  }
  const handleAddDb = (event) => {
    if (event.key === 'Enter') {
      onScanListUpdate([...scanList, dbInput])
      showAddDbBtn()
    }
  }
  // list definition
  const dbList = scanList.map((database) => ({ database }))

  // search
  const [searchValue, setSearchValues] = useState('')
  const handleSearch = (_, { value }) => setSearchValues(value)
  const filteredList = dbList.filter((db) => {
    return db.database.toLowerCase().includes(searchValue.toLowerCase())
  })

  const columns = [
    {
      dataKey: isCosmosPostgres ? 'schema' : 'database',
      flex: 2,
      minWidth: 200,
      title: isCosmosPostgres
        ? intl.formatMessage({ id: 'schemas.table.name' })
        : intl.formatMessage({ id: 'databases.table.name' }),
      cellRenderer: function RegularRenderer({ data }) {
        const { database = '' } = data

        return (
          <RegularColumn
            flexItemsCentered
            className="db-name"
            tooltip={database}
            content={
              <>
                <SvgDatabase />
                <span className="xs-ml-8 truncate">{database}</span>
              </>
            }
          />
        )
      }
    },
    {
      dataKey: 'actions',
      minWidth: 100,
      resizable: false,
      title: '',
      cellRenderer: function RegularRenderer({ data }) {
        const { database = '' } = data
        const handleClick = () => handleRemoveChecked(database)

        return (
          <button className="btn icon-box remove" onClick={handleClick} disabled={disabled}>
            <Trash size={20} weight="fill" color="var(--color-primary)" />
          </button>
        )
      }
    }
  ]

  // input items dropdown
  const dropdownItems = list
    .filter((db) => !scanList.includes(db.database))
    .map((db) => ({ key: db.database, value: db.database, text: db.database }))

  const handleAddItem = (value) => onScanListUpdate([value, ...scanList])

  const handleRemoveChecked = (dbToRemove) => {
    onScanListUpdate(scanList.filter((db) => db !== dbToRemove))
  }
  const intlId = isCosmosPostgres ? 'schema' : 'db'

  return (
    <div
      className={`databases-scan custom-scrollbar ${datasourceId ? 'edit-page' : ''}`}
      data-test-id="databases-databases-connection-stats-list"
    >
      <LoaderView showLoader={showLoader} size="big" />
      {isGlueDs && (
        <WorkgroupInput
          isEditMode={isEditMode}
          workgroup={workgroup}
          setWorkGroup={setWorkGroup}
          disabled={disabled}
        />
      )}
      {
        <div className="db-scan-settings">
          {isCosmosPostgres && (
            <div className="row xs-mb-16">
              <Typography type="medium" variant="basePlus2" color="secondary" className="xs-mb-0">
                {intl.formatMessage({ id: 'dataSources.structured.header.database' })}&nbsp;:&nbsp;
              </Typography>
              <Typography type="medium" variant="basePlus2">
                {databaseName}
              </Typography>
            </div>
          )}
          <Typography variant="baseMinus2" color="secondary" className="xs-mb-0" as="div">
            {intl.formatMessage({ id: `dataSources.structured.settings.${intlId}.title` })}
          </Typography>
          {!isEditMode && (
            <div className="db-tabs">
              <RadioView
                checked={activeTab === ConnectionStatsTabs.all}
                onClick={() => handleTabChange(ConnectionStatsTabs.all)}
                disabled={isRestricted || disabled}
                content={
                  <Typography as="div" color="secondary" className="description">
                    {intl.formatMessage({ id: `dataSources.structured.settings.${intlId}.tabAll` })}
                    <PopupInfo
                      text={intl.formatMessage({
                        id: `dataSources.structured.settings.${intlId}.tabAll.tooltip`
                      })}
                    />
                  </Typography>
                }
              />
              <RadioView
                checked={activeTab === ConnectionStatsTabs.selected}
                onClick={() => handleTabChange(ConnectionStatsTabs.selected)}
                disabled={isRestricted || disabled}
                content={
                  <Typography as="div" color="secondary" className="description">
                    {intl.formatMessage({
                      id: `dataSources.structured.settings.${intlId}.tabSpecific`
                    })}
                    <PopupInfo
                      text={intl.formatMessage({
                        id: `dataSources.structured.settings.${intlId}.tabSpecific.tooltip`
                      })}
                    />
                  </Typography>
                }
              />
            </div>
          )}

          {list.length > 0 ? (
            <DropdownAddItem
              disabled={disabled}
              optionsList={dropdownItems}
              onAddItem={handleAddItem}
              trigger={
                <div className="db-dropdown-trigger">
                  <div className="icon-box">
                    <SvgPlusCircle size={16} weight="fill" color="var(--color-primary-light)" />
                  </div>
                  <div className="text">
                    {intl.formatMessage({ id: `dataSources.structured.settings.${intlId}.addNew` })}
                  </div>
                </div>
              }
            />
          ) : (
            <>
              {isDbInputOpened ? (
                <Input
                  disabled={disabled}
                  ref={dbInputRef}
                  value={dbInput}
                  onChange={(_, data) => setDbInput(data.value)}
                  onKeyPress={handleAddDb}
                  onBlur={showAddDbBtn}
                  placeholder={intl.formatMessage({
                    id: `dataSources.structured.settings.${intlId}.addNew`
                  })}
                  className="db-input"
                />
              ) : (
                <button className="btn add-new-db" onClick={openDbInput} disabled={disabled}>
                  <div className="icon-box">
                    <SvgPlusCircle size={16} weight="fill" color="var(--color-primary-light)" />
                  </div>
                  <div className="text">
                    {intl.formatMessage({ id: `dataSources.structured.settings.${intlId}.addNew` })}
                  </div>
                </button>
              )}
            </>
          )}
        </div>
      }

      {activeTab && (
        <Input
          disabled={disabled}
          value={searchValue}
          onChange={handleSearch}
          placeholder={intl.formatMessage({
            id: `dataSources.structured.settings.${intlId}.search`
          })}
          icon="search"
          iconPosition="left"
          className="list-search xs-mb-16"
        />
      )}

      <div className="db-list">
        <Table
          columns={columns}
          data={filteredList}
          tableParams={{ className: 'db-scan-table', hasActionColumn: true }}
          customHeader={
            <div className="db-list-control">
              <Typography
                as="div"
                color="primary"
                type="strong"
                variant="base"
                className="selected-count xs-m-0"
              >
                {dbList.length}
                &nbsp;
                {intl.formatMessage({
                  id: `dataSources.structured.settings.${intlId}.addedForScanning`
                })}
              </Typography>
            </div>
          }
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  list: getDatabasesConnectionStatsListSorted(state),
  databaseName: state.databases.databaseName,
  total: state.databases.connectionStats.total,
  isRestricted: state.databases.connectionStats.isRestricted,
  showLoader: getShowLoader(ACTION_DATABASES_STATS_LIST_FETCH)
})

const mapDispatchToProps = {
  fetchDatabasesConnectionStatsList,
  resetLists,
  resetConnectionStatsList
}

export default connect(mapStateToProps, mapDispatchToProps)(DatabasesConnectionStatsList)
