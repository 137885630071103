import { WhatsNewDatabase, WhatsNewWidgetParams } from './whatsNewSlice'
import { DATASOURCES_LIMIT_DEFAULT, GRAPHQL_API_FILTERS } from '../../constants'
import { gql } from 'graphql-request'

export const queryWhatsNewWidget = (params: WhatsNewWidgetParams): string => {
  return gql`
  {
    datasources(id: "${params.datasourceId}") {
      edges {
        node {
          datasourceProgress(filter:{key:STATUS, values:[COMPLETED]}) {
            edges {
              node {
                lastModified
                configuration {
                  database
                }
              }
            }
          }
          databases(first: ${DATASOURCES_LIMIT_DEFAULT}) {
            edges {
              node {
                id
                name
                createdOn
                createdBy
                modifiedBy
                tablesNeedReview: tables(first: 1, booleanFilter: {key: ${GRAPHQL_API_FILTERS.isReviewed}, value: false}) {
                  count
                }
                columnsNeedReview: columns(first: 1, booleanFilter: {key: ${GRAPHQL_API_FILTERS.isReviewed}, value: false}) {
                  count
                }
              }
            }
          }
        }
      }
    }
  }

`
}

// TODO: add type for graphql response
export const mapQueryWhatsNewWidget = (raw: any): WhatsNewDatabase[] => {
  try {
    return raw.datasources?.edges[0]?.node?.databases?.edges?.map(({ node: db }) => {
      const progress = raw.datasources?.edges[0]?.node?.datasourceProgress?.edges?.find(
        ({ node: progress }) => progress.configuration?.database === db.name
      )

      return {
        databaseId: db.id,
        databaseName: db.name,
        modifiedOn: progress?.node?.lastModified || '',
        modifiedBy: db.modifiedBy || db.createdBy || '',
        tablesNeedReviewCount: db.tablesNeedReview?.count || 0,
        columnsNeedReviewCount: db.columnsNeedReview?.count || 0
      }
    })
  } catch (error) {
    console.error(error)
    throw error
  }
}
