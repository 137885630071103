import {
  Cluster,
  ClusterEntityTag,
  ClusterEntityTagsDeleteParams,
  ClusterEntityTagsParams,
  DatabaseClustersParams,
  ClusterTablesParams,
  RelatedSchema,
  RelatedSchemasParams,
  RelatedSchemaTablesParams,
  RelatedTable,
  RelatedTableWithColumns,
  StartEntityScanParams,
  ReviewFilterTypes
} from './tableRelationshipsSlice'
import {
  DATA_SOURCE_TYPES,
  GRAPHQL_API_FILTERS,
  GRAPHQL_CLASSIFICATION_TYPE,
  LIMIT_DEFAULT,
  ORPHAN_CLUSTER_ID,
  ORPHAN_CLUSTER_TYPE_NAME,
  SEARCH_QUERY
} from '../../constants'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { gql } from 'graphql-request'

export const queryRelatedSchemas = (params: RelatedSchemasParams): string => {
  const clusterTablesQuery =
    params.clusterId !== ORPHAN_CLUSTER_ID
      ? `tableClusters(first: 1, id: "${params.clusterId}") {
          edges {
            node {
              ... on AnchorTableCluster {
                id
                name
              }
            }
          }
        }`
      : ''

  return gql`
    {
      databases(first: 1, id: "${params.databaseId}") {
        edges {
          node {
            id
            name
            ${clusterTablesQuery}
            datasource {
              edges {
                node {
                  id
                  name
                  type
                  createdBy
                }
              }
            }
            schemas(first: 999) {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryRelatedSchemas = (
  raw: any
): {
  clusterId: string
  clusterName: string
  dataSourceId: string
  dataSourceName: string
  dataSourceOwner: string
  dataSourceType: DATA_SOURCE_TYPES
  databaseId: string
  databaseName: string
  schemas: RelatedSchema[]
} => {
  try {
    const cluster = raw.databases?.edges[0]?.node?.tableClusters?.edges[0]?.node || {}
    const clusterId = decodeURIComponent(cluster.id || '')
    const clusterName = cluster.name || ''

    return {
      clusterId,
      clusterName,
      dataSourceId: raw.databases?.edges[0]?.node?.datasource?.edges[0]?.node.id,
      dataSourceName: raw.databases?.edges[0]?.node?.datasource?.edges[0]?.node.name,
      dataSourceOwner: raw.databases?.edges[0]?.node?.datasource?.edges[0]?.node.createdBy,
      dataSourceType: raw.databases?.edges[0]?.node?.datasource?.edges[0]?.node.type,
      databaseId: raw.databases?.edges[0]?.node?.id || '',
      databaseName: raw.databases?.edges[0]?.node?.name || '',
      schemas: raw.databases?.edges[0]?.node?.schemas?.edges.map(({ node: schema }) => ({
        schemaId: schema.id,
        schemaName: schema.name
      }))
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryRelatedSchemaTables = (params: RelatedSchemaTablesParams): string => {
  const { page, databaseId, filters, reviewFilter } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)

  let filter = ''

  if (reviewFilter) {
    filter = `booleanFilter: [{key: ${GRAPHQL_API_FILTERS.isReviewed}, value: ${
      reviewFilter === ReviewFilterTypes.reviewed
    } }],`
  }
  if (filters && !!Object.values(filters).length) {
    const filtersStr = Object.entries(filters).map(([key, values]) => {
      return `{key: ${key}, values: ${JSON.stringify(values)}}`
    })
    filter += `filter: [${filtersStr}],`
  }

  let searchQuery = ''
  if (params[SEARCH_QUERY]) {
    searchQuery = `query: "${params[SEARCH_QUERY]}", `
  }

  let nonPiiFilter = ''
  if (filters.CLASSIFICATION?.includes(GRAPHQL_CLASSIFICATION_TYPE.NOT_PII.toLowerCase())) {
    nonPiiFilter = `classification: NOT_PII,`
  }

  return gql`
    {
      databases(id: "${databaseId}") {
        edges {
          node {
            tables(first: ${LIMIT_DEFAULT}, after: "${cursor}", ${filter} ${nonPiiFilter} ${searchQuery}) {
              count
              edges {
                node {
                  id
                  name
                  isReviewed
                  clusterRootTableUrn
                  classifications{
                    identity{
                      isIdentity
                    }
                  }
                  columns(first: 1) {
                    count
                  }
                  columnsPii: columns(first: 1, toggleFilter: {key: IS_SENSITIVE}) {
                    count
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryRelatedSchemaTables = (
  raw: any,
  parentTableId: string
): {
  tables: RelatedTable[]
  total: number
} => {
  try {
    const tables: RelatedTable[] =
      raw.databases.edges[0].node?.tables?.edges?.map(({ node: table }) => {
        const tableId = decodeURIComponent(table.id || '')
        return {
          tableId,
          tableName: table.name,
          isReviewed: table.isReviewed || false,
          columnsCount: table.columns?.count || 0,
          columnsPiiCount: table.columnsPii?.count || 0,
          isParent: tableId === parentTableId,
          parentId: table.clusterRootTableUrn,
          isUnderCluster:
            !!table.clusterRootTableUrn || !!table.classifications?.identity?.isIdentity
        }
      }) || []

    return {
      total: raw.databases.edges[0].node?.tables?.count || 0,
      tables
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryRelatedColumns = (params: { tableId: string }): string => {
  return gql`
    {
      tables(id: "${params.tableId}", first: 1) {
        edges {
          node {
            id
            name
            columns {
              edges {
                node {
                  id
                  name
                  constraint
                  qualifiedName
                  classifications {
                    pii {
                      numRowsPII
                    }
                  }
                  relatedColumns {
                    edges {
                      node {
                        id
                        name
                        table {
                          edges {
                            node {
                              id
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryRelatedColumns = (raw: any): RelatedTableWithColumns => {
  const linkedTablesSet: Set<string> = new Set()

  const columns = raw.tables?.edges[0]?.node?.columns?.edges?.map(({ node: column }) => {
    return {
      columnId: column.id,
      columnName: column.name,
      constraint: column.constraint,
      qualifiedName: column.qualifiedName,
      isPii: column.classifications?.pii?.numRowsPII > 0,
      linkedColumns: column.relatedColumns.edges.map(({ node: relatedColumn }) => {
        linkedTablesSet.add(decodeURIComponent(relatedColumn.table?.edges[0]?.node.id || ''))
        return relatedColumn.id
      }),
      linkColumnQualifiedNamesAdd: [],
      linkColumnQualifiedNamesDelete: []
    }
  })

  const table = {
    tableId: decodeURIComponent(raw.tables?.edges[0]?.node.id || ''),
    tableName: raw.tables?.edges[0]?.node.name || '',
    columns,
    linkedTables: [...linkedTablesSet]
  }

  return table
}

export const queryClusterTables = (params: ClusterTablesParams): string => {
  return gql`
    {
      tables(id: "${params.tableId}", first: 1) {
        edges {
          node {
            id
            name
            isReviewed
            columnsPii: columns(first: 1, toggleFilter: { key: ${GRAPHQL_API_FILTERS.isSensitive} }) {
              count
            }
            schema(first: 1) {
              edges{
                node{
                  id
                  name
                }
              }
            }
            columns {
              count
              edges {
                node {
                  id
                  name
                  constraint
                  relatedColumns {
                    edges {
                      node {
                        id
                        name
                        table (first: 1) {
                          edges {
                            node {
                              id
                              name
                              isReviewed
                              columns(first: 1) {
                                count
                              }
                              columnsPii: columns(first: 1, toggleFilter: { key: ${GRAPHQL_API_FILTERS.isSensitive} }) {
                                count
                              }
                              schema(first: 1) {
                                edges{
                                  node{
                                    id
                                    name
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryClusterTables = (raw: any): RelatedTable[] => {
  const parentTableId = decodeURIComponent(raw.tables?.edges[0]?.node?.id || '')
  const parentTableName = decodeURIComponent(raw.tables?.edges[0]?.node?.name || '')

  const parentTable: RelatedTable = {
    clusterId: parentTableId,
    clusterName: parentTableName,
    tableId: parentTableId,
    tableName: parentTableName,
    isReviewed: raw.tables?.edges[0]?.node?.isReviewed,
    columnsCount: raw.tables?.edges[0]?.node?.columns?.count || 0,
    columnsPiiCount: raw.tables?.edges[0]?.node?.columnsPii?.count || 0,
    schemaId: raw.tables?.edges[0]?.node?.schema?.edges[0]?.node.id,
    schemaName: raw.tables?.edges[0]?.node?.schema?.edges[0]?.node.name,
    parentId: '',
    isParent: true,
    isUnderCluster: true
  }

  const distinctRelatedTables: { [key: string]: RelatedTable } = {}
  raw.tables?.edges[0]?.node?.columns?.edges?.forEach(({ node: column }) => {
    column.relatedColumns.edges.forEach(({ node: relatedColumn }) => {
      const table = relatedColumn.table?.edges[0]?.node || {}

      if (table.id) {
        distinctRelatedTables[table.id] = {
          clusterId: parentTableId,
          clusterName: parentTableName,
          tableId: decodeURIComponent(table.id || ''),
          tableName: table.name,
          isReviewed: table.isReviewed,
          columnsCount: table.columns?.count || 0,
          columnsPiiCount: table.columnsPii?.count || 0,
          schemaId: table.schema?.edges[0]?.node.id,
          schemaName: table.schema?.edges[0]?.node.name,
          parentId: parentTableId,
          isParent: false,
          isUnderCluster: true
        }
      }
    })
  })

  return [parentTable, ...Object.values(distinctRelatedTables)]
}

export const queryUpdateColumnsLinkage = (params: {
  candidateColumnQualifiedName: string
  parentColumnQualifiedName: string
  action: 'CREATE' | 'DELETE'
}): any => {
  return gql`
  mutation {
    createRelationship(
      relationshipObject: {
        leftColumnUrn: "${params.parentColumnQualifiedName}"
        rightColumnUrn: "${params.candidateColumnQualifiedName}"
        operationType: ${params.action}
      }
    ) {
      leftColumnUrn
      rightColumnUrn
    }
  }
`
}

export const queryStartEntityScan = (params: StartEntityScanParams): any => {
  return gql`
    mutation {
      triggerEntityScan(
        triggerEntityScanInput: { action: START, clusterId: "${params.clusterId}" }
      ) {
        success
      }
    }
  `
}

export const queryReviewCluster = (params: StartEntityScanParams): any => {
  return gql`
    mutation {
      reviewTableCluster(clusterId: "${params.clusterId}") {
        success
      }
    }
  `
}

/** Entity tags */
export const queryClusterEntityTags = (params: ClusterEntityTagsParams): string => {
  const filter =
    params.filterLayer === 'cluster'
      ? [{ key: 'CLUSTER_IDS', values: [params.clusterId || ''] }]
      : [{ key: 'DATASOURCE_IDS', values: [params.datasourceId || ''] }]

  const filterStr = parameterizeArrayofObjects(filter)

  return gql`
    {
      filters(filter: ${filterStr}) {
        edges {
          node {
            id
            configuration {
              ... on ProfileFilters {
                conditions {
                  conditionLabel {
                    edges {
                      node {
                        id
                        labelDisplayName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryClusterEntityTags = (
  raw: any
): { tags: ClusterEntityTag[]; filterId: string } => {
  try {
    const tags =
      raw.filters?.edges[0]?.node?.configuration?.conditions.map((condition) => {
        return {
          id: condition.conditionLabel.edges[0].node.id,
          name: condition.conditionLabel.edges[0].node.labelDisplayName
        }
      }) || []

    return { tags, filterId: raw.filters?.edges[0]?.node?.id || '' }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryCreateClusterEntityTag = (params: ClusterEntityTagsParams): string => {
  const newTagName = (params.entityTagNames && params.entityTagNames[0]) || ''
  return gql`
    mutation {
      createFilter(
        clientMutationId: ""
        filterData: {
          name: "${params.filterName}_${params.clusterId}"
          owner: "${params.filterOwner}"
          type: PROFILE_FILTERS
          configuration: {
            profileFilters: {
              conditions: [
                {
                  filterExpression: "ALL"
                  objectExpression: {
                    objectType: CLUSTER
                    values: ["${params.clusterId}"]
                  }
                  conditionLabel: "${newTagName}"
                }
              ]
            }
          }
        }
      ) {
        clientMutationId
        filter {
          edges {
            node {
              id
              configuration {
                ... on ProfileFilters {
                  conditions {
                    filterExpression
                    objectExpression {
                      objectType
                      values
                    }
                    conditionLabel {
                      edges {
                        node {
                          id
                          labelDisplayName
                        }
                      }
                    }
                    filterExpression
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const queryUpdateClusterEntityTag = (params: ClusterEntityTagsParams): string => {
  const filterExpString = `filterExpression: "ALL"`
  const objectExpString = `objectExpression: { objectType: CLUSTER, values: ["${params.clusterId}"] }`
  let conditionsString = ''

  params.entityTagNames?.forEach((tagName) => {
    conditionsString += `{${filterExpString}, ${objectExpString}, conditionLabel: "${tagName}"}`
  })

  return gql`
    mutation {
      updateFilter(
        clientMutationId: ""
        id: "${params.filterId}"
        filterData: {
          type: PROFILE_FILTERS
          configuration: {
            profileFilters: {
              conditions: [${conditionsString}]
            }
          }
        }
      ) {
        clientMutationId
        filter {
          edges {
            node {
              id
              configuration {
                ... on ProfileFilters {
                  conditions {
                    filterExpression
                    objectExpression {
                      objectType
                      values
                    }
                    conditionLabel {
                      edges {
                        node {
                          id
                          labelDisplayName
                        }
                      }
                    }
                    filterExpression
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const queryDeleteEntityTags = (params: ClusterEntityTagsDeleteParams): string => {
  return gql`
    mutation {
      deleteFilter(clientMutationId: "", id: "${params.filterId}") {
        isDeleted
      }
    }
  `
}

export const queryDatabaseClusters = (params: DatabaseClustersParams): string => {
  return gql`
    {
      databases(first: 1, id: "${params.databaseId}") {
        edges {
          node {
            tableClusters(first: 999) {
              edges {
                node {
                  __typename
                  ... on AnchorTableCluster {
                    id
                    name
                    isReviewed
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryDatabaseClusters = (raw: any): Cluster[] => {
  try {
    const clusters =
      raw.databases?.edges[0]?.node?.tableClusters?.edges.filter(
        ({ node: cl }) => cl.__typename !== ORPHAN_CLUSTER_TYPE_NAME
      ) || []

    return clusters.map(({ node: cluster }) => ({
      clusterId: decodeURIComponent(cluster.id),
      clusterName: cluster.name,
      isReviewed: cluster.isReviewed
    }))
  } catch (error) {
    console.error(error)
    throw error
  }
}
