import { ApiRequestsWidget } from './apiRequestsSlice'
import { gql } from 'graphql-request'

export const queryApiRequestsWidget = (): string => {
  return gql``
}

// TODO: add type for graphql response
export const mapQueryApiRequestsWidget = (raw: any): ApiRequestsWidget => ({
  total: raw.apiRequests.count
})
