import classNames from 'classnames'
import React, { ReactElement } from 'react'

interface IProps {
  text: string | ReactElement
  subText?: string | ReactElement
  className?: string
}

const PrintPageHeader = (props: IProps): React.ReactElement => {
  const { text, subText = '', className = '' } = props

  return (
    <div
      className={classNames('header', { [className]: !!className })}
      data-test-id="print-page-header"
    >
      {subText && <div className="sub-text">{subText}</div>}
      <div className="text">{text}</div>
    </div>
  )
}

export default PrintPageHeader
