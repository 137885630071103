import service from '../../../../../services/api/apiService'
import { Email } from '../../../../../services/api/apiTypes'
import { getSortDirection, SortParams } from '../../../../../utils/sortUtil'
import { IGetEmailParams, SORT_ORDER } from '../../../../../interfaces'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface IList {
  list?: Email[]
  total?: number
  sort: SortParams
}

interface EmailsListState {
  all: IList
  risky: IList
  sensitive: IList
}

export const initialState: EmailsListState = {
  all: {
    sort: {
      column: 'timestamp',
      direction: SORT_ORDER.DESC
    }
  },
  risky: {
    sort: {
      column: 'timestamp',
      direction: SORT_ORDER.DESC
    }
  },
  sensitive: {
    sort: {
      column: 'timestamp',
      direction: SORT_ORDER.DESC
    }
  }
}
export const fetchEmails = createAsyncThunk(
  'emailsList/listAll',
  async (filters: IGetEmailParams) => {
    return await service.getEmails(filters)
  }
)

const emailsListSlice = createSlice({
  name: 'emailsList',
  initialState,
  reducers: {
    setSort: (state, { payload }) => {
      state[payload.list].sort = getSortDirection(state[payload.list].sort, payload.column)
    },
    resetLists: (state) => {
      state.all = initialState.all
      state.risky = initialState.risky
      state.sensitive = initialState.sensitive
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmails.fulfilled, (state, { payload }) => {
      if (payload.risky) {
        state.risky.list = payload.list
        state.risky.total = payload.total
      } else if (payload.sensitive) {
        state.sensitive.list = payload.list
        state.sensitive.total = payload.total
      } else {
        state.all.list = payload.list
        state.all.total = payload.total
      }
    })
  }
})

export const { setSort, resetLists } = emailsListSlice.actions

export default emailsListSlice.reducer
