import {
  DsrEntityAttributeInstancesParams,
  DsrEntityAttributeInstance
} from './tickets/dsrTicketsSlice'
import { DsrForm, DsrFormParams, DsrRequestTypes } from './dsrForms/dsrFormsSlice'
import { DATA_SOURCE_ID, ENTITY_ID, LIMIT_DEFAULT } from '../../constants'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { Attribute } from '../../services/graphqlSchemaTypes'
import { gql } from 'graphql-request'

export const queryDsrForms = ({ id, page, searchQuery, filters }: DsrFormParams): string => {
  const isTableview = page !== undefined
  const cursor = isTableview ? getAfterCursor(page, LIMIT_DEFAULT) : ''
  const paginationParams = isTableview ? `first: ${LIMIT_DEFAULT}, after: "${cursor}"` : ''
  const idParams = id ? `id: "${id}", ` : ''
  const searchQueryParam = searchQuery ? `, searchQuery: "${searchQuery}"` : ''
  let booleanParamString = ''
  const booleanFilterString =
    filters && filters.booleanFilter ? parameterizeArrayofObjects(filters.booleanFilter) : ''
  const filtersString = id ? '' : `, filter: [{key: FORM_VERSION, values:["latest"]}]`
  if (booleanFilterString) {
    booleanParamString += `, booleanFilter: ${booleanFilterString} `
  }
  const sortParams = ` ,sortField: CREATED_AT, sortByAsc: false`
  const filter =
    booleanParamString || filtersString || searchQuery || paginationParams || idParams || sortParams
      ? `(${idParams}${paginationParams}${booleanParamString}${filtersString}${searchQueryParam}${sortParams})`
      : ''

  return gql`
    {
      dataSubjectRequestForm${filter} {
        count
        edges {
          node {
            id
            name
            type
            description
            formRawJSON
            createdAt
            isTemplate
            template
            locations
            updateTimestamp
            isPublished
            createdBy
            logoFileUrl
            formGroupId
            formWebUrl
            automationConfiguration {
              requestType
              mode
              workflowConfig{
                edges{
                  node{
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryDsrForms = (raw: any): { list: DsrForm[]; total: number } => {
  try {
    const list =
      raw?.dataSubjectRequestForm?.edges?.map(({ node: form }) => {
        const { dataAccess, dataErasure, dataRectification, dataDoNotSell, dataOther } = JSON.parse(
          form.formRawJSON
        )
        const requestTypes: DsrRequestTypes[] = []
        dataAccess?.isActive && requestTypes.push(DsrRequestTypes.access)
        dataErasure?.isActive && requestTypes.push(DsrRequestTypes.erasure)
        dataRectification?.isActive && requestTypes.push(DsrRequestTypes.rectification)
        dataDoNotSell?.isActive && requestTypes.push(DsrRequestTypes.doNotSell)
        dataOther?.isActive && requestTypes.push(DsrRequestTypes.other)

        const automationConfiguration =
          (form.automationConfiguration &&
            form.automationConfiguration.map((request) => {
              const { requestType, mode, workflowConfig } = request

              // Check if there are edges and grab the first node if available
              const workflowNode =
                workflowConfig.edges.length > 0 ? workflowConfig.edges[0].node : {}

              return {
                requestType,
                mode,

                // Add workflowConfig only if mode is AUTOMATIC
                ...(mode === 'AUTOMATIC' && {
                  workflowConfig: {
                    id: workflowNode.id || '',
                    name: workflowNode.name || ''
                  }
                }),

                // Add workflowConfigId only if mode is AUTOMATIC
                ...(mode === 'AUTOMATIC' && {
                  workflowConfigId: workflowNode.id || ''
                })
              }
            })) ||
          []

        return {
          id: form.id,
          name: form.name || '',
          type: form.type,
          description: form.description || '',
          createdBy: form.createdBy || '',
          createdAt: form.createdAt,
          updateTimestamp: form.updateTimestamp,
          templateName: form.template || '',
          locations: form.locations || [],
          isPublished: form.isPublished || false,
          isTemplate: form.isTemplate || false,
          template: form.template,
          formRawJSON: form.formRawJSON,
          formJsonParsed: form.formRawJSON ? JSON.parse(form.formRawJSON) : {},
          requestType: requestTypes,
          logoFileUrl: form.logoFileUrl || '',
          formGroupId: form.formGroupId || '',
          formWebUrl: form.formWebUrl || '',
          automationConfiguration: automationConfiguration
        }
      }) || []

    return { list, total: raw?.dataSubjectRequestForm?.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDsrEntityAttributeInstances = (
  params: DsrEntityAttributeInstancesParams
): string => {
  let paramString = ''
  const filterString = params.filters?.filter
    ? parameterizeArrayofObjects(params.filters?.filter)
    : ''
  if (filterString) {
    paramString += `, filter: ${filterString}`
  }
  return gql`
    {
      attributeInstance(
        entityId: "${params[ENTITY_ID]}",
        datasourceIds: ["${params[DATA_SOURCE_ID]}"]
        ${paramString}
        ) {
        count
        edges {
          node {
            id
            name
            values
          }
        }
      }
    }
  `
}
export const mapQueryDsrEntityAttributeInstances = (
  raw: any
): { list: DsrEntityAttributeInstance[]; total: number } => {
  try {
    const list = raw?.attributeInstance?.edges?.map(({ node: attr }) => ({
      id: attr.id || '',
      name: attr.name || '',
      values: attr.values || ''
    }))

    return { list, total: raw?.attributeInstance?.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDsrAttributes = (): string => {
  return gql`
    {
      attribute {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `
}
export const mapQueryDsrAttributes = (raw: any): Attribute[] => {
  try {
    return raw?.attribute?.edges?.map(({ node: attr }) => ({
      id: attr.id || '',
      name: attr.name || ''
    }))
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryActiveDsrForm = (): string => {
  return gql`
    {
      dataSubjectRequestForm(
        booleanFilter: [{ key: IS_PUBLISHED, value: true }]
        filter: [{ key: FORM_VERSION, values: ["latest"] }]
      ) {
        edges {
          node {
            id
            name
            formRawJSON
          }
        }
      }
    }
  `
}
export const mapQueryActiveDsrForm = (raw: any): DsrForm => {
  try {
    const index = raw?.dataSubjectRequestForm?.edges?.length
      ? raw?.dataSubjectRequestForm?.edges?.length - 1
      : 0
    const form = raw?.dataSubjectRequestForm?.edges[index]?.node

    return {
      id: form?.id || '',
      name: form?.name || '',
      type: form?.type || '',
      formRawJSON: form?.formRawJSON || ''
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
