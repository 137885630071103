import { queryDatabasesAndPolicies, mapQueryDatabasesAndPolicies } from './queries'
import { DATA_SOURCE_ID } from '../../constants'
import graphqlService from '../../services/graphqlService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface DatabasesAndPoliciesParams {
  [DATA_SOURCE_ID]: string
}

export const ACTION_DATABASES_AND_POLICIES = 'databasesAndPolicies/databasesAndPolicies'
export const fetchDatabasesAndPolicies = createAsyncThunk(
  ACTION_DATABASES_AND_POLICIES,
  async (params: DatabasesAndPoliciesParams) => {
    const resultRaw = await graphqlService.execute(queryDatabasesAndPolicies(params))
    return mapQueryDatabasesAndPolicies(resultRaw)
  }
)

type DatabasesAndPoliciesState = {
  databasesCount?: number
  policiesCount?: number
}

export const initialState: DatabasesAndPoliciesState = {}

const databasesAndPoliciesSlice = createSlice({
  name: 'databasesAndPolicies',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDatabasesAndPolicies.fulfilled, (state, { payload }) => {
      state.databasesCount = payload.databasesCount
      state.policiesCount = payload.policiesCount
    })
  }
})

export const { reset } = databasesAndPoliciesSlice.actions

export default databasesAndPoliciesSlice.reducer
