import {
  ELASTIC_SEARCH_HOSTNAME,
  ELASTIC_SEARCH_INSTANCE_NAME
} from '../../features/dataSources/components/UnstructuredForm/ElasticSeach/elasticSearchSettingsSlice'
import * as Yup from 'yup'

export const elasticSearchStep1ValidationSchema = Yup.object().shape({
  [ELASTIC_SEARCH_INSTANCE_NAME]: Yup.string().required(
    'dataSources.form.validation.Name.required'
  ),

  [ELASTIC_SEARCH_HOSTNAME]: Yup.string().required(
    'dataSources.form.validation.InstanceUrl.required'
  )
})
