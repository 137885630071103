import PrintPageHeader from './header'
import classNames from 'classnames'
import React, { Fragment } from 'react'

export const ContentsItem = (props: TableContentsItem) => {
  const { urlAnchor, title, className = '' } = props

  return (
    <a href={urlAnchor} className={classNames('contents-item', { [className]: !!className })}>
      {title}
    </a>
  )
}

export const SubmenuItem = (props: TableContentsItem) => {
  const { urlAnchor, title, className = '' } = props

  return (
    <a href={urlAnchor} className={classNames('submenu-item', { [className]: !!className })}>
      {title}
    </a>
  )
}

export type TableContentsItem = {
  urlAnchor: string
  title: string
  className?: string
  subMenu?: {
    urlAnchor: string
    title: string
    className?: string
  }[]
}

export interface IPropsPageContents {
  tableContents: TableContentsItem[]
  className?: string
}

const PrintContentsPage = (props: IPropsPageContents): React.ReactElement => {
  const { tableContents } = props
  const headerText = 'Table of Contents'

  return (
    <div className="print-page page-contents" data-test-id="print-contents-page">
      <PrintPageHeader text={headerText} />
      <div className="wrapper">
        <div className="table-contents">
          {tableContents.map((item, i) => (
            <Fragment key={i}>
              <ContentsItem key={i} title={item.title} urlAnchor={item.urlAnchor} />
              {item.subMenu &&
                item.subMenu.map((subItem, index) => (
                  <SubmenuItem key={index} title={subItem.title} urlAnchor={subItem.urlAnchor} />
                ))}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PrintContentsPage
