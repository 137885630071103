import {
  BasePiaQuestionnaire,
  CrossBorderTransferDetails,
  DataElementsQuestionnaire,
  DataRetentionQuestionnaire,
  DataSubjectsQuestionnaire,
  DatasourceDataProperty,
  FieldProperty,
  ProcessDetailsQuestionnaire,
  RetentionPeriod,
  ReassignmentParams,
  PiaProcess,
  PiaProcessesParams,
  PiaQuestion,
  SafeguardsQuestionnaire,
  SendReminderParams,
  SpecialDataElement,
  ThirdPartyTransferDetails,
  TransfersQuestionnaire,
  UpdateCollaborationTicketParams,
  TicketStatus,
  AssociatedTicketsParams,
  UpdateCollaborationParams,
  RejectTicketParams,
  PiaProcessSettings,
  PiaReviewSchedule,
  PiaProcessReviewStatuses,
  PiaCollaboration,
  PiaAttributesParams,
  PiaTicketsParams,
  PiaTicket,
  PiaProcessOverviewParams,
  PiaProcessOverview,
  PiaReportPrint,
  PiaProcessCollaborationScope,
  PiaQuestionnaireOptionalTopics,
  PiaCollaboratorQuestion,
  PiaRiskOption,
  PiaRiskDetails,
  SubmitProcessForReviewParams,
  PiaRiskScaleTypes,
  UpdatePiaTemplateParams,
  PiaRiskOrLikelinessUpdateParams,
  PiaAssessmentReviews,
  ReassignPiaAssignmentParams,
  RevokePiaReviewParams,
  PiaProcessGroupCard,
  PiaTotalProcessesParams,
  DataSourceDataPropertyFieldTypeEnum,
  mapDataPropertyFieldTypeToStateKey
} from './piaSlice'
import {
  generateReportName,
  getProcessStats,
  getPropertyIsMandatory,
  getPropertyLabelValue,
  getTotalProcessDetailsQuestions,
  isFieldAvailable,
  isObjectId,
  isQuestionDeleted,
  mapPiaReportTopicsToQuestionnaire,
  mapQuestions,
  mapTopicsToQuestionnaire
} from './piaUtil'

import {
  LIMIT_DEFAULT,
  PrivacyFeatureType,
  ProcessFilterKeys,
  ProcessSortValues,
  SENSITIVE_LABEL
} from '../../constants'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { AttributeSet, AttributeSetAttribute } from '../attributes/attributesSlice'
import { RopaProcessSettingsParams } from '../ropa/ropaSliceV2'
import { stringEscape } from '../../utils/stringUtil'
import type { GqlConnection, GqlConnectionWithCount, Take } from '../../utils/types'
import { gql } from 'graphql-request'

const stringify = (params?) => {
  if (!params) return ''
  if (params?.length === 0) return '[]'
  return JSON.stringify(params)
}

export const FRAGMENT_QUESTIONS_INFO = gql`
  edges{
    node{
      question
      questionResponse
      processQuestionId
    }
  }
`

export const queryProcesses = ({
  id,
  page,
  pageSize = LIMIT_DEFAULT,
  filters,
  isSystemDefined
}: PiaProcessesParams) => {
  const filtersParameterized = parameterizeArrayofObjects(filters?.filter || [])
  let filterParams = ''
  if (filtersParameterized) {
    filterParams = `, filter:${filtersParameterized}`
  }
  const cursor = getAfterCursor(page || 1, pageSize)
  const queryFilters = id
    ? `id: "${id}"`
    : `booleanFilter: [{
    key: IS_SYSTEM_DEFINED,
    value: ${isSystemDefined}
  }]`
  const pageParams = page === undefined ? '' : `first: ${pageSize}, after: "${cursor}",`

  let sortQuery = `, sortField: ${ProcessSortValues.updatedAt}`
  if (filters?.sortBy) {
    sortQuery = `sortField: ${filters?.sortBy}`
    if (filters?.sortBy === ProcessSortValues.name) {
      sortQuery += ', sortByAsc: true'
    }
  }

  return gql`{
    process ( ${pageParams} ${queryFilters} ${sortQuery} ${filterParams}) {
      count
      edges {
        node {
          id
          name
          updatedAt
          createdAt
          createdBy
          usageCount
          systemTemplate
          assessmentReview
          natureOfProcess
          reviewers
          reviewedOn
          processGroupsProperties{
            key
            value
            riskOptions{
              id
              value
            }
          }
          riskDetails {
            overallRiskValue
            overallOccurrenceValue
            riskRemediation
            additionalComments
          }
          processGroups {
            edges {
              node {
                value
              }
            }
          }
          ${processSettingsFragment}
        }
      }
    }
  }`
}

export const mapQueryProcesses = (raw: any): { list: PiaProcess[]; total: number } => {
  try {
    const list: PiaProcess[] =
      raw.process?.edges?.map(({ node: process }) => {
        return {
          id: process.id,
          name: process.name,
          description: process.description || '',
          owner: process.createdBy || '',
          updatedAt: process.updatedAt || '',
          createdAt: process.createdAt || '',
          reviewedOn: process?.reviewedOn || '',
          reviewers: process?.reviewers || '',
          usageCount: process.usageCount || 0,
          systemTemplate: process.systemTemplate || false,
          assessmentReview: process.assessmentReview,
          processGroups:
            process.processGroups.edges.map((processGroup) => processGroup.node.value || '') || [],
          systemDefined: process.systemDefined || false,
          processSettings: mapProcessSettings(
            process?.processSettings?.edges[process?.processSettings?.edges?.length - 1]?.node
          ),
          processGroupsProperties: process.processGroupsProperties,
          riskDetails: process.riskDetails,
          natureOfProcess: process.natureOfProcess
        }
      }) || []
    return { list, total: raw.process.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryProcessCards = ({ id, filters, isSystemDefined }: PiaProcessesParams) => {
  const filtersParameterized = parameterizeArrayofObjects(filters?.filter || [])
  let filterParams = ''
  if (filtersParameterized) {
    filterParams = `, filter:${filtersParameterized}`
  }
  const queryFilters = id
    ? `id: "${id}"`
    : `booleanFilter: [{
    key: IS_SYSTEM_DEFINED,
    value: ${isSystemDefined}
  }]`

  const sortQuery = `, sortField: ${ProcessSortValues.name}, sortByAsc: true`

  return gql`{
    processGroup (${queryFilters} ${filterParams} ${sortQuery}) {
      count
      edges {
        node {
          count
          inReviewCount
          processGroupId
          processGroupName
        }
      }
    }
  }`
}

export const mapQueryProcessCards = (raw: any): { list: PiaProcessGroupCard[]; total: number } => {
  try {
    const list: PiaProcessGroupCard[] =
      raw.processGroup?.edges?.map(({ node: group }) => {
        return {
          id: group?.processGroupId,
          name: group?.processGroupName,
          processesTotal: group?.count,
          processesInReview: group?.inReviewCount
        }
      }) || []
    return { list, total: raw.processGroup.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryPiaRiskValues = () => {
  return gql`
    {
      riskScale(type: ${PiaRiskScaleTypes.risk}) {
        edges {
          node {
            id
            displayName
            colour
            value
            processUsageCount
            templateUsageCount
          }
        }
      }
    }
  `
}
export const mapQueryPiaRiskOptions = (raw: any): PiaRiskOption[] => {
  try {
    return (
      raw.riskScale?.edges?.map(({ node }) => {
        return {
          id: node.id,
          colour: node.colour,
          value: node.value,
          displayName: node.displayName,
          processUsageCount: node.processUsageCount,
          templateUsageCount: node.templateUsageCount
        }
      }) || []
    )
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryPiaLikelinessValues = () => {
  return gql`
    {
      riskScale(type: ${PiaRiskScaleTypes.likeliness}) {
        edges {
          node {
            id
            displayName
            colour
            value
            processUsageCount
            templateUsageCount
          }
        }
      }
    }
  `
}
export const mapQueryPiaLikelinessValues = (raw: any): PiaRiskOption[] => {
  try {
    return (
      raw.riskScale?.edges?.map(({ node }) => {
        return {
          id: node.id,
          colour: node.colour,
          value: node.value,
          displayName: node.displayName,
          processUsageCount: node.processUsageCount,
          templateUsageCount: node.templateUsageCount
        }
      }) || []
    )
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const mutationUpdatePiaRiskOrLikeliness = (params: PiaRiskOrLikelinessUpdateParams) => {
  const paramsArr = params.options
    .map(({ id = '', displayName, colour, value }) => {
      const idParam = id?.length > 6 ? `id: "${id}",` : ''
      return `{
      ${idParam}
      type: ${params.type},
      value: ${value}
      displayName: "${displayName}",
      colour: "${colour}",
    }`
    })
    .join(',')

  return gql`
    mutation {
      updateRiskScales(
        clientMutationId: "updateRiskScales"
        riskScaleData: [${paramsArr}]
      ) {
        riskScaleIds
        clientMutationId
      }
    }
  `
}
export const mutationDeletePiaRiskOrLikeliness = (ids: string[]) => {
  return gql`
    mutation {
      deleteRiskScales(
        clientMutationId: "id"
        riskScaleIds: ${JSON.stringify(ids)}
      ) { riskScaleIds }
    }
  `
}

export const queryTotalPiaProcesses = (params?: PiaTotalProcessesParams) => {
  const filterQuery = params?.owner
    ? `, filter: [{ key: ${ProcessFilterKeys.ownerOrReviewer}, values: ["${params?.owner}"] }]`
    : ''

  return gql`
    {
      process(booleanFilter: [{ key: IS_SYSTEM_DEFINED, value: false }] ${filterQuery}) {
        count
      }
    }
  `
}

export const mapQueryTotalPiaProcesses = (raw: any): number => {
  try {
    return raw.process.count || 0
  } catch (error) {
    console.error(error)
    throw error
  }
}

const transformSubmittedRiskOptions = (value) => {
  const keyValString = `[${value
    .filter((riskValue) => riskValue.value)
    .map((riskValue) => `{id: "${riskValue.id}",value: "${riskValue.value}"}`)
    .join(',')}]`
  return keyValString
}
const transformSubmittedRadioRiskOptions = (value) => {
  const keyValString = `[${value
    .map((riskValue) => {
      return riskValue.id !== undefined && (riskValue.value || riskValue.riskOptions !== undefined)
        ? `{id: "${riskValue.id}"${riskValue.value ? `,value: "${riskValue.value}"` : ''}${
            riskValue.riskOptions !== undefined ? `,riskOptions: "${riskValue.riskOptions}"` : ''
          }}`
        : ''
    })
    .filter((str) => str)
    .join(',')}]`
  return keyValString
}
const getPropertiesQuery = (properties?: FieldProperty[], isRadio?: boolean) => {
  if (!properties) return ''
  const fn = isRadio ? transformSubmittedRadioRiskOptions : transformSubmittedRiskOptions
  return properties
    .map(
      (prop) => `
    {
      key: "${prop.key}"
      value:  ${prop.value && !!prop.value.length && !!prop.value[0] ? stringify(prop.value) : '[]'}
      ${prop.riskOptions ? `riskOptions: ${fn(prop.riskOptions)}` : ''}
    }
  `
    )
    .join()
}

const getQuestionsQuery = (questions?: PiaQuestion[]) => {
  if (!questions?.length) return ''
  return questions
    ?.map(
      (question) => `
   {
      question: "${question.question}"
      options: ${stringify(question.options || [])}
      isMandatory: ${question.isMandatory}
      responseType: ${question.responseType}
      questionResponse: ${stringify(question.questionResponse || [])}
      riskAssociated: ${!!question.riskAssociated}
      ${isObjectId(question?.questionId) ? `questionId: "${question.questionId}"` : ''}
      ${question.systemRiskValue ? `systemRiskValue: "${question.systemRiskValue}"` : ''}
      ${question.overallRiskValue ? `overallRiskValue: "${question.overallRiskValue}"` : ''}
      ${
        question.riskOptions?.length
          ? `riskOptions: ${transformSubmittedRiskOptions(question.riskOptions || [])}`
          : ''
      }
   }
  `
    )
    .join()
}

const getCustomTopicQuery = (topic: BasePiaQuestionnaire) => {
  return `
    {
      ${isObjectId(topic?.id) ? `id: "${topic.id}"` : ''}
      name: "${stringEscape(topic.name || '')}"
      optional: ${!!topic?.optional}
      description: ${stringify(topic.description || '')}
      ${
        topic.questionIds
          ? ` questionIds: ${stringify(topic.questionIds)}`
          : ` questions: [${getQuestionsQuery(topic.questions)}]`
      }
    }
  `
}

const getProcessDetailsPropertiesFragment = (processDetails?: ProcessDetailsQuestionnaire) => {
  if (!processDetails) return ''
  return `
    nameProperties: [${getPropertiesQuery(processDetails.nameProperties)}]
    descriptionProperties: [${getPropertiesQuery(processDetails.descriptionProperties)}]
    processGroupsProperties: [${getPropertiesQuery(processDetails.processGroupsProperties)}]
    automatedProcessingProperties: [${getPropertiesQuery(
      processDetails.automatedProcessingProperties
    )}]
    automatedProcessingDescriptionProperties: [${getPropertiesQuery(
      processDetails.automatedProcessingDescriptionProperties,
      true
    )}]
    purposeProperties: [${getPropertiesQuery(processDetails.purposeProperties)}]
    lawfulBasisProperties: [${getPropertiesQuery(processDetails.lawfulBasisProperties)}]
    processDetailsProperties: [${getPropertiesQuery(processDetails.processDetailsProperties)}]
  `
}

const getSpeicalDataElementsFragment = (specialDataElements?: SpecialDataElement[]) => {
  if (!specialDataElements) return ''
  return specialDataElements
    .map(
      (el) => `
    {
      attributeName: "${el.attributeName}"
      sensitivity: "${el.sensitivity}"
    }
  `
    )
    .join()
}

const getDataElementsPropertiesFragment = (
  dataElements?: DataElementsQuestionnaire,
  lawfulBasisProperties?: FieldProperty[]
) => {
  if (!dataElements) return ''
  return `dataElements: {
    ${dataElements.id ? `id: "${dataElements.id}"` : ''}
    name: "${stringEscape(dataElements.name || '')}"
    description: ${stringify(dataElements.description || '')}
    optional: ${!!dataElements.optional}
    questions: [${getQuestionsQuery(dataElements?.questions)}]
    attributeIds: ${stringify(dataElements?.attributes || [])}
    specialElements: [${getSpeicalDataElementsFragment(dataElements?.specialElements)}]
    associatedDatasourceIds: ${stringify(dataElements?.associatedDatasources || [])}
    lawfulBasisIds: ${stringify(dataElements.lawfulBases || [])}
    attributeProperties: [${getPropertiesQuery(dataElements.attributeProperties)}]
    specialElementProperties: [${getPropertiesQuery(dataElements.specialElementProperties)}]
    lawfulBasisProperties: [${getPropertiesQuery(lawfulBasisProperties)}]
  }`
}
const getDataElementsPropertiesCollaboratorFragment = (
  dataElements?: DataElementsQuestionnaire
) => {
  if (!dataElements) return ''
  return `dataElements: {
    id: "${dataElements.id}"
    name: "${stringEscape(dataElements.name || '')}"
    description: ${stringify(dataElements.description || '')}
    optional: ${!!dataElements.optional}
    questions: [${getQuestionsQuery(dataElements?.questions)}]
    attributeIds: ${stringify(dataElements?.attributes || [])}
    attributeProperties: [${getPropertiesQuery(dataElements.attributeProperties)}]
    specialElements: [${getSpeicalDataElementsFragment(dataElements?.specialElements)}]
    specialElementProperties: [${getPropertiesQuery(dataElements.specialElementProperties)}]
    associatedDatasourceIds: ${stringify(dataElements?.associatedDatasources || [])}
    lawfulBasisIds: ${stringify(dataElements.lawfulBases || [])}
    lawfulBasisProperties: [${getPropertiesQuery(dataElements.lawfulBasisProperties)}]
    questionIds: ${stringify(dataElements?.questionIds || [])}
  }`
}
const getDataSubjectsPropertiesFragment = (dataSubjects?: DataSubjectsQuestionnaire) => {
  if (!dataSubjects) return ''
  return `dataSubjects: {
    ${dataSubjects.id ? `id: "${dataSubjects.id}"` : ''}
    name: "${stringEscape(dataSubjects.name || '')}"
    description: ${stringify(dataSubjects.description || '')}
    optional: ${!!dataSubjects.optional}
    questions: [${getQuestionsQuery(dataSubjects.questions)}]
    dataSubjectProperties: [${getPropertiesQuery(dataSubjects.dataSubjectProperties, true)}]
    specialDataSubjectProperties: [${getPropertiesQuery(dataSubjects.specialDataSubjectProperties)}]
    specialDataSubjectsProperties: [${getPropertiesQuery(
      dataSubjects.specialDataSubjectsProperties
    )}]
    legalBasisProperties: [${getPropertiesQuery(dataSubjects.legalBasisProperties)}]
    dataSubjectIds: ${stringify(dataSubjects.categories || [])}
    specialDataSubjectIds: ${stringify(dataSubjects.specialCategories || [])}
    specialDataSubject: ${
      dataSubjects.specialDataSubject !== undefined ? dataSubjects.specialDataSubject : false
    }
    legalBasisIds: ${stringify(dataSubjects.lawfulBasis || [])}
  }`
}

const getDataSubjectsPropertiesCollaboratorFragment = (
  dataSubjects?: DataSubjectsQuestionnaire
) => {
  if (!dataSubjects) return ''
  return `dataSubjects: {
    id: "${dataSubjects.id}"
    name: "${stringEscape(dataSubjects.name || '')}"
    description: ${stringify(dataSubjects.description || '')}
    optional: ${!!dataSubjects.optional}
    questions: [${getQuestionsQuery(dataSubjects.questions)}]
    specialDataSubjectIds: []
    legalBasisIds: []
    dataSubjectIds: []
    dataSubjectProperties: [${getPropertiesQuery(dataSubjects.dataSubjectProperties)}]
    specialDataSubjectProperties: [${getPropertiesQuery(dataSubjects.specialDataSubjectProperties)}]
    specialDataSubjectsProperties: [${getPropertiesQuery(
      dataSubjects.specialDataSubjectsProperties
    )}]
    legalBasisProperties: [${getPropertiesQuery(dataSubjects.legalBasisProperties)}]
    dataSubjectIds: ${stringify(dataSubjects.categories || [])}
    specialDataSubjectIds: ${stringify(dataSubjects.specialCategories || [])}
    specialDataSubject: ${
      dataSubjects.specialDataSubject !== undefined ? dataSubjects.specialDataSubject : false
    }
    questionIds: ${stringify(dataSubjects?.questionIds || [])}
  }`
}

const getDataRetentionPropertiesFragment = (dataRetention?: DataRetentionQuestionnaire) => {
  if (!dataRetention) return ''
  return `dataRetention: {
    ${dataRetention.id ? `id: "${dataRetention.id}"` : ''}
    name: "${stringEscape(dataRetention.name || '')}"
    description: ${stringify(dataRetention.description || '')}
    optional: ${!!dataRetention.optional}
    questions: [${getQuestionsQuery(dataRetention.questions)}]
    dataRetentionIds: ${stringify(dataRetention.details?.map((detail) => detail.id) || [])}
    dataRetentionProperties: [${getPropertiesQuery(dataRetention.dataRetentionProperties)}]
    attributeSetProperties: [${getPropertiesQuery(dataRetention.attributeSetProperties)}]
    durationTypeProperties: [${getPropertiesQuery(dataRetention.durationTypeProperties)}]
    triggerEventProperties: [${getPropertiesQuery(dataRetention.triggerEventProperties)}]
  }`
}

const getDataRetentionPropertiesCollaboratorFragment = (
  dataRetention?: DataRetentionQuestionnaire
) => {
  if (!dataRetention) return ''
  return `dataRetention: {
    id: "${dataRetention.id}"
    name: "${stringEscape(dataRetention.name || '')}"
    description: ${stringify(dataRetention.description || '')}
    optional: ${!!dataRetention.optional}
    questions: [${getQuestionsQuery(dataRetention.questions)}]
    dataRetentionIds: []
    dataRetentionProperties: [${getPropertiesQuery(dataRetention.dataRetentionProperties)}]
    attributeSetProperties: [${getPropertiesQuery(dataRetention.attributeSetProperties)}]
    durationTypeProperties: [${getPropertiesQuery(dataRetention.durationTypeProperties)}]
    triggerEventProperties: [${getPropertiesQuery(dataRetention.triggerEventProperties)}]
    questionIds: ${stringify(dataRetention?.questionIds || [])}
  }`
}

const getSafeguardsPropertiesFragment = (safeguards?: SafeguardsQuestionnaire) => {
  if (!safeguards) return ''
  return `safeguards: {
    ${safeguards.id ? `id: "${safeguards.id}"` : ''}
    name: "${stringEscape(safeguards.name || '')}"
    description: ${stringify(safeguards.description || '')}
    optional: ${!!safeguards.optional}
    questions: [${getQuestionsQuery(safeguards.questions)}]
    safeguardProperties: [${getPropertiesQuery(safeguards.safeguardProperties)}]
    safeguardIds: ${stringify(safeguards.safeguards || [])}
  }`
}

const getSafeguardsPropertiesCollaboratorFragment = (safeguards?: SafeguardsQuestionnaire) => {
  if (!safeguards) return ''
  return `safeguards: {
    id: "${safeguards.id}"
    name: "${stringEscape(safeguards.name || '')}"
    description: ${stringify(safeguards.description || '')}
    optional: ${!!safeguards.optional}
    questions: [${getQuestionsQuery(safeguards.questions)}]
    safeguardIds: []
    safeguardProperties: [${getPropertiesQuery(safeguards.safeguardProperties)}]
    questionIds: ${stringify(safeguards?.questionIds || [])}
  }`
}

const getThirdPartyTransferDetailsFragment = (transfers: ThirdPartyTransferDetails[]) => {
  if (!transfers) return ``
  return transfers
    .map(
      (transfer) => `
    {
      name: "${transfer.name}"
      address: "${transfer.address}"
      email: "${transfer.email}"
      safeguards: ${stringify(transfer.safeguards || [])}
      nameProperties: [${getPropertiesQuery(transfer?.nameProperties)}]
      addressProperties: [${getPropertiesQuery(transfer?.addressProperties)}]
      emailProperties: [${getPropertiesQuery(transfer?.emailProperties)}]
      safeguardsProperties: [${getPropertiesQuery(transfer?.safeguardsProperties)}]
    }
  `
    )
    .join()
}

const getCrossBorderTransferDetailsFragment = (transfers: CrossBorderTransferDetails[]) => {
  if (!transfers) return ``
  return transfers
    .map(
      (transfer) => `
    {
      organisation: "${transfer.organisation}"
      country: "${transfer.country}"
      email: "${transfer.email}"
      safeguards: ${stringify(transfer.safeguards || [])}
      organisationProperties: [${getPropertiesQuery(transfer?.organisationProperties)}]
      countryProperties: [${getPropertiesQuery(transfer?.countryProperties)}]
      emailProperties: [${getPropertiesQuery(transfer?.emailProperties)}]
      safeguardsProperties: [${getPropertiesQuery(transfer?.safeguardsProperties)}]
    }
  `
    )
    .join()
}

const getTransfersPropertiesFragment = (transfers?: TransfersQuestionnaire) => {
  if (!transfers) return ''
  return `transfers: {
    ${transfers.id ? `id: "${transfers.id}"` : ''}
    name: "${stringEscape(transfers.name || '')}"
    description: ${stringify(transfers.description || '')}
    optional: ${!!transfers.optional}
    questions: [${getQuestionsQuery(transfers.questions)}]
    thirdParty: {
      thirdPartyProperties: [${getPropertiesQuery(
        transfers.thirdParty?.thirdPartyProperties,
        true
      )}]
      isThirdParty: ${
        transfers.thirdParty?.isThirdParty !== undefined
          ? transfers.thirdParty?.isThirdParty
          : false
      }
      details:  [${getThirdPartyTransferDetailsFragment(transfers.thirdParty.details)}]
    }
    crossBorder: {
      crossBorderProperties: [${getPropertiesQuery(
        transfers.crossBorder?.crossBorderProperties,
        true
      )}]
      isCrossBorder: ${
        transfers.crossBorder?.isCrossBorder !== undefined
          ? transfers.crossBorder?.isCrossBorder
          : false
      }
      details:  [${getCrossBorderTransferDetailsFragment(transfers.crossBorder.details)}]
    }
  }`
}

const getTransfersPropertiesCollaboratorFragment = (transfers?: TransfersQuestionnaire) => {
  if (!transfers) return ''
  return `transfers: {
    id: "${transfers.id}"
    name: "${stringEscape(transfers.name || '')}"
    description: ${stringify(transfers.description || '')}
    optional: ${!!transfers.optional}
    questions: [${getQuestionsQuery(transfers.questions)}]
    thirdParty: {
      thirdPartyProperties: [${getPropertiesQuery(transfers.thirdParty?.thirdPartyProperties)}]
      isThirdParty: ${
        transfers.thirdParty?.isThirdParty !== undefined
          ? transfers.thirdParty?.isThirdParty
          : false
      }
      details:  [${getThirdPartyTransferDetailsFragment(transfers.thirdParty.details)}]
    }
    crossBorder: {
      crossBorderProperties: [${getPropertiesQuery(transfers.crossBorder?.crossBorderProperties)}]
      isCrossBorder: ${
        transfers.crossBorder?.isCrossBorder !== undefined
          ? transfers.crossBorder?.isCrossBorder
          : false
      }
      details:  [${getCrossBorderTransferDetailsFragment(transfers.crossBorder.details)}]
    }
    questionIds: ${stringify(transfers?.questionIds || [])}
  }`
}

const getProcessActivityDetailsFragment = (process: PiaProcess) => {
  if (!process) return ''
  return process.processDetails?.processingActivityDetails
    ?.map(
      (details) => `
   {
      managerType: ${details.managerType}
      details: [${details.details
        .map(
          (detail) => `{
        name: "${detail.name}",
        email: "${detail.email}"
        contact: "${detail.contact}"
        address: "${detail.address}"
        roleType: ${detail.roleType}
        nameProperties: [${getPropertiesQuery(
          process.questionnaire?.processDetails?.processDetailsQuestionProperties.nameProperties
        )}]
        emailProperties: [${getPropertiesQuery(
          process.questionnaire?.processDetails?.processDetailsQuestionProperties.emailProperties
        )}]
        contactProperties: [${getPropertiesQuery(
          process.questionnaire?.processDetails?.processDetailsQuestionProperties.contactProperties
        )}]
        addressProperties: [${getPropertiesQuery(
          process.questionnaire?.processDetails?.processDetailsQuestionProperties.addressProperties
        )}]
      }`
        )
        .join()}]
   }
  `
    )
    .join()
}

const getCustomTopicFragment = (customTopics?: BasePiaQuestionnaire[]) => {
  if (!customTopics || customTopics.length === 0) return ''
  const topicsQuery = customTopics.map((topic) => getCustomTopicQuery(topic)).join()
  return `
    topics: [${topicsQuery}]
  `
}

export const getQuestionnaireFragment = (params: {
  process: PiaProcess
  isTemplate?: boolean
  isCollaborator?: boolean
}) => {
  const { process, isTemplate = false } = params
  // create lawful basis properties from process details while creating template
  const dataElementsPropertiesQuery = getDataElementsPropertiesFragment(
    process.questionnaire?.dataElements,
    isTemplate
      ? process.questionnaire?.processDetails?.lawfulBasisProperties
      : process.questionnaire?.dataElements?.lawfulBasisProperties
  )
  const dataSubjectsPropertiesQuery = getDataSubjectsPropertiesFragment(
    process.questionnaire?.dataSubjects
  )
  const dataRetentionPropertiesQuery = getDataRetentionPropertiesFragment(
    process.questionnaire?.dataRetention
  )
  const safeguardsPropertiesQuery = getSafeguardsPropertiesFragment(
    process.questionnaire?.safeguards
  )
  const transfersPropertiesQuery = getTransfersPropertiesFragment(process.questionnaire?.transfers)

  const customTopicsQuery = getCustomTopicFragment(process.questionnaire?.customTopics)
  return gql`
    ${dataElementsPropertiesQuery}
    ${dataSubjectsPropertiesQuery}
    ${dataRetentionPropertiesQuery}
    ${safeguardsPropertiesQuery}
    ${transfersPropertiesQuery}
    ${customTopicsQuery}
  `
}

const getRiskDetailsFragment = (details?: PiaRiskDetails) => {
  return `
  ${details?.overallRiskValue ? `overallRiskValue: "${details?.overallRiskValue}"` : ''}
  ${
    details?.overallOccurrenceValue
      ? `overallOccurrenceValue: "${details?.overallOccurrenceValue}"`
      : ''
  }
  riskRemediation: "${stringEscape(details?.riskRemediation || '')}"
  additionalComments: "${stringEscape(details?.additionalComments || '')}"
  `
}

const getCreateOrUpdateProcessOrTemplateFragment = (
  process: PiaProcess,
  isCloneProcess: boolean
) => {
  const processDetailsPropertiesQuery = getProcessDetailsPropertiesFragment(
    process.questionnaire?.processDetails
  )
  const riskDetails = process?.systemDefined ? '' : getRiskDetailsFragment(process.riskDetails)

  return `
      systemDefined: ${process.systemDefined}
      name: "${stringEscape(process.name || '')}"
      owner: "${process.owner}"
      description: "${stringEscape(process.description || '')}"
      ${isCloneProcess && process.templateId ? `templateId: "${process.templateId}"` : ''}
      ${isCloneProcess ? `assessmentReview: ${PiaAssessmentReviews.notSubmiitedForReview}` : ''}
      dpoEmail: "${process.dpoEmail}"
      automatedProcessing: ${process.automatedProcessing}
      automatedProcessingDescription: "${stringEscape(
        process.automatedProcessingDescription || ''
      )}"
      purposeIds: ${stringify(process.purpose)}
      lawfulBasisIds: ${stringify(process.lawfulBasis || [])}
      processGroupIds: ${stringify(process.processGroups || [])}
      ${processDetailsPropertiesQuery}
      controllerQuestions: [${[
        getQuestionsQuery(process.questionnaire?.processDetails?.controllerQuestions)
      ]}]
      processQuestions: [${[
        getQuestionsQuery(process.questionnaire?.processDetails?.processQuestions)
      ]}]
      processDetails: {
        processingActivityDetails: [${getProcessActivityDetailsFragment(process)}]
      }
      questionnaire: {
        ${getQuestionnaireFragment({ process, isTemplate: !!process.systemDefined })}
      }
      ${riskDetails ? `riskDetails: {${riskDetails}}` : ''}

  `
}

export const queryCreatePiaTemplate = (process: PiaProcess, isCloneProcess: boolean) => {
  const createMutation = isCloneProcess ? 'createProcessClone' : 'createProcessTemplate'
  return gql`
    mutation {
      ${createMutation}(
        clientMutationId: "clientMutationId",
        processData: {
          type: PIA
         ${getCreateOrUpdateProcessOrTemplateFragment(process, isCloneProcess)}
        }
      ) {
        clientMutationId
        ropaProcessId
      }
    }
  `
}

export const queryUpdatePiaTemplate = ({
  template: process,
  status,
  notUpdatedAfterReview
}: UpdatePiaTemplateParams) => {
  return gql`
    mutation {
      updateProcess(
        clientMutationId: "clientMutationId",
        ropaProcessId: "${process.id}"
        processData: {
          templateId: "${process.templateId}"
        ${status ? `assessmentReview: ${status}` : ''}
        ${notUpdatedAfterReview ? `notUpdatedAfterReview: ${notUpdatedAfterReview}` : ''}
        ${getCreateOrUpdateProcessOrTemplateFragment(process, false)}
        }
      ) {
        clientMutationId
        ropaProcessId
      }
    }
  `
}

export const queryCreatePiaProcess = (process: PiaProcess) => {
  return gql`
    mutation {
      createProcess(
        clientMutationId: "clientMutationId",
        processData: {
          type: PIA
          natureOfProcess: ${process.natureOfProcess}
          templateId: "${process.templateId}"
          name: "${process.name}"
          owner: "${process.owner}"
          dpoEmail: "${process.dpoEmail}"
        }
      ) {
        clientMutationId
        processId
      }
    }
  `
}

export const queryUpdateCollaborationTicket = ({
  process,
  status
}: UpdateCollaborationTicketParams) => {
  return gql`
    mutation {
      ropaSendCollaborationResponse(
        clientMutationId: "clientMutationId",
        collaboratorId: "${process.ticketId}"
        responseData: {
          status: ${status}
          ropaTopics: {
            ${getQuestionnaireFragment({ process })}
          }
        }
      ) {
        clientMutationId
      }
    }
  `
}

const getDatasourceDataPropertiesFragment = (dataProperties?: DatasourceDataProperty[]) => {
  if (!dataProperties || !dataProperties.length) return ''
  return dataProperties
    .filter((prop) => !!prop.value.trim())
    .map(
      (property) => `
  {
    fieldType: ${property.fieldType}
    value: "${property.value}"
  }
  `
    )
    .join()
}
export const queryBulkCreateOrUpdateDatasourceDataProperty = (
  dataProperties: DatasourceDataProperty[]
) => {
  return gql`
    mutation {
      bulkCreateUpdateDatasourceDataProperty(
        clientMutationId: "clientMutationId",
        datasourceDataPropertyFieldData: [${getDatasourceDataPropertiesFragment(dataProperties)}]
      ) {
        clientMutationId
        datasourceDataPropertyFieldIds
      }
    }
  `
}

export const mapBulkCreateOrUpdateDatasourceDataProperty = (raw: any, fieldType?) => {
  const { datasourceDataPropertyFieldIds } = raw.bulkCreateUpdateDatasourceDataProperty
  return { ids: datasourceDataPropertyFieldIds, fieldType }
}

export const queryCreateRetentionPeriod = (period: RetentionPeriod) => {
  return gql`
    mutation {
      createPiaRetentionPeriod(
        clientMutationId: "clientMutationId",
        retentionPeriodData: {
          durationCount: ${period.durationCount}
          durationType: "${period.durationType}"
          triggerEvent: "${period.triggerEvent}"
          attributeSetIds: ${stringify(period.attributeSets || [])}
        }
      ) {
        clientMutationId
        retentionPeriod {
          edges {
            node {
              id
              durationType
              durationCount
              triggerEvent
              attributeSet {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapRetentionPeriod = (raw: any) => {
  const {
    retentionPeriod: { edges }
  } = raw.createPiaRetentionPeriod

  const { node = {} } = edges?.[0]
  const { id, durationCount, durationType, attributeSet = [], triggerEvent } = node
  return {
    id,
    durationCount,
    durationType: durationType,
    triggerEvent,
    attributeSets: attributeSet?.edges?.map(({ node }) => node?.id) || []
  }
}

export const mutationUpdateTicketsStatus = ({
  ids,
  status
}: {
  ids: string[]
  status: TicketStatus
}) => {
  return gql`
    mutation{
     ropaUpdateCollaboratorStatus(
      clientMutationId:"clientMutationId",
            requestData:{
              collaboratorIds: ${stringify(ids)}
              status: ${status}
            }
            ){
              clientMutationId
            }
          }
  `
}

export const FRAGMENT_TOPIC_QUESTION = gql`questions {
  edges {
    node {
      question
      questionId
      questionResponse
      processQuestionId
      responseType
      isMandatory
      options
      riskAssociated
      systemRiskValue
      overallRiskValue
      riskOptions{
        id
        value
      }
    }
  }
}`

const fieldPropertyFragment = gql`
  {
    key
    value
    riskOptions {
      id
      value
    }
  }
`

const dataElementsFragment = gql`
  dataElements {
    optional
    name
    id
    description
    ${FRAGMENT_TOPIC_QUESTION}
    attributes {
      count
      edges {
        node {
          id
          name
          sensitivityLabel
          attributeSets{
            edges{
              node{
                name
                enabled
              }
            }
          }
        }
      }
    }
    attributeProperties ${fieldPropertyFragment}
    specialElements {
      attributeName
      sensitivity
    }
    specialElementProperties ${fieldPropertyFragment}
    lawfulBases {
      count
      edges {
        node {
          id
          value
          isDeleted
        }
      }
    }
    lawfulBasisProperties ${fieldPropertyFragment}
    associatedDatasources {
      count
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

const dataSubjectsFragment = gql`
  dataSubjects {
    optional
    name
    id
    description
    ${FRAGMENT_TOPIC_QUESTION}
    dataSubjects {
      count
      edges {
        node {
          id
          value
          isDeleted
        }
      }
    }
    dataSubjectProperties ${fieldPropertyFragment}
    specialDataSubject
    specialDataSubjectProperties ${fieldPropertyFragment}
    specialDataSubjects {
      count
      edges {
        node {
          id
          value
          isDeleted
        }
      }
    }
    specialDataSubjectsProperties ${fieldPropertyFragment}
    legalBases {
      count
      edges {
        node {
          id
          value
          isDeleted
        }
      }
    }
    legalBasisProperties ${fieldPropertyFragment}
  }
`
const dataRetentionFragment = gql`
  dataRetention {
    optional
    name
    id
    description
    ${FRAGMENT_TOPIC_QUESTION}
    dataRetentionProperties ${fieldPropertyFragment}
    durationTypeProperties ${fieldPropertyFragment}
    attributeSetProperties ${fieldPropertyFragment}
    triggerEventProperties ${fieldPropertyFragment}
    dataRetentionInfo {
      count
      edges {
        node {
          id
          durationType
          triggerEvent
          durationCount
          attributeSet {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

const dataSafeguardsFragment = gql`
  safeguards {
    optional
    name
    id
    description
    ${FRAGMENT_TOPIC_QUESTION}
    safeguards {
      count
      edges {
        node {
          id
          value
          isDeleted
        }
      }
    }
    safeguardProperties ${fieldPropertyFragment}
  }
`

const transfersFragment = gql`
  transfers {
    ${FRAGMENT_TOPIC_QUESTION}
    optional
    name
    id
    description
    crossBorder {
      isCrossBorder
      details {
        country
        countryProperties ${fieldPropertyFragment}
        organisation
        organisationProperties ${fieldPropertyFragment}
        email
        emailProperties ${fieldPropertyFragment}
        safeguards {
          count
          edges {
            node {
              id
              value
            }
          }
        }
        safeguardsProperties ${fieldPropertyFragment}
      }
      crossBorderProperties ${fieldPropertyFragment}
    }
    thirdParty {
      isThirdParty
      details {
        name
        nameProperties ${fieldPropertyFragment}
        address
        addressProperties ${fieldPropertyFragment}
        email
        emailProperties ${fieldPropertyFragment}
        safeguards {
          count
          edges {
            node {
              id
              value
            }
          }
        }
        safeguardsProperties ${fieldPropertyFragment}
      }
      thirdPartyProperties ${fieldPropertyFragment}
    }
  }
`

const customTopicsFragment = gql`
  customTopics {
    id
    name
    description
    optional
    ${FRAGMENT_TOPIC_QUESTION}
  }

`

const processSettingsFragment = gql`
  processSettings{
    edges{
      node{
        id
        reviewDuration
        reviewDueDate
        reviewStatus
        reviewFrequency
      }
    }
  }
`

const FRAGMENT_PROCESS_BODY = gql`
    id
    templateId
    owner
    name
    assessmentReview
    reviewers
    isUpdatedAfterReview
    nameProperties ${fieldPropertyFragment}
    description
    descriptionProperties ${fieldPropertyFragment}
    systemDefined
    systemTemplate
    status
    updatedAt
    reviewedOn
    natureOfProcess
    processQuestions {
      edges {
        node {
          question
          questionId
          options
          responseType
          isMandatory
          questionResponse
          riskAssociated
          systemRiskValue
          overallRiskValue
          riskOptions{
            id
            value
          }
        }
      }
    }
    ${processSettingsFragment}
    controllerQuestions {
      edges {
        node {
          question
          questionId
          options
          responseType
          isMandatory
          questionResponse
        }
      }
    }
    processDetails {
      creatorRole
      creatorRoleProperties ${fieldPropertyFragment}
      processingActivityDetails {
        managerType
        details {
          name
          nameProperties ${fieldPropertyFragment}
          email
          emailProperties ${fieldPropertyFragment}
          address
          addressProperties ${fieldPropertyFragment}
          contact
          contactProperties ${fieldPropertyFragment}
          roleType
        }
      }
    }
    processDetailsProperties ${fieldPropertyFragment}
    purpose {
      count
      edges {
        node {
          id
          value
          isDeleted
        }
      }
    }
    purposeProperties ${fieldPropertyFragment}
    lawfulBasis {
      count
      edges {
        node {
          id
          value
          isDeleted
        }
      }
    }
    lawfulBasisProperties ${fieldPropertyFragment}
    processGroups {
      count
      edges {
        node {
          id
          value
          isDeleted
        }
      }
    }
    processGroupsProperties ${fieldPropertyFragment}
    automatedProcessing
    automatedProcessingProperties ${fieldPropertyFragment}
    automatedProcessingDescription
    automatedProcessingDescriptionProperties ${fieldPropertyFragment}
    dpoEmail
    riskDetails {
      overallRiskValue
      overallOccurrenceValue
      riskRemediation
      additionalComments
    }
    ropaTopics {
      ${dataElementsFragment}
      ${dataSubjectsFragment}
      ${dataRetentionFragment}
      ${dataSafeguardsFragment}
      ${transfersFragment}
      ${customTopicsFragment}
    }
  `

const mapProcessSettings = (processSettings): PiaProcessSettings | undefined => {
  if (!processSettings) return

  const { id, reviewDueDate, reviewDuration, reviewStatus, reviewFrequency } = processSettings

  return {
    id,
    reviewDueDate,
    reviewDuration,
    reviewStatus,
    reviewFrequency,
    isDueDatePast: reviewStatus === PiaProcessReviewStatuses.reviewPastDue,
    isDueDateWarning: reviewStatus === PiaProcessReviewStatuses.reviewDue
  }
}

export const queryTicketsForProcessOrTicket = ({ id, isProcess }: AssociatedTicketsParams) => {
  const filter = `filter: [{key:TYPE,values:["${PrivacyFeatureType.pia}"]},{key: ${
    isProcess ? 'ROPA_PROCESS_ID' : 'PARENT_COLLABORATOR_ID'
  }, values: ["${id}"]}]`
  return gql`{
    ropaCollaboration(sortField: CREATED_AT, ${filter}) {
      count
      edges {
        node {
          id
          emailId
          status
          name
          parentCollaboratorId
          createdAt
          updateTimestamp
          ropaTopics {
            ${dataElementsFragment}
            ${dataSubjectsFragment}
            ${dataRetentionFragment}
            ${dataSafeguardsFragment}
            ${transfersFragment}
            ${customTopicsFragment}
          }
        }
      }
    }
  }`
}

export const queryProcessOrTemplateById = (id: string) => {
  return gql`
    {
      process (id: "${id}") {
        edges {
          node {
            ${FRAGMENT_PROCESS_BODY}
          }
        }
      }
    }
  `
}

export const mapQueryProcessOrTemplateById = (raw: any, details?: any): PiaProcess => {
  const {
    process: { edges }
  } = raw

  const { node = {} } = edges?.[0]

  const process: PiaProcess = {
    id: node?.id,
    owner: details?.owner || node?.owner,
    templateId: node?.templateId || node?.id,
    dpoEmail: details?.dpoEmail || node?.dpoEmail,
    systemDefined: node.systemDefined,
    systemTemplate: node.systemTemplate,
    name: details?.name || node?.name,
    description: details?.description ?? node?.description,
    purpose: node?.purpose.edges?.map(({ node }) => node?.id) || [],
    lawfulBasis: node?.lawfulBasis.edges?.map(({ node }) => node?.id) || [],
    processGroups: node?.processGroups.edges?.map(({ node }) => node?.id) || [],
    automatedProcessing: node?.automatedProcessing,
    automatedProcessingDescription: node?.automatedProcessingDescription,
    assessmentReview: node?.assessmentReview,
    isUpdatedAfterReview: node?.isUpdatedAfterReview,
    updatedAt: node?.updatedAt,
    reviewedOn: node?.reviewedOn,
    reviewers: node?.reviewers,
    natureOfProcess: node?.natureOfProcess,
    riskDetails: node?.riskDetails,
    processDetails: {
      processingActivityDetails: node?.processDetails?.processingActivityDetails?.map(
        (activityDetails) => ({
          managerType: activityDetails?.managerType,
          details: activityDetails?.details?.map(({ name, email, contact, address, roleType }) => ({
            name,
            email,
            contact,
            address,
            roleType
          }))
        })
      ) || [
        {
          managerType: 'CONTROLLER',
          details: []
        }
      ]
    },
    processSettings: mapProcessSettings(
      node?.processSettings?.edges[node?.processSettings?.edges?.length - 1]?.node
    ),
    questionnaire: {
      processDetails: {
        nameProperties: node?.nameProperties,
        descriptionProperties: node?.descriptionProperties,
        processGroupsProperties: node?.processGroupsProperties,
        automatedProcessingProperties: node?.automatedProcessingProperties,
        automatedProcessingDescriptionProperties: node?.automatedProcessingDescriptionProperties,
        purposeProperties: node?.purposeProperties,
        lawfulBasisProperties: node?.lawfulBasisProperties,
        processDetailsProperties: node?.processDetailsProperties,
        optional: false,
        controllerQuestions: node?.controllerQuestions?.edges?.map((edge) => edge?.node) || [],
        processQuestions: node?.processQuestions?.edges?.map((edge) => edge?.node) || [],
        processDetailsQuestionProperties: {
          nameProperties:
            node?.processDetails?.processingActivityDetails?.[0]?.details?.[0]?.nameProperties,
          addressProperties:
            node?.processDetails?.processingActivityDetails?.[0]?.details?.[0]?.addressProperties,
          emailProperties:
            node?.processDetails?.processingActivityDetails?.[0]?.details?.[0]?.emailProperties,
          contactProperties:
            node?.processDetails?.processingActivityDetails?.[0]?.details?.[0]?.contactProperties
        }
      },
      customTopics: node?.ropaTopics?.customTopics?.map((topic) => ({
        id: topic?.id || '',
        name: topic?.name || '',
        description: topic?.description || '',
        optional: topic?.optional,
        questions: topic?.questions?.edges?.map((edge) => edge?.node) || []
      }))
    }
  }
  const { totalQuestions, totalQuestionsAnswered } = getTotalProcessDetailsQuestions(process)
  if (process?.questionnaire?.processDetails) {
    process.questionnaire.processDetails.totalQuestions = totalQuestions
    process.questionnaire.processDetails.answeredQuestions = totalQuestionsAnswered
  }

  const ownerProcessResponse = mapTopicsToQuestionnaire(process, node)
  return ownerProcessResponse
}

export const queryCollaboratorTicketDetailsById = (id: string) => {
  return gql`{
    submittedTickets: ropaCollaboration(filter:[{key:TYPE,values:["${PrivacyFeatureType.pia}"]},{key: PARENT_COLLABORATOR_ID, values: ["${id}"]}]) {
      count
      edges {
        node {
          id
          emailId
          status
          name
          createdAt
          updateTimestamp
          ropaTopics {
            ${dataElementsFragment}
            ${dataSubjectsFragment}
            ${dataRetentionFragment}
            ${dataSafeguardsFragment}
            ${transfersFragment}
            ${customTopicsFragment}
          }
        }
      }
    }
    ropaCollaboration(filter:[{key:TYPE,values:["${PrivacyFeatureType.pia}"]}],id: "${id}") {
      edges {
        node {
          id
          name
          status
          emailId
          parentCollaboratorId
          ropaProcess {
            edges {
              node {
                name
                dpoEmail
                owner
                id
                templateId
                assessmentReview
                processGroups {
                  edges {
                    node {
                      id
                      value
                      isDeleted
                    }
                  }
                }
              }
            }
          }
          ropaTopics {
            ${dataElementsFragment}
            ${dataSubjectsFragment}
            ${dataRetentionFragment}
            ${dataSafeguardsFragment}
            ${transfersFragment}
            ${customTopicsFragment}
          }
        }
      }
    }
  }`
}

export const mapQueryCollaboratorTicketDetailsById = (raw: any): PiaProcess => {
  const {
    ropaCollaboration: { edges }
  } = raw
  const { node = {} } = edges?.[0]
  const {
    ropaProcess: { edges: processEdges }
  } = node
  const { node: processNode = {} } = processEdges?.[0]
  const process: PiaProcess = {
    id: processNode?.id,
    assessmentReview: processNode?.assessmentReview,
    ticketId: node?.id,
    parentCollaboratorId: node?.parentCollaboratorId,
    owner: processNode?.owner,
    dpoEmail: processNode?.dpoEmail,
    assignedTo: node?.emailId,
    name: processNode?.name,
    templateId: processNode?.templateId,
    processGroups: processNode?.processGroups.edges?.map(({ node }) => node?.id) || [],
    status: node?.status,
    questionnaire: {
      customTopics: node?.ropaTopics?.customTopics?.map((topic) => ({
        id: topic?.id || '',
        name: topic?.name || '',
        description: topic?.description || '',
        optional: topic?.optional,
        processQuestionId: topic?.processQuestionId || '',
        questions: topic?.questions?.edges?.map((edge) => edge?.node) || []
      }))
    }
  }
  const ownerProcessResponse = mapTopicsToQuestionnaire(process, node)
  return ownerProcessResponse
}

export const queryPiaListValues = (fieldTypes: Array<DataSourceDataPropertyFieldTypeEnum>) => {
  return gql`
    {
      datasourceDataProperty(fieldTypes: [${fieldTypes.join(',')}]) {
        edges {
          node {
            id
            value
            fieldType
          }
        }
      }
    }
  `
}

export const mapQueryPiaListValues = (raw: any): any => {
  try {
    const listValues = {}
    raw.datasourceDataProperty?.edges?.forEach(({ node }) => {
      const key = mapDataPropertyFieldTypeToStateKey[node.fieldType]
      if (node.value) {
        if (listValues[key])
          listValues[key] = [
            ...listValues[key],
            {
              id: node.id || '',
              name: node.value || ''
            }
          ]
        else
          listValues[key] = [
            {
              id: node.id || '',
              name: node.value || ''
            }
          ]
      }
    })
    return listValues
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryPiaProcessReviewSchedules = () => {
  return gql`
    {
      process {
        edges {
          node {
            id
            processSettings {
              edges {
                node {
                  reviewFrequency
                  reviewDuration
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryPiaProcessReviewSchedules = (raw: any): PiaReviewSchedule[] => {
  try {
    const list: PiaReviewSchedule[] = []
    raw.process?.edges?.forEach(({ node }) => {
      if (node.processSettings?.edges?.length) {
        node.processSettings?.edges.forEach(({ node }) => {
          if (
            !list.find(
              ({ reviewDuration, reviewFrequency }) =>
                reviewDuration === node.reviewDuration && reviewFrequency === node.reviewFrequency
            )
          ) {
            list.push(node)
          }
        })
      }
    })
    const sortByFrequency = (a, b) => a.reviewFrequency - b.reviewFrequency
    const dailySchedules = list
      .filter(({ reviewDuration }) => reviewDuration === 'DAY')
      .sort(sortByFrequency)
    const weeklySchedules = list
      .filter(({ reviewDuration }) => reviewDuration === 'WEEK')
      .sort(sortByFrequency)
    const monthlySchedules = list
      .filter(({ reviewDuration }) => reviewDuration === 'MONTH')
      .sort(sortByFrequency)
    const yearlySchedules = list
      .filter(({ reviewDuration }) => reviewDuration === 'YEAR')
      .sort(sortByFrequency)
    const sortedSchedules: PiaReviewSchedule[] = [
      ...dailySchedules,
      ...weeklySchedules,
      ...monthlySchedules,
      ...yearlySchedules
    ]
    return sortedSchedules
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDeleteProcesOrTemplate = (id: string) => {
  return gql`
    mutation {
      deleteRopaProcess(clientMutationId: "clientMutationId", ropaProcessId: "${id}") {
        clientMutationId
      }
    }
  `
}

export const queryAddCollaborators = ({
  ropaProcessId,
  dueDate,
  emailBody = '',
  parentCollaboratorId,
  collaboratorQuestionnaire = []
}: PiaCollaboration) => {
  const questionnaire = collaboratorQuestionnaire.map((questionnaire) => {
    const dataElementsPropertiesQuery = getDataElementsPropertiesCollaboratorFragment(
      questionnaire.questionnaireInput.dataElements
    )
    const dataSubjectsPropertiesQuery = getDataSubjectsPropertiesCollaboratorFragment(
      questionnaire.questionnaireInput.dataSubjects
    )
    const dataRetentionPropertiesQuery = getDataRetentionPropertiesCollaboratorFragment(
      questionnaire.questionnaireInput.dataRetention
    )
    const safeguardsPropertiesQuery = getSafeguardsPropertiesCollaboratorFragment(
      questionnaire.questionnaireInput.safeguards
    )
    const transfersPropertiesQuery = getTransfersPropertiesCollaboratorFragment(
      questionnaire.questionnaireInput.transfers
    )

    const customTopicsQuery = getCustomTopicFragment(questionnaire.questionnaireInput.customTopics)

    return `{
      emailId: "${questionnaire.emailId}"
      questionnaireInput: {
        ${dataElementsPropertiesQuery},
        ${dataSubjectsPropertiesQuery},
        ${dataRetentionPropertiesQuery},
        ${safeguardsPropertiesQuery},
        ${transfersPropertiesQuery},
        ${customTopicsQuery}
      }
    }`
  })

  return gql`
    mutation {
      ropaSendCollaborationRequest(
        clientMutationId: "ropaSendCollaborationRequest"
        requestData: {
          ropaProcessId: "${ropaProcessId}"
          dueDate: "${dueDate}"
          emailBody: "${encodeURIComponent(emailBody)}"
          ${
            isObjectId(parentCollaboratorId)
              ? `parentCollaboratorId: "${parentCollaboratorId}"`
              : ''
          }
          collaboratorQuestionnaire: [
            ${questionnaire.join(',')}
          ]
        }
      ) {
        clientMutationId
      }
    }
  `
}

export const queryUpdateCollaborator = ({
  collaboratorId,
  ropaProcessId,
  questionnaire
}: UpdateCollaborationParams) => {
  const dataElementsPropertiesQuery = getDataElementsPropertiesCollaboratorFragment(
    questionnaire.dataElements
  )
  const dataSubjectsPropertiesQuery = getDataSubjectsPropertiesCollaboratorFragment(
    questionnaire.dataSubjects
  )
  const dataRetentionPropertiesQuery = getDataRetentionPropertiesCollaboratorFragment(
    questionnaire.dataRetention
  )
  const safeguardsPropertiesQuery = getSafeguardsPropertiesCollaboratorFragment(
    questionnaire.safeguards
  )
  const transfersPropertiesQuery = getTransfersPropertiesCollaboratorFragment(
    questionnaire.transfers
  )
  const customTopicsQuery = getCustomTopicFragment(questionnaire.customTopics)

  return gql`
    mutation {
      updateRopaCollaborationRequest(
        clientMutationId: "1"
        collaboratorId: "${collaboratorId}"
        requestData: {
          ropaProcessId: "${ropaProcessId}"
          questionnaire: {
            ${dataElementsPropertiesQuery},
            ${dataSubjectsPropertiesQuery},
            ${dataRetentionPropertiesQuery},
            ${safeguardsPropertiesQuery},
            ${transfersPropertiesQuery},
            ${customTopicsQuery}
          }
        }
      ) {
        clientMutationId
      }
    }
  `
}

export const mutationSendReminder = (params: SendReminderParams) => {
  return gql`
  mutation {
    ropaRemindCollaborationRequest(
      clientMutationId: "clientMutationId",
        collaboratorId: "${params.id}"
        emailBody: "${encodeURIComponent(params.emailBody)}"
        dueDate: "${new Date(params.dueDate).toISOString()}"
    ) {
      clientMutationId
    }
  }`
}

export const mutationPiaRevokeReview = (params: RevokePiaReviewParams) => {
  const emailBody = params.emailBody ? `emailBody: "${encodeURIComponent(params.emailBody)}"` : ''
  return gql`
    mutation {
      revokePiaReviewRequest(
        clientMutationId: "clientMutationId",
        piaProcessId: "${params.processId}",
        ${emailBody}
    ) {
        clientMutationId
      }
    }
  `
}
export const mutationRevokeAssignment = (params: { id: string }) => {
  return gql`
    mutation {
      ropaRevokeCollaborationRequest(
        clientMutationId: "clientMutationId",
        collaboratorId: "${params.id}"
      ) {
        clientMutationId
      }
    }
  `
}
export const mutationReassignPiaReviewer = (params: ReassignPiaAssignmentParams) => {
  return gql`
    mutation {
      reassignPiaReviewRequest(
        clientMutationId: "clientMutationId",
        piaProcessId: "${params.processId}",
        reviewerEmails: ${JSON.stringify(params.reviewers)},
        emailBody: "${encodeURIComponent(params.emailBody)}",
        dueDate: "${params.dueDate}"),
      {
        clientMutationId
      }
    }
  `
}

export const mutationRejectTicket = (params: RejectTicketParams) => {
  return gql`
    mutation {
      ropaRejectCollaborationRequest(
        clientMutationId: "clientMutationId",
          collaboratorId: "${params.id}"
          emailBody: "${encodeURIComponent(params.emailBody)}"
      ) {
        clientMutationId
      }
    }`
}

export const mutationReassignTicket = (params: ReassignmentParams) => {
  return gql`
    mutation {
      ropaReassignCollaborationRequest(
        clientMutationId: "clientMutationId",
          collaboratorId: "${params.id}"
          emailBody: "${encodeURIComponent(params.emailBody)}"
          dueDate: "${new Date(params.dueDate).toISOString()}"
          emailId: "${params.email}"
      ) {
        clientMutationId
      }
    }
  `
}

export const deleteCollaborationRequest = (params: { id: string }) => {
  return gql`
    mutation {
      ropaDeleteCollaborationRequest(
        clientMutationId: "clientMutationId",
        collaboratorId: "${params.id}"
      ){
			clientMutationId
      }
    }
  `
}

export const sendPiaNewCollaborationRequest = (params: SendReminderParams) => {
  return gql`
    mutation {
      ropaSendNewTicket(
        clientMutationId: "clientMutationId",
        collaboratorId: "${params.id}"
        dueDate: "${new Date(params.dueDate).toISOString()}"
        emailBody: "${encodeURIComponent(params.emailBody)}"
      ){
			clientMutationId
      }
    }
  `
}

export const mutationUpdateProcessSettings = (params: RopaProcessSettingsParams) => {
  const { processId, duration, frequency } = params

  const durationFragment = duration ? `duration: ${duration},` : ''
  const frequencyFragment = frequency ? `frequency: ${frequency}` : ''

  return gql`
    mutation{
      sendRopaReviewReminder(
        clientMutationId: "test"
        ropaProcessId: "${processId}"
        schedulerDetails: { ${durationFragment} ${frequencyFragment} }
      ){
        clientMutationId
        ropaProcessId
      }
    }
  `
}

export const queryPiaAttributes = (params: PiaAttributesParams): string => {
  let queryParam = ''
  if (params.dataSourceIds.length) {
    queryParam += `(datasourceIds:${JSON.stringify(params.dataSourceIds)})`
  }
  return gql`
    {
      attribute${queryParam} {
        count
        edges {
          node {
            id
            name
            sensitivityLabel
            ${
              params.forDataElements
                ? `
              datasourceCounts {
                count
                datasource {
                  edges {
                    node {
                      id
                      name
                      type
                    }
                  }
                }
              }`
                : ''
            }
            attributeSets(first: 1) {
              count
            }
          }
        }
      }
    }
  `
}
type QueryPiaAttributeSetsResponse = {
  attributeSet: GqlConnection<AttributeSetFromGql>
  attribute: GqlConnectionWithCount<
    AttributeFromGql & {
      attributeSets: {
        count: number
      }
    }
  >
}

type AttributeFromGql = Take<AttributeSetAttribute, 'id' | 'name' | 'sensitivityLabel'> & {
  datasourceCounts?: Array<{
    count: number
    datasource: GqlConnectionWithCount<{
      id: string
      name: string
      type: string
    }>
  }>
}

type AttributeSetFromGql = Take<AttributeSet, 'id' | 'name' | 'enabled' | 'type'> & {
  attributes: GqlConnectionWithCount<AttributeFromGql>
}

export type PiaAttributeSet = Take<AttributeSet, 'id' | 'name'>
export type PiaAttributeSetAttribute = Take<
  AttributeSetAttribute,
  'id' | 'name' | 'datasources' | 'sensitivityLabel'
> & {
  instanceCount: number
}

export const mapQueryPiaAttributes = (
  raw: QueryPiaAttributeSetsResponse,
  { dataSourceIds, print, forDataElements }: PiaAttributesParams
): {
  attributes: PiaAttributeSetAttribute[]
} => {
  try {
    const distinctAttributes = {}

    const mapAttribute = (attr: AttributeFromGql) => {
      const datasources = new Set<string>()
      const instanceCount =
        dataSourceIds.length > 0
          ? attr.datasourceCounts?.reduce((total, datasourceCount) => {
              if (
                dataSourceIds.find(
                  (datasourceId) => datasourceCount.datasource?.edges[0]?.node?.id == datasourceId
                ) &&
                datasourceCount?.count
              ) {
                datasources.add(datasourceCount.datasource?.edges[0]?.node?.id || '')
                return datasourceCount.count + total
              } else {
                return total
              }
            }, 0) || 0
          : 0
      const attribute = {
        id: attr.id,
        name: attr.name,
        sensitivityLabel: attr.sensitivityLabel,
        instanceCount,
        ...(forDataElements
          ? {
              datasources: attr.datasourceCounts?.map((ds) => ({
                count: ds?.count,
                name: ds?.datasource?.edges?.[0]?.node?.name,
                id: ds?.datasource?.edges?.[0]?.node?.id,
                type: ds?.datasource?.edges?.[0]?.node?.type
              }))
            }
          : {}),
        ...(print ? { totalDataSources: datasources.size } : {})
      }
      return attribute
    }

    raw.attribute.edges.forEach(({ node: attr }) => {
      const attribute = mapAttribute(attr)
      distinctAttributes[attr.id] = attribute
    })

    const attrs = Object.values(distinctAttributes) as AttributeSetAttribute[]
    const sortMapper = (a, b) =>
      (b.datasources?.reduce((total, ds) => (total += ds?.count || 0), 0) || 0) -
      (a.datasources?.reduce((total, ds) => (total += ds?.count || 0), 0) || 0)
    const highSensitiveAttrs = attrs
      .filter(({ sensitivityLabel }) => sensitivityLabel === SENSITIVE_LABEL.HIGH)
      .sort(sortMapper)
    const mediumSensitiveAttrs = attrs
      .filter(({ sensitivityLabel }) => sensitivityLabel === SENSITIVE_LABEL.MEDIUM)
      .sort(sortMapper)
    const lowSensitiveAttrs = attrs
      .filter(({ sensitivityLabel }) => sensitivityLabel === SENSITIVE_LABEL.LOW)
      .sort(sortMapper)

    const attributes = [...highSensitiveAttrs, ...mediumSensitiveAttrs, ...lowSensitiveAttrs]
    const attributesWithoutSet = raw.attribute.edges
      .filter(({ node: attr }) => !attr?.attributeSets?.count)
      .map(({ node: attr }) => mapAttribute(attr))

    return {
      attributes: [...attributes, ...attributesWithoutSet]
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const mapQueryPiaOverviewAttributesInstanceCount = (
  raw: QueryPiaAttributeSetsResponse,
  { dataSourceIds, print, forDataElements }: PiaAttributesParams
): PiaAttributeSetAttribute[] => {
  try {
    const mapAttribute = (attr: AttributeFromGql) => {
      const datasources = new Set<string>()
      const instanceCount =
        dataSourceIds.length > 0
          ? attr.datasourceCounts?.reduce((total, datasourceCount) => {
              if (
                dataSourceIds.find(
                  (datasourceId) => datasourceCount.datasource?.edges[0]?.node?.id == datasourceId
                ) &&
                datasourceCount?.count
              ) {
                datasources.add(datasourceCount.datasource?.edges[0]?.node?.id || '')
                return datasourceCount.count + total
              } else {
                return total
              }
            }, 0) || 0
          : 0
      const attribute = {
        id: attr.id,
        name: attr.name,
        sensitivityLabel: attr.sensitivityLabel,
        instanceCount,
        ...(forDataElements
          ? {
              datasources: attr.datasourceCounts?.map((ds) => ({
                count: ds?.count,
                name: ds?.datasource?.edges?.[0]?.node?.name,
                id: ds?.datasource?.edges?.[0]?.node?.id,
                type: ds?.datasource?.edges?.[0]?.node?.type
              }))
            }
          : {}),
        ...(print ? { totalDataSources: datasources.size } : {})
      }
      return attribute
    }

    return raw.attribute.edges.map(({ node: attr }) => mapAttribute(attr))
  } catch (error) {
    console.error(error)
    throw error
  }
}

// Collaborators and Tickets
export const queryPiaTickets = ({
  page = 1,
  pageSize = LIMIT_DEFAULT,
  filters
}: PiaTicketsParams) => {
  const cursor = getAfterCursor(page || 1, pageSize)
  const filtersParameterized = parameterizeArrayofObjects(filters?.filter || [])
  let filterParams = ''
  if (filtersParameterized) {
    filterParams = `, filter:${filtersParameterized}`
  }

  return gql`
    {
      ropaCollaboration(sortField: UPDATE_TIMESTAMP, first:${pageSize}, after:"${cursor}"${filterParams}) {
        count
        edges {
          node {
            id
            dueDate
            status
            name
            updateTimestamp
            createdAt
            emailId
            ropaProcess{
              edges{
                node{
                  name
                  processGroups {
                    edges {
                      node {
                        value
                      }
                    }
                  }
                  owner
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryPiaTickets = (raw: any): { list: PiaTicket[]; total: number } => {
  try {
    const list =
      raw.ropaCollaboration?.edges?.map(({ node }) => ({
        id: node.id || '',
        ticketId: node.name || '',
        processName: node.ropaProcess?.edges[0]?.node?.name || '',
        processGroups:
          node.ropaProcess?.edges[0]?.node?.processGroups?.edges?.map(
            ({ node }) => node.value || ''
          ) || [],
        processOwner: node.ropaProcess?.edges[0]?.node?.owner || '',
        dueDate: node.dueDate || '',
        status: node.status || '',
        updatedAt: node.updateTimestamp || '',
        createdAt: node.createdAt || '',
        assignee: node.emailId || ''
      })) || []

    return { list, total: raw.ropaCollaboration?.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const mutationDeletePiaCollaboration = (id: string) => {
  return gql`
    mutation {
      ropaDeleteCollaborationRequest(
        clientMutationId: "ropaDeleteCollaborationRequest"
        collaboratorId: "${id}"
      ) {
        clientMutationId
      }
    }
  `
}

export const mutationSubmitProcessForReview = ({
  processId,
  emailId,
  emailBody = '',
  dueDate
}: SubmitProcessForReviewParams) => {
  const emailBodyStr = emailBody ? `emailBody: "${encodeURIComponent(emailBody)}"` : ''
  return gql`
    mutation {
      piaUpdateProcessStatusToReviewPending(
        clientMutationId: "clientId"
        dueDate: "${dueDate}"
        ${emailBodyStr}
        piaProcessId: "${processId}"
        reviewerEmails: ["${emailId}"]
      ) {
        ropaProcessId
        clientMutationId
      }

    }
  `
}

export const FRAGMENT_QUESTIONS = gql`
  edges{
    node{
      questionId
      question
      isMandatory
      processQuestionId
      riskAssociated
      systemRiskValue
      overallRiskValue
      riskOptions {
        id
        value
      }
    }
  }
`
const FRAGMENT_COLLABORATOR_QUESTIONS_AND_SCOPE = gql`
  edges {
        node {
          ropaTopics {
            dataElements {
              id
              name
              description
              optional
              associatedDatasources {
                edges {
                  node {
                    id
                  }
                }
              }
              specialElements{
                attributeName
                sensitivity
              }
              attributes{
                count
                edges{
                  node{
                    id
                  }
                }
              }
              lawfulBases{
                edges{
                  node{
                    id
                  }
                }
              }
              specialElementProperties${fieldPropertyFragment}
              attributeProperties${fieldPropertyFragment}
              lawfulBasisProperties${fieldPropertyFragment}
              questions {
                count
                ${FRAGMENT_QUESTIONS}
              }
            }
            dataSubjects{
              id
              name
              description
              optional
              specialDataSubject
              dataSubjectProperties${fieldPropertyFragment}
              specialDataSubjectProperties${fieldPropertyFragment}
              specialDataSubjectsProperties${fieldPropertyFragment}
              legalBasisProperties${fieldPropertyFragment}
              questions {
                count
                ${FRAGMENT_QUESTIONS}
              }
            }
            dataRetention{
              id
              name
              description
              optional
              dataRetentionProperties${fieldPropertyFragment}
              durationTypeProperties ${fieldPropertyFragment}
              attributeSetProperties ${fieldPropertyFragment}
              triggerEventProperties ${fieldPropertyFragment}
              questions{
                count
                ${FRAGMENT_QUESTIONS}
              }
            }
            safeguards{
              id
              name
              description
              optional
              safeguardProperties${fieldPropertyFragment}
              questions{
                count
                ${FRAGMENT_QUESTIONS}
              }
            }
            transfers {
              id
              name
              description
              optional
              thirdParty {
                isThirdParty
                thirdPartyProperties${fieldPropertyFragment}
                details {
                  nameProperties${fieldPropertyFragment}
                  emailProperties${fieldPropertyFragment}
                  addressProperties${fieldPropertyFragment}
                  safeguardsProperties${fieldPropertyFragment}
                }
              }
              crossBorder {
                isCrossBorder
                crossBorderProperties${fieldPropertyFragment}
                details {
                  countryProperties${fieldPropertyFragment}
                  emailProperties${fieldPropertyFragment}
                  organisationProperties${fieldPropertyFragment}
                  safeguardsProperties${fieldPropertyFragment}
                }
              }
              questions{
                count
                ${FRAGMENT_QUESTIONS}
              }
            }
            customTopics{
              id
              name
              description
              optional
              questions{
                count
                ${FRAGMENT_QUESTIONS}
              }
            }
          }
        }
      }
`
export const queryProcessDetails = (id: string) => {
  return gql`{
    process (id: "${id}") {
     ${FRAGMENT_COLLABORATOR_QUESTIONS_AND_SCOPE}
    }
  }`
}
export const queryCollaborationDetails = (id: string) => {
  return gql`{
    ropaCollaboration (id: "${id}") {
      ${FRAGMENT_COLLABORATOR_QUESTIONS_AND_SCOPE}
    }
  }`
}
export const mapQueryCollaborationDetails = (raw: any): PiaProcessCollaborationScope => {
  const mapQuestions = (topic) =>
    topic.questions?.edges?.map(({ node }) => ({
      questionId: node.questionId || '',
      question: node.question || '',
      systemRiskValue: node.systemRiskValue,
      overallRiskValue: node.overallRiskValue,
      riskOptions: node.riskOptions,
      isMandatory: node.isMandatory || false
    })) || []
  try {
    const ropaProcess = raw.process?.edges[0]?.node || raw.ropaCollaboration?.edges[0]?.node
    const ropaTopics = ropaProcess?.ropaTopics
    const topics: PiaQuestionnaireOptionalTopics = {}
    let totalTopics = 0
    const dataElements = ropaTopics?.dataElements
    if (dataElements) {
      totalTopics++
      topics.dataElements = {
        id: dataElements.id || '',
        name: dataElements.name || '',
        description: dataElements.description || '',
        optional: dataElements.optional || false,
        attributeProperties: dataElements.attributeProperties || [],
        specialElementProperties: dataElements.specialElementProperties || [],
        lawfulBasisProperties: dataElements.lawfulBasisProperties || [],
        questions: mapQuestions(dataElements)
      }
    }

    const dataSubjects = ropaTopics?.dataSubjects
    if (dataSubjects) {
      totalTopics++
      topics.dataSubjects = {
        optional: dataSubjects.optional || false,
        id: dataSubjects.id || '',
        name: dataSubjects.name || '',
        description: dataSubjects.description || '',
        dataSubjectProperties: dataSubjects.dataSubjectProperties || [],
        specialDataSubjectProperties: dataSubjects.specialDataSubjectProperties || [],
        specialDataSubjectsProperties: dataSubjects.specialDataSubjectsProperties || [],
        legalBasisProperties: dataSubjects.legalBasisProperties || [],
        questions: mapQuestions(dataSubjects)
      }
      const questions: PiaCollaboratorQuestion[] = []
      if (isFieldAvailable(dataSubjects, 'dataSubjectProperties')) {
        const dataSubjectLabel = getPropertyLabelValue(dataSubjects?.dataSubjectProperties)
        questions.push({
          id: 'dataSubjects',
          question: dataSubjectLabel.length ? dataSubjectLabel[0] || '' : '',
          isMandatory: getPropertyIsMandatory(dataSubjects?.dataSubjectProperties)
        })
      }
      if (dataSubjects.specialDataSubject) {
        if (isFieldAvailable(dataSubjects, 'specialDataSubjectsProperties')) {
          const specialDataSubjectLabel = getPropertyLabelValue(
            dataSubjects?.specialDataSubjectProperties
          )
          questions.push({
            id: 'specialDataSubjects',
            question: specialDataSubjectLabel.length ? specialDataSubjectLabel[0] || '' : '',
            isMandatory: getPropertyIsMandatory(dataSubjects?.specialDataSubjectProperties)
          })

          if (isFieldAvailable(dataSubjects, 'legalBasisProperties')) {
            const legalBasisLabel = getPropertyLabelValue(dataSubjects?.legalBasisProperties)
            questions.push({
              id: 'legalBasis',
              question: legalBasisLabel.length ? legalBasisLabel[0] || '' : '',
              isMandatory: getPropertyIsMandatory(dataSubjects?.legalBasisProperties)
            })
          }
        }
      }
    }

    const dataRetention = ropaTopics?.dataRetention
    if (dataRetention) {
      const questions: PiaCollaboratorQuestion[] = []
      totalTopics++
      topics.dataRetention = {
        id: dataRetention.id || '',
        name: dataRetention.name || '',
        description: dataRetention.description || '',
        optional: dataRetention.optional || false,
        dataRetentionProperties: dataRetention.dataRetentionProperties || [],
        attributeSetProperties: dataRetention.attributeSetProperties || [],
        durationTypeProperties: dataRetention.durationTypeProperties || [],
        triggerEventProperties: dataRetention.triggerEventProperties || [],
        questions: mapQuestions(dataRetention)
      }
      if (isFieldAvailable(dataRetention, 'dataRetentionProperties')) {
        const dataRetentionLabel = getPropertyLabelValue(dataRetention?.dataRetentionProperties)
        questions.push({
          id: 'dataRetention',
          question: dataRetentionLabel.length ? dataRetentionLabel[0] || '' : '',
          isMandatory: getPropertyIsMandatory(dataRetention?.dataRetentionProperties)
        })
      }
    }

    const safeguards = ropaTopics?.safeguards
    if (safeguards) {
      const questions: PiaCollaboratorQuestion[] = []
      totalTopics++
      topics.safeguards = {
        id: safeguards.id || '',
        name: safeguards.name || '',
        description: safeguards.description || '',
        optional: safeguards.optional || false,
        safeguardProperties: safeguards.safeguardProperties || [],
        questions: mapQuestions(safeguards)
      }
      if (isFieldAvailable(safeguards, 'safeguardProperties')) {
        const safeguardLabel = getPropertyLabelValue(safeguards?.safeguardProperties)
        questions.push({
          id: 'safeguards',
          question: safeguardLabel.length ? safeguardLabel[0] || '' : '',
          isMandatory: getPropertyIsMandatory(safeguards?.safeguardProperties)
        })
      }
    }

    const transfers = ropaTopics?.transfers
    if (transfers) {
      totalTopics++
      topics.transfers = {
        id: transfers.id || '',
        name: transfers.name || '',
        description: transfers.description || '',
        optional: transfers.optional || false,
        crossBorder: {
          isCrossBorder: ropaTopics?.transfers?.crossBorder?.isCrossBorder || false,
          crossBorderProperties: ropaTopics?.transfers?.crossBorder?.crossBorderProperties || [],
          details: ropaTopics?.transfers?.crossBorder?.details || []
        },
        thirdParty: {
          isThirdParty: ropaTopics?.transfers?.thirdParty?.isThirdParty || false,
          thirdPartyProperties: ropaTopics?.transfers?.thirdParty?.thirdPartyProperties || [],
          details: ropaTopics?.transfers?.thirdParty?.details || []
        },
        questions: mapQuestions(transfers)
      }
    }

    if (ropaTopics?.customTopics.length) {
      topics.customTopics =
        ropaTopics?.customTopics?.map((topic) => {
          totalTopics++

          return {
            optional: false,
            name: topic.name || '',
            questions: mapQuestions(topic)
          }
        }) || []
    }

    return {
      totalTopics,
      topics
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

// overview
export const queryProcessOverview = ({ processId }: PiaProcessOverviewParams) => {
  return gql`{
    processOverview: process (id: "${processId}") {
      edges {
        node {
          ${FRAGMENT_PROCESS_BODY}
          collaboration{
            edges{
              node{
                emailId
                status
              }
            }
          }
        }
      }
    }
    ropa(ropaProcessId: "${processId}") {
      count
      edges {
        node {
          createdAt
        }
      }
    }
    attributeInstanceGroupedbyName {
      name
      internalName
      sensitivity
      attributeInstanceCount
    }
  }`
}

export const mapQueryProcessOverview = (raw: any): PiaProcessOverview => {
  try {
    const ropaProcess = raw.processOverview?.edges[0]?.node
    const ropaTopics = ropaProcess?.ropaTopics
    const dataCategories =
      ropaTopics?.dataElements?.attributes?.edges?.map(
        ({ node }) =>
          node.attributeSets?.edges
            ?.filter(({ node }) => node.enabled)
            ?.map(({ node }) => node.id) || []
      ) || []
    const dataCategoriesSet = new Set<string>()
    dataCategories.forEach((dataCategory) => {
      dataCategory.forEach((category) => {
        dataCategoriesSet.add(category)
      })
    })
    const attributeInternalNames =
      ropaTopics?.dataElements?.attributes?.edges?.map(({ node }) => node.internalName) || []
    const completionPercentage = getProcessStats(ropaProcess).percentage

    const data = {
      processUpdatedAt: ropaProcess.updatedAt || '',
      totalDataElements: ropaTopics?.dataElements?.attributes?.count || 0,
      totalSpecialDataElements: ropaTopics?.dataElements?.specialElements?.length || 0,
      totalDataCategories: dataCategoriesSet.size,
      totalDataSubjects: ropaTopics?.dataSubjects?.dataSubjects?.count || 0,
      totalSpecialDataSubjects: ropaTopics?.dataSubjects?.specialDataSubjects?.count || 0,
      totalReports: raw?.ropa?.count || 0,
      oldestReportDate:
        (raw?.ropa?.edges?.map(({ node }) => node.createdAt) || []).sort(
          (dateA, dateB) => new Date(dateA).getTime() - new Date(dateB).getTime()
        )[0] || '',
      newestReportDate:
        (raw?.ropa?.edges?.map(({ node }) => node.createdAt) || []).sort(
          (dateB, dateA) => new Date(dateA).getTime() - new Date(dateB).getTime()
        )[0] || '',
      dataSources: ropaTopics?.dataElements?.associatedDatasources?.edges?.map(
        ({ node: associatedDatasource }) => {
          let total = 0
          ropaTopics?.dataElements?.attributes?.edges?.forEach(({ node }) => {
            if (
              node.datasourceCounts?.find(
                ({ datasource }) => datasource?.edges[0]?.node?.id == associatedDatasource?.id
              )
            ) {
              total++
            }
          })
          return {
            ...associatedDatasource,
            totalAttributes: total
          }
        }
      ),
      attributes: raw?.attributeInstanceGroupedbyName?.filter(
        ({ internalName }) => !!attributeInternalNames.find((name) => name == internalName)
      ),
      collaborators: ropaProcess.collaboration?.edges?.map(({ node }) => ({
        email: node.emailId || '',
        status: node.status || ''
      })),
      riskDetails: ropaProcess.riskDetails,
      completionPercentage
    }
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

// reports
export const queryReportById = (id: string) => gql`
  {
    ropa(id: "${id}"){
      edges {
        node {
          id
          createdAt
          processDetails {
            ${FRAGMENT_PROCESS_BODY}
          }
          riskScales {
            id
            displayName
            colour
            value
            type
          }
        }
      }
    }
  }
`

const getReportRiskOptions = (riskScales, type: PiaRiskScaleTypes) => {
  return (
    riskScales
      .filter((opt) => opt.type === type)
      ?.map((opt) => {
        return {
          id: opt.id,
          colour: opt.colour,
          value: opt.value,
          displayName: opt.displayName,
          processUsageCount: opt.processUsageCount,
          templateUsageCount: opt.templateUsageCount
        }
      }) || []
  )
}

export const mapQueryReportById = (raw: any): PiaReportPrint => {
  const filterDeleted = (list) => list.filter(({ isDeleted }) => !isDeleted)

  try {
    const ropa = raw.ropa?.edges[0]?.node || {}
    const process = ropa.processDetails || {}

    const result: PiaReportPrint = {
      id: ropa.id,
      name: generateReportName(ropa.createdAt || '', process?.name || ''),
      process: {
        id: process.id,
        name: isQuestionDeleted(process?.nameProperties || []) ? '' : process?.name || '',
        nameLabel: getPropertyLabelValue(process?.nameProperties || []),
        owner: process?.owner || '',
        dpoEmail: process?.dpoEmail || '',
        description: isQuestionDeleted(process?.descriptionProperties || [])
          ? ''
          : process?.description || '',
        descriptionLabel: getPropertyLabelValue(process?.descriptionProperties || []),
        processGroups: isQuestionDeleted(process?.processGroupsProperties || [])
          ? ''
          : filterDeleted(process?.processGroups?.edges || []).map(({ node }) => node),
        processGroupsLabel: getPropertyLabelValue(process?.processGroupsProperties || []),
        processGroupsProperties: process?.processGroupsProperties,
        purpose: isQuestionDeleted(process?.purposeProperties || [])
          ? undefined
          : filterDeleted(process?.purpose?.edges || []).map(({ node }) => node),
        purposeProperties: process?.purposeProperties,
        purposeLabel: getPropertyLabelValue(process?.purposeProperties || []),
        lawfulBasis: isQuestionDeleted(process?.lawfulBasisProperties || [])
          ? undefined
          : filterDeleted(process?.lawfulBasis?.edges || []).map(({ node }) => node),
        lawfulBasisLabel: getPropertyLabelValue(process?.lawfulBasisProperties || []),
        lawfulBasisProperties: process?.lawfulBasisProperties,
        automatedProcessing: isQuestionDeleted(process?.automatedProcessingProperties || [])
          ? undefined
          : process?.automatedProcessing,
        automatedProcessingProperties: process?.automatedProcessingProperties,
        automatedProcessingDescriptionProperties: process?.automatedProcessingDescriptionProperties,
        automatedProcessingLabel: getPropertyLabelValue(
          process?.automatedProcessingProperties || []
        ),
        automatedProcessingDescription: isQuestionDeleted(
          process?.automatedProcessingDescriptionProperties || []
        )
          ? ''
          : process?.automatedProcessingDescription || '',
        automatedProcessingDescriptionLabel: getPropertyLabelValue(
          process?.automatedProcessingDescriptionProperties || []
        ),
        controllerQuestions: mapQuestions(process?.controllerQuestions?.edges || []),
        questions: mapQuestions(process?.processQuestions?.edges || []),
        riskDetails: process?.riskDetails,
        assessmentReview: process.assessmentReview
      },
      controllers:
        process?.processDetails?.processingActivityDetails
          ?.filter(
            ({ managerType }) => managerType == 'CONTROLLER' || managerType == 'JOINT_CONTROLLER'
          )
          .map(({ details }) => details || []) || [],
      processors: process?.processDetails?.processingActivityDetails
        ?.filter(({ managerType }) => managerType == 'PROCESSOR' || managerType == 'SUB_PROCESSOR')
        .map(({ details }) => details || []),
      topics: mapPiaReportTopicsToQuestionnaire(process),
      riskOptions: getReportRiskOptions(ropa.riskScales, PiaRiskScaleTypes.risk),
      likeLinessOptions: getReportRiskOptions(ropa.riskScales, PiaRiskScaleTypes.likeliness)
    }

    return result as PiaReportPrint
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDeletePiaReport = (id: string) => {
  return gql`
    mutation {
      deletePiaReport(clientMutationId: "deletePiaReport", ropaReportId: "${id}") {
        clientMutationId
      }
    }
  `
}
export const queryGeneratePiaReport = (processId: string) => {
  return gql`
    mutation {
      createPiaReport(clientMutationId: "createPiaReport", ropaProcessId: "${processId}") {
        clientMutationId
        report{
          edges {
            node {
              id
              createdAt
              processDetails {
                ${FRAGMENT_PROCESS_BODY}
              }
              riskScales {
                id
                displayName
                colour
                value
                type
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryGeneratePiaReport = (raw: any): PiaReportPrint => {
  const filterDeleted = (list) => list.filter(({ isDeleted }) => !isDeleted)

  try {
    const ropa = raw.createPiaReport.report?.edges[0]?.node || {}
    const process = ropa.processDetails || {}

    const result: PiaReportPrint = {
      id: ropa.id,
      name: generateReportName(ropa.createdAt || '', process?.name || ''),
      process: {
        id: process.id,
        name: isQuestionDeleted(process?.nameProperties || []) ? '' : process?.name || '',
        nameLabel: getPropertyLabelValue(process?.nameProperties || []),
        owner: process?.owner || '',
        dpoEmail: process?.dpoEmail || '',
        description: isQuestionDeleted(process?.descriptionProperties || [])
          ? ''
          : process?.description || '',
        descriptionLabel: getPropertyLabelValue(process?.descriptionProperties || []),
        processGroups: isQuestionDeleted(process?.processGroupsProperties || [])
          ? ''
          : filterDeleted(process?.processGroups?.edges || []).map(({ node }) => node),
        processGroupsLabel: getPropertyLabelValue(process?.processGroupsProperties || []),
        processGroupsProperties: process?.processGroupsProperties,
        purpose: isQuestionDeleted(process?.purposeProperties || [])
          ? undefined
          : filterDeleted(process?.purpose?.edges || []).map(({ node }) => node),
        purposeProperties: process?.purposeProperties,
        purposeLabel: getPropertyLabelValue(process?.purposeProperties || []),
        lawfulBasis: isQuestionDeleted(process?.lawfulBasisProperties || [])
          ? undefined
          : filterDeleted(process?.lawfulBasis?.edges || []).map(({ node }) => node),
        lawfulBasisLabel: getPropertyLabelValue(process?.lawfulBasisProperties || []),
        lawfulBasisProperties: process?.lawfulBasisProperties,
        automatedProcessing: isQuestionDeleted(process?.automatedProcessingProperties || [])
          ? undefined
          : process?.automatedProcessing,
        automatedProcessingProperties: process?.automatedProcessingProperties,
        automatedProcessingDescriptionProperties: process?.automatedProcessingDescriptionProperties,
        automatedProcessingLabel: getPropertyLabelValue(
          process?.automatedProcessingProperties || []
        ),
        automatedProcessingDescription: isQuestionDeleted(
          process?.automatedProcessingDescriptionProperties || []
        )
          ? ''
          : process?.automatedProcessingDescription || '',
        automatedProcessingDescriptionLabel: getPropertyLabelValue(
          process?.automatedProcessingDescriptionProperties || []
        ),
        controllerQuestions: mapQuestions(process?.controllerQuestions?.edges || []),
        questions: mapQuestions(process?.processQuestions?.edges || []),
        riskDetails: process?.riskDetails
      },
      controllers:
        process?.processDetails?.processingActivityDetails
          ?.filter(
            ({ managerType }) => managerType == 'CONTROLLER' || managerType == 'JOINT_CONTROLLER'
          )
          .map(({ details }) => details || []) || [],
      processors: process?.processDetails?.processingActivityDetails
        ?.filter(({ managerType }) => managerType == 'PROCESSOR' || managerType == 'SUB_PROCESSOR')
        .map(({ details }) => details || []),
      topics: mapPiaReportTopicsToQuestionnaire(process),
      riskOptions: getReportRiskOptions(ropa.riskScales, PiaRiskScaleTypes.risk),
      likeLinessOptions: getReportRiskOptions(ropa.riskScales, PiaRiskScaleTypes.likeliness)
    }

    return result as PiaReportPrint
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryPiaProcessOwners = (): string => {
  return gql`
    {
      process(booleanFilter: [{ key: IS_SYSTEM_DEFINED, value: false }]) {
        edges {
          node {
            owner
          }
        }
      }
    }
  `
}
export const mapQueryPiaProcessOwners = (raw: any): string[] => {
  try {
    const list = (raw.process?.edges?.map(({ node }) => node.owner || '') || []) as string[]
    return [...new Set(list)]
  } catch (error) {
    console.error(error)
    throw error
  }
}
