import {
  mapQueryAlertsByDatabase,
  mapQueryAlertsListByEntity,
  mapQueryAlertsListCompactByEntity,
  mapQueryCriticalAlerts,
  mapQueryCriticalDatabaseAlerts,
  mapQueryDriveAlerts,
  mapQueryStaledAlertsSummary,
  queryAlertsByDatabase,
  queryAlertsListByEntity,
  queryAlertsListCompactByEntity,
  queryCriticalAlerts,
  queryCriticalDatabaseAlerts,
  queryDriveAlerts,
  queryStaledAlertsSummary,
  mapQueryAlertsListByBucket,
  queryAlertsListByBucket,
  queryAlertsListCompactByBucket
} from './queries'
import service from '../../services/api/apiService'
import { FilterParams } from '../../interfaces'
import {
  AlertInfoItem,
  NamedTimeSeriesItem,
  TimestampRequestParams
} from '../../services/api/apiTypes'
import {
  ALERT_STATUS,
  DATABASE_ID,
  DATA_SOURCE_ID,
  ENTITY_ID,
  PAGE,
  POLICY_TYPES,
  SEVERITY_LEVEL,
  BUCKET_ID,
  PAGE_SIZE
} from '../../constants'
import graphqlService from '../../services/graphqlService'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import { RootState } from '../../rootReducer'
import { getGlobalParams } from '../../utils/urlUtil'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

export enum ALERT_RESOLVE_REASON {
  falsePositive = 'FALSE_POSITIVE',
  handledOffline = 'HANDLED_OFFLINE',
  other = 'OTHER'
}

export type Alert = {
  generatedAt: string
  subAlertId?: string
  status?: ALERT_STATUS
  severity: SEVERITY_LEVEL
  alertId: string
  alertName: string
  objectsCount: number
  dataSourcesCount?: number
  policyId?: string
  policyName?: string
  policyType?: POLICY_TYPES
  assignee: string
  tablesImpactedCount?: number
  datasource: {
    id?: string
    name?: string
  }
}
export type AlertsList = {
  list: Alert[]
  total: number
}
export type AlertsListSettings = {
  list?: Alert[]
  total?: number
  sort: SortParams
}

export interface IGetAlertsListParams {
  [DATA_SOURCE_ID]?: string
  [PAGE]: number
}
export const ACTION_ALERTS_LIST_FETCH = 'alerts/listFetch'
export const fetchAlertsList = createAsyncThunk(
  ACTION_ALERTS_LIST_FETCH,
  async (params: IGetAlertsListParams) => await service.getAlertsList(params)
)

export interface IGetAlertsListByEntityParams {
  [ENTITY_ID]?: string
  [DATA_SOURCE_ID]?: string
  [PAGE]: number
  [PAGE_SIZE]?: number
}
export const ACTION_ALERTS_LIST_BY_ENTITY_FETCH = 'alerts/listByEntityFetch'
export const fetchAlertsListByEntity = createAsyncThunk(
  ACTION_ALERTS_LIST_BY_ENTITY_FETCH,
  async (params: IGetAlertsListByEntityParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const result = await graphqlService.execute(queryAlertsListByEntity(queryParams))
    return mapQueryAlertsListByEntity(result)
  }
)
export interface AlertsByDatabaseParams {
  [PAGE]: number
  [DATABASE_ID]: string
}
export const ACTION_ALERTS_BY_DATABASE = 'alerts/database'
export const fetchAlertsByDatabase = createAsyncThunk(
  ACTION_ALERTS_BY_DATABASE,
  async (params: AlertsByDatabaseParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const result = await graphqlService.execute(queryAlertsByDatabase(queryParams))
    return mapQueryAlertsByDatabase(result)
  }
)

export const ACTION_ALERTS_LIST_COMPACT_BY_ENTITY_FETCH = 'alerts/entity/listByEntityFetch'
export const fetchAlertsListCompactByEntity = createAsyncThunk(
  ACTION_ALERTS_LIST_COMPACT_BY_ENTITY_FETCH,
  async (params: IGetAlertsListByEntityParams) => {
    const result = await graphqlService.execute(queryAlertsListCompactByEntity(params))
    return mapQueryAlertsListCompactByEntity(result)
  }
)

export interface AlertStaled {
  name: string
  value: number
}

export interface AlertsSummaryParams {
  [DATA_SOURCE_ID]?: string
  [DATABASE_ID]?: string
  filters?: FilterParams
}
export const ACTION_ALERTS_STALED_WIDGET = 'alerts/widgetStaled'
export const fetchAlertsStaledWidget = createAsyncThunk(
  ACTION_ALERTS_STALED_WIDGET,
  async (params: AlertsSummaryParams) => {
    const result = await graphqlService.execute(queryStaledAlertsSummary(params))
    return mapQueryStaledAlertsSummary(result)
  }
)

export interface AlertCritical {
  id: string
  name: string
  status: ALERT_STATUS
  severity: SEVERITY_LEVEL
  generatedAt: string
  dataSourceId: string
  assignees: string[]
}
export const ACTION_ALERTS_CRITICAL = 'alerts/listCritical'
export const fetchAlertsCritical = createAsyncThunk(
  ACTION_ALERTS_CRITICAL,
  async (params: AlertsSummaryParams) => {
    if (params[DATABASE_ID]) {
      const result = await graphqlService.execute(queryCriticalDatabaseAlerts(params))
      return mapQueryCriticalDatabaseAlerts(result)
    } else {
      const result = await graphqlService.execute(queryCriticalAlerts(params))
      return mapQueryCriticalAlerts(result)
    }
  }
)

export interface DriveAlertsParams extends AlertsSummaryParams {
  [PAGE]: number
}
export const ACTION_DRIVE_ALERTS = 'drive/alerts/list'
export const fetchDriveAlerts = createAsyncThunk(
  ACTION_DRIVE_ALERTS,
  async (params: DriveAlertsParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const result = await graphqlService.execute(queryDriveAlerts(queryParams))
    return mapQueryDriveAlerts(result)
  }
)

export const fetchAlertsChart = createAsyncThunk(
  'alerts/fetchAlertsChart',
  async () => await service.getAlertsChart()
)
export interface IGetAlertsListByBucketParams {
  filters?: FilterParams
  [BUCKET_ID]?: string
  [DATA_SOURCE_ID]?: string
  [PAGE]: number
  [PAGE_SIZE]?: number
}
export const ACTION_ALERTS_LIST_BY_BUCKET_FETCH = 'alerts/listByBucketFetch'
export const fetchAlertsListByBucket = createAsyncThunk(
  ACTION_ALERTS_LIST_BY_BUCKET_FETCH,
  async (params: IGetAlertsListByBucketParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const result = await graphqlService.execute(queryAlertsListByBucket(queryParams))
    return mapQueryAlertsListByBucket(result)
  }
)

export const ACTION_ALERTS_LIST_COMPACT_BY_BUCKET_FETCH = 'alerts/listByBucketFetch/compact'
export const fetchAlertsListCompactByBucket = createAsyncThunk(
  ACTION_ALERTS_LIST_COMPACT_BY_BUCKET_FETCH,
  async (params: IGetAlertsListByBucketParams) => {
    const result = await graphqlService.execute(queryAlertsListCompactByBucket(params))
    return mapQueryAlertsListByBucket(result)
  }
)

interface AlertsState {
  listCompact?: AlertInfoItem[]
  list: AlertsListSettings
  chart?: Array<NamedTimeSeriesItem>
  dateRange: TimestampRequestParams
  total?: number
  widgetStaled?: AlertStaled[]
  listCritical?: AlertCritical[]
}

const initialList: AlertsListSettings = {
  sort: defaultSortParams
}

export const initialState: AlertsState = {
  list: { ...initialList },
  dateRange: {
    endDate: dayjs().unix(),
    startDate: dayjs().subtract(1, 'week').unix()
  }
}

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setDateRange: (state, action) => {
      state.dateRange = action.payload
    },
    resetListCompact: (state) => {
      state.listCompact = initialState.listCompact
      state.total = initialState.total
    },
    resetList: (state) => {
      state.list = initialState.list
    },
    resetWidgets: (state) => {
      state.widgetStaled = initialState.widgetStaled
      state.listCritical = initialState.listCritical
    },
    setSort: (state, { payload }) => {
      state.list.sort = getSortDirection(state[payload.list].sort, payload.column)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAlertsList.fulfilled, (state, action) => {
      state.listCompact = action.payload.list
      state.total = action.payload.total
    })
    builder.addCase(fetchAlertsListByEntity.fulfilled, (state, action) => {
      state.list.list = action.payload.list
      state.list.total = action.payload.total
    })
    builder.addCase(fetchDriveAlerts.fulfilled, (state, { payload }) => {
      const { list, total } = payload
      state.list.list = list
      state.list.total = total
    })
    builder.addCase(fetchAlertsListCompactByEntity.fulfilled, (state, action) => {
      state.list.list = action.payload.list
      state.list.total = action.payload.total
    })
    builder.addCase(fetchAlertsListCompactByBucket.fulfilled, (state, action) => {
      state.list.list = action.payload.list
      state.list.total = action.payload.total
    })

    builder.addCase(fetchAlertsChart.fulfilled, (state, action) => {
      state.chart = action.payload
    })
    builder.addCase(fetchAlertsChart.rejected, (state) => {
      state.chart = []
    })
    builder.addCase(fetchAlertsStaledWidget.fulfilled, (state, { payload }) => {
      state.widgetStaled = payload
    })
    builder.addCase(fetchAlertsCritical.fulfilled, (state, { payload }) => {
      state.listCritical = payload
    })
    builder.addCase(fetchAlertsByDatabase.fulfilled, (state, { payload }) => {
      state.list.list = payload.list
      state.list.total = payload.total
    })
    builder.addCase(fetchAlertsListByBucket.fulfilled, (state, action) => {
      state.list.list = action.payload.list
      state.list.total = action.payload.total
    })
  }
})

export const {
  setDateRange,
  setSort,
  resetListCompact,
  resetWidgets,
  resetList
} = alertsSlice.actions
export default alertsSlice.reducer
