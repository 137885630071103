import './conditionCombinatorGroup.less'
import { CONDITION_COMBINATOR } from '../../../../interfaces'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import { Button } from 'semantic-ui-react'
import React from 'react'

interface IProps {
  onClick: (combinator: string) => void
  combinator: string
}

const ConditionCombinatorGroup = ({ onClick, combinator }: IProps): React.ReactElement => {
  return (
    <Button.Group
      className="condition-combinators"
      data-test-id="policies-condition-combinator-group"
    >
      <Button
        onClick={() => onClick(CONDITION_COMBINATOR.AND)}
        className={cx('xs-py-4 xs-px-8', { on: combinator === CONDITION_COMBINATOR.AND })}
      >
        <FormattedMessage id="filters.conditions.combinator.and" />
      </Button>
      <Button
        onClick={() => onClick(CONDITION_COMBINATOR.OR)}
        className={cx('xs-py-4 xs-px-8', { on: combinator === CONDITION_COMBINATOR.OR })}
      >
        <FormattedMessage id="filters.conditions.combinator.or" />
      </Button>
    </Button.Group>
  )
}

export default ConditionCombinatorGroup
