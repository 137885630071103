import './popupInfo.less'
import React from 'react'
import { Info as SvgInfo } from 'phosphor-react'
import { Popup, StrictPopupProps } from 'semantic-ui-react'

interface IProps {
  text: string | React.ReactElement
  position?: StrictPopupProps['position']
  on?: StrictPopupProps['on']
  colorIcon?: string
  sizeIcon?: number
  trigger?: React.ReactElement | string | number
  className?: string
  iconClass?: string
  inverted?: boolean
  flowing?: boolean
  wide?: boolean | 'very'
  disabled?: boolean
}

const PopupInfo = (props: IProps) => {
  const {
    text,
    position = 'top left',
    on = 'click',
    colorIcon = '#7D8791',
    sizeIcon = 14,
    className = '',
    iconClass = '',
    wide = '',
    inverted = false,
    trigger,
    flowing = false,
    disabled = false
  } = props
  const defaultTrigger = (
    <div className={`icon-box popup-info-trigger ${iconClass}`}>
      <SvgInfo size={sizeIcon} color={colorIcon} {...(inverted ? {} : { weight: 'fill' })} />
    </div>
  )

  return (
    <Popup
      data-test-id="popup-info"
      className={`popup-info ${className}`}
      popperModifiers={{ preventOverflow: { boundariesElement: 'window' } }}
      content={text}
      position={position}
      on={on}
      {...(wide ? { wide } : {})}
      size="small"
      flowing={flowing}
      trigger={trigger || defaultTrigger}
      disabled={disabled}
    />
  )
}

export default PopupInfo
