import { filterCurrentDayData } from '../../utils/timeUtil'
import { getUrlParam } from '../../utils/urlUtil'
import dayjs from 'dayjs'

/**
 * output: array
 * Aggregate attribute instances array of each week into array with total counts by week
 */
export const getDeltaReportingAttributesAggregation = (array) => {
  const preparedData = filterCurrentDayData(array || [])

  return preparedData.map((stats) => {
    const agreegatedAttributes = stats.attributes.reduce(
      (acc, attribute) => {
        return {
          startTotal: acc.startTotal + attribute.startTotal,
          total: acc.total + attribute.total,
          added: acc.added + attribute.added,
          deleted: acc.deleted + attribute.deleted,
          startAdded: acc.startAdded + attribute.startAdded,
          startDeleted: acc.startDeleted + attribute.startDeleted
        }
      },
      { startTotal: 0, startDeleted: 0, startAdded: 0, total: 0, added: 0, deleted: 0 }
    )
    return {
      startTimestamp: stats.startTimestamp,
      endTimestamp: stats.endTimestamp,
      startTotal: agreegatedAttributes.startTotal,
      total: agreegatedAttributes.total,
      added: agreegatedAttributes.added,
      deleted: agreegatedAttributes.deleted,
      startAdded: agreegatedAttributes.startAdded,
      startDeleted: agreegatedAttributes.startDeleted
    }
  })
}

/**
 * output: object
 * Prepare data for table widget with getting data of previous week to compare it with next week
 */
export const getDeltaReportAggregationTableData = (array, showDeleted = true) => {
  const preparedData = filterCurrentDayData(array)
  return (
    preparedData.map((stats, index) => {
      // compare added and deleted count with previous week
      const prevWeek = index === 0 ? stats : preparedData[index - 1]

      return {
        dateRange:
          dayjs(stats.startTimestamp).format('D MMM') +
          ' - ' +
          dayjs(stats.endTimestamp).format('D MMM'),
        startTotal: stats.startTotal,
        startAdded: prevWeek.added,
        ...(showDeleted ? { startDeleted: prevWeek.deleted, deleted: stats.deleted } : {}),
        added: stats.added,
        total: stats.total
      }
    }) || []
  )
}

/**
 * output: object
 * Aggregate attribute instances array of each week into single object with total counts
 */
export const getDeltaReportAttributeInstanceAggregation = (arr) => {
  // calculations based on 4-week trend, here it will be array of 4 items
  // "start" values is the initial values each of the week
  // to calculate delta, we need to compare sum of 4 weeks with values of 1st week
  const firstWeek = {
    startTotal: 0,
    startAdded: 0,
    startDeleted: 0
  }

  const monthAggregation = arr.reduce(
    (acc, week, index) => {
      // inside each week item there are attributes array, aggregate values to gate weekly sum
      const weekAggregation = week.attributes.reduce(
        (acc, attribute) => {
          return {
            startTotal: acc.startTotal + attribute.startTotal,
            startAdded: acc.startAdded + attribute.startAdded,
            startDeleted: acc.startDeleted + attribute.startDeleted,
            total: acc.total + attribute.total,
            added: acc.added + attribute.added,
            deleted: acc.deleted + attribute.deleted
          }
        },
        {
          startTotal: 0,
          startDeleted: 0,
          startAdded: 0,
          total: 0,
          added: 0,
          deleted: 0
        }
      )

      // check if it is a first week, then add start values
      if (index === 0) {
        firstWeek.startTotal = +weekAggregation?.startTotal || 0
        firstWeek.startAdded = +weekAggregation?.startAdded || 0
        firstWeek.startDeleted = +weekAggregation?.startDeleted || 0
      }

      return {
        startTotal: weekAggregation.startTotal,
        startAdded: weekAggregation.startAdded,
        startDeleted: weekAggregation.startDeleted,
        total: weekAggregation.total || acc.total,
        added:
          weekAggregation.added || weekAggregation.startAdded
            ? weekAggregation.startAdded + weekAggregation.added
            : acc.added,
        deleted:
          weekAggregation.deleted || weekAggregation.startDeleted
            ? weekAggregation.startDeleted + weekAggregation.deleted
            : acc.deleted
      }
    },
    { startTotal: 0, startAdded: 0, startDeleted: 0, total: 0, added: 0, deleted: 0 }
  ) || { startTotal: 0, startAdded: 0, startDeleted: 0, total: 0, added: 0, deleted: 0 }

  return {
    ...monthAggregation,
    ...firstWeek,
    added: monthAggregation.added,
    deleted: monthAggregation.deleted
  }
}

/**
 * output: object
 * Aggregate counts of each week into single object with total counts
 */
export const getDeltaReportAggregation = (statsByWeek) => {
  // calculations based on 4-week trend, here it will be array of 4 items
  // "start" values is the initial values each of the week
  // to calculate delta, we need to compare sum of 4 weeks with values of 1st week
  const firstWeek = {
    startDate: statsByWeek ? statsByWeek[0]?.startDate || '' : '',
    startTotal: statsByWeek ? +statsByWeek[0]?.startTotal : 0,
    startAdded: statsByWeek ? +statsByWeek[0]?.startAdded : 0,
    startDeleted: statsByWeek ? +statsByWeek[0]?.startDeleted : 0
  }
  const monthAggregation = (statsByWeek || []).reduce(
    (acc, week) => {
      return {
        total: week.total || acc.total,
        added: week.added || week.startAdded ? week.startAdded + week.added : acc.added,
        deleted: week.deleted || week.startDeleted ? week.startDeleted + week.deleted : acc.deleted,
        endDate: week.endTimestamp
      }
    },
    { total: 0, added: 0, deleted: 0, endDate: '' }
  )
  return {
    ...monthAggregation,
    ...firstWeek,
    added: monthAggregation.added,
    deleted: monthAggregation.deleted
  }
}

export const getDeltaReportFormattedDate = (date: string) => {
  return dayjs(date).format('D MMMM YYYY')
}

/**
 * Returns date range {startDate, endDate}
 * from the URL.
 *
 * @returns {object} Date range object having startDate and endDate
 */
const getStartEndDateFromURL = () => {
  const startDateParam = getUrlParam('startDate')
  const EndDateParam = getUrlParam('endDate')

  return {
    startDate: startDateParam ? new Date(startDateParam).toISOString() : '',
    endDate: EndDateParam ? new Date(EndDateParam).toISOString() : ''
  }
}

/**
 * Returns date range {startDate, endDate}
 * for the delta report.
 *
 * @returns {object} Date range object having startDate and endDate
 */
export const getDateRangeForDeltaReport = () => {
  const { startDate, endDate } = getStartEndDateFromURL()
  const dateObj = new Date()
  const now = dateObj.toISOString()
  dateObj.setDate(dateObj.getDate() - 4 * 7)

  return {
    startDate: startDate || dateObj.toISOString(),
    endDate: endDate || now
  }
}

export const percentageDeltaCalculator = (
  partialValue = 0,
  fullValue = 0,
  isEndOfRange = false
) => {
  if (isEndOfRange) {
    if (partialValue === 0 && fullValue > 0) return 100
    if (partialValue === fullValue) return 0
  } else {
    if (partialValue === 0 && fullValue > 0) return 100
    if (partialValue > 0 && partialValue === fullValue) return 100
  }

  return (100 * (fullValue - partialValue)) / partialValue
}
