import {
  DataSourceByEntity,
  InstancesByDatasourceWidgetItem,
  DataSourceRiskyWidget,
  DataSourcesParams,
  DataSourceSummary,
  DataSourceSummaryWidgetParams,
  IGetAttributeInstancesByDataSourceParams,
  StructuredSummaryWidget,
  UnstructuredSummaryWidget
} from './dataSourcesSlice'
import {
  API_FILTER_DATA_SOURCES,
  DATA_SOURCE_ID,
  DATA_SOURCE_TYPE_API_MAP,
  DATASOURCES_LIMIT_DEFAULT,
  ENTITY_ID,
  GRAPHQL_API_FILTERS,
  LIMIT_DEFAULT,
  OBJECT_TYPES
} from '../../constants'
import { DataSource } from '../../services/api/apiTypes'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { Drive } from '../../services/graphqlSchemaTypes'
import { gql } from 'graphql-request'

export const queryAttributeInstancesByDataSource = (
  params: IGetAttributeInstancesByDataSourceParams
): string => {
  const filters = params[ENTITY_ID] ? `(entityId: "${params[ENTITY_ID]}")` : ''
  return gql`
    {
      attributeInstanceGroupedbyDatasource${filters} {
        datasource {
          edges {
            node {
              id
              name
            }
          }
        }
        attributeInstanceCount
      }
    }
  `
}

// TODO: add type for graphql response
export const mapqueryAttributeInstancesByDataSource = (
  raw: any
): InstancesByDatasourceWidgetItem[] => {
  return raw.attributeInstanceGroupedbyDatasource.map((attribute) => ({
    id: attribute.datasource.edges[0].node.id,
    name: attribute.datasource.edges[0].node.name,
    count: attribute.attributeInstanceCount || 0
  }))
}

export const queryDataSourcesByEntity = (
  params: IGetAttributeInstancesByDataSourceParams
): string => {
  return gql`
    {
      userEntities(id: "${params[ENTITY_ID]}") {
        edges {
          node {
            name
            datasources {
              count
              edges {
                node {
                  id
                  name
                  type
                  attributeInstance(entityId: "${params[ENTITY_ID]}") {
                    count
                  }
                  tables(filter:{key: ${GRAPHQL_API_FILTERS.entityIds}, values:["${params[ENTITY_ID]}"]}){
                    count
                  }
                  objects(entityIds: ["${params[ENTITY_ID]}"], first: 1) {
                    count
                  }
                  createdBy
                }
              }
            }
          }
        }
      }
    }
  `
}

// TODO: add type for graphql response
export const mapQueryDataSourcesByEntity = (
  raw: any
): { list: DataSourceByEntity[]; total: number } => {
  const list = raw.userEntities.edges[0].node.datasources.edges.map(({ node: ds }) => ({
    dataSourceId: ds.id,
    dataSourceName: ds.name,
    dataSourceType: ds.type,
    attributesCount: ds.attributeInstance?.count || 0,
    objectsCount: ds.objects?.count || ds.tables?.count || 0,
    createdBy: ds.createdBy
  }))

  return { list, total: raw.userEntities.edges[0].node.datasources.count }
}

export const queryDataSourcesRiskyWidget = (): string => {
  return gql`
    {
      datasources {
        count
      }
      datasourcesRisky: datasources(isRisky: true) {
        count
        edges {
          node {
            id
            name
            type
          }
        }
      }
    }
  `
}

// TODO: add type for graphql response
export const mapQueryDataSourcesRiskyWidget = (raw: any): DataSourceRiskyWidget => ({
  total: raw.datasources.count || 0,
  risky: raw.datasourcesRisky.count || 0,
  datasources: raw.datasourcesRisky?.edges?.map(({ node }) => node)
})

export const queryDataSourcesUnstructuredSummary = (
  params: DataSourceSummaryWidgetParams
): string => {
  // unstructured types: [GOOGLE_DRIVE, GMAIL, SLACK, ELASTICSEARCH, ONE_DRIVE, SHAREPOINT, AWS_S3, OUTLOOK, SALESFORCE]
  const FRAGMENT_COUNTS = `objects(booleanFilter: {key: ${GRAPHQL_API_FILTERS.isSensitive}, value: true}){
    count
  }
  objectsAtRisk: objects(booleanFilter: {key: ${GRAPHQL_API_FILTERS.isRisky}, value: true}){
    count
  }
  files: objects(category: ${OBJECT_TYPES.file}, booleanFilter: {key: ${GRAPHQL_API_FILTERS.isSensitive}, value: true}) {
    count
  }
  filesAtRisk: objects(category: ${OBJECT_TYPES.file}, booleanFilter: {key: ${GRAPHQL_API_FILTERS.isRisky}, value: true}) {
    count
  }
  messages: objects(category: ${OBJECT_TYPES.message}, booleanFilter: {key: ${GRAPHQL_API_FILTERS.isSensitive}, value: true}) {
    count
  }
  messagesAtRisk: objects(category: ${OBJECT_TYPES.message}, booleanFilter: {key: ${GRAPHQL_API_FILTERS.isRisky}, value: true}) {
    count
  }
  blobs: objects(category: ${OBJECT_TYPES.blob}, booleanFilter: {key: ${GRAPHQL_API_FILTERS.isSensitive}, value: true}) {
    count
  }
  blobsAtRisk: objects(category: ${OBJECT_TYPES.blob}, booleanFilter: {key: ${GRAPHQL_API_FILTERS.isRisky}, value: true}) {
    count
  }
  `

  let query = ''
  if (params[DATA_SOURCE_ID]) {
    query = `{
              datasources(id: "${params[DATA_SOURCE_ID]}"){
                edges {
                  node {
                    ${FRAGMENT_COUNTS}
                  }
                }
              }
            }`
  } else {
    query = `{${FRAGMENT_COUNTS}}`
  }

  return gql`
    ${query}
  `
}

// TODO: add type for graphql response
export const mapQueryDataSourcesUnstructuredSummary = (raw: any): UnstructuredSummaryWidget => {
  const root = raw.datasources ? raw.datasources.edges[0].node : raw

  return {
    objectsCount: root.objects.count || 0,
    objectsAtRiskCount: root.objectsAtRisk.count || 0,
    filesCount: root.files.count || 0,
    filesAtRiskCount: root.filesAtRisk.count || 0,
    messagesCount: root.messages.count || 0,
    messagesAtRiskCount: root.messagesAtRisk.count || 0,
    blobsCount: root.blobs.count || 0,
    blobsAtRiskCount: root.blobsAtRisk.count || 0
  }
}

export const queryDataSourcesStructuredSummary = (
  params: DataSourceSummaryWidgetParams
): string => {
  const filterDsId = params[DATA_SOURCE_ID] ? `(id:"${params[DATA_SOURCE_ID]}")` : ''
  const commonFragment = gql`
      tables {
        count
      }
      databases {
        count
      }
      tableClusters {
          count
      }
  `
  if (filterDsId) {
    return gql`{
      datasources${filterDsId} {
        edges {
          node {
            ${commonFragment}
          }
        }
      }
    }`
  } else
    return gql`{
    ${commonFragment}
  }`
}

// TODO: add type for graphql response
export const mapQueryDataSourcesStructuredSummary = (raw: any): StructuredSummaryWidget => {
  try {
    const { datasources } = raw
    if (datasources) {
      const sum = raw.datasources.edges.reduce(
        (acc, current) => {
          const ds = current.node
          acc.databasesCount += ds?.databases?.count || 0
          acc.tablesCount += ds?.tables?.count || 0
          acc.totalClusterCount += ds?.tableClusters?.count || 0
          return acc
        },
        {
          databasesCount: 0,
          tablesCount: 0,
          totalClusterCount: 0
        }
      )
      return sum
    } else {
      return {
        databasesCount: raw.databases?.count || 0,
        tablesCount: raw.tables?.count || 0,
        totalClusterCount: raw.tableClusters?.count || 0
      }
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDataSourcesSensitiveTables = (id: string): string => {
  return gql`
    {
      datasources(id: "${id}") {
        edges {
          node {
            databases(first:${DATASOURCES_LIMIT_DEFAULT}) {
              edges {
                node {
                  name
                  tables(toggleFilter: { key: IS_PII_OR_MAYBE_PII }) {
                    count
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

// TODO: add type for graphql response
export const mapQueryDataSourcesSensitiveTables = (raw: any): any => {
  try {
    const dataSource = raw.datasources?.edges[0]?.node
    return (
      dataSource?.databases?.edges?.map(({ node }) => ({
        name: node.name || '',
        count: node.tables?.count || 0
      })) || []
    )
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDataSources = (params?: DataSourcesParams): string => {
  const basicFilters: { key: string; values?: string | string[]; value?: boolean }[] = []
  const limit = params?.limit || LIMIT_DEFAULT
  const cursor = getAfterCursor(params?.page || 1, limit)
  if (params?.dataSourceType) {
    basicFilters.push({
      key: API_FILTER_DATA_SOURCES.type,
      values: params?.dataSourceType?.split(',').map((ds) => DATA_SOURCE_TYPE_API_MAP[ds]) || ''
    })
  }
  if (params?.status) {
    basicFilters.push({
      key: API_FILTER_DATA_SOURCES.status,
      values: params?.status?.split(',').map((s) => s) || ''
    })
  }
  if (params?.owner) {
    basicFilters.push({
      key: API_FILTER_DATA_SOURCES.createdBy,
      values: params?.owner?.split(',').map((c) => c) || ''
    })
  }
  const allFiltersString = basicFilters.length ? parameterizeArrayofObjects(basicFilters) : ''

  return gql`
    {
      datasources(first: ${params?.pageSize || LIMIT_DEFAULT}, after: "${cursor}" ${
    allFiltersString ? ', filter:' + allFiltersString : ''
  }) {
        count
        edges {
          node {
            id
            name
            type
            createdBy
            status: state
            isSourceOfTruth
            location
            alerts(first: 1, filter: {key: STATUS, values: ["Active"]}){
              count
            }
            ropaDatasourceType
            ropaProcesses {
              count
            }
            labels{
              edges{
                node{
                  id
                  name
                  labelSetId
                  labelSetName
                }
              }
            }
            datasourceProgress {
              edges {
                node {
                  configuration {
                    columns{
                      total
                      failed
                      running
                      completed
                    }
                  }
                }
              }
            }
            policyActionConfiguration {
              actionType
              configuration {
                ... on PolicyActionArchiveConfigurationOutput {
                  archiveLocation
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryDataSources = (raw: any): { list: DataSource[]; total: number } => {
  const datasourcesList = raw.datasources.edges.map((edge) => {
    const ds = edge.node

    const totalColumnsCount =
      ds.datasourceProgress?.edges[0]?.node?.configuration?.columns?.total || 0
    const finishedColumnsCount =
      (ds.datasourceProgress?.edges[0]?.node?.configuration?.columns?.completed || 0) +
      (ds.datasourceProgress?.edges[0]?.node?.configuration?.columns?.failed || 0)

    return {
      id: ds.id,
      name: ds.name || '',
      type: ds.type || '',
      status: ds.status,
      createdBy: ds.createdBy,
      dataSourceType: ds.type || '',
      dataSourceName: ds.name || '',
      ropaDatasourceType: ds.ropaDatasourceType || '',
      location: ds.location || '',
      isSourceOfTruth: ds.isSourceOfTruth,
      alertsCount: ds.alerts?.count || 0,
      configuration: {
        datasourceType: ds.type || '',
        name: ds.name || ''
      },
      labels: ds.labels?.edges?.map((edge) => edge?.node) || [],
      ropaProcessesCount: ds.ropaProcesses?.count || 0,
      progress: Math.round((finishedColumnsCount / totalColumnsCount) * 100) || 0,
      archivalLocation: ds.policyActionConfiguration?.[0]?.configuration?.archiveLocation
    }
  })

  return {
    list: datasourcesList,
    total: raw.datasources.count || 0
  }
}

export const queryDataSourceOwners = (): string => {
  return gql`
    {
      datasources(first: 999) {
        edges {
          node {
            createdBy
          }
        }
      }
    }
  `
}

export const mapQueryDataSourceOwners = (raw: any): string[] => {
  return raw.datasources.edges.map(({ node: ds }) => ds.createdBy).filter((owner) => !!owner)
}

export const queryDataSourceDrives = (params: { ids: string[] }): string => {
  const idsStringified = JSON.stringify(params.ids)

  return gql`
    {
      drive(filter: [{ key: DRIVE_ID, values: ${idsStringified} }]) {
        edges {
          node {
            id
            driveId
            driveName
            type
            size
          }
        }
      }
    }
  `
}

export const mapQueryDataSourceDrives = (raw: any): Drive[] => {
  try {
    return raw.drive.edges.map(({ node: drive }) => ({
      id: drive.id,
      driveId: drive.driveId,
      driveName: drive.driveName || '',
      type: drive.type || '',
      size: drive.size || 0
    }))
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const queryDataSourcesSummary = (): string => {
  return gql`
    {
      datasources {
        edges {
          node {
            id
            name
            type
          }
        }
      }
    }
  `
}

export const mapQueryDataSourcesSummary = (raw: any): DataSourceSummary[] => {
  const datasourcesList = raw.datasources.edges.map((edge) => {
    const ds = edge.node
    return {
      id: ds.id,
      name: ds.name,
      type: ds.type
    }
  })

  return datasourcesList
}

export const queryResetPauseResumeError = (datasourceId: string) => {
  return gql`
    mutation {
      clearDatasourcePauseOrResumeError(
        actionInput: { clientMutationId: "1", datasourceId: "${datasourceId}" }
      ) {
        clientMutationId
        status
      }
    }
  `
}
