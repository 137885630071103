import '../../features/attributes/attributes.less'
import {
  DatabasesSummary,
  fetchDataSourcesSensitiveTables,
  resetDataSourcesSensitiveTables
} from './dataSourcesSlice'
import {
  MAP_ATTRIBUTE_TOP_CLASS_TO_COLORS,
  MAP_ATTRIBUTE_TOP_CLASS_TO_OLD_COLORS
} from '../../constants'
import ChartPie, { ChartPieItem } from '../../components/ChartPie/chartPie'
import NoSensitiveDataSvg from '../../assets/inlineSvg/noData.svg'
import { RootState } from '../../rootReducer'
import { getUrlParam } from '../../utils/urlUtil'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { noop } from 'underscore'
import { Typography } from '@lightbeamai/design-system'

const TOP_ATTRIBUTES_COUNT = 5
const COLOR_DEFAULT = '#A1DAB4'
interface IProps {
  databasesSummary?: { [key: string]: DatabasesSummary[] }
  dataSourceId?: string
  chartSize?: number
  fetchDataSourcesSensitiveTables: (id: string) => void
  resetDataSourcesSensitiveTables: () => void
  isClickable?: boolean
}

const SensitiveTablesWidget = ({
  databasesSummary,
  dataSourceId = '',
  chartSize = 180,
  resetDataSourcesSensitiveTables,
  fetchDataSourcesSensitiveTables,
  isClickable = true
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const data = dataSourceId && databasesSummary ? databasesSummary[dataSourceId] : undefined

  useEffect(() => {
    dataSourceId && fetchDataSourcesSensitiveTables(dataSourceId)
    return () => resetDataSourcesSensitiveTables()
  }, [dataSourceId, resetDataSourcesSensitiveTables, fetchDataSourcesSensitiveTables])

  const isPrint = getUrlParam('print')
  const printClass = isPrint ? 'print' : ''
  const sortedList = data?.slice().sort((a, b) => (b.count || 0) - (a.count || 0)) || []
  const top = sortedList.slice(0, TOP_ATTRIBUTES_COUNT)
  const total = top.length || 0
  const colors = isPrint ? MAP_ATTRIBUTE_TOP_CLASS_TO_OLD_COLORS : MAP_ATTRIBUTE_TOP_CLASS_TO_COLORS
  const filteredCategories = (total > 0
    ? top?.map(({ name, count = 0 }, i) => ({
        key: name,
        value: count,
        color: colors[i] || COLOR_DEFAULT
      })) || []
    : []) as ChartPieItem[]

  return (
    <>
      <div className={`dashboard-container-title centered xs-mb-0 ${printClass}`}>
        <Typography as="span" variant="base" color="primary">
          {intl.formatMessage({
            id: 'databases.widget.distribution.title'
          })}
        </Typography>
      </div>
      <div
        data-test-id="attributes-attributes-dashboard-widget"
        className={`attributes widget-grouped horizontal`}
      >
        {filteredCategories.length > 0 ? (
          <div className="chart-wrapper">
            <ChartPie
              data={filteredCategories}
              onClick={noop}
              width={chartSize}
              gap={5}
              showHalfCircle
              centerCountStyles={{ fontSize: '2.1rem', fontWeight: 700 }}
              className={'xs-mb-36 xs-mt-36'}
              isAnimated={false}
              isClickable={isClickable}
            />
            <div className="chart-text">
              <FormattedMessage id="databases.widget.distribution.pieText" />
            </div>
          </div>
        ) : (
          <div className="icon-box empty-state-text">
            <NoSensitiveDataSvg />
          </div>
        )}
        <div className="legend">
          {filteredCategories.length ? (
            <>
              {filteredCategories.map((item, i) => {
                const formattedCount = intl.formatNumber(item.value, { notation: 'compact' })
                return (
                  <div className="item btn" key={i}>
                    <div className="circle" style={{ backgroundColor: item.color }} />
                    <span className="key truncate">{item.key}</span>&nbsp;
                    <span className="value">{formattedCount}</span>
                  </div>
                )
              })}
            </>
          ) : (
            <div className="empty-state-text">
              {intl.formatMessage({ id: 'dashboard.widget.noSensitiveData' })}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  databasesSummary: state.dataSources.databasesSummary
})

const mapDispatchToProps = {
  fetchDataSourcesSensitiveTables,
  resetDataSourcesSensitiveTables
}

export default connect(mapStateToProps, mapDispatchToProps)(SensitiveTablesWidget)
