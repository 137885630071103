import {
  DsrDefaultTemplateSetting,
  DsrDefaultTemplateSettings,
  DsrEmailTemplate,
  DsrEmailTemplateTypes,
  DsrEmailTemplatesParams,
  SaveDsrEmailTemplateParams,
  SystemValueInfo,
  TestDsrEmailTemplateParams
} from './dsrEmailTemplatesSlice'
import { DsrRequestApiKeys } from '../dsrForms/dsrFormsSlice'
import { DsrRequestType } from '../../../services/graphqlSchemaTypes'
import { CMSettingsEmailTemplateEventTypeValues } from '../../consentManagement/constants'
import { gql } from 'graphql-request'

export const queryDsrEmailTemplates = (params: DsrEmailTemplatesParams) => {
  const { filters } = params

  let filter = ''
  if (filters && !!Object.values(filters).length) {
    const filtersStr = Object.entries(filters).map(([key, values]) => {
      return `{key: ${key}, values: ${JSON.stringify(values)}}`
    })
    filter += `,filter: [${filtersStr}]`
  }

  return gql`
    {
      dsrEmailTemplate(sortField: UPDATE_TIMESTAMP${filter}){
        edges {
          node {
            id
            name
            description
            eventType
            requestType
            createdAt
            updateTimestamp
            createdBy
            updatedBy
          }
        }
      }
    }
  `
}

export const mapQueryDsrEmailTemplates = (
  raw: any
): { list: DsrEmailTemplate[]; total: number } => {
  const list = raw.dsrEmailTemplate.edges.map(({ node: template }) => ({
    id: template.id,
    name: template.name,
    description: template.description,
    templateLanguage: template.templateLanguage,
    emailSubjectLine: template.emailSubjectLine,
    eventType: template.eventType,
    requestType: template.requestType,
    logo: template.logo,
    header: template.header,
    body: template.body,
    footer: template.footer,
    includedSystemValues: template.includedSystemValues,
    compiledHTMLTemplate: template.compiledHTMLTemplate,
    createdBy: template.createdBy,
    createdAt: template.createdAt,
    updateTimestamp: template.updateTimestamp
  }))

  return {
    list,
    total: list?.length || 0
  }
}

export const queryDsrEmailTemplateById = (params: DsrEmailTemplatesParams) => {
  const { id, filters } = params

  let filter = ''
  if (filters && !!Object.values(filters).length) {
    const filtersStr = Object.entries(filters).map(([key, values]) => {
      return `{key: ${key}, values: ${JSON.stringify(values)}}`
    })
    filter += `,filter: [${filtersStr}]`
  }

  return gql`
    {
      dsrEmailTemplate(id: "${id}" ${filter}) {
        edges {
          node {
            name
            description
            templateLanguage
            emailSubjectLine
            eventType
            requestType
            logo
            compiledHTMLTemplate
            header
            body
            footer
            includedSystemValues
          }
        }
      }
    }
  `
}

export const mapQueryDsrEmailTemplateById = (raw: any): DsrEmailTemplate => {
  const template = raw.dsrEmailTemplate.edges[0].node
  return {
    name: template.name,
    description: template.description,
    templateLanguage: template.templateLanguage,
    emailSubjectLine: template.emailSubjectLine,
    compiledHTMLTemplate: template.compiledHTMLTemplate,
    eventType: template.eventType,
    requestType: template.requestType || [],
    logo: template.logo,
    header: template.header,
    body: template.body,
    footer: template.footer,
    includedSystemValues: template.includedSystemValues || []
  }
}

export const queryDsrEmailTemplatePreviewInfo = (id: string) => {
  return gql`
    {
      dsrEmailTemplate(id: "${id}") {
        edges {
          node {
            name
            compiledHTMLTemplate
          }
        }
      }
    }
  `
}

export const mapQueryDsrEmailTemplatePreviewInfo = (raw: any): DsrEmailTemplate => {
  const template = raw.dsrEmailTemplate.edges[0].node
  return {
    name: template.name,
    compiledHTMLTemplate: template.compiledHTMLTemplate
  }
}

export const queryDsrEmailEventTypes = () => {
  return gql`
    {
      dsrEmailEvent {
        id
        eventType
      }
    }
  `
}

export const mapQueryDsrEmailEventTypes = (raw: any): DsrEmailTemplateTypes[] => {
  return raw.dsrEmailEvent.map(({ eventType }) => eventType)
}

export const queryDsrEmailTemplateSettings = () => {
  return gql`
    {
      dsrEmailEvent {
        eventType
        associatedTemplates {
          edges {
            node {
              id
              name
              eventType
              requestType
            }
          }
        }
        defaultTemplates {
          access {
            edges {
              node {
                id
                eventType
              }
            }
          }
          rectification {
            edges {
              node {
                id
                eventType
              }
            }
          }
          erasure {
            edges {
              node {
                id
                eventType
              }
            }
          }
          other {
            edges {
              node {
                id
                eventType
              }
            }
          }
          doNotSell {
            edges {
              node {
                id
                eventType
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryDsrEmailTemplateSettings = (raw: any): DsrDefaultTemplateSettings[] => {
  const settings: DsrDefaultTemplateSettings[] = []

  const dsrRequestTypes = [
    DsrRequestApiKeys.access,
    DsrRequestApiKeys.erasure,
    DsrRequestApiKeys.rectification,
    DsrRequestApiKeys.doNotSell,
    DsrRequestApiKeys.other
  ]
  //NOTE: Currently filtering event type of Consent Management - BE should provide ideal way to filter this
  raw.dsrEmailEvent
    .filter((event) => !CMSettingsEmailTemplateEventTypeValues.includes(event.eventType))
    .forEach((event) => {
      const setting: DsrDefaultTemplateSetting[] = []
      dsrRequestTypes.forEach((requestType) => {
        const requestTypeMapped =
          requestType == DsrRequestApiKeys.access
            ? DsrRequestType.Access
            : requestType == DsrRequestApiKeys.erasure
            ? DsrRequestType.Erasure
            : requestType == DsrRequestApiKeys.rectification
            ? DsrRequestType.Rectification
            : requestType == DsrRequestApiKeys.doNotSell
            ? DsrRequestType.DoNotSell
            : DsrRequestType.Other
        const associatedTemplateId = event.defaultTemplates[requestType]?.edges[0]?.node?.id
        const options =
          event.associatedTemplates?.edges
            ?.map((associatedTemplate) => associatedTemplate.node)
            .filter((associatedTemplate) => associatedTemplate?.requestType == requestTypeMapped) ||
          []

        options.length > 0 &&
          setting.push({
            associatedTemplateId,
            options,
            requestType: requestTypeMapped
          })
      })
      settings.push({
        eventType: event.eventType as DsrEmailTemplateTypes,
        setting
      })
    })
  return settings
}

export const querySystemValues = (eventType: string) => {
  return gql`
    {
      dsrEmailEvent(filter: [{key: EVENT_TYPE, values: ["${eventType}"]}]) {
        systemValues {
          displayName
          systemValue
        }
      }
    }
  `
}

export const mapQuerySystemValues = (raw: any): SystemValueInfo[] => {
  return (
    raw.dsrEmailEvent[0]?.systemValues?.map((sysValue) => ({
      displayName: sysValue.displayName,
      name: sysValue.systemValue
    })) || []
  )
}

export const mutationDeleteDsrEmailTemplate = (templateId: string) => {
  return gql`
    mutation {
      deleteDsrEmailTemplate(clientMutationId: "deleteDsrEmailTemplate", dsrEmailTemplateId: "${templateId}"){ clientMutationId }
    }
  `
}

export const queryMutationSaveDsrEmailTemplate = (data: SaveDsrEmailTemplateParams): any => {
  const {
    emailTemplateId,
    name = '',
    description = '',
    subject,
    footer,
    body,
    compiledHTMLTemplate,
    createdBy = '',
    systemValues = [],
    requestType,
    language,
    eventType
  } = data
  const mutationName = emailTemplateId ? 'updateDsrEmailTemplate' : 'createDsrEmailTemplate'
  const idParam = emailTemplateId ? `dsrEmailTemplateId: "${emailTemplateId}"` : ''
  const createdByParam = createdBy ? `createdBy: "${createdBy}"` : ''

  try {
    return gql`
      mutation {
        ${mutationName}(
          clientMutationId: "1"
          ${idParam}
          dsrEmailTemplateData: {
            name: "${name}"
            description: "${description}"
            emailSubjectLine: "${subject}"
            logo: ""
            header: ""
            ${createdByParam}
            body: ${body}
            footer: ${footer}
            templateLanguage: ${language}
            compiledHTMLTemplate: "${compiledHTMLTemplate}"
            requestType: ${requestType}
            eventType: ${eventType}
            includedSystemValues: [${systemValues.map((val) => val)}]
          }
        ) {
          clientMutationId
        }
      }
    `
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryMutationSaveDsrEmailTemplateSettings = (
  data: DsrDefaultTemplateSetting[]
): any => {
  try {
    return gql`
      mutation {
        associateDsrEmailTemplateToDsrEvents(
          clientMutationId: "1"
          eventTemplateAssociation: [${data.map(
            ({ requestType, eventType, associatedTemplateId }) =>
              `{requestType: ${requestType}, eventType: ${eventType}, associatedTemplateId: "${associatedTemplateId}"}`
          )}]
        ) {
          clientMutationId
        }
      }
    `
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryMutationSendTestEmail = (data: TestDsrEmailTemplateParams): any => {
  const { emails = [], subject, footer, body, compiledHTMLTemplate, requestType, eventType } = data
  const mails = emails.map((email) => '"' + email + '"')

  try {
    return gql`
      mutation {
        sendTestDsrEmailTemplate(
          clientMutationId: "1"
          emailIds: [${mails.join(',')}]
          dsrEmailTemplateData: {
            emailSubjectLine: "${subject}"
            logo: ""
            header: ""
            body: ${body}
            footer: ${footer}
            compiledHTMLTemplate: "${compiledHTMLTemplate}"
            requestType: ${requestType}
            eventType: ${eventType}
          }
        ) {
          clientMutationId
        }
      }
    `
  } catch (error) {
    console.error(error)
    throw error
  }
}
