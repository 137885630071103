import { IS_ALL, URL_ENTITIES, URL_OVERVIEW } from '../../../constants'
import { getUpdatedUrl } from '../../../utils/urlUtil'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Header, List } from 'semantic-ui-react'

interface IHighlightedSearch {
  string: string
  search: string
}

const HighlightedSearch = ({ string, search }: IHighlightedSearch): React.ReactElement => {
  const startIx = string.toLowerCase().indexOf(search.toLowerCase())

  if (startIx < 0) return <span>{string}</span>

  const noBoldPart1 = string.slice(0, startIx)
  const noBoldPart2 = string.substring(startIx + search.length)
  const boldPart = string.slice(startIx, startIx + search.length)

  return (
    <span>
      {noBoldPart1}
      <strong>{boldPart}</strong>
      {noBoldPart2}
    </span>
  )
}

interface IProps {
  results: any[]
  search: string
}
const SearchResultsList = (props: IProps): React.ReactElement => {
  const { results, search } = props
  const getEntityDetailsUrl = (entityId) =>
    getUpdatedUrl({
      pathName: URL_ENTITIES + '/' + entityId + URL_OVERVIEW,
      pageName: URL_ENTITIES,
      pageQueryParams: {
        [IS_ALL]: true
      }
    })
  return (
    <List className="records-container" data-test-id="search-search-results-list">
      <Header className="records-container-header" as="h2">
        <FormattedMessage id="search.results.records.header" />
      </Header>
      {results.map((r, i) => {
        return (
          <Link to={getEntityDetailsUrl(r.id)} className="result-item" key={i}>
            <span className="header-like">
              <HighlightedSearch string={r.title} search={search} />
            </span>
            <List.Content>
              <div>
                {r.records} <FormattedMessage id="search.results.records.text" />
              </div>
              <div>
                {r.entities} <FormattedMessage id="search.results.records.entities" />
              </div>
            </List.Content>
          </Link>
        )
      })}
    </List>
  )
}

export default SearchResultsList
