import './percentage.less'
import cx from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'

interface IProps {
  percentage?: number
  direction?: 'up' | 'down'
  size?: 'sm' | 'md'
}

const PercentageIndicator = ({ size, percentage = 0, direction }: IProps): React.ReactElement => {
  const percentIncrease = direction === 'up' || percentage > 0
  const percentDecrease = direction === 'down' || percentage < 0
  const isSmall = size === 'sm'
  const intl = useIntl()
  return (
    <span
      className={cx('percent-indicator', {
        'percent-increase': percentIncrease,
        'percent-decrease': percentDecrease,
        'indicator-small': size
      })}
    >
      <span
        className={cx('triangle xs-mr-4', {
          'triangle-up': percentIncrease,
          'triangle-down': percentDecrease,
          'xs-ml-4': isSmall,
          'xs-ml-8': !isSmall
        })}
      />
      {percentage
        ? `${intl.formatNumber(Math.abs(percentage), {
            style: 'decimal',
            maximumFractionDigits: 2
          })}%`
        : ''}
    </span>
  )
}

export default PercentageIndicator
