import { useState, useEffect } from 'react'

const useDebouncedResize = (delay: number) => {
  const [key, setKey] = useState(0)

  useEffect(() => {
    let timer: NodeJS.Timeout

    const handleResize = () => {
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => setKey((prevKey) => prevKey + 1), delay)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      if (timer) clearTimeout(timer)
    }
  }, [delay])

  return key
}

export default useDebouncedResize
