import {
  createPolicyParameterMutation,
  deletePolicyParameterMutation,
  enableDisablePolicyParam,
  mapPolicyParameterById,
  mapQueryPolicyParameters,
  queryPolicyParameterById,
  queryPolicyParameters,
  updatePolicyParameterMutation
} from './queries'
import { PolicyParameter, PolicyParameterListItem, PolicyParametersData } from './types'
import graphqlService from '../../../services/graphqlService'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

interface PolicySettingsState {
  policyParamerters: {
    list?: PolicyParameterListItem[]
    total: number
    selectedPolicyParameter?: PolicyParameter
  }
}

export const ACTION_POLICY_PARAMETERS_FETCH = 'policy/policyParameters'
export const fetchPolicyParameters = createAsyncThunk(ACTION_POLICY_PARAMETERS_FETCH, async () => {
  const result = await graphqlService.execute(queryPolicyParameters())
  return mapQueryPolicyParameters(result)
})

export const ACTION_POLICY_PARAMETERS_FETCH_BY_ID = 'policy/policyParameters/fetchById'
export const fetchPolicyParameterById = createAsyncThunk(
  ACTION_POLICY_PARAMETERS_FETCH_BY_ID,
  async (id: string) => {
    const result = await graphqlService.execute(queryPolicyParameterById(id))
    return mapPolicyParameterById(result)
  }
)

export const ACTION_POLICY_PARAMETERS_CREATE = 'policy/policyParameters/create'
export const createPolicyParameters = createAsyncThunk(
  ACTION_POLICY_PARAMETERS_CREATE,
  async (params: PolicyParametersData) => {
    await graphqlService.execute(createPolicyParameterMutation(params))
  }
)

export const ACTION_POLICY_PARAMETERS_UPDATE = 'policy/policyParameters/update'
export const updatePolicyParameters = createAsyncThunk(
  ACTION_POLICY_PARAMETERS_UPDATE,
  async (params: PolicyParametersData) => {
    await graphqlService.execute(updatePolicyParameterMutation(params))
  }
)

export const ACTION_POLICY_PARAMETERS_DELETE = 'policy/policyParameters/delete'
export const deletePolicyParameter = createAsyncThunk(
  ACTION_POLICY_PARAMETERS_DELETE,
  async (id: string) => {
    await graphqlService.execute(deletePolicyParameterMutation(id))
  }
)

export const ACTION_POLICY_PARAMETERS_ENABLE_DISABLE = 'policy/policyParameters/enableDisable'
export const enableDisablePolicyParameter = createAsyncThunk(
  ACTION_POLICY_PARAMETERS_ENABLE_DISABLE,
  async (params: PolicyParameter) => {
    await graphqlService.execute(enableDisablePolicyParam(params))
  }
)

const initialState: PolicySettingsState = {
  policyParamerters: {
    total: 0
  }
}

export const policySettingsSlice = createSlice({
  name: 'policySettings',
  initialState,
  reducers: {
    resetSelectedPolicyParameter: (state) => {
      state.policyParamerters.selectedPolicyParameter =
        initialState.policyParamerters.selectedPolicyParameter
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPolicyParameters.fulfilled, (state, { payload }) => {
      state.policyParamerters.list = payload.list
      state.policyParamerters.total = payload.total
    })
    builder.addCase(fetchPolicyParameterById.fulfilled, (state, { payload }) => {
      state.policyParamerters.selectedPolicyParameter = payload
    })
  }
})

export const { resetSelectedPolicyParameter } = policySettingsSlice.actions

export default policySettingsSlice.reducer
