import '../../features/attributes/attributes.less'
import {
  ACTION_ATTRIBUTES_FETCH,
  AttributesTopListByDataSource,
  fetchTopAttributes,
  IGetAttributesFilterParams,
  resetAttributesAll
} from '../../features/attributes/attributesSlice'
import { getShowLoader } from '../../reducers/requestReducer'
import { RootState } from '../../rootReducer'
import useUrlContext from '../../hooks/useUrlContext'
import {
  DATA_SOURCE_ID,
  FILTER_ATTRIBUTE_NAME,
  GRAPHQL_API_FILTERS,
  MAP_ATTRIBUTE_OTHER_CLASS_TO_COLORS,
  MAP_ATTRIBUTE_TOP_CLASS_TO_COLORS,
  PAGE_VIEW_KEY,
  PAGE_VIEW_VALUES,
  SENSITIVE_LABEL,
  URL_ATTRIBUTES
} from '../../constants'

import ChartPie from '../../components/ChartPie/chartPie'
import LoaderView from '../../components/Loader/loader'
import { changeUrl, managePageFiltersMultiple } from '../../utils/urlUtil'
import NoSensitiveDataSvg from '../../assets/inlineSvg/noData.svg'
import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Typography } from '@lightbeamai/design-system'

const TOP_ATTRIBUTES_COUNT = 4

const COLOR_DEFAULT = '#A1DAB4'
interface IProps {
  topListByDs: AttributesTopListByDataSource[]
  topHighSensitiveList: AttributesTopListByDataSource[]
  showLoader: boolean
  parentUrl?: string
  dataSourceId?: string
  legendPosition?: 'bottom' | 'right'
  piClass?: string
  chartSize?: number
  isAnimated?: boolean
  fetchTopAttributes: (params: IGetAttributesFilterParams) => void
  resetAttributesAll: () => void
  isHighSensitive?: boolean
}

const StructutedDSAttributesDashboardWidget = (props: IProps): React.ReactElement => {
  const {
    topListByDs = [],
    topHighSensitiveList = [],
    showLoader,
    parentUrl = '',
    dataSourceId = '',
    legendPosition = 'bottom',
    piClass = 'xs-mb-36 xs-mt-36',
    chartSize = 180,
    isAnimated = true,
    fetchTopAttributes,
    resetAttributesAll,
    isHighSensitive = false
  } = props
  const intl = useIntl()
  const context = useUrlContext()
  const datasourceId = dataSourceId || context.contextFilters[DATA_SOURCE_ID] || ''

  const list = useMemo(
    () =>
      (isHighSensitive ? topHighSensitiveList : topListByDs).find(
        (item) => item.datasourceId === datasourceId
      )?.list || [],
    [isHighSensitive, datasourceId, topHighSensitiveList, topListByDs]
  )

  useEffect(() => {
    fetchTopAttributes({
      datasourceIds: [datasourceId],
      filter: [],
      isStructuredDS: true,
      ...(isHighSensitive ? { sensitivity: SENSITIVE_LABEL.HIGH } : {}),
      ...(isHighSensitive
        ? { booleanFilter: [{ key: GRAPHQL_API_FILTERS.enabled, value: true }] }
        : { booleanFilter: [{ key: 'HAS_COLUMN_CLASSIFICATION', value: true }] })
    })
    return () => resetAttributesAll()
  }, [datasourceId, fetchTopAttributes, resetAttributesAll])

  const baseUrl = parentUrl ? parentUrl + URL_ATTRIBUTES : context.pathname + URL_ATTRIBUTES

  const attributes = list.slice().sort((a, b) => (b.columnCount || 0) - (a.columnCount || 0))

  const top = attributes.slice(0, TOP_ATTRIBUTES_COUNT).map((a, i) => {
    return {
      key: a.name,
      internalName: a.internalName,
      value: a.columnCount || 0,
      color: MAP_ATTRIBUTE_TOP_CLASS_TO_COLORS[i] || COLOR_DEFAULT
    }
  })

  const topKeys = top.map((a) => a.key)
  const others = attributes
    .filter((a) => !topKeys.includes(a.name))
    .reduce(
      (acc, current) => {
        acc.value += current.columnCount || 0
        return acc
      },
      {
        key: 'Others',
        value: 0,
        color: MAP_ATTRIBUTE_OTHER_CLASS_TO_COLORS[0] || COLOR_DEFAULT
      }
    )

  const filteredCategories = others.value ? [...top, others] : top

  const handleOpenPageWithFilter = (value) => {
    const urlName = list.find(({ name: attribiuteName }) => attribiuteName === value)

    if (value === 'Others') {
      return changeUrl(baseUrl, { PAGE_VIEW_KEY: PAGE_VIEW_VALUES.card })
    } else if (urlName && urlName.internalName) {
      managePageFiltersMultiple({
        pathname: baseUrl,
        pageName: URL_ATTRIBUTES,
        filters: [
          {
            filterKey: PAGE_VIEW_KEY,
            filterValues: [PAGE_VIEW_VALUES.list],
            action: 'add'
          },
          {
            filterKey: FILTER_ATTRIBUTE_NAME,
            filterValues: [urlName.internalName],
            action: 'add'
          }
        ]
      })
    }
  }

  return (
    <>
      <div className="dashboard-container-title centered xs-mb-0">
        <Typography as="span" variant="h6" color="primary">
          {intl.formatMessage({ id: 'attributes.widget.distribution.title' })}:&nbsp;
          {isHighSensitive
            ? intl.formatMessage({
                id: 'attributes.widget.distribution.sensitivity'
              })
            : intl.formatMessage({
                id: 'attributes.widget.distribution.all'
              })}
        </Typography>
      </div>
      <div
        data-test-id="structuted-ds-attributes-dashboard-widget"
        className={`attributes widget-grouped ${
          legendPosition === 'bottom' ? 'vertical' : 'horizontal'
        }`}
      >
        {list && filteredCategories.length ? (
          <div className="chart-wrapper">
            <ChartPie
              data={filteredCategories.slice().reverse()}
              onClick={handleOpenPageWithFilter}
              width={chartSize}
              gap={5}
              showHalfCircle
              centerCountStyles={{ fontSize: '2.1rem', fontWeight: 700 }}
              totalLink={baseUrl + context.search}
              className={piClass}
              isAnimated={isAnimated}
            />
            {legendPosition === 'right' && (
              <div className="chart-text">
                {intl.formatMessage(
                  { id: 'tableRelationships.graph.piiColumns' },
                  { count: TOP_ATTRIBUTES_COUNT }
                )}{' '}
              </div>
            )}
          </div>
        ) : (
          <div className="icon-box empty-state-text">
            <NoSensitiveDataSvg />
          </div>
        )}
        <div className="legend">
          {filteredCategories.length ? (
            <>
              {legendPosition === 'right' && (
                <div className="legend-horizontal-title item">
                  <span className="key">
                    {intl.formatMessage(
                      { id: 'attributes.widget.distribution.topAttributes' },
                      { count: TOP_ATTRIBUTES_COUNT }
                    )}
                  </span>
                  <span className="value">
                    {intl.formatMessage({ id: 'tables.table.columns' })}
                  </span>
                </div>
              )}
              {filteredCategories.map((item, i) => {
                const goToFilterPage = () => handleOpenPageWithFilter(item.key)
                return (
                  <div className="item btn" key={i} onClick={goToFilterPage}>
                    <div className="circle" style={{ backgroundColor: item.color }} />
                    <span className="key truncate">{item.key}</span>&nbsp;
                    {legendPosition === 'bottom' ? (
                      <span className="value">({item.value})</span>
                    ) : (
                      <span className="value">{item.value}</span>
                    )}
                  </div>
                )
              })}
            </>
          ) : (
            <div className="empty-state-text">
              {intl.formatMessage({ id: 'dashboard.widget.noSensitiveData' })}
            </div>
          )}
        </div>
        <LoaderView showLoader={showLoader} />
      </div>
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  topListByDs: state.attributes.attributes.topList?.filter((item) => !item.isHighSensitive),
  topHighSensitiveList: state.attributes.attributes.highSensitiveTopList,
  showLoader: getShowLoader(ACTION_ATTRIBUTES_FETCH)
})

const mapDispatchToProps = { fetchTopAttributes, resetAttributesAll }

export default connect(mapStateToProps, mapDispatchToProps)(StructutedDSAttributesDashboardWidget)
