import {
  FetchAttributeInstancesByProjectParams,
  FetchProjectsListParams,
  FetchProjectSummaryParams,
  Project,
  ProjectAttributeInstanceInfo
} from './projectsSlice'
import { LIMIT_DEFAULT } from '../../constants'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { gql } from 'graphql-request'

export const queryProjectCards = (params: FetchProjectsListParams): string => {
  const { filters } = params
  let filterParamString = ''
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  if (filterString) {
    filterParamString += `(filter: ${filterString})`
  }
  return gql`
  {
    projects${filterParamString} {
      count
      edges {
        node {
          id
          name
          userEntities {
            count
          }
          attributes {
            count
          }
          objects(isSensitive: true) {
            count
          }
        }
      }
    }
  }
`
}

export const mapQueryProjectCards = (
  raw: any
): { list: Project[]; totalProjects: number; totalObjects: number } => {
  try {
    const list = raw.projects.edges.map(
      ({ node: { name, id, userEntities, attributes, objects } }) => ({
        projectId: id,
        projectName: name,
        totalEntities: userEntities.count || 0,
        totalAttributes: attributes.count || 0,
        totalObjects: objects.count || 0
      })
    )
    const totalObjects = list.reduce(
      (total: number, current: Project) => total + (current.totalObjects || 0),
      0
    )
    return { list, totalProjects: raw.projects.count || 0, totalObjects }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryProjectsList = (params: FetchProjectsListParams): string => {
  const { filters, datasourceId, entityId, page = 1 } = params

  const filterOptions: { key: string; values?: string | string[]; value?: boolean }[] = []

  if (filters && filters.filter) {
    filterOptions.push({
      key: filters?.filter?.[0]?.key || '',
      values: filters?.filter?.[0]?.values.toString().split(',') || ''
    })
  }

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  let paramString = ''
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filterOptions) : ''
  if (filterString) {
    paramString += `, filter: ${filterString}`
  }

  if (entityId) {
    paramString += `, entityIds: "${entityId}"`
  }
  return gql`
  {
    objects(datasourceIds: "${datasourceId}", first: ${LIMIT_DEFAULT}, after: "${cursor}" ${paramString}){
      count
      edges{
      node{
        id
        name,
        type,
        lastModifiedTime,
        project {
          count
          edges {
            node {
              id
              name
            }
          }
        }
        attribute{
          count
          edges{
            node{
              instanceCount
              internalName
              name
            }
          }
        }
        entity{
          count
        }
      }
    }
  }
}
`
}

export const mapQueryProjectsList = (
  raw: any
): { list: Project[]; totalProjects: number; totalObjects: number } => {
  try {
    if (raw.objects.count > 0) {
      const list = raw.objects.edges.map(
        ({ node: { id, name, type, lastModifiedTime, entity, attribute, project } }) => ({
          objectName: name,
          objectId: id,
          fileType: type,
          scanTime: lastModifiedTime || '',
          attributes: attribute?.edges?.map(({ node }) => ({ ...node })) || [],
          projectName: project?.edges[0]?.node?.name || '',
          totalProjects: project?.count || 0,
          totalAttributes: attribute?.count || 0,
          totalEntities: entity?.count || 0
        })
      )
      const totalProjects = list.reduce(
        (total: number, current: Project) => total + (current.totalProjects || 0)
      )
      return { list, totalObjects: raw.objects.count || 0, totalProjects }
    } else {
      const list = [
        {
          fileType: '',
          scanTime: '',
          projectName: '',
          totalAttributes: 0,
          totalEntities: 0
        }
      ]
      return { list, totalObjects: 0, totalProjects: 0 }
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAttributeInstancesByProject = (
  params: FetchAttributeInstancesByProjectParams
): string => {
  const { entityId, datasourceId } = params

  return gql`
  {
    attributeInstanceGroupedbyProject(entityId: "${entityId}", datasourceId: "${datasourceId}") {
      attributeInstanceCount
      project {
        edges {
          node {
            id
            name
          }
        }
      }
    }
}
`
}

export const mapQueryAttributeInstancesByProject = (
  raw: any
): { list: ProjectAttributeInstanceInfo[] } => {
  try {
    const list = raw.attributeInstanceGroupedbyProject?.map(
      ({ attributeInstanceCount = 0, project }) => ({
        count: attributeInstanceCount,
        id: project.edges[0]?.node?.id || '',
        name: project.edges[0]?.node?.name || ''
      })
    )
    return { list }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryProjectSummary = (params: FetchProjectSummaryParams): string => {
  const { projectId } = params
  return gql`
  {
    projects (id: "${projectId}") {
      count
      edges {
        node {
          userEntities {
            count
          }
          attributes {
            count
          }
          objects(isSensitive: true) {
            count
          }
        }
      }
    }
  }
`
}

export const mapQueryProjectSummary = (raw: any): { summary: Project[] } => {
  try {
    const summary = raw.projects.edges.map(({ node: { userEntities, attributes, objects } }) => ({
      totalEntities: userEntities.count || 0,
      totalAttributes: attributes.count || 0,
      totalObjects: objects.count || 0
    }))
    return { summary }
  } catch (error) {
    console.error(error)
    throw error
  }
}
