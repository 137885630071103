import PrintPageHeader from '../../components/header'
import SvgDatabase from '../../../assets/inlineSvg/database3.svg'
import SvgStarInShield from '../../../assets/inlineSvg/icon-star-in-shield.svg'
import SvgColumn from '../../../assets/inlineSvg/svg-column.svg'
import SvgTable from '../../../assets/inlineSvg/svg-table.svg'
import SvgFile from '../../../assets/inlineSvg/svg-file.svg'
import SvgUser from '../../../assets/inlineSvg/svg-user.svg'
import { DataSourcesOverview, HistoricalStats, fetchDataSourcesOverview } from '../../printSlice'
import { RootState } from '../../../rootReducer'
import SvgPiiDataType from '../../../assets/inlineSvg/icon-pii-data-type.svg'
import SvgDocClassifications from '../../../assets/inlineSvg/icon-doc-classifications.svg'
import { fetchUserInfo } from '../../../features/login/loginSlice'
import PercentageIndicator from '../../../components/PercentageIndicator/percentage'
import { getDate4WeeksBefore, getYesterday } from '../../../utils/timeUtil'
import {
  getDeltaReportAggregation,
  getDeltaReportAttributeInstanceAggregation,
  getDeltaReportFormattedDate,
  percentageDeltaCalculator
} from '../deltaReportUtil'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'

interface IProps {
  overview?: DataSourcesOverview
  historicalStats?: HistoricalStats
  fetchDataSourcesOverview: () => void
  fetchUserInfo: () => void
  classificationsCount?: number
  userFirstName?: string
  userLastName?: string
}
const PrintOverviewPage = (props: IProps): React.ReactElement => {
  const { overview, fetchDataSourcesOverview, fetchUserInfo, historicalStats } = props
  const {
    policiesCount = 0,
    policiesViolatedCount = 0,
    entitiesCount = 0,
    entitiesRiskyCount = 0,
    classifiedFilesCount = 0,
    classificationsCount = 0
  } = overview || {}

  // TODO: check usage on other pages, potential issue
  // additionally aggregate added and deleted stats
  const agreegatedDatasourceStats = getDeltaReportAggregation(historicalStats?.datasourceStats)
  const agreegatedAttributeStats = getDeltaReportAggregation(historicalStats?.attributeStats)
  const agreegatedColumnStats = getDeltaReportAggregation(historicalStats?.columnStats)
  const agreegatedTableStats = getDeltaReportAggregation(historicalStats?.tableStats)
  const agreegatedAttributeInstanceStats = getDeltaReportAttributeInstanceAggregation(
    historicalStats?.attributeInstanceStats || []
  )
  const agreegatedFileStats = getDeltaReportAggregation(historicalStats?.fileStats)
  const headerText = 'Overview'
  const intl = useIntl()
  useEffect(() => {
    fetchUserInfo()
    fetchDataSourcesOverview()
  }, [fetchDataSourcesOverview, fetchUserInfo])
  const percentageDeltaAgreegatedFiles = percentageDeltaCalculator(
    agreegatedFileStats.startTotal,
    agreegatedFileStats.total
  )
  const percentageDeltaAgreegatedTables = percentageDeltaCalculator(
    agreegatedTableStats.startTotal,
    agreegatedTableStats.total
  )
  const percentageDeltaAgreegatedColumns = percentageDeltaCalculator(
    agreegatedColumnStats.startTotal,
    agreegatedColumnStats.total
  )
  const percentageDeltaAgreegatedAttributes = percentageDeltaCalculator(
    agreegatedAttributeStats.startTotal,
    agreegatedAttributeStats.total
  )
  const percentageDeltaAgreegatedAttributeInstances = percentageDeltaCalculator(
    agreegatedAttributeInstanceStats.startTotal,
    agreegatedAttributeInstanceStats.total
  )

  return (
    <div id="overview" className="delta-print-page delta-page-overview">
      <PrintPageHeader text={headerText} />
      <div className="widget-title overview-title">
        <span className="bold-text">4 Weeks Trend</span>(
        {getDeltaReportFormattedDate(getDate4WeeksBefore())} -{' '}
        {getDeltaReportFormattedDate(getYesterday())})
      </div>
      <div className="wrapper">
        <div className="cards-list">
          <div className="card">
            <div className="card-header">
              <div className="icon-box db">
                <SvgDatabase />
              </div>
              <div className="text">Monitored Data Sources</div>
            </div>
            <div className="card-content">
              <div className="count-item">
                <div
                  className={'count' + (agreegatedDatasourceStats.total === 0 ? ' opaque50' : '')}
                >
                  {intl.formatNumber(agreegatedDatasourceStats.total, { notation: 'compact' })}
                </div>
                <div className="label">Total</div>
              </div>
              <div className="divider" />
              <div className="count-item total">
                <div
                  className={
                    'count total' + (agreegatedDatasourceStats.added === 0 ? ' opaque50' : '')
                  }
                >
                  {intl.formatNumber(agreegatedDatasourceStats.added, { notation: 'compact' })}
                </div>
                <div className="label">Added</div>
              </div>
              <div className="divider" />
              <div className="count-item total">
                <div
                  className={
                    'count total' + (agreegatedDatasourceStats.deleted === 0 ? ' opaque50' : '')
                  }
                >
                  {intl.formatNumber(agreegatedDatasourceStats.deleted, { notation: 'compact' })}
                </div>
                <div className="label">Deleted</div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="icon-box">
                <SvgFile />
              </div>
              <div className="text">Total Files</div>
            </div>
            <div className="card-content">
              <div className="count-item">
                <div className={'count' + (agreegatedFileStats.total === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(agreegatedFileStats.total, { notation: 'compact' })}
                  <PercentageIndicator percentage={percentageDeltaAgreegatedFiles} size="sm" />
                </div>
                <div className="label">Total</div>
              </div>
              <div className="divider" />
              <div className="count-item total">
                <div
                  className={'count total' + (agreegatedFileStats.added === 0 ? ' opaque50' : '')}
                >
                  {intl.formatNumber(agreegatedFileStats.added, { notation: 'compact' })}
                </div>
                <div className="label">Added</div>
              </div>
              <div className="divider" />
              <div className="count-item total">
                <div
                  className={'count total' + (agreegatedFileStats.deleted === 0 ? ' opaque50' : '')}
                >
                  {intl.formatNumber(agreegatedFileStats.deleted, { notation: 'compact' })}
                </div>
                <div className="label">Deleted</div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="icon-box">
                <SvgTable />
              </div>
              <div className="text">Tables With Sensitive Data</div>
            </div>
            <div className="card-content">
              <div className="count-item">
                <div className={'count' + (agreegatedTableStats.total === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(agreegatedTableStats.total, { notation: 'compact' })}
                  <PercentageIndicator percentage={percentageDeltaAgreegatedTables} size="sm" />
                </div>
                <div className="label">Total</div>
              </div>
              <div className="divider" />
              <div className="count-item total">
                <div
                  className={'count total' + (agreegatedTableStats.added === 0 ? ' opaque50' : '')}
                >
                  {intl.formatNumber(agreegatedTableStats.added, { notation: 'compact' })}
                </div>
                <div className="label">Added</div>
              </div>
              <div className="divider" />
              <div className="count-item total">
                <div
                  className={
                    'count total' + (agreegatedTableStats.deleted === 0 ? ' opaque50' : '')
                  }
                >
                  {intl.formatNumber(agreegatedTableStats.deleted, { notation: 'compact' })}
                </div>
                <div className="label">Deleted</div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="icon-box">
                <SvgColumn />
              </div>
              <div className="text">Columns With Sensitive Data</div>
            </div>
            <div className="card-content">
              <div className="count-item">
                <div className={'count' + (agreegatedColumnStats.total === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(agreegatedColumnStats.total, { notation: 'compact' })}
                  <PercentageIndicator percentage={percentageDeltaAgreegatedColumns} size="sm" />
                </div>
                <div className="label">Total</div>
              </div>
              <div className="divider" />
              <div className="count-item total">
                <div
                  className={'count total' + (agreegatedColumnStats.added === 0 ? ' opaque50' : '')}
                >
                  {intl.formatNumber(agreegatedColumnStats.added, { notation: 'compact' })}
                </div>
                <div className="label">Added</div>
              </div>
              <div className="divider" />
              <div className="count-item total">
                <div
                  className={
                    'count total' + (agreegatedColumnStats.deleted === 0 ? ' opaque50' : '')
                  }
                >
                  {intl.formatNumber(agreegatedColumnStats.deleted, { notation: 'compact' })}
                </div>
                <div className="label">Deleted</div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="icon-box">
                <SvgPiiDataType />
              </div>
              <div className="text">Sensitive Data Type</div>
            </div>
            <div className="card-content">
              <div className="count-item">
                <div
                  className={'count' + (agreegatedAttributeStats.total === 0 ? ' opaque50' : '')}
                >
                  {intl.formatNumber(agreegatedAttributeStats.total, { notation: 'compact' })}
                  <PercentageIndicator percentage={percentageDeltaAgreegatedAttributes} size="sm" />
                </div>
                <div className="label">Total</div>
              </div>
              <div className="divider" />
              <div className="count-item total">
                <div
                  className={
                    'count total' + (agreegatedAttributeStats.added === 0 ? ' opaque50' : '')
                  }
                >
                  {intl.formatNumber(agreegatedAttributeStats.added, { notation: 'compact' })}
                </div>
                <div className="label">Added</div>
              </div>
              <div className="divider" />
              <div className="count-item total">
                <div
                  className={
                    'count total' + (agreegatedAttributeStats.deleted === 0 ? ' opaque50' : '')
                  }
                >
                  {intl.formatNumber(agreegatedAttributeStats.deleted, { notation: 'compact' })}
                </div>
                <div className="label">Deleted</div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="icon-box">
                <SvgPiiDataType />
              </div>
              <div className="text">Sensitive Data Count</div>
            </div>
            <div className="card-content">
              <div className="count-item">
                <div
                  className={
                    'count' + (agreegatedAttributeInstanceStats.total === 0 ? ' opaque50' : '')
                  }
                >
                  {intl.formatNumber(agreegatedAttributeInstanceStats.total, {
                    notation: 'compact'
                  })}
                  <PercentageIndicator
                    percentage={percentageDeltaAgreegatedAttributeInstances}
                    size="sm"
                  />
                </div>
                <div className="label">Total</div>
              </div>
              <div className="divider" />
              <div className="count-item total">
                <div
                  className={
                    'count total' +
                    (agreegatedAttributeInstanceStats.added === 0 ? ' opaque50' : '')
                  }
                >
                  {intl.formatNumber(agreegatedAttributeInstanceStats.added, {
                    notation: 'compact'
                  })}
                </div>
                <div className="label">Added</div>
              </div>
              <div className="divider" />
              <div className="count-item total">
                <div
                  className={
                    'count total' +
                    (agreegatedAttributeInstanceStats.deleted === 0 ? ' opaque50' : '')
                  }
                >
                  {intl.formatNumber(agreegatedAttributeInstanceStats.deleted, {
                    notation: 'compact'
                  })}
                </div>
                <div className="label">Deleted</div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="icon-box">
                <SvgDocClassifications />
              </div>
              <div className="text">Document Classification</div>
            </div>
            <div className="card-content">
              <div className="count-item total">
                <div className={'count' + (classifiedFilesCount === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(classifiedFilesCount, { notation: 'compact' })}
                </div>
                <div className="label">Files</div>
              </div>
              <div className="divider" />
              <div className="count-item total">
                <div className={'count total' + (classificationsCount === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(classificationsCount, { notation: 'compact' })}
                </div>
                <div className="label">Classification</div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="icon-box">
                <SvgStarInShield />
              </div>
              <div className="text">Policies</div>
            </div>
            <div className="card-content">
              <div className="count-item">
                <div className={'count' + (policiesViolatedCount === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(policiesViolatedCount, { notation: 'compact' })}
                </div>
                <div className="label">Rule Set Violated</div>
              </div>
              <div className="divider" />
              <div className="count-item">
                <div className={'count total' + (policiesCount === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(policiesCount, { notation: 'compact' })}
                </div>
                <div className="label">Rule Sets</div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="icon-box">
                <SvgUser />
              </div>
              <div className="text">Data Subject Categories</div>
            </div>
            <div className="card-content">
              <div className="count-item">
                <div className={'count' + (entitiesRiskyCount === 0 ? ' opaque50' : ' is-risky')}>
                  {intl.formatNumber(entitiesRiskyCount, { notation: 'compact' })}
                </div>
                <div className="label">At risk</div>
              </div>
              <div className="divider" />
              <div className="count-item">
                <div className={'count total' + (entitiesCount === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(entitiesCount, { notation: 'compact' })}
                </div>
                <div className="label">Total</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  overview: state.print.overview,
  userFirstName: state.user.userInfo?.firstName,
  userLastName: state.user.userInfo?.lastName
})

const mapDispatchToProps = {
  fetchDataSourcesOverview,
  fetchUserInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintOverviewPage)
