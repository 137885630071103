import './classifications.less'
import {
  ACTION_CLASSIFICATIONS_GROUPED,
  fetchClassificationsGrouped,
  ClassificationsGroupedParams,
  resetGroupedWidget,
  ClassificationsTopListByDataSource
} from './classificationsSlice'
import { getShowLoader } from '../../reducers/requestReducer'
import { RootState } from '../../rootReducer'
import useUrlContext from '../../hooks/useUrlContext'
import {
  API_FILTER_DATA_SOURCES,
  CLASSICATION_TYPE,
  DATA_SOURCE_ID,
  FILTER_CLASS_KEY,
  FILTER_DANGER_KEY,
  MAP_ATTRIBUTE_OTHER_CLASS_TO_OLD_COLORS,
  MAP_ATTRIBUTE_TOP_CLASS_TO_OLD_COLORS,
  PAGE_VIEW_KEY,
  PAGE_VIEW_VALUES,
  URL_DOCUMENTS_ALL,
  URL_DOCUMENTS_CLASSIFIED
} from '../../constants'
import ChartPie from '../../components/ChartPie/chartPie'
import LoaderView from '../../components/Loader/loader'
import { FilterParams } from '../../interfaces'
import { changeUrl, getUpdatedUrl, managePageFiltersMultiple } from '../../utils/urlUtil'
import NoSensitiveDataSvg from '../../assets/inlineSvg/noData.svg'
import cx from 'classnames'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import React, { useEffect } from 'react'

const TOP_CLASSIFICATIONS_COUNT = 4
const COLOR_DEFAULT = '#A1DAB4'

interface IProps {
  classificationsByDs: ClassificationsTopListByDataSource[]
  showLoader: boolean
  parentUrl?: string
  piClass?: string
  folderId?: string
  legendItemClass?: string
  title?: string
  dataSourceId?: string
  isDashboard?: boolean
  legendPosition?: 'bottom' | 'right'
  chartSize?: number
  isAnimated?: boolean
  fetchClassificationsGrouped: (filters: ClassificationsGroupedParams) => void
  resetGroupedWidget: () => void
  isClickable?: boolean
}

const ClassificationsGroupedWidget = (props: IProps): React.ReactElement => {
  const {
    classificationsByDs = [],
    showLoader,
    folderId = '',
    parentUrl = '',
    piClass = 'xs-mb-36 xs-mt-36',
    legendItemClass = 'xs-mr-8',
    dataSourceId = '',
    title = 'classifications.widget.title',
    isDashboard = true,
    legendPosition = 'bottom',
    chartSize = 180,
    isAnimated = true,
    fetchClassificationsGrouped,
    resetGroupedWidget,
    isClickable = true
  } = props
  const intl = useIntl()
  const context = useUrlContext()
  const datasourceId = dataSourceId || context.contextFilters[DATA_SOURCE_ID] || ''
  const bucketId = context.contextFilters.bucketId
  const shareId = context.contextFilters.shareId
  const driveId = context.queryParams.driveId

  const list = classificationsByDs.find((item) => item.datasourceId === datasourceId)?.list || []

  useEffect(() => {
    const filters: FilterParams = {
      filter: [],
      booleanFilter: [{ key: API_FILTER_DATA_SOURCES.isSensitive, value: true }]
    }
    if (filters.filter) {
      datasourceId &&
        filters.filter.push({
          key: 'DATASOURCE_IDS',
          values: [datasourceId]
        })
      driveId &&
        filters.filter.push({
          key: 'DRIVE_IDS',
          values: [driveId as string]
        })
      bucketId &&
        filters.filter.push({
          key: 'BUCKET_NAMES',
          values: [bucketId as string]
        })
      folderId &&
        filters.filter.push({
          key: 'LB_FOLDER_IDS',
          values: [folderId as string]
        })
      shareId &&
        filters.filter.push({
          key: 'FILE_SHARE_IDS',
          values: [shareId as string]
        })
    }

    resetGroupedWidget()
    fetchClassificationsGrouped({ datasourceId, filters })
  }, [
    datasourceId,
    driveId,
    folderId,
    shareId,
    fetchClassificationsGrouped,
    resetGroupedWidget,
    bucketId
  ])

  const classifications = list.slice().sort((a, b) => b.count - a.count)

  const top = classifications
    .filter(
      (c) =>
        c.name !== CLASSICATION_TYPE.OTHERS &&
        c.name !== CLASSICATION_TYPE.ATTENTION_NEEDED &&
        c.name !== CLASSICATION_TYPE.UNCLASSIFIED &&
        c.name !== CLASSICATION_TYPE.MISC
    )
    .slice(0, TOP_CLASSIFICATIONS_COUNT)
    .map((c, i) => ({
      key: c.name,
      value: c.count,
      color: MAP_ATTRIBUTE_TOP_CLASS_TO_OLD_COLORS[i] || COLOR_DEFAULT
    }))

  const topKeys = top.map((a) => a.key)
  const others = classifications
    .filter(
      (c) =>
        c.name !== CLASSICATION_TYPE.ATTENTION_NEEDED &&
        c.name !== CLASSICATION_TYPE.UNCLASSIFIED &&
        c.name !== CLASSICATION_TYPE.MISC &&
        !topKeys.includes(c.name)
    )
    .reduce((acc, current) => ({ ...acc, value: acc.value + current.count }), {
      key: CLASSICATION_TYPE.OTHERS,
      value: 0,
      color: MAP_ATTRIBUTE_OTHER_CLASS_TO_OLD_COLORS[0] || COLOR_DEFAULT
    })

  const attentionNeeded = classifications
    .filter((c) => c.name === CLASSICATION_TYPE.ATTENTION_NEEDED)
    .map((c) => ({
      key: c.name,
      value: c.count,
      color: MAP_ATTRIBUTE_OTHER_CLASS_TO_OLD_COLORS[1] || COLOR_DEFAULT
    }))

  const sensitive = classifications
    .filter((c) => c.name === CLASSICATION_TYPE.MISC)
    .map((c) => ({
      key: c.name,
      value: c.count,
      color: MAP_ATTRIBUTE_OTHER_CLASS_TO_OLD_COLORS[2] || COLOR_DEFAULT
    }))
  const pathArr = context.pathname.split('/')
  !isDashboard && pathArr.pop()
  const redirectUrl = parentUrl
    ? parentUrl + URL_DOCUMENTS_CLASSIFIED
    : pathArr.join('/') + URL_DOCUMENTS_ALL

  const urlDocumentsList = getUpdatedUrl({
    pathName: redirectUrl,
    pageName: parentUrl ? URL_DOCUMENTS_CLASSIFIED : URL_DOCUMENTS_ALL,
    pageQueryParams: { [PAGE_VIEW_KEY]: PAGE_VIEW_VALUES.list, [FILTER_DANGER_KEY]: 'Sensitive' }
  })

  const filteredCategories = top
  if (others.value) {
    filteredCategories.push(others)
  }
  if (attentionNeeded[0]?.value) {
    filteredCategories.push(...attentionNeeded)
  }
  if (sensitive[0]?.value) {
    filteredCategories.push(...sensitive)
  }
  const handleOpenPageWithFilter = (value) => {
    if (value === CLASSICATION_TYPE.ATTENTION_NEEDED) {
      return changeUrl(urlDocumentsList)
    }

    if (value === CLASSICATION_TYPE.OTHERS) {
      return changeUrl(urlDocumentsList)
    }
    managePageFiltersMultiple({
      pathname: redirectUrl,
      pageName: URL_DOCUMENTS_CLASSIFIED,
      filters: [
        {
          filterKey: PAGE_VIEW_KEY,
          filterValues: [PAGE_VIEW_VALUES.list],
          action: 'add'
        },
        {
          filterKey: FILTER_CLASS_KEY,
          filterValues: [value],
          action: 'add'
        },
        {
          filterKey: FILTER_DANGER_KEY,
          filterValues: ['Sensitive'],
          action: 'add'
        }
      ]
    })
  }

  const urlAll = getUpdatedUrl({
    pathName: redirectUrl,
    pageName: URL_DOCUMENTS_CLASSIFIED,
    pageQueryParams: {
      [FILTER_DANGER_KEY]: 'Sensitive',
      [PAGE_VIEW_KEY]: PAGE_VIEW_VALUES.card
    }
  })

  return (
    <>
      <div className="dashboard-container-title centered xs-mb-0 print">
        {intl.formatMessage({ id: title })}
      </div>
      <div
        data-test-id="classifications-classifications-grouped-widget"
        className={`classifications widget-grouped ${
          legendPosition === 'bottom' ? 'vertical' : 'horizontal'
        }`}
      >
        {list && filteredCategories.length ? (
          <div className="chart-wrapper">
            <ChartPie
              className={piClass}
              data={filteredCategories.slice().reverse()}
              onClick={handleOpenPageWithFilter}
              width={chartSize}
              gap={5}
              showHalfCircle
              centerCountStyles={{
                fontSize: '2.14rem',
                fontWeight: 700
              }}
              totalLink={urlAll}
              isAnimated={isAnimated}
              isClickable={isClickable}
            />
            {legendPosition === 'right' && (
              <div className="chart-text">{intl.formatMessage({ id: 'menu.filters.objects' })}</div>
            )}
          </div>
        ) : (
          <div className="icon-box empty-state-text">
            <NoSensitiveDataSvg />
          </div>
        )}
        <div className="legend">
          {filteredCategories.length ? (
            filteredCategories.map((item, i) => {
              const formattedCount = intl.formatNumber(item.value, { notation: 'compact' })
              const goToFilterPage = () => handleOpenPageWithFilter(item.key)
              return (
                <div
                  className={cx('item btn', {
                    [legendItemClass]: i < filteredCategories.length - 1
                  })}
                  key={i}
                  onClick={goToFilterPage}
                >
                  <div className="circle" style={{ backgroundColor: item.color }} />
                  <span className="key truncate">{item.key}</span>&nbsp;
                  {legendPosition === 'bottom' ? (
                    <span className="value">({formattedCount})</span>
                  ) : (
                    <span className="value">{formattedCount}</span>
                  )}
                </div>
              )
            })
          ) : (
            <div className="empty-state-text">
              {intl.formatMessage({ id: 'dashboard.widget.noSensitiveData' })}
            </div>
          )}
        </div>
        <LoaderView showLoader={showLoader} />
      </div>
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  classificationsByDs: state.classifications.widgetGrouped,
  showLoader: getShowLoader(ACTION_CLASSIFICATIONS_GROUPED)
})

const mapDispatchToProps = { fetchClassificationsGrouped, resetGroupedWidget }

export default connect(mapStateToProps, mapDispatchToProps)(ClassificationsGroupedWidget)
