import SelectSensitivity from './components/SelectSensitivity'
import ItemsArea from '../../../../components/ItemsArea/itemsArea'
import ConditionCombinatorGroup from '../ConditionCombinatorGroup'
import SvgWarning from '../../../../assets/inlineSvg/icon-warning.svg'
import SvgInfoSelected from '../../../../assets/inlineSvg/icon-info-selected.svg'
import SvgRegulations from '../../../../assets/inlineSvg/icon-regulations.svg'
import {
  ACTIVE_STEP,
  ACTIVE_TAB,
  ALERT_ASSIGMENT_OPTIONS,
  EMAIL_REGEX_RFC2822,
  mapSeverityLevelToText,
  POLICY_CREATE_ALERT_STATUS,
  POLICY_FORM_STEPS,
  POLICY_REGULATIONS,
  REGULATIONS,
  SEVERITY_LEVEL
} from '../../../../constants'
import { getRegulationImage } from '../../../../utils/policyUtils'
import { changeUrl } from '../../../../utils/urlUtil'
import useUrlContext from '../../../../hooks/useUrlContext'
import { AlertAttributeCondition, ATTRIBUTE_CONDITION_SENSITIVITY } from '../../policiesSlice'
import RadioView from '../../../../components/RadioView/radioView'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Form,
  Accordion,
  Icon,
  Checkbox,
  CheckboxProps,
  Dropdown,
  DropdownProps
} from 'semantic-ui-react'
import cx from 'classnames'
import {
  MinusCircle as IconMinusCircle,
  PlusCircle as IconPlusCircle,
  Info as IconInfo,
  Warning as IconWarning,
  WarningCircle as IconWarningCircle
} from 'phosphor-react'
import React, { useState, useEffect, SyntheticEvent, Fragment } from 'react'
import { Typography } from '@lightbeamai/design-system'

export const mapSeverityLevelToIcon = {
  [SEVERITY_LEVEL.info]: <IconInfo size={15} color="#3776BA" weight="fill" className="xs-mr-4" />,
  [SEVERITY_LEVEL.warning]: (
    <IconWarning size={15} color="#FC9D03" weight="fill" className="xs-mr-4" />
  ),
  [SEVERITY_LEVEL.critical]: (
    <IconWarningCircle
      size={15}
      color="var(--color-severity-critical)"
      weight="fill"
      className="xs-mr-4"
    />
  )
}

export const mapSerivityLevelToColor = {
  [SEVERITY_LEVEL.info]: 'lavender',
  [SEVERITY_LEVEL.warning]: 'caution',
  [SEVERITY_LEVEL.critical]: 'error'
}

interface IProps {
  isEdit: boolean
  hasAttributeSets: boolean
  policyTypeId: string
  enableAlerts: string
  severity: string
  regulations: string[]
  notifyUsers: string[]
  generateAlertSettings: AlertAttributeCondition[]
  setSeverity: (severity: SEVERITY_LEVEL) => void
  setEnableAlerts: (status: POLICY_CREATE_ALERT_STATUS) => void
  setRegulations: (regulation: REGULATIONS[]) => void
  setNotifyUsers: (users: string[]) => void
  setGenerateAlertSettings: (settings: AlertAttributeCondition[]) => void
  alertAssignment: ALERT_ASSIGMENT_OPTIONS
  setAlertAssignment: (val: ALERT_ASSIGMENT_OPTIONS) => void
}

export enum ACCORDIAN_POLICY_STEP_3 {
  SELECT_ALERT_SETTINGS = 1,
  SELECT_REGULATIONS
}

const defaultAlertSettingLevel1: AlertAttributeCondition = {
  sensitivity: 'HIGH',
  attributeCount: 1,
  conditionOperator: null
}
const defaultAlertSettingLevel2: AlertAttributeCondition = {
  sensitivity: 'MEDIUM',
  attributeCount: 2,
  conditionOperator: 'OR'
}
const defaultAlertSettingLevel3: AlertAttributeCondition = {
  sensitivity: 'MEDIUM',
  attributeCount: 3,
  conditionOperator: 'AND'
}

const defaultSettings = [
  defaultAlertSettingLevel1,
  defaultAlertSettingLevel2,
  defaultAlertSettingLevel3
]
const CreatePolicyStep3 = ({
  isEdit,
  policyTypeId,
  severity,
  hasAttributeSets = true,
  enableAlerts,
  regulations,
  notifyUsers,
  generateAlertSettings,
  setEnableAlerts,
  setSeverity,
  setRegulations,
  setNotifyUsers,
  setGenerateAlertSettings,
  alertAssignment,
  setAlertAssignment
}: IProps) => {
  const intl = useIntl()
  const context = useUrlContext()
  const { queryParams } = context
  const [activeAccordion, setActiveAccordion] = useState<ACCORDIAN_POLICY_STEP_3 | null>(
    ACCORDIAN_POLICY_STEP_3.SELECT_ALERT_SETTINGS
  )
  const [regulationOptions, setRegulationOptions] = useState(
    POLICY_REGULATIONS.map((regulation) => ({
      text: regulation,
      key: regulation,
      value: regulation
    }))
  )
  const activeTab = queryParams[ACTIVE_TAB] as ACCORDIAN_POLICY_STEP_3
  const isAlertSettingsActive = activeAccordion == ACCORDIAN_POLICY_STEP_3.SELECT_ALERT_SETTINGS
  const isRegulationsActive = activeAccordion == ACCORDIAN_POLICY_STEP_3.SELECT_REGULATIONS

  useEffect(() => {
    !isEdit && setGenerateAlertSettings([defaultAlertSettingLevel1])
  }, [isEdit, setGenerateAlertSettings])

  useEffect(() => {
    if (activeTab) setActiveAccordion(activeTab)
    else if (activeTab === undefined) setActiveAccordion(null)
  }, [activeTab])

  const onChangeEnableAlertSettings = (_, { value }: CheckboxProps) =>
    setEnableAlerts(value as POLICY_CREATE_ALERT_STATUS)

  const severityDropdownOptions = Object.keys(mapSeverityLevelToText).map((severityKey) => ({
    text: intl.formatMessage({ id: mapSeverityLevelToText[severityKey] }),
    key: severityKey,
    value: severityKey,
    icon: mapSeverityLevelToIcon[severityKey]
  }))

  const alertAssignmentOptions = [
    {
      key: 1,
      value: ALERT_ASSIGMENT_OPTIONS.DATASOURCE_OWNER,
      text: intl.formatMessage(
        { id: 'ruleset.section.assignAlert.datasourceOwner' },
        {
          placeholder: (
            <Typography as="span" variant="baseMinus2" color="primary">
              (default)
            </Typography>
          )
        }
      )
    },
    {
      key: 2,
      value: ALERT_ASSIGMENT_OPTIONS.OBJECT_OWNER,
      text: intl.formatMessage({ id: 'ruleset.section.assignAlert.objectOwner' })
    }
  ]

  const handleSeverityChange = (_: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    setSeverity(data.value as SEVERITY_LEVEL)
  }

  const handleChangeRegulation = (_: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) =>
    setRegulations(data.value as REGULATIONS[])

  const handleAddRegulation = (_: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const valueAdded = data.value as string
    const newItem = {
      text: valueAdded as REGULATIONS,
      key: valueAdded as REGULATIONS,
      value: valueAdded as REGULATIONS
    }
    const updatedRgulationOptions = [...regulationOptions, newItem]
    setRegulationOptions(updatedRgulationOptions)
    const updatedValsArr = [...new Set([...regulations, valueAdded])]
    setRegulations(updatedValsArr as REGULATIONS[])
  }

  const handleToggleSelectAccordian = (tab, isActive) => {
    if (isActive)
      changeUrl('', { policyTypeId, [ACTIVE_STEP]: POLICY_FORM_STEPS.POLICY_FORM_STEP_3 }, true)
    else changeUrl('', { [ACTIVE_TAB]: tab })
  }

  const transSelectRegulation = intl.formatMessage({ id: 'ruleset.field.regulations.select' })
  const transAlertEnable = intl.formatMessage({ id: 'ruleset.field.alertSetting.enable' })
  const transAlertDisable = intl.formatMessage({ id: 'ruleset.field.alertSetting.disable' })
  const totalAlertSettings = generateAlertSettings.length
  const isAlertOptionsInLimit = totalAlertSettings < 3

  const handleChangeAttributeCount = (event, indexToUpdate: number) => {
    const newValue = event.target.value
    if (newValue > 0 && newValue < 5) {
      setGenerateAlertSettings(
        generateAlertSettings.map(({ sensitivity, attributeCount, conditionOperator }, i) => ({
          sensitivity,
          conditionOperator,
          attributeCount: i === indexToUpdate ? newValue : attributeCount
        }))
      )
    }
  }

  const handleSetSensitivity = (indexToUpdate: number, value: ATTRIBUTE_CONDITION_SENSITIVITY) =>
    setGenerateAlertSettings(
      generateAlertSettings.map(({ sensitivity, attributeCount, conditionOperator }, i) => ({
        attributeCount,
        conditionOperator,
        sensitivity: i === indexToUpdate ? value : sensitivity
      }))
    )

  const handleSetCombinator = (indexToUpdate: number, combinator: string) =>
    setGenerateAlertSettings(
      generateAlertSettings.map(({ sensitivity, attributeCount, conditionOperator }, i) => ({
        attributeCount,
        sensitivity,
        conditionOperator:
          i === indexToUpdate && combinator !== conditionOperator ? combinator : conditionOperator
      }))
    )

  const handleAddAlertSetting = () =>
    setGenerateAlertSettings([...generateAlertSettings, defaultSettings[totalAlertSettings]])

  const handleRemoveSetting = (indToDelete: number) => {
    const next = generateAlertSettings[indToDelete + 1]
    if (indToDelete) {
      setGenerateAlertSettings(generateAlertSettings.filter((_, i) => i !== indToDelete))
    } else if (next) {
      const settings = generateAlertSettings
        .map((setting, i) => {
          if (i === indToDelete + 1) {
            return {
              ...setting,
              conditionOperator: null
            }
          } else return setting
        })
        .filter((_, i) => i !== indToDelete)
      setGenerateAlertSettings(settings)
    }
  }

  const handleUpdateNotifyUsers = (users: string[]) => {
    const validUserEmails = new Set(
      users.map((user) => user.trim()).filter((user) => EMAIL_REGEX_RFC2822.test(user))
    )
    setNotifyUsers([...validUserEmails])
  }

  return (
    <div
      className="create-ruleset-step select-alert-regulations"
      data-test-id="policies-create-policy-step3"
    >
      <Form>
        <Accordion styled>
          <Accordion.Title
            active={isAlertSettingsActive}
            index={ACCORDIAN_POLICY_STEP_3.SELECT_ALERT_SETTINGS}
            onClick={handleToggleSelectAccordian.bind(
              null,
              ACCORDIAN_POLICY_STEP_3.SELECT_ALERT_SETTINGS,
              isAlertSettingsActive
            )}
            className="xs-py-24 xs-px-32"
          >
            <span className="title-text">
              {isAlertSettingsActive ? (
                <Icon name="chevron up" className="xs-mr-12" />
              ) : (
                <Icon name="chevron down" className="xs-mr-12" />
              )}
              <SvgWarning />
              <Typography as="span" variant="basePlus2" color="primary" className="xs-ml-8">
                <FormattedMessage id="ruleset.field.alertSettings" />
              </Typography>
            </span>
            {!isAlertSettingsActive && <SvgInfoSelected />}
          </Accordion.Title>
          <Accordion.Content active={isAlertSettingsActive} className="xs-pt-0 xs-mx-44 xs-px-12">
            <div className="xs-mb-32">
              <div
                className={cx('radio-alert xs-py-16 xs-px-24', {
                  active: enableAlerts === POLICY_CREATE_ALERT_STATUS.ENABLE
                })}
              >
                <Checkbox
                  value={POLICY_CREATE_ALERT_STATUS.ENABLE}
                  name="alert-setting"
                  checked={enableAlerts === POLICY_CREATE_ALERT_STATUS.ENABLE}
                  onChange={onChangeEnableAlertSettings}
                  label={transAlertEnable}
                  radio={true}
                />
              </div>
              <div
                className={cx('radio-alert xs-py-16 xs-px-24 xs-ml-24', {
                  active: enableAlerts === POLICY_CREATE_ALERT_STATUS.DISABLE
                })}
              >
                <Checkbox
                  value={POLICY_CREATE_ALERT_STATUS.DISABLE}
                  name="alert-setting"
                  checked={enableAlerts === POLICY_CREATE_ALERT_STATUS.DISABLE}
                  onChange={onChangeEnableAlertSettings}
                  label={transAlertDisable}
                  radio={true}
                />
              </div>
            </div>
            <div className="xs-p-24 box-policy">
              <Typography as="p" color="primary" variant="base" type="strong" className="xs-mb-12">
                <FormattedMessage id="ruleset.section.assignAlert.title" />
              </Typography>

              {alertAssignmentOptions.map((opt) => (
                <RadioView
                  key={opt.key}
                  checked={opt.value === alertAssignment}
                  onClick={() => setAlertAssignment(opt.value)}
                  content={opt.text}
                  className="xs-mb-12"
                />
              ))}
              <div className="asignees-list">
                <Typography as="label" color="secondary" variant="baseMinus2">
                  <FormattedMessage id="ruleset.field.notifyMember" />
                </Typography>
                <ItemsArea
                  items={notifyUsers || []}
                  onItemsChange={handleUpdateNotifyUsers}
                  textAddNewLabel={intl.formatMessage({
                    id: 'ruleset.field.notifyMember.control.placeholder'
                  })}
                  textAddNewPlaceholder={intl.formatMessage({
                    id: 'ruleset.field.notifyMember.placeholder'
                  })}
                />
              </div>
            </div>
            {hasAttributeSets && (
              <div className="xs-p-24 xs-mt-40 box-policy">
                <Typography
                  as="p"
                  variant="base"
                  color="primary"
                  type="strong"
                  className="xs-mb-12"
                >
                  <FormattedMessage id="ruleset.section.generateAlert.title" />
                </Typography>
                <div className="box-content xs-py-16 xs-px-24">
                  <Typography as="p" color="primary" variant="baseMinus2" className="xs-mb-20">
                    <FormattedMessage id="ruleset.section.generateAlert.subTitle" />
                  </Typography>
                  <div>
                    {totalAlertSettings === 0 && (
                      <div className="alert-control action xs-ml-4">
                        <label>-</label>
                        <button onClick={handleAddAlertSetting}>
                          <IconPlusCircle
                            size="18"
                            color="var(--color-primary-light)"
                            weight="fill"
                          />
                          <span>
                            <FormattedMessage id="ruleset.field.entities.add" />
                          </span>
                        </button>
                      </div>
                    )}
                    {generateAlertSettings.map(
                      ({ sensitivity, attributeCount, conditionOperator }, i) => {
                        const isLastItem = i === totalAlertSettings - 1
                        const hasMulipleSettings = totalAlertSettings > 1
                        const isLastSetting =
                          totalAlertSettings === 0 || (isAlertOptionsInLimit && isLastItem)

                        return (
                          <Fragment key={i}>
                            {conditionOperator && (
                              <div className="xs-mb-16">
                                <ConditionCombinatorGroup
                                  combinator={conditionOperator}
                                  onClick={handleSetCombinator.bind(null, i)}
                                />
                              </div>
                            )}
                            <div className="alert-control-rows xs-mb-16" key={i}>
                              <SelectSensitivity
                                value={sensitivity}
                                setSensitivity={handleSetSensitivity.bind(null, i)}
                              />
                              <div className="alert-control attr-count xs-ml-24 xs-mr-12">
                                <Typography as="label" variant="baseMinus2" color="secondary">
                                  <FormattedMessage id="ruleset.field.attributeCount" />
                                </Typography>
                                <input
                                  type="number"
                                  max={4}
                                  min={1}
                                  value={attributeCount}
                                  onChange={(event) => handleChangeAttributeCount(event, i)}
                                />
                              </div>
                              {hasMulipleSettings && (
                                <div className="alert-control action">
                                  <label>-</label>
                                  <button onClick={handleRemoveSetting.bind(null, i)}>
                                    <IconMinusCircle
                                      size="18"
                                      color="var(--color-primary-light)"
                                      weight="fill"
                                    />
                                  </button>
                                </div>
                              )}
                              {isLastSetting && (
                                <div className="alert-control action xs-ml-4">
                                  <label>-</label>
                                  <button onClick={handleAddAlertSetting}>
                                    <IconPlusCircle
                                      size="18"
                                      color="var(--color-primary-light)"
                                      weight="fill"
                                    />
                                    <span>
                                      <FormattedMessage id="ruleset.field.entities.add" />
                                    </span>
                                  </button>
                                </div>
                              )}
                            </div>
                          </Fragment>
                        )
                      }
                    )}
                  </div>
                  <div className="xs-mb-20 xs-mt-48 xs-pt-16 select-severity">
                    <Typography
                      as="label"
                      color="primary"
                      variant="base"
                      type="medium"
                      className="xs-mb-8 required"
                    >
                      <FormattedMessage id="ruleset.field.severity" />
                    </Typography>
                    <Dropdown
                      className="xs-py-16 xs-pl-24 xs-pr-0"
                      icon="chevron down"
                      defaultValue={severity}
                      compact
                      selection
                      onChange={handleSeverityChange}
                      options={severityDropdownOptions}
                    />
                  </div>
                </div>
              </div>
            )}
          </Accordion.Content>
          <Accordion.Title
            active={isRegulationsActive}
            index={ACCORDIAN_POLICY_STEP_3.SELECT_REGULATIONS}
            onClick={handleToggleSelectAccordian.bind(
              null,
              ACCORDIAN_POLICY_STEP_3.SELECT_REGULATIONS,
              isRegulationsActive
            )}
            className="xs-py-24 xs-px-32"
          >
            <span className="title-text">
              {isRegulationsActive ? (
                <Icon name="chevron up" className="xs-mr-12" />
              ) : (
                <Icon name="chevron down" className="xs-mr-12" />
              )}
              <SvgRegulations />
              <Typography as="span" variant="basePlus2" color="primary" className="xs-ml-8">
                <FormattedMessage id="ruleset.field.regulations.select" />{' '}
                <span>
                  &nbsp;
                  <FormattedMessage id="optional" />
                </span>
              </Typography>
            </span>
            {!isRegulationsActive && <SvgInfoSelected />}
          </Accordion.Title>
          <Accordion.Content
            active={isRegulationsActive}
            className="select-regulation xs-py-0 xs-mx-44 xs-px-12"
          >
            <Dropdown
              placeholder={transSelectRegulation}
              className="select-regulations"
              fluid
              search
              selection
              multiple
              allowAdditions
              wrapSelection
              closeOnChange
              icon={false}
              onChange={handleChangeRegulation}
              onAddItem={handleAddRegulation}
              options={regulationOptions.map((regulation) => ({
                ...regulation,
                content: (
                  <>
                    <img
                      src={`../../../../assets/images/regulationTypes/${getRegulationImage(
                        regulation.text as REGULATIONS
                      )}`}
                      className="xs-mr-8"
                    />
                    {regulation.text}
                  </>
                )
              }))}
              renderLabel={(label) => ({
                content: (
                  <>
                    <img
                      src={`../../../../assets/images/regulationTypes/${getRegulationImage(
                        label.text as REGULATIONS
                      )}`}
                      className="xs-mr-4"
                    />
                    {label.text}
                  </>
                )
              })}
              defaultValue={regulations}
            />
          </Accordion.Content>
        </Accordion>
      </Form>
    </div>
  )
}

export default CreatePolicyStep3
