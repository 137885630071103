import service from '../../services/api/apiService'
import {
  GDriveGroupsWidgets,
  GDriveGroupsWidgetsQueryParams,
  Group
} from '../../services/api/apiTypes'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import { IGetGroupsParams } from '../../interfaces'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export type GroupsListSettings = {
  list?: Group[]
  total?: number
  sort: SortParams
}

export interface GroupState {
  widget: GDriveGroupsWidgets | null
  all: GroupsListSettings
  risky: GroupsListSettings
  sensitive: GroupsListSettings
  gMail?: GDriveGroupsWidgets | null
  outLook?: GDriveGroupsWidgets | null
}

const initialList = {
  sort: defaultSortParams
}

export const initialState: GroupState = {
  widget: null,
  all: { ...initialList },
  risky: { ...initialList },
  sensitive: { ...initialList }
}

export const fetchGroupsGDriveWidgetData = createAsyncThunk(
  'groups/widget/gdrive',
  async (filters: GDriveGroupsWidgetsQueryParams): Promise<GDriveGroupsWidgets> =>
    await service.getGDriveGroupsWidgetData(filters)
)

export const fetchGroupsGmailWidgetData = createAsyncThunk(
  'groups/widget/gmail',
  async (filters: GDriveGroupsWidgetsQueryParams): Promise<GDriveGroupsWidgets> =>
    await service.getGmailGroupsWidgetData(filters)
)

export const fetchGroupsOutlookWidgetData = createAsyncThunk(
  'groups/widget/outlook',
  async (filters: GDriveGroupsWidgetsQueryParams): Promise<GDriveGroupsWidgets> =>
    await service.getOutlookGroupsWidgetData(filters)
)

export const fetchGroupsList = createAsyncThunk(
  'groups/list',
  async (filters: IGetGroupsParams) => await service.getGroupsList(filters)
)

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setSort: (state, { payload }) => {
      state[payload.list].sort = getSortDirection(state[payload.list].sort, payload.column)
    },
    resetWidget: (state) => {
      state.widget = initialState.widget
    },
    clearList: (state) => {
      state.all = { ...initialList }
      state.sensitive = { ...initialList }
      state.risky = { ...initialList }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGroupsGDriveWidgetData.fulfilled, (state, action) => {
      state.widget = action.payload
    })
    builder.addCase(fetchGroupsGDriveWidgetData.rejected, (state) => {
      state.widget = initialState.widget
    })
    builder.addCase(fetchGroupsList.fulfilled, (state, { payload }) => {
      const { list, total } = payload

      if (payload.risky) {
        state.risky.list = list
        state.risky.total = total
      } else if (payload.sensitive) {
        state.sensitive.list = list
        state.sensitive.total = total
      } else {
        state.all.list = list
        state.all.total = total
      }
    })
    builder.addCase(fetchGroupsGmailWidgetData.fulfilled, (state, action) => {
      state.gMail = action.payload
    })
    builder.addCase(fetchGroupsGmailWidgetData.rejected, (state) => {
      state.gMail = initialState.widget
    })
    builder.addCase(fetchGroupsOutlookWidgetData.fulfilled, (state, action) => {
      state.outLook = action.payload
    })
    builder.addCase(fetchGroupsOutlookWidgetData.rejected, (state) => {
      state.outLook = initialState.widget
    })
  }
})

export const { setSort, clearList, resetWidget } = groupsSlice.actions

export default groupsSlice.reducer
