import {
  AttributeInstanceValuesParams,
  HistoricalInsightsParams,
  ListInstanceValues,
  TimestampInsight
} from './timeMachineSlice'
import { MAX_INSTANCES_LIST_PER_PAGE } from '../../constants'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { gql } from 'graphql-request'

export const queryHistoricalInsights = ({
  startTimestamp,
  endTimestamp,
  filters
}: HistoricalInsightsParams): string => {
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  let paramStr = ''
  if (filterString) {
    paramStr += `, filter: ${filterString}`
  }

  return gql`
    {
      historicalInsights(dateFilter: {
        key: DURATION, start: ${startTimestamp}, end: ${endTimestamp}
      } ${paramStr}
    ) {
        edges {
          node {
            lastSyncTime,
            insights {
              ... on AttributeInsights {
                timestamp
                datasource {
                  edges {
                    node {
                      id,
                      name,
                      type
                    }
                  }
                }
                createdAttribute: attributeInstance(instanceState: CREATED) { count }
                deletedAttribute: attributeInstance(instanceState: DELETED) { count }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryHistoricalInsights = (raw: any): TimestampInsight[] => {
  try {
    return raw.historicalInsights.edges[0].node.insights.map((insight) => {
      const info = insight.datasource.edges[0].node
      return {
        id: info.id,
        name: info.name,
        type: info.type,
        createdAttributesCount: insight.createdAttribute.count || 0,
        deletedAttributesCount: insight.deletedAttribute.count || 0,
        timestamp: insight.timestamp
      }
    })
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAttributeInstanceValues = ({
  attributeId,
  datasourceId,
  timestamp,
  page
}: AttributeInstanceValuesParams): string => {
  const cursor = getAfterCursor(page, MAX_INSTANCES_LIST_PER_PAGE)

  return gql` 
    {
      attributeInstance( 
        first: ${MAX_INSTANCES_LIST_PER_PAGE}, after: "${cursor}",datasourceIds: ["${datasourceId}"]
        dateFilter: {key: DURATION, start: ${timestamp}, end: ${timestamp}},
    filter: [{key: ATTRIBUTE_IDS, values: ["${attributeId}"]}]
      ) {
        count
        edges {
          node {
            values
            instanceState
            object {
              edges {
                node {
                  name
                }
              }
            }
            object {
              edges {
                node {
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryAttributeInstanceValues = (raw: any): ListInstanceValues => {
  try {
    return {
      list: raw.attributeInstance.edges.map(({ node: { values, instanceState, object } }) => ({
        instanceValue: values,
        instanceState,
        objectName: object.edges[0].node.name || ''
      })),
      total: raw.attributeInstance.count || 0
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
