import {
  mapQueryBreadcrumbsDatabase,
  queryBreadcrumbsDatabase,
  mapQueryBreadcrumbsTable,
  queryBreadcrumbsTable
} from './queries'
import { mapAttrubuteItemNameById, queryAttributeItemNameById } from '../attributes/queries'
import apiService from '../../services/api/apiService'
import {
  URL_DASHBOARD,
  URL_DATASOURCE_FORM_UNSTRUCTURED,
  URL_LOCATIONS,
  URL_DATA_SOURCES_UNSTRUCTURED,
  URL_DATA_SOURCES_STRUCTURED,
  URL_DOCUMENT,
  URL_DRIVES,
  URL_ENTITIES,
  URL_DOCUMENTS_ALL,
  URL_DOCUMENTS_CLASSIFIED,
  URL_DOCUMENTS_UNCLASSIFIED_WITH_ENTITIES,
  URL_DOCUMENTS_UNCLASSIFIED_WITHOUT_ENTITIES,
  URL_GROUPS,
  URL_RESIDENCIES,
  URL_SEARCH,
  URL_SETTINGS,
  URL_USERS,
  ACTION_BREADCRUMBS_GET,
  META_URL,
  DRIVE_ID,
  DATA_SOURCE_TYPE,
  PREVIEW,
  URL_CONVERSATIONS,
  ANNOTATION_TYPE,
  URL_EMAILS,
  URL_DATABASES,
  URL_TABLES,
  POLICY_FILTER_TYPES,
  URL_FILTERS,
  URL_INSIGHTS,
  URL_ATTRIBUTES_SET,
  URL_ATTRIBUTES,
  URL_CREATE,
  URL_PRIVACY_OPS,
  URL_DSR,
  URL_COOKIES_CONSENT,
  URL_DATA_SOURCES,
  URL_OVERVIEW,
  URL_ALERTS,
  URL_PRIVACY_PARTNERS,
  URL_OBJECTS_SHARED
} from '../../constants'
import { IBreadcrumb } from '../../interfaces'
import { formatBreadcrumbSubstring } from '../../utils/breadcrumbsUtil'
import { memCache } from '../../utils/cache'
import { UrlContextParams } from '../../utils/urlUtil'
import graphqlService from '../../services/graphqlService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { stringify } from 'query-string'

const cache = memCache()

const urlNameMap = {
  [URL_DASHBOARD]: 'breadcrumbs.page.dashboard',
  [URL_SEARCH]: 'breadcrumbs.page.search',
  [URL_DOCUMENT]: 'breadcrumbs.page.document',
  [URL_ENTITIES]: 'breadcrumbs.page.entities',
  [URL_DATASOURCE_FORM_UNSTRUCTURED]: 'breadcrumbs.page.datasourceForm',
  [URL_SETTINGS]: 'breadcrumbs.page.settings',
  [URL_RESIDENCIES]: 'breadcrumbs.page.residencies',
  [URL_LOCATIONS]: 'breadcrumbs.page.dataLocation',
  [URL_DOCUMENTS_ALL]: 'breadcrumbs.page.files',
  [URL_DOCUMENTS_CLASSIFIED]: 'breadcrumbs.page.classified',
  [URL_DOCUMENTS_UNCLASSIFIED_WITH_ENTITIES]: 'breadcrumbs.page.unclassified',
  [URL_DOCUMENTS_UNCLASSIFIED_WITHOUT_ENTITIES]: 'breadcrumbs.page.unclassified',
  [URL_USERS]: 'breadcrumbs.page.users',
  [URL_GROUPS]: 'breadcrumbs.page.groups',
  [URL_DATA_SOURCES_UNSTRUCTURED]: 'breadcrumbs.page.dataSources',
  [URL_DATA_SOURCES_STRUCTURED]: 'breadcrumbs.page.dataSources',
  [URL_DRIVES]: 'breadcrumbs.page.drives',
  [URL_CONVERSATIONS]: 'breadcrumbs.page.conversations',
  [URL_EMAILS]: 'breadcrumbs.page.emails',
  [URL_DATABASES]: 'breadcrumbs.page.databases',
  [URL_TABLES]: 'breadcrumbs.page.tables',
  [URL_FILTERS]: 'breadcrumbs.page.filters',
  [URL_INSIGHTS]: 'breadcrumbs.page.insights',
  [URL_ATTRIBUTES_SET]: 'breadcrumbs.page.attributesSet',
  [URL_ATTRIBUTES]: 'breadcrumbs.page.attributes',
  [URL_CREATE]: 'breadcrumbs.page.create',
  [URL_PRIVACY_OPS]: 'breadcrumbs.page.privacyOps',
  [URL_DSR]: 'breadcrumbs.page.dsr',
  [URL_COOKIES_CONSENT]: 'breadcrumbs.page.cookiesConsent',
  [URL_PRIVACY_PARTNERS]: 'breadcrumbs.page.privacyPartners',
  [URL_DATA_SOURCES]: 'breadcrumbs.page.dataSources',
  [URL_OVERVIEW]: 'breadcrumbs.page.overview',
  [URL_OBJECTS_SHARED]: 'breadcrumbs.page.objectsShared'
}

const dataSourceHandler = {
  fetch: async (dataSourceId: string) => {
    try {
      const cachedDS = await cache.get('dataSourceId:' + dataSourceId)
      if (cachedDS) {
        return JSON.parse(cachedDS).data.configuration?.name || dataSourceId
      }

      const result = await apiService.getDataSourceById(dataSourceId)

      if (result.data?.id) {
        await cache.set(
          'dataSourceId:' + dataSourceId,
          JSON.stringify({ createdAt: new Date(), data: result.data })
        )
      }

      return result.data?.configuration?.name || dataSourceId
    } catch {
      return dataSourceId
    }
  }
}

const documentHandler = {
  fetch: async (documentId: string) => {
    try {
      const result = await apiService.getDocumentById(documentId)
      return result?.name || documentId
    } catch {
      return documentId
    }
  }
}

const entityHandler = {
  fetch: async (entityId: string) => {
    try {
      const result = await apiService.getEntityById(entityId)
      return result?.name && result?.name.length ? result.name[0] : entityId
    } catch {
      return entityId
    }
  }
}

const emailHandler = {
  fetch: async (mailId: string) => {
    try {
      const result = await apiService.getEmailById(mailId)
      return result.subject || mailId
    } catch {
      return mailId
    }
  }
}

const gDriveHandler = {
  fetch: async (datasourceId: string, queryParams: UrlContextParams['queryParams']) => {
    try {
      const driveId = queryParams[DRIVE_ID] as string
      const key = `gDriveId:${datasourceId}:driveId${driveId}`
      const cachedDS = await cache.get(key)
      if (cachedDS) {
        return JSON.parse(cachedDS).data.driveName || driveId
      }

      const data = await apiService.getGDriveSummaryWidgetData({ datasourceId, driveId })

      if (data.driveName) {
        await cache.set(key, JSON.stringify({ createdAt: new Date(), data }))
      }

      return data?.driveName || driveId
    } catch {
      return queryParams[DRIVE_ID]
    }
  }
}

export const ACTION_BREADCRUMBS_DATABASE_FETCH = 'breadcrumbs/database'
const databaseHandler = {
  fetch: async (databaseId: string) => {
    try {
      const resultRaw = await graphqlService.execute(queryBreadcrumbsDatabase(databaseId))
      const result = mapQueryBreadcrumbsDatabase(resultRaw)

      return result.databaseName
    } catch {
      return databaseId
    }
  }
}

export const ACTION_BREADCRUMBS_TABLE_FETCH = 'breadcrumbs/table'
const tableHandler = {
  fetch: async (tableId: string) => {
    try {
      const resultRaw = await graphqlService.execute(queryBreadcrumbsTable(tableId))
      const result = mapQueryBreadcrumbsTable(resultRaw)

      return result.tableName
    } catch {
      return tableId
    }
  }
}

const policyFiltersHandler = {
  fetch: (filterTypeId: string) => {
    const filterType = POLICY_FILTER_TYPES.find((filterType) => filterType.id === filterTypeId)

    if (filterType) {
      return filterType.name
    } else {
      return filterTypeId
    }
  }
}

export const ACTION_BREADCRUMBS_ATTRIBUTE_SET = 'breadcrumbs/attributeSet'
const attributeSetHandler = {
  fetch: async (attrSetId: string) => {
    const queryNode = 'attributeSet'
    try {
      const resultRaw = await graphqlService.execute(
        queryAttributeItemNameById(attrSetId, queryNode)
      )
      return mapAttrubuteItemNameById(resultRaw, queryNode)
    } catch {
      return attrSetId
    }
  }
}

export const ACTION_BREADCRUMBS_ATTRIBUTES = 'breadcrumbs/attributeSet'
const attributeHandler = {
  fetch: async (attrId: string) => {
    const queryNode = 'attribute'
    try {
      const resultRaw = await graphqlService.execute(queryAttributeItemNameById(attrId, queryNode))
      return mapAttrubuteItemNameById(resultRaw, queryNode)
    } catch {
      return attrId
    }
  }
}

const mapUrlNames = {
  [URL_DOCUMENTS_CLASSIFIED]: documentHandler,
  [URL_DOCUMENTS_UNCLASSIFIED_WITH_ENTITIES]: documentHandler,
  [URL_DOCUMENTS_UNCLASSIFIED_WITHOUT_ENTITIES]: documentHandler,
  [URL_DOCUMENTS_ALL]: documentHandler,
  [URL_ENTITIES]: entityHandler,
  // [URL_CONVERSATIONS]: conversationsHandler, // API is not available
  [URL_EMAILS]: emailHandler,
  [URL_DATA_SOURCES_UNSTRUCTURED]: dataSourceHandler,
  [URL_DATA_SOURCES_STRUCTURED]: dataSourceHandler,
  [URL_DRIVES]: gDriveHandler,
  [URL_DATABASES]: databaseHandler,
  [URL_TABLES]: tableHandler,
  [URL_FILTERS]: policyFiltersHandler,
  [URL_ATTRIBUTES_SET]: attributeSetHandler,
  [URL_ATTRIBUTES]: attributeHandler
}

const mapFeatureMainSubpage = {
  [URL_INSIGHTS]: URL_ENTITIES,
  [URL_PRIVACY_OPS]: URL_DSR
}

export const getBreadcrumbsFromUrl = createAsyncThunk(
  ACTION_BREADCRUMBS_GET, // this type should be excluded in the requestReducer
  async (context: UrlContextParams): Promise<{ breadcrumbs: IBreadcrumb[] }> => {
    const parentUrl = context.queryParams[META_URL] || ''
    // remove empty elements, pathArray will be like: [data-sources, 601011901ecab83048df555c, drives, abhinay@gmail.com, files]
    const pathsArray = (parentUrl + context.pathname).split('/').filter((path) => !!path)
    // remove duplicate paths, for eg /data-sources/drives/drives/driveId
    const cleanedPaths = pathsArray.filter((path, i) => path !== pathsArray[i - 1])
    const pathParamsByLayer = getPathParamsByLayers(cleanedPaths, context.queryParams)
    const breadcrumbs: IBreadcrumb[] = []
    let counter = 0

    for await (const path of cleanedPaths) {
      counter++
      const pathSlashed = '/' + path
      const name = urlNameMap[pathSlashed]
      const search = '?' + pathParamsByLayer[counter - 1].search || ''
      const url = pathParamsByLayer[counter - 1].url
      const urlWithFeatureMainSubpage = `${url}${mapFeatureMainSubpage[url] || ''}`
      // if page in standard names, pass the name
      if (name) {
        breadcrumbs.push({ url: urlWithFeatureMainSubpage, name, isTranslate: true, search })
        continue
      }

      // if page not in standard names, try fetch the name by id
      const urlId =
        mapUrlNames['/' + cleanedPaths[counter - 1]] ||
        mapUrlNames['/' + cleanedPaths[counter - 2]] ||
        ''

      if (urlId) {
        const name = await urlId.fetch(path, context.queryParams)
        const nameTrimmed = formatBreadcrumbSubstring(name)
        breadcrumbs.push({
          url: urlWithFeatureMainSubpage,
          name,
          isTranslate: false,
          search,
          ...(name !== nameTrimmed ? { nameTrimmed } : {})
        })
        continue
      }

      // if url name not found, set name to url
      breadcrumbs.push({
        url: urlWithFeatureMainSubpage,
        name: pathSlashed.substring(1),
        isTranslate: false,
        search
      })
    }

    return { breadcrumbs }
  }
)

const pageCarriers = {
  [URL_DATA_SOURCES_UNSTRUCTURED]: { nestingAmount: 3 },
  [URL_DATA_SOURCES_STRUCTURED]: { nestingAmount: 3 },
  [URL_DRIVES]: { nestingAmount: 3 },
  [URL_ENTITIES]: { nestingAmount: 3, hiddenLevelsCount: 1 },
  [URL_ALERTS]: { nestingAmount: 3, hiddenLevelsCount: 1 },
  [URL_ATTRIBUTES_SET]: { nestingAmount: 3, hiddenLevelsCount: 1 },
  [URL_DOCUMENTS_ALL]: { nestingAmount: 3, hiddenLevelsCount: 1 },
  [URL_DOCUMENTS_CLASSIFIED]: { nestingAmount: 3, hiddenLevelsCount: 1 },
  [URL_DOCUMENTS_UNCLASSIFIED_WITH_ENTITIES]: { nestingAmount: 3, hiddenLevelsCount: 1 },
  [URL_DOCUMENTS_UNCLASSIFIED_WITHOUT_ENTITIES]: { nestingAmount: 3, hiddenLevelsCount: 1 },
  [URL_CONVERSATIONS]: { nestingAmount: 3, hiddenLevelsCount: 1 },
  [URL_EMAILS]: { nestingAmount: 3, hiddenLevelsCount: 1 },
  [URL_DATABASES]: { nestingAmount: 3 },
  [URL_TABLES]: { nestingAmount: 3, hiddenLevelsCount: 1 }
}

type Layer = {
  paths: string[]
  nestingAmount: number
  hiddenLevelsCount?: number
  queryParams: Array<UrlContextParams['queryParams']>
}

const getPathParamsByLayers = (urlPathArray, queryParams: UrlContextParams['queryParams']) => {
  const NORMAL_NESTING_LAYER = 2
  const layers: Layer[] = []

  // separate URL by layers. Carriers may have 2 pages due to :subPage
  // carrier: /data-sources/datasourceId/files
  // simple page: /entities/entityId
  urlPathArray.forEach((path) => {
    const carrier = pageCarriers['/' + path]
    const layerWithFreeCell = layers.find((layer) => layer.nestingAmount !== layer.paths.length)

    if (layerWithFreeCell) {
      layerWithFreeCell.paths.push(path)
    }

    if (carrier) {
      layers.push({ ...carrier, paths: [path] })
    }

    if (!carrier && !layerWithFreeCell) {
      layers.push({ nestingAmount: NORMAL_NESTING_LAYER, paths: [path], queryParams: [] })
    }
  })

  type QueryPathParams = Array<{ [key: string]: string | number | boolean }>
  type QueryLayerParams = { [queryName: string]: string | number | boolean }

  // enhance layers with query params. Each layer can see only its own query params
  const layersQueryAccumulator: QueryLayerParams = { [META_URL]: '' }
  const layersWithQuery: Layer[] = layers.map((layer) => {
    // const query = { ...queryIncrement }
    let queriesByPath: QueryPathParams = []

    layer.paths.forEach((path, i) => {
      const pathSlashed = '/' + path

      queriesByPath[i] = {}

      // add path-specific filters: files=isAtRisk:true,sortOrder:desc,sortColumn:name
      if (queryParams[pathSlashed]) {
        queriesByPath[i][pathSlashed] = queryParams[pathSlashed]
      }

      // add layer-specific queries
      if (
        pathSlashed === URL_DATA_SOURCES_UNSTRUCTURED ||
        pathSlashed === URL_DATA_SOURCES_STRUCTURED ||
        pathSlashed === URL_DRIVES
      ) {
        layersQueryAccumulator[DATA_SOURCE_TYPE] = queryParams[DATA_SOURCE_TYPE] || ''
      }

      if (
        pathSlashed === URL_DRIVES &&
        !layer.paths.includes(URL_DATA_SOURCES_UNSTRUCTURED.substr(1))
      ) {
        layersQueryAccumulator[DRIVE_ID] = queryParams[DRIVE_ID] || ''
      }
      if (
        pathSlashed === URL_DOCUMENTS_ALL ||
        pathSlashed === URL_DOCUMENTS_CLASSIFIED ||
        pathSlashed === URL_DOCUMENTS_UNCLASSIFIED_WITH_ENTITIES ||
        pathSlashed === URL_DOCUMENTS_UNCLASSIFIED_WITHOUT_ENTITIES ||
        pathSlashed === URL_EMAILS ||
        pathSlashed === URL_CONVERSATIONS
      ) {
        layersQueryAccumulator[PREVIEW] = queryParams[PREVIEW] || ''
      }
      if (pathSlashed === URL_CONVERSATIONS || pathSlashed === URL_EMAILS) {
        layersQueryAccumulator[ANNOTATION_TYPE] = queryParams[ANNOTATION_TYPE] || ''
      }

      queriesByPath = queriesByPath.map((queryByPath) => ({
        ...layersQueryAccumulator,
        ...queryByPath
      }))
    })

    // add to metaUrl paths from the previous layer
    layersQueryAccumulator[META_URL] = `${layersQueryAccumulator[META_URL]}/${layer.paths.join(
      '/'
    )}`

    return { ...layer, queryParams: queriesByPath }
  })

  // add sequential urls
  const layersWithUrls = layersWithQuery.map((layer) => {
    let urlIncrement = ''

    return layer.paths.map((path, i) => {
      const showPrevUrl = i >= layer.paths.length - (layer.hiddenLevelsCount || 0)
      urlIncrement += showPrevUrl ? '' : '/' + path
      return { path, url: urlIncrement, search: stringify(layer.queryParams[i]) }
    })
  })

  // flat array
  const flatted = layersWithUrls.flat()

  // remove duplicates
  let prevPath
  const withoutDuplicates = flatted.filter((item) => {
    const isDuplicate = item.path === prevPath
    prevPath = item.path
    return !isDuplicate
  })

  return withoutDuplicates
}

interface BreadcrumbsState {
  breadcrumbs: IBreadcrumb[]
}

export const initialState: BreadcrumbsState = {
  breadcrumbs: []
}

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBreadcrumbsFromUrl.fulfilled, (state, action) => {
      state.breadcrumbs = action.payload.breadcrumbs
    })
    builder.addCase(getBreadcrumbsFromUrl.rejected, (state) => {
      state.breadcrumbs = initialState.breadcrumbs
    })
  }
})

export default breadcrumbsSlice.reducer
