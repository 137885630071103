import { SlackWorkspaceWidget, SlackWidgetQueryParams } from '../../../../../services/api/apiTypes'
import service from '../../../../../services/api/apiService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface SlackWorkspaceDashboardState {
  widget: SlackWorkspaceWidget | null
}

export const initialState: SlackWorkspaceDashboardState = {
  widget: null
}

export const fetchSlackWorkspaceWidget = createAsyncThunk(
  'workspace/widget',
  async (filters: SlackWidgetQueryParams) => await service.getSlackWorkspaceWidget(filters)
)

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    resetWidget: (state) => {
      state.widget = initialState.widget
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSlackWorkspaceWidget.fulfilled, (state, action) => {
      state.widget = action.payload
    })
  }
})

export const { resetWidget } = workspaceSlice.actions

export default workspaceSlice.reducer
