import service from '../../../../../services/api/apiService'
import {
  ElasticSearchIndexFieldParams,
  ElasticSearchIndexFieldResponse,
  ElasticSearchIndexFieldUpdateParams
} from '../../../../../services/api/apiTypes'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const ELASTIC_SEARCH_INSTANCE_NAME = 'name'
export const ELASTIC_SEARCH_HOSTNAME = 'hosts'
export const ELASTIC_SEARCH_USER_NAME = 'username'
export const ELASTIC_SEARCH_PASSWORD = 'password'
export const ELASTIC_SEARCH_API_KEY_ID = 'apiKeyId'
export const ELASTIC_SEARCH_API_KEY_SECRET = 'apiKeySecret'
export const ELASTIC_SEARCH_AUTH_METHOD = 'authMethod'

export const BASIC_AUTH_METHOD = 'basic-auth'
export const API_KEY_METHOD = 'api-key'

export type ElasticSearchRegisterValues = {
  [ELASTIC_SEARCH_INSTANCE_NAME]: string
  [ELASTIC_SEARCH_HOSTNAME]: string
  [ELASTIC_SEARCH_USER_NAME]: string
  [ELASTIC_SEARCH_PASSWORD]: string
  [ELASTIC_SEARCH_API_KEY_ID]: string
  [ELASTIC_SEARCH_API_KEY_SECRET]: string
  [ELASTIC_SEARCH_AUTH_METHOD]: string
}

export type ElasticSearchConfiguration = {
  hosts: string
  username?: string
  password?: string
  apiKeyId?: string
  apiKeySecret?: string
}

export type ElasticSearchParams = {
  configuration: {
    name: string
    datasourceType: string
    elasticsearchConfiguration: ElasticSearchConfiguration
  }
}

export type ElasticSearchIndicesDetails = {
  indices?: string[]
  indexDetails?: {
    [index: string]: ElasticSearchIndexFieldResponse
  }
}

export const initialState: ElasticSearchIndicesDetails = {
  indices: [],
  indexDetails: {}
}

export const getIndices = createAsyncThunk('elasticSearch/indices/get', async (id: string) => {
  try {
    return await service.getElasticSearchIndicesById(id)
  } catch (e) {
    throw new Error(e as string)
  }
})

export const updateIndexFields = createAsyncThunk(
  'elasticSearch/indexFields/update',
  async (params: ElasticSearchIndexFieldUpdateParams, { rejectWithValue }) => {
    try {
      const result = await service.updateElasticSearchIndexFieldsById(params)
      return { statusMessage: 'elasticSearch.indexFields.update.success.text', ...result }
    } catch (e) {
      return rejectWithValue({ statusMessage: 'elasticSearch.indexFields.update.error.text' })
    }
  }
)

export const getIndexFields = createAsyncThunk<
  ElasticSearchIndexFieldResponse,
  ElasticSearchIndexFieldParams
>('elasticSearch/indexFields/get', async (params: ElasticSearchIndexFieldParams) => {
  try {
    const result = await service.getElasticSearchIndexFieldsById(params)
    return {
      ...params,
      fields: result.fields
    }
  } catch (e) {
    throw new Error(e as string)
  }
})

const elasticSearchSlice = createSlice({
  name: 'elasticSearch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIndices.fulfilled, (state, action) => {
      const { data } = action.payload
      state.indices = data
    })
    builder.addCase(getIndexFields.fulfilled, (state, action) => {
      const { index } = action.payload
      state.indexDetails = {
        ...state.indexDetails,
        [index]: action.payload
      }
    })
  }
})

export default elasticSearchSlice.reducer
