import Table from '../../../components/Table/table'
import { DataSource } from '../../../services/api/apiTypes'
import { RootState } from '../../../rootReducer'
import { sortByOrder } from '../../../utils/sortUtil'
import { STRUCTURED_DATASOURCES, DATA_SOURCE_TYPES, MIXED_DATASOURCES } from '../../../constants'
import RegularColumn from '../../../components/Table/regularColumn'
import { SORT_ORDER } from '../../../interfaces'
import PrintPageHeader from '../../components/header'
import {
  DatasourceAtrributeDelta,
  fetchDeltaPrintDataSourcesAttributeHighSensitivity
} from '../../printSlice'
import { getDate4WeeksBefore, getYesterday } from '../../../utils/timeUtil'
import {
  getDeltaReportAttributeInstanceAggregation,
  getDeltaReportFormattedDate
} from '../deltaReportUtil'
import { connect } from 'react-redux'
import { useIntl, FormattedNumber } from 'react-intl'
import React, { useEffect } from 'react'

export enum datasourceTypeEnum {
  STRUCTURED = 'Structured',
  UNSTRUCTURED = 'Unstructured'
}
/**Datasources where delete is not supported */
export const isAirByteDatasource = (dsType) => {
  return [...MIXED_DATASOURCES, DATA_SOURCE_TYPES.gmail].includes(dsType)
}
interface iProps {
  datasourcesList?: DataSource[]
  datasourceAttributeInstanceStats?: DatasourceAtrributeDelta
  datasourceType?: datasourceTypeEnum
  fetchDeltaPrintDataSourcesAttributeHighSensitivity: (datasourceId: string) => void
}
const DataSourcesList = ({
  datasourcesList,
  fetchDeltaPrintDataSourcesAttributeHighSensitivity,
  datasourceAttributeInstanceStats,
  datasourceType = datasourceTypeEnum.UNSTRUCTURED
}: iProps) => {
  const intl = useIntl()
  const listDefined = datasourcesList || []
  const filteredList = listDefined.filter((ds) => {
    if (
      ds.dataSourceType !== DATA_SOURCE_TYPES.generic &&
      ds.dataSourceType !== DATA_SOURCE_TYPES.remote
    ) {
      if (datasourceType === datasourceTypeEnum.STRUCTURED) {
        if (STRUCTURED_DATASOURCES.includes(ds.dataSourceType as DATA_SOURCE_TYPES)) {
          return true
        } else {
          return false
        }
      }
      if (datasourceType === datasourceTypeEnum.UNSTRUCTURED) {
        if (!STRUCTURED_DATASOURCES.includes(ds.dataSourceType as DATA_SOURCE_TYPES)) {
          return true
        } else {
          return false
        }
      }
    }
    return false
  })
  useEffect(() => {
    if (filteredList?.length) {
      filteredList.forEach((ds) => {
        if (ds.id) {
          fetchDeltaPrintDataSourcesAttributeHighSensitivity(ds.id)
        }
      })
    }
  }, [datasourcesList])
  /** Todo: remove this hardcoded sorting */

  const list = filteredList.map(({ dataSourceName, dataSourceType, id = '' }) => {
    const aggrStats = getDeltaReportAttributeInstanceAggregation(
      datasourceAttributeInstanceStats && datasourceAttributeInstanceStats[id]
        ? datasourceAttributeInstanceStats[id]
        : []
    )
    return {
      dataSourceName,
      dataSourceType,
      addedCount: aggrStats.added,
      deletedCount: aggrStats.deleted
    }
  })

  const sortedList = sortByOrder(list.slice(), 'addedCount', SORT_ORDER.DESC)

  /**Todo: dericve a type for column configuration */
  const columns = [
    {
      title: intl.formatMessage({ id: 'dataSources.table.name' }),
      dataKey: 'dataSourceName',
      cellRenderer: function RegularRenderer({ dataSourceName = '' }) {
        return <RegularColumn content={dataSourceName} title={dataSourceName} />
      }
    },
    {
      title: 'Added High Sensitivity Inst.',
      dataKey: 'attributesAdded',
      cellRenderer: function RegularRenderer({ addedCount }) {
        return (
          <RegularColumn
            content={<FormattedNumber value={addedCount} notation="compact" />}
            title={addedCount}
          />
        )
      }
    },
    {
      title: 'Deleted High Sensitivity Inst.',
      dataKey: 'attributesDeleted',
      cellRenderer: function RegularRenderer({ dataSourceType, alertsCount = '', deletedCount }) {
        const showDeleted = !isAirByteDatasource(dataSourceType)

        return (
          <RegularColumn
            tooltip={alertsCount}
            content={
              showDeleted ? <FormattedNumber value={deletedCount} notation="compact" /> : 'N/A'
            }
            title={deletedCount}
          />
        )
      }
    }
  ]
  const headerText =
    datasourceType === datasourceTypeEnum.UNSTRUCTURED
      ? 'Unstructured Datasources'
      : 'Structured Datasources'

  return (
    <div className="delta-print-page" id={'ds-list' + datasourceType}>
      <PrintPageHeader text={headerText} />
      <div className="widget-title overview-title">
        <span className="bold-text">4 Weeks Trend</span>(
        {getDeltaReportFormattedDate(getDate4WeeksBefore())} -{' '}
        {getDeltaReportFormattedDate(getYesterday())})
      </div>
      <div className="wrapper">
        {sortedList.length > 0 && (
          <Table
            columns={columns}
            data={sortedList}
            isCellRenderer
            scrollable={false}
            tableProps={{
              classes: {
                table: '',
                thead: '',
                th: 'delta-header-cell-bg'
              }
            }}
          />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  datasourceAttributeInstanceStats: state.print.datasourceHighSensitivityAttributeInstanceStats
})

const mapDispatchToProps = {
  fetchDeltaPrintDataSourcesAttributeHighSensitivity
}

export default connect(mapStateToProps, mapDispatchToProps)(DataSourcesList)
