import { RootState } from '../../../../../../rootReducer'
import {
  ElasticSearchIndexFieldParams,
  ElasticSearchIndexFieldResponse
} from '../../../../../../services/api/apiTypes'
import { getIndexFields } from '../elasticSearchSettingsSlice'
import { connect } from 'react-redux'
import React, { SyntheticEvent, useEffect } from 'react'
import { Dropdown } from 'semantic-ui-react'
interface IProps {
  datasourceId: string
  key: number
  index: string
  indexDetails?: {
    [index: string]: ElasticSearchIndexFieldResponse
  }
  getIndexFields: (params: ElasticSearchIndexFieldParams) => void
  setUpdatedField: (index: string, timestampField: string) => void
}
const IndexFieldDropDown = (props: IProps): React.ReactElement => {
  const { datasourceId, index, getIndexFields, indexDetails, key, setUpdatedField } = props
  const fields = indexDetails && indexDetails[index] && indexDetails[index].fields
  const fieldOptions = fields?.indexFields.map((field) => {
    return {
      value: field,
      text: field
    }
  })

  useEffect(() => {
    setTimeout(() => {
      getIndexFields({ datasourceId, index })
    }, key * 150)
  }, [])
  const onDropDownChange = (_: SyntheticEvent, data: any) => {
    setUpdatedField(index, data.value)
  }
  return fieldOptions ? (
    <Dropdown
      defaultValue={fields?.currentTimestampField}
      placeholder="Select Field"
      fluid
      search
      selection
      options={fieldOptions}
      onChange={onDropDownChange}
      data-test-id="datasources-elasticsearch-index-field-dropdown"
    />
  ) : (
    <></>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    indexDetails: state.elasticSearchSettings.indexDetails
  }
}

const mapDispatchToProps = {
  getIndexFields
}
export default connect(mapStateToProps, mapDispatchToProps)(IndexFieldDropDown)
