import { Typography } from '@lightbeamai/design-system'
import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

interface IProps {
  title: string
  content: string | number | React.ReactElement
  className?: string
  innerTextClassName?: string
  onClick?: () => void
  tooltip?: string
  link?: string
  newTabOnClick?: boolean
}

const RegularColumn = (props: IProps): React.ReactElement => {
  const {
    content,
    title,
    className = '',
    innerTextClassName = '',
    onClick,
    tooltip,
    link = '',
    newTabOnClick = false
  } = props

  const hoverTitle = typeof content === 'string' ? content : ''

  const ClickableComponent = link ? Link : ('div' as any)

  return (
    <>
      <Grid
        className={`${link ? 'with-url' : ''} regular-column mobile hidden xs-m-0 ${className}`}
        onClick={onClick}
        {...(tooltip ? { title: tooltip } : { title: hoverTitle })}
      >
        <ClickableComponent
          {...(newTabOnClick ? { target: '_blank', rel: 'noreferrer' } : {})}
          className={`truncate xs-p-0 ${innerTextClassName}`}
          to={link}
          onClick={onClick}
          data-test-id="regular-column"
        >
          <Typography color="primary">{content}</Typography>
        </ClickableComponent>
      </Grid>
      <Grid
        className={`${
          link ? 'with-url' : ''
        }  regular-column  mobile only m-content truncate xs-my-0 xs-mx-0 xs-p-0 ${className}`}
      >
        <ClickableComponent
          to={link}
          className="li xs-px-0"
          onClick={onClick}
          data-test-id="regular-column-mobile"
        >
          <p className="title-mobile">{title}</p>
          <div className="content-mobile truncate xs-p-0">{content}</div>
        </ClickableComponent>
      </Grid>
    </>
  )
}

export default RegularColumn
