import ContentEditable from 'react-contenteditable'
import React from 'react'
import { useIntl } from 'react-intl'

interface IProps {
  value: string
  onChange: (value: string) => void
  className?: string
  placeholder?: string
  innerRef?: any
}

const MultiLineInput = (props: IProps): React.ReactElement => {
  const intl = useIntl()

  const {
    value = '',
    className = '',
    onChange,
    innerRef = null,
    placeholder = intl.formatMessage({ id: 'placeholder.typeHere' })
  } = props

  const handleChange = (event) => onChange(event.currentTarget.textContent + '')

  return (
    <ContentEditable
      innerRef={innerRef}
      className={`editable ${className}`}
      placeholder={placeholder}
      html={value}
      onChange={handleChange}
      data-test-id="multiline-input"
    />
  )
}
export default MultiLineInput
