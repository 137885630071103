import {
  Column,
  ToggleTablePrimaryKeyParams,
  ColumnsByTableWidget,
  ColumnsByTableWidgetParams,
  ColumnsParams,
  ColumnsWidget,
  ColumnsWidgetParams,
  COLUMN_CHANGES,
  ColumnsSensitiveByDataSourceParams,
  ColumnsSensitiveByDataSource,
  ColumnsReviewParams,
  ColumnsCountParams,
  ColumnReviewStatuses,
  ColumnSamplesParams
} from './columnsSlice'
import {
  FILTER_PII_VALUES,
  GRAPHQL_API_FILTERS,
  GRAPHQL_CLASSIFICATION_TYPE,
  IS_ALL,
  IS_AT_RISK,
  IS_SENSITIVE,
  LIMIT_DEFAULT,
  PAGE,
  SEARCH_QUERY
} from '../../constants'
import { Identifier } from '../../services/api/apiTypes'
import { getAfterCursor } from '../../utils/graphqlUtil'
import { gql } from 'graphql-request'

export const queryIdentifiers = (): string => {
  return gql`
    {
      attribute(booleanFilter: [
        { key: ${GRAPHQL_API_FILTERS.isCategory}, value: false },
        { key: ${GRAPHQL_API_FILTERS.enabled}, value: true }
      ]) {
        edges {
          node {
            id
            name
            internalName
          }
        }
      }
    }
  `
}

// TODO: add type for graphql response
export const mapQueryIdentifiers = (raw: any): Identifier[] => {
  try {
    const list = raw.attribute.edges.map(({ node: identifier }) => ({
      id: identifier.id,
      name: identifier.name,
      internalName: identifier.internalName
    }))

    return list
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryColumns = (params: ColumnsParams): string => {
  return gql`
  {
    tables(id: "${params.tableId}") {
      edges {
        node {
          id
          name
          columns {
            count
            edges {
              node {
                name
                id
                constraint
                qualifiedName
                type: commonType
                isReviewed
                changeType
                classifications {
                  pii {
                    numRowsPII
                    attributeId
                    additionalAttributeIds
                  }
                  maybePii {
                    types {
                      attributeId
                      reason
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `
}

// TODO: add type for graphql response
export const mapQueryColumns = (raw: any): { list: Column[]; total: number } => {
  const list = raw.tables.edges[0].node.columns.edges.map(({ node: column }) => {
    const { additionalAttributeIds, attributeId = '', numRowsPII = 0 } =
      column.classifications?.pii || {}
    return {
      columnId: column.id,
      columnName: column.name,
      identifierId: attributeId,
      additionalAttributeIds: additionalAttributeIds || [],
      confidence: numRowsPII,
      constraint: column.constraint || '',
      qualifiedName: column.qualifiedName || '',
      isReviewed: column.isReviewed,
      changeType: column.changeType,
      type: column.type?.replace('CommonType.', '') || '' || '',
      dsrMasked: false,
      dsrIndex: false,
      changesStatus: COLUMN_CHANGES.noChanges,
      maybePii: column.classifications?.maybePii?.types || []
    }
  })

  const total = raw.tables.edges[0].node.columns.count || 0

  return { list, total }
}

export const queryClusterColumns = (params: ColumnsParams): string => {
  return gql`
    {
      databases(id: "${params.databaseId}") {
        edges {
          node {
            tableClusters(id: "${params.clusterId}") {
              edges {
                node {
                  columns {
                    count
                    edges {
                      node {
                        name
                        id
                        constraint
                        qualifiedName
                        type: commonType
                        isReviewed
                        classifications {
                          pii {
                            numRowsPII
                            attributeId
                            additionalAttributeIds
                          }
                          maybePii {
                            types {
                              attributeId
                              reason
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

// TODO: add type for graphql response
export const mapQueryClusterColumns = (raw: any): { list: Column[]; total: number } => {
  try {
    const list = raw.databases?.edges[0]?.node?.tableClusters?.edges[0].node?.columns?.edges.map(
      ({ node: column }) => {
        const columnName = column.name
        const { additionalAttributeIds, attributeId = '', numRowsPII = 0 } =
          column.classifications?.pii || {}

        return {
          columnId: column.id,
          columnName,
          header: columnName,
          isReviewed: column.isReviewed,
          identifierId: attributeId,
          additionalAttributeIds: additionalAttributeIds || [],
          confidence: numRowsPII,
          maybePii: column.classifications?.maybePii?.types || [],
          constraint: column.constraint || '',
          qualifiedName: column.qualifiedName || '',
          type: column.type?.replace('CommonType.', '') || '' || '',
          dsrMasked: false,
          dsrIndex: false,
          changesStatus: COLUMN_CHANGES.noChanges
        }
      }
    )

    const total = raw.databases?.edges[0]?.node?.tableClusters?.edges[0].node?.columns?.count || 0

    return { list, total }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryColumnsWidget = (params: ColumnsWidgetParams): string => {
  return gql`
    {
      databases(first: 1, id: "${params.databaseId}") {
        edges {
          node {
            columns(first: 1) {
              count
            }
            columnsPii: columns(first: 1, toggleFilter: { key: ${GRAPHQL_API_FILTERS.isSensitive}  }) {
              count
            }
            columnsNeedReview: columns(first: 1, booleanFilter: {key: ${GRAPHQL_API_FILTERS.isReviewed}, value: false}) {
              count
            }
            columnsNeedReviewPii: columns(
              first: 1,
              booleanFilter: [{key: ${GRAPHQL_API_FILTERS.isReviewed}, value: false}, {key: ${GRAPHQL_API_FILTERS.isSensitive}, value: true}])
            {
              count
            }
          }
        }
      }
    }
  `
}

// TODO: add type for graphql response
export const mapQueryColumnsWidget = (raw: any): ColumnsWidget => {
  try {
    return {
      columnsCount: raw.databases.edges[0]?.node?.columns?.count || 0,
      columnsPiiCount: raw.databases.edges[0]?.node?.columnsPii?.count || 0,
      columnsNeedReviewCount: raw.databases.edges[0]?.node?.columnsNeedReview?.count || 0,
      columnsNeedReviewPiiCount: raw.databases.edges[0]?.node?.columnsNeedReviewPii?.count || 0
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryColumnsSummaryByTableId = (params: ColumnsByTableWidgetParams): string => gql`
  {
    tables(id: "${params.tableId}") {
      edges {
        node {
          id
          name
          columns {
            count
            edges {
              node {
                isReviewed
                classifications {
                  pii {
                    attributeId
                  }
                  maybePii {
                    types {
                      attributeId
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

// TODO: add type for input graphql response
export const mapQueryColumnsSummaryByTableId = (raw: any): ColumnsByTableWidget => {
  const columnsPiiCount = raw.tables?.edges[0]?.node?.columns?.edges?.filter(
    ({ node: column }) =>
      !!column?.classifications?.pii?.attributeId || !!column?.classifications?.maybePii?.length
  ).length
  const columnsNeedReviewCount = raw.tables?.edges[0]?.node?.columns?.edges?.filter(
    ({ node: column }) => !column.isReviewed
  ).length
  return {
    tableId: raw.tables.edges[0].node.id,
    tableName: raw.tables.edges[0].node.name,
    columnsCount: raw.tables.edges[0].node.columns.count,
    columnsPiiCount,
    columnsNeedReviewCount
  }
}

export const queryReviewTable = (params: ColumnsByTableWidgetParams): string => gql`
  mutation {
    reviewTable(tableId: "${params.tableId}") {
      success
    }
  }
`
export const queryCreateTablePrimaryKey = (params: ToggleTablePrimaryKeyParams): string => gql`
  mutation {
    createTablePrimaryKey(columnId: "${params.columnId}") {
      success
    }
  }
`
export const queryDeleteTablePrimaryKey = (params: ToggleTablePrimaryKeyParams): string => gql`
  mutation {
    deleteTablePrimaryKey(columnId: "${params.columnId}") {
      success
    }
  }
`

export const queryColumnsBySensitivity = (
  params: ColumnsSensitiveByDataSourceParams
): string => gql`
  {
    datasources (id: "${params.datasourceId}"){
      edges {
        node {
          columns {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

// TODO: add type for input graphql response
export const mapQueryColumnsBySensitivity = (raw: any): ColumnsSensitiveByDataSource => {
  raw
  return {
    datasourceId: '',
    total: 0,
    highSensitivityCount: 0,
    mediumSensitivityCount: 0
  }
}

export const queryColumnsCount = (params: ColumnsCountParams): string => {
  const { filters, reviewStatus } = params

  let filter = ''
  let booleanFilter = ''
  if (params.piiDetection) {
    filter = mapPiiDetectionFilterToQuery(params.piiDetection)
  }
  const piiTablesFilter = params.piiDetection
    ? ''
    : `toggleFilter: { key: ${GRAPHQL_API_FILTERS.isPiiOrMaybePii} },`

  if (filters && !!Object.values(filters).length) {
    const filtersStr = Object.entries(filters).map(([key, values]) => {
      return `{key: ${key}, values: ${JSON.stringify(values)}}`
    })
    filter += `filter: [${filtersStr}],`
  }

  if (reviewStatus) {
    const value = reviewStatus === ColumnReviewStatuses.reviewed
    booleanFilter += `booleanFilter: [{key: ${GRAPHQL_API_FILTERS.isReviewed}, value: ${value} }],`
  }

  let searchQuery = ''
  if (params[SEARCH_QUERY]) {
    searchQuery = `query: "${params[SEARCH_QUERY]}", `
  }

  return gql`
    {
      datasources(id: "${params.datasourceId}") {
        edges {
          node {
            columns(first: 1, ${filter} ${booleanFilter} ${searchQuery}) { count }
            columnsPii: columns(first: 1, ${piiTablesFilter} ${filter} ${booleanFilter} ${searchQuery}){
              count
            }
            columnsNeedReview: columns(first: 1, booleanFilter: [{key: ${GRAPHQL_API_FILTERS.isReviewed}, value: false }], ${filter} ${searchQuery}){
              count
            }
          }
        }
      }
    }
  `
}
export const mapQueryColumnsCount = (
  raw: any
): { [IS_ALL]: number; [IS_SENSITIVE]: number; [IS_AT_RISK]: number } => {
  const root = raw.datasources

  return {
    [IS_ALL]: root.edges[0].node.columns?.count || 0,
    [IS_SENSITIVE]: root.edges[0].node.columnsPii?.count || 0,
    [IS_AT_RISK]: root.edges[0].node.columnsNeedReview?.count || 0
  }
}

const mapPiiDetectionFilterToQuery = (filter: FILTER_PII_VALUES[]): string => {
  if (
    filter.includes(FILTER_PII_VALUES.pii) &&
    filter.includes(FILTER_PII_VALUES.maybePii) &&
    filter.includes(FILTER_PII_VALUES.nonPii)
  ) {
    return ''
  }

  if (filter.includes(FILTER_PII_VALUES.pii) && filter.includes(FILTER_PII_VALUES.maybePii)) {
    return `toggleFilter: { key: ${GRAPHQL_API_FILTERS.isPiiOrMaybePii} },`
  }

  // TODO: this case is broken because lack of API
  if (filter.includes(FILTER_PII_VALUES.pii) && filter.includes(FILTER_PII_VALUES.nonPii)) {
    return `classification: ${GRAPHQL_CLASSIFICATION_TYPE.NOT_PII},`
  }

  if (filter.includes(FILTER_PII_VALUES.maybePii) && filter.includes(FILTER_PII_VALUES.nonPii)) {
    return (
      `toggleFilter: { key: ${GRAPHQL_API_FILTERS.isMaybePII} },` +
      `classification: ${GRAPHQL_CLASSIFICATION_TYPE.NOT_PII},`
    )
  }

  if (filter.includes(FILTER_PII_VALUES.pii)) {
    return `classification: ${GRAPHQL_CLASSIFICATION_TYPE.PII},`
  }
  if (filter.includes(FILTER_PII_VALUES.maybePii)) {
    return `toggleFilter: { key: ${GRAPHQL_API_FILTERS.isMaybePII} },`
  }
  if (filter.includes(FILTER_PII_VALUES.nonPii)) {
    return `classification: ${GRAPHQL_CLASSIFICATION_TYPE.NOT_PII},`
  }

  return ''
}

export const queryDataSourceColumns = (params: ColumnsParams): string => {
  const {
    tableId,
    clusterId,
    databaseId,
    datasourceId,
    isSensitive,
    isAtRisk,
    filters,
    reviewStatus,
    piiDetection
  } = params
  const cursor = getAfterCursor(params[PAGE] || 1, LIMIT_DEFAULT)

  let filter = ''
  if (isSensitive && !piiDetection) {
    filter = `toggleFilter: { key: ${GRAPHQL_API_FILTERS.isPiiOrMaybePii} },`
  }
  if (piiDetection) {
    filter = mapPiiDetectionFilterToQuery(piiDetection)
  }
  if (isAtRisk || reviewStatus) {
    const value = reviewStatus === ColumnReviewStatuses.reviewed
    filter += `booleanFilter: [{key: ${GRAPHQL_API_FILTERS.isReviewed}, value: ${value} }],`
  }
  if (filters && !!Object.values(filters).length) {
    const filtersStr = Object.entries(filters).map(([key, values]) => {
      return `{key: ${key}, values: ${JSON.stringify(values)}}`
    })
    filter += `filter: [${filtersStr}],`
  }

  let searchQuery = ''
  if (params[SEARCH_QUERY]) {
    searchQuery = `query: "${params[SEARCH_QUERY]}", `
  }

  const columnsFragment = `
    columns(
      first: ${LIMIT_DEFAULT},
      after: "${cursor}",
      sortField: ${GRAPHQL_API_FILTERS.tableId},
      sortByAsc: true,
      ${filter}
      ${searchQuery}
    ) {
      count
      edges {
        node {
          id
          name
          type: commonType
          changeType
          isReviewed
          constraint
          qualifiedName
          database {
            edges {
              node {
                id
                name
              }
            }
          }
          table {
            edges {
              node {
                id
                name
              }
            }
          }
          classifications {
            pii {
              numRowsPII
              attributeId
              additionalAttributeIds
            }
            maybePii {
              types {
                attributeId
                reason
              }
            }
          }
        }
      }
    }`

  if (tableId) {
    return `
    {
      tables(id: "${tableId}") {
        edges {
          node {
            id
            name
            ${columnsFragment}
          }
        }
      }
    }`
  }

  if (clusterId && databaseId) {
    return `
    {
      databases(id: "${databaseId}") {
        edges {
          node {
            tableClusters(id: "${clusterId}") {
              edges {
                node {
                  ${columnsFragment}
                }
              }
            }
          }
        }
      }
    }`
  }

  if (datasourceId) {
    return `
    {
      datasources(id: "${params.datasourceId}") {
        edges {
          node {
            id
            name
            ${columnsFragment}
          }
        }
      }
    }`
  }

  return ''
}
export const mapQueryDataSourceColumns = (raw: any): { list: Column[]; total: number } => {
  try {
    const root = raw.tables ||
      raw.databases?.edges[0].node?.tableClusters ||
      raw.datasources || { edges: [] }

    const list = root?.edges[0]?.node?.columns?.edges.map(({ node: column }) => {
      const columnName = column.name
      const { additionalAttributeIds, attributeId = '', numRowsPII = 0 } =
        column.classifications?.pii || {}

      return {
        columnId: column.id,
        columnName,
        header: columnName,
        type: column.type?.replace('CommonType.', '') || '' || '',
        changeType: column.changeType,
        isReviewed: column.isReviewed,
        constraint: column.constraint || '',
        qualifiedName: column.qualifiedName || '',
        changesStatus: COLUMN_CHANGES.noChanges,
        identifierId: attributeId,
        additionalAttributeIds: additionalAttributeIds || [],
        confidence: numRowsPII,
        databaseId: column.database.edges[0]?.node.id,
        databaseName: column.database.edges[0]?.node.name,
        tableId: column.table.edges[0]?.node.id,
        tableName: column.table.edges[0]?.node.name,
        maybePii: column.classifications?.maybePii?.types || []
      }
    })

    return { list, total: root?.edges[0]?.node?.columns.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryReviewColumns = (params: ColumnsReviewParams[]) => {
  const options = params
    .map((item) => {
      const attribute = item.attributeId ? `, attributeId: "${item.attributeId}"` : ''
      const additionalAttributeIds = item.additionalAttributeIds?.length
        ? `, additionalAttributeIds: ${JSON.stringify([...new Set(item.additionalAttributeIds)])}`
        : ''
      const deleteClassification = item.deleteClassification
        ? `, deleteClassification: ${item.deleteClassification}`
        : ''
      return `{ columnId: "${item.columnId}" ${deleteClassification} ${attribute} ${additionalAttributeIds} }`
    })
    .join(',')
  return gql`
    mutation {
      reviewColumns(input: [${options}]) {
        success
        message
      }
    }
  `
}

export const queryFullscanColumns = (colIds: string[]) => {
  const options = colIds
    .map((colId) => {
      return `{ columnId: "${colId}" }`
    })
    .join(',')
  return gql`
    mutation {
      registerFullScanBlobColumns(input: [${options}]) {
        success
        message
      }
    }
  `
}

export const queryColumnSamples = (params: ColumnSamplesParams): string => {
  const { tableId, columnName } = params

  return gql`
    {
      tables(id: "${tableId}") {
        edges {
          node {
            id
            sampleData(columnNames: ["${columnName}"], rowCount: 5) {
              header
              rows
            }
          }
        }
      }
    }
  `
}
export const mapQueryColumnSamples = (raw: any): Array<Array<string>> => {
  try {
    const sampleData = raw.tables?.edges[0]?.node?.sampleData
    return sampleData?.rows[0] && sampleData?.rows
  } catch (error) {
    console.error(error)
    throw error
  }
}
