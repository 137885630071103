import PercentageIndicator from '../PercentageIndicator/percentage'
import { FormattedNumber } from 'react-intl'
import React from 'react'

export interface TrendItemData {
  count: number | null
  text: string
  bulletColor?: string
  percentage?: number
}

interface IProps {
  data: TrendItemData
}
const TrendItem = ({ data }: IProps): React.ReactElement => {
  const { count, bulletColor, text, percentage } = data

  return (
    <div className="list-item-trend xs-pl-16 xs-pt-28 xs-pb-8">
      <div className="item-count xs-mb-4">
        {count != null ? <FormattedNumber value={count ?? 0} notation="compact" /> : 'N/A'}
        {percentage !== undefined && <PercentageIndicator percentage={percentage} size="md" />}
      </div>
      <div className="item-text xs-m-0">
        {bulletColor && <div className="bullet" style={{ backgroundColor: bulletColor }} />}
        {text}
      </div>
    </div>
  )
}

export default TrendItem
