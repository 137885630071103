import './stepper.less'
import PopupInfo from '../PopupInfo/popupInfo'
import React from 'react'
import { Typography } from '@lightbeamai/design-system'

export type Step = {
  text: string
  tooltip?: string
  disabled?: boolean
}

interface IProps {
  steps: Step[]
  activeStep: number
  className?: string
  onClick?: (step: number) => void
}

const Stepper = ({ steps, activeStep, className = '', onClick }: IProps): React.ReactElement => {
  const stepsCount = steps.length

  return (
    <div className={`steps-bar step-no-${activeStep} ${className}`} data-test-id="stepper">
      <div className="steps-wrapper">
        <div className="row circles">
          {steps.map(({ tooltip, disabled }, i) => {
            const currentStep = i + 1
            const isPastStep = currentStep < activeStep

            return (
              <React.Fragment key={i}>
                {tooltip && currentStep != activeStep ? (
                  <PopupInfo
                    position="top center"
                    text={tooltip}
                    trigger={
                      <button
                        onClick={() => !disabled && onClick && onClick(currentStep)}
                        className={`btn circle ${activeStep >= currentStep ? 'active' : ''} ${
                          isPastStep ? 'full' : ''
                        }`}
                      >
                        {currentStep}
                      </button>
                    }
                  />
                ) : (
                  <button
                    onClick={() => !disabled && onClick && onClick(currentStep)}
                    className={`btn circle ${activeStep >= currentStep ? 'active' : ''} ${
                      isPastStep ? 'full' : ''
                    }`}
                  >
                    {currentStep}
                  </button>
                )}
                {currentStep < stepsCount && (
                  <div className={`connector ${isPastStep ? 'active' : ''}`} />
                )}
              </React.Fragment>
            )
          })}
        </div>
        <div className="row text">
          {steps.map(({ text, tooltip, disabled }, i) => {
            const currentStep = i + 1
            const isPastStep = currentStep < activeStep
            return (
              <React.Fragment key={i}>
                {tooltip && currentStep != activeStep ? (
                  <PopupInfo
                    position="top center"
                    text={tooltip}
                    trigger={
                      <button
                        onClick={() => !disabled && onClick && onClick(currentStep)}
                        className={`btn text ${activeStep >= currentStep ? 'active' : ''} ${
                          isPastStep ? 'full' : ''
                        }`}
                      >
                        <Typography variant="baseMinus2" color="secondary">
                          {text}
                        </Typography>
                      </button>
                    }
                  />
                ) : (
                  <button
                    key={i}
                    onClick={() => !disabled && onClick && onClick(currentStep)}
                    className={`btn text ${activeStep >= currentStep ? 'active' : ''} ${
                      isPastStep ? 'full' : ''
                    }`}
                  >
                    <Typography variant="baseMinus2" color="secondary">
                      {text}
                    </Typography>
                  </button>
                )}
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Stepper
