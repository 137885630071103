import { getContextParamsFromUrl, UrlContextParams } from '../utils/urlUtil'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const useUrlContext = (params?: { pageName: string }): UrlContextParams => {
  const location = useLocation()

  return useMemo(() => getContextParamsFromUrl(location, params), [location, params])
}

export default useUrlContext
