import {
  queryFoldersList,
  mapQueryFoldersList,
  queryFoldersCount,
  mapQueryFoldersCount
} from './queries'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import { FilterParams } from '../../interfaces'
import graphqlService from '../../services/graphqlService'
import { getGlobalParams } from '../../utils/urlUtil'
import { RootState } from '../../rootReducer'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export interface Folder {
  id: string
  name: string
  bucketName: string
  path: string
  totalEntities: number
  objectsTotal: number
}
export interface IFetchParams {
  datasourceId?: string
  filters?: FilterParams
  page?: number
  pageSize?: number
}

export type FoldersListSettings = {
  list?: Folder[]
  total?: number
  sort: SortParams
}

export interface FolderState {
  folders: FoldersListSettings
}
const initialList = {
  sort: defaultSortParams
}
const initialState: FolderState = {
  folders: initialList
}

export const ACTION_FETCH_FOLDERS_LIST = 'folders/list'
export const fetchFoldersList = createAsyncThunk(
  ACTION_FETCH_FOLDERS_LIST,
  async (params: IFetchParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const resultRaw = await graphqlService.execute(queryFoldersList(queryParams))
    return mapQueryFoldersList(resultRaw)
  }
)

export const ACTION_FETCH_FOLDERS_COUNT = 'folders/count'
export const fetchFoldersCount = createAsyncThunk(
  ACTION_FETCH_FOLDERS_COUNT,
  async (params: string) => {
    const resultRaw = await graphqlService.execute(queryFoldersCount(params))
    return mapQueryFoldersCount(resultRaw)
  }
)

const foldersSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    resetFolders: (state) => {
      state.folders.list = initialState.folders.list
    },
    setSort: (state, { payload }) => {
      state[payload.list].sort = getSortDirection(state[payload.list].sort, payload.column)
    },
    resetTotalFolders: (state) => {
      state.folders.total = initialState.folders.total
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFoldersList.fulfilled, (state, { payload }) => {
      state.folders.list = payload
    })
    builder.addCase(fetchFoldersCount.fulfilled, (state, { payload }) => {
      state.folders.total = payload
    })
  }
})

export const { setSort, resetFolders, resetTotalFolders } = foldersSlice.actions

export default foldersSlice.reducer
