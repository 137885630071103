import {
  Consent,
  ConsentManagementLogEvents,
  ConsentManagementLogsParams,
  ConsentManagementLogSyncStatus
} from './consentManagementSlice'
import { systemLogFilterLabelMap } from './constants'
import { GRAPHQL_API_FILTERS } from '../../constants'

export const transformFormConfigArray = (input) => {
  return input.map((item) => {
    const configObj = item.config.reduce((acc, configItem) => {
      acc[configItem.key] = configItem.values.length > 1 ? configItem.values : configItem.values[0]
      return acc
    }, {})
    return configObj
  })
}

// type FormConfigItem = {
//   [configType: string]: {
//     [key: string]: string | string[]
//   }
// }

export const transformFormConfigArrayByConfigType = (input) => {
  return input.reduce((acc, item) => {
    acc[item.configType] = item.config.reduce((acc, configItem) => {
      acc[configItem.key] = configItem.values.length > 1 ? configItem.values : configItem.values[0]
      return acc
    }, {})
    return acc
  }, {})
}

export function getConsentCategoryById({
  preferenceCategoryId,
  formConfig
}: {
  preferenceCategoryId: string
  formConfig: any
}) {
  let localFormConfig = formConfig
  if (formConfig.edges) {
    localFormConfig = formConfig.edges[0].node.formConfig
  }

  const formConfigObj = transformFormConfigArray(localFormConfig)

  return formConfigObj.find((item) => item.preference_config_id === preferenceCategoryId)
}

const systemLogSyncStatusFilterMap = {
  [ConsentManagementLogSyncStatus.all]: {
    filters: []
  },
  [ConsentManagementLogSyncStatus.success]: {
    filters: [
      {
        key: GRAPHQL_API_FILTERS.syncStatus,
        values: [ConsentManagementLogSyncStatus.success]
      }
    ]
  },
  [ConsentManagementLogSyncStatus.failed]: {
    filters: [
      {
        key: GRAPHQL_API_FILTERS.syncStatus,
        values: [ConsentManagementLogSyncStatus.failed]
      }
    ]
  }
}

export const getSystemLogsCountParams = (): {
  groupName: string
  groupParams: ConsentManagementLogsParams[]
}[] => {
  const keys = Object.keys(systemLogFilterLabelMap)
  return keys.map((groupName) => ({
    groupName,
    groupParams: systemLogSyncStatusFilterMap[groupName]
  }))
}

export const isConsentEventSubscribed = (consent: Consent) =>
  consent.consentEvent === ConsentManagementLogEvents.subscribed

export const isConsentEventUnubscribed = (consent: Consent) =>
  consent.consentEvent === ConsentManagementLogEvents.unsubscribed
