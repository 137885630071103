import classNames from 'classnames'
import './page.less'
import React from 'react'

interface IProps {
  sidebar?: React.ReactElement | React.ReactElement[]
  aboveHeader?: React.ReactElement | React.ReactElement[]
  header?: React.ReactElement | React.ReactElement[]
  tabs?: React.ReactElement | React.ReactElement[]
  underHeader?: React.ReactElement | React.ReactElement[]
  widgets?: React.ReactElement | React.ReactElement[]
  children: React.ReactElement | React.ReactElement[]
  className?: string
  contentClass?: string
  showBreadcrumbs?: boolean
}

const Page = (props: IProps): React.ReactElement => {
  const {
    sidebar,
    aboveHeader,
    header,
    tabs,
    underHeader,
    widgets,
    children,
    className = '',
    contentClass = ''
  } = props

  return (
    <div className={'page-wrapper custom-scrollbar'} data-test-id="page">
      {sidebar && <div className="column-left">{sidebar}</div>}
      <div className="column-right custom-scrollbar">
        <div className={`page custom-scrollbar ${className}`}>
          {aboveHeader && <div className="section-above-header">{aboveHeader}</div>}
          <div className="dashboard-content">
            <div className="segment-wide">
              {header && <div className="section-header">{header}</div>}
              {tabs && <div className="section-tabs">{tabs}</div>}
            </div>
            {underHeader && <div className="section-under-header">{underHeader}</div>}
            {widgets && <div className="section-widgets">{widgets}</div>}
            <div className={classNames('section-content', { [contentClass]: !!contentClass })}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page
