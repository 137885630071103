import { CookieConsentDomainScanStatuses } from './domains/domainsSlice'
import { checkForCookieCollision } from './domains/domainUtils'
import apiService from '../../services/api/apiService'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export type DateRange = {
  startDate?: string
  endDate?: string
}

export type DomainDateParams = { domainId?: string } & DateRange

export type CookiesPerCategory = {
  categoryName: string
  cookieCount: number
  domains: {
    [key: string]: number
  }
}

export type ScanHistoryItem = {
  domainId: string
  timestamp: string
  cookies: number
  domain?: string
}

export type ConsentsByDomain = {
  domainId?: string
  count: number
  domain: string
  additionalData?: {
    label: string
    value: number
  }[]
}

export type ConsentsByRegion = {
  [region: string]: {
    consentCount: number
    domains: {
      [domain: string]: number
    }
  }
}

export type DomainOverviewData = {
  domainId: string
  domain: string
  scanStatus: CookieConsentDomainScanStatuses
  cookieCollision: boolean
  timestamp: string
}

export type ConsentsByCategory = {
  [key: string]: { optInCount: number; optOutCount: number }
}

type CookieConsentState = {
  overview: {
    categoryCount?: number
    cookieCount?: number
    consentLogsCount?: number
    scansCount?: number
    consentsByCategory?: ConsentsByCategory
    cookiesPerCategory?: CookiesPerCategory[]
    scanHistory?: ScanHistoryItem[]
    consentsByDomain?: ConsentsByDomain[]
    consentsByRegion?: ConsentsByRegion
    domainsData?: DomainOverviewData[]
  }
}

export const ACTION_FETCH_COOKIE_DISTRIBUTION = 'cookieConsent/fetchCookieDistribution'
export const fetchCookieDistribution = createAsyncThunk(
  ACTION_FETCH_COOKIE_DISTRIBUTION,
  async () => await apiService.getCookieDistribution()
)

export const ACTION_FETCH_COOKIE_DISTRIBUTION_PER_DOMAIN =
  'cookieConsent/fetchCookieDistributionPerDomain'
export const fetchCookieDistributionPerDomain = createAsyncThunk(
  ACTION_FETCH_COOKIE_DISTRIBUTION_PER_DOMAIN,
  async (domainId: string) => await apiService.getCookieDistribution(domainId)
)

export const ACTION_FETCH_COOKIE_CONSENT_LOGS_COUNT = 'cookieConsent/fetchCookieConsentLogsCount'
export const fetchCookieConsentLogsCount = createAsyncThunk(
  ACTION_FETCH_COOKIE_CONSENT_LOGS_COUNT,
  async () => await apiService.getCookieConsentCount()
)

export const ACTION_FETCH_COOKIE_CONSENT_SCAN_STATS = 'cookieConsent/fetchCookieConsentScanStats'
export const fetchCookieConsentScanStats = createAsyncThunk(
  ACTION_FETCH_COOKIE_CONSENT_SCAN_STATS,
  async () => await apiService.getCookieConsentScanStats()
)

export const ACTION_FETCH_COOKIE_CONSENT_CATEGORY = 'cookieConsent/fetchCookieConsentMetrics'
export const fetchCookieConsentByCategory = createAsyncThunk(
  ACTION_FETCH_COOKIE_CONSENT_CATEGORY,
  async (params: DomainDateParams) => await apiService.getCookieConsentByCategory(params)
)

export const ACTION_FETCH_COOKIE_CONSENT_DOMAINS = 'cookieConsent/fetchCookieConsentByDomains'
export const fetchCookieConsentByDomains = createAsyncThunk(
  ACTION_FETCH_COOKIE_CONSENT_DOMAINS,
  async (params: DateRange) => await apiService.getCookieConsentByDomain(params)
)

export const ACTION_FETCH_SCAN_STATS = 'cookieConsent/fetchScanStats'
export const fetchScanStats = createAsyncThunk(
  ACTION_FETCH_SCAN_STATS,
  async () => await apiService.getCookieConsentScanHistory()
)

export const ACTION_GET_COOKIE_CONSENTS_BY_REGION = 'cookieConsent/getCookieConsentsByRegion'
export const fetchCookieConsentsByRegion = createAsyncThunk(
  ACTION_GET_COOKIE_CONSENTS_BY_REGION,
  async (params: DomainDateParams) => await apiService.getCookiesConsentByRegion(params)
)

export const ACTION_GET_ALL_DOMAINS = 'cookieConsent/getAllDomains'
export const fetchAllDomains = createAsyncThunk(
  ACTION_GET_ALL_DOMAINS,
  async () => await apiService.getCookieConsentDomains()
)

const mapConsentsByCategory = (
  payload: any
): { cookieCount: number; cookiesPerCategory: CookiesPerCategory[] } => {
  const cookiesPerCategory: CookiesPerCategory[] = []
  const cookieDistributionByCategories = payload?.cookieDistributionByCategories || []
  const totalCount = payload?.totalCookieCount
  cookieDistributionByCategories.forEach((category) => {
    const cookieCount = category?.cookieCount ? Number(category.cookieCount) : 0
    cookiesPerCategory.push({
      categoryName: category?.categoryName || '',
      cookieCount,
      domains: category?.domains || []
    })
  })

  return { cookieCount: totalCount, cookiesPerCategory }
}

const mapConsentsByDomain = (payload: any): ConsentsByDomain[] => {
  const { consentsByDomain = {} } = payload
  const result: ConsentsByDomain[] = []
  for (const domain in consentsByDomain) {
    const currentDomainData = consentsByDomain[domain]
    const currentConsentValues: number[] = Object.values(currentDomainData)
    const currentResult: ConsentsByDomain = {
      domain,
      additionalData: Object.entries(currentDomainData).map(
        ([label, value]) =>
          ({
            label,
            value
          } as { label: string; value: number })
      ),
      count: currentConsentValues.reduce(
        (acc: number, curr: number) => Number(acc + curr),
        0
      ) as number
    }
    result.push(currentResult)
  }
  return result
}

const mapDomainsData = (payload: any): DomainOverviewData[] => {
  if (!payload || !payload.length) return []
  return payload.map((domain) => {
    const domainDataItem: Partial<DomainOverviewData> = {
      domainId: domain?.id,
      domain: domain?.domain
    }
    const scanResults = domain?.jobs || []
    const lastScan = scanResults[scanResults.length - 1]
    const lastScanStatus = lastScan?.status
    domainDataItem.scanStatus = lastScanStatus
    const cookies = domain?.cookies || []
    const { cookieCollision } = checkForCookieCollision(cookies)
    domainDataItem.cookieCollision = cookieCollision
    domainDataItem.timestamp = lastScan?.createdAt
    return domainDataItem
  })
}

const initialState: CookieConsentState = {
  overview: {}
}

const cookieConsentSlice = createSlice({
  name: 'cookieConsent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCookieDistribution.fulfilled, (state, { payload }) => {
      const { cookieCount, cookiesPerCategory } = mapConsentsByCategory(payload)
      const categoryCount = cookiesPerCategory.length
      state.overview.categoryCount = categoryCount
      state.overview.cookieCount = cookieCount
      state.overview.cookiesPerCategory = cookiesPerCategory
    })
    builder.addCase(fetchCookieDistributionPerDomain.fulfilled, (state, { payload }) => {
      const { cookiesPerCategory } = mapConsentsByCategory(payload)
      state.overview.cookiesPerCategory = cookiesPerCategory
    })
    builder.addCase(fetchCookieConsentLogsCount.fulfilled, (state, { payload }) => {
      state.overview.consentLogsCount = payload.totalCount
    })
    builder.addCase(fetchCookieConsentScanStats.fulfilled, (state, { payload }) => {
      state.overview.scansCount = payload.scanResults.length
    })
    builder.addCase(fetchCookieConsentByDomains.fulfilled, (state, { payload }) => {
      state.overview.consentsByDomain = mapConsentsByDomain(payload)
    })
    builder.addCase(fetchCookieConsentByCategory.fulfilled, (state, { payload }) => {
      const consentByCategory = payload?.categoriesInfo || {}
      state.overview.consentsByCategory = consentByCategory
    })
    builder.addCase(fetchScanStats.fulfilled, (state, { payload }) => {
      const scanHistory: ScanHistoryItem[] = []
      const { scanResults = [] } = payload
      scanResults.forEach((scan) => {
        const { domainId, updatedAt, scanResult } = scan
        scanHistory.push({ domainId, timestamp: updatedAt, cookies: scanResult?.cookies || 0 })
      })
      state.overview.scanHistory = scanHistory
    })
    builder.addCase(fetchCookieConsentsByRegion.fulfilled, (state, { payload }) => {
      state.overview.consentsByRegion = payload as ConsentsByRegion
    })
    builder.addCase(fetchAllDomains.fulfilled, (state, { payload }) => {
      state.overview.domainsData = mapDomainsData(payload)
    })
  }
})

export default cookieConsentSlice.reducer
