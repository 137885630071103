import { IgnoreObjectProps } from './documentSlice'
import { GRAPHQL_API_FILTERS } from '../../constants'
import { Identifier } from '../../services/api/apiTypes'
import { gql } from 'graphql-request'

export const queryIdentifiers = (): string => {
  return gql`
    {
      attribute(booleanFilter: [{ key: ${GRAPHQL_API_FILTERS.isCategory}, value: false }]) {
        edges {
          node {
            id
            name
            internalName
          }
        }
      }
    }
  `
}

// TODO: add type for graphql response
export const mapQueryIdentifiers = (raw: any): Identifier[] => {
  try {
    const list = raw.attribute.edges.map(({ node: identifier }) => ({
      id: identifier.id,
      name: identifier.name,
      internalName: identifier.internalName
    }))

    return list
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryIgnoreBlob = ({ datasourceId, key, value }: IgnoreObjectProps): string => {
  const idsArr = value.map((value) => '"' + value + '"') || []

  return gql`
    mutation {
      ignoreObjects(
        actionInput: {
          clientMutationId: "1",
          datasourceId: "${datasourceId}"
          objectInput: {key: ${key}, value: [${idsArr.join(',')}]}
        }
      ) {
        status
      }
    }
  `
}
export const mutationIgnoreAttributeInstance = (attributeInstanceId: string): string => {
  return gql`
    mutation {
      deleteAttributeInstance(
        attributeInstanceIds: ["${attributeInstanceId}"]
        clientMutationId: ""
      ) {
        message
      }
    }
  `
}

export const queryDocumentTemplates = (): string => {
  return gql`
    {
      attribute(booleanFilter: [{ key: ${GRAPHQL_API_FILTERS.isCategory}, value: false }]) {
        edges {
          node {
            id
            name
            internalName
          }
        }
      }
    }
  `
}
