import { DataSourceDistribution, DataSourceItem, DataSourcesParams } from './dataSourcesSlice'
import { mapDataSourceGraphQlStatus } from '../../../../../../utils/structuredDataUtil'
import { API_FILTER_DATA_SOURCES, DATA_SOURCE_TYPE_API_MAP } from '../../../../../../constants'
import { parameterizeArrayofObjects } from '../../../../../../utils/graphqlUtil'
import { gql } from 'graphql-request'
import { DropdownItemProps } from 'semantic-ui-react'

export const queryDataSourcesDistribution = (dsrId: string): string => {
  return gql`
    dsrDataSourceDistribution(dsrId: ${dsrId}) {
      connectedDataSources {
        total
        count
      }
      remoteDataSources {
        total
        count
      }
      dataCollection {
        total
        count
      }
    }
  `
}

type DataSourcesDistributionData = {
  connectedDataSources?: DataSourceDistribution
  remoteDataSources?: DataSourceDistribution
  dataCollection?: DataSourceDistribution
}

export const mapQueryDataSourcesDistribution = (raw: any): DataSourcesDistributionData => {
  const { connectedDataSources, remoteDataSources, dataCollection } = raw?.dsrDataSourceDistribution
  return {
    connectedDataSources: {
      total: connectedDataSources?.total || 0,
      count: connectedDataSources?.count || 0
    },
    remoteDataSources: {
      total: remoteDataSources?.total || 0,
      count: remoteDataSources?.count || 0
    },
    dataCollection: {
      total: dataCollection?.total || 0,
      count: dataCollection?.count || 0
    }
  }
}

export const queryDataSources = (params?: DataSourcesParams): string => {
  const basicFilters = [
    {
      key: API_FILTER_DATA_SOURCES.type,
      values: params?.dataSourceType?.split(',').map((ds) => DATA_SOURCE_TYPE_API_MAP[ds]) || ''
    },
    {
      key: API_FILTER_DATA_SOURCES.status,
      values: params?.status?.split(',').map((s) => s) || ''
    },
    {
      key: API_FILTER_DATA_SOURCES.createdBy,
      values: params?.owner?.split(',').map((c) => c) || ''
    }
  ]

  const allFiltersString = parameterizeArrayofObjects(basicFilters)

  return gql`
    {
      datasources(first: 100 ${allFiltersString ? ', filter:' + allFiltersString : ''}) {
        count
        edges {
          node {
            id
            name
            createdBy
            # dataDavlidationStatus // Todo: uncomment
            # taskId // Todo: uncomment
            numberOfObjects
            registeredOn
            type
          }
        }
      }
    }
  `
}

export const queryAllDataSources = (): string => {
  return gql`
    {
      datasources(first: 999) {
        count
        edges {
          node {
            id
            name
            createdBy
            owner {
              email
            }
          }
        }
      }
    }
  `
}

export const mapQueryDataSources = (raw: any): { list: DataSourceItem[]; total: number } => {
  const datasourcesList = raw.datasources.edges.map((edge: any) => {
    const ds = edge.node

    return {
      id: ds.id,
      status: mapDataSourceGraphQlStatus(ds.status),
      createdBy: ds.createdBy,
      dataSourceName: ds.name || '',
      numberOfObjects: ds.numberOfObjects,
      registeredOn: ds.registeredOn,
      dataSourceType: ds.type
    }
  })

  return {
    list: datasourcesList,
    total: raw.datasources.count || 0
  }
}

export const queryDataSourceOwners = (selectedDataSources?: string[]): string => {
  const selectedDataSourcesString = selectedDataSources?.join(',') || ''
  return gql`
    {
      datasources(selectedDataSources: ${selectedDataSourcesString}) {
        edges {
          node {
            createdBy
            owner {
              email
            }
          }
        }
      }
    }
  `
}

export const mapQueryDataSourceFilters = (raw: any): string[] => {
  return raw.datasources.edges.map(({ node: ds }) => ds.createdBy).filter((owner) => !!owner)
}

export const mapQueryDataSourceOwners = (raw: any): DropdownItemProps[] => {
  return raw.datasources.edges.map(({ node: ds }, index: number) => ({
    key: (ds.owner?.email || ds.createdBy) + index,
    value: ds.owner?.email || ds.createdBy,
    text: ds.createdBy
  }))
}

export const mapAllDatasourceNames = (raw: any): DropdownItemProps[] => {
  return raw.datasources.edges.map(({ node: ds }) => ({ key: ds.id, value: ds.id, text: ds.name }))
}
