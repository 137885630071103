import {
  DocAnalyticsFilesCountResponseType,
  DocAnalyticsFilesObject,
  DocAnalyticsFilesResponseType,
  FetchDocAnalyticsFilesListParams,
  SitesFilterObj,
  SitesFilterParams,
  SitesFilterResponseType
} from './docAnalyticsSlice'
import { LIMIT_DEFAULT } from '../../constants'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { gql } from 'graphql-request'

// Fetch Files List
export const queryDocAnalyticsFilesList = (params: FetchDocAnalyticsFilesListParams): string => {
  const { datasourceId, filters, page } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  let paramStr = ''
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const booleanFilterString =
    filters && filters.booleanFilter ? parameterizeArrayofObjects(filters.booleanFilter) : ''

  if (datasourceId) {
    paramStr += `, datasourceId:"${datasourceId}"`
  }
  if (filterString) {
    paramStr += `, filter: ${filterString}`
  }
  if (booleanFilterString) {
    paramStr += `, booleanFilter: ${booleanFilterString} `
  }
  if (paramStr) {
    paramStr = `(first: ${LIMIT_DEFAULT}${paramStr},after:"${cursor}")`
  }
  return gql`
      {
        docAnalyticsObject${paramStr} {
          edges {
            node {
              id
              name
              extension
              size
              skipped
              skipReason
              checksum
              lastModifiedTimestamp
              createdTimestamp
              objectLink
            }
          }
        }
      }
    `
}
export const mapQueryDocAnalyticsFilesList = (
  queryResponse: any
): DocAnalyticsFilesResponseType => {
  try {
    const list: DocAnalyticsFilesObject[] =
      queryResponse?.docAnalyticsObject?.edges.map(({ node }) => ({
        id: node.id,
        name: node.name,
        extension: node.extension,
        size: node.size,
        skipped: node.skipped,
        skipReason: node.skipReason || '',
        checksum: node.checksum || '',
        lastModifiedTimestamp: node.lastModifiedTimestamp,
        createdTimestamp: node.createdTimestamp || '',
        objectLink: node.objectLink
      })) || []

    return { list }
  } catch (e) {
    return { list: [] }
  }
}

// Fetch Files Count
export const queryDocAnalyticsFilesCount = (params: FetchDocAnalyticsFilesListParams): string => {
  const { datasourceId, filters, page } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  let paramStr = ''
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const booleanFilterString =
    filters && filters.booleanFilter ? parameterizeArrayofObjects(filters.booleanFilter) : ''

  if (datasourceId) {
    paramStr += `, datasourceId:"${datasourceId}"`
  }
  if (filterString) {
    paramStr += `, filter: ${filterString}`
  }
  if (booleanFilterString) {
    paramStr += `, booleanFilter: ${booleanFilterString} `
  }
  if (paramStr) {
    paramStr = `(first: ${LIMIT_DEFAULT}${paramStr},after:"${cursor}")`
  }
  return gql`
      {
        docAnalyticsFilesCount: docAnalyticsObject${paramStr} {
          count
        }
      }
    `
}
export const mapQueryDocAnalyticsFilesCount = (
  queryResponse: any
): DocAnalyticsFilesCountResponseType => {
  try {
    const total = queryResponse?.docAnalyticsFilesCount?.count || 0

    return { total }
  } catch (e) {
    return { total: 0 }
  }
}

//Fetch Sites filter
export const queryDocAnalyticsSitesFilter = (params: SitesFilterParams): string => {
  const { datasourceId, searchQuery = '' } = params

  let paramStr = ''

  if (datasourceId) {
    paramStr += `, datasourceId:"${datasourceId}"`
  }
  if (searchQuery) {
    paramStr += `, searchQuery:"${searchQuery}"`
  }
  if (paramStr) {
    paramStr = `(first: ${LIMIT_DEFAULT}${paramStr},after:"")`
  }
  return gql`
      {
        docAnalyticsSite${paramStr}{
          edges{
            node{
              siteId, name
            }
          }
        }
      }
    `
}
export const mapQueryDocAnalyticsSitesFilter = (queryResponse: any): SitesFilterResponseType => {
  try {
    const list: SitesFilterObj[] =
      queryResponse?.docAnalyticsSite?.edges.map(({ node }) => ({
        siteId: node.siteId,
        name: node.name
      })) || []

    return { list }
  } catch (e) {
    return { list: [] }
  }
}
