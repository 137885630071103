import { fetchDocumentById, fetchDocumentByFilters } from './documentSlice'
import type { BoundingBoxUI, UnlinkedAttributeUI } from '../../interfaces'
import { mapUnlinkedAttributesForUI } from '../../utils/documentUtil'
import { createSlice } from '@reduxjs/toolkit'

interface DocumentState {
  boundingBoxes: BoundingBoxUI[]
  classification: string
  subClassification: string
  templateName: string
  templateDescription: string
  unlinkedAttributes?: UnlinkedAttributeUI[]
  showUnlinkedAttributes: boolean
}

export const initialState: DocumentState = {
  boundingBoxes: [],
  classification: '',
  subClassification: '',
  templateName: '',
  templateDescription: '',
  showUnlinkedAttributes: false
}

const documentSlice = createSlice({
  name: 'documentTimeTravel',
  initialState,
  reducers: {
    setBoundingBoxes: (state, action) => {
      state.boundingBoxes = action.payload
    },
    addBoundingBoxes: (state, action) => {
      state.boundingBoxes.push(action.payload)
      state.boundingBoxes = state.boundingBoxes.slice()
    },
    updateBoundingBoxes: (state, { payload }) => {
      state.boundingBoxes = state.boundingBoxes.map((box) => {
        const updatedBox = payload.find(({ localId }) => localId === box.localId)
        return updatedBox ? { ...box, ...updatedBox } : box
      })
      state.unlinkedAttributes = state.unlinkedAttributes?.map((box) => {
        const updatedBox = payload.find(({ localId }) => localId === box.localId)
        return updatedBox ? { ...box, ...updatedBox } : box
      })
    },
    removeBoundingBox: (state, action) => {
      state.boundingBoxes = state.boundingBoxes.filter((box) => box.localId !== action.payload)
    },
    setClassification: (state, action) => {
      state.classification = action.payload
    },
    setSubClassification: (state, action) => {
      state.subClassification = action.payload
    },
    updateTemplateName: (state, { payload }) => {
      state.templateName = payload
    },
    updateTemplateDescription: (state, { payload }) => {
      state.templateDescription = payload
    },
    setShowUnlinkedAttributes: (state, { payload }: { payload: boolean }) => {
      state.showUnlinkedAttributes = payload
    },
    resetHistory: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDocumentById.fulfilled, (state, action) => {
      state.classification = action.payload.class || ''
      state.subClassification = action.payload.subclass || ''
      state.templateName = action.payload.annotationName || ''
      state.boundingBoxes = action.payload.boundingBoxTags || []
      state.unlinkedAttributes = mapUnlinkedAttributesForUI(action.payload)
    })
    builder.addCase(fetchDocumentByFilters.fulfilled, (state, { payload: list }) => {
      state.boundingBoxes = list.map((doc) => doc?.boundingBoxTags || []).flat()

      if (list.length === 1) {
        const doc = list[0]
        state.classification = doc?.class?.trim() || ''
        state.subClassification = doc?.subclass || ''
        state.templateName = doc?.annotationName || ''
      }
    })
  }
})

export const {
  setBoundingBoxes,
  addBoundingBoxes,
  removeBoundingBox,
  updateBoundingBoxes,
  setClassification,
  setSubClassification,
  updateTemplateName,
  updateTemplateDescription,
  setShowUnlinkedAttributes,
  resetHistory
} = documentSlice.actions

export default documentSlice.reducer
