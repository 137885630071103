import './trendTableWidget.less'
import PercentageIndicator from '../PercentageIndicator/percentage'
import { Table } from 'semantic-ui-react'
import { Tag, FileText, GridFour, Columns } from 'phosphor-react'
import { FormattedNumber } from 'react-intl'
import React from 'react'

export enum TrendSummaryTypes {
  'FILES_SENSITIVE_DATA' = 'files',
  'SENSITIVE_DATA_TOTAL' = 'attrubutes_total',
  'SENSITIVE_DATA_HIGH' = 'attributes_high',
  'TABLES_SENSITIVE_DATA' = 'tables',
  'COLUMNS_SENSITIVE_DATA' = 'columns'
}

export interface TableTrendHeaderData {
  type: TrendSummaryTypes
  total: number
}

export interface TablerendItemData {
  type: TrendSummaryTypes
  addedTotal: number
  deletedTotal: number
}

interface IProps {
  headers: TableTrendHeaderData[]
  addedData: { [key: string]: { added: number; percentageChange: number } }
  deletedData: { [key: string]: { deleted: number; percentageChange: number } }
}

const iconMapper = {
  [TrendSummaryTypes.SENSITIVE_DATA_TOTAL]: <Tag size={24} color="#6A737D" />,
  [TrendSummaryTypes.SENSITIVE_DATA_HIGH]: <Tag size={24} color="#6A737D" />,
  [TrendSummaryTypes.FILES_SENSITIVE_DATA]: <FileText size={24} color="#6A737D" />,
  [TrendSummaryTypes.TABLES_SENSITIVE_DATA]: <GridFour size={24} color="#6A737D" />,
  [TrendSummaryTypes.COLUMNS_SENSITIVE_DATA]: <Columns size={24} color="#6A737D" />
}
const textMapper = {
  [TrendSummaryTypes.SENSITIVE_DATA_TOTAL]: 'Sensitive Data Count: Total',
  [TrendSummaryTypes.SENSITIVE_DATA_HIGH]: 'Sensitive Data Count: High',
  [TrendSummaryTypes.FILES_SENSITIVE_DATA]: 'Total Files',
  [TrendSummaryTypes.TABLES_SENSITIVE_DATA]: 'Tables with sensitive data',
  [TrendSummaryTypes.COLUMNS_SENSITIVE_DATA]: 'Columns with sensitive data'
}

const TrendTableWidgetStructured = ({
  headers,
  addedData,
  deletedData
}: IProps): React.ReactElement => {
  return (
    <div className="table-trend-widget">
      <Table very="simple" celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            {headers.map(({ type, total }) => (
              <Table.HeaderCell key={type} textAlign="center" className="xs-py-16">
                <p className="xs-mb-8">{iconMapper[type]}</p>
                <p className="xs-mb-4 text-count">
                  <FormattedNumber value={total} notation="compact" />
                </p>
                <p className="text-title">{textMapper[type]}</p>
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Added</Table.Cell>
            {headers.map(({ type }) => (
              <Table.Cell key={type} textAlign="center">
                <FormattedNumber value={addedData[type].added} notation="compact" />
                {addedData[type].percentageChange ? (
                  <PercentageIndicator percentage={addedData[type].percentageChange} size="sm" />
                ) : (
                  ''
                )}
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Deleted</Table.Cell>
            {headers.map(({ type }) => (
              <Table.Cell key={type} textAlign="center">
                {deletedData[type] ? (
                  <FormattedNumber value={deletedData[type].deleted} notation="compact" />
                ) : (
                  '-'
                )}
                {deletedData[type]?.percentageChange ? (
                  <PercentageIndicator percentage={deletedData[type].percentageChange} size="sm" />
                ) : (
                  ''
                )}
              </Table.Cell>
            ))}
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  )
}

export default TrendTableWidgetStructured
