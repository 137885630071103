import React from 'react'

const PrintFinalPage = (): React.ReactElement => {
  return (
    <div className="print-page page-final" data-test-id="print-final-page">
      <div className="wrapper">
        <div className="icon-box logo-lb">
          <img src="../assets/images/lightbeam-logo2.svg" />
        </div>
        <div className="final-text">Data Security & Privacy Automation</div>
        <div className="final-sub-text">Confidential document</div>
      </div>
    </div>
  )
}

export default PrintFinalPage
