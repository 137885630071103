import service from '../../services/api/apiService'
import { ConnectedApps } from '../../services/api/apiTypes'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface ConnectedAppsState {
  connectedApps?: ConnectedApps
}

export const initialState: ConnectedAppsState = {
  connectedApps: {}
}

export const fetchConnectedApps = createAsyncThunk(
  'connectedApps/fetchConnectedApps',
  async () => await service.getConnectedApps()
)

const connectedAppsSlice = createSlice({
  name: 'connectedApps',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConnectedApps.fulfilled, (state, action) => {
      state.connectedApps = action.payload
    })
    builder.addCase(fetchConnectedApps.rejected, (state) => {
      state.connectedApps = {}
    })
  }
})

export default connectedAppsSlice.reducer
