import {
  mapQueryAllRecipientsGroups,
  mapQueryAllRecipientsIndividual,
  mapQueryAllSenders,
  mapQueryEmailCards,
  mapQueryEmailList,
  mapQueryEmailRecipientGroupsCard,
  mapQueryEmailRecipientIndividualCard,
  mapQueryEmailsCount,
  mapQueryRecipientGroupCount,
  mapQueryRecipientIndividualCount,
  queryAllRecipientsGroups,
  queryAllRecipientsIndividual,
  queryAllSenders,
  queryEmailCards,
  queryEmailList,
  queryEmailRecipientGroupsCard,
  queryEmailRecipientIndividualCard,
  queryEmailsCount,
  queryRecipientIndividualCount,
  queryRecipientsGroupCount
} from './queries'
import { EmailTabs } from './emailsList'
import { Email } from '../../../../../services/api/apiTypes'
import { getSortDirection, SortParams } from '../../../../../utils/sortUtil'
import { FilterParams, IGetEmailParams, IMailInfo, SORT_ORDER } from '../../../../../interfaces'
import graphqlService from '../../../../../services/graphqlService'
import { RootState } from '../../../../../rootReducer'
import { getGlobalParams } from '../../../../../utils/urlUtil'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface IList {
  list?: IMailInfo[]
  sort: SortParams
}
interface ICard {
  items?: Email[]
  individualRecipientsItems?: Email[]
  groupRecipientsItems?: Email[]
  totalSenders?: number
  totalIndividualRecipients?: number
  totalGroupRecipients?: number
  sort: SortParams
}

interface EmailsListState {
  list: IList
  cards: ICard
  senders?: string[]
  recipientsIndividual?: string[]
  recipientsGroups?: string[]
  totalEmails?: number
  totalEmailsRecipientIndividual?: number
  totalEmailsRecipientGroup?: number
}
export type EmailFilters = { datasourceId?: string; filter?: FilterParams; activeTab?: EmailTabs }
export const initialState: EmailsListState = {
  list: {
    sort: {
      column: 'timestamp',
      direction: SORT_ORDER.DESC
    }
  },
  cards: {
    sort: {
      column: 'name',
      direction: SORT_ORDER.ASC
    }
  }
}

export const ACTION_EMAILS_CARD_FETCH = 'emails/cards'
export const fetchEmailCards = createAsyncThunk(
  ACTION_EMAILS_CARD_FETCH,
  async (params: IGetEmailParams) => {
    const resultRaw = await graphqlService.execute(queryEmailCards(params))
    return mapQueryEmailCards(resultRaw)
  }
)

export const ACTION_EMAILS_RECIPIENT_INDIVIDUAL_CARD_FETCH = 'emails/recipient/individual/cards'
export const fetchEmailRecipientIndividualCards = createAsyncThunk(
  ACTION_EMAILS_RECIPIENT_INDIVIDUAL_CARD_FETCH,
  async (params: IGetEmailParams) => {
    const resultRaw = await graphqlService.execute(queryEmailRecipientIndividualCard(params))
    return mapQueryEmailRecipientIndividualCard(resultRaw)
  }
)

export const ACTION_EMAILS_RECIPIENT_GROUPS_CARD_FETCH = 'emails/recipient/groups/cards'
export const fetchEmailRecipientGroupsCards = createAsyncThunk(
  ACTION_EMAILS_RECIPIENT_GROUPS_CARD_FETCH,
  async (params: IGetEmailParams) => {
    const resultRaw = await graphqlService.execute(queryEmailRecipientGroupsCard(params))
    return mapQueryEmailRecipientGroupsCard(resultRaw)
  }
)

export const ACTION_EMAILS_LIST_FETCH = 'emails/list'
export const fetchEmailList = createAsyncThunk(
  ACTION_EMAILS_LIST_FETCH,
  async (params: IGetEmailParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const resultRaw = await graphqlService.execute(queryEmailList(queryParams))
    return mapQueryEmailList(resultRaw)
  }
)

export const ACTION_EMAIL_SENDERS_FETCH = 'emails/senders'
export const fetchAllEmailSenders = createAsyncThunk(
  ACTION_EMAIL_SENDERS_FETCH,
  async (params: EmailFilters) => {
    const resultRaw = await graphqlService.execute(queryAllSenders(params))
    return mapQueryAllSenders(resultRaw)
  }
)

export const ACTION_EMAIL_RECIPIENTS_INDIVIDUAL_FETCH = 'emails/recipients/individual'
export const fetchAllEmailRecipientsIndividual = createAsyncThunk(
  ACTION_EMAIL_RECIPIENTS_INDIVIDUAL_FETCH,
  async (params: EmailFilters) => {
    const resultRaw = await graphqlService.execute(queryAllRecipientsIndividual(params))
    return mapQueryAllRecipientsIndividual(resultRaw)
  }
)

export const ACTION_EMAIL_RECIPIENTS_GROUPS_FETCH = 'emails/recipients/groups'
export const fetchAllEmailRecipientsGroups = createAsyncThunk(
  ACTION_EMAIL_RECIPIENTS_GROUPS_FETCH,
  async (params: EmailFilters) => {
    const resultRaw = await graphqlService.execute(queryAllRecipientsGroups(params))
    return mapQueryAllRecipientsGroups(resultRaw)
  }
)

export const ACTION_EMAILS_COUNT_FETCH = 'emails/count'
export const fetchEmailsCount = createAsyncThunk(
  ACTION_EMAILS_COUNT_FETCH,
  async (params: EmailFilters) => {
    const resultRaw = await graphqlService.execute(queryEmailsCount(params))
    return mapQueryEmailsCount(resultRaw)
  }
)

export const ACTION_RECIPIENT_INDIVIDUAL_COUNT_FETCH = 'recipient-individual/count'
export const fetchRecipientIndividualCount = createAsyncThunk(
  ACTION_RECIPIENT_INDIVIDUAL_COUNT_FETCH,
  async (params: EmailFilters) => {
    const resultRaw = await graphqlService.execute(queryRecipientIndividualCount(params))
    return mapQueryRecipientIndividualCount(resultRaw)
  }
)

export const ACTION_RECIPIENT_GROUP_COUNT_FETCH = 'recipient-group/count'
export const fetchRecipientGroupCount = createAsyncThunk(
  ACTION_RECIPIENT_GROUP_COUNT_FETCH,
  async (params: EmailFilters) => {
    const resultRaw = await graphqlService.execute(queryRecipientsGroupCount(params))
    return mapQueryRecipientGroupCount(resultRaw)
  }
)

const emailsListSlice = createSlice({
  name: 'emails',
  initialState,
  reducers: {
    setSort: (state, { payload }) => {
      state.list.sort = getSortDirection(state.list.sort, payload.column)
    },
    resetEmailCards: (state) => {
      state.cards = initialState.cards
      state.totalEmails = initialState.totalEmails
      state.totalEmailsRecipientIndividual = initialState.totalEmailsRecipientIndividual
      state.totalEmailsRecipientGroup = initialState.totalEmailsRecipientGroup
    },
    resetEmailList: (state) => {
      state.list = initialState.list
    },
    resetSenders: (state) => {
      state.senders = initialState.senders
    },
    resetRecipientsIndividual: (state) => {
      state.recipientsIndividual = initialState.recipientsIndividual
    },
    resetRecipientsGroups: (state) => {
      state.recipientsGroups = initialState.recipientsGroups
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmailCards.fulfilled, (state, { payload }) => {
      state.cards.items = payload.list
      state.cards.totalSenders = payload.total
    })
    builder.addCase(fetchEmailRecipientIndividualCards.fulfilled, (state, { payload }) => {
      state.cards.individualRecipientsItems = payload.list
      state.cards.totalIndividualRecipients = payload.total
    })
    builder.addCase(fetchEmailRecipientGroupsCards.fulfilled, (state, { payload }) => {
      state.cards.groupRecipientsItems = payload.list
      state.cards.totalGroupRecipients = payload.total
    })
    builder.addCase(fetchEmailsCount.fulfilled, (state, { payload }) => {
      state.totalEmails = payload.senderTotal
    })
    builder.addCase(fetchRecipientIndividualCount.fulfilled, (state, { payload }) => {
      state.totalEmailsRecipientIndividual = payload.recipientIndividualTotal
    })
    builder.addCase(fetchRecipientGroupCount.fulfilled, (state, { payload }) => {
      state.totalEmailsRecipientGroup = payload.recipientGroupTotal
    })
    builder.addCase(fetchEmailList.fulfilled, (state, { payload }) => {
      state.list.list = payload.list
    })
    builder.addCase(fetchAllEmailSenders.fulfilled, (state, { payload }) => {
      state.senders = [...new Set(payload.list)]
    })
    builder.addCase(fetchAllEmailRecipientsIndividual.fulfilled, (state, { payload }) => {
      state.recipientsIndividual = [...new Set(payload.list)]
    })
    builder.addCase(fetchAllEmailRecipientsGroups.fulfilled, (state, { payload }) => {
      state.recipientsGroups = [...new Set(payload.list)]
    })
  }
})

export const {
  setSort,
  resetEmailCards,
  resetEmailList,
  resetSenders,
  resetRecipientsIndividual,
  resetRecipientsGroups
} = emailsListSlice.actions

export default emailsListSlice.reducer
