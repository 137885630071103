import PrintPageHeader from '../components/header'
import ClassificationsList from '../../features/classifications/classifiicationsPrint'
import React from 'react'

const PrintAttributeListPage = (): React.ReactElement => {
  return (
    <div
      id="classifications"
      className="print-page page-classifications"
      data-test-id="print-classifications-attribute-list-page"
    >
      <PrintPageHeader text={<strong>Document classification</strong>} />
      <ClassificationsList />
    </div>
  )
}

export default PrintAttributeListPage
