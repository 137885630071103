import {
  AttributesBySensitivity,
  CreateLabelParams,
  CreateLabelSetParams,
  DocumentLabel,
  DocumentLabelProvider,
  DocumentLabelSet,
  LabelByIdParams,
  LabelsWidgetParams,
  ListQueryParams,
  UpdateLabelParams,
  UpdateLabelSetParams
} from './types'
import { DOCUMENT_LABEL_PROVIDERS, DocumentLabelProviderConfigurations } from './contants'
import { LIMIT_DEFAULT, SENSITIVE_LABEL } from '../../constants'
import { getAfterCursor } from '../../utils/graphqlUtil'
import { escapeNewlineandTab } from '../../utils/stringUtil'
import { AttributeSet, AttributeSetAttribute } from '../attributes/attributesSlice'
import { sortAttributesByInstanceCountAndSensitivity } from '../../utils/attributesUtil'
import { SORT_ORDER } from '../../interfaces'
import { sortByOrder } from '../../utils/sortUtil'
import { removeDuplicates } from '../../utils'
import {
  AttributeInstanceCountType,
  AttributeSensitivityType,
  CriteriaOperatorTypes,
  FileClassificationType,
  Ruleset,
  RulesetType
} from '../../utils/rulesetUtils'
import gqlast from '../../utils/filtersUtil'
import { gql } from 'graphql-request'

// queries
export const queryLabelSets = (): string => {
  return gql`
    {
      documentLabelSet {
        edges {
          node {
            id
            name
            id
            type
            createdAt
            updateTimestamp
            labels {
              count
              edges {
                node {
                  id
                  name
                  description
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryLabelSets = (raw: any): DocumentLabelSet[] => {
  return raw.documentLabelSet?.edges?.map(({ node: labelSet }) => ({
    id: labelSet?.id || '',
    name: labelSet?.name || '',
    description: labelSet?.description || '',
    type: labelSet?.type || '',
    labelsCount: labelSet?.labels?.count,
    labels: labelSet?.labels?.edges?.map(({ node }) => ({
      name: node.name,
      id: node.id
    })),
    createdAt: labelSet.createdAt,
    updatedAt: labelSet.updateTimestamp
  }))
}

export const queryCompactLabelSets = (): string => {
  return gql`
    {
      documentLabelSet {
        count
        edges {
          node {
            id
            name
            id
            type
            labels {
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryCompactLabelSets = (raw: any): { list: DocumentLabelSet[]; total: number } => {
  return {
    list: raw.documentLabelSet?.edges?.map(({ node: labelSet }) => ({
      id: labelSet?.id || '',
      name: labelSet?.name || '',
      type: labelSet?.type || '',
      labelsCount: labelSet?.labels?.count
    })),
    total: raw.documentLabelSet?.count || 0
  }
}

export const queryLabelSetsList = (params: ListQueryParams): string => {
  const { page = 1, pageSize = LIMIT_DEFAULT } = params
  const cursor = getAfterCursor(page, pageSize)
  return gql`
    {
      documentLabelSet(first: ${pageSize}, after: "${cursor}") {
        count
        edges {
          node {
            id
            name
            id
            type
            labels {
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryLabelSetsList = (raw: any): { list: DocumentLabelSet[]; total: number } => {
  return {
    list: raw.documentLabelSet?.edges?.map(({ node: labelSet }) => ({
      id: labelSet?.id || '',
      name: labelSet?.name || '',
      description: labelSet?.description || '',
      type: labelSet?.type || '',
      labelsCount: labelSet?.labels?.count
    })),
    total: raw.documentLabelSet?.count || 0
  }
}

// queries
export const queryLabelsList = (params: ListQueryParams): string => {
  const { page = 1, pageSize = LIMIT_DEFAULT } = params
  const cursor = getAfterCursor(page, pageSize)
  return gql`
    {
      documentLabelSet(first: ${pageSize}, after: "${cursor}") {
        count
        edges {
          node {
            id
            name
            id
            labels {
              edges {
                node {
                  id
                  name
                  type
                  objectCount
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryLabelsList = (raw: any): { list: DocumentLabel[]; total: number } => {
  const list =
    raw.documentLabelSet.edges
      .map(({ node: set }) => {
        if (set?.labels) {
          set?.labels?.edges?.map?.(({ node: label }) => {
            if (label) {
              label.labelSetId = set?.id
              label.labelSetName = set?.name
            }
          })
        }
        return set?.labels?.edges
      })
      .flat()
      ?.map(({ node: label }) => label) || []

  return {
    list,
    total: list.length
  }
}

export const queryLabelSetById = (id: string): string => {
  return gql`
    {
      documentLabelSet(labelSetIds: "${id}") {
        edges {
          node {
            id
            name
            description
            type
            labels {
              edges {
                node {
                  id
                  name
                  priority
                  type
                  updateTimestamp
                  createdAt
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryLabelSetById = (raw: any): DocumentLabelSet => {
  const { edges } = raw.documentLabelSet
  const labelSet = edges?.[0]?.node || {}
  labelSet.labels = labelSet?.labels?.edges?.map(({ node }) => node) || []
  return labelSet
}

export const queryLabelById = (params: LabelByIdParams): string => {
  const { id, setId } = params
  return gql`
    {
      documentLabelSet(labelSetIds: "${setId}") {
        edges {
          node {
            id
            name
            type
            labels(labelIds: "${id}") {
              edges {
                node {
                  id
                  name
                  priority
                  type
                  objectCount
                  description
                  color
                  datasourceCounts {
                    count
                    datasource {
                      edges {
                        node {
                          id
                          type
                          name
                        }
                      }
                    }
                  }
                  labelDefinition {
                    operator
                    groups {
                      type
                      operator
                      attributeInstanceCounts {
                        attributeId
                        minCount
                        maxCount
                      }
                      attributeSensitivityCounts {
                        attributeSensitivityLabel
                        minCount
                        maxCount
                      }
                      fileClassificationSet {
                        fileClass
                        fileSubClass
                      }

                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryLabelById = (
  raw: any
): { label: DocumentLabel; operator: CriteriaOperatorTypes; rulesets: Ruleset[] } => {
  const set = raw.documentLabelSet.edges[0]?.node || {}
  const label = set.labels.edges[0]?.node || {}

  return {
    label: {
      ...label,
      labelSetId: set.id,
      labelSetName: set.name,
      datasourceCount: label?.datasourceCounts?.length,
      datasources:
        label?.datasourceCounts?.map((item) => {
          const { count, datasource } = item
          return {
            objectCount: count,
            type: datasource?.edges?.[0]?.node?.type,
            name: datasource?.edges?.[0]?.node?.name,
            id: datasource?.edges?.[0]?.node?.id
          }
        }) || []
    },
    operator: label?.labelDefinition?.operator,
    rulesets: label?.labelDefinition?.groups
  }
}

export const mutationSaveDocumentLabelProvider = (params: DocumentLabelProvider) => {
  let thirdPartyConfigurationFragment = ''
  if (params.type === DOCUMENT_LABEL_PROVIDERS.mip) {
    const { tenantId = '', clientId = '', clientSecret = '' } =
      params?.[DocumentLabelProviderConfigurations.mip] || {}

    thirdPartyConfigurationFragment = `${DocumentLabelProviderConfigurations.mip}: {
          tenantId: "${tenantId}"
          clientId: "${clientId}"
          clientSecret:  "${clientSecret}"
        }`
  } else if (params.type === DOCUMENT_LABEL_PROVIDERS.google) {
    const { accountJson = '', delegatedCredential = '', accountType = '' } =
      params?.[DocumentLabelProviderConfigurations.google] || {}

    thirdPartyConfigurationFragment = `${DocumentLabelProviderConfigurations.google}: {
          accountJson: "${accountJson}"
          delegatedCredential: "${delegatedCredential}"
          accountType: ${accountType.toUpperCase()}
        }`
  }

  return gql`
    mutation {
      createDocumentLabelSet(
        clientMutationId: "1"
        documentLabelSetData: {
          name: "${params.name}"
          type: ${params.type}
          thirdPartyConfiguration: {
            ${thirdPartyConfigurationFragment}
          }
        }
      ) {
        clientMutationId
        documentLabelSetId
      }
    }
  `
}

export const mutationCreateLabelSet = (params: CreateLabelSetParams) => {
  return `mutation {
    createDocumentLabelSet(
      clientMutationId: "1",
      documentLabelSetData: {
        name: "${params.name}"
        type: ${DOCUMENT_LABEL_PROVIDERS.lightBeam}
        description: "${escapeNewlineandTab(params.description)}"
        labels: []
      }
    ) {
      documentLabelSetId
    }
  }`
}

export const mutationUpdateLabelSet = (params: UpdateLabelSetParams) => {
  return ` mutation {
    updateDocumentLabelSet (
      clientMutationId: "1",
			documentLabelSetData: {
        labelSetId:"${params.setId}"
        name: "${params.name}"
        description: "${escapeNewlineandTab(params.description)}"
      }
    ) {
      documentLabelSetId
    }
  }`
}

const getGroupsFragment = (
  group: AttributeSensitivityType | AttributeInstanceCountType | FileClassificationType
) => {
  let fragment = ''
  switch (group.type) {
    case RulesetType.attributeInstanceCount:
      fragment = `attributeInstanceCounts:[${group.attributeInstanceCounts
        .map(
          (item) =>
            `{attributeId:"${item.attributeId}", minCount:${item.minCount}, ${
              item.maxCount ? `maxCount:${item.maxCount}` : ''
            }}`
        )
        .join(',')}]`
      break
    case RulesetType.attributeSensitivity:
      fragment = `attributeSensitivityCounts:[${group.attributeSensitivityCounts
        .map(
          (item) =>
            `{attributeSensitivityLabel:${item.attributeSensitivityLabel}, minCount:${item.minCount}}`
        )
        .join(',')}]`
      break
    case RulesetType.classification:
      fragment = `fileClassificationSet:[${group.fileClassificationSet
        .map(
          (item) => `{
        fileClass:"${item.fileClass}",
        fileSubClass:"${item.fileSubClass}",
      }`
        )
        .join(',')}]`
      break
  }
  return fragment
}

const getDocumentFiltersGroupFragment = (groups) => {
  return groups
    .map(
      (group) => `{
        operator: ${group.operator}
        type: ${group.type}
        ${getGroupsFragment(group)}
      }`
    )
    .join(',')
}

export const mutationCreateLabel = (params: CreateLabelParams) => {
  const { name, setId, priority, color, description, definition } = params

  return `mutation {
    createDocumentLabel(
        clientMutationId: "1",
        documentLabelData: {
          type: ${DOCUMENT_LABEL_PROVIDERS.lightBeam}
          name: "${name}"
          description: "${description ?? ''}"
          documentLabelSetId: "${setId}"
          priority: ${priority}
          color: "${color ?? ''}"
          definition: {
            operator: ${definition.operator}
            groups: [${getDocumentFiltersGroupFragment(definition.groups)}]
          }

        }
      ){ clientMutationId, documentLabelSetId }
    }`
}

export const mutationUpdateLabel = (params: UpdateLabelParams) => {
  // const { lightBeamLabelOperator, lightBeamLabelGroups } = params

  // const definition = getFragmentDefinition({ lightBeamLabelOperator, lightBeamLabelGroups })
  const { definition } = params

  return `mutation {
    updateDocumentLabel(
        clientMutationId: "1",
        documentLabelData: {
          name: "${params.name}"
          description: "${params.description ?? ''}"
          labelSetId: "${params.setId}"
          labelId: "${params.id}"
          color: "${params.color ?? ''}"
           definition: {
            operator: ${definition.operator}
            groups: [${getDocumentFiltersGroupFragment(definition.groups)}]
          }
        }
      ){ documentLabelSetId }
    }`
}

export const queryRulesetAttributes = (id?: string): string => {
  const idFragment = id ? `id: "${id}"` : ''

  return gql`
    {
      attributeSet(first: 999, ${idFragment}) {
        edges {
          node {
            id
            name
            type
            enabled
            systemDefined
            attributes {
              count
              edges {
                node {
                  id
                  name
                  sensitivityLabel
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryRulesetAttributes = (
  raw: any
): { attributeSets: AttributeSet[]; attributesBySensitivity: AttributesBySensitivity } => {
  try {
    const attributesBySensitivity: AttributesBySensitivity = {}
    const attributeSets = raw.attributeSet.edges.map(({ node: attrSet }) => {
      const attributes =
        attrSet.attributes?.edges?.map(({ node: attr }) => {
          const attribute = {
            id: attr.id,
            name: attr.name,
            setId: attrSet.id,
            sensitivityLabel: attr.sensitivityLabel
          }

          return attribute
        }) || []

      return {
        id: attrSet.id,
        name: attrSet.name,
        enabled: attrSet.enabled,
        description: attrSet.description || '',
        type: attrSet.type || '',
        attributesCount: attrSet.attributes?.count || 0,
        attributes: sortAttributesByInstanceCountAndSensitivity(attributes, SORT_ORDER.DESC)
      }
    })
    const sortedAttributeSets = sortByOrder<AttributeSet>(
      attributeSets,
      'instanceCount',
      SORT_ORDER.DESC
    ).sort((a, b) => +b.enabled - +a.enabled)

    const activeAttributeSets = attributeSets.filter((set) => set.enabled)
    const allAttributes = removeDuplicates<AttributeSetAttribute>(
      activeAttributeSets.reduce((acc, set) => acc.concat(set.attributes), []),
      'id'
    )

    attributesBySensitivity[SENSITIVE_LABEL.HIGH] = allAttributes.filter(
      (attr) => attr.sensitivityLabel === SENSITIVE_LABEL.HIGH
    )
    attributesBySensitivity[SENSITIVE_LABEL.MEDIUM] = allAttributes.filter(
      (attr) => attr.sensitivityLabel === SENSITIVE_LABEL.MEDIUM
    )
    attributesBySensitivity[SENSITIVE_LABEL.LOW] = allAttributes.filter(
      (attr) => attr.sensitivityLabel === SENSITIVE_LABEL.LOW
    )

    return {
      attributeSets: sortedAttributeSets,
      attributesBySensitivity
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const mutationDeleteLabel = (params: LabelByIdParams) => {
  return `mutation deleteDocumentLabel {
    deleteDocumentLabel (clientMutationId: 1, documentLabelData: {labelSetId: "${params.setId}", labelId: "${params.id}"  }) {
      documentLabelSetId
    }
  }`
}

export const queryLabelsWidget = ({ datasourceIds }: LabelsWidgetParams): string => {
  const dsIdParam = datasourceIds ? `(datasourceIds: "${datasourceIds}")` : ''

  return gql`
    {
      documentLabelSet${dsIdParam} {
        edges {
          node {
            labels${dsIdParam} {
              count
              edges {
                node {
                  id
                  name
                  type
                  objectCount
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryLabelsWidget = (raw: any): DocumentLabel[] => {
  return raw.documentLabelSet.edges
    .map(({ node: set }) => {
      return set?.labels?.edges?.map(({ node: label }) => {
        return {
          id: label?.id || '',
          name: label?.name || '',
          type: label?.type || '',
          objectCount: label?.objectCount || 0
        }
      })
    })
    .flat()
}

export const mutationAttachLabel = (params: { labels: DocumentLabel[]; docId: string }) => {
  const queryTransformed = gqlast`mutation {
    attachDocumentLabel(clientMutationId: "1",
    documentId: ${params.docId},
      objectLabelInput: ${params.labels}
    )
    {
      clientMutationId
    }
  }`
  return gql`
    ${queryTransformed}
  `
}
