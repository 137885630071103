import React, { useContext } from 'react'
export const LIGHT_THEME = 'light-theme'
export const DARK_THEME = 'dark-theme'
const ThemeContext = React.createContext<{
  theme: string
  applyTheme: (theme: string) => void
} | null>(null)

// wrapper to make theme and changeTheme available
// down the tree
function ThemeContextProvider({ children }) {
  const [theme, setTheme] = React.useState(LIGHT_THEME)

  const applyTheme = (newTheme) => {
    // TODO: apply new theme on app
    setTheme(newTheme)
  }

  return <ThemeContext.Provider value={{ theme, applyTheme }}>{children}</ThemeContext.Provider>
}

export const useThemeContext = () => {
  const themeContext = useContext(ThemeContext)
  if (!themeContext) throw new Error('useThemeContext cannot be used outside ThemeContextProvider')
  return themeContext
}

export default ThemeContextProvider
