import service from '../../services/api/apiService'
import { apiStatusToMessage } from '../../services/api/apiStatuses'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
export interface AuthProviderConfig {
  metadataUrl?: string
  clientId?: string
  clientSecret?: string
  endpoint?: string
}
export interface AuthProvider extends AuthProviderConfig {
  id?: string
  name?: string
  protocol?: string
  configuration?: AuthProviderConfig
  defaultUserRole?: string
}

export interface UserState {
  list?: AuthProvider[]
  authProvider?: AuthProvider
  isAuthProviderDeleted?: boolean
  isAuthProviderCreated?: boolean
}

export const initialState: UserState = {}

export const ACTION_AUTH_PROVIDERS_FETCH = 'auth-provider/list'
export const fetchAuthProviders = createAsyncThunk(
  ACTION_AUTH_PROVIDERS_FETCH,
  async (): Promise<AuthProvider[]> => {
    return await service.getAuthProviders().catch((e) => {
      throw new Error(e)
    })
  }
)

export const ACTION_AUTH_PROVIDER_BY_ID_FETCH = 'auth-provider/id'
export const fetchAuthProvider = createAsyncThunk(
  ACTION_AUTH_PROVIDER_BY_ID_FETCH,
  async (name: string): Promise<AuthProvider> => {
    return await service.getAuthProviderByName(name).catch((e) => {
      throw new Error(e)
    })
  }
)

export const ACTION_AUTH_PROVIDER_CREATE = 'auth-provider/add'
export const addAuthProvider = createAsyncThunk(
  ACTION_AUTH_PROVIDER_CREATE,
  async (params: AuthProvider, { rejectWithValue }) => {
    try {
      await service.createAuthProvider(params)
      return { statusMessage: 'authProvider.create.success' }
    } catch (error) {
      return rejectWithValue({ statusMessage: apiStatusToMessage[error as string] })
    }
  }
)

export const ACTION_AUTH_PROVIDER_UPDATE = 'auth-provider/update'
export const updateAuthProvider = createAsyncThunk(
  ACTION_AUTH_PROVIDER_CREATE,
  async (params: AuthProvider, { rejectWithValue }) => {
    try {
      await service.updateAuthProvider(params)
      return { statusMessage: 'authProvider.update.success' }
    } catch (error) {
      return rejectWithValue({ statusMessage: apiStatusToMessage[error as string] })
    }
  }
)

export const ACTION_AUTH_PROVIDER_DELETE = 'auth-provider/delete'
export const deleteAuthProvider = createAsyncThunk(
  ACTION_AUTH_PROVIDER_DELETE,
  async (name: string, { rejectWithValue }) => {
    try {
      await service.deleteAuthProvider(name)
      return { statusMessage: 'authProvider.delete.success' }
    } catch (error) {
      return rejectWithValue({ statusMessage: `error.message` })
    }
  }
)

const authProviderSlice = createSlice({
  name: 'authProvider',
  initialState,
  reducers: {
    resetAuthProvider: (state) => {
      state.authProvider = initialState.authProvider
    },
    resetAuthProviders: (state) => {
      state.list = initialState.list
    },
    resetIsAuthProviderDeleted: (state) => {
      state.isAuthProviderDeleted = initialState.isAuthProviderDeleted
    },
    resetIsAuthProviderCreated: (state) => {
      state.isAuthProviderCreated = initialState.isAuthProviderCreated
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAuthProviders.fulfilled, (state, action) => {
      state.list = action.payload
    })
    builder.addCase(fetchAuthProvider.fulfilled, (state, action) => {
      state.authProvider = action.payload
    })
    builder.addCase(deleteAuthProvider.fulfilled, (state) => {
      state.isAuthProviderDeleted = true
    })
    builder.addCase(addAuthProvider.fulfilled, (state) => {
      state.isAuthProviderCreated = true
    })
  }
})

export const {
  resetAuthProvider,
  resetAuthProviders,
  resetIsAuthProviderDeleted,
  resetIsAuthProviderCreated
} = authProviderSlice.actions
export default authProviderSlice.reducer
