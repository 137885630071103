import FirstLoadingSvg from '../../assets/inlineSvg/first_landing.svg'
import { URL_DATASOURCE_FORM } from '../../constants'
import React from 'react'
import { useIntl } from 'react-intl'
import './firstLanding.less'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
const FirstLanding = (): React.ReactElement => {
  const intl = useIntl()
  return (
    <div className="main-container" data-test-id="firstlanding-first-landing">
      <FirstLoadingSvg />
      <div className="title">{intl.formatMessage({ id: 'title.welcome' })}</div>
      <div className="sub-title">{intl.formatMessage({ id: 'first.landing.subTitle' })}</div>
      <Button primary as={Link} to={URL_DATASOURCE_FORM} className="xs-mt-40 button">
        {intl.formatMessage({ id: 'first.landing.button' })}
      </Button>
    </div>
  )
}

export default FirstLanding
