import PrintPageFooter from './footer'
import useUrlContext from '../../hooks/useUrlContext'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'

export type PageTitleParams = {
  title?: string
  subTitle?: string
  addSubtitleLegend?: boolean
  addSubtitleLabel?: boolean
  logo?: string
  logoPlaceholder?: string
  companyName?: string
  companyPlaceholder?: string
  date?: string
  subTitleColor?: string
  unbranded?: boolean
}

const PrintTitlePage = (props: PageTitleParams): React.ReactElement => {
  const context = useUrlContext()
  const {
    date = '',
    title = '',
    subTitle,
    logo = '',
    addSubtitleLegend = false,
    addSubtitleLabel = true,
    subTitleColor,
    logoPlaceholder = '',
    companyName: outerCompanyName = '',
    companyPlaceholder = '',
    unbranded = false
  } = props || {}
  const pageTitle = title || ((context.queryParams?.title || '') as string)
  const coverHelpColor =
    subTitleColor ||
    ((context.queryParams?.subTitleColor ? `#${context.queryParams?.subTitleColor}` : '') as string)
  const coverSubtitleLabel = addSubtitleLabel && context.queryParams?.addSubtitleLabel !== 'false'
  const unbrandedVal = unbranded || context.queryParams?.unbranded === 'true' || false
  const pageSubTitle = subTitle || ((context.queryParams?.subTitle || '') as string)
  const companyName = outerCompanyName || ((context.queryParams?.companyName || '') as string)
  const showSubtitleLegend =
    addSubtitleLegend || ((context.queryParams?.showSubtitleLegend || '') as string) === 'true'
  const companyLogo = logo || ((context.queryParams?.companyLogo || '') as string)

  return (
    <div className="print-page page-title" data-test-id="print-title-page">
      <div className="wrapper">
        {(companyLogo || logoPlaceholder) && (
          <div className="logo-wrapper">
            {companyLogo ? (
              <div className="img-container">
                <img src={companyLogo} />
              </div>
            ) : (
              <div className="text">{logoPlaceholder}</div>
            )}
          </div>
        )}
        {(companyName || companyPlaceholder) && (
          <div className="company-wrapper">
            <div className="text">{companyName || companyPlaceholder}</div>
          </div>
        )}
        <div className="main-title">{pageTitle && window.decodeURIComponent(pageTitle)}</div>
        {showSubtitleLegend ? (
          <>
            {coverSubtitleLabel && (
              <p className="subtitle-title xs-mb-4 xs-mt-24">
                <FormattedMessage id="ropa.report.header.process" />
              </p>
            )}
            <p
              className={classNames('subtitle-value', {
                'xs-mt-12': !!coverHelpColor
              })}
              {...(coverHelpColor ? { style: { color: coverHelpColor } } : {})}
            >
              {pageSubTitle && window.decodeURIComponent(pageSubTitle)}
            </p>
          </>
        ) : (
          <div className="sub-title">{pageSubTitle && window.decodeURIComponent(pageSubTitle)}</div>
        )}
        <PrintPageFooter isTitlePage unbranded={unbrandedVal} date={date} />
      </div>
    </div>
  )
}

export default PrintTitlePage
