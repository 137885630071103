import service, { InviteUserParams, UserAccess, UserRole } from '../../services/api/apiService'
import {
  GDriveUsersWidgets,
  GDriveUsersWidgetsQueryParams,
  GMailUsersWidget,
  GMailUsersWidgetQueryParams,
  OutlookUsersWidget,
  OutlookUsersWidgetQueryParams
} from '../../services/api/apiTypes'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import { APIResponseMsg, IGetUsersParams } from '../../interfaces'
import { IS_AT_RISK, IS_SENSITIVE } from '../../constants'
import { RootState } from '../../rootReducer'
import { getGlobalParams } from '../../utils/urlUtil'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface IUser {
  name?: string
  firstName?: string
  lastName?: string
  id?: string
  description?: string
  type?: string
  recordCount?: number
  classifiedFilesCount?: number
  unclassifiedFilesCount?: number
  piiCount?: number
  riskyCount?: number
  emailId?: string
  lastActiveAt?: string
  accessLevels?: UserAccess[]
  roles?: {
    name: string
    description: string
    accessLevels: UserAccess[]
  }[]
}
export type UsersListSettings = {
  list?: IUser[]
  total?: number
  sort: SortParams
}

export interface UsersCountCombined {
  all?: number
  risky?: number
  sensitive?: number
}
export interface UserState {
  gDrive?: GDriveUsersWidgets
  all: UsersListSettings
  risky: UsersListSettings
  sensitive: UsersListSettings
  gMail?: GMailUsersWidget
  outLook?: OutlookUsersWidget
  usersCountCombined: UsersCountCombined
  inviteUserResponse?: APIResponseMsg
  roles?: UserRole[]
  user?: IUser
}

const initialList = {
  sort: defaultSortParams
}

export const initialState: UserState = {
  all: { ...initialList },
  risky: { ...initialList },
  sensitive: { ...initialList },
  usersCountCombined: {}
}

export const fetchUserGDriveWidgetData = createAsyncThunk(
  'users/gDrive',
  async (filters: GDriveUsersWidgetsQueryParams) => await service.getGDriveUsersWidgetData(filters)
)
export const fetchDatasourceUsersList = createAsyncThunk(
  'datasource/users/list',
  async (filters: IGetUsersParams) => await service.getDatasourceUsersList(filters)
)

export const ACTION_FETCH_USERS = 'users/list'
export const fetchUsersList = createAsyncThunk(
  ACTION_FETCH_USERS,
  async (filters: IGetUsersParams, { getState }) => {
    const queryParams = { ...filters, ...getGlobalParams(getState() as RootState) }
    return await service.getUsersList(queryParams)
  }
)

export const ACTION_FETCH_USER = 'get-user/id'
export const fetchUserById = createAsyncThunk(
  ACTION_FETCH_USER,
  async (id: string) => await service.getUserById(id)
)

export const ACTION_USER_DELETE = 'user/delete'
export const deleteUser = createAsyncThunk(
  ACTION_USER_DELETE,
  async (id: string) => await service.deleteUser(id)
)

export const fetchAvailableUserRoles = createAsyncThunk(
  'users/roles',
  async () => await service.getUserRolesList()
)

export const ACTION_USER_INVITE = 'user/invite'
export const inviteNewUser = createAsyncThunk(
  ACTION_USER_INVITE,
  async (params: InviteUserParams) => await service.postInviteUser(params)
)

export interface UpdateUserParams {
  userId?: string
  email_id: string
  roles: {
    access_levels: Array<UserAccess>
    name: string
    description: string
  }[]
}
export const ACTION_USER_INFO_UPDATE = 'user/update'
export const updateUserInfo = createAsyncThunk(
  ACTION_USER_INFO_UPDATE,
  async (params: UpdateUserParams) => await service.patchUser(params)
)

export const fetchUsersCombinedCount = createAsyncThunk(
  'users/combinedCount',
  async (filters: IGetUsersParams) => {
    const allResult = await service.getUsersList(filters)
    const isAtRiskResult = await service.getDatasourceUsersList({
      ...filters,
      [IS_AT_RISK]: true
    })
    const isSensitiveResult = await service.getDatasourceUsersList({
      ...filters,
      [IS_SENSITIVE]: true
    })
    return {
      all: allResult.total,
      risky: isAtRiskResult.total,
      sensitive: isSensitiveResult.total
    }
  }
)

export const fetchUsersGMailWidgetData = createAsyncThunk(
  'users/gMail',
  async (filters: GMailUsersWidgetQueryParams) => await service.getGMailUsersWidgetData(filters)
)

export const fetchUsersOutlookWidgetData = createAsyncThunk(
  'users/outLook',
  async (filters: OutlookUsersWidgetQueryParams) => await service.getOutlookUsersWidgetData(filters)
)

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSort: (state, { payload }) => {
      state[payload.list].sort = getSortDirection(state[payload.list].sort, payload.column)
    },
    resetWidget: (state) => {
      state.gDrive = initialState.gDrive
      state.gMail = initialState.gMail
      state.outLook = initialState.outLook
      state.usersCountCombined = initialState.usersCountCombined
    },
    clearList: (state) => {
      state.all = { ...initialList }
      state.sensitive = { ...initialList }
      state.risky = { ...initialList }
    },
    resetInviteUser: (state) => {
      state.inviteUserResponse = initialState.inviteUserResponse
    },
    resetUser: (state) => {
      state.user = initialState.user
    },
    resetAvailableUserRoles: (state) => {
      state.roles = initialState.roles
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserGDriveWidgetData.fulfilled, (state, action) => {
      state.gDrive = action.payload
    })
    builder.addCase(fetchDatasourceUsersList.fulfilled, (state, { payload }) => {
      const { list, total } = payload

      if (payload.risky) {
        state.risky.list = list
        state.risky.total = total
      } else if (payload.sensitive) {
        state.sensitive.list = list
        state.sensitive.total = total
      } else {
        state.all.list = list
        state.all.total = total
      }
    })
    builder.addCase(fetchUsersList.fulfilled, (state, { payload }) => {
      const { list, total } = payload
      state.all.list = list
      state.all.total = total
    })
    builder.addCase(fetchUsersList.rejected, (state) => {
      state.all.list = []
      state.all.total = 0
    })
    builder.addCase(fetchUsersCombinedCount.fulfilled, (state, action) => {
      state.usersCountCombined.all = action.payload.all
      state.usersCountCombined.risky = action.payload.risky
      state.usersCountCombined.sensitive = action.payload.sensitive
    })
    builder.addCase(fetchUsersGMailWidgetData.fulfilled, (state, action) => {
      state.gMail = action.payload
    })
    builder.addCase(fetchUsersOutlookWidgetData.fulfilled, (state, action) => {
      state.outLook = action.payload
    })
    builder.addCase(inviteNewUser.rejected, (state, action) => {
      state.inviteUserResponse = { error: action.error.message || '', success: '' }
    })
    builder.addCase(fetchAvailableUserRoles.fulfilled, (state, { payload }) => {
      state.roles = payload
    })
    builder.addCase(fetchUserById.fulfilled, (state, { payload }) => {
      const { user } = payload
      state.user = user
    })
  }
})

export const {
  setSort,
  clearList,
  resetWidget,
  resetInviteUser,
  resetUser,
  resetAvailableUserRoles
} = usersSlice.actions

export default usersSlice.reducer
