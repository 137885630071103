import './headerWithActions.less'
import cx from 'classnames'
import React from 'react'
import { Typography, Button } from '@lightbeamai/design-system'

interface IProps {
  title: string
  className?: string
  children?: React.ReactElement
  closeText?: string
  onClose?: () => void
}

const HeaderWithActions = ({
  title = '',
  className = '',
  children,
  closeText = '',
  onClose
}: IProps): React.ReactElement => {
  return (
    <header
      className={cx('header-with-actions', { [className]: className })}
      data-test-id="policies-header-with-actions"
    >
      <Typography as="span" variant="base" color="primary" type="strong">
        {title}
      </Typography>
      <div className="header-actions xs-mb-24 md-mb-0">
        {children ? children : ''}
        {onClose && (
          <Button variant="secondary" className="xs-mr-0" onClick={onClose}>
            {closeText}
          </Button>
        )}
      </div>
    </header>
  )
}

export default HeaderWithActions
