import { queryApiRequestsWidget, mapQueryApiRequestsWidget } from './queries'
import graphqlService from '../../services/graphqlService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export type ApiRequestsWidget = {
  total: number
}

export const ACTION_API_REQUESTS_WIDGET_FETCH = 'apiRequests/widget'
export const fetchApiRequestsWidget = createAsyncThunk(
  ACTION_API_REQUESTS_WIDGET_FETCH,
  async () => {
    graphqlService
    queryApiRequestsWidget
    const resultRaw = await graphqlService.execute(queryApiRequestsWidget())
    return mapQueryApiRequestsWidget(resultRaw)
  }
)

interface ApiRequestsState {
  widget?: ApiRequestsWidget
}

export const initialState: ApiRequestsState = {
  widget: {
    total: 0
  }
}

const apiRequestsSlice = createSlice({
  name: 'apiRequests',
  initialState,
  reducers: {
    resetWidget: (state) => {
      state.widget = initialState.widget
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchApiRequestsWidget.fulfilled, (state, { payload }) => {
      state.widget = payload
    })
  }
})

export const { resetWidget } = apiRequestsSlice.actions

export default apiRequestsSlice.reducer
