import './dropdownAddItem.less'
import React, { useState } from 'react'
import { Dropdown, DropdownProps } from 'semantic-ui-react'

export type DropdownOption = {
  icon?: React.ReactElement
  key: string | number
  value: string | number | boolean
  text: string | number | boolean
}

interface IProps {
  optionsList: DropdownOption[]
  trigger: string | number | boolean | React.ReactElement
  disabled?: boolean
  className?: string
  onAddItem: (value: DropdownOption['value']) => void
}

const DropdownAddItem = (props: IProps): React.ReactElement => {
  const { optionsList = [], trigger, disabled = false, className = '', onAddItem } = props

  const [value, setValue] = useState('')
  const [isPlaceholderVisible, setPlaceholderVisible] = useState(true)

  const hidePlaceholder = () => setPlaceholderVisible(false)
  const showPlaceholder = () => setPlaceholderVisible(true)

  const handleAddItem = (_, data: DropdownProps) => {
    if (typeof data.value === 'string' || typeof data.value === 'number') {
      onAddItem(data.value)
      setValue('')
    }
  }

  return (
    <Dropdown
      data-test-id="dropdown-add-item"
      search
      selectOnNavigation={false}
      disabled={disabled}
      options={optionsList}
      onChange={handleAddItem}
      className={`dropdown-add-item ${className}`}
      value={value}
      icon={false}
      onFocus={hidePlaceholder}
      onBlur={showPlaceholder}
      defaultSelectedLabel={undefined}
      selectOnBlur={false}
      trigger={isPlaceholderVisible ? trigger : ''}
    />
  )
}

export default DropdownAddItem
