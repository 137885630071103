import './classifications.less'
import {
  ACTION_CLASSIFICATION_PRINT,
  ClassificationsPrint,
  fetchClassificationsPrint
} from './classificationsSlice'
import { getShowLoader } from '../../reducers/requestReducer'
import { RootState } from '../../rootReducer'
import { getClassificationIcon } from '../../utils/classificationIcons'
import { CLASSICATION_TYPE } from '../../constants'
import SvgNoData from '../../assets/inlineSvg/empty_doc_classification_print.svg'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'

interface IProps {
  classificationsPrint?: ClassificationsPrint[]
  showLoader: boolean
  fetchClassificationsPrint: () => void
}

const ClassificationsPrint = (props: IProps): React.ReactElement => {
  const { classificationsPrint = [], fetchClassificationsPrint } = props

  useEffect(() => {
    fetchClassificationsPrint()
  }, [fetchClassificationsPrint])

  const intl = useIntl()
  const noDataClassificationsPrint = classificationsPrint.length === 0

  return (
    <>
      {noDataClassificationsPrint ? (
        <div className="no-data">
          <SvgNoData />
        </div>
      ) : (
        <div className="classifications-print" data-test-id="classifications-classifications-print">
          <div className="wrapper">
            <div className="row table-header">
              <div className="col">Document type</div>
              <div className="col">Files</div>
            </div>

            {classificationsPrint.map((item, i) => {
              const icon = getClassificationIcon(item.classification || CLASSICATION_TYPE.OTHERS)
              return (
                <div key={i} className="row-wrap">
                  <div className="row row-header">
                    <div className="col">
                      <div className="header-container">
                        <div className="icon-box">{icon}</div>
                        <div className="class-name">{item.classification}</div>
                      </div>
                    </div>
                    <div className="col">
                      {intl.formatNumber(item.objectsCount, { notation: 'compact' })}
                    </div>
                  </div>
                  {item.subclasses.map((subclass, x) => (
                    <div className="row subclass" key={x}>
                      <div className="col">{subclass}</div>
                      <div className="col"></div>
                    </div>
                  ))}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  classificationsPrint: state.classifications.classificationsPrint,
  showLoader: getShowLoader(ACTION_CLASSIFICATION_PRINT)
})

const mapDispatchToProps = {
  fetchClassificationsPrint
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassificationsPrint)
