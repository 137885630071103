import localForage from 'localforage'

export const memCache: () => {
  get: (key: string) => Promise<any | null>
  set: (key: string, val: any) => Promise<any | null>
  remove: (key: string) => Promise<void>
} = function () {
  return {
    get: async (key) => localForage.getItem(key),
    set: async (key, val) => localForage.setItem(key, val),
    remove: async (key) => localForage.removeItem(key)
  }
}
