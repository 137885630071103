import {
  AutomationModes,
  DsrForm,
  DsrFormAttribute,
  DsrFormWorkflowType,
  RequestType
} from './dsrFormsSlice'
import { gql } from 'graphql-request'

export const queryDsrFormAttributes = (): string => {
  return gql`
    {
      attribute {
        count
        edges {
          node {
            id
            name
            internalName
          }
        }
      }
    }
  `
}

export const mapQueryDsrFormAttributes = (raw: any): DsrFormAttribute[] => {
  return raw.attribute.edges.map(({ node: attr }) => ({
    id: attr.id,
    name: attr.name,
    internalName: attr.internalName
  }))
}

function generateGraphQLInputArray(dataArray, enumFields: string[] = []) {
  return `[${dataArray
    .map((item) => {
      const fields = Object.keys(item)
        .map((key) => {
          // Check if the field should be treated as an enum
          if (enumFields.includes(key)) {
            return `${key}: ${item[key]}` // No quotes around enum values
          }
          // Treat other fields as normal strings or numbers
          const value = typeof item[key] === 'string' ? `"${item[key]}"` : item[key]
          return `${key}: ${value}`
        })
        .join(', ')
      return `{ ${fields} }`
    })
    .join(', ')}]`
}

export const mutationSaveDsrForm = (form: DsrForm) => {
  // Construct the automationConfiguration string manually
  const automationConfiguration =
    form?.automationConfiguration?.map(({ requestType, mode, workflowConfigId }) => {
      const config: any = {
        requestType: RequestType[requestType as keyof typeof RequestType],
        mode: AutomationModes[mode as keyof typeof AutomationModes]
      }

      if (workflowConfigId) {
        config.workflowConfigId = workflowConfigId // Keep quotes for workflowConfigId values
      }

      return config
    }) || null

  //Format the automationConfiguration to a valid GraphQL format
  const FormattedAutomationConfiguration = generateGraphQLInputArray(automationConfiguration, [
    'requestType',
    'mode'
  ])

  return gql`
    mutation {
      createDsrForm(clientMutationId: "createDsrForm", dsrFormData: {
        name: ${JSON.stringify(form.name || '')}
        type: ${form.type}
        description: ${JSON.stringify(form.description || '')}
        locations: ${JSON.stringify(form.locations || [])}
        isDraft: false
        isTemplate: ${!!form.isTemplate}
        createdBy: "${form.createdBy || ''}"
        template: {
          name: ${JSON.stringify(form.template?.name || '')}
        }
        logoFileUrl: "${form.logoFileUrl || ''}"
        formRawJSON: ${JSON.stringify(form.formRawJSON || '')}
        automationConfiguration: ${FormattedAutomationConfiguration}
      }) {
        clientMutationId
        dsrFormId
      }
    }
  `
}

export const mutationUpdateDsrForm = (form: DsrForm) => {
  const automationConfiguration =
    (form.automationConfiguration &&
      form?.automationConfiguration.map(({ requestType, mode, workflowConfigId }) => {
        const config: DsrFormWorkflowType = {
          requestType,
          mode
        }

        if (workflowConfigId) {
          config.workflowConfigId = workflowConfigId
        }

        return config
      })) ||
    null

  //Format the automationConfiguration to a valid GraphQL format
  const FormattedAutomationConfiguration = generateGraphQLInputArray(automationConfiguration, [
    'requestType',
    'mode'
  ])

  return gql`
    mutation {
      updateDsrForm(clientMutationId: "createDsrForm", dsrFormId: "${form.id}", dsrFormData: {
        name: ${JSON.stringify(form.name || '')}
        type: ${form.type}
        description: ${JSON.stringify(form.description || '')}
        locations: ${JSON.stringify(form.locations || [])}
        isDraft: false
        isTemplate: ${!!form.isTemplate}
        createdBy: "${form.createdBy || ''}"
        template: {
          name: ${JSON.stringify(form.template?.name || '')}
        }
        logoFileUrl: "${form.logoFileUrl || ''}"
        formRawJSON: ${JSON.stringify(form.formRawJSON || '')}
        automationConfiguration: ${FormattedAutomationConfiguration}
      }) {
        clientMutationId
        dsrFormId
      }
    }
  `
}

export const mutationDeleteDsrForm = (formId: string) => {
  return gql`
    mutation {
      deleteDsrForm(clientMutationId: "deleteDsrForm", dsrFormId: "${formId}"){ clientMutationId }
    }
  `
}

export const mutationPublishDsrForm = (formId: string) => {
  return gql`
    mutation {
      publishDsrForm(clientMutationId: "publishDsrForm", dsrFormId: "${formId}"){ clientMutationId }
    }
  `
}

export const mutationUnpublishDsrForm = (formId: string) => {
  return gql`
    mutation {
      unpublishDsrForm(clientMutationId: "unpublishDsrForm", dsrFormId: "${formId}"){ clientMutationId }
    }
  `
}

export const mutationResendVerification = (email: string) => {
  return gql`
    mutation {
      resendDsrVerificationEmail(clientMutationId: "resendDsrVerificationEmail", dataSubjectEmailId: "${email}"){ clientMutationId }
 }
  `
}

export const queryTotalUserForms = (): string => {
  return gql`
    {
      dataSubjectRequestForm(booleanFilter: [{ key: IS_TEMPLATE, value: false }]) {
        count
      }
    }
  `
}

export const mapQueryTotalUserForms = (raw: any): number => {
  return raw.dataSubjectRequestForm.count || 0
}

// DSR Workflow List Query
export const queryDsrWorkflowListByRequestType = (params) => {
  const requestType = params

  return gql`{
    dsrWorkflowConfig (filter: [{ key: REQUEST_TYPE, values: ["${requestType}"] }]){
      edges{
        node{
          id
          name
          idVerificationConfig {
            requestSubmissionEmailTemplateID
            IdVerificationEmailTemplateID
          }
        }
      }
    }
  }  `
}

// DSR Workflow List Query Mapper
export const mapQueryDsrWorkflowList = (raw: any) => {
  try {
    return raw?.dsrWorkflowConfig?.edges?.map(({ node: workflow }) => ({
      id: workflow.id,
      name: workflow.name,
      requestSubmissionEmailTemplateID:
        workflow.idVerificationConfig?.requestSubmissionEmailTemplateID,
      idVerificationEmailTemplateID: workflow.idVerificationConfig?.IdVerificationEmailTemplateID
    }))
  } catch (error) {
    console.error(error)
    throw error
  }
}
