import './table.less'
import RegularColumn from './regularColumn'
import PopupInfo from '../PopupInfo/popupInfo'
import { SORT_ORDER } from '../../interfaces'
import { getMaskedString } from '../../utils/stringUtil'
import { getUrlParam } from '../../utils/urlUtil'
import cx from 'classnames'
import React, { useState } from 'react'
import { Checkbox, StrictTableProps, Table, TableBodyProps } from 'semantic-ui-react'
import { Typography } from '@lightbeamai/design-system'

type classes = {
  table?: string
  thead?: string
  th?: string
  tr?: string
  td?: string
}

interface TableProps extends StrictTableProps {
  classes?: classes
}

export type RowProps = {
  dataKey?: string | number
  onClick?: (data: any) => void
}

type TableBaseColumn = {
  title: string
  dataKey: string
  onClick?: () => void
  sorted?: SORT_ORDER | null
  className?: string
  isColumnSelectable?: boolean
  isColumnSelected?: boolean
  tooltip?: string
  disabled?: boolean
  widthPercent?: number
  toggle?: boolean
  onColumnSelectChange?: () => void
}

interface TableColumnWithCellRenderer extends TableBaseColumn {
  cellRenderer?: (item: any) => React.ReactElement | string | number
  masking?: never
  maskingToggleDisabled?: never
}
export interface TableColumnWithMasking extends TableBaseColumn {
  masking?: boolean
  cellRenderer?: (item: any) => React.ReactElement | string | number
  maskingToggleDisabled?: boolean
}
/**A column config can only contain either masking or cellRenderer as making cannot be provided in case of a complex component */
export type TableColumn = TableColumnWithCellRenderer | TableColumnWithMasking
interface IDataExpandable {
  isExpanded: boolean
  cellRenderer: () => React.ReactElement | string | number | boolean
}

interface IProps {
  columns: TableColumn[]
  data: TableBodyProps
  dataExpandable?: IDataExpandable[]
  tableProps?: TableProps
  rowProps?: RowProps
  hideTableHeader?: boolean
  isCellRenderer?: boolean // TODO: remove this prop after adding "cellRenderer" function to every table
  scrollable?: boolean // remove table responsiveness, adds horizontal scroll
}

const GenericTable = (props: IProps): React.ReactElement => {
  const {
    columns,
    data,
    dataExpandable = [],
    tableProps,
    rowProps,
    isCellRenderer,
    hideTableHeader,
    scrollable = true
  } = props
  let classes
  if (tableProps) {
    classes = tableProps.classes
  }

  const maskingDefaultConfig = columns
    .filter((col) => col.masking)
    .reduce((acc, col) => {
      acc[col.dataKey] = true
      return acc
    }, {})
  const [maskingToggle, setMaskingToggle] = useState<{ [key: string]: boolean }>(
    maskingDefaultConfig
  )

  const columnsByKeyMap = {}
  columns.forEach((col) => (columnsByKeyMap[col.dataKey] = col))
  const headers = (
    <Table.Row>
      {columns.map((col, ind) => {
        const {
          title,
          className = '',
          isColumnSelectable,
          disabled = false,
          onColumnSelectChange,
          isColumnSelected,
          onClick,
          masking = false,
          tooltip,
          maskingToggleDisabled = false,
          toggle = false
        } = col
        const combinedClass = `${classes?.th} ${className}`
        const isPrint = getUrlParam('print')

        const getHeaderContent = () => {
          if (masking) {
            return (
              <span className="field-selectable">
                <span onClick={onClick || undefined}>{col.title}</span>
                {!maskingToggleDisabled && (
                  <Checkbox
                    title={title}
                    toggle
                    checked={maskingToggle[col.dataKey]}
                    onChange={(e) => {
                      e.stopPropagation()
                      setMaskingToggle({
                        ...maskingToggle,
                        [col.dataKey]: maskingToggle ? !maskingToggle[col.dataKey] : true
                      })
                    }}
                    label=""
                    className="simple fitted xs-ml-8"
                  />
                )}
              </span>
            )
          }
          if (isColumnSelectable) {
            return (
              <span className="field-selectable">
                <Checkbox
                  title={title}
                  checked={isColumnSelected}
                  onChange={onColumnSelectChange}
                  label=""
                  disabled={disabled}
                  toggle={toggle}
                />
                <span onClick={onClick || onColumnSelectChange || undefined}>{col.title}</span>
              </span>
            )
          }
          if (tooltip) {
            return (
              <span className="field-tooltip">
                <span onClick={onClick || onColumnSelectChange || undefined}>{col.title}</span>
                <PopupInfo
                  inverted
                  flowing
                  sizeIcon={18}
                  text={tooltip}
                  iconClass="xs-ml-4"
                  className="column-tooltip"
                  position="top center"
                  on={['click', 'hover']}
                />
              </span>
            )
          }
          return col.title
        }
        return (
          <Table.HeaderCell
            key={`${title}-${ind}`}
            className={combinedClass}
            onClick={onClick}
            {...(col.tooltip ? {} : { title: col.title })}
          >
            <Typography
              as="span"
              color={isPrint ? 'custom' : 'primary'}
              variant="base"
              type="medium"
            >
              {getHeaderContent()}
            </Typography>
          </Table.HeaderCell>
        )
      })}
    </Table.Row>
  )

  const rows = data.map((item, i) => {
    const handleClick = () => {
      return rowProps?.onClick && rowProps.dataKey && rowProps.onClick(item[rowProps.dataKey])
    }
    const cells = Object.keys(columnsByKeyMap)

    const className = cx(
      { [classes.tr]: !!classes.tr },
      { clickable: rowProps?.onClick && rowProps.dataKey }
    )

    return (
      <React.Fragment key={i}>
        <Table.Row onClick={handleClick} className={className}>
          {cells.map((key, p) => (
            <Table.Cell className={classes?.td} key={p} {...(p == 0 ? { width: 2 } : {})}>
              {isCellRenderer && columnsByKeyMap[key].cellRenderer ? (
                columnsByKeyMap[key].cellRenderer(item)
              ) : columnsByKeyMap[key].masking ? (
                <RegularColumn
                  title={columnsByKeyMap[key].title}
                  content={
                    maskingToggle && maskingToggle[key] ? getMaskedString(item[key]) : item[key]
                  }
                />
              ) : (
                item[key]
              )}
            </Table.Cell>
          ))}
        </Table.Row>
        {dataExpandable.length > 0 && dataExpandable[i]?.isExpanded && (
          <Table.Row colSpan={cells.length}>
            <Table.Cell colSpan={cells.length}>{dataExpandable[i].cellRenderer()}</Table.Cell>
          </Table.Row>
        )}
      </React.Fragment>
    )
  })

  const scrollableClass = scrollable ? 'scrollable custom-scrollbar' : ''

  const finalProps: StrictTableProps = {
    basic: 'very',
    verticalAlign: 'middle',
    className: `${scrollableClass} ${classes?.table || ''}`, // scrollable adds horizontal scroll
    collapsing: tableProps?.collapsing || false, // table takes up only as much space as its rows
    selectable: tableProps?.selectable || true, // row highlights on hover
    sortable: tableProps?.sortable || true, // sort columns by content
    unstackable: tableProps?.unstackable || false // stacks table content responsively.
  }

  return (
    <Table {...finalProps} data-test-id="table-generic-table">
      <Table.Header
        {...(classes && classes.thead ? { className: classes.thead } : {})}
        className={hideTableHeader ? 'header-hidden' : ''}
      >
        {headers}
      </Table.Header>
      <Table.Body>{rows}</Table.Body>
    </Table>
  )
}

export default GenericTable
