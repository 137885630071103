import { ScanStatus } from '../interfaces'

export const isReviewNeeded = (confidence: number): boolean => confidence > 0 && confidence < 70

export const mapDataSourceGraphQlStatus = (status: string): ScanStatus => {
  if (status === 'Registering') return ScanStatus.registering
  if (status === 'Scanning') return ScanStatus.scanning
  if (status === 'SCANNING') return ScanStatus.scanning
  if (status === 'SCANNED') return ScanStatus.completed
  if (status === 'Good') return ScanStatus.completed
  if (status === 'Warning') return ScanStatus.warning
  if (status === 'Disconnected') return ScanStatus.disconnected
  if (status === 'Deleting') return ScanStatus.deleting

  return ScanStatus.registering
}

export const mapDatabaseGraphQlStatus = (status: string): ScanStatus => {
  if (status === 'registering') return ScanStatus.registering
  if (status === 'running') return ScanStatus.scanning
  if (status === 'failed') return ScanStatus.failed
  if (status === 'completed') return ScanStatus.completed

  return ScanStatus.failed
}
