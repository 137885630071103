import {
  IGetPoliciesByTypeParams,
  mapQueryAssigneesNameEmail,
  mapQueryAwsBucketFilters,
  mapQueryAwsBucketOwners,
  mapQueryDataSourceNames,
  mapQueryDrivesNames,
  mapQueryJiraProjectIdNames,
  mapQueryPoliciesIDNamesByType,
  mapQueryProjectIdNames,
  mapQueryReportersNameEmail,
  mapQueryRopaProcessOwners,
  mapQueryTemplates,
  queryAssigneesNameEmail,
  queryAwsBucketFilters,
  queryAwsBucketOwners,
  queryDataSourceNames,
  queryDrivesNames,
  queryJiraProjectIdNames,
  queryPoliciesIDNamesByType,
  queryProjectIdNames,
  queryReportersNameEmail,
  queryRopaProcessOwners,
  queryTemplates
} from './queries'
import { FilterParams } from '../../interfaces'
import service from '../../services/api/apiService'
import {
  mapQueryBLOBSParentsSummary,
  mapQueryDatabasesSummary,
  mapQueryTablesSummary,
  queryBLOBSParentsSummary,
  queryDatabasesSummary,
  queryTablesSummary
} from '../blobs/queries'
import { NameIdSummary } from '../ropa/ropaSlice'
import { Classification } from '../../services/api/apiTypes'
import graphqlService from '../../services/graphqlService'
import { DATA_SOURCE_TYPE_API_MAP } from '../../constants'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface FileType {
  name: string
  types: string[]
}

interface FiltersState {
  isFiltersReceived: boolean
  dataSourcesTypes: string[]
  classes: Classification[]
  subClasses: string[]
  templates: { id: string; name: string }[]
  fileTypes: FileType[]
  datasources: NameIdSummary[]
  projects?: { id: string; name: string }[]
  drives?: { id: string; driveName: string }[]
  bucketOwners?: { id: string; name: string }[]
  processOwners?: string[]
  awsBuckets?: string[]
  policies?: { id: string; name: string }[]
  reporters?: { email: string; name: string }[]
  assignees?: { email: string; name: string }[]
  blobParents?: { id: string; name: string }[]
  databases?: { id: string; name: string }[]
  tables?: { id: string; name: string }[]
  datasourcesLoaded: boolean
}

export const initialState: FiltersState = {
  isFiltersReceived: false,
  dataSourcesTypes: Object.values(DATA_SOURCE_TYPE_API_MAP),
  classes: [],
  subClasses: [],
  templates: [],
  fileTypes: [
    { name: 'TEXT', types: ['txt'] },
    { name: 'PDF', types: ['pdf'] },
    { name: 'MS DOC', types: ['doc', 'docx'] },
    { name: 'EXCEL', types: ['xls', 'xlsx'] },
    { name: 'IMAGE', types: ['jpg', 'jpeg', 'png'] }
  ],
  datasources: [],
  datasourcesLoaded: false
}

export const fetchFilters = createAsyncThunk('filters/get', async () => {
  const results = await Promise.all([service.getClassificationsList()]).catch((error) => {
    throw error
  })

  return {
    classes: results[0]
  }
})

export const ACTION_PROJECTS_ID_NAMES_FETCH = 'projects/namesIds'
export const fetchProjectsIDNames = createAsyncThunk(ACTION_PROJECTS_ID_NAMES_FETCH, async () => {
  const result = await graphqlService.execute(queryProjectIdNames())
  return mapQueryProjectIdNames(result)
})

export const ACTION_AWS_BUCKET_OWNERS_FETCH = 'aws/bucketOwners'
export const fetchAwsBucketOwners = createAsyncThunk(
  ACTION_AWS_BUCKET_OWNERS_FETCH,
  async (filters?: FilterParams) => {
    const result = await graphqlService.execute(queryAwsBucketOwners(filters))
    return mapQueryAwsBucketOwners(result)
  }
)

export const ACTION_ROPA_PROCESS_OWNERS_FETCH = 'ropa/process-owners'
export const fetchRopaProcessOwners = createAsyncThunk(
  ACTION_ROPA_PROCESS_OWNERS_FETCH,
  async () => {
    const result = await graphqlService.execute(queryRopaProcessOwners())
    return mapQueryRopaProcessOwners(result)
  }
)

export const ACTION_AWS_S3_BUCKETS_FETCH = 'aws-s3/buckets'
export const fetchAwsBucketFilters = createAsyncThunk(
  ACTION_AWS_S3_BUCKETS_FETCH,
  async (filters?: FilterParams) => {
    const result = await graphqlService.execute(queryAwsBucketFilters(filters))
    return mapQueryAwsBucketFilters(result)
  }
)

export const ACTION_JIRA_PROJECTS_ID_NAMES_FETCH = 'jira/projects/namesIds'
export const fetchJiraProjectsIDNames = createAsyncThunk(
  ACTION_JIRA_PROJECTS_ID_NAMES_FETCH,
  async (id: string) => {
    const result = await graphqlService.execute(queryJiraProjectIdNames(id))
    return mapQueryJiraProjectIdNames(result)
  }
)

export const ACTION_JIRA_REPORTERS_NAME_EMAIL_FETCH = 'jira/reporter/name-email'
export const fetchReportersNameEmail = createAsyncThunk(
  ACTION_JIRA_REPORTERS_NAME_EMAIL_FETCH,
  async () => {
    const result = await graphqlService.execute(queryReportersNameEmail())
    return mapQueryReportersNameEmail(result)
  }
)

export const ACTION_JIRA_ASSIGNEE_NAME_EMAIL_FETCH = 'jira/assignee/name-email'
export const fetchAssigneesNameEmail = createAsyncThunk(
  ACTION_JIRA_ASSIGNEE_NAME_EMAIL_FETCH,
  async () => {
    const result = await graphqlService.execute(queryAssigneesNameEmail())
    return mapQueryAssigneesNameEmail(result)
  }
)

export const fetchClassFilters = createAsyncThunk('classFilters/get', async () => {
  const results = await Promise.all([service.getClassificationsList()]).catch((error) => {
    throw error
  })

  return {
    classes: results[0]
  }
})
export const fetchDriveFilters = createAsyncThunk(
  'driveFilters/get',
  async (datasourceId?: string) => {
    const resultsRaw = await graphqlService.execute(queryDrivesNames(datasourceId))

    return mapQueryDrivesNames(resultsRaw)
  }
)
export const fetchTemplateFilters = createAsyncThunk('templateFilters/get', async () => {
  const resultsRaw = await graphqlService.execute(queryTemplates())
  return mapQueryTemplates(resultsRaw)
})
export const ACTION_DATASOURCENAMES_FETCH = 'attributes/datasourceNames'
export const fetchDatasourcesIDNames = createAsyncThunk(
  ACTION_DATASOURCENAMES_FETCH,
  async (params: FilterParams) => {
    const resultRaw = await graphqlService.execute(queryDataSourceNames(params))
    return mapQueryDataSourceNames(resultRaw)
  }
)

export const ACTION_POLICIES__NAMES_FETCH = 'policies/names'
export const fetchPoliciesIDNamesByType = createAsyncThunk(
  ACTION_POLICIES__NAMES_FETCH,
  async (params: IGetPoliciesByTypeParams) => {
    const resultRaw = await graphqlService.execute(queryPoliciesIDNamesByType(params))
    return mapQueryPoliciesIDNamesByType(resultRaw)
  }
)

export const ACTION_BLOBS_PARENTS_FETCH = 'blobs/parents'
export const fetchBLOBSParentsSummary = createAsyncThunk(
  ACTION_BLOBS_PARENTS_FETCH,
  async (params: FilterParams) => {
    const resultRaw = await graphqlService.execute(queryBLOBSParentsSummary(params))
    return mapQueryBLOBSParentsSummary(resultRaw)
  }
)

export const ACTION_DATATABASE_FILTERS_FETCH = 'databases/filters'
export const fetchDatabaseFilters = createAsyncThunk(
  ACTION_DATATABASE_FILTERS_FETCH,
  async (datasourceId: string) => {
    const resultRaw = await graphqlService.execute(queryDatabasesSummary(datasourceId))
    return mapQueryDatabasesSummary(resultRaw)
  }
)

export const ACTION_TABLE_FILTERS_FETCH = 'tables/filters'
export const fetchTablesFilters = createAsyncThunk(
  ACTION_TABLE_FILTERS_FETCH,
  async (datasourceId: string) => {
    const resultRaw = await graphqlService.execute(queryTablesSummary(datasourceId))
    return mapQueryTablesSummary(resultRaw)
  }
)

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setSubClasses: (state, { payload }) => {
      state.subClasses = payload
    },
    resetProjectsIDNames: (state) => {
      state.projects = initialState.projects
    },
    resetBLOBSParentsSummary: (state) => {
      state.blobParents = initialState.blobParents
    },
    resetDatasourcesIDNames: (state) => {
      state.datasources = initialState.datasources
    },
    resetProcessOwners: (state) => {
      state.processOwners = initialState.processOwners
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFilters.fulfilled, (state, { payload }) => {
      state.classes = payload.classes
      state.dataSourcesTypes = Object.values(DATA_SOURCE_TYPE_API_MAP)
      state.isFiltersReceived = true
    })
    builder.addCase(fetchClassFilters.fulfilled, (state, { payload }) => {
      state.classes = payload.classes
    })
    builder.addCase(fetchDriveFilters.fulfilled, (state, { payload }) => {
      state.drives = payload.list
    })
    builder.addCase(fetchTemplateFilters.fulfilled, (state, { payload }) => {
      state.templates = payload.list
    })
    builder.addCase(fetchDatasourcesIDNames.pending, (state) => {
      state.datasourcesLoaded = false
    })
    builder.addCase(fetchDatabaseFilters.fulfilled, (state, { payload }) => {
      state.databases = payload
    })
    builder.addCase(fetchTablesFilters.fulfilled, (state, { payload }) => {
      state.tables = payload
    })
    builder.addCase(fetchDatasourcesIDNames.rejected, (state) => {
      state.datasourcesLoaded = false
    })
    builder.addCase(fetchDatasourcesIDNames.fulfilled, (state, { payload }) => {
      state.datasources = payload.list
      state.datasourcesLoaded = true
    })
    builder.addCase(fetchPoliciesIDNamesByType.fulfilled, (state, { payload }) => {
      state.policies = payload.list
    })
    builder.addCase(fetchProjectsIDNames.fulfilled, (state, { payload }) => {
      state.projects = payload.list
    })
    builder.addCase(fetchJiraProjectsIDNames.fulfilled, (state, { payload }) => {
      state.projects = payload.list
    })
    builder.addCase(fetchReportersNameEmail.fulfilled, (state, { payload }) => {
      state.reporters = payload.list
    })
    builder.addCase(fetchAssigneesNameEmail.fulfilled, (state, { payload }) => {
      state.assignees = payload.list
    })
    builder.addCase(fetchAwsBucketOwners.fulfilled, (state, { payload }) => {
      state.bucketOwners = payload.list
    })
    builder.addCase(fetchRopaProcessOwners.fulfilled, (state, { payload }) => {
      state.processOwners = payload
    })
    builder.addCase(fetchAwsBucketFilters.fulfilled, (state, { payload }) => {
      state.awsBuckets = payload
    })
    builder.addCase(fetchBLOBSParentsSummary.fulfilled, (state, { payload }) => {
      state.blobParents = payload
    })
  }
})
export const fileTypesFilterMap = (filterVal) => {
  return filterVal.map((fileType) => fileType.name)
}
export const {
  setSubClasses,
  resetProjectsIDNames,
  resetBLOBSParentsSummary,
  resetDatasourcesIDNames,
  resetProcessOwners
} = filtersSlice.actions

export default filtersSlice.reducer
