import './modals.less'
import ModalAnnotationFeedback from '../annotator/components/annotationFeedback/annotationFeedback'
import React from 'react'

const Modals = (): React.ReactElement => {
  return (
    <>
      <ModalAnnotationFeedback />
      {/** put here another modals */}
    </>
  )
}

export default Modals
