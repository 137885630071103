import './databases.less'
import { DS_WORKGROUP } from '../dataSourcesMixed/slice'
import { workgroupValidationSchema } from '../../utils/validators/dataSourceStructuredValidator'
import { RootState } from '../../rootReducer'
import { useFormik } from 'formik'
import React from 'react'
import { Form } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Typography } from '@lightbeamai/design-system'

interface IProps {
  disabled: boolean
  isEditMode: boolean
  workgroup?: string
  workgroups?: string[]
  setWorkGroup: (workgroup: string) => void
}

const WorkgroupInput = ({ disabled, setWorkGroup, workgroup, isEditMode, workgroups }: IProps) => {
  const intl = useIntl()
  const initialValues = {
    [DS_WORKGROUP]: workgroup || ''
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: workgroupValidationSchema,
    onSubmit: async () => ''
  })

  const workgroupOptions =
    workgroups?.map((val, i) => ({
      key: i,
      value: val,
      text: val
    })) || []

  return (
    <>
      <Typography
        as="span"
        variant="baseMinus2"
        className={`label show-required ${isEditMode ? ' xs-mt-0' : ' xs-mt-36'}`}
      >
        <FormattedMessage id="dataSources.structured.settings.workgroup.title" />
      </Typography>
      <div className={`form-item ${isEditMode ? 'xs-mb-8' : ''} xs-mt-8  workgroup-input`}>
        <Form.Dropdown
          disabled={disabled}
          fluid
          selection
          options={workgroupOptions}
          icon="chevron down"
          placeholder={intl.formatMessage({ id: 'dropdown.placeholder.select' })}
          {...formik.getFieldProps(DS_WORKGROUP)}
          onChange={(_, data) => {
            setWorkGroup(data.value as string)
            formik.setFieldValue(DS_WORKGROUP, data.value)
          }}
          data-test-id="ds-register-workgroup"
          error={
            formik.errors[DS_WORKGROUP] && {
              content: intl.formatMessage({
                id: formik.errors[DS_WORKGROUP]
              }),
              pointing: 'above',
              'data-test-id': 'ds-register-workgroup-error'
            }
          }
        />
      </div>
    </>
  )
}
const mapStateToProps = (state: RootState) => ({
  workgroups: state.databases.workgroups
})

export default connect(mapStateToProps)(WorkgroupInput)
