import PrintPageHeader from '../components/header'
import RuleSetListPrint from '../../features/policies/RuleSetListPrint'
import React from 'react'

const PrintPoliciesPage = (): React.ReactElement => {
  return (
    <div
      id="policy"
      className="print-page data-sources page-policies"
      data-test-id="print-policies-page"
    >
      <PrintPageHeader text={<strong>Policies Violated</strong>} />
      <RuleSetListPrint />
    </div>
  )
}

export default PrintPoliciesPage
