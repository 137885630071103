import { STATES } from '../../interfaces'
import apiService from '../../services/api/apiService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface SmtpConfiguration {
  endpoint: string
  username: string
  sender: string
  password: string
  security_mode: string
  port: string
  tenant_id: string
  client_id: string
  client_secret: string
  auth_type?: string
  oauth_provider?: string // todo: add separate interfaces for oauth providers
}

export const ACTION_SMTP_CONFIG_TEST = 'smtp/testConnection'
export const testSmtpConfiguration = createAsyncThunk(
  ACTION_SMTP_CONFIG_TEST,
  async (params: SmtpConfiguration) => await apiService.testSmtpConfiguration(params)
)

export const ACTION_SMTP_CONFIG_SAVE = 'smtp/save'
export const saveSmtpConfiguration = createAsyncThunk(
  ACTION_SMTP_CONFIG_SAVE,
  async (params: SmtpConfiguration) => await apiService.saveSmtpConfiguration(params)
)

export const ACTION_SMTP_CONFIG_FETCH = 'smtp/get'
export const getSmtpConfiguration = createAsyncThunk(
  ACTION_SMTP_CONFIG_FETCH,
  async () => await apiService.getSmtpConfiguration()
)

interface SmtpState {
  testConnectionStatus: STATES | ''
  isSmtpConfigSaved?: boolean
  config?: SmtpConfiguration
}

export const initialState: SmtpState = {
  testConnectionStatus: ''
}

const smtpSlice = createSlice({
  name: 'smtpConfig',
  initialState,
  reducers: {
    resetTestSmtpConfiguration: (state) => {
      state.testConnectionStatus = initialState.testConnectionStatus
    },
    resetConfig: (state) => {
      state.config = initialState.config
    },
    resetIsSmtpConfigSaved: (state) => {
      state.isSmtpConfigSaved = initialState.isSmtpConfigSaved
    }
  },
  extraReducers: (builder) => {
    builder.addCase(testSmtpConfiguration.fulfilled, (state) => {
      state.testConnectionStatus = STATES.success
    })
    builder.addCase(testSmtpConfiguration.pending, (state) => {
      state.testConnectionStatus = STATES.pending
    })
    builder.addCase(testSmtpConfiguration.rejected, (state) => {
      state.testConnectionStatus = STATES.failed
    })
    builder.addCase(saveSmtpConfiguration.rejected, (state) => {
      state.isSmtpConfigSaved = false
    })
    builder.addCase(saveSmtpConfiguration.fulfilled, (state) => {
      state.isSmtpConfigSaved = true
    })
    builder.addCase(getSmtpConfiguration.fulfilled, (state, { payload }) => {
      state.config = payload
    })
  }
})

export const { resetTestSmtpConfiguration, resetConfig, resetIsSmtpConfigSaved } = smtpSlice.actions
export default smtpSlice.reducer
