export const alertsList = [
  {
    type: 'error',
    text: 'Suspicious user activity for PII data.',
    dateTime: 'May 18, 2020 @ 18:30'
  },
  {
    type: 'error',
    text: 'GDPR potential violation Data for EU resident replicated to Asia.',
    dateTime: 'On Aug 02, 2020 @ 11:00 GMT'
  },
  {
    type: 'warning',
    text: 'AWS IAM Privilege escalation path discovered.',
    dateTime: 'May 18, 2020 @ 18:30'
  },
  {
    type: 'error',
    text: 'GDPR potential violation Data for EU resident replicated to Asia.',
    dateTime: 'On Aug 06, 2020 @ 13:00 GMT'
  },
  {
    type: 'warning',
    text: 'AWS IAM Privilege escalation path discovered.',
    dateTime: 'May 18, 2020 @ 18:30'
  },
  {
    type: 'error',
    text: 'GDPR potential violation Data for EU resident replicated to Asia.',
    dateTime: 'May 18, 2020 @ 18:30'
  },
  {
    type: 'warning',
    text: 'AWS IAM Privilege escalation path discovered.',
    dateTime: 'May 18, 2020 @ 18:30'
  }
]

export const alertsChart = [
  { timestamp: 1610928000.0, value: 3.0, units: null },
  { timestamp: 1611014400.0, value: 2.0, units: null },
  { timestamp: 1611100800.0, value: 1.0, units: null },
  { timestamp: 1611187200.0, value: 9.0, units: null },
  { timestamp: 1611273600.0, value: 9.0, units: null },
  { timestamp: 1611360000.0, value: 9.0, units: null },
  { timestamp: 1611446400.0, value: 9.0, units: null }
]
