import './print.less'
import PrintContentsPage, { TableContentsItem } from './components/pageContents'
import PrintOverviewPage from './pages/pageOverview'
import PrintAttributeListPage from './pages/pageAttributeList'
import PrintClassifications from './pages/pageClassifications'
import PrintPoliciesPage from './pages/pagePolicies'
import PrintMonitoredDataSources from './pages/pageMonitoredDataSources'
import React from 'react'
import { useIntl } from 'react-intl'

const Print = (): React.ReactElement => {
  const intl = useIntl()

  const tableContents: TableContentsItem[] = [
    { urlAnchor: '#overview', title: intl.formatMessage({ id: 'print.contents.overview' }) },
    { urlAnchor: '#data-sources', title: intl.formatMessage({ id: 'print.contents.datasources' }) },
    { urlAnchor: '#pii', title: intl.formatMessage({ id: 'print.contents.piiData' }) },
    {
      urlAnchor: '#classifications',
      title: intl.formatMessage({ id: 'print.contents.classifications' })
    },
    { urlAnchor: '#policy', title: intl.formatMessage({ id: 'print.contents.policies' }) }
  ]

  return (
    <div className="report" data-test-id="print">
      <div className="page-break" />

      <PrintContentsPage tableContents={tableContents} />
      <div className="page-break" />

      <PrintOverviewPage />
      <div className="page-break" />

      <PrintMonitoredDataSources />
      <div className="page-break" />

      <PrintAttributeListPage />
      <div className="page-break" />

      <PrintClassifications />
      <div className="page-break" />

      <PrintPoliciesPage />
    </div>
  )
}

export default Print
