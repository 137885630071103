import {
  ChannelSummary,
  ConversationTeam,
  ConversationTeamsParams,
  IGetConversationMessagesParams,
  MessageCardInfo
} from './messagesSlice'
import { parameterizeArrayofObjects } from '../../../../../utils/graphqlUtil'
import { DATA_SOURCE_TYPES } from '../../../../../constants'
import { gql } from 'graphql-request'

export const queryConversationMessagesCardsData = (
  params: IGetConversationMessagesParams
): string => {
  const { datasourceId, datasourceType, filter, booleanFilter, entityIds } = params
  let paramString = ''
  const filterString = filter ? parameterizeArrayofObjects(filter) : ''
  const booleanFilterString = booleanFilter ? parameterizeArrayofObjects(booleanFilter) : ''
  if (filterString) {
    paramString += `, filter: ${filterString}`
  }
  if (booleanFilterString) {
    paramString += `, booleanFilter: ${booleanFilterString}`
  }

  const msTeamsFragment =
    datasourceType === DATA_SOURCE_TYPES.teams
      ? `msTeams(first: 1){
          edges{
            node{
              displayName
              visibility
            }
          }
        }`
      : ''

  return gql`
  {
    channel(datasourceIds: "${[datasourceId]}"${
    entityIds ? `, entityIds: "${entityIds}"` : ''
  }${paramString}) {
    count
      edges {
        node {
          channelId
          channelName
          membersCount
          guestsCount
          type
          piiConversationsCount
          ${msTeamsFragment}
          objects(category: FILE, isSensitive: true){
            count
          }
          attributes{
            count
          }
          userEntities{
            count
          }
        }
      }
    }
  }
`
}
export const mapQueryConversationMessagesCardsData = (
  raw: any
): { list: MessageCardInfo[]; total: number } => {
  const list =
    raw.channel.edges?.map(({ node }) => {
      const teams = node.msTeams?.edges[0]?.node

      return {
        channelId: node.channelId,
        channelName: node.channelName,
        channelType: teams?.visibility || node.type,
        groupName: node.msTeams?.edges[0]?.node?.displayName,
        guestsCount: node.guestsCount || 0,
        membersCount: node.membersCount || 0,
        piiConversationsCount: node.piiConversationsCount || 0,
        objectsCount: node.objects?.count || 0,
        attributesCount: node.attributes?.count || 0,
        entitiesCount: node.userEntities?.count || 0,
        test: node.userEntities?.count || 0
      }
    }) || []

  return { list, total: raw.channel.count }
}

export const queryChannelNames = (): string => {
  return gql`
    {
      channel {
        edges {
          node {
            channelId
            channelName
          }
        }
      }
    }
  `
}
export const mapQueryChannelNames = (raw: any): { list: ChannelSummary[] } => {
  const list =
    raw.channel.edges?.map(({ node }) => ({
      channelId: node.channelId,
      channelName: node.channelName
    })) || []

  return { list }
}

export const queryConversationTeams = (params: ConversationTeamsParams) => {
  return gql`
    {
      msTeams(filter: { key: DATASOURCE_IDS, values: "${params.datasourceId}" }) {
        edges {
          node {
            teamId
            displayName
          }
        }
      }
    }
  `
}
export const mapQueryConversationTeams = (raw: any): ConversationTeam[] => {
  return (
    raw.msTeams?.edges?.map(({ node }) => ({
      teamId: node.teamId,
      displayName: node.displayName
    })) || []
  )
}
