import PrintPageHeader from '../../components/header'
import { fetchDeltaTablesPerDatasource, stats } from '../../printSlice'
import TrendPage from '../components/trendPageTemplate'
import { RootState } from '../../../rootReducer'
import { filterCurrentDayData } from '../../../utils/timeUtil'
import {
  getDeltaReportAggregation,
  getDeltaReportAggregationTableData,
  percentageDeltaCalculator
} from '../deltaReportUtil'
import dayjs from 'dayjs'
import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
interface IProps {
  fetchDeltaTablesPerDatasource: (datasourceId: string) => void
  tablesperDs?: {
    [datasourceId: string]: stats[]
  }
  subText?: string
  datasourceId?: string
  datasourceName?: string
}
const SensitiveTables = ({
  fetchDeltaTablesPerDatasource,
  tablesperDs = {},
  subText,
  datasourceId = '',
  datasourceName
}: IProps): React.ReactElement => {
  useEffect(() => {
    datasourceId && fetchDeltaTablesPerDatasource(datasourceId)
  }, [datasourceId, fetchDeltaTablesPerDatasource])

  const historicalTableStats = useMemo(() => {
    const dataSourceIds = Object.keys(tablesperDs)
    const startDataSourceId = dataSourceIds[0]
    let aggrData: stats[] = dataSourceIds.length ? tablesperDs[startDataSourceId] || [] : []
    dataSourceIds.forEach((dataSourceId) => {
      if (startDataSourceId !== dataSourceId) {
        const dataSourceData: stats[] = tablesperDs[dataSourceId]
        aggrData = aggrData.map((timeStampData) => {
          const data = dataSourceData.find(
            ({ endTimestamp, startTimestamp }) =>
              endTimestamp === timeStampData.endTimestamp &&
              startTimestamp === timeStampData.startTimestamp
          )
          const totalTimestampData = {
            endTimestamp: timeStampData.endTimestamp,
            startTimestamp: timeStampData.startTimestamp,
            total: (data?.total || 0) + (timeStampData.total || 0),
            added: (data?.added || 0) + (timeStampData.added || 0),
            deleted: (data?.deleted || 0) + (timeStampData.deleted || 0),
            startAdded: (data?.startAdded || 0) + (timeStampData.startAdded || 0),
            startDeleted: (data?.startDeleted || 0) + (timeStampData.startDeleted || 0),
            startTotal: (data?.startTotal || 0) + (timeStampData.startTotal || 0)
          }
          return totalTimestampData
        })
      }
    })

    return (datasourceId ? tablesperDs?.[datasourceId] : aggrData) || []
  }, [tablesperDs, datasourceId])

  const agreegatedTableStats = getDeltaReportAggregation(historicalTableStats)
  const aggreegatedPercentageChange = percentageDeltaCalculator(
    agreegatedTableStats.startTotal,
    agreegatedTableStats.total
  )
  const tableStats = filterCurrentDayData(historicalTableStats)
  const chartData = tableStats.map((stats) => {
    return {
      name:
        dayjs(stats.startTimestamp).format('D MMM') +
        ' - ' +
        dayjs(stats.endTimestamp).format('D MMM'),
      value: stats.total || 0
    }
  })
  const barData = tableStats.map((stats) => {
    return {
      name:
        dayjs(stats.startTimestamp).format('D MMM') +
        ' - ' +
        dayjs(stats.endTimestamp).format('D MMM'),
      added: stats.added,
      deleted: stats.deleted
    }
  })
  const tableData = getDeltaReportAggregationTableData(tableStats)

  return (
    <div className="sensitive-files delta-print-page" id={'sensitive-tables' + datasourceId}>
      <PrintPageHeader
        text={<strong>Tables With Sensitive Data</strong>}
        {...(subText
          ? { subText }
          : datasourceId
          ? {
              subText: (
                <>
                  Datasource: <strong>{datasourceName}</strong>
                </>
              )
            }
          : {})}
        {...(subText || datasourceId ? { className: 'align-horizontal' } : {})}
      />
      <TrendPage
        chartData={chartData || []}
        tableData={tableData}
        barData={barData || []}
        trendWidgetData={[
          {
            count: agreegatedTableStats.total,
            text: 'Current tables',
            percentage: aggreegatedPercentageChange
          },
          { count: agreegatedTableStats.added, text: 'Tables Added', bulletColor: '#13C2C2' },
          { count: agreegatedTableStats.deleted, text: 'Tables Deleted', bulletColor: '#CF1322' }
        ]}
        tableHeaders={['Date Range', 'Start of range', 'Added', 'Deleted', 'End of range']}
        legendText={'Tables with sensitive data'}
        areaTitleText="Trend for total tables with sensitive data"
        barTitleText="Delta changes for PII tables"
        barLegendPrefix="Tables"
      />
    </div>
  )
}
const mapStateToProps = (state: RootState) => ({
  tablesperDs: state.print.deltaTablesPerDatasource
})

const mapDispatchToProps = {
  fetchDeltaTablesPerDatasource
}

export default connect(mapStateToProps, mapDispatchToProps)(SensitiveTables)
