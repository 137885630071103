import getErrorText from './apiStatuses'
import { useRequestInterceptors, useResponseInterceptors } from './apiInterceptors'
import { accessTokenKey } from '../../constants'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
interface IRequest extends AxiosRequestConfig {
  url: string
  headers?: { [key: string]: string }
}

export const axiosInstance = axios.create()

function getAxiosParams(options: IRequest) {
  const defaultParams = {
    validateStatus: () => true,
    headers: { [accessTokenKey]: window.localStorage.getItem(accessTokenKey) || '' }
  }

  const params: AxiosRequestConfig = {
    ...defaultParams,
    ...options,
    headers: { ...defaultParams.headers, ...options.headers }
  }

  return params
}

const request = async (options: IRequest): Promise<AxiosResponse> => {
  const params = getAxiosParams(options)

  try {
    axiosInstance.interceptors.request.use(useRequestInterceptors)
    axiosInstance.interceptors.response.use(useResponseInterceptors)

    return await axiosInstance(params)
  } catch (error: any) {
    /**Todo Get API to finalize Error schema : LA-3250 */
    throw (error?.status === 400 || error?.status === 409) && error?.data?.message
      ? error.data.message
      : getErrorText(error?.status)
  }
}

export async function requestWithErrorData(options: IRequest) {
  const params = getAxiosParams(options)

  axiosInstance.interceptors.request.use(useRequestInterceptors)
  axiosInstance.interceptors.response.use(useResponseInterceptors)

  return await axiosInstance(params).catch((error) => {
    throw error.data
  })
}

export default request
