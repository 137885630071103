import { codeToStatusMap, API_ERROR_STATUS } from './apiStatuses'
import { logout } from '../../features/login/loginSlice'
import { store } from '../../index'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

const handleUnauthorized = (response: AxiosResponse): AxiosResponse => {
  if (response && codeToStatusMap[response.status] === API_ERROR_STATUS.UNAUTHORIZED) {
    store.dispatch(logout())
  }
  return response
}

const validateResponse = (response: AxiosResponse): AxiosResponse => {
  if (!response.status || response.status < 200 || response.status >= 400) {
    throw response
  }

  return response
}

const responseInterceptorsArray = [handleUnauthorized, validateResponse]

export const useResponseInterceptors = (response: AxiosResponse): Promise<AxiosResponse> => {
  for (const interceptor of responseInterceptorsArray) {
    response = interceptor(response)
  }

  return Promise.resolve(response)
}

export const useRequestInterceptors = async (
  request: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  return request
}
