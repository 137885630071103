import { NotificationIconName } from '../../features/notifications/notificationsSlice'
import CsvFileSvg from '../../assets/inlineSvg/csvFile.svg'
import PdfFileSvg from '../../assets/inlineSvg/icon-pdf.svg'
import React, { ReactNode } from 'react'

import './NotificationIcon.less'
import { FileZip } from 'phosphor-react'

export function iconNameToIconFile(iconName?: NotificationIconName) {
  switch (iconName) {
    case 'csv':
      return <CsvFileSvg />
    case 'pdf':
      return <PdfFileSvg />
    case 'zip':
      return <FileZip size={32} />
    default:
      return null
  }
}

type NotificationIconProps = {
  children: ReactNode
}

export function NotificationIcon({ children }: NotificationIconProps) {
  return <div className="NotificationIcon">{children}</div>
}
