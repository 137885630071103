import { FetchTicketsListParams, Ticket, TicketSummary } from './ticketsSlice'
import { LIMIT_DEFAULT } from '../../constants'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { gql } from 'graphql-request'

export const queryTicketsCards = (params: FetchTicketsListParams): string => {
  const { datasourceId, projectId } = params
  const projectIdFilter = projectId ? `id: "${projectId}"` : ''
  const datasourceFilter = datasourceId ? `datasourceId: "${datasourceId}",` : ''
  const filters =
    projectIdFilter || datasourceFilter ? `(${datasourceFilter} ${projectIdFilter})` : ''

  return gql`
  {
    ticketStoreProject${filters} {
      count
      edges {
        node {
          id
          projectName
          tickets(booleanFilter: [{key: CONTAINS_PII_DATA, value: true}]) {
            count
          }
          objects {
            count
          }
          attributes {
            count
          }
          userEntities {
            count
          }
          impactedObjects: objects(booleanFilter: { key: HAS_ALERTS, value: true }) {
            count
          }
          alerts(first: 999, filter: [{ key: STATUS, values: ["Active"] }]) {
            count
          }
        }
      }
    }

  }
`
}

export const mapQueryTicketsCards = (
  raw: any
): { list: Ticket[]; totalTickets: number; totalProjects: number } => {
  try {
    const list = raw.ticketStoreProject.edges.map(
      ({
        node: {
          id,
          projectName,
          attributes,
          userEntities,
          objects,
          tickets,
          alerts,
          impactedObjects
        }
      }) => ({
        projectId: id,
        projectName,
        totalAttributes: attributes.count || 0,
        totalEntities: userEntities.count || 0,
        totalObjects: objects.count || 0,
        totalTicketsWithPII: tickets.count || 0,
        totalAlerts: alerts.count || 0,
        totalObjectsImpacted: impactedObjects.count || 0
      })
    )
    const totalTickets =
      list.reduce(
        (total: number, current: Ticket) => total + (current.totalTicketsWithPII || 0),
        0
      ) || 0
    return { list, totalProjects: raw.ticketStoreProject.count || 0, totalTickets }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryEntityTicketsCards = (params: FetchTicketsListParams): string => {
  const { datasourceId, projectId, entityId } = params
  const projectIdFilter = projectId ? `id: "${projectId}"` : ''
  const datasourceFilter = datasourceId ? `datasourceId: "${datasourceId}",` : ''
  const filters =
    projectIdFilter || datasourceFilter ? `(${datasourceFilter} ${projectIdFilter})` : ''

  return gql`
  {
    userEntities(id: "${entityId}")
    {
      edges {
        node {
          ticketStoreProjects${filters} {
            count
            edges {
              node {
                id
                projectName
                tickets(filter: [{key: USER_ENTITY_IDS, values: ["${entityId}"]}]) {
                  count
                }
                objects {
                  count
                }
                attributes {
                  count
                }
                userEntities {
                  count
                }
              }
            }
          }
        }
      }
    }
  }
`
}

export const mapQueryEntityTicketsCards = (
  raw: any
): { list: Ticket[]; totalTickets: number; totalProjects: number } => {
  try {
    const list =
      raw.userEntities?.edges[0]?.node?.ticketStoreProjects?.edges?.map(
        ({
          node: {
            id,
            projectName,
            attributes,
            userEntities,
            objects,
            tickets,
            alerts,
            impactedObjects
          }
        }) => ({
          projectId: id,
          projectName,
          totalAttributes: attributes?.count || 0,
          totalEntities: userEntities?.count || 0,
          totalObjects: objects?.count || 0,
          totalTicketsWithPII: tickets?.count || 0,
          totalAlerts: alerts?.count || 0,
          totalObjectsImpacted: impactedObjects?.count || 0
        })
      ) || []

    const totalTickets =
      list.reduce(
        (total: number, current: Ticket) => total + (current.totalTicketsWithPII || 0),
        0
      ) || 0
    return {
      list,
      totalProjects: raw.userEntities?.edges[0]?.node?.ticketStoreProjects?.count || 0,
      totalTickets
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryTicket = (id: string): string => {
  return gql`
  {
    ticket(id: "${id}") {
      edges {
        node {
          ticketName
          ticketLink
          lastModifiedTime
          summaryAndDescription {
            summary
            description
          }
          comments {
            authorName
            updateTimestamp
            body
          }
        }
      }
    }
  }
`
}

export const mapQueryTicket = (raw: any): { data: TicketSummary } => {
  try {
    const {
      ticketName,
      lastModifiedTime,
      summaryAndDescription,
      comments,
      ticketLink
    } = raw.ticket?.edges[0]?.node
    const data: TicketSummary = {
      ticketName,
      ticketLink,
      comments,
      lastModifiedTime,
      summary: summaryAndDescription?.summary || '',
      description: summaryAndDescription?.description || ''
    }
    return {
      data
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryTicketsList = (params: FetchTicketsListParams): string => {
  const { filters, datasourceId, page = 1, pageSize = LIMIT_DEFAULT } = params
  const cursor = getAfterCursor(page, pageSize)
  const ticketDatasourceFilter = datasourceId ? `datasourceId: "${datasourceId}",` : ''
  const objectDatasourceFilter = datasourceId ? `(datasourceIds: ["${datasourceId}"])` : ''
  let paramString = ''
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  if (filterString) {
    paramString += `, filter: ${filterString}`
  }
  const booleanFilterString =
    filters && filters.booleanFilter ? parameterizeArrayofObjects(filters.booleanFilter) : ''
  if (booleanFilterString) {
    paramString += `, booleanFilter: ${booleanFilterString} `
  }

  return gql`
  {
    ticket(${ticketDatasourceFilter} first: ${pageSize}, after: "${cursor}", ${paramString}) {
      count
      edges {
        node {
          id
          ticketId
          ticketName
          lastModifiedTime
          ticketLink
          reporter {
            name
          }
          assignee {
            name
          }
          attributes {
            count
          }
          ticketStoreProject {
            edges {
              node {
                id
                projectName
              }
            }
          }
          userEntities {
            count
            edges {
              node {
                name
                id
              }
            }
          }
          objects${objectDatasourceFilter} {
            edges {
              node {
                alert(first: 10) {
                  edges {
                    node {
                      name
                      severity
                      id
                    }
                  }
                }
                attribute {
                  edges {
                    node {
                      name
                      internalName
                    }
                  }
                }
              }
            }
          }
          hasAttachments
          attachments {
            id
            name
            type
          }
        }
      }
    }
  }
`
}

export const mapQueryTicketsList = (raw: any): { list: Ticket[]; totalTickets: number } => {
  try {
    const list = raw.ticket.edges.map(
      ({
        node: {
          id,
          ticketId,
          ticketName,
          ticketLink,
          reporter,
          assignee,
          ticketStoreProject,
          userEntities,
          objects,
          lastModifiedTime,
          hasAttachments,
          attachments,
          attributes
        }
      }) => ({
        id,
        ticketId,
        ticketName,
        ticketLink,
        reporter: reporter?.name || '',
        assignee: assignee?.name || '',
        projectName: ticketStoreProject?.edges[0]?.node?.projectName || '',
        projectId: ticketStoreProject?.edges[0]?.node?.id || '',
        sharedWhen: lastModifiedTime || '',
        totalEntities: userEntities?.count || 0,
        hasAttachments: !!hasAttachments,
        attachments: attachments || [],
        attributes:
          [
            ...new Map(
              (
                objects?.edges?.reduce(
                  (attributes, { node }) => [
                    ...attributes,
                    ...node?.attribute?.edges?.map(({ node }) => ({ ...node } || []))
                  ],
                  []
                ) || []
              ).map((attribute) => [attribute['name'], attribute])
            ).values()
          ] || [],
        totalAttributes: attributes?.count || 0,
        alerts: objects?.edges[0]?.node?.alert?.edges?.map(({ node }) => node) || [],
        hasAlerts: !!objects?.edges[0]?.node?.alert?.count,
        entities: userEntities?.edges?.map(({ node }) => node) || []
      })
    )
    const totalTickets = raw.ticket.count
    return { list, totalTickets }
  } catch (error) {
    console.error(error)
    throw error
  }
}
