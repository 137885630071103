import './elasticSearchSettings.less'
import Step1 from './Steps/step1'
import Step2 from './Steps/step2'
import {
  API_KEY_METHOD,
  BASIC_AUTH_METHOD,
  ElasticSearchConfiguration,
  ElasticSearchParams,
  ElasticSearchRegisterValues,
  ELASTIC_SEARCH_API_KEY_ID,
  ELASTIC_SEARCH_API_KEY_SECRET,
  ELASTIC_SEARCH_AUTH_METHOD,
  ELASTIC_SEARCH_HOSTNAME,
  ELASTIC_SEARCH_INSTANCE_NAME,
  ELASTIC_SEARCH_PASSWORD,
  ELASTIC_SEARCH_USER_NAME
} from './elasticSearchSettingsSlice'
import { RootState } from '../../../../../rootReducer'
import { setDataSourceId } from '../../../dataSourcesSlice'
import DataSourceSettings, {
  INIT_STEP,
  Step
} from '../../../../../components/DataSourceSettings/dataSourceSettings'
import {
  DATA_SOURCE_TYPE,
  DATA_SOURCE_TYPES,
  URL_DATASOURCE_FORM_UNSTRUCTURED,
  DATA_SOURCE_TYPE_API_MAP
} from '../../../../../constants'
import { changeUrl } from '../../../../../utils/urlUtil'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

const initElasticSearchParams: ElasticSearchRegisterValues = {
  [ELASTIC_SEARCH_INSTANCE_NAME]: '',
  [ELASTIC_SEARCH_HOSTNAME]: '',
  [ELASTIC_SEARCH_AUTH_METHOD]: '',
  [ELASTIC_SEARCH_USER_NAME]: '',
  [ELASTIC_SEARCH_PASSWORD]: '',
  [ELASTIC_SEARCH_API_KEY_ID]: '',
  [ELASTIC_SEARCH_API_KEY_SECRET]: ''
}

const mapValuesToApiParams = (values: ElasticSearchRegisterValues): ElasticSearchParams => {
  let elasticsearchConfiguration: ElasticSearchConfiguration = {
    [ELASTIC_SEARCH_HOSTNAME]: values[ELASTIC_SEARCH_HOSTNAME]
  }
  if (values[ELASTIC_SEARCH_AUTH_METHOD] == BASIC_AUTH_METHOD) {
    elasticsearchConfiguration = {
      ...elasticsearchConfiguration,
      [ELASTIC_SEARCH_USER_NAME]: values[ELASTIC_SEARCH_USER_NAME],
      [ELASTIC_SEARCH_PASSWORD]: values[ELASTIC_SEARCH_PASSWORD]
    }
  }
  if (values[ELASTIC_SEARCH_AUTH_METHOD] == API_KEY_METHOD) {
    elasticsearchConfiguration = {
      ...elasticsearchConfiguration,
      [ELASTIC_SEARCH_API_KEY_ID]: values[ELASTIC_SEARCH_API_KEY_ID],
      [ELASTIC_SEARCH_API_KEY_SECRET]: values[ELASTIC_SEARCH_API_KEY_SECRET]
    }
  }
  return {
    configuration: {
      [DATA_SOURCE_TYPE]: DATA_SOURCE_TYPE_API_MAP[DATA_SOURCE_TYPES.elasticSearch],
      [ELASTIC_SEARCH_INSTANCE_NAME]: values[ELASTIC_SEARCH_INSTANCE_NAME],
      elasticsearchConfiguration
    }
  }
}

interface IProps {
  instanceName?: string
  hostName?: string
  dataSourceId?: string
  onCancelClick?: () => void
  setDataSourceId: (id: string) => void
}

const ElasticSearchForm = (props: IProps): React.ReactElement => {
  const {
    instanceName = '',
    hostName = '',
    dataSourceId = '',
    setDataSourceId,
    onCancelClick
  } = props
  const [currentStep, setCurrentStep] = useState(INIT_STEP)
  const [values, setValues] = useState({
    ...initElasticSearchParams,
    [ELASTIC_SEARCH_INSTANCE_NAME]: instanceName,
    [ELASTIC_SEARCH_HOSTNAME]: hostName
  })
  const [isNextEnabled, setIsNextEnabled] = useState(false)

  const handleFormChange = (incomingValues: ElasticSearchRegisterValues) => {
    const newValues = { ...values, ...incomingValues }
    setValues(newValues)
  }
  const handleNextStatus = (isValid: boolean) => {
    setIsNextEnabled(isValid)
  }

  const handleGoToDashboard = () => {
    if (dataSourceId) {
      changeUrl(URL_DATASOURCE_FORM_UNSTRUCTURED + '/' + dataSourceId, {
        [DATA_SOURCE_TYPE]: DATA_SOURCE_TYPES.elasticSearch
      })
    }
  }

  useEffect(() => {
    setDataSourceId('')
  }, [setDataSourceId])

  const steps: Step[] = [
    {
      text: 'Config',
      component: (
        <Step1
          values={values}
          handleFormChange={handleFormChange}
          mapValuesToApiParams={mapValuesToApiParams}
          handleNextStatus={handleNextStatus}
        />
      ),
      hideStep: false,
      isBtnBackDisabled: true,
      btnBackText: '',
      btnNextText: 'Next',
      isBtnNextDisabled: !isNextEnabled,
      btnCancelText: 'Close',
      isBtnCancelDisabled: onCancelClick ? false : true,
      onBtnCancelClick: onCancelClick
    },
    {
      text: 'Advanced Config',
      component: <Step2 />,
      hideStep: false,
      isBtnBackDisabled: false,
      btnBackText: 'Back',
      btnNextText: '',
      btnCancelText: '',
      onBtnNextClick: handleGoToDashboard,
      isBtnCancelDisabled: onCancelClick ? false : true,
      onBtnCancelClick: onCancelClick
    }
  ]

  return (
    <DataSourceSettings
      steps={steps}
      className="elastic-search-settings"
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
    />
  )
}

const mapStateToProps = (state: RootState) => ({
  dataSourceId: state.dataSources.dataSourceId
})

const mapDispatchToProps = {
  setDataSourceId
}

export default connect(mapStateToProps, mapDispatchToProps)(ElasticSearchForm)
