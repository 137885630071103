import {
  queryPoliciesCountForDatasource,
  mapQueryPoliciesCountForDatasource,
  IGetDatasourcePoliciesCountParams,
  queryPiiEmailsCount,
  mapQueryPiiEmailsCount,
  mapQueryFileSharesCount,
  queryFileSharesCount
} from './queries'
import graphqlService from '../../services/graphqlService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface SummaryCountsState {
  policiesCount?: number
  piiEmailsCount?: number
  fileSharesCount?: number
  inboxEmailCount?: number
  sentEmailsCount?: number
}

export const initialState: SummaryCountsState = {}

export const ACTION_SUMMARY_COUNT_PII_MAILS = 'mails/pii/count'
export const fetchPiiEmailsCount = createAsyncThunk(
  ACTION_SUMMARY_COUNT_PII_MAILS,
  async (params: IGetDatasourcePoliciesCountParams) => {
    const resultRaw = await graphqlService.execute(queryPiiEmailsCount(params))
    return mapQueryPiiEmailsCount(resultRaw)
  }
)

export const ACTION_SUMMARY_COUNT_POLICIES = 'datasource/policies/count'
export const fetchDatasourcePoliciesCount = createAsyncThunk(
  ACTION_SUMMARY_COUNT_POLICIES,
  async (params: IGetDatasourcePoliciesCountParams) => {
    const resultRaw = await graphqlService.execute(queryPoliciesCountForDatasource(params))
    return mapQueryPoliciesCountForDatasource(resultRaw)
  }
)

export interface FileSharesCountParams {
  datasourceId: string
}
export const fetchFileSharesCount = createAsyncThunk(
  'datasource/smb/summary/counts',
  async (params: FileSharesCountParams) => {
    const resultRaw = await graphqlService.execute(queryFileSharesCount(params))
    return mapQueryFileSharesCount(resultRaw)
  }
)

const dashboardSummarySlice = createSlice({
  name: 'dashboardSummary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDatasourcePoliciesCount.fulfilled, (state, action) => {
      state.policiesCount = action.payload.policiesCount || 0
    })
    builder.addCase(fetchPiiEmailsCount.fulfilled, (state, action) => {
      state.piiEmailsCount = action.payload.emailsCount
      state.sentEmailsCount = action.payload.sentCount
      state.inboxEmailCount = action.payload.inboxCount
    })
    builder.addCase(fetchFileSharesCount.fulfilled, (state, action) => {
      state.fileSharesCount = action.payload
    })
  }
})

export default dashboardSummarySlice.reducer
