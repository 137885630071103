import {
  mapQueryChannelNames,
  mapQueryConversationMessagesCardsData,
  mapQueryConversationTeams,
  queryChannelNames,
  queryConversationMessagesCardsData,
  queryConversationTeams
} from './queries'
import { SlackChannelsWidgets, SlackWidgetQueryParams } from '../../../../../services/api/apiTypes'
import { defaultSortParams, getSortDirection, SortParams } from '../../../../../utils/sortUtil'
import { IGetMessagesParams } from '../../../../../interfaces'
import service, { ImMessageItem } from '../../../../../services/api/apiService'
import graphqlService from '../../../../../services/graphqlService'
import { CHANNEL_TYPES, DATA_SOURCE_ID, DATA_SOURCE_TYPES } from '../../../../../constants'
import { RootState } from '../../../../../rootReducer'
import { getGlobalParams } from '../../../../../utils/urlUtil'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface MessageCardInfo {
  channelName: string
  channelId: string
  channelType: CHANNEL_TYPES
  membersCount: number
  guestsCount: number
  objectsCount: number
  piiConversationsCount: number
  attributesCount: number
  entitiesCount: number
  groupName?: string
}

export interface ChannelSummary {
  channelId: string
  channelName: string
}
export interface ConversationTeam {
  teamId: string
  displayName: string
}

export interface SlackMessagesState {
  widget: SlackChannelsWidgets
  list: ImMessageItem[]
  cards: MessageCardInfo[]
  channelNamesWithIDs: ChannelSummary[]
  teams?: ConversationTeam[]
  directMessagesTotal: number
  conversationMessagesTotal: number
  sort: SortParams
}

export const initialState: SlackMessagesState = {
  widget: {
    piiDataChannelsCount: 0,
    riskyChannelsCount: 0
  },
  list: [],
  cards: [],
  channelNamesWithIDs: [],
  directMessagesTotal: 0,
  conversationMessagesTotal: 0,
  sort: defaultSortParams
}

export const fetchSlackChannelWidgetData = createAsyncThunk(
  'messages/widget',
  async (filters: SlackWidgetQueryParams) => await service.getSlackChannelsWidgetData(filters)
)

export const ACTION_MESSAGES_LIST_DATA = 'messages/list'
export const fetchConversationMessages = createAsyncThunk(
  ACTION_MESSAGES_LIST_DATA,
  async (filters: IGetMessagesParams, { getState }) => {
    const params = { ...filters, ...getGlobalParams(getState() as RootState) }
    const resultRaw = await service.getImMessage(params)
    const listSpecified = resultRaw.list.map((message: ImMessageItem) => ({
      ...message,
      conversationName: `${
        message.messageId.substring(0, message.messageId.lastIndexOf('.'))
          ? message.messageId.substring(0, message.messageId.lastIndexOf('.')) + ' - '
          : ''
      }${message.channelId}`
    }))

    return {
      list: listSpecified,
      total: resultRaw.total
    }
  }
)

export interface IGetConversationMessagesParams {
  sensitivity?: string
  datasourceId: string
  datasourceType: DATA_SOURCE_TYPES
  entityIds?: string
  filter?: {
    key: string
    values: string[]
  }[]
  booleanFilter?: {
    key: string
    value: boolean
  }[]
}
export const ACTION_MESSAGES_CARD_DATA = 'messages/cards'
export const fetchConversationMessagesCardsData = createAsyncThunk(
  ACTION_MESSAGES_CARD_DATA,
  async (params: IGetConversationMessagesParams) => {
    const resultRaw = await graphqlService.execute(queryConversationMessagesCardsData(params))
    return mapQueryConversationMessagesCardsData(resultRaw)
  }
)

export const ACTION_CHANNEL_NAMES = 'messages/channelNames'
export const fetchChannelNames = createAsyncThunk(ACTION_CHANNEL_NAMES, async () => {
  const resultRaw = await graphqlService.execute(queryChannelNames())
  return mapQueryChannelNames(resultRaw)
})

export type ConversationTeamsParams = {
  [DATA_SOURCE_ID]: string
}
export const ACTION_CONVERSATION_TEAMS = 'messages/teams'
export const fetchConversationTeams = createAsyncThunk(
  ACTION_CONVERSATION_TEAMS,
  async (params: ConversationTeamsParams) => {
    const resultRaw = await graphqlService.execute(queryConversationTeams(params))
    return mapQueryConversationTeams(resultRaw)
  }
)

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setSort: (state, action) => {
      state.sort = getSortDirection(state.sort, action.payload.column)
    },
    clearList: (state) => {
      state.list = initialState.list
    },
    resetWidget: (state) => {
      state.widget = initialState.widget
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSlackChannelWidgetData.fulfilled, (state, action) => {
      state.widget = action.payload
    })
    builder.addCase(fetchConversationMessages.rejected, (state) => {
      state.list = initialState.list
      state.conversationMessagesTotal = 0
    })
    builder.addCase(fetchConversationMessages.fulfilled, (state, { payload }) => {
      state.list = payload.list
      state.conversationMessagesTotal = payload.total
    })
    builder.addCase(fetchConversationMessagesCardsData.fulfilled, (state, { payload }) => {
      state.cards = payload.list
      state.conversationMessagesTotal = payload.total
    })
    builder.addCase(fetchChannelNames.fulfilled, (state, { payload }) => {
      state.channelNamesWithIDs = payload.list
    })
    builder.addCase(fetchConversationTeams.fulfilled, (state, { payload }) => {
      const distinctTeams: ConversationTeam[] = []
      payload.forEach((team) => {
        const found = distinctTeams.find((includedTeam) => includedTeam.teamId === team.teamId)
        if (!found) {
          distinctTeams.push(team)
        }
      })
      state.teams = distinctTeams
    })
  }
})

export const { setSort, clearList, resetWidget } = messagesSlice.actions

export default messagesSlice.reducer
