import { DataSourcesOverview, PrintDataSourceSummary } from './printSlice'
import {
  AlertStatuses,
  API_FILTER_ALERTS,
  CLASSICATION_TYPE,
  DATA_SOURCE_TYPES,
  DATASOURCES_LIMIT_DEFAULT,
  MIXED_DATASOURCES
} from '../constants'
import { gql } from 'graphql-request'
const getConfiguredDS = (dsList) => {
  return dsList.filter(({ node }) => {
    if (node.type === DATA_SOURCE_TYPES.generic && node.piiFilesCount?.count) {
      return true
    } else if (node.type !== DATA_SOURCE_TYPES.generic) {
      return true
    }
    return false
  })
}
export const queryDataSourcesOverview = (): string => {
  return gql`
    {
      datasources(first: ${DATASOURCES_LIMIT_DEFAULT}) {
        count
        edges {
          node {
            id
            name
            type
            piiFilesCount: objects(category: FILE, isSensitive: true) {
              count
            }
            alerts(first: 1, filter: { key: ${API_FILTER_ALERTS.STATUS}, values: "${AlertStatuses.active}"}) {
              count
            }
          }
        }
      }
      policy(first: 100) {
        count
        edges {
          node {
            id
            name
            alert(first: 1) {
              count
            }
          }
        }
      }
      attributeInstance {
        count
      }
      sensitiveAttributeInstances: attributeInstance(sensitivity:HIGH) {
        count
      }
      attribute(first: 1, after: "", hasInstances: true, booleanFilter: [ { key: ENABLED, value: true } { key: IS_CATEGORY, value: false } ]) {
        count
      }
      attributesPii: attribute(first: 1, after: "", sensitivity: [HIGH], hasInstances:true, booleanFilter: [ { key: ENABLED, value: true } { key: IS_CATEGORY, value: false } ]) {
        count
      }
      attributeEnabledTotal: attribute(booleanFilter: [{key: ENABLED, value: true}, {key: IS_CATEGORY, value: false}]){
        count
      }
      userEntitiesRisky: userEntities(first: 999, booleanFilter: [{ key: IS_RISKY, value: true }]) {
        count
      }
      userEntities(first: 999) {
        count
      }
      objectGroupByClassificationType{
        classification
        objectCount
      }
      objects(isSensitive: true){
        count
      }
      riskyObjects: objects(isRisky: true){
        count
      }
      objectGroupByClassificationType{
        classification
      }
      piiTables: datasources{
        edges{
          node{
            tables(booleanFilter:[{key: IS_SENSITIVE, value: true}]){
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryDataSourcesOverview = (raw: any): DataSourcesOverview => {
  try {
    const dataSources = getConfiguredDS(raw.datasources?.edges || [])
    const dataSourcesRiskCount =
      dataSources.filter(({ node: ds }) => ds.alerts?.count > 0).length || 0
    const policiesCount = raw.policy?.count || 0
    const policiesViolatedCount =
      raw.policy.edges.filter(({ node: policy }) => policy?.alert?.count > 0).length || 0

    const attributeInstancesCount = raw.attributeInstance?.count || 0
    const attributesCount = raw.attribute?.count || 0
    const attributesPiiCount = raw.attributesPii?.count || 0

    const entitiesCount = raw.userEntities.count || 0
    const entitiesRiskyCount = raw.userEntitiesRisky.count || 0
    const classifiedFilesCount = raw.objectGroupByClassificationType.reduce((acc, curr) => {
      if (curr.classification === CLASSICATION_TYPE.UNCLASSIFIED) {
        return acc
      }
      return acc + parseInt(curr.objectCount, 10)
    }, 0)
    const attributeEnabledTotal = raw.attributeEnabledTotal?.count || 0
    const piiFilesCount = raw.objects?.count || 0
    const piiTablesCount = raw.piiTables?.edges?.reduce((acc, { node }) => {
      return acc + (node.tables.count || 0)
    }, 0)
    const classificationsCount = raw.objectGroupByClassificationType.length || 0
    const riskyObjectsCount = raw.riskyObjects?.count || 0
    const sensitiveAttributeInstances = raw.sensitiveAttributeInstances?.count || 0
    return {
      dataSourcesCount: dataSources.length,
      dataSourcesRiskCount,
      policiesCount,
      policiesViolatedCount,
      attributeInstancesCount,
      attributesCount,
      attributesPiiCount,
      entitiesCount,
      entitiesRiskyCount,
      classifiedFilesCount,
      attributeEnabledTotal,
      dataSourcesInfo:
        raw.datasources?.edges?.map(({ node: ds }) => ({
          id: ds.id,
          isMixed: !!MIXED_DATASOURCES.find((mixedType) => mixedType == ds.type)
        })) || [],
      piiFilesCount,
      piiTablesCount,
      classificationsCount,
      riskyObjectsCount,
      sensitiveAttributeInstances
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDataSourceSummary = (dsId: string, isMixed: boolean): string => {
  const parentObjectType = isMixed ? 'parentObjectType' : ''
  const objectGroupByParent = isMixed
    ? `objectGroupByParent(filter: [{key: DATASOURCE_IDS, values: ["${dsId}"]}]) { count }`
    : ''

  return gql`
    {
      datasources(id: "${dsId}"){
        count
        edges {
          node {
            id
            name
            type
            createdBy
            ${parentObjectType}
            owner {
              name
              email
            }
            alerts(first: 1, filter: {key: STATUS, values: ["Active"]}){
              count
            }
            buckets {
              count
            }
            drive {
              count
            }
            channel {
              count
            }
            mail {
              count
            }
            tables {
              count
            }
            columns {
              count
            }
            databases(first: ${DATASOURCES_LIMIT_DEFAULT}) {
              count
              edges {
                node {
                  piiTables: tables(classification: PII) {
                    count
                  }
                  piiColumns: columns(classification: PII) {
                    count
                  }
                }
              }
            }
            policies {
              edges {
                node {
                  alert(first: 1) {
                    count
                  }
                }
              }
            }
            objects(category: FILE, isSensitive: true) {
              count
            }
            projects {
              count
            }
          }
        }
      }
      ticketStoreProject(datasourceId: "${dsId}") {
        count
      }
      fileShare(datasourceId: "${dsId}") {
        count
      }
      ${objectGroupByParent}
    }
  `
}

export const mapQueryDataSourceSummary = (raw: any): PrintDataSourceSummary => {
  const ds = raw.datasources?.edges[0]?.node

  try {
    return {
      id: ds.id,
      name: ds.name,
      type: ds.type,
      ownerName: ds.owner?.name || '',
      ownerEmail: ds.owner?.email || ds.createdBy || '',
      bucketsCount: ds.buckets?.count || 0,
      drivesCount: ds.drive?.count || 0,
      channelsCount: ds.channel?.count || 0,
      mailsCount: ds.mail?.count || 0,
      tablesCount: ds.tables?.count || 0,
      piiTablesCount: ds?.databases?.edges?.reduce((acc, { node }) => {
        return acc + node.piiTables.count
      }, 0),
      piiColumnsCount: ds?.databases?.edges?.reduce((acc, { node }) => {
        return acc + node.piiColumns.count
      }, 0),
      sharesCount: raw.fileShare?.count || 0,
      databasesCount: ds.databases?.count || 0,
      columnsCount: ds.columns?.count || 0,
      piiFilesCount: ds.objects?.count || 0,
      parentObjectType: ds.parentObjectType || '',
      policiesViolatedCount: ds?.alerts?.count || 0,
      projectsCount: ds.projects?.count || 0,
      jiraProjectCount: raw.ticketStoreProject?.count || 0,
      parentObjectCount: raw.objectGroupByParent?.count || 0
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAttributesCountByDs = (dsId: string): string => {
  return gql`
    {
      attribute(datasourceIds: ["${dsId}"], hasInstances: true){
        count
      }
    }
  `
}

export const mapQueryAttributesCountByDs = (raw: any): number => {
  try {
    return raw.attribute?.count || 0
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDeltaSummary = (startDate, endDate): string => {
  return gql`
    {
      datasourceHistoricalStats(
        historyFilter: {
          start: "${startDate}"
          end: "${endDate}"
        }
        timeUnit: WEEK
      ) {
        stats {
          startAdded
          startDeleted
          startTotal
          total
          added
          deleted
          startTimestamp
          endTimestamp
        }
      }
      attributeHistoricalStats(
        historyFilter: {
          start: "${startDate}"
          end: "${endDate}"
        }
        timeUnit: WEEK
      ) {
        stats {
          startAdded
          startDeleted
          startTotal
          total
          added
          deleted
          startTimestamp
          endTimestamp
        }
      }
      columnHistoricalStats(
        historyFilter: {
          start: "${startDate}"
          end: "${endDate}"
        }
        timeUnit: WEEK
      ) {
        stats {
          startAdded
          startDeleted
          startTotal
          total
          added
          deleted
          startTimestamp
          endTimestamp
        }
      }
      tableHistoricalStats(
        historyFilter: {
          start: "${startDate}"
          end: "${endDate}"
        }
        timeUnit: WEEK
      ) {
        stats {
          startAdded
          startDeleted
          startTotal
          total
          added
          deleted
          startTimestamp
          endTimestamp
        }
      }
      attributeInstanceHistoricalStats(
        historyFilter: {
          start: "${startDate}"
          end: "${endDate}"
        }
        timeUnit: WEEK
      ) {
        stats {
          startTimestamp
          endTimestamp
          attributes {
            attributeId
            startAdded
            startDeleted
            startTotal
            total
            added
            deleted
          }
        }
      }
      fileHistoricalStats(
        historyFilter: {
          start: "${startDate}"
          end: "${endDate}"
        }
        timeUnit: WEEK
      ) {
        stats {
          startAdded
          startDeleted
          startTotal
          total
          added
          deleted
          startTimestamp
          endTimestamp
        }
      }
    }
  `
}

export const mapQueryDeltaSummary = (raw: any) => {
  try {
    const datasourceStats = raw?.datasourceHistoricalStats.stats
    const attributeStats = raw?.attributeHistoricalStats.stats
    const columnStats = raw?.columnHistoricalStats.stats
    const tableStats = raw?.tableHistoricalStats.stats
    const attributeInstanceStats = raw?.attributeInstanceHistoricalStats.stats
    const fileStats = raw?.fileHistoricalStats.stats
    return {
      datasourceStats,
      attributeStats,
      columnStats,
      tableStats,
      attributeInstanceStats,
      fileStats
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDatasourceAttributeInstancesDeltaCounts = (
  startDate,
  endDate,
  datasourceId
): string => {
  return gql`
    {
      attributeInstanceHistoricalStats(
        historyFilter: {
          start: "${startDate}"
          end: "${endDate}"
        }
        datasourceIds:["${datasourceId}"]
        timeUnit: WEEK
      ) {
        stats {
          startTimestamp
          endTimestamp
          attributes {
            startAdded
            startDeleted
            startTotal
            total
            added
            deleted
          }
        }
      }
   }`
}

export const mapQueryDatasourceAttributeInstancesDeltaCounts = (raw: any) => {
  try {
    return raw?.attributeInstanceHistoricalStats.stats
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDeltaHighSensitivyAttributeInstance = (
  startDate,
  endDate,
  datasourceId
): string => {
  let paramsStr = `historyFilter: {
    start: "${startDate}"
    end: "${endDate}"
  }
  timeUnit: WEEK
  sensitivity:[HIGH]`

  if (datasourceId) {
    paramsStr += ` datasourceIds: ["${datasourceId}"]`
  }
  return gql`
    {
      attributeInstanceHistoricalStats(${paramsStr}) {
        stats {
          startTimestamp
          endTimestamp
          attributes {
            attributeId
            startAdded
            startDeleted
            startTotal
            total
            added
            deleted
          }
        }
      }
    }
  `
}

export const mapQueryDeltaHighSensitivyAttributeInstance = (raw: any) => {
  try {
    return raw?.attributeInstanceHistoricalStats.stats
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const queryDeltaHighSensitivyColumns = (startDate, endDate, datasourceId): string => {
  let paramsStr = `historyFilter: {
    start: "${startDate}"
    end: "${endDate}"
  }
  sensitivity: [HIGH]
  timeUnit: WEEK`
  if (datasourceId) {
    paramsStr += ` datasourceIds: ["${datasourceId}"]`
  }
  return gql`
  {
    columnHistoricalStats(${paramsStr}) {
      stats {
        startAdded
        startDeleted
        startTotal
        total
        added
        deleted
        startTimestamp
        endTimestamp
      }
    }
  }
  `
}

export const mapQueryDeltaHighSensitivyColumns = (raw: any) => {
  try {
    return raw?.columnHistoricalStats.stats
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const queryDeltaDatasourceFiles = (startDate, endDate, datasourceId): string => {
  return gql`
  {
    fileHistoricalStats(
      historyFilter: {
        start: "${startDate}"
        end: "${endDate}"
      }
      datasourceIds: ["${datasourceId}"]
      timeUnit: WEEK
    ) {
      stats {
        startAdded
        startDeleted
        startTotal
        total
        added
        deleted
        startTimestamp
        endTimestamp
      }
    }
  }
  `
}

export const mapQueryDeltaDatasourceFiles = (raw: any) => {
  try {
    return raw?.fileHistoricalStats.stats
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDeltaDatasourceTables = (startDate, endDate, datasourceId): string => {
  return gql`
  {
    tableHistoricalStats(
      historyFilter: {
        start: "${startDate}"
        end: "${endDate}"
      }
      datasourceIds: ["${datasourceId}"]
      timeUnit: WEEK
    ) {
      stats {
        startAdded
        startDeleted
        startTotal
        total
        added
        deleted
        startTimestamp
        endTimestamp
      }
    }
  }
  `
}

export const mapQueryDeltaDatasourceTables = (raw: any) => {
  try {
    return raw?.tableHistoricalStats.stats
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDeltaDatasourceColumns = (startDate, endDate, datasourceId): string => {
  return gql`
  {
    columnHistoricalStats(
      historyFilter: {
        start: "${startDate}"
        end: "${endDate}"
      }
      datasourceIds: ["${datasourceId}"]
      timeUnit: WEEK
    ) {
      stats {
        startAdded
        startDeleted
        startTotal
        total
        added
        deleted
        startTimestamp
        endTimestamp
      }
    }
  }
  `
}

export const mapQueryDeltaDatasourceColumns = (raw: any) => {
  try {
    return raw?.columnHistoricalStats.stats
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const policyPerDatasource = (datasourceId: string) => {
  return gql`{
  policy(first: 100) {
    count
    edges {
      node {
        id
        name
        alert(first: 1, datasourceIds:["${datasourceId}"]) {
          count
        }
      }
    }
  }
  }`
}

export const mapPolicyPerDatasource = (raw: any) => {
  try {
    return raw?.policy?.edges.filter(({ node: policy }) => policy?.alert?.count > 0).length || 0
  } catch (error) {
    console.error(error)
    throw error
  }
}
