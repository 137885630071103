import service from '../../../../services/api/apiService'
import { AnnotationFeedbackParams } from '../../../../services/api/apiTypes'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const ACTION_ANNOTATION_FEEDBACK = 'annotationFeedback/post'
export const sendFeedback = createAsyncThunk(
  ACTION_ANNOTATION_FEEDBACK,
  async (
    { documentId, data }: { documentId: string; data: AnnotationFeedbackParams },
    { rejectWithValue }
  ) => {
    try {
      await service.postUserFeedback(documentId, data)

      return { statusMessage: 'annotationFeedback.message.success' }
    } catch (error) {
      return rejectWithValue({ statusMessage: 'annotationFeedback.message.error' })
    }
  }
)

export const initialState = {}

const annotationFeedbackSlice = createSlice({
  name: 'annotationFeedback',
  initialState,
  reducers: {}
})

export default annotationFeedbackSlice.reducer
