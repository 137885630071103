import {
  mapAllDatasourceNames,
  mapQueryDataSourceFilters,
  mapQueryDataSourceOwners,
  mapQueryDataSources,
  mapQueryDataSourcesDistribution,
  queryAllDataSources,
  queryDataSources
  // queryDataSourcesDistribution
} from './queries'
import { DataSource } from '../../../../../../services/api/apiTypes'
import { DATASOURCE_STATUS, DATA_SOURCE_TYPES } from '../../../../../../constants'
import { getSortDirection, defaultSortParams, SortParams } from '../../../../../../utils/sortUtil'
import graphqlService from '../../../../../../services/graphqlService'
import { ListItemData } from '../../../../../../components/TableCardView/tableCardView'
import { User } from '../../../requestDetailsSlice'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DropdownItemProps } from 'semantic-ui-react'

export interface DataSourcesListFilters {
  dataSourceType: DATA_SOURCE_TYPES[]
  status: DATASOURCE_STATUS[]
  createdBy: string[]
}

export type DataSourceDistribution = {
  total: number
  count: number
}

export type DataSourceItem = DataSource & ListItemData & { comments: Comment[] }

export type DataSourceOwner = { name: string; email: string }

export type Comment = {
  text: string
  by: User
  on: string
}

interface DataSourcesState {
  list: DataSourceItem[]
  total: number
  sort: SortParams
  currentPage: number
  filterOptions: DataSourcesListFilters
  connectedDataSources: DataSourceDistribution
  remoteDataSources: DataSourceDistribution
  dataCollection: DataSourceDistribution
  allDataSourceNames: DropdownItemProps[]
  owners: DropdownItemProps[]
}

export type DataSourcesParams = {
  page?: number
  dataSourceType?: DATA_SOURCE_TYPES
  status?: DATASOURCE_STATUS
  owner?: string
}

const dataSourcesDistributionRaw = {
  dsrDataSourceDistribution: {
    connectedDataSources: {
      total: 9,
      count: 5
    },
    remoteDataSources: {
      total: 6,
      count: 2
    },
    dataCollection: {
      total: 20,
      count: 15
    }
  }
}

export const FETCH_DATA_SOURCES_DISTRIBUTION = 'fetch/dsr/data-discovery/dataSources/distribution'
export const fetchDataSourcesDistribution = createAsyncThunk(
  FETCH_DATA_SOURCES_DISTRIBUTION,
  async () => {
    // const resultRaw = await graphqlService.execute(queryDataSourcesDistribution())
    return mapQueryDataSourcesDistribution(dataSourcesDistributionRaw)
  }
)

export const FETCH_DATA_SOURCES_LIST = 'fetch/dsr/data-discovery/dataSources/list'
export const fetchDataSources = createAsyncThunk(
  FETCH_DATA_SOURCES_LIST,
  async (params?: DataSourcesParams) => {
    params
    queryDataSources
    mapQueryDataSources
    // const results = await graphqlService.execute(queryDataSources(params))
    // return mapQueryDataSources(results)

    const mockedList = [
      {
        id: '1',
        status: 'registering',
        createdBy: 'hussnain@lightbeam.ai',
        dataSourceName: 'Google Drive Workspace',
        numberOfObjects: 7,
        registeredOn: '2022-12-06T08:00:54.641000+00:00',
        dataSourceType: 'GOOGLE_DRIVE',
        dueDate: '2023-01-08T11:00:00.000Z',
        connectionType: 'Connected'
      },
      {
        id: '2',
        status: 'registering',
        createdBy: 'hussnain@lightbeam.ai',
        dataSourceName: 'PostgreSQL Database',
        numberOfObjects: 10,
        registeredOn: '2022-12-06T08:00:54.641000+00:00',
        dataSourceType: 'POSTGRESQL',
        dueDate: '2023-01-08T11:00:00.000Z',
        connectionType: 'Connected'
      },
      {
        id: '3',
        status: 'registering',
        createdBy: 'hussnain@lightbeam.ai',
        dataSourceName: 'ServiceNow System',
        numberOfObjects: 5,
        registeredOn: '2022-12-06T08:00:54.641000+00:00',
        dataSourceType: 'SERVICE_NOW',
        dueDate: '2023-01-08T11:00:00.000Z',
        connectionType: 'Connected'
      },
      {
        id: '4',
        status: 'registering',
        createdBy: 'hussnain@lightbeam.ai',
        dataSourceName: 'JIRA Ticketing System',
        numberOfObjects: 4,
        registeredOn: '2022-12-06T08:00:54.641000+00:00',
        dataSourceType: 'SERVICE_NOW',
        dueDate: '2023-01-08T11:00:00.000Z',
        connectionType: 'Connected'
      },
      {
        id: '5',
        status: 'registering',
        createdBy: 'hussnain@lightbeam.ai',
        dataSourceName: 'Slack Workspace',
        numberOfObjects: 3,
        registeredOn: '2022-12-06T08:00:54.641000+00:00',
        dataSourceType: 'SLACK',
        dueDate: '2023-01-08T11:00:00.000Z',
        connectionType: 'Connected'
      },
      {
        id: '6',
        status: 'registering',
        createdBy: 'hussnain@lightbeam.ai',
        dataSourceName: 'SAP',
        numberOfObjects: 6,
        registeredOn: '2022-12-06T08:00:54.641000+00:00',
        dataSourceType: 'GENERIC',
        dueDate: '2023-01-08T11:00:00.000Z',
        connectionType: 'Remote'
      },
      {
        id: '7',
        status: 'registering',
        createdBy: 'hussnain@lightbeam.ai',
        dataSourceName: 'FileShare',
        numberOfObjects: 6,
        registeredOn: '2022-12-06T08:00:54.641000+00:00',
        dataSourceType: 'GENERIC',
        dueDate: '2023-01-08T11:00:00.000Z',
        connectionType: 'Remote'
      }
    ] as any

    return { list: mockedList, total: mockedList.length }
  }
)

export const FETCH_ALL_DATA_SOURCE_NAMES =
  'fetch/dsr/data-discovery/dataSources/all-datasource-names'
export const fetchALlDataSourceNames = createAsyncThunk(FETCH_ALL_DATA_SOURCE_NAMES, async () => {
  const results = await graphqlService.execute(queryAllDataSources())
  return mapAllDatasourceNames(results)
})

export const fetchDataSourceFilters = createAsyncThunk(
  'fetch/dsr/data-discovery/dataSources/filters',
  async () => {
    const results = await graphqlService.execute(queryAllDataSources())
    const createdBy = [...new Set(mapQueryDataSourceFilters(results))]
    const dataSourceType = Object.values(DATA_SOURCE_TYPES)
    const status = Object.values(DATASOURCE_STATUS)

    return { createdBy, dataSourceType, status }
  }
)

export const FETCH_DATA_SOURCES_OWNERS = 'fetch/dsr/data-discovery/dataSources/owners'
export const fetchDataSourceOwners = createAsyncThunk(FETCH_DATA_SOURCES_OWNERS, async () => {
  const results = await graphqlService.execute(queryAllDataSources())
  return mapQueryDataSourceOwners(results)
})

const SEND_DATA_DISCOVERY_REQUEST = 'send/dsr/data-discovery/request'
export const sendRequest = createAsyncThunk(
  SEND_DATA_DISCOVERY_REQUEST,
  async (/* selectedDataSources: string[] */) => {
    try {
      // await graphqlService.execute(queryAllDataSources(selectedDataSources))
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }
)

const POST_DATA_SOURCE_COMMENT = 'post/dsr/data-discovery/data-sources/comment'
export const postComment = createAsyncThunk(
  POST_DATA_SOURCE_COMMENT,
  async (/* selectedDataSources: string[] */) => {
    try {
      // await graphqlService.execute(queryAllDataSources(selectedDataSources))
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }
)

export const SEND_DATA_DISCOVERY_REMINDER = 'send/dsr/data-discovery/reminder'
export const sendReminder = createAsyncThunk(
  SEND_DATA_DISCOVERY_REMINDER,
  async (/* selectedDataSources: string[] */) => {
    try {
      // await graphqlService.execute(queryDataSourceOwners(selectedDataSources))
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }
)

export const ADD_DATA_DISCOVERY_TASK = 'send/dsr/data-discovery/add-task'
export const addTask = createAsyncThunk(ADD_DATA_DISCOVERY_TASK, async () => {
  try {
    // await graphqlService.execute(queryDataSourceOwners())
    return true
  } catch (error) {
    console.error(error)
    return false
  }
})

export const initialState: DataSourcesState = {
  list: [],
  total: 0,
  sort: defaultSortParams,
  currentPage: 1,
  filterOptions: {
    dataSourceType: [],
    status: [],
    createdBy: []
  },
  connectedDataSources: {
    total: 0,
    count: 0
  },
  remoteDataSources: { total: 0, count: 0 },
  dataCollection: { total: 0, count: 0 },
  allDataSourceNames: [],
  owners: []
}

const dataSourcesSlice = createSlice({
  name: 'dsrDataDiscoveryDataSources',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setSort: (state, { payload }) => {
      state[payload.list].sort = getSortDirection(state[payload.list].sort, payload.column)
    },
    reset: (state) => {
      Object.assign(state, initialState)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDataSources.rejected, (state) => {
      state.list = initialState.list
    })
    builder.addCase(fetchDataSources.fulfilled, (state, { payload }) => {
      const { list, total } = payload

      state.list = list
      state.total = total
    })

    builder.addCase(fetchALlDataSourceNames.fulfilled, (state, { payload }) => {
      state.allDataSourceNames = payload
    })

    builder.addCase(fetchDataSourceFilters.fulfilled, (state, { payload }) => {
      state.filterOptions = payload
    })

    builder.addCase(fetchDataSourcesDistribution.fulfilled, (state, { payload }) => {
      const data = {
        total: 0,
        count: 0
      }
      state.connectedDataSources = {
        ...data,
        ...payload.connectedDataSources
      }
      state.remoteDataSources = {
        ...data,
        ...payload.remoteDataSources
      }
      state.dataCollection = {
        ...data,
        ...payload.dataCollection
      }
    })
    builder.addCase(fetchDataSourceOwners.fulfilled, (state, { payload }) => {
      state.owners = payload
    })
  }
})

export const { reset, setCurrentPage, setSort } = dataSourcesSlice.actions

export default dataSourcesSlice.reducer
