import './dataSourceTypeItem.less'
import { getDataSourceImage } from '../../utils/dataSourceUtil'
import { DATA_SOURCE_TYPES, DATA_SOURCE_TYPE_API_MAP } from '../../constants'
import { DatasourceType } from '../../services/graphqlSchemaTypes'
import React, { ReactElement } from 'react'
import { Typography } from '@lightbeamai/design-system'

interface IProps {
  dataSourceType?: DATA_SOURCE_TYPES | DatasourceType
  content?: ReactElement | string
  sizePx?: number
  tooltip?: string
}

const DataSourceTypeItem = (props: IProps): React.ReactElement => {
  const { dataSourceType, content, sizePx = 18, tooltip = '' } = props

  if (!dataSourceType) return <></>

  return (
    <div
      className="data-source-item"
      title={tooltip || dataSourceType}
      data-test-id="datasource-type-item"
    >
      <div className="icon-box" style={{ height: sizePx, width: sizePx }}>
        <img src={getDataSourceImage(dataSourceType, '/../../assets/images/dataSourceTypes/')} />
      </div>
      &nbsp;
      <Typography as="span" color="primary" variant="base" className="datasource-name truncate">
        {content !== undefined ? content : DATA_SOURCE_TYPE_API_MAP[dataSourceType]}
      </Typography>
    </div>
  )
}

export default DataSourceTypeItem
