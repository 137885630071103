import {
  downloadDsrReport,
  fetchDsrReportsList,
  fetchReportGroupByRequestType,
  mapFetchAllRequestIds,
  mapFetchDsrReportsList,
  mapFetchReportGroupByRequestType,
  fetchAllRequestIds
} from './queries'
import graphqlService from '../../../services/graphqlService'
import { DsrRequestStatus, DsrRequestType } from '../../../services/graphqlSchemaTypes'
import { IGetRequestListParams } from '../requestList/dsrRequestSlice'
import apiService from '../../../services/api/apiService'
import { RootState } from '../../../rootReducer'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export type ReportData = {
  reportId: string
  reportStatus: string | null
  requestId: string
  requestName: string
  requestType: DsrRequestType
  updateTimestamp: string
  dataSubjectName: string
  requestStatus: DsrRequestStatus
  dueDate: string
  createdAt: string
  assigneeName: string
  assigneeEmail: string
  firstName?: string
  lastName?: string
}

export type ReportGroupByRequestType = {
  requestType: DsrRequestType
  count: number
}

export type DsrReportsState = {
  reports: ReportData[]
  reportsGroupByRequestType: ReportGroupByRequestType[]
  assigneeEmail?: string[]
  totalCount: number
  allRequestIds?: string[]
}

export const DSR_REPORT_LIST = 'dsr/reports/list'

export const fetchDsrReportList = createAsyncThunk(
  DSR_REPORT_LIST,
  async (params: IGetRequestListParams) => {
    const resultRaw = await graphqlService.execute(fetchDsrReportsList(params))

    const mappedData = mapFetchDsrReportsList(resultRaw)

    return mappedData
  }
)

export const DSR_REPORTS_GROUP_BY_REQUEST_TYPE = 'dsr/reports/groupByRequestType'

export const getReportsGroupByRequestType = createAsyncThunk(
  DSR_REPORTS_GROUP_BY_REQUEST_TYPE,
  async (params: IGetRequestListParams) => {
    const resultRaw = await graphqlService.execute(fetchReportGroupByRequestType(params))

    const mappedData = mapFetchReportGroupByRequestType(resultRaw)

    return mappedData
  }
)

export const DSR_DOWNLOAD_REPORT = 'dsr/reports/downloadReport'

export const downloadBulkDsrReport = createAsyncThunk(
  DSR_DOWNLOAD_REPORT,
  async (params: { selectedReports: string[]; actionType; appliedFilters }, { getState }) => {
    const { selectedReports, actionType, appliedFilters } = params
    const state = getState() as RootState
    const currentUserEmail = state.user.userInfo?.emailId || ''

    const allParams = { selectedReports, currentUserEmail, actionType, appliedFilters }

    const resultRaw = await graphqlService.execute(downloadDsrReport(allParams))
    if (resultRaw.createDsrBulkReportAction.clientMutationId) {
      return {
        mutationStatus: 'SUCCESS'
      }
    } else {
      return {
        mutationStatus: 'ERROR'
      }
    }
  }
)

export const FETCH_DSR_REPORT_ASSIGNEE_EMAILS = 'fetch/dsr/report/assignee-emails'
export const fetchDSRReportAssigneeEmails = createAsyncThunk(
  FETCH_DSR_REPORT_ASSIGNEE_EMAILS,
  async () => {
    return await apiService.getDSRAssigneeEmails()
  }
)

export const FETCH_ALL_REQUEST_IDS = 'dsr/reports/requestIds'
export const getAllRequestIds = createAsyncThunk(FETCH_ALL_REQUEST_IDS, async (appliedFilters) => {
  const resultRaw = await graphqlService.execute(fetchAllRequestIds(appliedFilters))

  const mappedData = mapFetchAllRequestIds(resultRaw)

  return {
    requestIds: mappedData,
    status: 'SUCCESS'
  }
})

const initialState: DsrReportsState = {
  reports: [],
  reportsGroupByRequestType: [],
  assigneeEmail: [],
  totalCount: 0,
  allRequestIds: []
}

const dsrReportsSlice = createSlice({
  name: 'dsrReports',
  initialState,
  reducers: {
    setReports(state, action) {
      state.reports = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDsrReportList.fulfilled, (state, action) => {
      state.reports = action.payload.report
      state.totalCount = action.payload.count
    })
    builder.addCase(getReportsGroupByRequestType.fulfilled, (state, action) => {
      state.reportsGroupByRequestType = action.payload
    })
    builder.addCase(fetchDSRReportAssigneeEmails.fulfilled, (state, action) => {
      state.assigneeEmail = action.payload.assigneeEmail
    })
    builder.addCase(getAllRequestIds.fulfilled, (state, action) => {
      state.allRequestIds = action.payload.requestIds
    })
  }
})

export const { setReports } = dsrReportsSlice.actions

export default dsrReportsSlice.reducer
