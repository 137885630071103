import {
  queryPartnersListCompact,
  mapQueryPartnersListCompact,
  queryPartnersCard,
  mapQueryPartnersCard,
  queryPartnersList,
  mapQueryPartnersList,
  queryDatasourceTopWidget,
  mapQueryDatasourceTopWidget,
  queryPartnerObjectsSharedGroupByDataSourceType,
  mapQueryPartnerObjectsSharedGroupByDataSourceType,
  queryPartnerObjectsSharedGroupBySender,
  mapQueryPartnerObjectsSharedGroupBySender,
  queryPartnerObjectsSharedList,
  mapQueryPartnerObjectsSharedList,
  queryRegisterPartner,
  queryPartnerById,
  mapQueryPartnerById,
  queryUpdatePartner,
  queryGenericPartnerDomains,
  mapQueryGenericPartnerDomains,
  mapQueryExternalMembersList,
  queryExternalMembersList,
  queryExternalMembersListCompact,
  mapQueryExternalMembersListCompact,
  queryPartnerSummary,
  mapQueryPartnerSummary
} from './queries'
import graphqlService from '../../services/graphqlService'
import { DATA_SOURCE_TYPES, PAGE, PAGE_SIZE } from '../../constants'
import { IGetAttributesFilterParams } from '../attributes/attributesSlice'
import { FilterParams } from '../../interfaces'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import { RootState } from '../../rootReducer'
import { getGlobalParams } from '../../utils/urlUtil'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
export interface PartnerInfo {
  id?: string
  name: string
  domain: string
  contractUrl?: string
  partnerManager?: string
  partnerContact?: string
  dataExchangePurpose?: string
  permittedAttributes?: { attributeSetId: string; attributeIds: string[] }[]
}

export interface ObjectShared {
  messageId?: string
  lastModifiedTime?: string
  shareOn?: string
  objectId?: string
  objectType?: string
  datasourceName?: string
  datasourceType?: DATA_SOURCE_TYPES
  datasourceId?: string
  attributesCount?: number
  attributes?: string[]
  entitiesCount?: number
  entities?: { id: string; name: string }[]
  recipients?: string[]
  subject?: string
  mailShareOn?: string
  sharedBy?: string
  senderEmail?: string
  objectName?: string
  senderName?: string
}

export type PartnerCard = {
  id: string
  name: string
  objectsCount: number
  isContractDocumentAdded: boolean
  attributeCount: number
  attributeInstanceCount: number
  entitiesCount: number
  alertsCount?: number
  objectsImpactedCount?: number
  ids?: string[]
}

export type ExternalMembers = {
  id: string
  name: string
  objectsCount: number
  isContractDocumentAdded: boolean
  attributeCount: number
  attributeInstanceCount: number
  entitiesCount: number
  alertsCount?: number
  objectsImpactedCount?: number
  ids?: string[]
  piiObjectCount: number
  datasourceCount: number
}

export type ExternalMembersList = {
  membersList: ExternalMembers[]
}

export type TopDatasource = {
  datasourceType: string
  objectCount: number
}
export type TopDomains = {
  domainName: string
  objectCount: number
}
export interface PartnerCardList {
  cards: PartnerCard[]
}
export interface PartnerCompact {
  id: string
  name: string
  objectsCount: number
  ids?: string[]
  isGeneralPartner?: boolean
}
export type Partner = {
  id: string
  name: string
  objectsCount: number
  piiObjectCount: number
  attributeCount: number
  attributeInstanceCount: number
  entitiesCount: number
  datasourceCount: number
  ids?: string[]
}
export type PartnerListCompact = {
  listCompact?: PartnerCompact[]
  total?: number
}
export type ExternalMembersListCompact = {
  membersListCompact: PartnerCompact[]
}
export type PartnerList = {
  list?: Partner[]
  total?: number
}
interface PartnersState {
  listCompact: PartnerListCompact
  membersListCompact: ExternalMembersListCompact
  list: PartnerList
  cards: PartnerCardList
  externalMembersList: ExternalMembersList
  topDatasourceList: TopDatasource[]
  topDomainsList: TopDomains[]
  partnerInfo?: PartnerInfo
  objectsSharedList: {
    displayedDataSourceTypes?: string[]
    cards?: ObjectShared[]
    list?: ObjectShared[]
    sendersList?: ObjectShared[]
    sort: SortParams
    datasourcesCount?: number
    sendersCount?: number
    filterSendersList?: ObjectShared[]
    objectsCount?: number
    filteredObjectsCount?: number
  }
  partnerRegisterSuccess?: boolean
  partnerUpdateSuccess?: boolean
  partnerSummary: PartnerSumary
}

export type IGetListParams = {
  [PAGE]?: number
  [PAGE_SIZE]?: number
  partnerId?: string
}
export type IGetCardsParams = {
  [PAGE]?: number
  [PAGE_SIZE]?: number
}
export type GetPartnersCompactListParams = {
  [PAGE]?: number
}

export type PartnerSumary = {
  objectCount: number
  partnerName: string
}

const initialListCompact: PartnerListCompact = {}
const initialList: PartnerList = {}
export const initialState: PartnersState = {
  list: { ...initialList },
  listCompact: { ...initialListCompact },
  membersListCompact: { membersListCompact: [] },
  cards: { cards: [] },
  externalMembersList: { membersList: [] },
  topDatasourceList: [],
  topDomainsList: [],
  objectsSharedList: {
    sort: defaultSortParams
  },
  partnerSummary: {
    objectCount: 0,
    partnerName: ''
  }
}

export const ACTION_PARTNERS_COMPACT_FETCH = 'partners/list/compact'
export const fetchPartnersListCompact = createAsyncThunk(
  ACTION_PARTNERS_COMPACT_FETCH,
  async (params: GetPartnersCompactListParams) => {
    const { page } = params
    const resultRaw = await graphqlService.execute(queryPartnersListCompact({ page }))
    return mapQueryPartnersListCompact(resultRaw)
  }
)

export const ACTION_PARTNERS_EXTERNAL_COMPACT_FETCH = 'partners/externalMembers/compact'
export const fetchExternalMembersListCompact = createAsyncThunk(
  ACTION_PARTNERS_EXTERNAL_COMPACT_FETCH,
  async () => {
    const resultRaw = await graphqlService.execute(queryExternalMembersListCompact())
    return mapQueryExternalMembersListCompact(resultRaw)
  }
)
export const ACTION_PARTNERS_TOP_DATASOURCES = 'partners/top/datasources'
export const fetchPartnersTopDatasources = createAsyncThunk(
  ACTION_PARTNERS_TOP_DATASOURCES,
  async (params: IGetAttributesFilterParams) => {
    const resultRaw = await graphqlService.execute(queryDatasourceTopWidget(params))
    return mapQueryDatasourceTopWidget(resultRaw)
  }
)
export const ACTION_PARTNERS_TOP_DOMAINS = 'partners/top/domains'
export const fetchGenericPartnersTopDomains = createAsyncThunk(
  ACTION_PARTNERS_TOP_DOMAINS,
  async () => {
    const resultRaw = await graphqlService.execute(queryGenericPartnerDomains())
    return mapQueryGenericPartnerDomains(resultRaw)
  }
)
export const ACTION_PARTNERS_CARDS_FETCH = 'partners/cards'
export const fetchPartnersCards = createAsyncThunk(
  ACTION_PARTNERS_CARDS_FETCH,
  async (params: IGetCardsParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const resultRaw = await graphqlService.execute(queryPartnersCard(queryParams))
    return mapQueryPartnersCard(resultRaw)
  }
)
export const ACTION_PARTNERS_LIST_FETCH = 'partners/list'
export const fetchPartnersList = createAsyncThunk(
  ACTION_PARTNERS_LIST_FETCH,
  async (params: IGetListParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const resultRaw = await graphqlService.execute(queryPartnersList(queryParams))
    return mapQueryPartnersList(resultRaw)
  }
)

export const ACTION_EXTERNAL_MEMBERS_LIST_FETCH = 'externalMembers/list'
export const fetchExternalMembersList = createAsyncThunk(
  ACTION_EXTERNAL_MEMBERS_LIST_FETCH,
  async () => {
    const resultRaw = await graphqlService.execute(queryExternalMembersList())
    return mapQueryExternalMembersList(resultRaw)
  }
)

export interface PartnerObjectsSharedCardParams {
  partnerId: string
  groupBySender: boolean
}
export const ACTION_PARTNER_OBJECTS_SHARED_GROUPBY_DATASOURCE_TYPE =
  'partners/objects-shared/datasourceTypes'
export const fetchPartnerObjectsSharedGroupByDataSourceType = createAsyncThunk(
  ACTION_PARTNER_OBJECTS_SHARED_GROUPBY_DATASOURCE_TYPE,
  async (params: FilterParams) => {
    const resultRaw = await graphqlService.execute(
      queryPartnerObjectsSharedGroupByDataSourceType(params)
    )
    return mapQueryPartnerObjectsSharedGroupByDataSourceType(resultRaw)
  }
)
export const ACTION_PARTNER_OBJECTS_SHARED_GROUPBY_SENDER = 'partners/objects-shared/senders'
export const fetchPartnerObjectsSharedGroupBySender = createAsyncThunk(
  ACTION_PARTNER_OBJECTS_SHARED_GROUPBY_SENDER,
  async (params: FilterParams) => {
    const resultRaw = await graphqlService.execute(queryPartnerObjectsSharedGroupBySender(params))
    return mapQueryPartnerObjectsSharedGroupBySender(resultRaw)
  }
)

export interface FetchPartnerObjectsSharedListParams {
  page: number
  pageSize?: number
  datasourceType?: string
  filters: FilterParams
}
export const ACTION_PARTNER_OBJECTS_SHARED_LIST = 'partners/objects-shared/list'
export const fetchPartnerObjectsSharedList = createAsyncThunk(
  ACTION_PARTNER_OBJECTS_SHARED_LIST,
  async (params: FetchPartnerObjectsSharedListParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const resultRaw = await graphqlService.execute(queryPartnerObjectsSharedList(queryParams))
    return mapQueryPartnerObjectsSharedList(resultRaw)
  }
)

export const ACTION_PARTNERS_REGISTER = 'partners/register'
export const registerPartner = createAsyncThunk(
  ACTION_PARTNERS_REGISTER,
  async (params: PartnerInfo) => {
    await graphqlService.execute(queryRegisterPartner(params))
  }
)
export const ACTION_PARTNERS_UPDATE = 'partners/update'
export const updatePartner = createAsyncThunk(
  ACTION_PARTNERS_UPDATE,
  async (params: PartnerInfo) => {
    await graphqlService.execute(queryUpdatePartner(params))
  }
)
export const ACTION_FETCH_PARTNER_BY_ID = 'partners/by-id'
export const fetchPartnerById = createAsyncThunk(ACTION_FETCH_PARTNER_BY_ID, async (id: string) => {
  const resultRaw = await graphqlService.execute(queryPartnerById(id))
  return mapQueryPartnerById(resultRaw)
})

export const ACTION_FETCH_PARTNER_SUMMARY = 'partners/summary'
export const fetchPartnerSummary = createAsyncThunk(
  ACTION_FETCH_PARTNER_SUMMARY,
  async (activePartnerId: string) => {
    const resultRaw = await graphqlService.execute(queryPartnerSummary(activePartnerId))
    return mapQueryPartnerSummary(resultRaw)
  }
)

const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    clearList: (state) => {
      state.list = initialState.list
    },
    clearTopDatasourceList: (state) => {
      state.topDatasourceList = []
    },
    setSortForObjectsShared: (state, { payload }) => {
      state.objectsSharedList.sort = getSortDirection(state.objectsSharedList.sort, payload.column)
    },
    resetObjectsShared: (state) => {
      state.objectsSharedList = initialState.objectsSharedList
    },
    resetPartnerRegisterSuccess: (state) => {
      state.partnerRegisterSuccess = initialState.partnerRegisterSuccess
    },
    resetPartnerUpdateSuccess: (state) => {
      state.partnerUpdateSuccess = initialState.partnerUpdateSuccess
    },
    resetPartnerInfo: (state) => {
      state.partnerInfo = initialState.partnerInfo
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPartnersListCompact.fulfilled, (state, { payload }) => {
      const { listCompact, total } = payload
      state.listCompact.listCompact = listCompact
      state.listCompact.total = total
    })
    builder.addCase(fetchExternalMembersListCompact.fulfilled, (state, { payload }) => {
      const { membersListCompact } = payload
      state.membersListCompact.membersListCompact = membersListCompact
    })
    builder.addCase(fetchPartnersCards.fulfilled, (state, { payload }) => {
      state.cards.cards = payload.cards
    })
    builder.addCase(fetchPartnersList.fulfilled, (state, { payload }) => {
      state.list.list = payload.list
      state.list.total = payload.total
    })
    builder.addCase(fetchExternalMembersList.fulfilled, (state, { payload }) => {
      state.externalMembersList.membersList = payload.membersList
    })
    builder.addCase(fetchPartnersTopDatasources.fulfilled, (state, { payload }) => {
      state.topDatasourceList = payload.topList
    })
    builder.addCase(fetchGenericPartnersTopDomains.fulfilled, (state, { payload }) => {
      state.topDomainsList = payload.topList
    })
    builder.addCase(
      fetchPartnerObjectsSharedGroupByDataSourceType.fulfilled,
      (state, { payload }) => {
        state.objectsSharedList.cards = payload.list
        state.objectsSharedList.datasourcesCount = payload.datasourcesCount
        state.objectsSharedList.objectsCount = payload.objectsCount
        state.objectsSharedList.displayedDataSourceTypes = payload.displayedDataSourceTypes
      }
    )
    builder.addCase(fetchPartnerObjectsSharedGroupBySender.fulfilled, (state, { payload }) => {
      state.objectsSharedList.cards = payload.list
      state.objectsSharedList.sendersList = payload.list
      state.objectsSharedList.sendersCount = payload.sendersCount
      state.objectsSharedList.objectsCount = payload.objectsCount
    })
    builder.addCase(fetchPartnerObjectsSharedList.fulfilled, (state, { payload }) => {
      state.objectsSharedList.list = payload.list
      state.objectsSharedList.filteredObjectsCount = payload.objectsCount
    })
    builder.addCase(registerPartner.fulfilled, (state) => {
      state.partnerRegisterSuccess = true
    })
    builder.addCase(registerPartner.rejected, (state) => {
      state.partnerRegisterSuccess = false
    })
    builder.addCase(fetchPartnerById.fulfilled, (state, { payload }) => {
      state.partnerInfo = payload.partner
    })
    builder.addCase(updatePartner.fulfilled, (state) => {
      state.partnerUpdateSuccess = true
    })
    builder.addCase(updatePartner.rejected, (state) => {
      state.partnerUpdateSuccess = false
    })
    builder.addCase(fetchPartnerSummary.fulfilled, (state, { payload }) => {
      state.partnerSummary.objectCount = payload.objectCount
      state.partnerSummary.partnerName = payload.partnerName
    })
  }
})

export const {
  clearList,
  clearTopDatasourceList,
  setSortForObjectsShared,
  resetPartnerRegisterSuccess,
  resetPartnerUpdateSuccess,
  resetPartnerInfo,
  resetObjectsShared
} = partnersSlice.actions

export default partnersSlice.reducer
