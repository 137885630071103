import dayjs from 'dayjs'

export const datasourcesSummary = {
  datasources: {
    edges: [
      {
        node: {
          id: '62f61f55e54674d673bcf450',
          name: 'Google Drive Sandbox',
          type: 'GOOGLE_DRIVE'
        }
      },
      {
        node: {
          id: '62f4dbd0d70d2d1491335101',
          name: 'Slack Workspace',
          type: 'SLACK'
        }
      },
      {
        node: {
          id: '62f9f046cff9974438d30a31',
          name: 'Outlook Exchange',
          type: 'OUTLOOK'
        }
      },
      {
        node: {
          id: '62f6557f9b13a7a5c3af5738',
          name: 'Dev Project',
          type: 'JIRA'
        }
      },
      {
        node: {
          id: '62f4d928062db5f73c54cec7',
          name: 'Generic Data Source',
          type: 'GENERIC'
        }
      }
    ]
  }
}

export const secretByEntitySummary = [
  {
    date: dayjs().subtract(1, 'day'),
    objectsCount: 11,
    attributesCount: 1,
    attributeInctancesCount: 63,
    datasourcesCount: 1
  },
  {
    date: dayjs().subtract(5, 'day'),
    objectsCount: 69,
    attributesCount: 2,
    attributeInctancesCount: 35,
    datasourcesCount: 1
  },
  {
    date: dayjs().subtract(4, 'day'),
    objectsCount: 42,
    attributesCount: 2,
    attributeInctancesCount: 35,
    datasourcesCount: 2
  },
  {
    date: dayjs().subtract(2, 'day'),
    objectsCount: 14,
    attributesCount: 2,
    attributeInctancesCount: 68,
    datasourcesCount: 2
  },
  {
    date: dayjs().subtract(1, 'month'),
    objectsCount: 37,
    attributesCount: 1,
    attributeInctancesCount: 8,
    datasourcesCount: 1
  }
]

export const secretByEntity = {
  '62f61f55e54674d673bcf450': [
    {
      date: dayjs().subtract(1, 'day'),
      objectsCount: 11,
      attributesCount: 1,
      attributeInctancesCount: 63
    },
    {
      date: dayjs().subtract(5, 'day'),
      objectsCount: 69,
      attributesCount: 2,
      attributeInctancesCount: 35
    }
  ],
  '62f4dbd0d70d2d1491335101': [
    {
      date: dayjs().subtract(4, 'day'),
      objectsCount: 13,
      attributesCount: 1,
      attributeInctancesCount: 25
    },
    {
      date: dayjs().subtract(2, 'day'),
      objectsCount: 3,
      attributesCount: 1,
      attributeInctancesCount: 21
    }
  ],
  '62f9f046cff9974438d30a31': [
    {
      date: dayjs().subtract(2, 'day'),
      objectsCount: 11,
      attributesCount: 1,
      attributeInctancesCount: 47
    }
  ],
  '62f4d928062db5f73c54cec7': [
    {
      date: dayjs().subtract(4, 'day'),
      objectsCount: 29,
      attributesCount: 1,
      attributeInctancesCount: 10
    },
    {
      date: dayjs().subtract(1, 'month'),
      objectsCount: 37,
      attributesCount: 1,
      attributeInctancesCount: 8
    }
  ]
}

export const secretByEntityType = {
  '62f4d928062db5f73c54cec7': [
    {
      date: dayjs().subtract(30, 'day'),
      objectsCount: 13,
      attributesCount: 83,
      attributeInctancesCount: 100,
      entityTypesCount: 3
    },
    {
      date: dayjs().subtract(25, 'day'),
      objectsCount: 24,
      attributesCount: 10,
      attributeInctancesCount: 1500,
      entityTypesCount: 3
    },
    {
      date: dayjs().subtract(2, 'day'),
      objectsCount: 30,
      attributesCount: 98,
      attributeInctancesCount: 100,
      entityTypesCount: 10
    },
    {
      date: dayjs().subtract(2, 'month'),
      objectsCount: 13,
      attributesCount: 50,
      attributeInctancesCount: 500,
      entityTypesCount: 4
    }
  ],
  '62f4dbd0d70d2d1491335101': [
    {
      date: dayjs().subtract(29, 'day'),
      objectsCount: 5,
      attributesCount: 16,
      attributeInctancesCount: 79,
      entityTypesCount: 10003
    },
    {
      date: dayjs().subtract(20, 'day'),
      objectsCount: 24,
      attributesCount: 1,
      attributeInctancesCount: 1500,
      entityTypesCount: 3
    },
    {
      date: dayjs().subtract(5, 'day'),
      objectsCount: 3000,
      attributesCount: 57,
      attributeInctancesCount: 48,
      entityTypesCount: 10
    },
    {
      date: dayjs().subtract(8, 'day'),
      objectsCount: 77,
      attributesCount: 9,
      attributeInctancesCount: 5220,
      entityTypesCount: 100
    }
  ],
  '62f9f046cff9974438d30a31': [
    {
      date: dayjs().subtract(25, 'day'),
      objectsCount: 11,
      attributesCount: 8,
      attributeInctancesCount: 79,
      entityTypesCount: 47
    },
    {
      date: dayjs().subtract(25, 'day'),
      objectsCount: 2,
      attributesCount: 801,
      attributeInctancesCount: 79,
      entityTypesCount: 47
    },
    {
      date: dayjs().subtract(2, 'month'),
      objectsCount: 31,
      attributesCount: 1,
      attributeInctancesCount: 93,
      entityTypesCount: 2
    }
  ]
}

export const secretByAttribute = {
  '62f4d928062db5f73c54cec7': [
    {
      date: dayjs().subtract(1, 'month'),
      attributeInctancesCount: 1500
    },
    {
      date: dayjs().subtract(28, 'day'),
      attributeInctancesCount: 3
    },
    {
      date: dayjs().subtract(15, 'day'),
      attributeInctancesCount: 274
    },
    {
      date: dayjs().subtract(1, 'week'),
      attributeInctancesCount: 43
    }
  ],
  '62f4dbd0d70d2d1491335101': [
    {
      date: dayjs().subtract(29, 'day'),
      attributeInctancesCount: 4
    },
    {
      date: dayjs().subtract(2, 'day'),
      attributeInctancesCount: 19
    },
    {
      date: dayjs().subtract(4, 'day'),
      attributeInctancesCount: 100
    },
    {
      date: dayjs().subtract(9, 'day'),
      attributeInctancesCount: 990
    }
  ],
  '62f9f046cff9974438d30a31': [
    {
      date: dayjs().subtract(30, 'day'),
      attributeInctancesCount: 192
    },
    {
      date: dayjs().subtract(29, 'day'),
      attributeInctancesCount: 1
    },
    {
      date: dayjs().subtract(15, 'day'),
      attributeInctancesCount: 45
    }
  ],
  '62f61f55e54674d673bcf450': [
    {
      date: dayjs().subtract(9, 'day'),
      attributeInctancesCount: 8
    },
    {
      date: dayjs().subtract(20, 'day'),
      attributeInctancesCount: 21
    }
  ],
  '62f6557f9b13a7a5c3af5738': [
    {
      date: dayjs().subtract(6, 'day'),
      attributeInctancesCount: 4080
    },
    {
      date: dayjs(),
      attributeInctancesCount: 370
    }
  ]
}
