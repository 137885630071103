import { Typography } from '@lightbeamai/design-system'
import './radioView.less'
import React from 'react'

interface IProps {
  checked: boolean
  content: string | number | boolean | React.ReactElement | React.ReactNode
  disabled?: boolean
  className?: string
  testContext?: string
  onClick: (checked: boolean) => void
}

const RadioView = (props: IProps) => {
  const { checked, content, disabled = false, className = '', testContext, onClick } = props

  const handleClick = () => !disabled && onClick(!checked)

  return (
    <div
      className={`radio-custom ${disabled ? 'disabled' : ''} ${className}`}
      onClick={handleClick}
      data-test-id="radio-view"
      {...(testContext ? { 'data-test-context': testContext } : {})}
    >
      <div className={`checkbox ${checked ? 'checked' : ''}`}>
        {checked && <div className="selection" />}
      </div>
      <Typography as="div" color="primary" variant="base" className="label">
        {content}
      </Typography>
    </div>
  )
}

export default RadioView
