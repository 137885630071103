import {
  CustomTopicKey,
  FieldProperty,
  ProcessorEnum,
  PiaDefineProcessSteps,
  PiaProcess,
  PiaQuestion,
  PiaQuestionnaireV2,
  PiaProcessOverview,
  DataElementsQuestionnaire,
  DataSubjectsQuestionnaire,
  DataRetentionQuestionnaire,
  SafeguardsQuestionnaire,
  TransfersQuestionnaire,
  PiaRiskOption,
  FieldOptions,
  PiaReportPrint
} from './piaSlice'
import { deepClone, getTicketOrProcessStats } from './collaboratorUtils'
import { SPECIAL_ATTRIBUTES } from './piaProcesses/components/sectionDataElements/constants'
import { PiaRiskLabelData } from './piaProcesses/components/labelRiskStatus/labelRiskStatus'
import { EMAIL_REGEX_RFC2822, SENSITIVE_LABEL, URL_TICKETS } from '../../constants'
import { groupByProperty } from '../../utils/common'
import dayjs from 'dayjs'

export const EMPTY_PROCESSOR_DETAILS = {
  name: '',
  email: '',
  address: '',
  contact: '',
  roleType: 'ORGANISATION' as any
}
const riskAssociationAvailable = {
  key: 'riskAssociated',
  value: ['true']
}
const riskAssociationNone = {
  key: 'riskAssociated',
  value: ['false']
}

const transformRadioProperties = (properties: FieldProperty[]) => {
  const isRiskConfigured = isFieldRisky(properties)
  const riskOptions = getFieldProperty('riskOptions', properties) as FieldOptions[]
  const props = [
    ...((properties || []).filter(
      ({ key }) => key !== 'riskAssociated' && key !== 'riskOptions' && key !== 'options'
    ) || []),
    isRiskConfigured ? riskAssociationAvailable : riskAssociationNone,
    {
      key: 'riskOptions',
      riskOptions: riskOptions.length > 0 ? riskOptions : [{ id: false }, { id: true }]
    }
  ]

  return props
}
const transformProperties = (properties: FieldProperty[], list: Array<{ id: string }>) => {
  const isRiskConfigured = isFieldRisky(properties)
  const options = getFieldProperty('options', properties) as string[]
  const riskOptions = getFieldProperty('riskOptions', properties) as FieldOptions[]
  const props = [
    ...((properties || []).filter(({ key }) => key !== 'riskAssociated' && key !== 'riskOptions') ||
      []),
    isRiskConfigured ? riskAssociationAvailable : riskAssociationNone,
    {
      key: 'riskOptions',
      riskOptions:
        list?.map(({ id }) => ({
          id,
          ...(isRiskConfigured &&
          options.find((processGroupId) => id === processGroupId) &&
          riskOptions.find(({ id: processGroupId }) => id === processGroupId)?.value
            ? {
                value: riskOptions.find(({ id: processGroupId }) => id === processGroupId)?.value
              }
            : {})
        })) || []
    }
  ]

  return props
}
const transformDataTransfersProperties = (questionnaire: PiaQuestionnaireV2, safetyMeasures) => {
  const { transfers } = questionnaire || {}
  const { thirdParty, crossBorder } = transfers || {}
  const { crossBorderProperties = [], details: crossBorderDetails = [] } = crossBorder || {}
  const { thirdPartyProperties = [], details: thirdPartyDetails = [] } = thirdParty || {}
  const thirdPartyProps = transformRadioProperties(thirdPartyProperties)
  const crossBorderProps = transformRadioProperties(crossBorderProperties)
  const crossBorderFields = crossBorderDetails.map((detail) => ({
    ...detail,
    organisation: '',
    country: '',
    email: '',
    address: '',
    safeguardsProperties: transformProperties(detail.safeguardsProperties || [], safetyMeasures)
  }))
  const thirdPartyFields = thirdPartyDetails.map((detail) => ({
    ...detail,
    name: '',
    address: '',
    email: '',
    safeguards: [],
    safeguardsProperties: transformProperties(detail.safeguardsProperties || [], safetyMeasures)
  }))
  return {
    crossBorder: {
      ...crossBorder,
      crossBorderProperties: crossBorderProps,
      details: crossBorderFields
    },
    thirdParty: {
      ...thirdParty,
      thirdPartyProperties: thirdPartyProps,
      details: thirdPartyFields
    }
  }
}
const transformDataElementsProperties = (
  questionnaire: PiaQuestionnaireV2,
  attributes,
  riskOptions
) => {
  const riskSeverityNone = riskOptions.find(({ value }) => value === 0)?.id
  const riskSeverityHigh = riskOptions.find(
    ({ displayName = '' }) => displayName.toLowerCase() === SENSITIVE_LABEL.HIGH.toLowerCase()
  )?.id

  const { dataElements } = questionnaire || {}
  const { specialElementProperties = [], attributeProperties = [] } = dataElements || {}

  const isRiskConfigured = isFieldRisky(attributeProperties)
  const riskyOptions = getFieldProperty('riskOptions', attributeProperties) as FieldOptions[]
  const attributeProps = [
    ...((attributeProperties || []).filter(
      ({ key }) => key !== 'riskAssociated' && key !== 'riskOptions'
    ) || []),
    isRiskConfigured ? riskAssociationAvailable : riskAssociationNone,
    {
      key: 'riskOptions',
      riskOptions:
        riskyOptions.length > 0
          ? riskyOptions
          : attributes?.map(({ id, sensitivityLabel }) => {
              const risk = riskOptions.find(
                ({ displayName }) => displayName.toLowerCase() === sensitivityLabel.toLowerCase()
              )
              return {
                id,
                value: risk?.id
              }
            }) || []
    }
  ]
  const specialAttributeProps = [
    ...((specialElementProperties || []).filter(
      ({ key }) => key !== 'riskAssociated' && key !== 'riskOptions'
    ) || []),
    isRiskConfigured ? riskAssociationAvailable : riskAssociationNone,
    {
      key: 'riskOptions',
      riskOptions:
        SPECIAL_ATTRIBUTES.map(({ name }) => {
          return {
            id: name,
            value: riskSeverityHigh || riskSeverityNone || ''
          }
        }) || []
    }
  ]

  return { attributeProperties: attributeProps, specialElementProperties: specialAttributeProps }
}
const transformDataSubjectsProperties = (
  questionnaire: PiaQuestionnaireV2,
  subjectCategoriesList,
  subjectSpecialCategoriesList,
  legalBasisList
) => {
  const { dataSubjects } = questionnaire || {}
  const {
    dataSubjectProperties = [],
    legalBasisProperties = [],
    specialDataSubjectProperties = [],
    specialDataSubjectsProperties = []
  } = dataSubjects || {}
  const dataSubjectProps = transformProperties(dataSubjectProperties, subjectCategoriesList)
  const legalBasisProps = transformProperties(legalBasisProperties, legalBasisList)
  const specialDataSubjectsProps = transformProperties(
    specialDataSubjectsProperties,
    subjectSpecialCategoriesList
  )
  const specialDataSubjectProps = transformRadioProperties(specialDataSubjectProperties)

  return {
    dataSubjectProperties: dataSubjectProps,
    legalBasisProperties: legalBasisProps,
    specialDataSubjectsProperties: specialDataSubjectsProps,
    specialDataSubjectProperties: specialDataSubjectProps
  }
}
const transformDataRetentionProperties = (
  questionnaire: PiaQuestionnaireV2,
  durations,
  attributeSetList
) => {
  const { dataRetention } = questionnaire || {}
  const { durationTypeProperties = [], attributeSetProperties = [], triggerEventProperties = [] } =
    dataRetention || {}

  const attrSetProps = attributeSetProperties.find(({ key }) => key === 'options')
    ? attributeSetProperties
    : [{ key: 'options', value: attributeSetList.map(({ id }) => id) }, ...attributeSetProperties]

  const attributeSetProps = transformProperties(attrSetProps, attributeSetList)
  const durationTypeProps = transformProperties(durationTypeProperties, durations)

  return {
    triggerEventProperties,
    durationTypeProperties: durationTypeProps,
    attributeSetProperties: attributeSetProps
  }
}

type transformQuestionnairePropertiesArgs = {
  attributeSetList: Array<{ id: string }>
} & Record<string, any>

export const transformQuestionnaireProperties = (
  questionnaire: PiaQuestionnaireV2,
  {
    departmentsList,
    purposes,
    legalBasisList,
    subjectCategoriesList,
    subjectSpecialCategoriesList,
    safetyMeasures,
    attributes,
    riskOptions,
    durations,
    attributeSetList
  }: transformQuestionnairePropertiesArgs
) => {
  const { processDetails, dataElements, dataSubjects, safeguards, dataRetention, transfers } =
    questionnaire || {}
  const {
    processGroupsProperties = [],
    purposeProperties = [],
    automatedProcessingProperties = [],
    lawfulBasisProperties = []
  } = processDetails || {}
  const { safeguardProperties = [] } = safeguards || {}
  const processGroupsProps = transformProperties(processGroupsProperties, departmentsList)
  const purposeProps = transformProperties(purposeProperties, purposes)
  const lawfulBasisProps = transformProperties(lawfulBasisProperties, legalBasisList)
  const automatedProcessingProps = transformRadioProperties(automatedProcessingProperties)

  const processDetailsDefaultProps = {
    processGroupsProperties: processGroupsProps,
    automatedProcessingProperties: automatedProcessingProps,
    purposeProperties: purposeProps,
    lawfulBasisProperties: lawfulBasisProps
  }
  const dataSubjectsDefaultProps = transformDataSubjectsProperties(
    questionnaire,
    subjectCategoriesList,
    subjectSpecialCategoriesList,
    legalBasisList
  )
  const dataTransfersDefaultProps = transformDataTransfersProperties(questionnaire, safetyMeasures)
  const dataRetentionDefaultProps = transformDataRetentionProperties(
    questionnaire,
    durations,
    attributeSetList
  )
  const dataElementsDefaultProps = transformDataElementsProperties(
    questionnaire,
    attributes,
    riskOptions
  )
  const dataElementsTransformmed = dataElements
    ? {
        dataElements: {
          ...dataElements,
          ...dataElementsDefaultProps
        }
      }
    : {}
  const dataSubjectsTransformmed = dataSubjects
    ? {
        dataSubjects: {
          ...dataSubjects,
          ...dataSubjectsDefaultProps
        }
      }
    : {}
  const safeguardsTransformmed = safeguards
    ? {
        safeguards: {
          ...safeguards,
          safeguardProperties: transformProperties(safeguardProperties, safetyMeasures)
        }
      }
    : {}
  const transfersTransformmed = transfers
    ? {
        transfers: {
          ...transfers,
          ...dataTransfersDefaultProps
        }
      }
    : {}
  const retentionTransformmed = dataRetention
    ? {
        dataRetention: {
          ...dataRetention,
          ...dataRetentionDefaultProps
        }
      }
    : {}

  const questionnaireTransformmed = {
    processDetails: { ...processDetails, ...processDetailsDefaultProps },
    ...dataElementsTransformmed,
    ...dataSubjectsTransformmed,
    ...safeguardsTransformmed,
    ...transfersTransformmed,
    ...retentionTransformmed
  }

  return questionnaireTransformmed
}

type transformQuestionnaireArgs = {
  attributeSetList: Array<{ id: string }>
} & Record<string, any>
export const getDefaultProcess = (
  mapKeyToTranslation,
  {
    departmentsList,
    purposes,
    legalBasisList,
    subjectCategoriesList,
    subjectSpecialCategoriesList,
    attributeSetList,
    safetyMeasures,
    attributes,
    durations,
    riskOptions
  }: transformQuestionnaireArgs
) => {
  const riskAssociationNone = {
    key: 'riskAssociated',
    value: ['false']
  }
  const riskSeverityNone = riskOptions.find(({ value }) => value === 0)?.id
  const riskSeverityHigh = riskOptions.find(
    ({ displayName = '' }) => displayName.toLowerCase() === SENSITIVE_LABEL.HIGH.toLowerCase()
  )?.id

  return {
    processDetails: {
      processingActivityDetails: [
        {
          managerType: ProcessorEnum.Controller,
          details: [EMPTY_PROCESSOR_DETAILS]
        }
      ]
    },
    questionnaire: {
      processDetails: {
        optional: false,
        questions: [],
        processDetailsQuestionProperties: {
          nameProperties: [
            {
              key: 'label',
              value: [mapKeyToTranslation('ropa.defineProcess.name')]
            },
            {
              key: 'optional',
              value: ['false']
            },
            {
              key: 'isDeleted',
              value: ['false']
            }
          ],
          emailProperties: [
            {
              key: 'label',
              value: [mapKeyToTranslation('ropa.defineProcess.email')]
            },
            {
              key: 'optional',
              value: ['false']
            },
            {
              key: 'isDeleted',
              value: ['false']
            }
          ],
          addressProperties: [
            {
              key: 'label',
              value: [mapKeyToTranslation('ropa.defineProcess.address')]
            },
            {
              key: 'optional',
              value: ['true']
            },
            {
              key: 'isDeleted',
              value: ['false']
            }
          ],
          contactProperties: [
            {
              key: 'label',
              value: [mapKeyToTranslation('ropa.defineProcess.contact')]
            },
            {
              key: 'optional',
              value: ['true']
            },
            {
              key: 'isDeleted',
              value: ['false']
            }
          ]
        },
        processDetailsProperties: [
          {
            key: 'name',
            value: [mapKeyToTranslation('ropa.stepMenu.processDetails')]
          },
          {
            key: 'description',
            value: [mapKeyToTranslation('ropa.stepMenu.processDetails.help')]
          },
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.defineProcess.controllers.info.help')]
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'isDeleted',
            value: ['false']
          }
        ],
        nameProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.defineProcess.processName')]
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'isDeleted',
            value: ['false']
          }
        ],
        descriptionProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('processDefinitions.field.description')]
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'isDeleted',
            value: ['false']
          }
        ],
        processGroupsProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('process.definitionType.processGroup')]
          },
          {
            key: 'options',
            value: departmentsList?.map(({ id }) => id) || []
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'isDeleted',
            value: ['false']
          },
          riskAssociationNone,
          {
            key: 'riskOptions',
            riskOptions: departmentsList?.map(({ id }) => ({ id })) || []
          }
        ],
        automatedProcessingDescriptionProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.defineProcess.automatedProcessing.description')]
          },
          {
            key: 'optional',
            value: ['true']
          },
          {
            key: 'isDeleted',
            value: ['false']
          }
        ],
        automatedProcessingProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.defineProcess.automatedProcessing')]
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'isDeleted',
            value: ['false']
          },
          riskAssociationNone,
          {
            key: 'riskOptions',
            riskOptions: [{ id: false }, { id: true }]
          }
        ],
        purposeProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.defineProcess.purpose')]
          },
          {
            key: 'options',
            value: purposes?.map(({ id }) => id) || []
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'isDeleted',
            value: ['false']
          },
          riskAssociationNone,
          {
            key: 'riskOptions',
            riskOptions: purposes?.map(({ id }) => ({ id })) || []
          }
        ],
        lawfulBasisProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.defineProcess.lawfulBasis')]
          },
          {
            key: 'options',
            value: legalBasisList?.map(({ id }) => id) || []
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'isDeleted',
            value: ['false']
          },
          riskAssociationNone,
          {
            key: 'riskOptions',
            riskOptions: legalBasisList?.map(({ id }) => ({ id })) || []
          }
        ]
      },
      dataElements: {
        name: mapKeyToTranslation('ropa.stepMenu.dataElements'),
        description: mapKeyToTranslation('ropa.stepMenu.dataElements.help'),
        optional: false,
        attributeProperties: [
          riskAssociationNone,
          {
            key: 'riskOptions',
            riskOptions:
              attributes?.map(({ id, sensitivityLabel }) => {
                const risk = riskOptions.find(
                  ({ displayName }) => displayName.toLowerCase() === sensitivityLabel.toLowerCase()
                )

                return {
                  id,
                  value: risk?.id
                }
              }) || []
          }
        ],
        specialElementProperties: [
          riskAssociationNone,
          {
            key: 'riskOptions',
            riskOptions:
              SPECIAL_ATTRIBUTES.map(({ name }) => {
                return {
                  id: name,
                  value: riskSeverityHigh || riskSeverityNone || ''
                }
              }) || []
          }
        ]
      },
      dataRetention: {
        name: mapKeyToTranslation('ropa.stepMenu.dataRetention'),
        description: mapKeyToTranslation('ropa.dataRetention.help'),
        optional: false,
        dataRetentionProperties: [riskAssociationNone],
        durationTypeProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.dataRetention.modal.form.timeDuration')]
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'options',
            value: durations?.map(({ id }) => id) || []
          },
          {
            key: 'isDeleted',
            value: ['false']
          },
          riskAssociationNone,
          {
            key: 'riskOptions',
            riskOptions: durations?.map(({ id }) => ({ id })) || []
          }
        ],
        triggerEventProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.dataRetention.modal.form.triggerEvent')]
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'isDeleted',
            value: ['false']
          }
        ],
        attributeSetProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.dataRetention.modal.form.scope')]
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'options',
            value: attributeSetList?.map(({ id }) => id) || []
          },
          {
            key: 'isDeleted',
            value: ['false']
          },
          riskAssociationNone,
          {
            key: 'riskOptions',
            riskOptions: attributeSetList?.map(({ id }) => ({ id })) || []
          }
        ]
      },
      dataSubjects: {
        optional: false,
        name: mapKeyToTranslation('ropa.stepMenu.dataSubjects'),
        description: mapKeyToTranslation('ropa.stepMenu.dataSubjects.help'),
        questions: [],
        dataSubjectProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.questionnaire.field.dataSubjects')]
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'options',
            value: subjectCategoriesList?.map(({ id }) => id) || []
          },
          {
            key: 'isDeleted',
            value: ['false']
          },
          riskAssociationNone,
          {
            key: 'riskOptions',
            riskOptions: subjectCategoriesList?.map(({ id }) => ({ id })) || []
          }
        ],
        specialDataSubjectProperties: [
          {
            key: 'label',
            value: ['Special Data subjects']
          },
          {
            key: 'optional',
            value: ['false']
          },
          {
            key: 'isDeleted',
            value: ['false']
          },
          riskAssociationNone,
          {
            key: 'riskOptions',
            riskOptions: [{ id: false }, { id: true }]
          }
        ],
        specialDataSubjectsProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.questionnaire.field.selectSpecialDataSubjects')]
          },
          { key: 'optional', value: ['false'] },
          {
            key: 'options',
            value: subjectSpecialCategoriesList?.map(({ id }) => id) || []
          },
          {
            key: 'isDeleted',
            value: ['false']
          },
          riskAssociationNone,
          {
            key: 'riskOptions',
            riskOptions:
              subjectSpecialCategoriesList?.map(({ id }) => ({
                id
              })) || []
          }
        ],
        legalBasisProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.questionnaire.field.specialLegalBasis')]
          },
          { key: 'optional', value: ['false'] },
          {
            key: 'options',
            value: legalBasisList?.map(({ id }) => id) || []
          },
          {
            key: 'isDeleted',
            value: ['false']
          },
          riskAssociationNone,
          {
            key: 'riskOptions',
            riskOptions:
              legalBasisList?.map(({ id }) => ({
                id
              })) || []
          }
        ]
      },
      safeguards: {
        name: mapKeyToTranslation('ropa.stepMenu.safeguards'),
        description: mapKeyToTranslation('ropa.stepMenu.safeguards.description'),
        optional: false,
        questions: [],
        safeguardProperties: [
          {
            key: 'label',
            value: [mapKeyToTranslation('ropa.stepMenu.safeguards')]
          },
          { key: 'optional', value: ['false'] },
          {
            key: 'options',
            value: safetyMeasures?.map(({ id }) => id) || []
          },
          {
            key: 'isDeleted',
            value: ['false']
          },
          riskAssociationNone,
          {
            key: 'riskOptions',
            riskOptions: safetyMeasures?.map(({ id }) => ({ id })) || []
          }
        ]
      },
      transfers: {
        name: mapKeyToTranslation('ropa.stepMenu.transfers'),
        description: mapKeyToTranslation('ropa.stepMenu.transfers.description'),
        optional: false,
        questions: [],
        thirdParty: {
          thirdPartyProperties: [
            {
              key: 'label',
              value: [mapKeyToTranslation('ropa.transfers.thirdPartyLabel')]
            },
            { key: 'optional', value: ['false'] },
            {
              key: 'isDeleted',
              value: ['false']
            },
            riskAssociationNone,
            {
              key: 'riskOptions',
              riskOptions: [{ id: false }, { id: true }]
            }
          ],
          details: [
            {
              name: '',
              nameProperties: [
                {
                  key: 'label',
                  value: [mapKeyToTranslation('ropa.defineProcess.name')]
                },
                {
                  key: 'optional',
                  value: ['false']
                },
                {
                  key: 'isDeleted',
                  value: ['false']
                }
              ],
              email: '',
              emailProperties: [
                {
                  key: 'label',
                  value: [mapKeyToTranslation('ropa.defineProcess.email')]
                },
                {
                  key: 'optional',
                  value: ['false']
                },
                {
                  key: 'isDeleted',
                  value: ['false']
                }
              ],
              address: '',
              addressProperties: [
                {
                  key: 'label',
                  value: [mapKeyToTranslation('ropa.questionnaire.address')]
                },
                {
                  key: 'optional',
                  value: ['true']
                },
                {
                  key: 'isDeleted',
                  value: ['false']
                }
              ],
              safeguards: [],
              safeguardsProperties: [
                {
                  key: 'label',
                  value: [mapKeyToTranslation('ropa.questionnaire.safeguards')]
                },
                {
                  key: 'options',
                  value: safetyMeasures?.map(({ id }) => id) || []
                },
                {
                  key: 'optional',
                  value: ['false']
                },
                {
                  key: 'isDeleted',
                  value: ['false']
                },
                riskAssociationNone,
                {
                  key: 'riskOptions',
                  riskOptions: safetyMeasures?.map(({ id }) => ({ id })) || []
                }
              ]
            }
          ]
        },
        crossBorder: {
          crossBorderProperties: [
            {
              key: 'label',
              value: [mapKeyToTranslation('ropa.transfers.crossBorderLabel')]
            },
            { key: 'optional', value: ['false'] },
            {
              key: 'isDeleted',
              value: ['false']
            },
            riskAssociationNone,
            {
              key: 'riskOptions',
              riskOptions: [{ id: false }, { id: true }]
            }
          ],
          details: [
            {
              organisation: '',
              organisationProperties: [
                {
                  key: 'label',
                  value: [mapKeyToTranslation('ropa.defineProcess.companyName')]
                },
                {
                  key: 'optional',
                  value: ['false']
                },
                {
                  key: 'isDeleted',
                  value: ['false']
                }
              ],
              email: '',
              emailProperties: [
                {
                  key: 'label',
                  value: [mapKeyToTranslation('ropa.defineProcess.email')]
                },
                {
                  key: 'optional',
                  value: ['false']
                },
                {
                  key: 'isDeleted',
                  value: ['false']
                }
              ],
              country: '',
              countryProperties: [
                {
                  key: 'label',
                  value: [mapKeyToTranslation('ropa.defineProcess.country')]
                },

                {
                  key: 'optional',
                  value: ['false']
                },
                {
                  key: 'isDeleted',
                  value: ['false']
                }
              ],
              safeguards: [],
              safeguardsProperties: [
                {
                  key: 'label',
                  value: [mapKeyToTranslation('ropa.questionnaire.safeguards')]
                },
                {
                  key: 'options',
                  value: safetyMeasures?.map(({ id }) => id) || []
                },
                {
                  key: 'optional',
                  value: ['false']
                },
                {
                  key: 'isDeleted',
                  value: ['false']
                },
                riskAssociationNone,
                {
                  key: 'riskOptions',
                  riskOptions: safetyMeasures?.map(({ id }) => ({ id })) || []
                }
              ]
            }
          ]
        }
      }
    }
  }
}

export function uniq(a) {
  const seen = {}
  return a.filter(function (item) {
    return seen?.[item] ? false : (seen[item] = true)
  })
}

const objectIdPattern = /^[0-9a-fA-F]{24}$/
export const isObjectId = (object, key?) =>
  typeof object === 'string' ? objectIdPattern.test(object) : objectIdPattern.test(object?.[key])

export const getProcessStats = (ropaProcess) => {
  const ropaTopics = ropaProcess?.ropaTopics
  let totalQuestions = 0
  let totalQuestionsAnswered = 0

  if (isFieldAvailable(ropaProcess, 'nameProperties')) {
    totalQuestions++
    if ((ropaProcess.name || '')?.trim()) totalQuestionsAnswered++
  }
  if (isFieldAvailable(ropaProcess, 'descriptionProperties')) {
    totalQuestions++
    if ((ropaProcess.description || '')?.trim()) totalQuestionsAnswered++
  }
  if (isFieldAvailable(ropaProcess, 'purposeProperties')) {
    totalQuestions++
    if (ropaProcess.purpose?.count) totalQuestionsAnswered++
  }
  if (isFieldAvailable(ropaProcess, 'processGroupsProperties')) {
    totalQuestions++
    if (ropaProcess.processGroups?.count) totalQuestionsAnswered++
  }
  if (isFieldAvailable(ropaProcess, 'automatedProcessingProperties')) {
    totalQuestions++
    if (ropaProcess.automatedProcessing === true || ropaProcess.automatedProcessing === false)
      totalQuestionsAnswered++

    if (isFieldAvailable(ropaProcess, 'automatedProcessingDescriptionProperties')) {
      totalQuestions++
      if (ropaProcess.automatedProcessingDescription) totalQuestionsAnswered++
    }
  }
  if (isFieldAvailable(ropaProcess, 'lawfulBasisProperties')) {
    totalQuestions++
    if (ropaProcess.lawfulBasis?.count) totalQuestionsAnswered++
  }

  const totalProcessQuestions = ropaProcess?.processQuestions?.count || 0
  if (totalProcessQuestions > 0) {
    totalQuestions += totalProcessQuestions
    ropaProcess?.processQuestions?.edges?.forEach(({ node }) => {
      if (node.questionResponse?.length) totalQuestionsAnswered++
    })
  }

  ropaProcess.processDetails?.processingActivityDetails?.forEach(({ details }) => {
    details.forEach((detail) => {
      if (isFieldAvailable(detail, 'nameProperties')) {
        totalQuestions++
        detail.name && totalQuestionsAnswered++
      }
      if (isFieldAvailable(detail, 'emailProperties')) {
        totalQuestions++
        detail.email && totalQuestionsAnswered++
      }
      if (isFieldAvailable(detail, 'contactProperties')) {
        totalQuestions++
        detail.contact && totalQuestionsAnswered++
      }
      if (isFieldAvailable(detail, 'addressProperties')) {
        totalQuestions++
        detail?.address && totalQuestionsAnswered++
      }
    })
  })

  //data elements
  const dataElements = ropaTopics?.dataElements
  if (dataElements) {
    totalQuestions += 3 //3 hardcoded questions From Data Elements (cannot be deleted)
    if (dataElements?.associatedDatasources?.count) {
      totalQuestionsAnswered++
    }
    if (dataElements?.specialElements?.length) {
      totalQuestionsAnswered++
    }
    if (dataElements?.attributes?.count) {
      totalQuestionsAnswered++
    }
    const dataElementsTotalCustomQuestions = dataElements?.questions?.count || 0
    if (dataElementsTotalCustomQuestions) {
      totalQuestions += dataElementsTotalCustomQuestions
      totalQuestionsAnswered +=
        dataElements?.questions?.edges?.map(({ node }) => (node.questionResponse?.length || 0) > 0)
          .length || 0
    }
  }

  //data Subjects
  const dataSubjects = ropaTopics?.dataSubjects
  if (dataSubjects) {
    if (isFieldAvailable(dataSubjects, 'dataSubjectProperties')) {
      totalQuestions++
      if (dataSubjects?.dataSubjects?.count) totalQuestionsAnswered++
    }
    if (isFieldAvailable(dataSubjects, 'specialDataSubjectsProperties')) {
      //add an additional 1 considering radio button as a question
      totalQuestions += 2
      if (dataSubjects?.specialDataSubjects?.count) totalQuestionsAnswered += 2
    }
    if (isFieldAvailable(dataSubjects, 'legalBasisProperties')) {
      totalQuestions++
      if (dataSubjects?.legalBasis?.count) totalQuestionsAnswered++
    }
    const dataSubjectsTotalCustomQuestions = dataSubjects?.questions?.count || 0
    if (dataSubjectsTotalCustomQuestions > 0) {
      totalQuestions += dataSubjectsTotalCustomQuestions
      totalQuestionsAnswered +=
        dataSubjects?.questions?.edges?.map(({ node }) => (node.questionResponse?.length || 0) > 0)
          .length || 0
    }
  }

  //data retention
  const dataRetention = ropaTopics?.dataRetention
  if (dataRetention) {
    totalQuestions += 1 //1 hardcoded question for retention
    const retentionTotalCustomQuestions = dataRetention?.questions?.count || 0
    if (retentionTotalCustomQuestions) {
      totalQuestions++
      totalQuestionsAnswered +=
        dataRetention?.questions?.edges?.map(({ node }) => (node.questionResponse?.length || 0) > 0)
          .length || 0
    }
  }

  //safeguards
  const safeguards = ropaTopics?.safeguards
  if (safeguards) {
    if (isFieldAvailable(safeguards, 'safeguardProperties')) {
      totalQuestions++
      if (safeguards?.safeguards?.count) totalQuestionsAnswered++
    }
    const safeguardsTotalCustomQuestions = safeguards?.questions?.count || 0
    if (safeguardsTotalCustomQuestions) {
      totalQuestions++
      totalQuestionsAnswered +=
        safeguards?.questions?.edges?.map(({ node }) => (node.questionResponse?.length || 0) > 0)
          .length || 0
    }
  }

  //transfers
  const transfers = ropaTopics?.transfers
  if (transfers) {
    const thirdParty = transfers?.thirdParty
    if (thirdParty?.isThirdParty) {
      totalQuestions++
      totalQuestionsAnswered++
      thirdParty?.details?.map((detail) => {
        const { name, email, address, safeguards } = detail
        if (isFieldAvailable(detail, 'nameProperties')) {
          totalQuestions++
          if (name.trim()) {
            totalQuestionsAnswered++
          }
          if (email.trim()) {
            totalQuestionsAnswered++
          }
          if (address.trim()) {
            totalQuestionsAnswered++
          }
          if (safeguards?.length) {
            totalQuestionsAnswered++
          }
        }
        if (isFieldAvailable(detail, 'emailProperties')) {
          totalQuestions++
        }
        if (isFieldAvailable(detail, 'addressProperties')) {
          totalQuestions++
        }
        if (isFieldAvailable(detail, 'safeguardsProperties')) {
          totalQuestions++
        }
      })
    }

    const crossBorder = transfers?.crossBorder
    if (crossBorder?.isCrossBorder) {
      totalQuestions++
      totalQuestionsAnswered++
      crossBorder?.details?.map((detail) => {
        const { organisation, email, country, safeguards } = detail
        if (isFieldAvailable(detail, 'emailProperties')) {
          totalQuestions++
          if (email.trim()) {
            totalQuestionsAnswered++
          }
          if (country.trim()) {
            totalQuestionsAnswered++
          }
          if (organisation.trim()) {
            totalQuestionsAnswered++
          }
          if (safeguards?.length) {
            totalQuestionsAnswered++
          }
        }
        if (isFieldAvailable(detail, 'organisationProperties')) {
          totalQuestions++
        }
        if (isFieldAvailable(detail, 'countryProperties')) {
          totalQuestions++
        }
        if (isFieldAvailable(detail, 'safeguardsProperties')) {
          totalQuestions++
        }
      })
    }
  }

  //custom topics
  ropaTopics?.customTopics.map((topic) => {
    totalQuestions += topic.questions?.count || 0
    totalQuestionsAnswered +=
      topic.questions?.edges?.filter(({ node }) => (node.questionResponse?.length || 0) > 0)
        .length || 0
  })

  return {
    percentage: (totalQuestionsAnswered / totalQuestions) * 100,
    totalQuestions,
    totalQuestionsAnswered
  }
}

export const getCloneProcessPayload = (process: PiaProcess, intl) => {
  const clonedProcess = JSON.parse(
    JSON.stringify({
      ...process,
      id: '',
      owner: ''
    })
  ) as PiaProcess
  if (process.name) {
    clonedProcess.name = process.name + ` ${intl.formatMessage({ id: 'ropa.process.text.copy' })}`
  }
  if (process.description) {
    clonedProcess.description =
      process.description + ` ${intl.formatMessage({ id: 'ropa.process.text.copy' })}`
  }
  if (process.questionnaire?.processDetails && clonedProcess.questionnaire?.processDetails) {
    clonedProcess.questionnaire.processDetails = {
      ...clonedProcess.questionnaire.processDetails,
      answeredQuestions: 0,
      collaboratorDetails: []
    }
  }
  if (process.questionnaire?.dataElements && clonedProcess.questionnaire?.dataElements) {
    clonedProcess.questionnaire.dataElements = {
      ...clonedProcess.questionnaire.dataElements,
      answeredQuestions: 0,
      collaboratorDetails: []
    }
  }
  if (process.questionnaire?.dataSubjects && clonedProcess.questionnaire?.dataSubjects) {
    clonedProcess.questionnaire.dataSubjects = {
      ...clonedProcess.questionnaire.dataSubjects,
      answeredQuestions: 0,
      collaboratorDetails: []
    }
  }
  if (process.questionnaire?.dataRetention && clonedProcess.questionnaire?.dataRetention) {
    clonedProcess.questionnaire.dataRetention = {
      ...clonedProcess.questionnaire.dataRetention,
      answeredQuestions: 0,
      collaboratorDetails: []
    }
  }
  if (process.questionnaire?.safeguards && clonedProcess.questionnaire?.safeguards) {
    clonedProcess.questionnaire.safeguards = {
      ...clonedProcess.questionnaire.safeguards,
      answeredQuestions: 0,
      collaboratorDetails: []
    }
  }
  if (process.questionnaire?.transfers && clonedProcess.questionnaire?.transfers) {
    clonedProcess.questionnaire.transfers = {
      ...clonedProcess.questionnaire.transfers,
      crossBorder: {
        ...clonedProcess.questionnaire.transfers.crossBorder
      },
      thirdParty: {
        ...clonedProcess.questionnaire.transfers.thirdParty
      },
      answeredQuestions: 0,
      collaboratorDetails: []
    }
  }
  if (process.questionnaire?.customTopics?.length && clonedProcess.questionnaire?.customTopics) {
    clonedProcess.questionnaire.customTopics = clonedProcess.questionnaire.customTopics.map(
      (topic) => {
        topic.questions = topic.questions?.map((q) => ({
          ...q,
          answeredQuestions: 0,
          collaboratorDetails: []
        }))
        return topic
      }
    )
  }
  if (
    process.processDetails?.processingActivityDetails?.length &&
    clonedProcess?.processDetails?.processingActivityDetails?.length
  ) {
    clonedProcess.processDetails.processingActivityDetails = [
      {
        managerType: ProcessorEnum.Controller,
        details: clonedProcess.processDetails?.processingActivityDetails?.[0].details || []
      }
    ]
  }
  if (
    process.questionnaire?.processDetails?.processQuestions?.length &&
    clonedProcess.questionnaire?.processDetails?.processQuestions?.length
  ) {
    clonedProcess.questionnaire.processDetails.processQuestions =
      clonedProcess.questionnaire.processDetails?.processQuestions || []
  }
  return clonedProcess
}

export const getCustomQuestionsAndAnswersCount = (customQuestions?: PiaQuestion[]) => {
  let questions = 0,
    answers = 0
  customQuestions?.forEach((quest) => {
    questions += 1
    if (quest.questionResponse?.length) answers += 1
  })
  return { questions, answers }
}

type TotalQuestionReturnType = { totalQuestions: number; totalQuestionsAnswered: number }

export const getTotalProcessDetailsQuestions = (
  ropaProcess: PiaProcess
): TotalQuestionReturnType => {
  let totalQuestions = 0
  let totalQuestionsAnswered = 0
  if (isFieldAvailableAndRequired(ropaProcess.questionnaire?.processDetails, 'nameProperties')) {
    totalQuestions++
    if ((ropaProcess.name || '')?.trim()) totalQuestionsAnswered++
  }
  if (isFieldAvailableAndRequired(ropaProcess.questionnaire?.processDetails, 'purposeProperties')) {
    totalQuestions++
    if (ropaProcess.purpose?.length) totalQuestionsAnswered++
  }
  if (
    isFieldAvailableAndRequired(
      ropaProcess.questionnaire?.processDetails,
      'processGroupsProperties'
    )
  ) {
    totalQuestions++
    if (ropaProcess.processGroups?.length) totalQuestionsAnswered++
  }
  if (
    isFieldAvailableAndRequired(
      ropaProcess.questionnaire?.processDetails,
      'automatedProcessingProperties'
    )
  ) {
    totalQuestions++
    if (ropaProcess.automatedProcessing === true || ropaProcess.automatedProcessing === false)
      totalQuestionsAnswered++
  }
  if (
    isFieldAvailableAndRequired(ropaProcess.questionnaire?.processDetails, 'lawfulBasisProperties')
  ) {
    totalQuestions++
    if (ropaProcess.lawfulBasis?.length) totalQuestionsAnswered++
  }

  const { questions, answers } = getCustomQuestionsAndAnswersCount(
    ropaProcess.questionnaire?.processDetails?.processQuestions
  )
  totalQuestions += questions
  totalQuestionsAnswered += answers
  if (
    isFieldAvailableAndRequired(
      ropaProcess.questionnaire?.processDetails,
      'processDetailsProperties'
    )
  ) {
    totalQuestions++
    totalQuestionsAnswered++

    ropaProcess.processDetails?.processingActivityDetails?.forEach(({ details }) => {
      details.forEach((detail) => {
        if (
          isFieldAvailableAndRequired(
            ropaProcess?.questionnaire?.processDetails?.processDetailsQuestionProperties,
            'nameProperties'
          )
        ) {
          totalQuestions++
          detail.name && totalQuestionsAnswered++
        }
        if (
          isFieldAvailableAndRequired(
            ropaProcess?.questionnaire?.processDetails?.processDetailsQuestionProperties,
            'emailProperties'
          )
        ) {
          totalQuestions++
          detail.email && totalQuestionsAnswered++
        }
        if (
          isFieldAvailableAndRequired(
            ropaProcess?.questionnaire?.processDetails?.processDetailsQuestionProperties,
            'contactProperties'
          )
        ) {
          totalQuestions++
          detail.contact && totalQuestionsAnswered++
        }
        if (
          isFieldAvailableAndRequired(
            ropaProcess?.questionnaire?.processDetails?.processDetailsQuestionProperties,
            'addressProperties'
          )
        ) {
          totalQuestions++
          detail?.address && totalQuestionsAnswered++
        }
      })
    })
  }

  return { totalQuestions, totalQuestionsAnswered }
}

export const mapTopicsToQuestionnaire = (process: PiaProcess, node) => {
  process.questionnaire = process.questionnaire || {}
  if (node?.ropaTopics?.dataElements) {
    process.questionnaire.dataElements = {
      lawfulBasisProperties: node?.ropaTopics?.dataElements?.lawfulBasisProperties || [],
      attributeProperties: node?.ropaTopics?.dataElements?.attributeProperties || [],
      specialElementProperties: node?.ropaTopics?.dataElements?.specialElementProperties || [],
      optional: node?.ropaTopics?.dataElements?.optional,
      name: node?.ropaTopics?.dataElements?.name,
      description: node?.ropaTopics?.dataElements?.description,
      questions: node?.ropaTopics?.dataElements?.questions?.edges?.map((edge) => edge?.node) || [],
      attributes:
        node?.ropaTopics?.dataElements?.attributes?.edges?.map(({ node }) => node.id) || [],
      specialElements:
        node?.ropaTopics?.dataElements?.specialElements?.map((specialElement) => ({
          attributeName: specialElement.attributeName,
          sensitivity: specialElement.sensitivity
        })) || [],
      associatedDatasources:
        node?.ropaTopics?.dataElements?.associatedDatasources?.edges?.map(({ node }) => node.id) ||
        [],
      lawfulBases:
        node?.ropaTopics?.dataElements?.lawfulBases?.edges?.map(({ node }) => node.id) || [],
      id: node?.ropaTopics?.dataElements?.id || ''
    }
  }
  if (node?.ropaTopics?.dataSubjects) {
    process.questionnaire.dataSubjects = {
      dataSubjectProperties: node?.ropaTopics?.dataSubjects?.dataSubjectProperties || [],
      specialDataSubjectProperties:
        node?.ropaTopics?.dataSubjects?.specialDataSubjectProperties || [],
      specialDataSubjectsProperties:
        node?.ropaTopics?.dataSubjects?.specialDataSubjectsProperties || [],
      legalBasisProperties: node?.ropaTopics?.dataSubjects?.legalBasisProperties || [],
      optional: node?.ropaTopics?.dataSubjects?.optional,
      name: node?.ropaTopics?.dataSubjects?.name,
      description: node?.ropaTopics?.dataSubjects?.description,
      questions: node?.ropaTopics?.dataSubjects?.questions?.edges?.map((edge) => edge?.node) || [],
      categories:
        node?.ropaTopics?.dataSubjects?.dataSubjects?.edges?.map(({ node }) => node.id) || [],
      specialCategories:
        node?.ropaTopics?.dataSubjects?.specialDataSubjects?.edges?.map(({ node }) => node.id) ||
        [],
      lawfulBasis:
        node?.ropaTopics?.dataSubjects?.legalBases?.edges?.map(({ node }) => node.id) || [],
      specialDataSubject: node?.ropaTopics?.dataSubjects?.specialDataSubject,
      id: node?.ropaTopics?.dataSubjects?.id || ''
    }
  }
  if (node?.ropaTopics?.dataRetention) {
    process.questionnaire.dataRetention = {
      dataRetentionProperties: node?.ropaTopics?.dataRetention?.dataRetentionProperties || [],
      durationTypeProperties: node?.ropaTopics?.dataRetention?.durationTypeProperties || [],
      attributeSetProperties: node?.ropaTopics?.dataRetention?.attributeSetProperties || [],
      triggerEventProperties: node?.ropaTopics?.dataRetention?.triggerEventProperties || [],
      optional: node?.ropaTopics?.dataRetention?.optional,
      name: node?.ropaTopics?.dataRetention?.name,
      description: node?.ropaTopics?.dataRetention?.description,
      questions: node?.ropaTopics?.dataRetention?.questions?.edges?.map((edge) => edge?.node) || [],
      details:
        node?.ropaTopics?.dataRetention?.dataRetentionInfo?.edges?.map(({ node }) => ({
          id: node.id,
          durationType: node.durationType,
          durationCount: node.durationCount,
          triggerEvent: node.triggerEvent,
          attributeSets: node.attributeSet?.edges?.map(({ node }) => node.id) || []
        })) || [],
      id: node?.ropaTopics?.dataRetention?.id || ''
    }
  }
  if (node?.ropaTopics?.safeguards) {
    process.questionnaire.safeguards = {
      safeguardProperties: node?.ropaTopics?.safeguards?.safeguardProperties || [],
      optional: node?.ropaTopics?.safeguards?.optional,
      name: node?.ropaTopics?.safeguards?.name,
      description: node?.ropaTopics?.safeguards?.description,
      questions: node?.ropaTopics?.safeguards?.questions?.edges?.map((edge) => edge?.node) || [],
      safeguards: node?.ropaTopics?.safeguards?.safeguards?.edges?.map(({ node }) => node.id) || [],
      id: node?.ropaTopics?.safeguards?.id || ''
    }
  }
  if (node?.ropaTopics?.transfers) {
    process.questionnaire.transfers = {
      optional: node?.ropaTopics?.transfers?.optional,
      name: node?.ropaTopics?.transfers?.name,
      description: node?.ropaTopics?.transfers?.description,
      questions: node?.ropaTopics?.transfers?.questions?.edges?.map((edge) => edge?.node) || [],
      crossBorder: {
        isCrossBorder: node?.ropaTopics?.transfers?.crossBorder?.isCrossBorder,
        crossBorderProperties:
          node?.ropaTopics?.transfers?.crossBorder?.crossBorderProperties || [],
        details: node?.ropaTopics?.transfers?.crossBorder?.details?.map((detail) => ({
          safeguards: detail.safeguards?.edges?.map(({ node }) => node.id),
          safeguardsProperties: detail.safeguardsProperties || [],
          organisation: detail.organisation,
          organisationProperties: detail.organisationProperties || [],
          email: detail.email,
          emailProperties: detail.emailProperties || [],
          country: detail.country,
          countryProperties: detail.countryProperties || []
        }))
      },
      thirdParty: {
        thirdPartyProperties: node?.ropaTopics?.transfers?.thirdParty?.thirdPartyProperties,
        isThirdParty: node?.ropaTopics?.transfers?.thirdParty?.isThirdParty,
        details: node?.ropaTopics?.transfers?.thirdParty?.details?.map((detail) => ({
          safeguards: detail.safeguards?.edges?.map(({ node }) => node.id),
          safeguardsProperties: detail.safeguardsProperties,
          email: detail.email,
          emailProperties: detail.emailProperties,
          name: detail.name,
          nameProperties: detail.nameProperties,
          address: detail.address,
          addressProperties: detail.addressProperties
        }))
      },
      id: node?.ropaTopics?.transfers?.id || ''
    }
  }
  return process
}

export const isQuestionDeleted = (properties) => {
  const property = properties.find(({ key }) => key == 'isDeleted')
  return property?.value?.length ? property.value[0] == 'true' : false
}

export const getPropertyLabelValue = (properties) =>
  (properties || []).find(({ key = '' }) => key == 'label')?.value || ''

export const mapQuestions = (questions) =>
  questions?.map(({ node }) => ({
    question: node?.question || '',
    questionResponse: node?.questionResponse,
    riskAssociated: node?.riskAssociated || false,
    systemRiskValue: node?.systemRiskValue || '',
    overallRiskValue: node?.overallRiskValue || '',
    riskOptions: node?.riskOptions
  }))

export const getPropertyIsMandatory = (properties) =>
  (properties || []).find(({ key = '' }) => key == 'optional')?.value[0] == 'true' || false
export const isFieldOptional = (properties) =>
  (properties || []).find(({ key = '' }) => key == 'optional')?.value === 'true' || ''

export const mapPiaReportTopicsToQuestionnaire = (node) => {
  const filterDeleted = (list) => list.filter(({ isDeleted }) => !isDeleted)

  const questionnaire = {} as PiaReportPrint['topics']
  if (node?.ropaTopics?.dataElements) {
    questionnaire.dataElements = {
      name: node?.ropaTopics?.dataElements?.name,
      attributes:
        node?.ropaTopics?.dataElements?.attributes?.edges?.map(({ node }) => ({
          id: node.id,
          attributeName: node?.name || '',
          attributeSets:
            node?.attributeSets?.edges
              ?.filter(({ node }) => node.enabled)
              ?.map(({ node }) => node.name || '') || [],
          sensitivity: node?.sensitivityLabel || ''
        })) || [],
      attributeProperties: node?.ropaTopics?.dataElements?.attributeProperties,
      specialElements:
        node?.ropaTopics?.dataElements?.specialElements?.map((specialElement) => ({
          attributeName: specialElement.attributeName,
          sensitivity: specialElement.sensitivity
        })) || [],
      specialElementProperties: node?.ropaTopics?.dataElements?.specialElementProperties,
      lawfulBases:
        node?.ropaTopics?.dataElements?.lawfulBases?.edges?.map(({ node }) => node) || [],
      lawfulBasisProperties: node?.ropaTopics?.dataElements?.lawfulBasisProperties,
      questions: node?.ropaTopics?.dataElements?.questions?.edges?.map((edge) => edge?.node) || []
    }
  }
  if (node?.ropaTopics?.dataSubjects) {
    questionnaire.dataSubjects = {
      name: node?.ropaTopics?.dataSubjects?.name || '',
      dataSubjects:
        node?.ropaTopics?.dataSubjects?.dataSubjects?.edges?.map(({ node }) => node) || [],
      specialCategories:
        node?.ropaTopics?.dataSubjects?.specialDataSubjects?.edges?.map(({ node }) => node) || [],
      dataSubjectProperties: node?.ropaTopics?.dataSubjects?.dataSubjectProperties || [],
      dataSubjectLabel: getPropertyLabelValue(
        node?.ropaTopics?.dataSubjects?.dataSubjectProperties || []
      ),
      specialDataSubject: node?.ropaTopics?.dataSubjects?.specialDataSubject,
      specialDataSubjectProperties:
        node?.ropaTopics?.dataSubjects?.specialDataSubjectProperties || [],
      specialDataSubjectsProperties:
        node?.ropaTopics?.dataSubjects?.specialDataSubjectsProperties || [],
      legalBases:
        !node?.ropaTopics?.dataSubjects?.specialDataSubject ||
        isQuestionDeleted(node?.ropaTopics?.dataSubjects?.legalBasisProperties || [])
          ? undefined
          : filterDeleted(node?.ropaTopics?.dataSubjects?.legalBases?.edges || [])?.map(
              ({ node }) => node
            ) || [],
      legalBasesProperties: node?.ropaTopics?.dataSubjects?.legalBasisProperties || [],
      legalBasesLabel: getPropertyLabelValue(
        node?.ropaTopics?.dataSubjects?.legalBasisProperties || []
      ),
      questions: node?.ropaTopics?.dataSubjects?.questions?.edges?.map((edge) => edge?.node) || []
    }
  }
  if (node?.ropaTopics?.dataRetention) {
    questionnaire.dataRetention = {
      name: node?.ropaTopics?.dataRetention?.name || '',
      dataRetentionProperties: node?.ropaTopics?.dataRetention?.dataRetentionProperties || [],
      durationTypeProperties: node?.ropaTopics?.dataRetention?.durationTypeProperties || [],
      attributeSetProperties: node?.ropaTopics?.dataRetention?.attributeSetProperties || [],
      details:
        node?.ropaTopics?.dataRetention?.dataRetentionInfo?.edges?.map(({ node }) => ({
          durationType: node?.durationType || '',
          durationCount: node?.durationCount,
          triggerEvent: node?.triggerEvent || '',
          attributeSets: node?.attributeSet?.edges?.map(({ node }) => node?.name || '') || []
        })) || [],
      questions: mapQuestions(node?.ropaTopics?.dataRetention?.questions?.edges || [])
    }
  }
  if (node?.ropaTopics?.safeguards) {
    questionnaire.safeguards = {
      name: node?.ropaTopics?.safeguards?.name || '',
      safeguards: node?.ropaTopics?.safeguards?.safeguards?.edges?.map(({ node }) => node) || [],
      safeguardProperties: node?.ropaTopics?.safeguards?.safeguardProperties || [],
      safeguardsLabel: getPropertyLabelValue(
        node?.ropaTopics?.safeguards?.safeguardProperties || []
      ),
      questions: node?.ropaTopics?.safeguards?.questions?.edges?.map((edge) => edge?.node) || []
    }
  }
  if (node?.ropaTopics?.transfers) {
    questionnaire.transfers = {
      name: node?.ropaTopics?.transfers?.name || '',
      isCrossBorder: node?.ropaTopics?.transfers?.crossBorder?.isCrossBorder,
      crossBorderProperties: node?.ropaTopics?.transfers?.crossBorder?.crossBorderProperties || [],
      crossBorder: node?.ropaTopics?.transfers?.crossBorder?.isCrossBorder
        ? node?.ropaTopics?.transfers?.crossBorder?.details?.map((detail) => ({
            organisation: detail?.organisation || '',
            organisationProperties: detail?.organisationProperties || [],
            email: detail?.email || '',
            emailProperties: detail?.emailProperties || [],
            country: detail?.country || '',
            countryProperties: detail?.countryProperties || [],
            safeguards: detail?.safeguards?.edges?.map(({ node }) => node),
            safeguardsProperties: detail?.safeguardsProperties || []
          }))
        : undefined,
      isThirdParty: node?.ropaTopics?.transfers?.thirdParty?.isThirdParty,
      thirdPartyProperties: node?.ropaTopics?.transfers?.thirdParty?.thirdPartyProperties || [],
      thirdParty: node?.ropaTopics?.transfers?.thirdParty?.isThirdParty
        ? node?.ropaTopics?.transfers?.thirdParty?.details?.map((detail) => ({
            name: detail?.name || '',
            nameProperties: detail?.nameProperties || [],
            email: detail?.email || '',
            emailProperties: detail?.emailProperties || [],
            address: detail?.address || '',
            addressProperties: detail?.addressProperties || [],
            safeguards: detail?.safeguards?.edges?.map(({ node }) => node),
            safeguardsProperties: detail?.safeguardsProperties || []
          }))
        : undefined
    }
  }
  if (node?.ropaTopics?.customTopics) {
    questionnaire.customTopics =
      node?.ropaTopics?.customTopics?.map((topic) => ({
        name: topic?.name || '',
        questions: mapQuestions(topic?.questions?.edges || [])
      })) || []
  }
  return questionnaire
}

export const getFirstTopicOfTicket = (questionnaire?: PiaQuestionnaireV2) => {
  if (questionnaire?.dataElements) {
    return PiaDefineProcessSteps.dataElements
  } else if (questionnaire?.dataSubjects) {
    return PiaDefineProcessSteps.dataSubjects
  } else if (questionnaire?.dataRetention) {
    return PiaDefineProcessSteps.dataRetention
  } else if (questionnaire?.safeguards) {
    return PiaDefineProcessSteps.safeguards
  } else if (questionnaire?.transfers) {
    return PiaDefineProcessSteps.transfers
  } else if (questionnaire?.customTopics?.length) {
    return questionnaire.customTopics[0].id
  }
  return 0
}

export const SYSTEM_TOPICS = [
  'processDetails',
  'dataElements',
  'dataSubjects',
  'dataRetention',
  'safeguards',
  'transfers'
]

const getTotalDataElementsQuestions = (
  dataElements: DataElementsQuestionnaire,
  isTicket?
): TotalQuestionReturnType => {
  let totalQuestions = 0
  let totalQuestionsAnswered = 0
  const hasDataElementsAttributesQuestion = isTicket
    ? dataElements?.attributeProperties?.find(({ key }) => key == 'isAssigned')?.value?.[0] ===
      'true'
    : true
  if (dataElements) {
    if (hasDataElementsAttributesQuestion) {
      totalQuestions += 1 // attributes
      if (dataElements?.specialElements?.length) {
        totalQuestions += 2 // for lawful basis
        totalQuestionsAnswered++
        if (dataElements?.lawfulBases?.length) {
          totalQuestionsAnswered++
        }
      }
      if (dataElements?.attributes?.length) {
        totalQuestionsAnswered++
      }
    }

    const { questions, answers } = getCustomQuestionsAndAnswersCount(dataElements?.questions)
    totalQuestions += questions
    totalQuestionsAnswered += answers
  }
  return { totalQuestions, totalQuestionsAnswered }
}

const getTotalDataSubjectsQuestions = (
  dataSubjects: DataSubjectsQuestionnaire,
  isTicket?
): TotalQuestionReturnType => {
  let totalQuestions = 0
  let totalQuestionsAnswered = 0
  const existsFn = isTicket ? isFieldAvailableAndAssigned : isFieldAvailable

  if (dataSubjects) {
    if (existsFn(dataSubjects, 'dataSubjectProperties')) {
      totalQuestions++
      if (dataSubjects?.categories?.length) totalQuestionsAnswered++
    }
    const hasSpecialDataSubjectRadioQuestion = existsFn(
      dataSubjects,
      'specialDataSubjectProperties'
    )
    if (hasSpecialDataSubjectRadioQuestion) {
      totalQuestions += 1
      totalQuestionsAnswered += 1 // considering radio button as a question
    }
    const calculateSpecialDataSubjectsQuestions = isTicket
      ? true
      : hasSpecialDataSubjectRadioQuestion
      ? dataSubjects.specialDataSubject
      : false

    if (calculateSpecialDataSubjectsQuestions && existsFn(dataSubjects, 'legalBasisProperties')) {
      const calc = hasSpecialDataSubjectRadioQuestion ? dataSubjects.specialDataSubject : true
      if (calc) {
        totalQuestions++
        if (dataSubjects?.lawfulBasis?.length) totalQuestionsAnswered++
      }
    }
    if (
      calculateSpecialDataSubjectsQuestions &&
      existsFn(dataSubjects, 'specialDataSubjectsProperties')
    ) {
      const calc = hasSpecialDataSubjectRadioQuestion ? dataSubjects.specialDataSubject : true
      if (calc) {
        totalQuestions++
        if (dataSubjects?.specialCategories?.length) totalQuestionsAnswered++
      }
    }
    const { questions, answers } = getCustomQuestionsAndAnswersCount(dataSubjects?.questions)
    totalQuestions += questions
    totalQuestionsAnswered += answers
  }
  return { totalQuestions, totalQuestionsAnswered }
}

const getTotalDataRetentionQuestions = (
  dataRetention: DataRetentionQuestionnaire
): TotalQuestionReturnType => {
  let totalQuestions = 0
  let totalQuestionsAnswered = 0
  if (dataRetention) {
    totalQuestions += 1 //1 hardcoded question for retention
    if (dataRetention.details?.length && dataRetention.details?.length > 0) totalQuestionsAnswered++
    const { questions, answers } = getCustomQuestionsAndAnswersCount(dataRetention?.questions)
    totalQuestions += questions
    totalQuestionsAnswered += answers
  }
  return { totalQuestions, totalQuestionsAnswered }
}

const getTotalSafeguardsQuestions = (
  safeguards: SafeguardsQuestionnaire,
  isTicket?
): TotalQuestionReturnType => {
  let totalQuestions = 0
  let totalQuestionsAnswered = 0
  const existsFn = isTicket ? isFieldAvailableAndAssigned : isFieldAvailable
  if (safeguards) {
    if (existsFn(safeguards, 'safeguardProperties')) {
      totalQuestions++

      if (safeguards?.safeguards?.length) totalQuestionsAnswered++
    }
    const { questions, answers } = getCustomQuestionsAndAnswersCount(safeguards?.questions)
    totalQuestions += questions
    totalQuestionsAnswered += answers
  }
  return { totalQuestions, totalQuestionsAnswered }
}

const getTotalTransfersQuestions = (
  transfers: TransfersQuestionnaire,
  isTicket?
): TotalQuestionReturnType => {
  let totalQuestions = 0
  let totalQuestionsAnswered = 0
  const existsFn = isTicket ? isFieldAvailableAndAssigned : isFieldAvailable
  if (transfers) {
    const thirdParty = transfers?.thirdParty
    if (
      thirdParty?.isThirdParty !== undefined &&
      existsFn(transfers.thirdParty, 'thirdPartyProperties')
    ) {
      totalQuestions++
      totalQuestionsAnswered++
      thirdParty?.isThirdParty &&
        thirdParty?.details?.map((detail) => {
          const { name, email, address, safeguards } = detail

          if (isFieldAvailableAndRequired(detail, 'nameProperties')) {
            totalQuestions++
            if (name?.trim()) {
              totalQuestionsAnswered++
            }
          }

          if (isFieldAvailableAndRequired(detail, 'emailProperties')) {
            totalQuestions++
            if (email?.trim()) {
              totalQuestionsAnswered++
            }
          }
          if (isFieldAvailableAndRequired(detail, 'addressProperties')) {
            totalQuestions++
            if (address?.trim()) {
              totalQuestionsAnswered++
            }
          }
          if (isFieldAvailableAndRequired(detail, 'safeguardsProperties')) {
            totalQuestions++
            if (safeguards?.length) {
              totalQuestionsAnswered++
            }
          }
        })
    }

    const crossBorder = transfers?.crossBorder
    if (
      crossBorder?.isCrossBorder !== undefined &&
      existsFn(transfers.crossBorder, 'crossBorderProperties')
    ) {
      totalQuestions++
      totalQuestionsAnswered++
      crossBorder?.isCrossBorder &&
        crossBorder?.details?.map((detail) => {
          const { organisation, email, country, safeguards } = detail

          if (isFieldAvailableAndRequired(detail, 'emailProperties')) {
            totalQuestions++
            if (email?.trim()) {
              totalQuestionsAnswered++
            }
          }

          if (isFieldAvailableAndRequired(detail, 'organisationProperties')) {
            totalQuestions++

            if (organisation?.trim()) {
              totalQuestionsAnswered++
            }
          }
          if (isFieldAvailableAndRequired(detail, 'countryProperties')) {
            totalQuestions++
            if (country?.trim()) {
              totalQuestionsAnswered++
            }
          }
          if (isFieldAvailableAndRequired(detail, 'safeguardsProperties')) {
            totalQuestions++
            if (safeguards?.length) {
              totalQuestionsAnswered++
            }
          }
        })
    }
    const { questions, answers } = getCustomQuestionsAndAnswersCount(transfers?.questions)
    totalQuestions += questions
    totalQuestionsAnswered += answers
  }
  return { totalQuestions, totalQuestionsAnswered }
}

export const TotalQuestionsEvaluatorMapper = {
  processDetails: getTotalProcessDetailsQuestions,
  dataElements: getTotalDataElementsQuestions,
  dataSubjects: getTotalDataSubjectsQuestions,
  dataRetention: getTotalDataRetentionQuestions,
  safeguards: getTotalSafeguardsQuestions,
  transfers: getTotalTransfersQuestions
}

export const getNextStep = (current: string, questionnaire?: PiaQuestionnaireV2) => {
  if (isObjectId(current) && questionnaire?.customTopics?.length) {
    const currIdx = questionnaire?.customTopics.findIndex((topic) => topic.id === current)
    if (currIdx !== -1 && questionnaire?.customTopics.length > currIdx + 1) {
      return questionnaire?.customTopics[currIdx + 1].id
    } else return getFirstTopicOfTicket(questionnaire)
  } else {
    let currIdx = +current // current starts from 1
    while (currIdx <= SYSTEM_TOPICS.length) {
      const nextIdx = currIdx
      const nextTopic = SYSTEM_TOPICS[nextIdx]
      if (questionnaire?.[nextTopic]) {
        return nextIdx + 1 // since active tab starts with 1
      }
      currIdx += 1
    }
    if (questionnaire?.customTopics?.length) {
      return questionnaire.customTopics[0].id
    }
    return 0
  }
}

export const updateQuestionStatsAndReturnProcess = (process) => {
  if (process) {
    const isTicket = window?.location.pathname.includes(URL_TICKETS)
    const deepCloneProcess = JSON.parse(JSON.stringify(process)) as PiaProcess
    SYSTEM_TOPICS.map((key) => {
      if (deepCloneProcess.questionnaire?.[key]) {
        const evalFn = TotalQuestionsEvaluatorMapper[key]
        if (evalFn) {
          const { totalQuestions, totalQuestionsAnswered } = evalFn(
            key === 'processDetails' ? deepCloneProcess : deepCloneProcess.questionnaire[key],
            isTicket
          )
          deepCloneProcess.questionnaire[key].totalQuestions = totalQuestions
          deepCloneProcess.questionnaire[key].answeredQuestions = totalQuestionsAnswered
        }
      }
    })
    if (deepCloneProcess.questionnaire?.customTopics?.length) {
      deepCloneProcess.questionnaire.customTopics = deepCloneProcess.questionnaire.customTopics.map(
        (topic) => {
          const { answers, questions } = getCustomQuestionsAndAnswersCount(topic.questions)
          topic.totalQuestions = questions
          topic.answeredQuestions = answers
          return topic
        }
      )
    }
    return deepCloneProcess
  } else return process
}

export const removeDuplicates = (items: any[], key?: string) => {
  if (items?.length) {
    if (typeof items[0] === 'string') return [...new Set(items)]
    if (key) {
      const uniqueItemsByKey = items.reduce((unique, o) => {
        if (!unique.some((obj) => obj[key] === o[key])) {
          unique.push(o)
        }
        return unique
      }, [])
      return uniqueItemsByKey
    }
  }
  return []
}

export const getProcessOverview = (
  processOverview: PiaProcessOverview,
  process?: PiaProcess
): PiaProcessOverview => {
  const cloneOverview = deepClone(processOverview) as PiaProcessOverview
  if (process) {
    const { questionnaire } = process
    const { answers, questions } = getTicketOrProcessStats(process, false)
    cloneOverview.completionPercentage = (answers / questions) * 100
    if (questionnaire?.dataElements?.attributes) {
      cloneOverview.totalDataElements = questionnaire?.dataElements?.attributes.length
    }
    if (questionnaire?.dataElements?.specialElements) {
      cloneOverview.totalSpecialDataElements = questionnaire?.dataElements?.specialElements.length
    }
    if (questionnaire?.dataSubjects?.categories) {
      cloneOverview.totalDataSubjects = questionnaire?.dataSubjects?.categories.length
    }
    if (questionnaire?.dataSubjects?.specialCategories) {
      cloneOverview.totalSpecialDataSubjects = questionnaire?.dataSubjects?.specialCategories.length
    }

    cloneOverview.isSpecialDataSubjectsEnabled = questionnaire?.dataSubjects?.specialDataSubject
  }

  return cloneOverview
}

export const validateEmail = (email: string) => EMAIL_REGEX_RFC2822.test(email)
export const generateReportName = (dateNow: string, processName: string) => {
  const dateLocal = dayjs(dateNow).local()
  const date = dateLocal.format('YYYY-MM-DD')
  const time = dateLocal.format('hh:mm A')
  const fileName = `LB ${processName} ${date} at ${time}`

  return fileName
}

export const getFieldProperty = (fieldKey: string, properties: FieldProperty[]) => {
  const prop = properties?.find(({ key }) => key == fieldKey)
  return prop ? prop.riskOptions || prop.value || [] : []
}

export const getStatusTooltip = (status: string) => {
  const statusValue = status ? status.toLowerCase() : ''
  const cellValue = statusValue.replace('_', ' ') || ''
  const tooltip = (cellValue[0]?.toUpperCase() || '') + cellValue.slice(1)

  return tooltip
}

const ONLY_PROCESS_TOPIC_KEYS = ['processDetails', CustomTopicKey]
export const hasOnlyProcessDetailTopic = (process: PiaProcess): boolean => {
  const topicsKeys = Object.keys(process.questionnaire || {})

  const hasOnlyProcessDetail = topicsKeys.every((key) => ONLY_PROCESS_TOPIC_KEYS.includes(key))
  if (hasOnlyProcessDetail && process.questionnaire?.customTopics?.length === 0) {
    return true
  }
  return false
}

export const isFieldAvailable = (process, key) => {
  if (process) {
    const isFieldDeleted = process[key]?.find(({ key }) => key == 'isDeleted')
    const hasField = isFieldDeleted?.value?.length
      ? (isFieldDeleted.value[0] || 'false') == 'false'
      : false
    return hasField
  } else {
    return false
  }
}

export const isFieldAvailableAndRequired = (process, key) => {
  if (process) {
    const isFieldDeleted = process[key]?.find(({ key }) => key == 'isDeleted')
    const isFieldOptional = process[key]?.find(({ key }) => key == 'optional')
    const hasField = isFieldDeleted?.value?.length
      ? (isFieldDeleted.value[0] || 'false') == 'false'
      : false
    const isRequired = isFieldOptional?.value?.length
      ? (isFieldOptional.value[0] || 'false') == 'false'
      : false

    return hasField && isRequired
  } else {
    return false
  }
}

export const isFieldAvailableAndAssigned = (process, key) => {
  if (process) {
    const isFieldDeleted = process[key]?.find(({ key }) => key == 'isDeleted')
    const isFieldAssigned = process[key]?.find(({ key }) => key == 'isAssigned')
    const hasField = isFieldDeleted?.value?.length
      ? (isFieldDeleted.value[0] || 'false') == 'false'
      : !isFieldDeleted || false
    const isAssigned = isFieldAssigned?.value?.length
      ? (isFieldAssigned.value[0] || 'false') == 'true'
      : false

    return hasField && isAssigned
  } else {
    return false
  }
}

export interface RiskCounts {
  highRiskCount: number
  lowRiskCount: number
  mediumRiskCount: number
}
export interface ProcessRiskCount {
  color?: string
  displayName?: string
  severity?: number
  count: number
}
export interface ProcessRiskCounts {
  [key: string]: ProcessRiskCount[]
}

export const isFieldRisky = (properties) => {
  const risky = getFieldProperty('riskAssociated', properties)
  const overallRisk = getFieldProperty('overallRiskValue', properties)
  if (risky.length > 0 || overallRisk.length > 0) {
    return risky[0] === 'true' || overallRisk.length > 0
  } else {
    return false
  }
}

export const getSystemDefinedRiskValue = (properties) => {
  return getFieldProperty('systemRiskValue', properties)[0] + ''
}

export const getFieldRemedy = (properties) => {
  const value = getFieldProperty('riskRemediation', properties)
  return (value.length > 0 ? value[0] || '' : '') as string
}

export const getRiskValue = (
  riskOptions: PiaRiskOption[],
  properties,
  values: string[]
): PiaRiskLabelData | undefined => {
  const overallRiskValue = getFieldProperty('overallRiskValue', properties)
  const systemDefinedValue = getSystemDefinedRiskValue(properties)
  const overallRisk = overallRiskValue.length
    ? riskOptions.find(({ id }) => id === overallRiskValue[0])
    : undefined

  if (values.length > 0) {
    if (overallRisk?.displayName) {
      return {
        ...overallRisk,
        order: overallRisk.value,
        systemRiskValue: systemDefinedValue
      }
    } else {
      const propertyRiskyOptions = (getFieldProperty('riskOptions', properties) ||
        []) as FieldOptions[]
      const valueRiskOptions =
        propertyRiskyOptions?.filter(({ id }) => values?.find((val) => id === val)) || []
      const filteredRiskOptions = valueRiskOptions
        .map(({ value }) => riskOptions.find((item) => item.id === value) as PiaRiskOption)
        .filter((rikOpt) => rikOpt)
      let order = -1
      filteredRiskOptions.forEach(({ value = 0 }) => {
        if (value >= order) {
          order = value
        }
      })
      if (order === -1) {
        return undefined
      } else if (filteredRiskOptions.length > 0) {
        const item = filteredRiskOptions.find(({ value }) => value === order)
        return {
          displayName: item?.displayName || '',
          colour: item?.colour || '',
          order: item?.value,
          value: item?.value,
          id: item?.id as string,
          systemRiskValue: item?.id
        }
      } else {
        const option = filteredRiskOptions[0]
        return {
          displayName: option?.displayName,
          colour: option?.colour,
          id: option?.id,
          order: option?.value,
          value: option?.value,
          systemRiskValue: option?.id
        }
      }
    }
  } else {
    return undefined
  }
}

export const getRiskForDataElements = (
  dataElements: DataElementsQuestionnaire,
  riskOptions: PiaRiskOption[]
) => {
  const {
    attributes = [],
    specialElements = [],
    attributeProperties = [],
    specialElementProperties = []
  } = dataElements || {}
  const dataElementsRiskConfigured = isFieldRisky(attributeProperties)

  const overallRiskValue = dataElementsRiskConfigured
    ? getFieldProperty('overallRiskValue', attributeProperties)
    : false
  if (overallRiskValue && overallRiskValue.length) {
    const riskId = overallRiskValue[0]
    const riskOption = riskOptions.find(({ id }) => id === riskId)
    return riskOption
      ? {
          displayName: riskOption?.displayName || '',
          colour: riskOption?.colour || '',
          order: riskOption?.value,
          value: riskOption?.value,
          id: riskOption?.id,
          systemRiskValue: riskOption?.id
        }
      : undefined
  } else {
    const dataElementsRiskLabelData = dataElementsRiskConfigured
      ? getRiskValue(riskOptions, attributeProperties, attributes)
      : undefined
    const specialElementsRiskConfigured = isFieldRisky(specialElementProperties)
    const specialElementsRiskLabelData = specialElementsRiskConfigured
      ? getRiskValue(
          riskOptions,
          specialElementProperties,
          specialElements.map(({ attributeName }) => attributeName)
        )
      : undefined

    if (dataElementsRiskLabelData?.displayName && specialElementsRiskLabelData?.displayName) {
      if ((dataElementsRiskLabelData?.order || 0) >= (specialElementsRiskLabelData?.order || 0)) {
        return dataElementsRiskLabelData
      } else {
        return specialElementsRiskLabelData
      }
    } else if (dataElementsRiskLabelData?.displayName) {
      return dataElementsRiskLabelData
    } else {
      return specialElementsRiskLabelData
    }
  }
}

export const getRiskForDataRetention = (
  dataRetention: DataRetentionQuestionnaire,
  riskOptions: PiaRiskOption[]
) => {
  const {
    dataRetentionProperties = [],
    durationTypeProperties = [],
    attributeSetProperties = [],
    details = []
  } = dataRetention || {}
  const dataRetentionRiskConfigured = isFieldRisky(dataRetentionProperties)
  const overallRiskValue = dataRetentionRiskConfigured
    ? getFieldProperty('overallRiskValue', dataRetentionProperties)
    : undefined

  if (overallRiskValue && overallRiskValue.length) {
    const riskId = overallRiskValue[0]
    const riskOption = riskOptions.find(({ id }) => id === riskId)
    return riskOption
      ? {
          displayName: riskOption?.displayName || '',
          colour: riskOption?.colour || '',
          order: riskOption?.value,
          value: riskOption?.value,
          id: riskOption?.id,
          systemRiskValue: riskOption?.id
        }
      : undefined
  } else {
    const attributeSetRiskConfigured = isFieldRisky(attributeSetProperties)
    const durationTypeRiskConfigured = isFieldRisky(durationTypeProperties)

    const detailsRiskData = details
      .filter(
        ({ attributeSets = [], durationType }) => attributeSets.length > 0 || !!durationType.trim()
      )
      .map(({ attributeSets = [], durationType }) => {
        const attributeSetRiskLabelData = attributeSetRiskConfigured
          ? getRiskValue(riskOptions, attributeSetProperties, attributeSets)
          : undefined
        const durationTypeRiskLabelData = durationTypeRiskConfigured
          ? getRiskValue(riskOptions, durationTypeProperties, [durationType])
          : undefined

        if (attributeSetRiskLabelData && durationTypeRiskLabelData) {
          if ((attributeSetRiskLabelData?.order || 0) >= (durationTypeRiskLabelData?.order || 0)) {
            return attributeSetRiskLabelData
          } else {
            return durationTypeRiskLabelData
          }
        } else if (durationTypeRiskLabelData?.order) {
          return durationTypeRiskLabelData
        } else if (attributeSetRiskLabelData?.order) {
          return attributeSetRiskLabelData
        } else return undefined
      })
    const risks = detailsRiskData.filter((risk) => risk && risk?.order) as PiaRiskLabelData[]
    let order = -1
    risks.forEach((risk) => {
      if ((risk.order || 0) >= order) {
        order = risk.order || 0
      }
    })

    const opt = riskOptions.find(({ value }) => value === order)
    return opt
      ? {
          displayName: opt?.displayName || '',
          colour: opt?.colour || '',
          order: opt?.value,
          value: opt?.value,
          id: opt?.id,
          systemRiskValue: opt?.id
        }
      : undefined
  }
}

export const getRiskForThirdPartyTransfers = (
  process: PiaProcess,
  riskOptions: PiaRiskOption[]
) => {
  const dataTransfers = process?.questionnaire?.transfers
  const { thirdParty } = dataTransfers || {}
  const { thirdPartyProperties = [], isThirdParty = false, details = [] } = thirdParty || {}
  const thirdPartyRiskConfigured = isFieldRisky(thirdPartyProperties)
  const thirdPartyRiskLabelData = thirdPartyRiskConfigured
    ? getRiskValue(riskOptions, thirdPartyProperties, [String(isThirdParty)])
    : undefined

  const overallRiskValue = getFieldProperty('overallRiskValue', thirdPartyProperties)

  if (overallRiskValue && overallRiskValue.length) {
    const riskId = overallRiskValue[0]
    const riskOption = riskOptions.find(({ id }) => id === riskId)
    return riskOption
      ? {
          displayName: riskOption?.displayName || '',
          colour: riskOption?.colour || '',
          order: riskOption?.value,
          value: riskOption?.value,
          id: riskOption?.id,
          systemRiskValue: riskOption?.id
        }
      : undefined
  } else {
    const detailsRiskData = details
      .filter(({ safeguards = [] }) => safeguards.length > 0)
      .map(({ safeguardsProperties = [], safeguards = [] }) => {
        const safeguardsRiskConfigured = isFieldRisky(thirdPartyProperties)
        const safeguardsRiskLabelData = safeguardsRiskConfigured
          ? getRiskValue(riskOptions, safeguardsProperties, safeguards)
          : undefined

        return safeguardsRiskLabelData
      })
    const risks = [thirdPartyRiskLabelData, ...detailsRiskData].filter(
      (risk) => risk && risk?.order
    ) as PiaRiskLabelData[]
    let order = -1
    risks.forEach((risk) => {
      if ((risk.order || 0) >= order) {
        order = risk.order || 0
      }
    })

    const opt = riskOptions.find(({ value }) => value === order)
    return opt
      ? {
          displayName: opt?.displayName || '',
          colour: opt?.colour || '',
          order: opt?.value,
          value: opt?.value,
          id: opt?.id,
          systemRiskValue: opt?.id
        }
      : undefined
  }
}
export const getRiskForCrossBorderTransfers = (
  process: PiaProcess,
  riskOptions: PiaRiskOption[]
) => {
  const dataTransfers = process?.questionnaire?.transfers
  const { crossBorder } = dataTransfers || {}
  const { crossBorderProperties = [], isCrossBorder = false, details = [] } = crossBorder || {}
  const crossBorderRiskConfigured = isFieldRisky(crossBorderProperties)
  const overallRiskValue = getFieldProperty('overallRiskValue', crossBorderProperties)

  if (overallRiskValue && overallRiskValue.length) {
    const riskId = overallRiskValue[0]
    const riskOption = riskOptions.find(({ id }) => id === riskId)
    return riskOption
      ? {
          displayName: riskOption?.displayName || '',
          colour: riskOption?.colour || '',
          order: riskOption?.value,
          value: riskOption?.value,
          id: riskOption?.id,
          systemRiskValue: riskOption?.id
        }
      : undefined
  } else {
    const crossBorderRiskLabelData = crossBorderRiskConfigured
      ? getRiskValue(riskOptions, crossBorderProperties, [String(isCrossBorder)])
      : undefined
    const detailsRiskData = details
      .filter(({ safeguards = [] }) => safeguards.length > 0)
      .map(({ safeguardsProperties = [], safeguards = [] }) => {
        const safeguardsRiskConfigured = isFieldRisky(safeguardsProperties)
        const safeguardsRiskLabelData = safeguardsRiskConfigured
          ? getRiskValue(riskOptions, safeguardsProperties, safeguards)
          : undefined

        return safeguardsRiskLabelData
      })
    const risks = [crossBorderRiskLabelData, ...detailsRiskData].filter(
      (risk) => risk && risk?.order
    ) as PiaRiskLabelData[]
    let order = -1
    risks.forEach((risk) => {
      if ((risk.order || 0) >= order) {
        order = risk.order || 0
      }
    })

    const opt = riskOptions.find(({ value }) => value === order)
    return opt
      ? {
          displayName: opt?.displayName || '',
          colour: opt?.colour || '',
          order: opt?.value,
          value: opt?.value,
          id: opt?.id,
          systemRiskValue: opt?.id
        }
      : undefined
  }
}

export const mapValuesToOptions = (properties, values) => {
  const options = getFieldProperty('options', properties || []) as any
  const riskOptions = getFieldProperty('riskOptions', properties || []) as any
  return options.map((optId) => ({
    id: optId,
    value: riskOptions?.find(({ id }) => id === optId)?.value,
    name: values?.find(({ id }) => id === optId)?.name || ''
  }))
}

export const calculateSystemRisk = (
  value,
  properties,
  allRiskOptions: PiaRiskOption[],
  resetOverallRisk = false
) => {
  let props = JSON.parse(JSON.stringify(properties || []))
  const riskOptions = getFieldProperty('riskOptions', properties) as any
  const vals = (value || []).map((v) => riskOptions.find(({ id }) => v === String(id)))

  let hightestRiskId
  let highestRiskSeverity = -1

  vals
    .filter((v) => v && v.value !== undefined)
    .forEach((element) => {
      const num = allRiskOptions.find(({ id }) => element.value === id)?.value || 0
      if (num > highestRiskSeverity) {
        highestRiskSeverity = num
        hightestRiskId = element.value
      }
    })

  if (hightestRiskId) {
    if (properties.find(({ key }) => key === 'systemRiskValue')) {
      props = properties.map((field) =>
        field.key === 'systemRiskValue' ? { ...field, value: [hightestRiskId] } : field
      )
    } else {
      props.push({ key: 'systemRiskValue', value: [hightestRiskId] })
    }
  } else {
    props = props.filter((field) => field.key !== 'systemRiskValue')
  }
  return resetOverallRisk ? props.filter(({ key }) => key !== 'overallRiskValue') : props
}

export const getRiskValueV2 = (riskOptions: PiaRiskOption[], properties, values: string[]) => {
  const props = calculateSystemRisk(values, JSON.parse(JSON.stringify(properties)), riskOptions)
  const overallRiskValue = getFieldProperty('overallRiskValue', props)
  const systemRiskValue = getFieldProperty('systemRiskValue', props)
  const riskId =
    overallRiskValue.length > 0
      ? overallRiskValue[0]
      : systemRiskValue.length > 0
      ? systemRiskValue[0]
      : ''
  return riskId ? riskOptions.find(({ id }) => id === riskId) : undefined
}

export const getRiskValueForCustomQuestion = (
  riskOptions: PiaRiskOption[],
  question?: PiaQuestion
): PiaRiskLabelData | undefined => {
  const overallRiskValue = question?.overallRiskValue
  const systemRiskValue = question?.systemRiskValue
  const riskId = overallRiskValue || systemRiskValue
  const riskOpt = riskOptions.find(({ id }) => id === riskId)
  return riskId
    ? {
        ...riskOpt,
        id: riskOpt?.id || '',
        colour: riskOpt?.colour || '',
        displayName: riskOpt?.displayName || '',
        order: riskOpt?.value,
        value: riskOpt?.value,
        systemRiskValue
      }
    : undefined
}

export const calculateRiskValueForCustomQuestion = (
  riskOptions: PiaRiskOption[],
  values: string[],
  riskMapper: any[]
) => {
  const riskIds = values.map((val) => riskMapper.find(({ id }) => id === val)?.value)
  const filteredRiskIds = riskIds.map((riskId) => riskId)
  const opts = (riskOptions.filter(({ id }) => filteredRiskIds.find((fi) => fi === id)) ||
    []) as PiaRiskOption[]

  let order = -1
  opts.forEach(({ value = 0 }) => {
    if (value >= order) {
      order = value
    }
  })

  return riskOptions.find(({ value }) => value === order)?.id || ''
}

const transformRisks = (topicRisks) => {
  const risks = topicRisks.filter((risk) => risk?.displayName)
  const grouped = groupByProperty(risks, 'value')
  return Object.keys(grouped).map((value) => ({
    severity: parseInt(value),
    count: grouped[value].length,
    displayName: (grouped[value][0] as any)?.displayName || ''
  }))
}

export const getRisksForCustomQuestions = (
  questions: PiaQuestion[],
  riskOptions: PiaRiskOption[]
): PiaRiskLabelData[] => {
  const risks = questions
    .filter(({ riskAssociated = false }) => riskAssociated)
    .map((question) => {
      const riskId = question.overallRiskValue || question.systemRiskValue
      const risk = riskOptions.find(({ id }) => id === riskId)

      return { ...risk, order: risk?.value || 0, systemRiskValue: question.systemRiskValue }
    }) as PiaRiskLabelData[]

  return risks
}

export const getRiskStatus = (
  process: PiaProcess,
  riskOptions: PiaRiskOption[]
): ProcessRiskCounts => {
  const data: ProcessRiskCounts = {}
  if (process) {
    const {
      lawfulBasis = [],
      automatedProcessing = false,
      processGroups = [],
      purpose = [],
      questionnaire
    } = process
    const {
      processDetails,
      dataElements,
      dataSubjects,
      dataRetention,
      safeguards,
      transfers,
      customTopics = []
    } = questionnaire || {}

    if (processDetails) {
      const {
        lawfulBasisProperties,
        purposeProperties,
        processGroupsProperties,
        automatedProcessingProperties,
        processQuestions = []
      } = processDetails

      const isAutoRiskConfigured = isFieldRisky(automatedProcessingProperties)
      const automatedProcessRisk = isAutoRiskConfigured
        ? getRiskValueV2(riskOptions, automatedProcessingProperties, [String(automatedProcessing)])
        : undefined

      const lawfulBasisRiskConfigured = isFieldRisky(lawfulBasisProperties)
      const lawfulBasisRisk =
        lawfulBasisRiskConfigured && lawfulBasis?.length
          ? getRiskValueV2(riskOptions, lawfulBasisProperties, lawfulBasis)
          : undefined

      const purposeRiskConfigured = isFieldRisky(purposeProperties)
      const purposeRisk =
        purposeRiskConfigured && purpose?.length
          ? getRiskValueV2(riskOptions, purposeProperties, purpose)
          : undefined

      const processGroupsRiskConfigured = isFieldRisky(processGroupsProperties)
      const processGroupsRisk =
        processGroupsRiskConfigured && processGroups?.length
          ? getRiskValueV2(riskOptions, processGroupsProperties, processGroups)
          : undefined
      const processQuestionRisks = getRisksForCustomQuestions(processQuestions, riskOptions)

      const processDetailsRisks = [
        automatedProcessRisk,
        processGroupsRisk,
        lawfulBasisRisk,
        purposeRisk,
        ...processQuestionRisks
      ]
      data['processDetails'] = transformRisks(processDetailsRisks)
    }
    if (dataElements) {
      const dataElementsRiskConfigured = isFieldRisky(dataElements?.attributeProperties)
      const attributesRisk =
        dataElementsRiskConfigured && dataElements?.attributes?.length
          ? getRiskValueV2(riskOptions, dataElements?.attributeProperties, dataElements?.attributes)
          : undefined
      const specialAttributesRisk =
        dataElementsRiskConfigured && dataElements?.specialElements?.length
          ? getRiskValueV2(
              riskOptions,
              dataElements?.specialElementProperties,
              dataElements?.specialElements?.map(({ attributeName = '' }) => attributeName)
            )
          : undefined

      const customQuestionRisks = getRisksForCustomQuestions(
        dataElements?.questions || [],
        riskOptions
      )
      const dataElementsRisks = [attributesRisk, specialAttributesRisk, ...customQuestionRisks]
      data['dataElements'] = transformRisks(dataElementsRisks)
    }
    if (dataSubjects) {
      const {
        categories = [],
        dataSubjectProperties = [],
        specialDataSubjectProperties = [],
        specialDataSubject = false,
        questions = [],
        legalBasisProperties = [],
        lawfulBasis = [],
        specialDataSubjectsProperties = [],
        specialCategories = []
      } = dataSubjects || {}
      const dataSubjectsRiskConfigured = categories
        ? isFieldRisky(dataSubjectProperties)
        : undefined
      const dataSubjectsRisk =
        dataSubjectsRiskConfigured && categories.length
          ? getRiskValueV2(riskOptions, dataSubjectProperties, categories)
          : undefined

      const isSpecialSubjectRiskConfigured = isFieldRisky(specialDataSubjectProperties)
      const specialDataSubjectRisk = isSpecialSubjectRiskConfigured
        ? getRiskValueV2(riskOptions, specialDataSubjectProperties, [
            String(specialDataSubject || false)
          ])
        : undefined

      const specialDataSubjectsRiskConfigured = specialCategories
        ? isFieldRisky(specialDataSubjectsProperties)
        : undefined
      const specialDataSubjectsRisk =
        specialDataSubjectsRiskConfigured && specialCategories.length
          ? getRiskValueV2(riskOptions, specialDataSubjectsProperties, specialCategories)
          : undefined

      const subjectLawfulBasisRiskConfigured = lawfulBasis
        ? isFieldRisky(legalBasisProperties)
        : undefined
      const subjectLawfulBasisRisk =
        subjectLawfulBasisRiskConfigured && lawfulBasis.length
          ? getRiskValueV2(riskOptions, legalBasisProperties, lawfulBasis)
          : undefined

      const customQuestionRisks = getRisksForCustomQuestions(questions, riskOptions)
      const dataSubjectsRisks = [
        dataSubjectsRisk,
        specialDataSubjectRisk,
        specialDataSubjectsRisk,
        subjectLawfulBasisRisk,
        ...customQuestionRisks
      ]
      data['dataSubjects'] = transformRisks(dataSubjectsRisks)
    }
    if (safeguards) {
      const safeguardsRiskConfigured = safeguards?.safeguards
        ? isFieldRisky(safeguards?.safeguardProperties)
        : undefined
      const safeguardsRisk =
        safeguardsRiskConfigured && safeguards?.safeguards?.length
          ? getRiskValueV2(riskOptions, safeguards?.safeguardProperties, safeguards?.safeguards)
          : undefined

      const customQuestionRisks = getRisksForCustomQuestions(
        safeguards?.questions || [],
        riskOptions
      )
      data['safeguards'] = transformRisks([safeguardsRisk, ...customQuestionRisks])
    }
    if (transfers) {
      const transfersRisks: (PiaRiskOption | undefined)[] = []

      if (transfers?.thirdParty) {
        const isThirdPartyRiskConfigured = isFieldRisky(transfers?.thirdParty.thirdPartyProperties)
        const thirdPartyRisk = isThirdPartyRiskConfigured
          ? getRiskValueV2(riskOptions, transfers?.thirdParty.thirdPartyProperties, [
              String(transfers?.thirdParty?.isThirdParty || false)
            ])
          : undefined
        transfersRisks.push(thirdPartyRisk)
        if (transfers?.thirdParty?.isThirdParty) {
          transfers.thirdParty?.details.forEach(({ safeguards = [], safeguardsProperties }) => {
            const safeguardsRiskConfigured =
              safeguards.length > 0 ? isFieldRisky(safeguardsProperties) : undefined
            const safeguardsRisk =
              safeguardsRiskConfigured && safeguards?.length
                ? getRiskValueV2(riskOptions, safeguardsProperties, safeguards)
                : undefined
            transfersRisks.push(safeguardsRisk)
          })
        }
      }

      if (transfers?.crossBorder) {
        const isCrossBorderRiskConfigured = isFieldRisky(
          transfers?.crossBorder.crossBorderProperties
        )
        const crossBorderRisk = isCrossBorderRiskConfigured
          ? getRiskValueV2(riskOptions, transfers?.crossBorder.crossBorderProperties, [
              String(transfers?.crossBorder?.isCrossBorder || false)
            ])
          : undefined
        transfersRisks.push(crossBorderRisk)
        if (transfers?.crossBorder?.isCrossBorder) {
          transfers.crossBorder?.details.forEach(({ safeguards = [], safeguardsProperties }) => {
            const safeguardsRiskConfigured =
              safeguards.length > 0 ? isFieldRisky(safeguardsProperties) : undefined
            const safeguardsRisk =
              safeguardsRiskConfigured && safeguards?.length
                ? getRiskValueV2(riskOptions, safeguardsProperties, safeguards)
                : undefined
            transfersRisks.push(safeguardsRisk)
          })
        }
      }

      const customQuestionRisks = getRisksForCustomQuestions(
        transfers?.questions || [],
        riskOptions
      )
      data['transfers'] = transformRisks([...transfersRisks, ...customQuestionRisks])
    }
    if (dataRetention) {
      const {
        attributeSetProperties = [],
        durationTypeProperties = [],
        dataRetentionProperties = [],
        details = [],
        questions = []
      } = dataRetention || {}
      const isRiskConfigured = isFieldRisky(dataRetentionProperties)
      const overallRiskValue = getFieldProperty('overallRiskValue', dataRetentionProperties)
      const attributeSetRiskConfigured = isFieldRisky(attributeSetProperties)
      const durationTypeRiskConfigured = isFieldRisky(durationTypeProperties)
      const customQuestionRisks = getRisksForCustomQuestions(questions, riskOptions)
      const retentionRisks: (PiaRiskOption | undefined)[] = []

      isRiskConfigured &&
        details.forEach(({ attributeSets = [], durationType }) => {
          const overallRiskId = overallRiskValue.length > 0 ? overallRiskValue[0] : ''
          const overallRisk = riskOptions.find(({ id }) => id === overallRiskId)

          if (overallRisk) {
            retentionRisks.push(overallRisk)
          } else {
            const attributeSetRisk =
              attributeSetRiskConfigured && attributeSets?.length
                ? getRiskValueV2(riskOptions, attributeSetProperties, attributeSets)
                : undefined
            const durationTypeRisk =
              durationTypeRiskConfigured && !!durationType.trim()
                ? getRiskValueV2(
                    riskOptions,
                    durationTypeProperties,
                    durationType ? [durationType] : []
                  )
                : undefined
            if ((attributeSetRisk?.value || 0) >= (durationTypeRisk?.value || 0)) {
              retentionRisks.push(attributeSetRisk)
            } else if (durationTypeRisk?.value || 0) {
              retentionRisks.push(durationTypeRisk)
            } else if ((attributeSetRisk?.value || 0) > 0) {
              retentionRisks.push(attributeSetRisk)
            }
          }
        })
      customQuestionRisks.forEach((risk) => retentionRisks.push(risk))
      data['dataRetention'] = transformRisks(retentionRisks)
    }

    if (customTopics.length > 0) {
      customTopics.map(({ questions = [], id = '' }) => {
        const risks = getRisksForCustomQuestions(questions, riskOptions)
        data[id] = transformRisks(risks)
      })
    }
  }
  return data
}

export const calculateProcessRisk = (process: PiaProcess, riskOptions: PiaRiskOption[]): string => {
  const severities = new Set<number>()
  const riskByTopics = getRiskStatus(process, riskOptions)
  Object.keys(riskByTopics).forEach((topic) => {
    if (riskByTopics[topic]) {
      riskByTopics[topic]?.forEach(({ severity = 0 }) => {
        severities.add(severity)
      })
    }
  })
  const maxSeverity = Math.max(...Array.from(severities.values()))
  const risk = (riskOptions || []).find(({ value }) => value === maxSeverity)
  return risk?.id || ''
}

export const getMaxQuestionsRisk = (
  questions: PiaQuestion[],
  riskOptions: PiaRiskOption[]
): PiaRiskOption | undefined => {
  const risks = getRisksForCustomQuestions(questions, riskOptions)
  const maxSeverity = Math.max(...transformRisks(risks).map(({ severity = 0 }) => severity))
  const risk = (riskOptions || []).find(({ value }) => value === maxSeverity)
  return risk
}

export const mapTopicLevelRisks = (risk, riskOptions: PiaRiskOption[]) => {
  return risk.map(({ severity, count, displayName = '' }) => {
    const riskOption = riskOptions.find(({ value }) => value == ((severity as unknown) as number))
    return { count, color: riskOption?.colour || '#000', displayName }
  })
}

export const getRadioControlOptions = (
  properties,
  textYes: string,
  textNo: string,
  reverseOrder?: boolean
) => {
  const riskOptions = getFieldProperty('riskOptions', properties || []) as any
  const riskOptionYes = riskOptions.find(({ id }) => {
    return id === 'true' || id === true
  })
  const riskOptionNo = riskOptions.find(({ id }) => id === 'false' || id === false)

  const optNo = {
    value: riskOptionNo?.value || '',
    id: riskOptionNo?.id === undefined ? 'false' : riskOptionNo?.id,
    name: textNo
  }
  const optYes = {
    value: riskOptionYes?.value || '',
    id: riskOptionYes?.id === undefined ? 'true' : riskOptionYes?.id,
    name: textYes
  }
  return reverseOrder ? [optYes, optNo] : [optNo, optYes]
}

export const addRiskDefaultProps = (initialProps: FieldProperty[] = []): FieldProperty[] => {
  const itemProps = [...initialProps]
  if (!itemProps.find((prop) => prop.key === 'riskAssociated')) {
    itemProps.push({ key: 'riskAssociated', value: ['false'], riskOptions: null })
  }
  if (!itemProps.find((prop) => prop.key === 'riskOptions')) {
    itemProps.push({ key: 'riskOptions', value: [], riskOptions: null })
  }

  return itemProps
}

export const updateOverallRiskProps = (
  properties: FieldProperty[] = [],
  overallRiskId = ''
): FieldProperty[] => {
  const updatedProps = properties.filter(
    ({ key }) => key !== 'overallRiskValue' && key !== 'riskAssociated'
  )
  updatedProps.push({ key: 'overallRiskValue', value: overallRiskId ? [overallRiskId] : [] })
  updatedProps.push({ key: 'riskAssociated', value: [!!overallRiskId + ''] })
  return updatedProps
}
