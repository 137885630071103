import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import cx from 'classnames'
import { ArrowSquareOut as IconArrowSquareOut } from 'phosphor-react'

interface IProps {
  title: string
  className?: string
  tooltip?: string
  showTooltip?: boolean
  content: string | number | React.ReactElement
  link?: string | { search?: string; pathname?: string }
  newTabOnClick?: boolean
  showExternalLinkIcon?: boolean
  onClick?: () => void
}

const TitleColumn = (props: IProps): React.ReactElement => {
  const {
    title,
    className = '',
    content,
    link = '',
    onClick,
    tooltip,
    showTooltip = true,
    newTabOnClick = false,
    showExternalLinkIcon = true
  } = props
  const ClickableComponent = link ? Link : ('div' as any)

  return (
    <>
      <Grid
        className={`title-column mobile hidden xs-m-0 ${className}`}
        {...(showTooltip ? (tooltip ? { title: tooltip } : { title: content }) : {})}
        onClick={onClick}
      >
        <ClickableComponent
          data-test-id="title-column"
          to={link}
          className={cx('xs-p-0', { truncate: !newTabOnClick, 'is-external-link': newTabOnClick })}
          {...(tooltip ? { title: tooltip } : {})}
          {...(newTabOnClick ? { target: '_blank', rel: 'noreferrer' } : {})}
        >
          <span className={cx({ truncate: newTabOnClick })}>{content}</span>
          {newTabOnClick && showExternalLinkIcon && (
            <span className="icon-external xs-pl-4">
              <IconArrowSquareOut size="16" color="var(--color-primary-light)" weight="bold" />
            </span>
          )}
        </ClickableComponent>
      </Grid>
      <Grid className={`mobile only title-column m-content xs-my-0 xs-mx-0 xs-p-0 ${className}`}>
        <div className="li xs-px-0">
          <p className="title-mobile">{title}</p>
          <ClickableComponent
            data-test-id="title-column-mobile"
            to={link}
            {...(newTabOnClick ? { target: '_blank', rel: 'noreferrer' } : {})}
            className="content-mobile truncate xs-p-0"
            onClick={onClick}
          >
            &nbsp;{content}
          </ClickableComponent>
        </div>
      </Grid>
    </>
  )
}

export default TitleColumn
