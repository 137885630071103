import {
  mapQueryAttributesCountByDs,
  mapQueryDatasourceAttributeInstancesDeltaCounts,
  mapQueryDataSourcesOverview,
  mapQueryDataSourceSummary,
  mapQueryDeltaSummary,
  queryAttributesCountByDs,
  queryDatasourceAttributeInstancesDeltaCounts,
  queryDataSourcesOverview,
  queryDataSourceSummary,
  queryDeltaSummary,
  queryDeltaHighSensitivyAttributeInstance,
  mapQueryDeltaHighSensitivyAttributeInstance,
  mapQueryDeltaHighSensitivyColumns,
  queryDeltaHighSensitivyColumns,
  mapQueryDeltaDatasourceFiles,
  queryDeltaDatasourceFiles,
  mapQueryDeltaDatasourceTables,
  queryDeltaDatasourceTables,
  mapQueryDeltaDatasourceColumns,
  queryDeltaDatasourceColumns,
  policyPerDatasource,
  mapPolicyPerDatasource
} from './queries'
import graphqlService from '../services/graphqlService'
import { DATA_SOURCE_TYPES } from '../constants'
import {
  ACTION_ENTITIES_WIDGET_SUMMARY,
  EntitiesSummaryWidget
} from '../features/entities/entitiesSlice'
import { queryEntitiesSummary, mapQueryEntitiesSummary } from '../features/entities/queries'
import { getUrlParam } from '../utils/urlUtil'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const getStartEndDateFromURL = () => {
  const startDateParam = getUrlParam('startDate')
  const EndDateParam = getUrlParam('endDate')

  return {
    startDate: startDateParam ? new Date(startDateParam).toISOString() : '',
    endDate: EndDateParam ? new Date(EndDateParam).toISOString() : ''
  }
}

export type stats = {
  startAdded: number
  startDeleted: number
  startTotal: number
  total: number
  added: number
  deleted: number
  startTimestamp?: string
  endTimestamp?: string
}
export type AttributeInstanceStats = {
  startTimestamp: string
  endTimestamp: string
  attributes: stats[]
}

export type HistoricalStats = {
  datasourceStats: stats[]
  attributeStats: stats[]
  columnStats: stats[]
  tableStats: stats[]
  attributeInstanceStats: AttributeInstanceStats[]
  fileStats: stats[]
}

export type DataSourcesOverview = {
  dataSourcesCount: number
  dataSourcesRiskCount: number
  policiesCount: number
  policiesViolatedCount: number
  attributeInstancesCount: number
  attributesCount: number
  attributesPiiCount: number
  entitiesCount: number
  entitiesRiskyCount: number
  classifiedFilesCount: number
  attributeEnabledTotal: number
  dataSourcesInfo: PrintDataSourceSummaryParams[]
  piiTablesCount: number
  piiFilesCount: number
  classificationsCount: number
  riskyObjectsCount: number
  sensitiveAttributeInstances: number
}
export const ACTION_PRINT_OVERVIEW = 'print/overview'
export const fetchDataSourcesOverview = createAsyncThunk(ACTION_PRINT_OVERVIEW, async () => {
  const resultRaw = await graphqlService.execute(queryDataSourcesOverview())
  const totals = mapQueryDataSourcesOverview(resultRaw)

  return { ...totals }
})

export type PrintDataSourceSummary = {
  id: string
  name: string
  type: DATA_SOURCE_TYPES
  ownerName: string
  ownerEmail: string
  bucketsCount?: number
  drivesCount?: number
  sharesCount?: number
  channelsCount?: number
  mailsCount?: number
  tablesCount?: number
  piiTablesCount?: number
  piiColumnsCount?: number
  databasesCount?: number
  columnsCount?: number
  piiFilesCount?: number
  policiesViolatedCount?: number
  projectsCount?: number
  attributesCount?: number
  jiraProjectCount?: number
  parentObjectType?: string
  parentObjectCount?: number
}
export type PrintDataSourceSummaryParams = {
  id: string
  isMixed: boolean
}
export type DatasourceAtrributeDelta = {
  [datasourceId: string]: AttributeInstanceStats[]
}
export const ACTION_PRINT_DS_SUMMARY = 'print/dsSummary'
export const fetchPrintDataSourcesSummary = createAsyncThunk(
  ACTION_PRINT_DS_SUMMARY,
  async (params: PrintDataSourceSummaryParams[]) => {
    const result = await Promise.all(
      params.map(async ({ id, isMixed }) => {
        const dsResultRaw = await graphqlService.execute(queryDataSourceSummary(id, isMixed))
        const ds = mapQueryDataSourceSummary(dsResultRaw)

        const attributesCountRaw = await graphqlService.execute(queryAttributesCountByDs(id))
        const attributesCount = mapQueryAttributesCountByDs(attributesCountRaw)

        return { ...ds, attributesCount }
      })
    )
    return result
  }
)
export const ACTION_DELTA_PRINT_DS_SUMMARY = 'print/delta/dsSummary'
export const fetchDeltaPrintDataSourcesSummary = createAsyncThunk(
  ACTION_DELTA_PRINT_DS_SUMMARY,
  async () => {
    const { startDate, endDate } = getStartEndDateFromURL()
    const dateObj = new Date()
    const now = dateObj.toISOString()
    // get 1 month old date TODO: add a config
    dateObj.setDate(dateObj.getDate() - 4 * 7)
    const dsResultRaw = await graphqlService.execute(
      startDate || queryDeltaSummary(dateObj.toISOString(), endDate || now)
    )
    return mapQueryDeltaSummary(dsResultRaw)
  }
)
export const ACTION_DELTA_PRINT_DS_HIGHSENSITIVITY_ATTRIBUTE =
  'print/delta/DS/highSensitivity/attribute'
export const fetchDeltaPrintDataSourcesAttributeHighSensitivity = createAsyncThunk(
  ACTION_DELTA_PRINT_DS_HIGHSENSITIVITY_ATTRIBUTE,
  async (datasourceId: string): Promise<DatasourceAtrributeDelta> => {
    const { startDate, endDate } = getStartEndDateFromURL()
    const dateObj = new Date()
    const now = dateObj.toISOString()
    // get 1 month old date TODO: add a config
    dateObj.setDate(dateObj.getDate() - 4 * 7)
    const dsResultRaw = await graphqlService.execute(
      queryDeltaHighSensitivyAttributeInstance(
        startDate || dateObj.toISOString(),
        endDate || now,
        datasourceId
      )
    )
    return {
      [datasourceId]: mapQueryDeltaHighSensitivyAttributeInstance(dsResultRaw)
    }
  }
)
export const ACTION_DELTA_PRINT_DS_ATTRIBUTE = 'print/delta/DS/attribute'
export const fetchDeltaPrintDataSourcesTotalAttributes = createAsyncThunk(
  ACTION_DELTA_PRINT_DS_ATTRIBUTE,
  async (datasourceId: string): Promise<DatasourceAtrributeDelta> => {
    const { startDate, endDate } = getStartEndDateFromURL()
    const dateObj = new Date()
    const now = dateObj.toISOString()
    // get 1 month old date TODO: add a config
    dateObj.setDate(dateObj.getDate() - 4 * 7)
    const dsResultRaw = await graphqlService.execute(
      queryDatasourceAttributeInstancesDeltaCounts(
        startDate || dateObj.toISOString(),
        endDate || now,
        datasourceId
      )
    )
    return {
      [datasourceId]: mapQueryDatasourceAttributeInstancesDeltaCounts(dsResultRaw)
    }
  }
)
export const ACTION_DELTA_PRINT_DS_HIGH_SENSITIVITY_ATTRIBUTE =
  'print/delta/DS/attribute/highSensitivity'
export const fetchDeltaHighSensitivyAttributeInstance = createAsyncThunk(
  ACTION_DELTA_PRINT_DS_HIGH_SENSITIVITY_ATTRIBUTE,
  async () => {
    const { startDate, endDate } = getStartEndDateFromURL()
    const dateObj = new Date()
    const now = dateObj.toISOString()
    // get 1 month old date TODO: add a config
    dateObj.setDate(dateObj.getDate() - 4 * 7)
    const dsResultRaw = await graphqlService.execute(
      queryDeltaHighSensitivyAttributeInstance(
        startDate || dateObj.toISOString(),
        endDate || now,
        ''
      )
    )
    return mapQueryDeltaHighSensitivyAttributeInstance(dsResultRaw)
  }
)
export const ACTION_DELTA_PRINT_DS_HIGH_SENSITIVITY_COLUMNS =
  'print/delta/DS/columns/highSensitivity'
export const fetchDeltaHighSensitivyColumns = createAsyncThunk(
  ACTION_DELTA_PRINT_DS_HIGH_SENSITIVITY_COLUMNS,
  async () => {
    const { startDate, endDate } = getStartEndDateFromURL()
    const dateObj = new Date()
    const now = dateObj.toISOString()
    // get 1 month old date TODO: add a config
    dateObj.setDate(dateObj.getDate() - 4 * 7)
    const dsResultRaw = await graphqlService.execute(
      queryDeltaHighSensitivyColumns(startDate || dateObj.toISOString(), endDate || now, '')
    )
    return mapQueryDeltaHighSensitivyColumns(dsResultRaw)
  }
)
export const ACTION_DELTA_PRINT_DS_HIGH_SENSITIVITY_COLUMNS_PERDS =
  'print/delta/DS/columns/highSensitivity/perDS'
export const fetchDeltaHighSensitivyColumnsPerDS = createAsyncThunk(
  ACTION_DELTA_PRINT_DS_HIGH_SENSITIVITY_COLUMNS_PERDS,
  async (datasourceId: string) => {
    const { startDate, endDate } = getStartEndDateFromURL()
    const dateObj = new Date()
    const now = dateObj.toISOString()
    // get 1 month old date TODO: add a config
    dateObj.setDate(dateObj.getDate() - 4 * 7)
    const dsResultRaw = await graphqlService.execute(
      queryDeltaHighSensitivyColumns(
        startDate || dateObj.toISOString(),
        endDate || now,
        datasourceId
      )
    )
    return {
      [datasourceId]: mapQueryDeltaHighSensitivyColumns(dsResultRaw)
    }
  }
)
export const ACTION_DELTA_PRINT_FILES_PER_DS = 'print/delta/files/perDS'
export const fetchDeltaFilesPerDatasource = createAsyncThunk(
  ACTION_DELTA_PRINT_FILES_PER_DS,
  async (datasourceId: string) => {
    const { startDate, endDate } = getStartEndDateFromURL()
    const dateObj = new Date()
    const now = dateObj.toISOString()
    // get 1 month old date TODO: add a config
    dateObj.setDate(dateObj.getDate() - 4 * 7)
    const dsResultRaw = await graphqlService.execute(
      queryDeltaDatasourceFiles(startDate || dateObj.toISOString(), endDate || now, datasourceId)
    )
    return {
      [datasourceId]: mapQueryDeltaDatasourceFiles(dsResultRaw)
    }
  }
)
export const ACTION_DELTA_PRINT_TABLES_PER_DS = 'print/delta/tables/perDS'
export const fetchDeltaTablesPerDatasource = createAsyncThunk(
  ACTION_DELTA_PRINT_TABLES_PER_DS,
  async (datasourceId: string) => {
    const { startDate, endDate } = getStartEndDateFromURL()
    const dateObj = new Date()
    const now = dateObj.toISOString()
    // get 1 month old date TODO: add a config
    dateObj.setDate(dateObj.getDate() - 4 * 7)
    const dsResultRaw = await graphqlService.execute(
      queryDeltaDatasourceTables(startDate || dateObj.toISOString(), endDate || now, datasourceId)
    )
    return {
      [datasourceId]: mapQueryDeltaDatasourceTables(dsResultRaw)
    }
  }
)
export const ACTION_DELTA_PRINT_COLUMNS_PER_DS = 'print/delta/columns/perDS'
export const fetchDeltaColumnsPerDatasource = createAsyncThunk(
  ACTION_DELTA_PRINT_COLUMNS_PER_DS,
  async (datasourceId: string) => {
    const { startDate, endDate } = getStartEndDateFromURL()
    const dateObj = new Date()
    const now = dateObj.toISOString()
    // get 1 month old date TODO: add a config
    dateObj.setDate(dateObj.getDate() - 4 * 7)
    const dsResultRaw = await graphqlService.execute(
      queryDeltaDatasourceColumns(startDate || dateObj.toISOString(), endDate || now, datasourceId)
    )
    return {
      [datasourceId]: mapQueryDeltaDatasourceColumns(dsResultRaw)
    }
  }
)
export const fetchEntitiesWidgetSummary = createAsyncThunk(
  ACTION_ENTITIES_WIDGET_SUMMARY,
  async (datasourceId: string) => {
    const resultRaw = await graphqlService.execute(queryEntitiesSummary({ datasourceId }))
    return {
      [datasourceId]: mapQueryEntitiesSummary(resultRaw)
    }
  }
)
const ACTION_POLICY_VIOLATION_PER_DS = 'policy/violation/perDS'
export const fetchPolicyViolationPerDS = createAsyncThunk(
  ACTION_POLICY_VIOLATION_PER_DS,
  async (datasourceId: string) => {
    const resultRaw = await graphqlService.execute(policyPerDatasource(datasourceId))
    return {
      [datasourceId]: mapPolicyPerDatasource(resultRaw)
    }
  }
)
interface PrintState {
  overview?: DataSourcesOverview
  dataSources?: PrintDataSourceSummary[]
  historicalStats?: HistoricalStats
  datasourceTotalAttributeInstanceStats?: DatasourceAtrributeDelta
  datasourceHighSensitivityAttributeInstanceStats?: DatasourceAtrributeDelta
  historicalHighSensitivityAttributes?: {
    startTimestamp: string
    endTimestamp: string
    attributes: stats[]
  }[]
  historicalHighSensitivityColumns?: stats[]
  deltaFilesPerDatasource?: {
    [datasourceId: string]: stats[]
  }
  deltaTablesPerDatasource?: {
    [datasourceId: string]: stats[]
  }
  deltaColumnsPerDatasource?: {
    [datasourceId: string]: stats[]
  }
  deltaHighSensitivityColumnsPerDatasource?: {
    [datasourceId: string]: stats[]
  }
  datasourceEntitySummary?: {
    [datasourceId: string]: EntitiesSummaryWidget
  }
  datasourcePolicyViolation?: {
    [datasourceId: string]: number
  }
}
export const initialState: PrintState = {}

const printSlice = createSlice({
  name: 'print',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDataSourcesOverview.fulfilled, (state, { payload }) => {
      state.overview = payload
    })
    builder.addCase(fetchPrintDataSourcesSummary.fulfilled, (state, { payload }) => {
      state.dataSources = payload
    })
    builder.addCase(fetchDeltaPrintDataSourcesSummary.fulfilled, (state, { payload }) => {
      state.historicalStats = payload
    })
    builder.addCase(
      fetchDeltaPrintDataSourcesAttributeHighSensitivity.fulfilled,
      (state, { payload }) => {
        const datasourceId = Object.keys(payload)[0]
        if (state.datasourceHighSensitivityAttributeInstanceStats) {
          state.datasourceHighSensitivityAttributeInstanceStats[datasourceId] =
            payload[datasourceId]
        } else {
          state.datasourceHighSensitivityAttributeInstanceStats = {
            [datasourceId]: payload[datasourceId]
          }
        }
      }
    )
    builder.addCase(fetchDeltaPrintDataSourcesTotalAttributes.fulfilled, (state, { payload }) => {
      const datasourceId = Object.keys(payload)[0]
      if (state.datasourceTotalAttributeInstanceStats) {
        state.datasourceTotalAttributeInstanceStats[datasourceId] = payload[datasourceId]
      } else {
        state.datasourceTotalAttributeInstanceStats = {
          [datasourceId]: payload[datasourceId]
        }
      }
    })
    builder.addCase(fetchDeltaHighSensitivyAttributeInstance.fulfilled, (state, { payload }) => {
      state.historicalHighSensitivityAttributes = payload
    })
    builder.addCase(fetchDeltaHighSensitivyColumns.fulfilled, (state, { payload }) => {
      state.historicalHighSensitivityColumns = payload
    })
    builder.addCase(fetchDeltaFilesPerDatasource.fulfilled, (state, { payload }) => {
      const datasourceId = Object.keys(payload)[0]
      if (state.deltaFilesPerDatasource) {
        state.deltaFilesPerDatasource[datasourceId] = payload[datasourceId]
      } else {
        state.deltaFilesPerDatasource = payload
      }
    })
    builder.addCase(fetchDeltaTablesPerDatasource.fulfilled, (state, { payload }) => {
      const datasourceId = Object.keys(payload)[0]
      if (state.deltaTablesPerDatasource) {
        state.deltaTablesPerDatasource[datasourceId] = payload[datasourceId]
      } else {
        state.deltaTablesPerDatasource = payload
      }
    })
    builder.addCase(fetchDeltaColumnsPerDatasource.fulfilled, (state, { payload }) => {
      const datasourceId = Object.keys(payload)[0]
      if (state.deltaColumnsPerDatasource) {
        state.deltaColumnsPerDatasource[datasourceId] = payload[datasourceId]
      } else {
        state.deltaColumnsPerDatasource = payload
      }
    })
    builder.addCase(fetchDeltaHighSensitivyColumnsPerDS.fulfilled, (state, { payload }) => {
      const datasourceId = Object.keys(payload)[0]
      if (state.deltaHighSensitivityColumnsPerDatasource) {
        state.deltaHighSensitivityColumnsPerDatasource[datasourceId] = payload[datasourceId]
      } else {
        state.deltaHighSensitivityColumnsPerDatasource = payload
      }
    })
    builder.addCase(fetchEntitiesWidgetSummary.fulfilled, (state, { payload }) => {
      const datasourceId = Object.keys(payload)[0]
      if (state.datasourceEntitySummary) {
        state.datasourceEntitySummary[datasourceId] = payload[datasourceId]
      } else {
        state.datasourceEntitySummary = payload
      }
    })
    builder.addCase(fetchPolicyViolationPerDS.fulfilled, (state, { payload }) => {
      const datasourceId = Object.keys(payload)[0]
      if (state.datasourcePolicyViolation) {
        state.datasourcePolicyViolation[datasourceId] = payload[datasourceId]
      } else {
        state.datasourcePolicyViolation = payload
      }
    })
  }
})

export default printSlice.reducer
