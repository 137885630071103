import PrintPageHeader from '../components/header'
import SvgDatabase from '../../assets/inlineSvg/database2.svg'
import SvgStarInShield from '../../assets/inlineSvg/icon-star-in-shield.svg'
import { DataSourcesOverview, fetchDataSourcesOverview } from '../printSlice'
import { RootState } from '../../rootReducer'
import SvgPiiDataCount from '../../assets/inlineSvg/icon-pii-data.svg'
import SvgPiiDataType from '../../assets/inlineSvg/icon-pii-data-type.svg'
import SvgDocClassifications from '../../assets/inlineSvg/icon-doc-classifications.svg'
import { fetchUserInfo } from '../../features/login/loginSlice'
import { getReportGeneratedText } from '../../utils/reportsUtil'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { User as SvgUser } from 'phosphor-react'
import { useIntl } from 'react-intl'

interface IProps {
  overview?: DataSourcesOverview
  fetchDataSourcesOverview: () => void
  fetchUserInfo: () => void
  classificationsCount?: number
  userFirstName?: string
  userLastName?: string
}

const PrintOverviewPage = (props: IProps): React.ReactElement => {
  const { overview, fetchDataSourcesOverview, userFirstName, userLastName, fetchUserInfo } = props
  const {
    dataSourcesCount = 0,
    dataSourcesRiskCount = 0,
    policiesCount = 0,
    policiesViolatedCount = 0,
    attributeInstancesCount = 0,
    attributesCount = 0,
    attributesPiiCount = 0,
    entitiesCount = 0,
    entitiesRiskyCount = 0,
    classifiedFilesCount = 0,
    attributeEnabledTotal = 0,
    piiTablesCount = 0,
    piiFilesCount = 0,
    riskyObjectsCount = 0,
    sensitiveAttributeInstances = 0,
    classificationsCount = 0
  } = overview || {}

  const headerText = 'Overview'
  const senstiveObjectsCount = piiTablesCount + piiFilesCount
  const riskyObjectsTablesCount = riskyObjectsCount
  const intl = useIntl()
  useEffect(() => {
    fetchUserInfo()
    fetchDataSourcesOverview()
  }, [fetchDataSourcesOverview, fetchUserInfo])

  return (
    <div id="overview" className="print-page page-overview" data-test-id="print-overview-page">
      <PrintPageHeader text={headerText} />
      <div className="wrapper">
        <div className="cards-list">
          <div className="card">
            <div className="card-header">
              <div className="icon-box db">
                <SvgDatabase />
              </div>
              <div className="text">Monitored Data Sources</div>
            </div>
            <div className="card-content">
              <div className="count-item">
                <div className={'count' + (dataSourcesRiskCount === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(dataSourcesRiskCount)}
                </div>
                <div className="label">At risk</div>
              </div>
              <div className="divider" />
              <div className="count-item total">
                <div className={'count total' + (dataSourcesCount === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(dataSourcesCount)}
                </div>
                <div className="label">Total</div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="icon-box">
                <SvgPiiDataCount />
              </div>
              <div className="text">Sensitive Objects</div>
              <div className="sub-text">Files/tables containing PII</div>
            </div>
            <div className="card-content">
              <div className="count-item">
                <div className={'count' + (riskyObjectsTablesCount === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(riskyObjectsTablesCount)}
                </div>
                <div className="label">At Risk</div>
              </div>
              <div className="divider" />
              <div className="count-item">
                <div className={'count total' + (senstiveObjectsCount === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(senstiveObjectsCount)}
                </div>
                <div className="label">With PII</div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <div className="icon-box">
                <SvgPiiDataCount />
              </div>
              <div className="text">Sensitive data count</div>
              <div className="sub-text">Attribute Instances</div>
            </div>
            <div className="card-content">
              <div className="count-item">
                <div className={'count' + (sensitiveAttributeInstances === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(sensitiveAttributeInstances)}
                </div>
                <div className="label">High Sensitivity</div>
              </div>
              <div className="divider" />
              <div className="count-item">
                <div className={'count total' + (attributeInstancesCount === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(attributeInstancesCount)}
                </div>
                <div className="label">Found</div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <div className="icon-box">
                <SvgPiiDataType />
              </div>
              <div className="text">Sensitive data type</div>
              <div className="sub-text">Attributes</div>
            </div>
            <div className="card-content">
              <div className="count-item">
                <div className={'count' + (attributesPiiCount === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(attributesPiiCount)}
                </div>
                <div className="label">High sensitivity</div>
              </div>
              <div className="divider" />
              <div className="count-item">
                <div className={'count total' + (attributesCount === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(attributesCount)}
                </div>
                <div className="label">Found</div>
              </div>
            </div>
            <div className="card-footer">
              <div className="label">Tracking {attributeEnabledTotal} attributes</div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <div className="icon-box">
                <SvgDocClassifications />
              </div>
              <div className="text">Document classification</div>
              <div className="sub-text">Classified files</div>
            </div>
            <div className="card-content">
              <div className="count-item total">
                <div className={'count total' + (classifiedFilesCount === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(classifiedFilesCount)}
                </div>
                <div className="label">Files</div>
              </div>
              <div className="divider" />
              <div className="count-item total">
                <div className={'count total' + (classificationsCount === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(classificationsCount)}
                </div>
                <div className="label">Classification</div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <div className="icon-box">
                <SvgUser size={14} weight="fill" color="var(--color-white)" />
              </div>
              <div className="text">Data subject categories</div>
              <div className="sub-text">Entities Identified</div>
            </div>
            <div className="card-content">
              <div className="count-item">
                <div className={'count' + (entitiesRiskyCount === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(entitiesRiskyCount)}
                </div>
                <div className="label">At risk</div>
              </div>
              <div className="divider" />
              <div className="count-item">
                <div className={'count total' + (entitiesCount === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(entitiesCount)}
                </div>
                <div className="label">Total</div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="icon-box">
                <SvgStarInShield />
              </div>
              <div className="text">Policies</div>
            </div>
            <div className="card-content">
              <div className="count-item">
                <div className={'count' + (policiesViolatedCount === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(policiesViolatedCount)}
                </div>
                <div className="label">Rule Set Violated</div>
              </div>
              <div className="divider" />
              <div className="count-item">
                <div className={'count' + (policiesCount === 0 ? ' opaque50' : '')}>
                  {intl.formatNumber(policiesCount)}
                </div>
                <div className="label">Rule Sets</div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-summary">
          {getReportGeneratedText({
            firstName: userFirstName,
            lastName: userLastName
          })}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  overview: state.print.overview,
  userFirstName: state.user.userInfo?.firstName,
  userLastName: state.user.userInfo?.lastName
})

const mapDispatchToProps = {
  fetchDataSourcesOverview,
  fetchUserInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintOverviewPage)
