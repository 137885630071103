import '../elasticSearchSettings.less'

import { getIndices, updateIndexFields } from '../elasticSearchSettingsSlice'
import IndexField from '../components/indexField'
import { addNotification, INotificationParams } from '../../../../../notification/notificationSlice'
import { RootState } from '../../../../../../rootReducer'
import { ElasticSearchIndexFieldUpdateParams } from '../../../../../../services/api/apiTypes'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Form, Label } from 'semantic-ui-react'

interface IProps {
  dataSourceId: string
  indices?: string[]
  success: string
  error: string
  getIndices: (id: string) => void
  updateIndexFields: (params: ElasticSearchIndexFieldUpdateParams) => void
  addNotification: (notification: INotificationParams) => void
}

const Step2 = (props: IProps): React.ReactElement => {
  const {
    dataSourceId,
    getIndices,
    indices,
    updateIndexFields,
    addNotification,
    success,
    error
  } = props
  const [updatedTimestampFields, setUpdatedTimestampFields] = useState({})
  const isValid = () => {
    return Object.keys(updatedTimestampFields).length > 0
  }
  useEffect(() => {
    getIndices(dataSourceId)
  }, [dataSourceId, getIndices])

  useEffect(() => {
    if (success || error) {
      addNotification({ success, error })
    }
  }, [success, error, addNotification])

  const setUpdatedField = (index, timestampField) => {
    setUpdatedTimestampFields({
      ...updatedTimestampFields,
      [index]: timestampField
    })
  }
  const onUpdateClick = () => {
    const params = {
      datasourceId: dataSourceId,
      patchRecords: Object.keys(updatedTimestampFields).map((item) => {
        return {
          indexName: item,
          timestampField: updatedTimestampFields[item]
        }
      })
    }
    updateIndexFields(params)
  }
  return (
    <Form className="register-form" data-test-id="datasources-elasticsearch-step2">
      <div className="form-wrapper">
        <div className="form-item">
          <Form.Group inline className="step2-dropdown">
            <Form.Field>
              <Label>Indexes</Label>
            </Form.Field>
            <Form.Field>
              <Label>Time Fields</Label>
            </Form.Field>
          </Form.Group>
        </div>
        <IndexField
          indices={indices}
          dataSourceId={dataSourceId}
          setUpdatedField={setUpdatedField}
        />
        {isValid() && (
          <div className="form-item">
            <Form.Button onClick={onUpdateClick}>Update</Form.Button>
          </div>
        )}
      </div>
    </Form>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    dataSourceId: state.dataSources.dataSourceId,
    indices: state.elasticSearchSettings.indices,
    success: state.request.success,
    error: state.request.error
  }
}

const mapDispatchToProps = {
  getIndices,
  updateIndexFields,
  addNotification
}
export default connect(mapStateToProps, mapDispatchToProps)(Step2)
