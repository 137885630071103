import { UserDetails } from '../../../interfaces'
import { RootState } from '../../../rootReducer'
import { hasActionAccess } from '../../../routes/accessFunctions'
import { AccessLevelRoles } from '../../../constants'
import React from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Button } from '@lightbeamai/design-system'

export type StepTab = {
  isActive: boolean
  content: string
  onClick: () => void
}

interface IProps {
  tabs: StepTab[]
  className?: string | number | boolean | React.ReactElement
  onEditClick?: () => void
  onDeleteClick?: () => void
  userDetails?: UserDetails
}

const StepsTabs = (props: IProps): React.ReactElement => {
  const { tabs, className, onEditClick, onDeleteClick, userDetails } = props
  const intl = useIntl()
  const hasAccess = hasActionAccess(userDetails, AccessLevelRoles.addDataSource)
  return (
    <div
      className={`steps-tabs ${className || ''} button-style`}
      data-test-id="dssettings-steps-tabs"
    >
      {tabs.map((tab, i) => {
        return (
          <button
            key={i}
            className={`btn step-tab ${tab.isActive ? 'active' : ''}`}
            onClick={tab.onClick}
            type="button"
          >
            {tab.content}
          </button>
        )
      })}
      {hasAccess && (
        <div className="config-actions">
          {!!onEditClick && (
            <Button
              variant="secondary"
              size={40}
              className="btn step-tab edit"
              onClick={onEditClick}
              htmlType="button"
            >
              {intl.formatMessage({ id: 'dataSources.info.edit' })}
            </Button>
          )}
          {!!onDeleteClick && (
            <Button
              variant="secondary"
              size={40}
              className="btn step-tab delete"
              onClick={onDeleteClick}
              htmlType="button"
            >
              {intl.formatMessage({ id: 'dataSources.info.delete' })}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  userDetails: state.user.userInfo
})
export default connect(mapStateToProps)(StepsTabs)
