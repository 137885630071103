import { TimestampRequestParams, WidgetSensitiveInfo } from '../../services/api/apiTypes'
import apiService from '../../services/api/apiService'
import {
  ConversationWidget,
  ConversationWidgetParams,
  IGetDocumentsParams,
  ISensitiveInfoChartCombined,
  ISensitiveInfoChartTotal
} from '../../interfaces'
import { IS_AT_RISK, IS_SENSITIVE } from '../../constants'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

export interface ObjectDataCombined {
  all?: number
  risky?: number
  sensitive?: number
}
interface SensitiveInfoState {
  dateRange: TimestampRequestParams
  chartData: WidgetSensitiveInfo | null
  chartDataCombined: ISensitiveInfoChartCombined | null
  chartTotal: ISensitiveInfoChartTotal | null
  chartKeys: string[]
  objectDataCombined: ObjectDataCombined
  conversationWidgetData?: ConversationWidget
}

export const initialState: SensitiveInfoState = {
  chartData: null,
  dateRange: {
    endDate: dayjs().unix(),
    startDate: dayjs().subtract(1, 'week').unix()
  },
  chartDataCombined: null,
  objectDataCombined: {},
  chartTotal: null,
  chartKeys: []
}

export const formatChartData = (chartData: WidgetSensitiveInfo): ISensitiveInfoChartCombined => {
  const handleTimeSeries = (timeseries1, timeseries2) => {
    if (
      !timeseries1 ||
      !timeseries2 ||
      !Array.isArray(timeseries1) ||
      !Array.isArray(timeseries2) ||
      timeseries1.length === 0 ||
      timeseries2.length === 0 ||
      timeseries1.length !== timeseries2.length
    ) {
      return []
    }

    return timeseries1
      .map((item, i) => ({
        timestamp: item.timestamp,
        value1: item.value,
        value2: timeseries2[i].value,
        dateTime: dayjs.unix(item.timestamp).format('MMM-D'),
        units: null
      }))
      .sort((a, b) => +((a.timestamp || 0) > (b.timestamp || 0)))
  }

  return {
    ...(chartData.piiDataEntitiesCount && {
      entities: handleTimeSeries(
        chartData.piiDataEntitiesCount?.timeseries,
        chartData.riskEntitiesCount?.timeseries
      )
    }),
    ...(chartData.piiDataMessagesCount && {
      messages: handleTimeSeries(
        chartData.piiDataMessagesCount?.timeseries,
        chartData.riskMessagesCount?.timeseries
      )
    }),
    ...(chartData.piiDataFilesCount && {
      files: handleTimeSeries(
        chartData.piiDataFilesCount?.timeseries,
        chartData.riskFilesCount?.timeseries
      )
    }),
    ...(chartData.residenciesCount && {
      residencies: handleTimeSeries(
        chartData.residenciesCount?.timeseries,
        chartData.riskResidenciesCount?.timeseries
      )
    })
  }
}

export const fetchCombinedObjectCount = createAsyncThunk(
  'combinedObjects/fetch',
  async (filters: IGetDocumentsParams) => {
    const allResult = await apiService.getDocuments({ ...filters, classificationType: 'all' })
    const isAtRiskResult = await apiService.getDocuments({
      ...filters,
      [IS_AT_RISK]: true,
      classificationType: 'all'
    })
    const isSensitiveResult = await apiService.getDocuments({
      ...filters,
      [IS_SENSITIVE]: true,
      classificationType: 'all'
    })
    return {
      all: allResult.total,
      risky: isAtRiskResult.total,
      sensitive: isSensitiveResult.total
    }
  }
)

export const getTotalByTimeSeries = (chartData: WidgetSensitiveInfo): ISensitiveInfoChartTotal => {
  const calcTotal = (timeSeries) => {
    if (!timeSeries || !Array.isArray(timeSeries) || timeSeries.length === 0) {
      return 0
    }
    // return last index's count as total count
    return timeSeries[timeSeries.length - 1].value
  }

  return {
    ...(chartData.piiDataEntitiesCount && {
      entities: {
        total: calcTotal(chartData.piiDataEntitiesCount?.timeseries),
        atRisk: calcTotal(chartData.riskEntitiesCount?.timeseries)
      }
    }),
    ...(chartData.piiDataMessagesCount && {
      messages: {
        total: calcTotal(chartData.piiDataMessagesCount?.timeseries),
        atRisk: calcTotal(chartData.riskMessagesCount?.timeseries)
      }
    }),
    ...(chartData.piiDataFilesCount && {
      files: {
        total: calcTotal(chartData.piiDataFilesCount?.timeseries),
        atRisk: calcTotal(chartData.riskFilesCount?.timeseries)
      }
    }),
    ...(chartData.residenciesCount && {
      residencies: {
        total: calcTotal(chartData.residenciesCount?.timeseries),
        atRisk: calcTotal(chartData.riskResidenciesCount?.timeseries)
      }
    }),
    ...(chartData.piiMailsCount && {
      emails: {
        total: calcTotal(chartData.piiMailsCount?.timeseries),
        atRisk: calcTotal(chartData.riskMailsCount?.timeseries)
      }
    })
  }
}

export const fetchConversationMessages = createAsyncThunk(
  'messages/widget',
  async (filters: ConversationWidgetParams) => {
    const { total } = await apiService.getImMessage(filters)
    const { total: totalRisk } = await apiService.getImMessage({ ...filters, isAtRisk: true })
    const { total: totalSensitive } = await apiService.getImMessage({
      ...filters,
      isSensitive: true
    })

    return { total, totalRisk, totalSensitive }
  }
)

const hybridWidgetSensitiveInfoSlice = createSlice({
  name: 'sensitiveInfo',
  initialState,
  reducers: {
    setDateRange: (state, action) => {
      state.dateRange = action.payload
    },
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCombinedObjectCount.fulfilled, (state, action) => {
      state.objectDataCombined.all = action.payload.all
      state.objectDataCombined.risky = action.payload.risky
      state.objectDataCombined.sensitive = action.payload.sensitive
    })
    builder.addCase(fetchConversationMessages.fulfilled, (state, action) => {
      state.conversationWidgetData = action.payload
    })
  }
})

export const { setDateRange, reset } = hybridWidgetSensitiveInfoSlice.actions

export default hybridWidgetSensitiveInfoSlice.reducer
