export const classificationTemplates = [
  {
    title: 'Driver License - California',
    templates: 50,
    records: 800,
    createdBy: 'Lightbeam',
    compliantTo: 'GDPR|CGPA',
    description: 'Lorem impsum blah blah trali vali eto mu ne prohodili',
    editedAt: '12 Oct 2020'
  },
  {
    title: 'Resume',
    templates: 20,
    records: 2000,
    createdBy: 'User',
    compliantTo: 'HIPRA',
    description: 'Lorem impsum blah blah trali vali eto mu ne prohodili',
    editedAt: '10 Oct 2020'
  },
  {
    title: 'Credit Card bill',
    templates: 70,
    records: 4400,
    createdBy: 'User',
    compliantTo: 'GDPR',
    description: 'Lorem impsum blah blah trali vali eto mu ne prohodili',
    editedAt: '10 Oct 2020'
  },
  {
    title: 'Bank Statement',
    templates: 100,
    records: 10632,
    createdBy: 'Lightbeam',
    compliantTo: 'GDPR|CGPA',
    description: 'Lorem impsum blah blah trali vali eto mu ne prohodili',
    editedAt: '10 Oct 2020'
  },
  {
    title: 'Driver License - California',
    templates: 50,
    records: 800,
    createdBy: 'Lightbeam',
    compliantTo: 'GDPR|CGPA',
    description: 'Lorem impsum blah blah trali vali eto mu ne prohodili',
    editedAt: '12 Oct 2020'
  },
  {
    title: 'Resume',
    templates: 20,
    records: 2000,
    createdBy: 'User',
    compliantTo: 'HIPRA',
    description: 'Lorem impsum blah blah trali vali eto mu ne prohodili',
    editedAt: '10 Oct 2020'
  },
  {
    title: 'Credit Card bill',
    templates: 70,
    records: 4400,
    createdBy: 'User',
    compliantTo: 'GDPR',
    description: 'Lorem impsum blah blah trali vali eto mu ne prohodili',
    editedAt: '10 Oct 2020'
  },
  {
    title: 'Bank Statement',
    templates: 100,
    records: 10632,
    createdBy: 'Lightbeam',
    compliantTo: 'GDPR|CGPA',
    description: 'Lorem impsum blah blah trali vali eto mu ne prohodili',
    editedAt: '10 Oct 2020'
  },
  {
    title: 'Driver License - California',
    templates: 50,
    records: 800,
    createdBy: 'Lightbeam',
    compliantTo: 'GDPR|CGPA',
    description: 'Lorem impsum blah blah trali vali eto mu ne prohodili',
    editedAt: '12 Oct 2020'
  },
  {
    title: 'Resume',
    templates: 20,
    records: 2000,
    createdBy: 'User',
    compliantTo: 'HIPRA',
    description: 'Lorem impsum blah blah trali vali eto mu ne prohodili',
    editedAt: '10 Oct 2020'
  },
  {
    title: 'Credit Card bill',
    templates: 70,
    records: 4400,
    createdBy: 'User',
    compliantTo: 'GDPR',
    description: 'Lorem impsum blah blah trali vali eto mu ne prohodili',
    editedAt: '10 Oct 2020'
  },
  {
    title: 'Bank Statement',
    templates: 100,
    records: 10632,
    createdBy: 'Lightbeam',
    compliantTo: 'GDPR|CGPA',
    description: 'Lorem impsum blah blah trali vali eto mu ne prohodili',
    editedAt: '10 Oct 2020'
  }
]
