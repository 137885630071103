import dayjs from 'dayjs'

export const getReportGeneratedText = (user) => {
  const userText =
    user.firstName?.toLowerCase() === 'lbadmin'
      ? ''
      : user.firstName?.toLowerCase() === 'admin'
      ? `by ${user.firstName}`
      : `by ${user.firstName} ${user.lastName}`

  return `Report Generated on ${dayjs().format('MMMM D, YYYY')} ${userText}`
}
