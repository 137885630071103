import {
  mapQueryDsrEmailEventTypes,
  mapQueryDsrEmailTemplateById,
  mapQueryDsrEmailTemplateSettings,
  mapQueryDsrEmailTemplates,
  mapQuerySystemValues,
  mutationDeleteDsrEmailTemplate,
  queryDsrEmailEventTypes,
  queryDsrEmailTemplateById,
  queryDsrEmailTemplateSettings,
  queryDsrEmailTemplates,
  queryMutationSaveDsrEmailTemplate,
  queryMutationSaveDsrEmailTemplateSettings,
  queryMutationSendTestEmail,
  querySystemValues
} from './queries'
import graphqlService from '../../../services/graphqlService'
import { DsrRequestApiKeys, DsrRequestTypes } from '../dsrForms/dsrFormsSlice'
import { DsrRequestType } from '../../../services/graphqlSchemaTypes'
import { NameIdSummary } from '../../ropa/ropaSlice'
import { EVENT_TYPE, REQUEST_TYPE } from '../../../constants'
import { RootState } from '../../../rootReducer'
import { getGlobalParams } from '../../../utils/urlUtil'
import { SettingsEmailTemplateEventType } from '../../consentManagement/constants'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export enum DsrEmailTemplateTypes {
  requestSubmitted = 'REQUEST_SUBMITTED',
  requestApproved = 'REQUEST_APPROVED',
  requestRejected = 'REQUEST_REJECTED',
  emailVerificationSuccess = 'EMAIL_VERIFICATION_SUCCESSFUL',
  dueDateExtension = 'DUE_DATE_EXTENSION',
  reportShared = 'REPORT_SHARED',
  reportPassword = 'REPORT_PASSWORD',
  requestClosed = 'REQUEST_CLOSED',
  requestReassigned = 'REQUEST_REASSIGNED'
}

export enum DsrEmailTemplateLanguages {
  en = 'EN'
}

export const mapDsrEmailTemplateTypesToText = (type: string | DsrEmailTemplateTypes) => {
  if (type === DsrEmailTemplateTypes.requestSubmitted) {
    return 'dsr.emailTemplate.requestSubmitted'
  }
  if (type === DsrEmailTemplateTypes.requestApproved) {
    return 'dsr.emailTemplate.requestApproved'
  }
  if (type === DsrEmailTemplateTypes.requestRejected) {
    return 'dsr.emailTemplate.requestRejected'
  }
  if (type === DsrEmailTemplateTypes.emailVerificationSuccess) {
    return 'dsr.emailTemplate.emailVerifySuccess'
  }
  if (type === DsrEmailTemplateTypes.dueDateExtension) {
    return 'dsr.emailTemplate.dueDateExtension'
  }
  if (type === DsrEmailTemplateTypes.reportShared) {
    return 'dsr.emailTemplate.reportShared'
  }
  if (type === DsrEmailTemplateTypes.reportPassword) {
    return 'dsr.emailTemplate.reportPassword'
  }
  if (type === DsrEmailTemplateTypes.requestClosed) {
    return 'dsr.emailTemplate.requestClosed'
  }
  if (type === DsrEmailTemplateTypes.requestReassigned) {
    return 'dsr.emailTemplate.requestReassigned'
  }
  return ''
}

export const mapConsentManagementEmailTemplateTypesToText = (
  type: string | SettingsEmailTemplateEventType
) => {
  if (type === SettingsEmailTemplateEventType.emailVerification) {
    return 'consentManagement.settings.emailTemplate.emailVerification'
  }
  if (type === SettingsEmailTemplateEventType.consentRenewal) {
    return 'consentManagement.settings.emailTemplate.consentRenewal'
  }
  return ''
}

export enum DsrEmailTemplateSystemValues {
  dataSubjectName = 'DATA_SUBJECT_NAME',
  emailAddress = 'EMAIL_ADDRESS',
  requestId = 'REQUEST_ID',
  requestSubmissionDate = 'REQUEST_SUBMISSION_DATE',
  originalDueDate = 'ORIGINAL_DUE_DATE',
  extendedDueDate = 'EXTENDED_DUE_DATE',
  reportPassword = 'REPORT_PASSWORD',
  reportId = 'REPORT_ID',
  requestType = 'REQUEST_TYPE'
}

export type DsrEmailTemplate = {
  id?: string
  name: string
  description?: string
  templateLanguage?: DsrEmailTemplateLanguages
  emailSubjectLine?: string
  eventType?: DsrEmailTemplateTypes
  requestType?: DsrRequestTypes
  logo?: string
  header?: string
  body?: string
  footer?: string
  includedSystemValues?: DsrEmailTemplateSystemValues[]
  compiledHTMLTemplate?: string
  createdAt?: string
  updateTimestamp?: string
  createdBy?: string
  updatedBy?: string
  emailCount?: number
}

// CRUD
export interface DsrEmailTemplatesParams {
  id?: string
  searchQuery?: string
  filters: {
    [EVENT_TYPE]?: string
    [REQUEST_TYPE]?: DsrRequestApiKeys
  }
  page?: number
  pageSize?: number
}
export const ACTION_DSR_EMAIL_TEMPLATES = 'dsrEmailTemplates/list'
export const fetchDsrEmailTemplates = createAsyncThunk(
  ACTION_DSR_EMAIL_TEMPLATES,
  async (params: DsrEmailTemplatesParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const raw = await graphqlService.execute(queryDsrEmailTemplates(queryParams))
    return mapQueryDsrEmailTemplates(raw)
  }
)

export const ACTION_DSR_EMAIL_TEMPLATE_DELETE = 'dsrEmailTemplates/delete'
export const deleteDsrEmailTemplate = createAsyncThunk(
  ACTION_DSR_EMAIL_TEMPLATE_DELETE,
  async (templateId: string) => {
    await graphqlService.execute(mutationDeleteDsrEmailTemplate(templateId))
  }
)

export const ACTION_DSR_EMAIL_TEMPLATE_BY_ID = 'dsr/email-template/by-id'
export const fetchDsrEmailTemplateById = createAsyncThunk(
  ACTION_DSR_EMAIL_TEMPLATE_BY_ID,
  async (params: DsrEmailTemplatesParams) => {
    const raw = await graphqlService.execute(queryDsrEmailTemplateById(params))
    return mapQueryDsrEmailTemplateById(raw)
  }
)

export const ACTION_DSR_EMAIL_TEMPLATE_BY_IDS_FOR_EVENT_TYPE =
  'dsr/email-template/by-ids-for-event-type'
export const fetchDsrEmailTemplateByIdsForEventType = createAsyncThunk(
  ACTION_DSR_EMAIL_TEMPLATE_BY_IDS_FOR_EVENT_TYPE,
  async (params: DsrEmailTemplatesParams) => {
    if (params.id) {
      const raw = await graphqlService.execute(queryDsrEmailTemplateById(params))
      return mapQueryDsrEmailTemplateById(raw)
    }
    return null
  }
)

export const ACTION_DSR_EMAIL_TEMPLATE_PREVIEW = 'dsr/email-template/preview'
export const fetchDsrReportTemplates = createAsyncThunk(
  ACTION_DSR_EMAIL_TEMPLATE_PREVIEW,
  async (ids: string[]) => {
    const templates = await Promise.all(
      ids.map((id) =>
        graphqlService.execute(queryDsrEmailTemplateById({ id } as DsrEmailTemplatesParams))
      )
    )
    return templates.map((template) => mapQueryDsrEmailTemplateById(template))
  }
)

export const ACTION_SYS_VALUES_EVENT_TYPES = 'dsr/email-template/placeholders-event-types'
export const fetchDsrSystemValues = createAsyncThunk(
  ACTION_SYS_VALUES_EVENT_TYPES,
  async (eventType: string) => {
    const raw = await graphqlService.execute(querySystemValues(eventType))
    return { list: mapQuerySystemValues(raw), eventType }
  }
)

export interface FetchPlaceholderValuesParams {
  requestId: string
  placeholders: string[]
}

export const ACTION_DSR_EMAIL_EVENT_TYPES = 'dsr/email-template/event-types'
export const fetchDsrEmailEventTypes = createAsyncThunk(ACTION_DSR_EMAIL_EVENT_TYPES, async () => {
  const raw = await graphqlService.execute(queryDsrEmailEventTypes())
  return mapQueryDsrEmailEventTypes(raw)
})

export const ACTION_DSR_TEMPLATE_SETTINGS = 'dsr/email-template/settings'
export const fetchDsrEmailTemplateSettings = createAsyncThunk(
  ACTION_DSR_TEMPLATE_SETTINGS,
  async () => {
    const raw = await graphqlService.execute(queryDsrEmailTemplateSettings())
    return mapQueryDsrEmailTemplateSettings(raw)
  }
)

export interface SaveDsrEmailTemplateParams {
  emailTemplateId?: string
  name?: string
  description?: string
  createdBy?: string
  subject: string
  header: string
  body: string
  footer: string
  compiledHTMLTemplate: string
  requestType: DsrRequestType
  eventType: DsrEmailTemplateTypes
  language?: DsrEmailTemplateLanguages
  systemValues?: string[]
}
export const ACTION_DSR_ADD_EMAIL_TEMPLATE = 'dsr/email-template/add'
export const createDsrEmailTemplate = createAsyncThunk(
  ACTION_DSR_ADD_EMAIL_TEMPLATE,
  async (params: SaveDsrEmailTemplateParams, { rejectWithValue }) => {
    try {
      const result = await graphqlService.execute(queryMutationSaveDsrEmailTemplate(params))
      return result
    } catch (error: any) {
      return rejectWithValue({ statusMessage: error?.errors[0]?.message || '' })
    }
  }
)

export const ACTION_DSR_CLONE_EMAIL_TEMPLATE = 'dsr/email-template/clone'
export const cloneDsrEmailTemplate = createAsyncThunk(
  ACTION_DSR_CLONE_EMAIL_TEMPLATE,
  async (params: SaveDsrEmailTemplateParams, { rejectWithValue }) => {
    try {
      const result = await graphqlService.execute(queryMutationSaveDsrEmailTemplate(params))
      return result
    } catch (error: any) {
      return rejectWithValue({ statusMessage: error?.errors[0]?.message || '' })
    }
  }
)

export const ACTION_DSR_UPDATE_EMAIL_TEMPLATE = 'dsr/email-template/update'
export const updateDsrEmailTemplate = createAsyncThunk(
  ACTION_DSR_UPDATE_EMAIL_TEMPLATE,
  async (params: SaveDsrEmailTemplateParams) => {
    await graphqlService.execute(queryMutationSaveDsrEmailTemplate(params))
  }
)

export interface TestDsrEmailTemplateParams extends SaveDsrEmailTemplateParams {
  emails: string[]
}
export const ACTION_DSR_TEST_EMAIL_TEMPLATE = 'dsr/email-template/test-email'
export const testDsrEmailTemplate = createAsyncThunk(
  ACTION_DSR_TEST_EMAIL_TEMPLATE,
  async (params: TestDsrEmailTemplateParams) => {
    await graphqlService.execute(queryMutationSendTestEmail(params))
  }
)

export const ACTION_DSR_SAVE_EMAIL_TEMPLATE_SETTINGS = 'dsr/email-template/settings/save'
export const saveDsrEmailTemplateSettings = createAsyncThunk(
  ACTION_DSR_SAVE_EMAIL_TEMPLATE_SETTINGS,
  async (params: DsrDefaultTemplateSetting[]) => {
    await graphqlService.execute(queryMutationSaveDsrEmailTemplateSettings(params))
  }
)

export interface SystemValueInfo {
  name: string
  displayName: string
}
export interface DsrDefaultTemplateSetting {
  associatedTemplateId?: string
  requestType: string
  eventType?: DsrEmailTemplateTypes

  options?: NameIdSummary[]
}
export interface DsrDefaultTemplateSettings {
  eventType: DsrEmailTemplateTypes
  setting: DsrDefaultTemplateSetting[]
}

export interface DsrEventSystemValues {
  [key: string]: SystemValueInfo[]
}

/** Initial state */
type DsrEmailTemplatesState = {
  emailTemplates: {
    list: DsrEmailTemplate[]
    total: number
  }
  emailTemplate?: DsrEmailTemplate
  reportTemplates?: DsrEmailTemplate[]
  eventTypes?: DsrEmailTemplateTypes[]
  systemValues?: DsrEventSystemValues
  settings?: DsrDefaultTemplateSettings[]
  emailTemplatesByEventType?: { [key in DsrEmailTemplateTypes]?: DsrEmailTemplate }
}

const initialDsrFormsList = {
  list: [],
  total: 0
}

export const initialState: DsrEmailTemplatesState = {
  emailTemplates: initialDsrFormsList,
  emailTemplatesByEventType: {}
}

const dsrEmailTemplatesSlice = createSlice({
  name: 'dsrEmailTemplates',
  initialState,
  reducers: {
    resetDsrEmailTemplates: (state) => {
      state.emailTemplates = initialState.emailTemplates
    },
    resetReportTemplates: (state) => {
      state.reportTemplates = initialState.reportTemplates
    },
    resetEmailTemplate: (state) => {
      state.emailTemplate = initialState.emailTemplate
    },
    resetDsrEmailTemplateSettings: (state) => {
      state.settings = initialState.settings
    },
    resetDsrEmailEventTypes: (state) => {
      state.eventTypes = initialState.eventTypes
    },
    resetDsrSystemValues: (state) => {
      state.systemValues = initialState.systemValues
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDsrEmailTemplates.fulfilled, (state, { payload }) => {
      state.emailTemplates.list = payload.list
      state.emailTemplates.total = payload.total
    })
    builder.addCase(fetchDsrEmailTemplateById.fulfilled, (state, { payload }) => {
      state.emailTemplate = payload
    })
    builder.addCase(fetchDsrSystemValues.fulfilled, (state, { payload }) => {
      state.systemValues = { ...state.systemValues, [payload.eventType]: payload.list }
    })
    builder.addCase(fetchDsrEmailEventTypes.fulfilled, (state, { payload }) => {
      state.eventTypes = payload
    })
    builder.addCase(fetchDsrEmailTemplateSettings.fulfilled, (state, { payload }) => {
      state.settings = payload
    })
    builder.addCase(fetchDsrReportTemplates.fulfilled, (state, { payload }) => {
      state.reportTemplates = payload
    })
    builder.addCase(fetchDsrEmailTemplateByIdsForEventType.fulfilled, (state, { payload }) => {
      if (payload?.eventType) {
        if (!state.emailTemplatesByEventType) {
          state.emailTemplatesByEventType = {}
        }
        state.emailTemplatesByEventType[payload?.eventType] = payload
      }
    })
  }
})

export const {
  resetDsrEmailTemplates,
  resetEmailTemplate,
  resetDsrEmailEventTypes,
  resetDsrSystemValues,
  resetReportTemplates,
  resetDsrEmailTemplateSettings
} = dsrEmailTemplatesSlice.actions

export default dsrEmailTemplatesSlice.reducer
