import service from '../../../../../services/api/apiService'
import { SlackMembersWidgets, SlackWidgetQueryParams } from '../../../../../services/api/apiTypes'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface SlackMemberState {
  widget: SlackMembersWidgets
}

export const initialState: SlackMemberState = {
  widget: {
    piiDataTotalMembersCount: 0,
    riskyTotalMembersCount: 0,
    piiDataInternalMembersCount: 0,
    riskyInternalMembersCount: 0,
    piiDataGuestMembersCount: 0,
    riskyGuestMembersCount: 0
  }
}

export const fetchSlackMembersWidgetData = createAsyncThunk(
  'members/widget',
  async (filters: SlackWidgetQueryParams) => await service.getSlackMembersWidgetData(filters)
)

const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    resetWidget: (state) => {
      state.widget = initialState.widget
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSlackMembersWidgetData.fulfilled, (state, action) => {
      state.widget = action.payload
    })
  }
})

export const { resetWidget } = membersSlice.actions

export default membersSlice.reducer
