import {
  queryNoScanList,
  mapQueryNoScanList,
  queryRemoveFromNoScanList,
  queryOutlookNoScanList,
  mapQueryOutlookNoScanList
} from './queries'
import { FilterParams, SORT_ORDER } from '../../interfaces'
import { getSortDirection, SortParams } from '../../utils/sortUtil'
import graphqlService from '../../services/graphqlService'
import { DATA_SOURCE_ID } from '../../constants'
import { RootState } from '../../rootReducer'
import { getGlobalParams } from '../../utils/urlUtil'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface NoScanListParams extends FilterParams {
  page: number
  pageSize?: number
}
export const ACTION_NO_SCAN_LIST = 'noScanList/table'
export const fetchNoScanList = createAsyncThunk(
  ACTION_NO_SCAN_LIST,
  async (params: NoScanListParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const resultRaw = await graphqlService.execute(queryNoScanList(queryParams))
    return mapQueryNoScanList(resultRaw)
  }
)

export const ACTION_OUTLOOK_NO_SCAN_LIST = 'outlook/noScanList/table'
export const fetchOutlookNoScanList = createAsyncThunk(
  ACTION_OUTLOOK_NO_SCAN_LIST,
  async (params: NoScanListParams) => {
    const resultRaw = await graphqlService.execute(queryOutlookNoScanList(params))
    return mapQueryOutlookNoScanList(resultRaw)
  }
)

export interface DeleteFromNoScanListParams {
  [DATA_SOURCE_ID]: string
  ids: string[] // no scan item ids
}
export const ACTION_DELETE_FROM_NO_SCAN = 'noScanList/removeItems'
export const deleteFromNoScanList = createAsyncThunk(
  ACTION_DELETE_FROM_NO_SCAN,
  async (params: DeleteFromNoScanListParams) => {
    await graphqlService.execute(queryRemoveFromNoScanList(params))
    return params
  }
)

export type NoScanItem = {
  id: string
  type: string
  name: string
  objectLink: string
  groupName?: string
  owner?: string
  updateTimestamp: string
  projectName: string
  addedBy: string
}

export type OutlookNoScanItem = {
  id: string
  sender: string
  recipient: string
  subject: string
  folder: string
  addedOn: string
}

interface INoScanListTableData {
  sort: SortParams
  list?: NoScanItem[]
  total?: number
}

interface IOutLookNoScanListTableData {
  list: OutlookNoScanItem[]
  total: number
  sort: SortParams
}

const initialList = { sort: { column: 'updateTimestamp', direction: SORT_ORDER.DESC } }
const outlookNoScanListInitialState = {
  list: [],
  total: 0,
  sort: { column: 'addedOn', direction: SORT_ORDER.DESC }
}
interface NoScanListState {
  list: INoScanListTableData
  outlookNoScanList: IOutLookNoScanListTableData
}

export const initialState: NoScanListState = {
  list: { ...initialList },
  outlookNoScanList: { ...outlookNoScanListInitialState }
}

const noScanListSlice = createSlice({
  name: 'noScanList',
  initialState,
  reducers: {
    setSort: (state, { payload }) => {
      state.list.sort = getSortDirection(state.list.sort, payload.column)
      state.outlookNoScanList.sort = getSortDirection(state.outlookNoScanList.sort, payload.column)
    },
    resetLists: (state) => {
      state.list = { ...initialList }
    },
    resetOutlookNoScanList: (state) => {
      state.outlookNoScanList = { ...outlookNoScanListInitialState }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNoScanList.fulfilled, (state, { payload }) => {
      state.list.list = payload.list
      state.list.total = payload.total
    })
    builder.addCase(deleteFromNoScanList.fulfilled, (state, { payload }) => {
      state.list.list = state.list.list?.filter((item) => !payload.ids.includes(item.id))
      state.list.total = (state.list.total || 0) - payload.ids.length
    })
    builder.addCase(fetchOutlookNoScanList.fulfilled, (state, { payload }) => {
      state.outlookNoScanList.list = payload.list
      state.outlookNoScanList.total = payload.total
    })
  }
})

export const { setSort, resetLists, resetOutlookNoScanList } = noScanListSlice.actions
export default noScanListSlice.reducer
