import { parameterizeArrayofObjects } from '../../../utils/graphqlUtil'
import {
  CommunicationModeNode,
  DatasourceDetail,
  DatasourceListNode,
  DatasourceToAdd,
  SubscriptionNode
} from '../consentManagementSlice'
import { ConsentOptStatus } from '../constants'
import { gql } from 'graphql-request'

type subscriptionresult = {
  list: SubscriptionNode[]
  count: number
}

export const queryConsentManagementSettingsDatasourceList = (datasourceTypes: string[]): string => {
  return datasourceTypes.length > 0
    ? gql`
   {
  consentManagementSettings {
    edges {
      node {
        datasource {
          edges {
            node {
              communicationPreferences {
                count
              }
              type
              name
              id
              consentHistory(filter:[{key:CREATED_BY,values:${JSON.stringify(datasourceTypes)}}]) {
                count
              }
            }
          }
        }
        alertCount
      }
    }
  }
}

  `
    : ''
}

export const mapConsentManagementSettingsDatasourceList = (
  raw: any
): { datasourceList: DatasourceListNode[] } => {
  const mappedData: DatasourceListNode[] = []

  raw?.consentManagementSettings?.edges?.forEach((edge) => {
    edge?.node?.datasource?.edges?.forEach((ds) => {
      mappedData.push({
        icon: ds?.node?.type,
        title: ds?.node?.name,
        subscriptionCount: ds?.node?.communicationPreferences?.count,
        consents: ds?.node?.consentHistory?.count,
        alerts: edge?.node?.alertCount,
        id: ds?.node?.id
      })
    })
  })

  return { datasourceList: mappedData || [] }
}

export const queryConsentManagementSettingsDatasourcesToAdd = (
  datasourceTypes: string[]
): string => {
  return gql`
    {
      datasources(filter: [{key:TYPE,values:${JSON.stringify(datasourceTypes)}}]) {
        edges {
          node {
            id
            name
            type
          }
        }
      }
    }
  `
}

export const mapConsentManagementSettingsDatasourcesToAdd = (raw: any): DatasourceToAdd[] => {
  return raw?.datasources?.edges?.map((edge) => ({
    id: edge?.node?.id,
    name: edge?.node?.name,
    type: edge?.node?.type
  }))
}

export const querySubscription = (id: string) => {
  return gql`
    {
      communicationPreferences (id: "${id}") {
        edges {
          node {
            name
            description
            type
            configuration {
              ... on HubspotCommunicationPreference {
                __typename
              }
              ... on CustomCommunicationPreference {
                __typename
                communicationMethods {
                  communicationMethod
                  displayCommunicationMethod
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapSubscription = (raw: any) => {
  const subscription = raw?.communicationPreferences?.edges?.[0]?.node
  const communicationMethods = subscription?.configuration?.communicationMethods || []
  return {
    name: subscription?.name,
    description: subscription?.description,
    type: subscription?.type,
    communicationMethods
  }
}

export const querySubscriptionList = (params): string => {
  const filtersParameterized = parameterizeArrayofObjects(params.filters || [])
  const filterParams = filtersParameterized ? `(filter: ${filtersParameterized})` : ''
  return gql`
    {
      communicationPreferences ${filterParams} {
        count
        edges {
          node {
            id
            name
            description
            type
            configuration{
              ... on HubspotCommunicationPreference
              {
                __typename
              }
              ... on CustomCommunicationPreference
              {
                __typename
                communicationMethods {
                  communicationMethod
                  displayCommunicationMethod
                }
              }
            }
            optin: consentHistory(filter: [{ key: OPT_STATUS, values: ["${ConsentOptStatus.Subscribed}"] }]) {
              count
            }
            optout: consentHistory(filter: [{ key: OPT_STATUS, values: ["${ConsentOptStatus.UnSubscribed}"] }]) {
              count
            }
            preferenceCenter {
              count
              edges {
                node {
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQuerySubscriptionList = (raw: any): subscriptionresult => {
  const count = raw?.communicationPreferences?.count || 0
  const mappedData: SubscriptionNode[] = []

  raw?.communicationPreferences?.edges?.forEach((preferenceNode) => {
    const preference = preferenceNode?.node

    const communicationModeCount: number =
      preference?.type === 'HUBSPOT'
        ? 1
        : Array.isArray(preference?.configuration?.communicationMethods)
        ? preference?.configuration?.communicationMethods?.length
        : 1

    const preferenceCenterNames = preference?.preferenceCenter?.edges?.map(
      (center) => center?.node?.name
    )

    mappedData.push({
      id: preference?.id,
      subscriptionName: preference?.name,
      description: preference?.description,
      optOutCount: preference?.optout?.count,
      optInCount: preference?.optin?.count,
      communicationModeCount,
      preferenceCenterCount: preference?.preferenceCenter?.count,
      preferenceCenterNames
    })
  })

  return { list: mappedData || [], count }
}

export const mapQueryConsentLogSubscriptionFilters = (raw: any) => {
  return raw?.communicationPreferences?.edges?.map(({ node }) => ({ id: node.id, name: node.name }))
}

export const queryConsentManagementSettingsSubscriptionListCommunicationMode = (
  datasourceId: string,
  preferenceId: string
): string => {
  return gql`
    {
      consentHistoryGroupByCommunicationType(
        filter: [
          { key: DATASOURCE_IDS, values: "${datasourceId}" },
          { key: PREFERENCE, values: ["${preferenceId}"] }
        ]
      ) {
        edges {
          node {
            communicationType
            optin: consentHistory(
              filter: [
                { key: DATASOURCE_IDS, values: "${datasourceId}" },
                { key: PREFERENCE, values: ["${preferenceId}"] },
                { key: OPT_STATUS, values: ["${ConsentOptStatus.Subscribed}"] }
              ]
            ) {
              count
            }
            optout: consentHistory(
              filter: [
               { key: DATASOURCE_IDS, values: "${datasourceId}" },
              { key: PREFERENCE, values: ["${preferenceId}"] },
                { key: OPT_STATUS, values: ["${ConsentOptStatus.UnSubscribed}"] }
              ]
            ) {
              count
            }
          }
        }
      }
    }
  `
}

export const mapConsentManagementSettingsSubscriptionListCommunicationMode = (
  raw: any
): CommunicationModeNode[] => {
  return (
    raw.consentHistoryGroupByCommunicationType?.edges?.map((edge) => {
      const { communicationType, optin, optout } = edge?.node

      return {
        modeName: communicationType,
        optIn: optin?.count,
        optOut: optout?.count
      }
    }) || []
  )
}

export const queryConsentManagementSettingsDatasourceDetail = (
  datasourceDetailId: string,
  datasourceTypes: string[]
): string => {
  return datasourceDetailId && datasourceTypes.length > 0
    ? gql`{
   datasources(id:"${datasourceDetailId}"){
    edges{
      node{
        type
        name
        consentHistory(filter:[{key:CREATED_BY,values:${JSON.stringify(datasourceTypes)}}]){
          count
        }
        communicationPreferences{
          count
        }
        preferenceCenter{
          edges{
            node{
              id
              name
            }
          }
        }
      }
    }
    }
    }
  `
    : ''
}

export const mapConsentManagementSettingsDatasourceDetail = (raw: any): DatasourceDetail => {
  const mappedData = raw?.datasources?.edges[0]?.node || {}

  return {
    name: mappedData?.name,
    type: mappedData?.type,
    consentHistoryCount: mappedData?.consentHistory?.count,
    communicationPreferencesCount: mappedData?.communicationPreferences?.count,
    preferenceCenterNamesIds: mappedData?.preferenceCenter?.edges?.map((center) => center?.node)
  }
}

//mutation update datasource from consent management
export const mutationConsentManagementUpdateDatasourcesList = (datasourceId: string) => {
  return gql`mutation {
    updateDatasourcesConsentManagement(clientMutationId: "update_datasource",datasourceId: "${datasourceId}") {
      clientMutationId
      datasourceId
    }
  }
   `
}

//mutation removing datasource from consent management
export const mutationConsentManagementDeleteDatasource = (datasourceId: string) => {
  return gql`mutation{
    removeConsentManagementDatasource(
      clientMutationId:"delete_datasource",
      datasourceId:"${datasourceId}"
    ){
      datasourceId
      clientMutationId
    }
  }
   `
}

export const queryConsentManagementSupportedDataSourceTypes = (): string => {
  return gql`
    {
      consentManagementSettings {
        edges {
          node {
            datasourceTypes
          }
        }
      }
    }
  `
}

export const mapConsentManagementSupportedDataSourceTypes = (raw: any): string[] => {
  const mappedData = raw?.consentManagementSettings?.edges?.[0]?.node?.datasourceTypes
  return mappedData || []
}

export const queryConsentManagementSettingsPreferenceCenterListByDatasourceId = (
  datasourceId: string
): string => {
  return gql`
    {
      preferenceCenter(filter: [{key: DATASOURCE_ID, values: ["${datasourceId}"]}]) {
        edges {
          node {
            id
            preferenceCenterGroupId
				    name
            datasource{
          edges{
            node{
              id
              name
              type
            }
          }
        }
          }
        }
      }
    }
    `
}

export const mapConsentManagementSettingsPreferenceCenterListByDatasourceId = (raw: any) => {
  return raw?.preferenceCenter?.edges?.map((edge) => ({
    id: edge?.node?.id,
    preferenceCenterGroupId: edge?.node?.preferenceCenterGroupId,
    name: edge?.node?.name,
    datasourceList: edge?.node?.datasource?.edges?.map((ds) => ({
      id: ds?.node?.id,
      name: ds?.node?.name,
      type: ds?.node?.type
    }))
  }))
}

export const mutationConsentManagementScanDatasource = (datasourceId: string) => {
  return gql`mutation{
      consentManagementDatasourceScanSync(
        datasourceId:"${datasourceId}",
        clientMutationId:"scan_datasource"
      ){
        clientMutationId
        datasourceId
      }
    }
   `
}
