import service from '../../services/api/apiService'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import { IGetResidenciesParams, IResidency } from '../../interfaces'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export type ResidenciesListSettings = {
  list?: IResidency[]
  total?: number
  sort: SortParams
}

type ResidenciesState = {
  all: ResidenciesListSettings
  risky: ResidenciesListSettings
  sensitive: ResidenciesListSettings
  isResidenciesFetched: boolean
}

const initialList = { sort: defaultSortParams }
export const initialState: ResidenciesState = {
  all: { ...initialList },
  risky: { ...initialList },
  sensitive: { ...initialList },
  isResidenciesFetched: false
}
export interface IExtendedGetREsidenciesParams extends IGetResidenciesParams {
  is_limit?: boolean
}
export const fetchResidenciesList = createAsyncThunk(
  'residencies/list',
  async (params: IExtendedGetREsidenciesParams) => await service.getResidenciesList(params)
)

const residenciesSlice = createSlice({
  name: 'residencies',
  initialState,
  reducers: {
    setSort: (state, { payload }) => {
      state[payload.list].sort = getSortDirection(state[payload.list].sort, payload.column)
    },
    resetLists: (state) => {
      state.all = initialState.all
      state.risky = initialState.risky
      state.sensitive = initialState.sensitive
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResidenciesList.pending, (state) => {
      state.isResidenciesFetched = false
    })
    builder.addCase(fetchResidenciesList.rejected, (state) => {
      state.isResidenciesFetched = false
    })
    builder.addCase(fetchResidenciesList.fulfilled, (state, { payload }) => {
      const { list, total } = payload

      if (payload.risky) {
        state.risky.list = list
        state.risky.total = total
      } else if (payload.sensitive) {
        state.sensitive.list = list
        state.sensitive.total = total
      } else {
        state.all.list = list
        state.all.total = total
      }
      state.isResidenciesFetched = true
    })
  }
})

export const { setSort, resetLists } = residenciesSlice.actions

export default residenciesSlice.reducer
