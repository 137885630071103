import './databases.less'
import {
  ACTION_DATABASES_BY_PII_TABLES,
  fetchDatabasesByPiiTables,
  WidgetDatabaseByPiiTables,
  WidgetDatabaseByPiiTablesParams
} from './databasesSlice'
import { getShowLoader } from '../../reducers/requestReducer'
import { RootState } from '../../rootReducer'
import useUrlContext from '../../hooks/useUrlContext'
import { DATA_SOURCE_ID } from '../../constants'
import ChartPie, { ChartPieItem } from '../../components/ChartPie/chartPie'
import NoSensitiveDataSvg from '../../assets/inlineSvg/noData.svg'
import LoaderView from '../../components/Loader/loader'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { Typography } from '@lightbeamai/design-system'

const TOP_DATABASE_COUNT = 5

interface IProps {
  dataSourceId?: string
  databasesByPiiTables: WidgetDatabaseByPiiTables[]
  showLoader: boolean
  legendPosition?: 'bottom' | 'right'
  piClass?: string
  chartSize?: number
  isAnimated?: boolean
  fetchDatabasesByPiiTables: (params: WidgetDatabaseByPiiTablesParams) => void
}

const WidgetDatabasesByPiiTables = (props: IProps): React.ReactElement => {
  const {
    dataSourceId = '',
    databasesByPiiTables = [],
    showLoader = false,
    legendPosition = 'bottom',
    piClass = 'xs-mb-36 xs-mt-36',
    chartSize = 180,
    isAnimated = true,
    fetchDatabasesByPiiTables
  } = props
  const intl = useIntl()
  const context = useUrlContext()
  const datasourceId = dataSourceId || context.contextFilters[DATA_SOURCE_ID] || ''

  useEffect(() => {
    fetchDatabasesByPiiTables({ datasourceId })
  }, [datasourceId, fetchDatabasesByPiiTables])

  const list = databasesByPiiTables.find((item) => item.datasourceId === datasourceId)?.list || []

  const colors = [
    '#225EA8',
    '#41B6C4',
    '#A1DAB4',
    '#7EBEA3',
    '#E2E3A9',
    '#225EA8',
    '#41B6C4',
    '#A1DAB4',
    '#7EBEA3',
    '#E2E3A9'
  ]
  const chartData: ChartPieItem[] = list
    .slice(0, TOP_DATABASE_COUNT)
    .map(({ database, piiTablesCount }, i) => ({
      key: database,
      filterKey: database,
      value: piiTablesCount,
      color: colors[i]
    }))

  return (
    <div
      className="widget-databases-by-pii-tables"
      data-test-id="databases-widget-databases-by-pii-tables"
    >
      <div className="dashboard-container-title centered xs-mb-0">
        <Typography as="span" variant="h6" color="primary">
          {intl.formatMessage({ id: 'databases.widget.title' })}
        </Typography>
      </div>
      <div
        className={`widget-database-container ${
          legendPosition === 'bottom' ? 'vertical' : 'horizontal'
        }`}
      >
        {chartData.length ? (
          <div className="chart-wrapper">
            <ChartPie
              data={chartData.slice().reverse()}
              width={chartSize}
              gap={5}
              showHalfCircle
              centerCountStyles={{ fontSize: '2.1rem', fontWeight: 700 }}
              className={piClass}
              isAnimated={isAnimated}
            />
            {legendPosition === 'right' && (
              <div className="chart-text">
                {intl.formatMessage({ id: 'databases.widget.piiTables' })}
              </div>
            )}
          </div>
        ) : (
          <div className="icon-box empty-state-text">
            <NoSensitiveDataSvg />
          </div>
        )}
        <div className="legend">
          {chartData.length ? (
            <>
              {legendPosition === 'right' && (
                <div className="legend-horizontal-title item">
                  <span className="key">
                    {intl.formatMessage(
                      { id: 'databases.widget.topDatabases' },
                      { count: TOP_DATABASE_COUNT }
                    )}
                  </span>
                  <span className="value">
                    {intl.formatMessage({ id: 'databases.widget.tables' })}
                  </span>
                </div>
              )}
              {chartData.map((item, i) => {
                return (
                  <div className="item btn" key={i}>
                    <div className="circle" style={{ backgroundColor: item.color }} />
                    <span className="key truncate">{item.key}</span>&nbsp;
                    {legendPosition === 'bottom' ? (
                      <span className="value">({item.value})</span>
                    ) : (
                      <span className="value">{item.value}</span>
                    )}
                  </div>
                )
              })}
            </>
          ) : (
            <div className="empty-state-text">
              {intl.formatMessage({ id: 'dashboard.widget.noSensitiveData' })}
            </div>
          )}
        </div>
        <LoaderView showLoader={showLoader} />
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  databasesByPiiTables: state.databases.widgetDatabasesByPiiTables,
  showLoader: getShowLoader(ACTION_DATABASES_BY_PII_TABLES)
})

const mapDispatchToProps = { fetchDatabasesByPiiTables }

export default connect(mapStateToProps, mapDispatchToProps)(WidgetDatabasesByPiiTables)
