/**This is autogenerated file, please do not change */
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  BigInt: any
  Character: any
  Date: any
  DateTime: any
  GrapheneObjectId: any
  JSONString: any
}

export type ApiStats = {
  __typename?: 'APIStats'
  successfulCalls?: Maybe<Scalars['Int']>
  failedCalls?: Maybe<Scalars['Int']>
}

export type Awss3DatasourceConfig = {
  __typename?: 'AWSS3DatasourceConfig'
  accessKey: Scalars['String']
  secretKey: Scalars['String']
  filterDate?: Maybe<Scalars['DateTime']>
}

export type AggregationResponse = Classifications & {
  __typename?: 'AggregationResponse'
  classificationType?: Maybe<Scalars['String']>
  groupingFieldValue?: Maybe<Scalars['String']>
  aggregatedValue?: Maybe<Scalars['Int']>
}

export type Alert = {
  __typename?: 'Alert'
  id: Scalars['GrapheneObjectId']
  name: Scalars['String']
  generatedAt: Scalars['DateTime']
  lastModifiedTime?: Maybe<Scalars['DateTime']>
  severity: AlertSeverity
  acknowledged?: Maybe<Scalars['Boolean']>
  status: AlertStatus
  resolutionReason?: Maybe<Scalars['String']>
  groupId?: Maybe<Scalars['GrapheneObjectId']>
  groupName?: Maybe<Scalars['String']>
  assignees: Array<Maybe<Scalars['String']>>
  reassignComment?: Maybe<Scalars['String']>
  actions?: Maybe<Array<Maybe<AlertActions>>>
  objectCount?: Maybe<Scalars['Int']>
  ticketsCount?: Maybe<Scalars['Int']>
  entitiesImpacted?: Maybe<Array<Maybe<EntitiesImpacted>>>
  attributes?: Maybe<Array<Maybe<Attributes>>>
  externalUser?: Maybe<Array<Maybe<ExternalUser>>>
  lastOccurred?: Maybe<Scalars['DateTime']>
  policy?: Maybe<PolicyConnection>
  datasource?: Maybe<DatasourceConnection>
  subAlerts?: Maybe<SubAlertConnection>
}

export type AlertExternalUserArgs = {
  user?: InputMaybe<Scalars['String']>
}

export type AlertPolicyArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['Boolean']>
  type?: InputMaybe<PolicyTypes>
  dateFilter?: InputMaybe<PolicyDateFilterInput>
  filter?: InputMaybe<Array<InputMaybe<PolicyFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<PolicyNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AlertDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AlertSubAlertsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  status?: InputMaybe<AlertStatus>
  userEntityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  severity?: InputMaybe<AlertSeverity>
  intRangeFilter?: InputMaybe<SubAlertIntRangeFilterInput>
  dateFilter?: InputMaybe<SubAlertDateFilterInput>
  externalUser?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Array<InputMaybe<SubAlertFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<SubAlertNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AlertActionPayload = {
  __typename?: 'AlertActionPayload'
  clientMutationId?: Maybe<Scalars['ID']>
  status?: Maybe<Scalars['Boolean']>
}

export enum AlertActionTypes {
  Resolve = 'RESOLVE',
  Reassign = 'REASSIGN',
  Mute = 'MUTE',
  Permit = 'PERMIT',
  NoScan = 'NO_SCAN',
  Unmute = 'UNMUTE'
}

export type AlertActions = {
  __typename?: 'AlertActions'
  type?: Maybe<AlertActionTypes>
  comment?: Maybe<Scalars['String']>
  muteEndTime?: Maybe<Scalars['DateTime']>
  resolvedReason?: Maybe<ResolvedReasons>
  updateTimestamp?: Maybe<Scalars['DateTime']>
  subAlertIds?: Maybe<Array<Maybe<Scalars['GrapheneObjectId']>>>
}

export enum AlertBaseFilterKey {
  Name = 'NAME',
  PolicyInstance = 'POLICY_INSTANCE',
  PolicyType = 'POLICY_TYPE',
  Status = 'STATUS',
  Severity = 'SEVERITY',
  DatasourceType = 'DATASOURCE_TYPE',
  DriveIds = 'DRIVE_IDS',
  PartnerIds = 'PARTNER_IDS'
}

export type AlertConnection = {
  __typename?: 'AlertConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<AlertEdge>>
  count?: Maybe<Scalars['Int']>
}

export type AlertDateFilterInput = {
  key: AlertDateFilterKey
  start?: InputMaybe<Scalars['BigInt']>
  end?: InputMaybe<Scalars['BigInt']>
}

export enum AlertDateFilterKey {
  GeneratedAt = 'GENERATED_AT',
  LastModifiedTime = 'LAST_MODIFIED_TIME'
}

export type AlertEdge = {
  __typename?: 'AlertEdge'
  node?: Maybe<Alert>
  cursor: Scalars['String']
}

export type AlertFilterInput = {
  key: AlertBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type AlertGroup = {
  __typename?: 'AlertGroup'
  datasourceType: Scalars['String']
  alertType?: Maybe<Scalars['String']>
  subAlertType?: Maybe<Scalars['String']>
}

export type AlertGroupByDatasource = {
  __typename?: 'AlertGroupByDatasource'
  datasourceType?: Maybe<DatasourceType>
  objectCount?: Maybe<Scalars['Int']>
  entitiesImpactedCount?: Maybe<Scalars['Int']>
  policyViolatedCount?: Maybe<Scalars['Int']>
  alertCount?: Maybe<Scalars['Int']>
  datasource?: Maybe<DatasourceConnection>
  alerts?: Maybe<AlertConnection>
}

export type AlertGroupByDatasourceDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AlertGroupByDatasourceAlertsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  status?: InputMaybe<AlertStatus>
  severity?: InputMaybe<AlertSeverity>
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  dateFilter?: InputMaybe<AlertDateFilterInput>
  filter?: InputMaybe<Array<InputMaybe<AlertFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AlertNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AlertGroupByDatasourceConnection = {
  __typename?: 'AlertGroupByDatasourceConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<AlertGroupByDatasourceEdge>>
  count?: Maybe<Scalars['Int']>
}

export type AlertGroupByDatasourceEdge = {
  __typename?: 'AlertGroupByDatasourceEdge'
  node?: Maybe<AlertGroupByDatasource>
  cursor: Scalars['String']
}

export enum AlertNode {
  Name = 'NAME',
  CreatedAt = 'CREATED_AT',
  LastModifiedTime = 'LAST_MODIFIED_TIME',
  Severity = 'SEVERITY',
  Status = 'STATUS',
  Assignees = 'ASSIGNEES',
  Datasource = 'DATASOURCE'
}

export enum AlertRange {
  Today = 'TODAY',
  Age_1_7 = 'AGE_1_7',
  Age_8_14 = 'AGE_8_14',
  Age_15_30 = 'AGE_15_30',
  Age_30 = 'AGE_30_'
}

export enum AlertSettings {
  Attribute = 'ATTRIBUTE',
  AttributeAndEntity = 'ATTRIBUTE_AND_ENTITY'
}

export enum AlertSeverity {
  Info = 'INFO',
  Warning = 'WARNING',
  Critical = 'CRITICAL'
}

export enum AlertStatus {
  ActiveStatus = 'ACTIVE_STATUS',
  InactiveStatus = 'INACTIVE_STATUS',
  ResolvedStatus = 'RESOLVED_STATUS',
  IgnoredStatus = 'IGNORED_STATUS'
}

export type AlertSummary = {
  __typename?: 'AlertSummary'
  range?: Maybe<AlertRange>
  value?: Maybe<Scalars['Int']>
}

export enum AlertSummaryBaseFilterKey {
  DriveIds = 'DRIVE_IDS',
  PartnerIds = 'PARTNER_IDS',
  Status = 'STATUS'
}

export type AlertSummaryFilterInput = {
  key: AlertSummaryBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type AnchorTableCluster = TableCluster & {
  __typename?: 'AnchorTableCluster'
  tables?: Maybe<TableConnection>
  columns?: Maybe<ColumnConnection>
  id: Scalars['String']
  name: Scalars['String']
  entityScanDetails: EntityScanDetails
  isReviewed: Scalars['Boolean']
}

export type AnchorTableClusterTablesArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  intRangeFilter?: InputMaybe<TableIntRangeFilterInput>
  dateFilter?: InputMaybe<TableDateFilterInput>
  toggleFilter?: InputMaybe<TableToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<TableBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<TableFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<TableNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AnchorTableClusterColumnsArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ColumnNode>
  toggleFilter?: InputMaybe<ColumnToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ColumnBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ColumnFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type Assignee = {
  __typename?: 'Assignee'
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type AttachmentDetails = {
  __typename?: 'AttachmentDetails'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type Attribute = Node & {
  __typename?: 'Attribute'
  id: Scalars['ID']
  name: Scalars['String']
  internalName?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  sensitivityLabel?: Maybe<SensitivityLabel>
  enableMasking?: Maybe<Scalars['Boolean']>
  maskingStrategy?: Maybe<MaskingStrategy>
  maskingStrategyConfiguration?: Maybe<MaskingStrategyConfig>
  systemDefined?: Maybe<Scalars['Boolean']>
  sampleData?: Maybe<Array<Maybe<Scalars['String']>>>
  alias?: Maybe<Array<Maybe<Scalars['String']>>>
  createdBy?: Maybe<Scalars['GrapheneObjectId']>
  context?: Maybe<Array<Maybe<Context>>>
  supportedLanguage?: Maybe<SupportedLanguage>
  blacklist?: Maybe<Array<Maybe<Scalars['String']>>>
  patterns?: Maybe<Array<Maybe<Pattern>>>
  attributeSets?: Maybe<AttributeSetConnection>
  instanceCount?: Maybe<Scalars['Int']>
  datasourceCounts?: Maybe<Array<Maybe<DatasourceCount>>>
  attributeInstance?: Maybe<AttributeInstanceConnection>
  objects?: Maybe<ObjectConnection>
  channels?: Maybe<ChannelConnection>
}

export type AttributeAttributeSetsArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AttributeAttributeInstanceArgs = {
  alertId?: InputMaybe<Scalars['GrapheneObjectId']>
  entityId?: InputMaybe<Scalars['GrapheneObjectId']>
  filter?: InputMaybe<Array<InputMaybe<AttributeInstanceFilterInput>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  channelIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  attributeSets?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  hasAttachments?: InputMaybe<Scalars['Boolean']>
  sensitivity?: InputMaybe<Array<InputMaybe<SensitivityLabel>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AttributeObjectsArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AttributeChannelsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ChannelTypes>>>
  alertId?: InputMaybe<Scalars['GrapheneObjectId']>
  alertStatus?: InputMaybe<AlertStatus>
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  intRangeFilter?: InputMaybe<ChannelIntRangeFilterInput>
  toggleFilter?: InputMaybe<ChannelToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<ChannelFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ChannelNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum AttributeBaseFilterKey {
  Name = 'NAME',
  Sensitivity = 'SENSITIVITY',
  CreatedBy = 'CREATED_BY',
  DriveIds = 'DRIVE_IDS',
  PartnerIds = 'PARTNER_IDS',
  ProjectIds = 'PROJECT_IDS',
  UserEntityIds = 'USER_ENTITY_IDS'
}

export type AttributeBooleanFilterInput = {
  key: AttributeBooleanFilterKey
  value: Scalars['Boolean']
}

export enum AttributeBooleanFilterKey {
  IsCategory = 'IS_CATEGORY'
}

export type AttributeConnection = {
  __typename?: 'AttributeConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<AttributeEdge>>
  count?: Maybe<Scalars['Int']>
}

export type AttributeDetection = {
  __typename?: 'AttributeDetection'
  value: Scalars['String']
  redactedValue?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Int']>
  boundingBox?: Maybe<BoundingBox>
}

export type AttributeEdge = {
  __typename?: 'AttributeEdge'
  node?: Maybe<Attribute>
  cursor: Scalars['String']
}

export type AttributeFilterInput = {
  key: AttributeBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type AttributeInstance = Node & {
  __typename?: 'AttributeInstance'
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  internalName?: Maybe<Scalars['String']>
  sensitivity?: Maybe<Scalars['String']>
  values?: Maybe<Scalars['String']>
  object?: Maybe<ObjectConnection>
  updateTimestamp?: Maybe<Scalars['DateTime']>
  datasource?: Maybe<DatasourceConnection>
  channel?: Maybe<ChannelConnection>
  project?: Maybe<ProjectConnection>
  attributeSets?: Maybe<AttributeSetConnection>
}

export type AttributeInstanceObjectArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AttributeInstanceDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AttributeInstanceChannelArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AttributeInstanceProjectArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AttributeInstanceAttributeSetsArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum AttributeInstanceBaseFilterKey {
  Name = 'NAME',
  DriveIds = 'DRIVE_IDS',
  ProjectIds = 'PROJECT_IDS',
  Classification = 'CLASSIFICATION',
  PartnerIds = 'PARTNER_IDS'
}

export type AttributeInstanceConnection = {
  __typename?: 'AttributeInstanceConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<AttributeInstanceEdge>>
  count?: Maybe<Scalars['Int']>
}

export type AttributeInstanceEdge = {
  __typename?: 'AttributeInstanceEdge'
  node?: Maybe<AttributeInstance>
  cursor: Scalars['String']
}

export type AttributeInstanceFilterInput = {
  key: AttributeInstanceBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type AttributeInstanceGroupedbyDatasource = {
  __typename?: 'AttributeInstanceGroupedbyDatasource'
  datasource: DatasourceConnection
  attributeInstanceCount?: Maybe<Scalars['Int']>
}

export type AttributeInstanceGroupedbyDatasourceDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AttributeInstanceGroupedbyDocumentClass = {
  __typename?: 'AttributeInstanceGroupedbyDocumentClass'
  documentClass?: Maybe<Scalars['String']>
  attributeInstanceCount?: Maybe<Scalars['Int']>
}

export type AttributeInstanceGroupedbyName = {
  __typename?: 'AttributeInstanceGroupedbyName'
  name?: Maybe<Scalars['String']>
  internalName?: Maybe<Scalars['String']>
  sensitivity?: Maybe<Scalars['String']>
  attributeInstanceCount?: Maybe<Scalars['Int']>
  datasources?: Maybe<DatasourceConnection>
  mail?: Maybe<MailConnection>
  objects?: Maybe<ObjectConnection>
  tickets?: Maybe<TicketConnection>
}

export type AttributeInstanceGroupedbyNameDatasourcesArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AttributeInstanceGroupedbyNameMailArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  riskLevel?: InputMaybe<Array<InputMaybe<EmailRiskLevel>>>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  hasAttachments?: InputMaybe<Scalars['Boolean']>
  intRangeFilter?: InputMaybe<EmailIntRangeFilterInput>
  toggleFilter?: InputMaybe<EmailToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<EmailBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<EmailFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<MailNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AttributeInstanceGroupedbyNameObjectsArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AttributeInstanceGroupedbyNameTicketsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  ticketId?: InputMaybe<Scalars['String']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  booleanFilter?: InputMaybe<Array<InputMaybe<TicketBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<TicketFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AttributeInstanceGroupedbyProject = {
  __typename?: 'AttributeInstanceGroupedbyProject'
  project: ProjectConnection
  attributeInstanceCount?: Maybe<Scalars['Int']>
}

export type AttributeInstanceGroupedbyProjectProjectArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  filter?: InputMaybe<Array<InputMaybe<ProjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ProjectNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum AttributeNode {
  Name = 'NAME'
}

export type AttributePayload = {
  __typename?: 'AttributePayload'
  clientMutationId?: Maybe<Scalars['ID']>
  attribute?: Maybe<AttributeConnection>
}

export type AttributePayloadAttributeArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AttributeSet = Node & {
  __typename?: 'AttributeSet'
  id: Scalars['ID']
  name: Scalars['String']
  type: Type
  description?: Maybe<Scalars['String']>
  systemDefined?: Maybe<Scalars['Boolean']>
  enabled?: Maybe<Scalars['Boolean']>
  createdBy?: Maybe<Scalars['GrapheneObjectId']>
  attributes?: Maybe<AttributeConnection>
  attributesInstanceCount?: Maybe<Scalars['Int']>
  attributesDatasourceCounts?: Maybe<Array<Maybe<DatasourceCount>>>
}

export type AttributeSetAttributesArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum AttributeSetBaseFilterKey {
  Name = 'NAME',
  CreatedBy = 'CREATED_BY'
}

export type AttributeSetConnection = {
  __typename?: 'AttributeSetConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<AttributeSetEdge>>
  count?: Maybe<Scalars['Int']>
}

export type AttributeSetEdge = {
  __typename?: 'AttributeSetEdge'
  node?: Maybe<AttributeSet>
  cursor: Scalars['String']
}

export type AttributeSetFilterInput = {
  key: AttributeSetBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export enum AttributeSetNode {
  Name = 'NAME'
}

export type AttributeSetPayload = {
  __typename?: 'AttributeSetPayload'
  clientMutationId?: Maybe<Scalars['ID']>
  attributeSet?: Maybe<AttributeSetConnection>
}

export type AttributeSetPayloadAttributeSetArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type Attributes = {
  __typename?: 'Attributes'
  attributeId?: Maybe<Scalars['GrapheneObjectId']>
  attributeName?: Maybe<Scalars['String']>
  objectCount?: Maybe<Scalars['Int']>
}

export type BoundingBox = {
  __typename?: 'BoundingBox'
  leftUpperX1: Scalars['Int']
  leftUpperY1: Scalars['Int']
  rightLowerX2: Scalars['Int']
  rightLowerY2: Scalars['Int']
}

export type Bucket = {
  __typename?: 'Bucket'
  id: Scalars['GrapheneObjectId']
  name?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['BigInt']>
  region?: Maybe<Scalars['String']>
  isHistoricalSyncCompleted?: Maybe<Scalars['String']>
  ownerId?: Maybe<Scalars['String']>
  ownerName?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  documentsCount?: Maybe<Scalars['Int']>
  piiDocumentsCount?: Maybe<Scalars['Int']>
  entitiesCount?: Maybe<Scalars['Int']>
  syncFromTime?: Maybe<Scalars['String']>
  syncToTime?: Maybe<Scalars['DateTime']>
}

export type BucketConnection = {
  __typename?: 'BucketConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<BucketEdge>>
  count?: Maybe<Scalars['Int']>
}

export type BucketEdge = {
  __typename?: 'BucketEdge'
  node?: Maybe<Bucket>
  cursor: Scalars['String']
}

export type Channel = {
  __typename?: 'Channel'
  id: Scalars['GrapheneObjectId']
  channelId?: Maybe<Scalars['String']>
  channelName?: Maybe<Scalars['String']>
  type?: Maybe<ChannelTypes>
  membersCount?: Maybe<Scalars['Int']>
  guestsCount?: Maybe<Scalars['Int']>
  piiDocumentsCount?: Maybe<Scalars['Int']>
  piiConversationsCount?: Maybe<Scalars['Int']>
  entitiesCount?: Maybe<Scalars['Int']>
  totalMessagesCount?: Maybe<Scalars['Int']>
  sentBy?: Maybe<Array<Maybe<Scalars['String']>>>
  datasource?: Maybe<DatasourceConnection>
  objects?: Maybe<ObjectConnection>
  userEntities?: Maybe<UserEntityConnection>
  attributes?: Maybe<AttributeConnection>
}

export type ChannelDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ChannelObjectsArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ChannelUserEntitiesArgs = {
  isSensitive?: InputMaybe<Scalars['Boolean']>
  database?: InputMaybe<Scalars['String']>
  schema?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<EntityTypes>>>
  toggleFilter?: InputMaybe<EntityToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<EntityFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<EntityBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<UserEntityNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ChannelAttributesArgs = {
  id?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  systemDefined?: InputMaybe<Scalars['Boolean']>
  attributeSets?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  sensitivity?: InputMaybe<Array<InputMaybe<SensitivityLabel>>>
  hasInstances?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Array<InputMaybe<AttributeFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<AttributeBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AttributeNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum ChannelBaseFilterKey {
  Name = 'NAME',
  Type = 'TYPE',
  IsSensitive = 'IS_SENSITIVE',
  IsRisky = 'IS_RISKY'
}

export type ChannelConnection = {
  __typename?: 'ChannelConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<ChannelEdge>>
  count?: Maybe<Scalars['Int']>
}

export type ChannelEdge = {
  __typename?: 'ChannelEdge'
  node?: Maybe<Channel>
  cursor: Scalars['String']
}

export type ChannelFilterInput = {
  key: ChannelBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type ChannelIntRangeFilterInput = {
  key: ChannelIntRangeFilterKey
  min?: InputMaybe<Scalars['Int']>
  max?: InputMaybe<Scalars['Int']>
}

export enum ChannelIntRangeFilterKey {
  MembersCount = 'MEMBERS_COUNT',
  GuestsCount = 'GUESTS_COUNT',
  PiiDocumentsCount = 'PII_DOCUMENTS_COUNT',
  EntitiesCount = 'ENTITIES_COUNT',
  TotalMessageCount = 'TOTAL_MESSAGE_COUNT'
}

export enum ChannelNode {
  Name = 'NAME',
  Type = 'TYPE',
  MemberCount = 'MEMBER_COUNT',
  GuestCount = 'GUEST_COUNT',
  PiiDocumentCount = 'PII_DOCUMENT_COUNT',
  TotalMessageCount = 'TOTAL_MESSAGE_COUNT'
}

export type ChannelToggleFilterInput = {
  key: ChannelToggleFilterKey
}

export enum ChannelToggleFilterKey {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
  IsRisky = 'IS_RISKY',
  IsSensitive = 'IS_SENSITIVE'
}

export enum ChannelTypes {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type CharacterMaskingStrategy = {
  __typename?: 'CharacterMaskingStrategy'
  charset: Array<Maybe<Scalars['Character']>>
  length?: Maybe<Length>
}

export type CharacterMaskingStrategyInput = {
  charset: Array<InputMaybe<Scalars['Character']>>
  length?: InputMaybe<LengthInput>
}

export enum ClassificationType {
  Pii = 'PII',
  Identity = 'IDENTITY',
  NotIdentity = 'NOT_IDENTITY',
  EntityType = 'ENTITY_TYPE',
  NotPii = 'NOT_PII'
}

export type Classifications = {
  classificationType?: Maybe<Scalars['String']>
}

export type Column = Node & {
  __typename?: 'Column'
  id: Scalars['ID']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  qualifiedName: Scalars['String']
  createdOn: Scalars['String']
  lastModifiedOn?: Maybe<Scalars['String']>
  isViewColumn: Scalars['Boolean']
  constraint?: Maybe<ConstraintType>
  type: Scalars['String']
  classifications?: Maybe<EntityClassificationObject>
  schema?: Maybe<SchemaConnection>
  table?: Maybe<TableConnection>
  database?: Maybe<DatabaseConnection>
  datasource?: Maybe<DatasourceConnection>
  createdBy?: Maybe<Scalars['String']>
  modifiedBy?: Maybe<Scalars['String']>
  relatedColumns?: Maybe<ColumnConnection>
  isReviewed?: Maybe<Scalars['Boolean']>
  lastReviewedOn?: Maybe<Scalars['BigInt']>
  isNoScanItem?: Maybe<Scalars['Boolean']>
}

export type ColumnSchemaArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  databaseQualifiedName?: InputMaybe<Scalars['String']>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<SchemaNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ColumnTableArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  intRangeFilter?: InputMaybe<TableIntRangeFilterInput>
  dateFilter?: InputMaybe<TableDateFilterInput>
  toggleFilter?: InputMaybe<TableToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<TableBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<TableFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<TableNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ColumnDatabaseArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  intRangeFilter?: InputMaybe<DatabaseIntRangeFilterInput>
  dateFilter?: InputMaybe<DatabaseDateFilterInput>
  toggleFilter?: InputMaybe<DatabaseToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatabaseFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatabaseNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ColumnDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ColumnRelatedColumnsArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ColumnNode>
  toggleFilter?: InputMaybe<ColumnToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ColumnBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ColumnFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum ColumnBaseFilterKey {
  ParentTableId = 'PARENT_TABLE_ID'
}

export type ColumnBooleanFilterInput = {
  key: ColumnToggleFilterKey
  value: Scalars['Boolean']
}

export type ColumnConnection = {
  __typename?: 'ColumnConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<ColumnEdge>>
  count?: Maybe<Scalars['Int']>
}

export type ColumnEdge = {
  __typename?: 'ColumnEdge'
  node?: Maybe<Column>
  cursor: Scalars['String']
}

export type ColumnFilterInput = {
  key: ColumnBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export enum ColumnNode {
  Name = 'NAME',
  QualifiedName = 'QUALIFIED_NAME',
  CreatedAt = 'CREATED_AT',
  LastModifiedTime = 'LAST_MODIFIED_TIME',
  Type = 'TYPE'
}

export type ColumnToggleFilterInput = {
  key: ColumnToggleFilterKey
}

export enum ColumnToggleFilterKey {
  IsSensitive = 'IS_SENSITIVE',
  IsRisky = 'IS_RISKY',
  IsReviewed = 'IS_REVIEWED',
  IsNoScanItem = 'IS_NO_SCAN_ITEM',
  IncludeDraftChanges = 'INCLUDE_DRAFT_CHANGES'
}

export type ColumnsConfiguration = {
  __typename?: 'ColumnsConfiguration'
  completed: Scalars['Int']
  running: Scalars['Int']
  failed: Scalars['Int']
  total: Scalars['Int']
}

export type Comment = {
  __typename?: 'Comment'
  authorName?: Maybe<Scalars['String']>
  updateTimestamp?: Maybe<Scalars['DateTime']>
  body?: Maybe<Scalars['String']>
}

export type Configuration = {
  __typename?: 'Configuration'
  database: Scalars['String']
  schema: Scalars['String']
  tables?: Maybe<TablesConfiguration>
  columns?: Maybe<ColumnsConfiguration>
}

export enum ConstraintType {
  PrimaryKey = 'PRIMARY_KEY',
  ForeignKey = 'FOREIGN_KEY'
}

export type Context = {
  __typename?: 'Context'
  keyword: Scalars['String']
  strength?: Maybe<ContextStrength>
}

export type ContextInput = {
  keyword: Scalars['String']
  strength?: InputMaybe<ContextStrength>
}

export enum ContextStrength {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW'
}

export type Coordinates = {
  __typename?: 'Coordinates'
  latitude: Scalars['Int']
  longitude: Scalars['Int']
}

export type CreateAttributeInput = {
  name: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  sensitivityLabel: SensitivityLabel
  isDlp: Scalars['Boolean']
  enableMasking: Scalars['Boolean']
  maskingStrategyConfiguration?: InputMaybe<MaskingStrategyConfigInput>
  createdBy?: InputMaybe<Scalars['GrapheneObjectId']>
  context?: InputMaybe<Array<InputMaybe<ContextInput>>>
  supportedLanguage?: InputMaybe<SupportedLanguage>
  blacklist?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  patterns?: InputMaybe<Array<InputMaybe<PatternInput>>>
  attributeSets?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
}

export type CreateAttributeSetInput = {
  name: Scalars['String']
  type: Type
  description?: InputMaybe<Scalars['String']>
  createdBy?: InputMaybe<Scalars['GrapheneObjectId']>
  enabled?: InputMaybe<Scalars['Boolean']>
  attributes?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
}

export type CreateProjectInput = {
  name: Scalars['String']
  displayName: Scalars['String']
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceName?: InputMaybe<Scalars['String']>
  storeState: Scalars['Boolean']
  objectType: ObjectType
  default?: InputMaybe<Scalars['Boolean']>
  owner?: InputMaybe<OwnerInput>
  coOwners?: InputMaybe<Array<InputMaybe<OwnerInput>>>
  dataStore?: InputMaybe<DataStoreInput>
}

export enum DataStore {
  Source = 'source',
  Destination = 'destination'
}

export type DataStoreInput = {
  source?: InputMaybe<Scalars['String']>
  destination?: InputMaybe<Scalars['String']>
}

export type Database = Node & {
  __typename?: 'Database'
  id: Scalars['ID']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  qualifiedName: Scalars['String']
  createdOn: Scalars['String']
  lastModifiedOn?: Maybe<Scalars['String']>
  tableClusters?: Maybe<TableClusterConnection>
  schemas?: Maybe<SchemaConnection>
  tables?: Maybe<TableConnection>
  columns?: Maybe<ColumnConnection>
  datasource?: Maybe<DatasourceConnection>
  size?: Maybe<Scalars['BigInt']>
  rowCount?: Maybe<Scalars['BigInt']>
  createdBy?: Maybe<Scalars['String']>
  modifiedBy?: Maybe<Scalars['String']>
  lastSyncedTime?: Maybe<Scalars['DateTime']>
  classifications?: Maybe<Classifications>
  reviewStatus?: Maybe<ReviewStatus>
  isRisky?: Maybe<Scalars['Boolean']>
  entityScanDetails?: Maybe<EntityScanDetails>
}

export type DatabaseTableClustersArgs = {
  id?: InputMaybe<Scalars['ID']>
  toggleFilter?: InputMaybe<TableClusterToggleFilterInput>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatabaseSchemasArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  databaseQualifiedName?: InputMaybe<Scalars['String']>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<SchemaNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatabaseTablesArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  intRangeFilter?: InputMaybe<TableIntRangeFilterInput>
  dateFilter?: InputMaybe<TableDateFilterInput>
  toggleFilter?: InputMaybe<TableToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<TableBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<TableFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<TableNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatabaseColumnsArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ColumnNode>
  toggleFilter?: InputMaybe<ColumnToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ColumnBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ColumnFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatabaseDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatabaseClassificationsArgs = {
  type?: InputMaybe<ClassificationType>
  groupby?: InputMaybe<GroupbyClassificationNode>
}

export enum DatabaseBaseFilterKey {
  Name = 'NAME',
  IsSensitive = 'IS_SENSITIVE',
  IsRisky = 'IS_RISKY'
}

export type DatabaseConnection = {
  __typename?: 'DatabaseConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<DatabaseEdge>>
  count?: Maybe<Scalars['Int']>
}

export type DatabaseDateFilterInput = {
  key: DatabaseDateFilterKey
  start?: InputMaybe<Scalars['BigInt']>
  end?: InputMaybe<Scalars['BigInt']>
}

export enum DatabaseDateFilterKey {
  LastModifiedTime = 'LAST_MODIFIED_TIME',
  CreatedOn = 'CREATED_ON',
  LastSyncedTime = 'LAST_SYNCED_TIME'
}

export type DatabaseEdge = {
  __typename?: 'DatabaseEdge'
  node?: Maybe<Database>
  cursor: Scalars['String']
}

export type DatabaseFilterInput = {
  key: DatabaseBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type DatabaseIntRangeFilterInput = {
  key: DatabaseIntRangeFilterKey
  min?: InputMaybe<Scalars['Int']>
  max?: InputMaybe<Scalars['Int']>
}

export enum DatabaseIntRangeFilterKey {
  Size = 'SIZE',
  RowCount = 'ROW_COUNT'
}

export enum DatabaseNode {
  Name = 'NAME',
  QualifiedName = 'QUALIFIED_NAME',
  CreatedAt = 'CREATED_AT',
  LastModifiedTime = 'LAST_MODIFIED_TIME',
  ModifiedBy = 'MODIFIED_BY',
  LastSyncedTime = 'LAST_SYNCED_TIME'
}

export type DatabaseToggleFilterInput = {
  key: DatabaseToggleFilterKey
}

export enum DatabaseToggleFilterKey {
  IsSensitive = 'IS_SENSITIVE',
  IsRisky = 'IS_RISKY',
  IsReviewed = 'IS_REVIEWED'
}

export type Datasource = Node & {
  __typename?: 'Datasource'
  id: Scalars['ID']
  location?: Maybe<Scalars['String']>
  type: DatasourceType
  name: Scalars['String']
  createdBy?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  status?: Maybe<DatasourceStatus>
  lastModifiedTime?: Maybe<Scalars['String']>
  scanTimeRemaining?: Maybe<Scalars['Int']>
  schedulePolicy?: Maybe<Scalars['String']>
  configuration?: DatasourceConfig
  coordinates?: Maybe<Coordinates>
  lastSyncedTime?: Maybe<Scalars['DateTime']>
  entityScanDetails?: Maybe<EntityScanDetails>
  totalProcessedBytes?: Maybe<Scalars['BigInt']>
  totalSkippedBytes?: Maybe<Scalars['BigInt']>
  totalBytes?: Maybe<Scalars['BigInt']>
  numberOfObjects?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['BigInt']>
  rowCount?: Maybe<Scalars['BigInt']>
  registeredOn?: Maybe<Scalars['DateTime']>
  owner?: Maybe<Owner>
  coOwners?: Maybe<Array<Maybe<Owner>>>
  apiStats?: Maybe<ApiStats>
  classifications?: Maybe<Classifications>
  objects?: Maybe<ObjectConnection>
  userEntities?: Maybe<UserEntityConnection>
  datasourceUsers?: Maybe<DatasourceUserConnection>
  groups?: Maybe<GroupConnection>
  drive?: Maybe<DriveConnection>
  member?: Maybe<MembersConnection>
  channel?: Maybe<ChannelConnection>
  mail?: Maybe<MailConnection>
  residencies?: Maybe<ResidencyConnection>
  templates?: Maybe<TemplateConnection>
  violations?: Maybe<ViolationConnection>
  alerts?: Maybe<AlertConnection>
  subAlerts?: Maybe<SubAlertConnection>
  databases?: Maybe<DatabaseConnection>
  schemas?: Maybe<SchemaConnection>
  tables?: Maybe<TableConnection>
  columns?: Maybe<ColumnConnection>
  attributeInstance?: Maybe<AttributeInstanceConnection>
  datasourceProgress?: Maybe<DatasourceProgressConnection>
  policies?: Maybe<PolicyConnection>
  sites?: Maybe<SiteConnection>
  buckets?: Maybe<BucketConnection>
  indexes?: Maybe<IndexConnection>
  projects?: Maybe<ProjectConnection>
}

export type DatasourceClassificationsArgs = {
  type?: InputMaybe<ClassificationType>
  groupby?: InputMaybe<GroupbyClassificationNode>
}

export type DatasourceObjectsArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceUserEntitiesArgs = {
  isSensitive?: InputMaybe<Scalars['Boolean']>
  database?: InputMaybe<Scalars['String']>
  schema?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<EntityTypes>>>
  toggleFilter?: InputMaybe<EntityToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<EntityFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<EntityBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<UserEntityNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceDatasourceUsersArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<EntityTypes>>>
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  intRangeFilter?: InputMaybe<DatasourceUserIntRangeFilterInput>
  toggleFilter?: InputMaybe<DatasourceUserToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceUserFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceUserNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceGroupsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  intRangeFilter?: InputMaybe<GroupIntRangeFilterInput>
  toggleFilter?: InputMaybe<GroupToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<GroupFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<GroupNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceDriveArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DriveTypes>>>
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  intRangeFilter?: InputMaybe<DriveIntRangeFilterInput>
  toggleFilter?: InputMaybe<DriveToggleFilterInput>
  searchQuery?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Array<InputMaybe<DriveFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DriveNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceMemberArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<MemberTypes>>>
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  intRangeFilter?: InputMaybe<MemberIntRangeFilterInput>
  toggleFilter?: InputMaybe<MemberToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<ImMemberFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<MemberNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceChannelArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ChannelTypes>>>
  alertId?: InputMaybe<Scalars['GrapheneObjectId']>
  alertStatus?: InputMaybe<AlertStatus>
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  intRangeFilter?: InputMaybe<ChannelIntRangeFilterInput>
  toggleFilter?: InputMaybe<ChannelToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<ChannelFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ChannelNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceMailArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  riskLevel?: InputMaybe<Array<InputMaybe<EmailRiskLevel>>>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  hasAttachments?: InputMaybe<Scalars['Boolean']>
  intRangeFilter?: InputMaybe<EmailIntRangeFilterInput>
  toggleFilter?: InputMaybe<EmailToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<EmailBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<EmailFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<MailNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceResidenciesArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  intRangeFilter?: InputMaybe<ResidencyIntRangeFilterInput>
  filter?: InputMaybe<Array<InputMaybe<ResidencyFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ResidencyNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceTemplatesArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  dateFilter?: InputMaybe<TemplateDateFilterInput>
  intRangeFilter?: InputMaybe<TemplateIntRangeFilterInput>
  toggleFilter?: InputMaybe<TemplateToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<TemplateFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<TemplateNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceViolationsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  dateFilter?: InputMaybe<ViolationDateFilterInput>
  filter?: InputMaybe<Array<InputMaybe<ViolationFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ViolationNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceAlertsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  status?: InputMaybe<AlertStatus>
  severity?: InputMaybe<AlertSeverity>
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  dateFilter?: InputMaybe<AlertDateFilterInput>
  filter?: InputMaybe<Array<InputMaybe<AlertFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AlertNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceSubAlertsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  status?: InputMaybe<AlertStatus>
  userEntityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  severity?: InputMaybe<AlertSeverity>
  intRangeFilter?: InputMaybe<SubAlertIntRangeFilterInput>
  dateFilter?: InputMaybe<SubAlertDateFilterInput>
  externalUser?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Array<InputMaybe<SubAlertFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<SubAlertNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceDatabasesArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  intRangeFilter?: InputMaybe<DatabaseIntRangeFilterInput>
  dateFilter?: InputMaybe<DatabaseDateFilterInput>
  toggleFilter?: InputMaybe<DatabaseToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatabaseFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatabaseNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceSchemasArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  databaseQualifiedName?: InputMaybe<Scalars['String']>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<SchemaNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceTablesArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  intRangeFilter?: InputMaybe<TableIntRangeFilterInput>
  dateFilter?: InputMaybe<TableDateFilterInput>
  toggleFilter?: InputMaybe<TableToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<TableBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<TableFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<TableNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceColumnsArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ColumnNode>
  toggleFilter?: InputMaybe<ColumnToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ColumnBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ColumnFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceAttributeInstanceArgs = {
  alertId?: InputMaybe<Scalars['GrapheneObjectId']>
  entityId?: InputMaybe<Scalars['GrapheneObjectId']>
  filter?: InputMaybe<Array<InputMaybe<AttributeInstanceFilterInput>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  channelIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  attributeSets?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  hasAttachments?: InputMaybe<Scalars['Boolean']>
  sensitivity?: InputMaybe<Array<InputMaybe<SensitivityLabel>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceDatasourceProgressArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<Scalars['String']>
  runId?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Array<InputMaybe<DatasourceProgressFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourcePoliciesArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['Boolean']>
  type?: InputMaybe<PolicyTypes>
  dateFilter?: InputMaybe<PolicyDateFilterInput>
  filter?: InputMaybe<Array<InputMaybe<PolicyFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<PolicyNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceSitesArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceBucketsArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceIndexesArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceProjectsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  filter?: InputMaybe<Array<InputMaybe<ProjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ProjectNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum DatasourceBaseFilterKey {
  Name = 'NAME',
  Type = 'TYPE',
  CreatedBy = 'CREATED_BY',
  Status = 'STATUS',
  Location = 'LOCATION',
  Classification = 'CLASSIFICATION',
  IsSensitive = 'IS_SENSITIVE',
  IsRisky = 'IS_RISKY'
}

export type DatasourceConfig =
  | GsuiteDatasourceConfig
  | SlackDatasourceConfig
  | OracleDatasourceConfig
  | SalesforceDatasourceConfig
  | Awss3DatasourceConfig
  | SnowflakeDatasourceConfig
  | PostgreSqlConfig
  | MsSqlConfig
  | ElasticsearchConfig
  | MicrosoftOfficeConfig
  | JiraConfig
  | MySqlConfig
  | GenericDatasourceConfig
  | SmbConfig

export type DatasourceConnection = {
  __typename?: 'DatasourceConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<DatasourceEdge>>
  count?: Maybe<Scalars['Int']>
}

export type DatasourceCount = {
  __typename?: 'DatasourceCount'
  datasource?: Maybe<DatasourceConnection>
  count?: Maybe<Scalars['Int']>
}

export type DatasourceCountDatasourceArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DatasourceDateFilterInput = {
  key: DatasourceDateFilterKey
  start?: InputMaybe<Scalars['BigInt']>
  end?: InputMaybe<Scalars['BigInt']>
}

export enum DatasourceDateFilterKey {
  LastModifiedTime = 'LAST_MODIFIED_TIME',
  RegisteredOn = 'REGISTERED_ON',
  LastSyncedTime = 'LAST_SYNCED_TIME'
}

export type DatasourceEdge = {
  __typename?: 'DatasourceEdge'
  node?: Maybe<Datasource>
  cursor: Scalars['String']
}

export type DatasourceFilterConditions = {
  __typename?: 'DatasourceFilterConditions'
  filterExpression?: Maybe<Scalars['String']>
}

export type DatasourceFilterConditionsInput = {
  filterExpression?: InputMaybe<Scalars['String']>
}

export type DatasourceFilterInput = {
  key: DatasourceBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type DatasourceFilters = {
  __typename?: 'DatasourceFilters'
  datasourceIds?: Maybe<Array<Maybe<Scalars['GrapheneObjectId']>>>
  conditions?: Maybe<Array<Maybe<DatasourceFilterConditions>>>
}

export type DatasourceFiltersInput = {
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  conditions?: InputMaybe<Array<InputMaybe<DatasourceFilterConditionsInput>>>
}

export type DatasourceIntRangeFilterInput = {
  key: DatasourceIntRangeFilterKey
  min?: InputMaybe<Scalars['Int']>
  max?: InputMaybe<Scalars['Int']>
}

export enum DatasourceIntRangeFilterKey {
  ObjectsCount = 'OBJECTS_COUNT'
}

export enum DatasourceNode {
  Name = 'NAME',
  Type = 'TYPE',
  CreatedBy = 'CREATED_BY',
  Status = 'STATUS',
  Location = 'LOCATION',
  NumberOfObjects = 'NUMBER_OF_OBJECTS',
  RowCount = 'ROW_COUNT',
  TotalProcessedBytes = 'TOTAL_PROCESSED_BYTES',
  TotalSkippedBytes = 'TOTAL_SKIPPED_BYTES',
  TotalBytes = 'TOTAL_BYTES',
  LastSyncedTime = 'LAST_SYNCED_TIME',
  RegisteredOn = 'REGISTERED_ON',
  LastModifiedTime = 'LAST_MODIFIED_TIME',
  Size = 'SIZE'
}

export type DatasourceProgress = {
  __typename?: 'DatasourceProgress'
  runId: Scalars['BigInt']
  status: Scalars['String']
  datasourceId: Scalars['String']
  configuration?: Maybe<Configuration>
  reviewStatus?: Maybe<ReviewStatus>
  lastModified?: Maybe<Scalars['DateTime']>
}

export enum DatasourceProgressBaseFilterKey {
  Status = 'STATUS'
}

export type DatasourceProgressConnection = {
  __typename?: 'DatasourceProgressConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<DatasourceProgressEdge>>
  count?: Maybe<Scalars['Int']>
}

export type DatasourceProgressEdge = {
  __typename?: 'DatasourceProgressEdge'
  node?: Maybe<DatasourceProgress>
  cursor: Scalars['String']
}

export type DatasourceProgressFilterInput = {
  key: DatasourceProgressBaseFilterKey
  values: Array<InputMaybe<DatasourceProgressStatus>>
}

export enum DatasourceProgressStatus {
  Pending = 'PENDING',
  Running = 'RUNNING',
  Completed = 'COMPLETED',
  AvailableForReview = 'AVAILABLE_FOR_REVIEW',
  ApplyingReviewChanges = 'APPLYING_REVIEW_CHANGES',
  Reviewed = 'REVIEWED',
  Failed = 'FAILED'
}

export type DatasourceStatus =
  | DriveDatasourceStatus
  | MailDatasourceStatus
  | SnowflakeDatasourceStatus
  | TicketStoreDatasourceStatus
  | GenericDatasourceStatus
  | DefaultDatasourceStatus

export type DatasourceToggleFilterInput = {
  key: DatasourceToggleFilterKey
}

export enum DatasourceToggleFilterKey {
  IsSensitive = 'IS_SENSITIVE',
  IsRisky = 'IS_RISKY'
}

export enum DatasourceType {
  Salesforce = 'SALESFORCE',
  GoogleDrive = 'GOOGLE_DRIVE',
  Gmail = 'GMAIL',
  AwsS3 = 'AWS_S3',
  Slack = 'SLACK',
  Oracle = 'ORACLE',
  Snowflake = 'SNOWFLAKE',
  Mssql = 'MSSQL',
  Postgresql = 'POSTGRESQL',
  OneDrive = 'ONE_DRIVE',
  Outlook = 'OUTLOOK',
  Elasticsearch = 'ELASTICSEARCH',
  Sharepoint = 'SHAREPOINT',
  Jira = 'JIRA',
  Generic = 'GENERIC',
  SMB = 'SMB',
  Dropbox = 'DROPBOX'
}

export type DatasourceUser = {
  __typename?: 'DatasourceUser'
  id: Scalars['GrapheneObjectId']
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  filesCount?: Maybe<Scalars['Int']>
  piiDataFilesCount?: Maybe<Scalars['Int']>
  sharedFilesCount?: Maybe<Scalars['Int']>
  mailCount?: Maybe<Scalars['Int']>
  piiDataMailCount?: Maybe<Scalars['Int']>
  riskyMailCount?: Maybe<Scalars['Int']>
  piiDataSharedFilesCount?: Maybe<Scalars['Int']>
  classifiedFilesCount?: Maybe<Scalars['Int']>
  unclassifiedFilesCount?: Maybe<Scalars['Int']>
  type?: Maybe<EntityTypes>
  datasource?: Maybe<DatasourceConnection>
}

export type DatasourceUserDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum DatasourceUserBaseFilterKey {
  Name = 'NAME',
  Type = 'TYPE',
  IsSensitive = 'IS_SENSITIVE',
  IsRisky = 'IS_RISKY'
}

export type DatasourceUserConnection = {
  __typename?: 'DatasourceUserConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<DatasourceUserEdge>>
  count?: Maybe<Scalars['Int']>
}

export type DatasourceUserEdge = {
  __typename?: 'DatasourceUserEdge'
  node?: Maybe<DatasourceUser>
  cursor: Scalars['String']
}

export type DatasourceUserFilterInput = {
  key: DatasourceUserBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type DatasourceUserIntRangeFilterInput = {
  key: DatasourceUserIntRangeFilterKey
  min?: InputMaybe<Scalars['Int']>
  max?: InputMaybe<Scalars['Int']>
}

export enum DatasourceUserIntRangeFilterKey {
  ObjectsCount = 'OBJECTS_COUNT',
  PiiDataFilesCount = 'PII_DATA_FILES_COUNT',
  SharedObjectCount = 'SHARED_OBJECT_COUNT',
  MailCount = 'MAIL_COUNT',
  PiiDataMailCount = 'PII_DATA_MAIL_COUNT',
  RiskyMailCount = 'RISKY_MAIL_COUNT',
  PiiDataSharedFilesCount = 'PII_DATA_SHARED_FILES_COUNT',
  ClassifiedFilesCount = 'CLASSIFIED_FILES_COUNT',
  UnclassifiedFilesCount = 'UNCLASSIFIED_FILES_COUNT'
}

export enum DatasourceUserNode {
  Name = 'NAME',
  Email = 'EMAIL',
  ObjectCount = 'OBJECT_COUNT',
  PiiDataDocumentCount = 'PII_DATA_DOCUMENT_COUNT',
  SharedObjectCount = 'SHARED_OBJECT_COUNT',
  MailCount = 'MAIL_COUNT',
  PiiDataMailCount = 'PII_DATA_MAIL_COUNT',
  RiskyMailCount = 'RISKY_MAIL_COUNT',
  PiiDataSharedDocumentCount = 'PII_DATA_SHARED_DOCUMENT_COUNT',
  ClassifiedDocumentCount = 'CLASSIFIED_DOCUMENT_COUNT',
  UnclassifiedDocumentCount = 'UNCLASSIFIED_DOCUMENT_COUNT',
  Type = 'TYPE'
}

export type DatasourceUserToggleFilterInput = {
  key: DatasourceUserToggleFilterKey
}

export enum DatasourceUserToggleFilterKey {
  IsSensitive = 'IS_SENSITIVE',
  IsRisky = 'IS_RISKY',
  Customer = 'CUSTOMER',
  Employee = 'EMPLOYEE',
  Supplier = 'SUPPLIER',
  Vendor = 'VENDOR'
}

export enum DateRangeType {
  Day = 'DAY',
  Week = 'WEEK'
}

export type DefaultDatasourceStatus = {
  __typename?: 'DefaultDatasourceStatus'
  processedCount?: Maybe<Scalars['Int']>
  pendingCount?: Maybe<Scalars['Int']>
  skippedCount?: Maybe<Scalars['Int']>
}

export type DeletePayload = {
  __typename?: 'DeletePayload'
  clientMutationId?: Maybe<Scalars['ID']>
  isDeleted?: Maybe<Scalars['Boolean']>
  message?: Maybe<Scalars['String']>
}

export type DetectAttributesInput = {
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceName?: InputMaybe<Scalars['String']>
  projectName?: InputMaybe<Scalars['String']>
  anonymize?: InputMaybe<Scalars['Boolean']>
  data: Scalars['String']
  isBase64: Scalars['Boolean']
  objectType?: InputMaybe<ObjectTypeInput>
  objectName?: InputMaybe<Scalars['String']>
  objectSize?: InputMaybe<Scalars['BigInt']>
  objectUrl?: InputMaybe<Scalars['String']>
  likelihood?: InputMaybe<Likelihood>
  attributeSets?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
}

export type DetectAttributesPayload = {
  __typename?: 'DetectAttributesPayload'
  clientMutationId: Scalars['ID']
  data: Scalars['String']
  isBase64: Scalars['Boolean']
  detectedAttributes?: Maybe<Array<Maybe<DetectedAttribute>>>
  object?: Maybe<ObjectConnection>
}

export type DetectAttributesPayloadObjectArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DetectedAttribute = {
  __typename?: 'DetectedAttribute'
  attributeName: Scalars['String']
  value: Scalars['String']
  attributeDetections: Array<Maybe<AttributeDetection>>
}

export enum DocumentNode {
  Name = 'NAME',
  Type = 'TYPE',
  Class = 'CLASS',
  SubClass = 'SUB_CLASS',
  LastModifiedTime = 'LAST_MODIFIED_TIME',
  Size = 'SIZE'
}

export enum DraftObjectOperationType {
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE'
}

export enum DraftObjectStatus {
  InProgress = 'IN_PROGRESS',
  Submitted = 'SUBMITTED',
  Applying = 'APPLYING',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Conflict = 'CONFLICT'
}

export type Drive = {
  __typename?: 'Drive'
  id: Scalars['GrapheneObjectId']
  driveName?: Maybe<Scalars['String']>
  owner?: Maybe<Scalars['String']>
  driveId?: Maybe<Scalars['String']>
  driveType?: Maybe<DriveTypes>
  documentsCount?: Maybe<Scalars['Int']>
  piiDataDocumentsCount?: Maybe<Scalars['Int']>
  piiDataSharedDocumentsCount?: Maybe<Scalars['Int']>
  sharedDocumentsCount?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  status?: Maybe<DriveStatus>
  siteId?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
  entities?: Maybe<UserEntityConnection>
  objects?: Maybe<ObjectConnection>
  datasource?: Maybe<DatasourceConnection>
}

export type DriveEntitiesArgs = {
  isSensitive?: InputMaybe<Scalars['Boolean']>
  database?: InputMaybe<Scalars['String']>
  schema?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<EntityTypes>>>
  toggleFilter?: InputMaybe<EntityToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<EntityFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<EntityBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<UserEntityNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DriveObjectsArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DriveDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum DriveBaseFilterKey {
  Name = 'NAME',
  Type = 'TYPE',
  Status = 'STATUS',
  IsSensitive = 'IS_SENSITIVE',
  IsRisky = 'IS_RISKY',
  DriveId = 'DRIVE_ID',
  Owner = 'OWNER'
}

export type DriveConnection = {
  __typename?: 'DriveConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<DriveEdge>>
  count?: Maybe<Scalars['Int']>
}

export type DriveDatasourceStatus = {
  __typename?: 'DriveDatasourceStatus'
  processedCount?: Maybe<Scalars['Int']>
  pendingCount?: Maybe<Scalars['Int']>
  skippedCount?: Maybe<Scalars['Int']>
}

export type DriveEdge = {
  __typename?: 'DriveEdge'
  node?: Maybe<Drive>
  cursor: Scalars['String']
}

export type DriveFilterInput = {
  key: DriveBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type DriveIntRangeFilterInput = {
  key: DriveIntRangeFilterKey
  min?: InputMaybe<Scalars['Int']>
  max?: InputMaybe<Scalars['Int']>
}

export enum DriveIntRangeFilterKey {
  ObjectsCount = 'OBJECTS_COUNT',
  SharedObjectCount = 'SHARED_OBJECT_COUNT',
  SensitiveObjectsCount = 'SENSITIVE_OBJECTS_COUNT',
  SensitiveSharedObjectsCount = 'SENSITIVE_SHARED_OBJECTS_COUNT',
  Size = 'SIZE'
}

export enum DriveNode {
  Name = 'NAME',
  Type = 'TYPE',
  ObjectCount = 'OBJECT_COUNT',
  PiiDocumentsCount = 'PII_DOCUMENTS_COUNT',
  PiiSharedDocumentsCount = 'PII_SHARED_DOCUMENTS_COUNT',
  SharedDocumentsCount = 'SHARED_DOCUMENTS_COUNT',
  Size = 'SIZE',
  Status = 'STATUS'
}

export enum DriveStatus {
  Active = 'ACTIVE',
  InActive = 'IN_ACTIVE'
}

export type DriveToggleFilterInput = {
  key: DriveToggleFilterKey
}

export enum DriveToggleFilterKey {
  IsSensitive = 'IS_SENSITIVE',
  IsRisky = 'IS_RISKY'
}

export enum DriveTypes {
  User = 'User',
  Shared = 'Shared'
}

export enum DurationType {
  Day = 'DAY',
  Week = 'WEEK'
}

export type ElasticsearchConfig = {
  __typename?: 'ElasticsearchConfig'
  hosts: Scalars['String']
  username?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  apiKeyId?: Maybe<Scalars['String']>
  apiKeySecret?: Maybe<Scalars['String']>
}

export enum EmailBaseFilterKey {
  Sender = 'SENDER',
  Subject = 'SUBJECT',
  IsRisky = 'IS_RISKY',
  IsSensitive = 'IS_SENSITIVE',
  AttributeIds = 'ATTRIBUTE_IDS',
  EntityIds = 'ENTITY_IDS',
  To = 'TO',
  From = 'FROM',
  Classification = 'CLASSIFICATION',
  AlertIds = 'ALERT_IDS'
}

export type EmailBooleanFilterInput = {
  key: EmailBooleanFilterKey
  value: Scalars['Boolean']
}

export enum EmailBooleanFilterKey {
  SharedWithInternalMembers = 'SHARED_WITH_INTERNAL_MEMBERS',
  SharedWithExternalMembers = 'SHARED_WITH_EXTERNAL_MEMBERS',
  SharedWithGroups = 'SHARED_WITH_GROUPS',
  HasAlerts = 'HAS_ALERTS',
  HasAttachments = 'HAS_ATTACHMENTS',
  HasPiiData = 'HAS_PII_DATA'
}

export type EmailDetails = {
  __typename?: 'EmailDetails'
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type EmailFilterInput = {
  key: EmailBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export enum EmailGroupBySenderBaseFilterKey {
  EntityIds = 'ENTITY_IDS',
  From = 'FROM'
}

export type EmailGroupBySenderFilterInput = {
  key: EmailGroupBySenderBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type EmailIntRangeFilterInput = {
  key: EmailIntRangeFilterKey
  min?: InputMaybe<Scalars['Int']>
  max?: InputMaybe<Scalars['Int']>
}

export enum EmailIntRangeFilterKey {
  PiiAttributesCount = 'PII_ATTRIBUTES_COUNT',
  ForeignRecipientCount = 'FOREIGN_RECIPIENT_COUNT'
}

export enum EmailRiskLevel {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW'
}

export type EmailToggleFilterInput = {
  key: EmailToggleFilterKey
}

export enum EmailToggleFilterKey {
  IsRisky = 'IS_RISKY',
  IsSensitive = 'IS_SENSITIVE'
}

export type EntitiesImpacted = {
  __typename?: 'EntitiesImpacted'
  entityId?: Maybe<Scalars['GrapheneObjectId']>
  name?: Maybe<Scalars['String']>
  attributeCount?: Maybe<Scalars['Int']>
  documentCount?: Maybe<Scalars['Int']>
}

export enum EntityBaseFilterKey {
  Name = 'NAME',
  Type = 'TYPE',
  Residency = 'RESIDENCY',
  DatasourceId = 'DATASOURCE_ID',
  DriveIds = 'DRIVE_IDS',
  PartnerIds = 'PARTNER_IDS',
  ProjectIds = 'PROJECT_IDS'
}

export type EntityBooleanFilterInput = {
  key: EntityBooleanFilterKey
  value: Scalars['Boolean']
}

export enum EntityBooleanFilterKey {
  IsRisky = 'IS_RISKY'
}

export type EntityClassificationObject = Classifications & {
  __typename?: 'EntityClassificationObject'
  classificationType?: Maybe<Scalars['String']>
  pii?: Maybe<PiiClassification>
  identity?: Maybe<IdentityEntityClassification>
  entityType?: Maybe<EntityTypeClassification>
  primaryIdentifier?: Maybe<PrimaryIdentifierClassification>
}

export type EntityFilterInput = {
  key: EntityBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type EntityResolutionInfo = {
  __typename?: 'EntityResolutionInfo'
  status: EntityResolutionStatus
  createdEntitiesCount?: Maybe<Scalars['Int']>
  resolvedEntitiesCount?: Maybe<Scalars['Int']>
}

export enum EntityResolutionStatus {
  Pending = 'PENDING',
  Preparing = 'PREPARING',
  Running = 'RUNNING',
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED'
}

export type EntityScanDetails = {
  __typename?: 'EntityScanDetails'
  status: EntityScanStatus
  totalEntitiesCount: Scalars['BigInt']
  scannedEntitiesCount: Scalars['BigInt']
  totalEntitiesSizeBytes: Scalars['BigInt']
  scannedEntitiesSizeBytes: Scalars['BigInt']
}

export enum EntityScanStatus {
  Pending = 'PENDING',
  Running = 'RUNNING',
  Failed = 'FAILED',
  Completed = 'COMPLETED',
  Interrupted = 'INTERRUPTED'
}

export enum EntityScanTriggerActionType {
  Start = 'START',
  Stop = 'STOP',
  Pause = 'PAUSE',
  Resume = 'RESUME'
}

export type EntityScanTriggerInput = {
  clusterId: Scalars['String']
  action: EntityScanTriggerActionType
}

export type EntityScanTriggerOutput = {
  __typename?: 'EntityScanTriggerOutput'
  success?: Maybe<Scalars['Boolean']>
}

export type EntityToggleFilterInput = {
  key: EntityToggleFilterKey
}

export enum EntityToggleFilterKey {
  IsRisky = 'IS_RISKY'
}

export enum EntityType {
  Customer = 'CUSTOMER',
  Vendor = 'VENDOR',
  Employee = 'EMPLOYEE',
  Supplier = 'SUPPLIER'
}

export type EntityTypeClassification = {
  __typename?: 'EntityTypeClassification'
  type: EntityType
  createdBy?: Maybe<Scalars['String']>
}

export enum EntityTypes {
  Customer = 'CUSTOMER',
  Employee = 'EMPLOYEE',
  Supplier = 'SUPPLIER',
  Vendor = 'VENDOR'
}

export type ExternalUser = {
  __typename?: 'ExternalUser'
  user?: Maybe<Scalars['String']>
  objectCount?: Maybe<Scalars['Int']>
}

export type FilterConnection = {
  __typename?: 'FilterConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<FilterEdge>>
  count?: Maybe<Scalars['Int']>
}

export type FilterEdge = {
  __typename?: 'FilterEdge'
  node?: Maybe<Filters>
  cursor: Scalars['String']
}

export type FilterPayload = {
  __typename?: 'FilterPayload'
  clientMutationId?: Maybe<Scalars['ID']>
  filter?: Maybe<FilterConnection>
}

export type FilterPayloadFilterArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum FilterTypes {
  DataSourceFilters = 'DATA_SOURCE_FILTERS',
  AttributeSetFilters = 'ATTRIBUTE_SET_FILTERS',
  LabelFilters = 'LABEL_FILTERS',
  ProfileFilters = 'PROFILE_FILTERS',
  TimeBasedFilters = 'TIME_BASED_FILTERS',
  UserFilters = 'USER_FILTERS',
  GroupFilters = 'GROUP_FILTERS',
  RegulationFilters = 'REGULATION_FILTERS'
}

export type Filters = {
  __typename?: 'Filters'
  id?: Maybe<Scalars['GrapheneObjectId']>
  name?: Maybe<Scalars['String']>
  owner?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  lastModifiedTime?: Maybe<Scalars['DateTime']>
  systemDefined?: Maybe<Scalars['Boolean']>
  startDate?: Maybe<Scalars['DateTime']>
  endDate?: Maybe<Scalars['DateTime']>
  type?: Maybe<FilterTypes>
  configuration?: Maybe<FiltersTypesConditions>
  permitReasons?: Maybe<Scalars['String']>
}

export type FiltersInput = {
  name: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  type: FilterTypes
  owner: Scalars['String']
  startDate?: InputMaybe<Scalars['DateTime']>
  endDate?: InputMaybe<Scalars['DateTime']>
  configuration: FiltersTypesConditionsInput
  permitReasons?: InputMaybe<Scalars['String']>
}

export type FiltersTypesConditions = DatasourceFilters | ProfileFilters

export type FiltersTypesConditionsInput = {
  datasourceFilters?: InputMaybe<DatasourceFiltersInput>
  profileFilters?: InputMaybe<ProfileFiltersInput>
}

export type Folder = {
  __typename?: 'Folder'
  folderPath: Scalars['String']
  folderId: Scalars['String']
  objects?: Maybe<ObjectConnection>
}

export type FolderObjectsArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type FolderInfo = {
  __typename?: 'FolderInfo'
  path?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
}

export type GenericDatasourceConfig = {
  __typename?: 'GenericDatasourceConfig'
  config?: Maybe<Scalars['String']>
}

export type GenericDatasourceStatus = {
  __typename?: 'GenericDatasourceStatus'
  processedCount?: Maybe<Scalars['Int']>
  pendingCount?: Maybe<Scalars['Int']>
  skippedCount?: Maybe<Scalars['Int']>
}

export type GraphSeries = {
  __typename?: 'GraphSeries'
  timestamp?: Maybe<Scalars['DateTime']>
  value?: Maybe<Scalars['BigInt']>
}

export enum GraphSeriesType {
  Document = 'DOCUMENT',
  Attribute = 'ATTRIBUTE'
}

export type Group = {
  __typename?: 'Group'
  id: Scalars['GrapheneObjectId']
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  filesCount?: Maybe<Scalars['Int']>
  piiDataFilesCount?: Maybe<Scalars['Int']>
  mailCount?: Maybe<Scalars['Int']>
  piiDataMailCount?: Maybe<Scalars['Int']>
  riskyMailCount?: Maybe<Scalars['Int']>
  members?: Maybe<MembersConnection>
  datasource?: Maybe<DatasourceConnection>
}

export type GroupMembersArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<MemberTypes>>>
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  intRangeFilter?: InputMaybe<MemberIntRangeFilterInput>
  toggleFilter?: InputMaybe<MemberToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<ImMemberFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<MemberNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type GroupDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum GroupBaseFilterKey {
  Name = 'NAME',
  Email = 'EMAIL'
}

export enum GroupByFunction {
  Sum = 'SUM',
  Count = 'COUNT'
}

export type GroupConnection = {
  __typename?: 'GroupConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<GroupEdge>>
  count?: Maybe<Scalars['Int']>
}

export type GroupEdge = {
  __typename?: 'GroupEdge'
  node?: Maybe<Group>
  cursor: Scalars['String']
}

export type GroupFilterInput = {
  key: GroupBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type GroupIntRangeFilterInput = {
  key: GroupIntRangeFilterKey
  min?: InputMaybe<Scalars['Int']>
  max?: InputMaybe<Scalars['Int']>
}

export enum GroupIntRangeFilterKey {
  ObjectsCount = 'OBJECTS_COUNT',
  PiiDataFilesCount = 'PII_DATA_FILES_COUNT',
  MailCount = 'MAIL_COUNT',
  PiiDataMailCount = 'PII_DATA_MAIL_COUNT',
  RiskyMailCount = 'RISKY_MAIL_COUNT'
}

export enum GroupNode {
  Name = 'NAME',
  Email = 'EMAIL',
  ObjectCount = 'OBJECT_COUNT',
  PiiDataDocumentCount = 'PII_DATA_DOCUMENT_COUNT',
  MailCount = 'MAIL_COUNT',
  PiiDataMailCount = 'PII_DATA_MAIL_COUNT',
  RiskyMailCount = 'RISKY_MAIL_COUNT'
}

export type GroupToggleFilterInput = {
  key: GroupToggleFilterKey
}

export enum GroupToggleFilterKey {
  IsRisky = 'IS_RISKY',
  IsSensitive = 'IS_SENSITIVE'
}

export type GroupbyClassificationNode = {
  groupingField?: InputMaybe<Scalars['String']>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  groupFn?: InputMaybe<GroupByFunction>
  limit?: InputMaybe<Scalars['Int']>
}

export type GsuiteDatasourceConfig = {
  __typename?: 'GsuiteDatasourceConfig'
  accountJson: Scalars['String']
  accountType: Scalars['String']
  delegatedCredential: Scalars['String']
  folderId?: Maybe<Scalars['String']>
  filterDate?: Maybe<Scalars['DateTime']>
}

export enum ImMemberBaseFilterKey {
  Name = 'NAME',
  Type = 'TYPE',
  IsSensitive = 'IS_SENSITIVE',
  IsRisky = 'IS_RISKY'
}

export type ImMemberFilterInput = {
  key: ImMemberBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type IdentityEntityClassification = {
  __typename?: 'IdentityEntityClassification'
  isReviewed?: Maybe<Scalars['Boolean']>
  lastReviewedOn?: Maybe<Scalars['BigInt']>
  createdBy?: Maybe<Scalars['String']>
  isIdentity: Scalars['Boolean']
  entityType: EntityType
}

export type IdentityTableDraftInputObject = {
  version?: InputMaybe<Scalars['String']>
  state?: InputMaybe<DraftObjectStatus>
  operationType?: InputMaybe<DraftObjectOperationType>
  entityType?: InputMaybe<EntityType>
  urn?: InputMaybe<Scalars['String']>
}

export type IdentityTableDraftOutputObject = {
  __typename?: 'IdentityTableDraftOutputObject'
  version?: Maybe<Scalars['String']>
  state?: Maybe<DraftObjectStatus>
  operationType?: Maybe<DraftObjectOperationType>
  entityType?: Maybe<EntityType>
  urn?: Maybe<Scalars['String']>
}

export type Index = {
  __typename?: 'Index'
  id: Scalars['GrapheneObjectId']
  indexName?: Maybe<Scalars['String']>
  timestampField?: Maybe<Scalars['String']>
  lastSyncTime?: Maybe<Scalars['DateTime']>
  isArchived?: Maybe<Scalars['Boolean']>
  updateTimestamp?: Maybe<Scalars['DateTime']>
}

export type IndexConnection = {
  __typename?: 'IndexConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<IndexEdge>>
  count?: Maybe<Scalars['Int']>
}

export type IndexEdge = {
  __typename?: 'IndexEdge'
  node?: Maybe<Index>
  cursor: Scalars['String']
}

export type JiraConfig = {
  __typename?: 'JiraConfig'
  email: Scalars['String']
  server: Scalars['String']
  apiToken: Scalars['String']
}

export type Label = {
  __typename?: 'Label'
  id: Scalars['GrapheneObjectId']
  labelDisplayName?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  lastModifiedTime?: Maybe<Scalars['DateTime']>
  systemDefined?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
}

export type LabelConnection = {
  __typename?: 'LabelConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<LabelEdge>>
  count?: Maybe<Scalars['Int']>
}

export type LabelEdge = {
  __typename?: 'LabelEdge'
  node?: Maybe<Label>
  cursor: Scalars['String']
}

export type Lead = {
  __typename?: 'Lead'
  accountId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type Length = {
  __typename?: 'Length'
  value?: Maybe<Scalars['Int']>
  function: LengthFunction
}

export enum LengthFunction {
  Equal = 'EQUAL',
  Base = 'BASE'
}

export type LengthInput = {
  value?: InputMaybe<Scalars['Int']>
  function: LengthFunction
}

export enum Likelihood {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW'
}

export type Mail = {
  __typename?: 'Mail'
  id: Scalars['GrapheneObjectId']
  sender?: Maybe<EmailDetails>
  recipients?: Maybe<Array<Maybe<EmailDetails>>>
  attachments?: Maybe<Array<Maybe<AttachmentDetails>>>
  messageId?: Maybe<Scalars['String']>
  subject?: Maybe<Scalars['String']>
  risk?: Maybe<EmailRiskLevel>
  size?: Maybe<Scalars['Int']>
  isDeleted?: Maybe<Scalars['Boolean']>
  piiAttributesCount?: Maybe<Scalars['Int']>
  foreignRecipientCount?: Maybe<Scalars['Int']>
  timestamp?: Maybe<Scalars['DateTime']>
  objects?: Maybe<ObjectConnection>
  attributes?: Maybe<AttributeConnection>
  attributeInstance?: Maybe<AttributeInstanceConnection>
  datasource?: Maybe<DatasourceConnection>
  userEntities?: Maybe<UserEntityConnection>
  alerts?: Maybe<AlertConnection>
}

export type MailObjectsArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type MailAttributesArgs = {
  id?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  systemDefined?: InputMaybe<Scalars['Boolean']>
  attributeSets?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  sensitivity?: InputMaybe<Array<InputMaybe<SensitivityLabel>>>
  hasInstances?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Array<InputMaybe<AttributeFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<AttributeBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AttributeNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type MailAttributeInstanceArgs = {
  alertId?: InputMaybe<Scalars['GrapheneObjectId']>
  entityId?: InputMaybe<Scalars['GrapheneObjectId']>
  filter?: InputMaybe<Array<InputMaybe<AttributeInstanceFilterInput>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  channelIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  attributeSets?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  hasAttachments?: InputMaybe<Scalars['Boolean']>
  sensitivity?: InputMaybe<Array<InputMaybe<SensitivityLabel>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type MailDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type MailUserEntitiesArgs = {
  isSensitive?: InputMaybe<Scalars['Boolean']>
  database?: InputMaybe<Scalars['String']>
  schema?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<EntityTypes>>>
  toggleFilter?: InputMaybe<EntityToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<EntityFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<EntityBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<UserEntityNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type MailAlertsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  status?: InputMaybe<AlertStatus>
  severity?: InputMaybe<AlertSeverity>
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  dateFilter?: InputMaybe<AlertDateFilterInput>
  filter?: InputMaybe<Array<InputMaybe<AlertFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AlertNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type MailConnection = {
  __typename?: 'MailConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<MailEdge>>
  count?: Maybe<Scalars['Int']>
}

export type MailDatasourceStatus = {
  __typename?: 'MailDatasourceStatus'
  processedCount?: Maybe<Scalars['Int']>
  pendingCount?: Maybe<Scalars['Int']>
  skippedCount?: Maybe<Scalars['Int']>
}

export type MailEdge = {
  __typename?: 'MailEdge'
  node?: Maybe<Mail>
  cursor: Scalars['String']
}

export type MailGroupBySender = {
  __typename?: 'MailGroupBySender'
  sender?: Maybe<EmailDetails>
  mails?: Maybe<MailConnection>
  userEntities?: Maybe<UserEntityConnection>
}

export type MailGroupBySenderMailsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  riskLevel?: InputMaybe<Array<InputMaybe<EmailRiskLevel>>>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  hasAttachments?: InputMaybe<Scalars['Boolean']>
  intRangeFilter?: InputMaybe<EmailIntRangeFilterInput>
  toggleFilter?: InputMaybe<EmailToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<EmailBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<EmailFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<MailNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type MailGroupBySenderUserEntitiesArgs = {
  isSensitive?: InputMaybe<Scalars['Boolean']>
  database?: InputMaybe<Scalars['String']>
  schema?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<EntityTypes>>>
  toggleFilter?: InputMaybe<EntityToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<EntityFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<EntityBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<UserEntityNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type MailGroupBySenderConnection = {
  __typename?: 'MailGroupBySenderConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<MailGroupBySenderEdge>>
  count?: Maybe<Scalars['Int']>
}

export type MailGroupBySenderEdge = {
  __typename?: 'MailGroupBySenderEdge'
  node?: Maybe<MailGroupBySender>
  cursor: Scalars['String']
}

export enum MailNode {
  Subject = 'SUBJECT',
  Size = 'SIZE',
  PiiAttributeCount = 'PII_ATTRIBUTE_COUNT',
  ForeignRecipientCont = 'FOREIGN_RECIPIENT_CONT',
  Risk = 'RISK'
}

export type Mapping = {
  __typename?: 'Mapping'
  sourceField?: Maybe<Scalars['String']>
  attribute?: Maybe<Scalars['String']>
  isSensitive?: Maybe<Scalars['Boolean']>
  confidence?: Maybe<Scalars['Float']>
  sourceFieldIndex?: Maybe<Scalars['Int']>
}

export type MappingInput = {
  sourceField?: InputMaybe<Scalars['String']>
  attribute?: InputMaybe<Scalars['String']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  confidence?: InputMaybe<Scalars['Float']>
}

export enum MaskingStrategy {
  Character = 'CHARACTER',
  String = 'STRING',
  Attribute = 'ATTRIBUTE'
}

export type MaskingStrategyConfig = CharacterMaskingStrategy | StringMaskingStrategy

export type MaskingStrategyConfigInput = {
  maskingStrategy: MaskingStrategy
  character?: InputMaybe<CharacterMaskingStrategyInput>
  string?: InputMaybe<StringMaskingStrategyInput>
}

export type Member = {
  __typename?: 'Member'
  name: Scalars['String']
  email: Scalars['String']
  objects?: Maybe<ObjectConnection>
  mails?: Maybe<MailConnection>
}

export type MemberObjectsArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type MemberMailsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  riskLevel?: InputMaybe<Array<InputMaybe<EmailRiskLevel>>>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  hasAttachments?: InputMaybe<Scalars['Boolean']>
  intRangeFilter?: InputMaybe<EmailIntRangeFilterInput>
  toggleFilter?: InputMaybe<EmailToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<EmailBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<EmailFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<MailNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum MemberBaseFilterKey {
  DatasourceIds = 'DATASOURCE_IDS',
  MemberType = 'MEMBER_TYPE'
}

export type MemberConnection = {
  __typename?: 'MemberConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<MemberEdge>>
  count?: Maybe<Scalars['Int']>
}

export type MemberEdge = {
  __typename?: 'MemberEdge'
  node?: Maybe<Member>
  cursor: Scalars['String']
}

export type MemberFilterInput = {
  key: MemberBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type MemberIntRangeFilterInput = {
  key: MemberIntRangeFilterKey
  min?: InputMaybe<Scalars['Int']>
  max?: InputMaybe<Scalars['Int']>
}

export enum MemberIntRangeFilterKey {
  SensitiveObjectsCount = 'SENSITIVE_OBJECTS_COUNT',
  RiskyObjectsCount = 'RISKY_OBJECTS_COUNT'
}

export enum MemberNode {
  Name = 'NAME',
  Type = 'TYPE',
  PiiDocumentCount = 'PII_DOCUMENT_COUNT',
  RiskyDocumentCount = 'RISKY_DOCUMENT_COUNT'
}

export type MemberToggleFilterInput = {
  key: MemberToggleFilterKey
}

export enum MemberToggleFilterKey {
  Internal = 'INTERNAL',
  Guest = 'GUEST',
  IsRisky = 'IS_RISKY',
  IsSensitive = 'IS_SENSITIVE'
}

export enum MemberTypes {
  Internal = 'INTERNAL',
  Guest = 'GUEST'
}

export type Members = {
  __typename?: 'Members'
  id: Scalars['GrapheneObjectId']
  name?: Maybe<Scalars['String']>
  type?: Maybe<MemberTypes>
  piiDocumentsCount?: Maybe<Scalars['Int']>
  riskDocumentsCount?: Maybe<Scalars['Int']>
  datasource?: Maybe<DatasourceConnection>
}

export type MembersDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type MembersConnection = {
  __typename?: 'MembersConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<MembersEdge>>
  count?: Maybe<Scalars['Int']>
}

export type MembersEdge = {
  __typename?: 'MembersEdge'
  node?: Maybe<Members>
  cursor: Scalars['String']
}

export enum MicrosoftOfficeAccountType {
  Personal = 'PERSONAL',
  Organization = 'ORGANIZATION'
}

export type MicrosoftOfficeConfig = {
  __typename?: 'MicrosoftOfficeConfig'
  delegatedCredential?: Maybe<Scalars['String']>
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  accountType?: Maybe<MicrosoftOfficeAccountType>
  tenantId: Scalars['String']
}

export type MsSqlConfig = {
  __typename?: 'MsSQLConfig'
  username: Scalars['String']
  password: Scalars['String']
  host: Scalars['String']
  port: Scalars['Int']
}

export type Mutation = {
  __typename?: 'Mutation'
  createFilter?: Maybe<FilterPayload>
  deleteFilter?: Maybe<DeletePayload>
  updateFilter?: Maybe<FilterPayload>
  updateProfileMap?: Maybe<UpdateProfileMapPayload>
  createPolicy?: Maybe<PolicyPayload>
  deletePolicy?: Maybe<DeletePayload>
  updatePolicy?: Maybe<PolicyPayload>
  muteAlert?: Maybe<AlertActionPayload>
  unMuteAlert?: Maybe<AlertActionPayload>
  updateMuteAlertDuration?: Maybe<AlertActionPayload>
  addToNoScanList?: Maybe<SubAlertActionPayload>
  resolveAlert?: Maybe<AlertActionPayload>
  reassignAlert?: Maybe<AlertActionPayload>
  ignoreSubAlert?: Maybe<SubAlertActionPayload>
  addToPermitList?: Maybe<SubAlertActionPayload>
  createAttribute?: Maybe<AttributePayload>
  updateAttribute?: Maybe<AttributePayload>
  deleteAttribute?: Maybe<DeletePayload>
  createAttributeSet?: Maybe<AttributeSetPayload>
  updateAttributeSet?: Maybe<AttributeSetPayload>
  deleteAttributeSet?: Maybe<DeletePayload>
  detectAttributes?: Maybe<DetectAttributesPayload>
  createRelationshipDraftObject?: Maybe<RelationshipDraftOutputObject>
  createPiiClassificationDraftObject?: Maybe<PiiClassificationDraftOutputObject>
  createIdentityTableDraftObject?: Maybe<IdentityTableDraftOutputObject>
  createRelationship?: Maybe<RelationshipOutputObject>
  triggerEntityScan?: Maybe<EntityScanTriggerOutput>
  updatePartnerDetails?: Maybe<PartnerPayload>
  createPartner?: Maybe<PartnerPayload>
  reviewTable?: Maybe<ReviewTableOutput>
  reviewTableCluster?: Maybe<ReviewTableClusterOutput>
  reviewStructObjects?: Maybe<ReviewStructObjectsOutput>
  createTablePrimaryKey?: Maybe<TablePrimaryKeyOutput>
  deleteTablePrimaryKey?: Maybe<TablePrimaryKeyOutput>
  createProject?: Maybe<ProjectPayload>
  updateProject?: Maybe<ProjectPayload>
  deleteProject?: Maybe<DeletePayload>
}

export type MutationCreateFilterArgs = {
  clientMutationId: Scalars['ID']
  filterData: FiltersInput
}

export type MutationDeleteFilterArgs = {
  clientMutationId: Scalars['ID']
  id: Scalars['GrapheneObjectId']
}

export type MutationUpdateFilterArgs = {
  clientMutationId: Scalars['ID']
  filterData: UpdateFiltersInput
  id: Scalars['GrapheneObjectId']
}

export type MutationUpdateProfileMapArgs = {
  clientMutationId: Scalars['ID']
  id: Scalars['GrapheneObjectId']
  mappingInput: Array<InputMaybe<MappingInput>>
}

export type MutationCreatePolicyArgs = {
  clientMutationId: Scalars['ID']
  policyData: PolicyInput
}

export type MutationDeletePolicyArgs = {
  clientMutationId: Scalars['ID']
  id: Scalars['GrapheneObjectId']
}

export type MutationUpdatePolicyArgs = {
  clientMutationId: Scalars['ID']
  id: Scalars['GrapheneObjectId']
  policyData: UpdatePolicyInput
}

export type MutationMuteAlertArgs = {
  clientMutationId: Scalars['ID']
  ids: Array<InputMaybe<Scalars['GrapheneObjectId']>>
  muteAlertData: MuteAlertInput
}

export type MutationUnMuteAlertArgs = {
  clientMutationId: Scalars['ID']
  ids: Array<InputMaybe<Scalars['GrapheneObjectId']>>
}

export type MutationUpdateMuteAlertDurationArgs = {
  clientMutationId: Scalars['ID']
  id: Scalars['GrapheneObjectId']
  updateMuteAlertData: MuteAlertDurationInput
}

export type MutationAddToNoScanListArgs = {
  actionInput: NoScanActionInput
}

export type MutationResolveAlertArgs = {
  clientMutationId: Scalars['ID']
  ids: Array<InputMaybe<Scalars['GrapheneObjectId']>>
  resolveAlertData: ResolveActionInput
}

export type MutationReassignAlertArgs = {
  clientMutationId: Scalars['ID']
  ids: Array<InputMaybe<Scalars['GrapheneObjectId']>>
  reassignActionData: ReassignActionInput
}

export type MutationIgnoreSubAlertArgs = {
  clientMutationId: Scalars['ID']
  ids: Array<InputMaybe<Scalars['GrapheneObjectId']>>
}

export type MutationAddToPermitListArgs = {
  actionInput: PermitActionInput
}

export type MutationCreateAttributeArgs = {
  attributeData: CreateAttributeInput
  clientMutationId: Scalars['ID']
}

export type MutationUpdateAttributeArgs = {
  attributeData: UpdateAttributeInput
  clientMutationId: Scalars['ID']
  id: Scalars['ID']
}

export type MutationDeleteAttributeArgs = {
  clientMutationId: Scalars['ID']
  id: Scalars['ID']
}

export type MutationCreateAttributeSetArgs = {
  attributeSetData: CreateAttributeSetInput
  clientMutationId: Scalars['ID']
}

export type MutationUpdateAttributeSetArgs = {
  attributeSetData: UpdateAttributeSetInput
  clientMutationId: Scalars['ID']
  id: Scalars['ID']
}

export type MutationDeleteAttributeSetArgs = {
  clientMutationId: Scalars['ID']
  id: Scalars['ID']
}

export type MutationDetectAttributesArgs = {
  clientMutationId: Scalars['ID']
  detectionInput: DetectAttributesInput
}

export type MutationCreateRelationshipDraftObjectArgs = {
  draftObject?: InputMaybe<RelationshipDraftInputObject>
}

export type MutationCreatePiiClassificationDraftObjectArgs = {
  draftObject?: InputMaybe<PiiClassificationDraftInputObject>
}

export type MutationCreateIdentityTableDraftObjectArgs = {
  draftObject?: InputMaybe<IdentityTableDraftInputObject>
}

export type MutationCreateRelationshipArgs = {
  relationshipObject?: InputMaybe<RelationshipInputObject>
}

export type MutationTriggerEntityScanArgs = {
  triggerEntityScanInput?: InputMaybe<EntityScanTriggerInput>
}

export type MutationUpdatePartnerDetailsArgs = {
  clientMutationId: Scalars['ID']
  partnerData: PartnerUpdateInput
}

export type MutationCreatePartnerArgs = {
  clientMutationId: Scalars['ID']
  partnerData: PartnerCreateInput
}

export type MutationReviewTableArgs = {
  tableId: Scalars['String']
}

export type MutationReviewTableClusterArgs = {
  clusterId: Scalars['String']
}

export type MutationReviewStructObjectsArgs = {
  reviewStructObjectsInput?: InputMaybe<ReviewStructObjectsInput>
}

export type MutationCreateTablePrimaryKeyArgs = {
  columnId?: InputMaybe<Scalars['String']>
}

export type MutationDeleteTablePrimaryKeyArgs = {
  columnId?: InputMaybe<Scalars['String']>
}

export type MutationCreateProjectArgs = {
  clientMutationId: Scalars['ID']
  projectData: CreateProjectInput
}

export type MutationUpdateProjectArgs = {
  clientMutationId: Scalars['ID']
  id: Scalars['ID']
  projectData: UpdateProjectInput
}

export type MutationDeleteProjectArgs = {
  clientMutationId: Scalars['ID']
  id: Scalars['ID']
}

export type MuteAlertDurationInput = {
  muteDuration: Scalars['BigInt']
  muteDurationType?: InputMaybe<DurationType>
}

export type MuteAlertInput = {
  muteDuration: Scalars['BigInt']
  muteDurationType?: InputMaybe<DurationType>
  comment: Scalars['String']
}

export type MySqlConfig = {
  __typename?: 'MySQLConfig'
  username: Scalars['String']
  password: Scalars['String']
  host: Scalars['String']
  port: Scalars['Int']
}

export type NoScanActionInput = {
  clientMutationId: Scalars['ID']
  datasourceId: Scalars['GrapheneObjectId']
  assignee?: InputMaybe<Scalars['String']>
  senderEmailIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  recipientEmailIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ids?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  alertId?: InputMaybe<Scalars['GrapheneObjectId']>
  channelIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  folderIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ticketIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ticketStoreProjectIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  projectIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  comment?: InputMaybe<Scalars['String']>
  mailObjectIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
}

export type Node = {
  id: Scalars['ID']
}

export type NotifyAction = {
  __typename?: 'NotifyAction'
  raiseAnAlert?: Maybe<Scalars['Boolean']>
  labelling?: Maybe<Scalars['Boolean']>
  labelText?: Maybe<Scalars['GrapheneObjectId']>
  auditLog?: Maybe<Scalars['Boolean']>
}

export type NotifyActionInput = {
  raiseAnAlert?: InputMaybe<Scalars['Boolean']>
  labelling?: InputMaybe<Scalars['Boolean']>
  labelText?: InputMaybe<Scalars['ID']>
  auditLog?: InputMaybe<Scalars['Boolean']>
}

export type Object = Node & {
  __typename?: 'Object'
  id: Scalars['ID']
  type?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  classification?: Maybe<Scalars['String']>
  unclassifiedCategory?: Maybe<UnclassifiedCategory>
  subclass?: Maybe<Scalars['String']>
  containsPiiData?: Maybe<Scalars['Boolean']>
  lastModifiedTime?: Maybe<Scalars['DateTime']>
  size?: Maybe<Scalars['Int']>
  owner?: Maybe<Scalars['String']>
  folder?: Maybe<FolderInfo>
  datasource?: Maybe<DatasourceConnection>
  template?: Maybe<TemplateConnection>
  messageId?: Maybe<Scalars['String']>
  sharedOn?: Maybe<Scalars['String']>
  sharedBy?: Maybe<SenderDetails>
  objectList?: Maybe<ObjectList>
  entity?: Maybe<UserEntityConnection>
  objectLink?: Maybe<Scalars['String']>
  attribute?: Maybe<AttributeConnection>
  alert?: Maybe<AlertConnection>
  drive?: Maybe<DriveConnection>
  mail?: Maybe<MailConnection>
  ticket?: Maybe<TicketConnection>
  project?: Maybe<ProjectConnection>
  attributes?: Maybe<Array<Maybe<Scalars['String']>>>
  entities?: Maybe<Array<Maybe<UserEntity>>>
  attributeCount?: Maybe<Scalars['Int']>
}

export type ObjectDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ObjectTemplateArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  dateFilter?: InputMaybe<TemplateDateFilterInput>
  intRangeFilter?: InputMaybe<TemplateIntRangeFilterInput>
  toggleFilter?: InputMaybe<TemplateToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<TemplateFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<TemplateNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ObjectEntityArgs = {
  isSensitive?: InputMaybe<Scalars['Boolean']>
  database?: InputMaybe<Scalars['String']>
  schema?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<EntityTypes>>>
  toggleFilter?: InputMaybe<EntityToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<EntityFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<EntityBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<UserEntityNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ObjectAttributeArgs = {
  id?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  systemDefined?: InputMaybe<Scalars['Boolean']>
  attributeSets?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  sensitivity?: InputMaybe<Array<InputMaybe<SensitivityLabel>>>
  hasInstances?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Array<InputMaybe<AttributeFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<AttributeBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AttributeNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ObjectAlertArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  status?: InputMaybe<AlertStatus>
  severity?: InputMaybe<AlertSeverity>
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  dateFilter?: InputMaybe<AlertDateFilterInput>
  filter?: InputMaybe<Array<InputMaybe<AlertFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AlertNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ObjectDriveArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DriveTypes>>>
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  intRangeFilter?: InputMaybe<DriveIntRangeFilterInput>
  toggleFilter?: InputMaybe<DriveToggleFilterInput>
  searchQuery?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Array<InputMaybe<DriveFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DriveNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ObjectMailArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  riskLevel?: InputMaybe<Array<InputMaybe<EmailRiskLevel>>>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  hasAttachments?: InputMaybe<Scalars['Boolean']>
  intRangeFilter?: InputMaybe<EmailIntRangeFilterInput>
  toggleFilter?: InputMaybe<EmailToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<EmailBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<EmailFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<MailNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ObjectTicketArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  ticketId?: InputMaybe<Scalars['String']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  booleanFilter?: InputMaybe<Array<InputMaybe<TicketBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<TicketFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ObjectProjectArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  filter?: InputMaybe<Array<InputMaybe<ProjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ProjectNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum ObjectBaseFilterKey {
  Name = 'NAME',
  Type = 'TYPE',
  Classification = 'CLASSIFICATION',
  SubClassification = 'SUB_CLASSIFICATION',
  TemplateName = 'TEMPLATE_NAME',
  DriveIds = 'DRIVE_IDS',
  UserEntityIds = 'USER_ENTITY_IDS',
  AttributeIds = 'ATTRIBUTE_IDS',
  SharedWith = 'SHARED_WITH',
  Owner = 'OWNER',
  PartnerIds = 'PARTNER_IDS',
  ProjectIds = 'PROJECT_IDS'
}

export type ObjectBooleanFilterInput = {
  key: ObjectBooleanFilterKey
  value: Scalars['Boolean']
}

export enum ObjectBooleanFilterKey {
  IsRisky = 'IS_RISKY',
  IsSensitive = 'IS_SENSITIVE',
  HasAlerts = 'HAS_ALERTS',
  IsSharedInternally = 'IS_SHARED_INTERNALLY',
  IsSharedExternally = 'IS_SHARED_EXTERNALLY'
}

export type ObjectBooleanPartnerBaseFilterKey = {
  key: PartnerBaseFilterKey
  value: Scalars['Boolean']
}

export enum ObjectCategory {
  File = 'FILE',
  Message = 'MESSAGE',
  Blob = 'BLOB'
}

export type ObjectConnection = {
  __typename?: 'ObjectConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<ObjectEdge>>
  count?: Maybe<Scalars['Int']>
}

export type ObjectDateFilterInput = {
  key: ObjectDateFilterKey
  start?: InputMaybe<Scalars['BigInt']>
  end?: InputMaybe<Scalars['BigInt']>
}

export enum ObjectDateFilterKey {
  LastModifiedTime = 'LAST_MODIFIED_TIME'
}

export type ObjectEdge = {
  __typename?: 'ObjectEdge'
  node?: Maybe<Record<string, unknown>>
  cursor: Scalars['String']
}

export type ObjectExpression = {
  __typename?: 'ObjectExpression'
  objectType?: Maybe<ProfileFilterObjectTypes>
  values?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ObjectExpressionInput = {
  objectType?: InputMaybe<ProfileFilterObjectTypes>
  values?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ObjectFilterInput = {
  key: ObjectBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type ObjectGroupByClassificationType = {
  __typename?: 'ObjectGroupByClassificationType'
  classification?: Maybe<Scalars['String']>
  subclass?: Maybe<Array<Maybe<Scalars['String']>>>
  objectCount?: Maybe<Scalars['Int']>
  alert?: Maybe<AlertConnection>
}

export type ObjectGroupByClassificationTypeAlertArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  status?: InputMaybe<AlertStatus>
  severity?: InputMaybe<AlertSeverity>
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  dateFilter?: InputMaybe<AlertDateFilterInput>
  filter?: InputMaybe<Array<InputMaybe<AlertFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AlertNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ObjectGroupByDatasourceType = {
  __typename?: 'ObjectGroupByDatasourceType'
  datasourceType?: Maybe<Scalars['String']>
  objects?: Maybe<ObjectConnection>
  attributeInstance?: Maybe<AttributeInstanceConnection>
}

export type ObjectGroupByDatasourceTypeObjectsArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ObjectGroupByDatasourceTypeAttributeInstanceArgs = {
  alertId?: InputMaybe<Scalars['GrapheneObjectId']>
  entityId?: InputMaybe<Scalars['GrapheneObjectId']>
  filter?: InputMaybe<Array<InputMaybe<AttributeInstanceFilterInput>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  channelIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  attributeSets?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  hasAttachments?: InputMaybe<Scalars['Boolean']>
  sensitivity?: InputMaybe<Array<InputMaybe<SensitivityLabel>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum ObjectGroupByDatasourceTypeBaseFilterKey {
  PartnerIds = 'PARTNER_IDS'
}

export type ObjectGroupByDatasourceTypeFilterInput = {
  key: ObjectGroupByDatasourceTypeBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type ObjectGroupByDatasourceTypetConnection = {
  __typename?: 'ObjectGroupByDatasourceTypetConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<ObjectGroupByDatasourceTypetEdge>>
  count?: Maybe<Scalars['Int']>
}

export type ObjectGroupByDatasourceTypetEdge = {
  __typename?: 'ObjectGroupByDatasourceTypetEdge'
  node?: Maybe<ObjectGroupByDatasourceType>
  cursor: Scalars['String']
}

export type ObjectGroupBySender = Node & {
  __typename?: 'ObjectGroupBySender'
  id: Scalars['ID']
  sender?: Maybe<SenderDetails>
  objects?: Maybe<ObjectConnection>
}

export type ObjectGroupBySenderObjectsArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum ObjectGroupBySenderBaseFilterKey {
  PartnerIds = 'PARTNER_IDS'
}

export type ObjectGroupBySenderConnection = {
  __typename?: 'ObjectGroupBySenderConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<ObjectGroupBySenderEdge>>
  count?: Maybe<Scalars['Int']>
}

export type ObjectGroupBySenderEdge = {
  __typename?: 'ObjectGroupBySenderEdge'
  node?: Maybe<ObjectGroupBySender>
  cursor: Scalars['String']
}

export type ObjectGroupBySenderFilterInput = {
  key: ObjectGroupBySenderBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type ObjectIntRangeFilterInput = {
  key: ObjectIntRangeFilterKey
  min?: InputMaybe<Scalars['Int']>
  max?: InputMaybe<Scalars['Int']>
}

export enum ObjectIntRangeFilterKey {
  Size = 'SIZE'
}

export type ObjectList = Mail

export enum ObjectOperationType {
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE'
}

export type ObjectToggleFilterInput = {
  key: ObjectToggleFilterKey
}

export enum ObjectToggleFilterKey {
  IsRisky = 'IS_RISKY',
  IsSensitive = 'IS_SENSITIVE'
}

export enum ObjectType {
  Document = 'DOCUMENT',
  Log = 'LOG',
  Html = 'HTML',
  Json = 'JSON',
  Generic = 'GENERIC'
}

export enum ObjectTypeInput {
  Pdf = 'PDF',
  Png = 'PNG',
  Jpg = 'JPG',
  Doc = 'DOC',
  Docx = 'DOCX',
  Pptx = 'PPTX',
  Xls = 'XLS',
  Xlsx = 'XLSX',
  Csv = 'CSV',
  Text = 'TEXT',
  Txt = 'TXT',
  Html = 'HTML',
  Json = 'JSON'
}

export enum ObjectTypes {
  Pdf = 'PDF',
  Png = 'PNG',
  Jpg = 'JPG',
  Doc = 'DOC',
  Docx = 'DOCX',
  Pptx = 'PPTX',
  Xls = 'XLS',
  Xlsx = 'XLSX',
  Csv = 'CSV',
  Row = 'ROW',
  Message = 'MESSAGE',
  Text = 'TEXT',
  Txt = 'TXT',
  Table = 'TABLE',
  Other = 'OTHER'
}

export type OracleDatasourceConfig = {
  __typename?: 'OracleDatasourceConfig'
  username: Scalars['String']
  password: Scalars['String']
  host: Scalars['String']
  port: Scalars['String']
  serviceName: Scalars['String']
}

export type OrphanTableCluster = TableCluster & {
  __typename?: 'OrphanTableCluster'
  tables?: Maybe<TableConnection>
  columns?: Maybe<ColumnConnection>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type OrphanTableClusterTablesArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  intRangeFilter?: InputMaybe<TableIntRangeFilterInput>
  dateFilter?: InputMaybe<TableDateFilterInput>
  toggleFilter?: InputMaybe<TableToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<TableBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<TableFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<TableNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type OrphanTableClusterColumnsArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ColumnNode>
  toggleFilter?: InputMaybe<ColumnToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ColumnBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ColumnFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type Owner = {
  __typename?: 'Owner'
  name?: Maybe<Scalars['String']>
  email: Scalars['String']
}

export type OwnerInput = {
  name?: InputMaybe<Scalars['String']>
  email: Scalars['String']
}

export type PiiClassification = {
  __typename?: 'PIIClassification'
  attributeId?: Maybe<Scalars['String']>
  numRowsSampled?: Maybe<Scalars['Int']>
  numRowsPII?: Maybe<Scalars['Int']>
  isReviewed?: Maybe<Scalars['Boolean']>
  lastReviewedOn?: Maybe<Scalars['BigInt']>
  reviewedBy?: Maybe<Scalars['String']>
  createdBy?: Maybe<Scalars['String']>
}

export type PiiClassificationObject = {
  attributeId?: InputMaybe<Scalars['String']>
  numRowsSampled?: InputMaybe<Scalars['Int']>
  numRowsPII?: InputMaybe<Scalars['Int']>
  isReviewed?: InputMaybe<Scalars['Boolean']>
  reviewedBy?: InputMaybe<Scalars['String']>
  createdBy?: InputMaybe<Scalars['String']>
}

export type PageInfo = {
  __typename?: 'PageInfo'
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  startCursor?: Maybe<Scalars['String']>
  endCursor?: Maybe<Scalars['String']>
}

export type Partner = Node & {
  __typename?: 'Partner'
  id: Scalars['ID']
  domain?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  contractUrl?: Maybe<Scalars['String']>
  dataExchangePurpose?: Maybe<Scalars['String']>
  partnerManager?: Maybe<Scalars['String']>
  partnerContact?: Maybe<Scalars['String']>
  permittedAttributes?: Maybe<Array<Maybe<PermittedAttributes>>>
  isGeneralPartner?: Maybe<Scalars['Boolean']>
  isContractDocumentAdded?: Maybe<Scalars['Boolean']>
  objects?: Maybe<ObjectConnection>
  datasources?: Maybe<DatasourceConnection>
  userEntities?: Maybe<UserEntityConnection>
  attributeInstance?: Maybe<AttributeInstanceConnection>
  attribute?: Maybe<AttributeConnection>
  alert?: Maybe<AlertConnection>
}

export type PartnerObjectsArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type PartnerDatasourcesArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type PartnerUserEntitiesArgs = {
  isSensitive?: InputMaybe<Scalars['Boolean']>
  database?: InputMaybe<Scalars['String']>
  schema?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<EntityTypes>>>
  toggleFilter?: InputMaybe<EntityToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<EntityFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<EntityBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<UserEntityNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type PartnerAttributeInstanceArgs = {
  alertId?: InputMaybe<Scalars['GrapheneObjectId']>
  entityId?: InputMaybe<Scalars['GrapheneObjectId']>
  filter?: InputMaybe<Array<InputMaybe<AttributeInstanceFilterInput>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  channelIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  attributeSets?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  hasAttachments?: InputMaybe<Scalars['Boolean']>
  sensitivity?: InputMaybe<Array<InputMaybe<SensitivityLabel>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type PartnerAttributeArgs = {
  id?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  systemDefined?: InputMaybe<Scalars['Boolean']>
  attributeSets?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  sensitivity?: InputMaybe<Array<InputMaybe<SensitivityLabel>>>
  hasInstances?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Array<InputMaybe<AttributeFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<AttributeBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AttributeNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type PartnerAlertArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  status?: InputMaybe<AlertStatus>
  severity?: InputMaybe<AlertSeverity>
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  dateFilter?: InputMaybe<AlertDateFilterInput>
  filter?: InputMaybe<Array<InputMaybe<AlertFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AlertNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum PartnerBaseFilterKey {
  IsGeneralPartner = 'IS_GENERAL_PARTNER'
}

export type PartnerConnection = {
  __typename?: 'PartnerConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<PartnerEdge>>
  count?: Maybe<Scalars['Int']>
}

export type PartnerCreateInput = {
  name: Scalars['String']
  contractUrl?: InputMaybe<Scalars['String']>
  dataExchangePurpose?: InputMaybe<Scalars['String']>
  partnerManager?: InputMaybe<Scalars['String']>
  partnerContact?: InputMaybe<Scalars['String']>
  permittedAttributes?: InputMaybe<Array<InputMaybe<PermittedAttributesInput>>>
  domain: Scalars['String']
}

export type PartnerEdge = {
  __typename?: 'PartnerEdge'
  node?: Maybe<Partner>
  cursor: Scalars['String']
}

export type PartnerPayload = {
  __typename?: 'PartnerPayload'
  clientMutationId?: Maybe<Scalars['ID']>
  partner?: Maybe<PartnerConnection>
}

export type PartnerPayloadPartnerArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type PartnerUpdateInput = {
  name: Scalars['String']
  contractUrl?: InputMaybe<Scalars['String']>
  dataExchangePurpose?: InputMaybe<Scalars['String']>
  partnerManager?: InputMaybe<Scalars['String']>
  partnerContact?: InputMaybe<Scalars['String']>
  permittedAttributes?: InputMaybe<Array<InputMaybe<PermittedAttributesInput>>>
  Id: Scalars['GrapheneObjectId']
}

export type Pattern = {
  __typename?: 'Pattern'
  name?: Maybe<Scalars['String']>
  regex?: Maybe<Scalars['String']>
  confidence?: Maybe<Scalars['Float']>
}

export type PatternInput = {
  name: Scalars['String']
  regex: Scalars['String']
  confidence: Scalars['Float']
}

export type PermitActionInput = {
  clientMutationId: Scalars['ID']
  datasourceId: Scalars['GrapheneObjectId']
  assignee: Scalars['String']
  senderEmailIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  recipientEmailIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ids?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  alertId?: InputMaybe<Scalars['GrapheneObjectId']>
  channelIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  folderIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ticketIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ticketStoreProjectIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  projectIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  comment?: InputMaybe<Scalars['String']>
}

export type PermitList = {
  __typename?: 'PermitList'
  updateAt: Scalars['DateTime']
  updateBy: Scalars['String']
  ruleSet?: Maybe<PolicyConnection>
  channel?: Maybe<ChannelConnection>
  table?: Maybe<TableConnection>
  permitListObjects?: Maybe<PermitObject>
  datasource?: Maybe<DatasourceConnection>
}

export type PermitListRuleSetArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<PolicyTypes>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type PermitListChannelArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ChannelTypes>>>
  alertId?: InputMaybe<Scalars['GrapheneObjectId']>
  alertStatus?: InputMaybe<AlertStatus>
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  intRangeFilter?: InputMaybe<ChannelIntRangeFilterInput>
  toggleFilter?: InputMaybe<ChannelToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<ChannelFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ChannelNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type PermitListTableArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  intRangeFilter?: InputMaybe<TableIntRangeFilterInput>
  dateFilter?: InputMaybe<TableDateFilterInput>
  toggleFilter?: InputMaybe<TableToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<TableBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<TableFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<TableNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type PermitListDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type PermitListConnection = {
  __typename?: 'PermitListConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<PermitListEdge>>
  count?: Maybe<Scalars['Int']>
}

export type PermitListEdge = {
  __typename?: 'PermitListEdge'
  node?: Maybe<PermitList>
  cursor: Scalars['String']
}

export type PermitListFilterInput = {
  key: PermitListFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export enum PermitListFilterKey {
  PolicyTypeIds = 'POLICY_TYPE_IDS',
  RuleSetIds = 'RULE_SET_IDS',
  DatasourceIds = 'DATASOURCE_IDS'
}

export type PermitListGroupedByPolicy = {
  __typename?: 'PermitListGroupedByPolicy'
  objectCount: Scalars['Int']
  ruleSet?: Maybe<PolicyConnection>
}

export type PermitListGroupedByPolicyRuleSetArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<PolicyTypes>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type PermitObject = Channel | Record<string, unknown> | Folder | Mail | Project

export type PermittedAttributes = {
  __typename?: 'PermittedAttributes'
  attributeSetId?: Maybe<Scalars['GrapheneObjectId']>
  attributeIds?: Maybe<Array<Maybe<Scalars['GrapheneObjectId']>>>
}

export type PermittedAttributesInput = {
  attributeSetId?: InputMaybe<Scalars['GrapheneObjectId']>
  attributeIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
}

export type PiiClassificationDraftInputObject = {
  version?: InputMaybe<Scalars['String']>
  state?: InputMaybe<DraftObjectStatus>
  operationType?: InputMaybe<DraftObjectOperationType>
  urn?: InputMaybe<Scalars['String']>
  classification?: InputMaybe<PiiClassificationObject>
}

export type PiiClassificationDraftOutputObject = {
  __typename?: 'PiiClassificationDraftOutputObject'
  version?: Maybe<Scalars['String']>
  state?: Maybe<DraftObjectStatus>
  operationType?: Maybe<DraftObjectOperationType>
  urn?: Maybe<Scalars['String']>
}

export type Policy = {
  __typename?: 'Policy'
  id: Scalars['GrapheneObjectId']
  name: Scalars['String']
  owner: Scalars['String']
  description: Scalars['String']
  policyFilters: Array<Maybe<PolicyFilters>>
  createdAt: Scalars['DateTime']
  lastModifiedTime: Scalars['DateTime']
  status: Scalars['Boolean']
  notifyAction?: Maybe<NotifyAction>
  systemDefined?: Maybe<Scalars['Boolean']>
  notifyUsers?: Maybe<Array<Maybe<Scalars['String']>>>
  regulations?: Maybe<Array<Maybe<Regulations>>>
  severity?: Maybe<AlertSeverity>
  historicalSyncCompleted?: Maybe<Scalars['Boolean']>
  alertGroup?: Maybe<AlertGroup>
  alertSettings?: Maybe<AlertSettings>
  policyType?: Maybe<PolicyTypeConnection>
  alert?: Maybe<AlertConnection>
}

export type PolicyPolicyTypeArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<PolicyTypes>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type PolicyAlertArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  status?: InputMaybe<AlertStatus>
  severity?: InputMaybe<AlertSeverity>
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  dateFilter?: InputMaybe<AlertDateFilterInput>
  filter?: InputMaybe<Array<InputMaybe<AlertFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AlertNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum PolicyBaseFilterKey {
  Status = 'STATUS',
  Severity = 'SEVERITY',
  Type = 'TYPE',
  Owner = 'OWNER',
  Name = 'NAME'
}

export type PolicyConnection = {
  __typename?: 'PolicyConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<PolicyEdge>>
  count?: Maybe<Scalars['Int']>
}

export type PolicyDateFilterInput = {
  key: PolicyDateFilterKey
  start?: InputMaybe<Scalars['BigInt']>
  end?: InputMaybe<Scalars['BigInt']>
}

export enum PolicyDateFilterKey {
  CreatedAt = 'CREATED_AT',
  LastModifiedTime = 'LAST_MODIFIED_TIME'
}

export type PolicyEdge = {
  __typename?: 'PolicyEdge'
  node?: Maybe<Policy>
  cursor: Scalars['String']
}

export enum PolicyFilterBaseFilterKey {
  Type = 'TYPE',
  Owner = 'OWNER',
  Name = 'NAME',
  DatasourceIds = 'DATASOURCE_IDS',
  ClusterIds = 'CLUSTER_IDS'
}

export enum PolicyFilterDateFilterKey {
  CreatedAt = 'CREATED_AT',
  LastModifiedTime = 'LAST_MODIFIED_TIME'
}

export type PolicyFilterInput = {
  key: PolicyBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type PolicyFilters = {
  __typename?: 'PolicyFilters'
  filterIds: Array<Maybe<Scalars['GrapheneObjectId']>>
  profileIds?: Maybe<Array<Maybe<Scalars['GrapheneObjectId']>>>
}

export type PolicyFiltersDateFilterInput = {
  key: PolicyFilterDateFilterKey
  start?: InputMaybe<Scalars['BigInt']>
  end?: InputMaybe<Scalars['BigInt']>
}

export type PolicyFiltersFilterInput = {
  key: PolicyFilterBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type PolicyFiltersInput = {
  filterIds: Array<InputMaybe<Scalars['GrapheneObjectId']>>
  profileIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
}

export enum PolicyFiltersNode {
  Name = 'NAME',
  Owner = 'OWNER',
  CreatedAt = 'CREATED_AT',
  LastModifiedTime = 'LAST_MODIFIED_TIME'
}

export type PolicyInput = {
  name: Scalars['String']
  owner: Scalars['String']
  description: Scalars['String']
  policyFilters: Array<InputMaybe<PolicyFiltersInput>>
  status: Scalars['Boolean']
  notifyAction?: InputMaybe<NotifyActionInput>
  notifyUsers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  severity: AlertSeverity
  policyType: PolicyTypes
  alertSettings?: InputMaybe<AlertSettings>
}

export enum PolicyNode {
  Name = 'NAME',
  Owner = 'OWNER',
  CreatedAt = 'CREATED_AT',
  LastModifiedTime = 'LAST_MODIFIED_TIME',
  Status = 'STATUS',
  Severity = 'SEVERITY'
}

export type PolicyPayload = {
  __typename?: 'PolicyPayload'
  clientMutationId?: Maybe<Scalars['ID']>
  policy?: Maybe<PolicyConnection>
}

export type PolicyPayloadPolicyArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type PolicyType = {
  __typename?: 'PolicyType'
  id: Scalars['GrapheneObjectId']
  name: PolicyTypes
  description: Scalars['String']
  regulations?: Maybe<Array<Maybe<Regulations>>>
  owner: Scalars['String']
  createdAt: Scalars['DateTime']
  lastModifiedTime?: Maybe<Scalars['DateTime']>
  systemDefined?: Maybe<Scalars['Boolean']>
  policy?: Maybe<PolicyConnection>
  riskyEntitiesCount?: Maybe<Array<Maybe<Scalars['GrapheneObjectId']>>>
}

export type PolicyTypePolicyArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['Boolean']>
  type?: InputMaybe<PolicyTypes>
  dateFilter?: InputMaybe<PolicyDateFilterInput>
  filter?: InputMaybe<Array<InputMaybe<PolicyFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<PolicyNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type PolicyTypeRiskyUserEntityIdsArgs = {
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
}

export type PolicyTypeConnection = {
  __typename?: 'PolicyTypeConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<PolicyTypeEdge>>
  count?: Maybe<Scalars['Int']>
}

export type PolicyTypeEdge = {
  __typename?: 'PolicyTypeEdge'
  node?: Maybe<PolicyType>
  cursor: Scalars['String']
}

export enum PolicyTypes {
  EntityLocationPolicy = 'ENTITY_LOCATION_POLICY',
  DataSharingPolicy = 'DATA_SHARING_POLICY',
  CrossBorderDataPolicy = 'CROSS_BORDER_DATA_POLICY',
  DataConsentPolicy = 'DATA_CONSENT_POLICY',
  InformationManagementPolicy = 'INFORMATION_MANAGEMENT_POLICY',
  DataRetentionPolicy = 'RETENTION',
  DataDuplicationPolicy = 'DATA_DUPLICATION_POLICY'
}

export type PostgreSqlConfig = {
  __typename?: 'PostgreSQLConfig'
  username: Scalars['String']
  password: Scalars['String']
  host: Scalars['String']
  port: Scalars['Int']
}

export type PrimaryIdentifierClassification = {
  __typename?: 'PrimaryIdentifierClassification'
  createdBy?: Maybe<Scalars['String']>
  isPrimaryIdentifier: Scalars['Boolean']
}

export type ProfileFilterConditions = {
  __typename?: 'ProfileFilterConditions'
  filterExpression: Scalars['String']
  objectExpression?: Maybe<Array<Maybe<ObjectExpression>>>
  conditionLabel?: Maybe<LabelConnection>
}

export type ProfileFilterConditionsConditionLabelArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ProfileFilterConditionsInput = {
  filterExpression?: InputMaybe<Scalars['String']>
  objectExpression?: InputMaybe<Array<InputMaybe<ObjectExpressionInput>>>
  conditionLabel: Scalars['String']
}

export enum ProfileFilterObjectTypes {
  Cluster = 'CLUSTER',
  Datasource = 'DATASOURCE'
}

export type ProfileFilters = {
  __typename?: 'ProfileFilters'
  profileMapId: Scalars['GrapheneObjectId']
  conditions?: Maybe<Array<Maybe<ProfileFilterConditions>>>
  entityResolutionInfo?: Maybe<EntityResolutionInfo>
}

export type ProfileFiltersInput = {
  profileMapId?: InputMaybe<Scalars['GrapheneObjectId']>
  conditions?: InputMaybe<Array<InputMaybe<ProfileFilterConditionsInput>>>
}

export type ProfileMap = {
  __typename?: 'ProfileMap'
  id?: Maybe<Scalars['GrapheneObjectId']>
  profileMapData?: Maybe<Scalars['JSONString']>
  fileName?: Maybe<Scalars['String']>
  totalRecordCount?: Maybe<Scalars['Int']>
  filteredRecordCount?: Maybe<Scalars['Int']>
  mapping?: Maybe<Array<Maybe<Mapping>>>
}

export type ProfileMapConnection = {
  __typename?: 'ProfileMapConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<ProfileMapEdge>>
  count?: Maybe<Scalars['Int']>
}

export type ProfileMapEdge = {
  __typename?: 'ProfileMapEdge'
  node?: Maybe<ProfileMap>
  cursor: Scalars['String']
}

export type Project = {
  __typename?: 'Project'
  id: Scalars['GrapheneObjectId']
  name: Scalars['String']
  displayName?: Maybe<Scalars['String']>
  objectType?: Maybe<ObjectType>
  storeState?: Maybe<Scalars['Boolean']>
  default?: Maybe<Scalars['Boolean']>
  owner?: Maybe<Owner>
  coOwners?: Maybe<Array<Maybe<Owner>>>
  dataStore?: Maybe<DataStore>
  apiStats?: Maybe<ApiStats>
  datasource?: Maybe<DatasourceConnection>
  objects?: Maybe<ObjectConnection>
  attributes?: Maybe<AttributeConnection>
  userEntities?: Maybe<UserEntityConnection>
}

export type ProjectDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ProjectObjectsArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ProjectAttributesArgs = {
  id?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  systemDefined?: InputMaybe<Scalars['Boolean']>
  attributeSets?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  sensitivity?: InputMaybe<Array<InputMaybe<SensitivityLabel>>>
  hasInstances?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Array<InputMaybe<AttributeFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<AttributeBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AttributeNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ProjectUserEntitiesArgs = {
  isSensitive?: InputMaybe<Scalars['Boolean']>
  database?: InputMaybe<Scalars['String']>
  schema?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<EntityTypes>>>
  toggleFilter?: InputMaybe<EntityToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<EntityFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<EntityBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<UserEntityNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum ProjectBaseFilterKey {
  Name = 'NAME',
  ObjectType = 'OBJECT_TYPE',
  DatasourceIds = 'DATASOURCE_IDS',
  EntityIds = 'ENTITY_IDS'
}

export type ProjectConnection = {
  __typename?: 'ProjectConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<ProjectEdge>>
  count?: Maybe<Scalars['Int']>
}

export type ProjectEdge = {
  __typename?: 'ProjectEdge'
  node?: Maybe<Project>
  cursor: Scalars['String']
}

export type ProjectFilterInput = {
  key: ProjectBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export enum ProjectNode {
  Name = 'NAME',
  Type = 'TYPE'
}

export type ProjectPayload = {
  __typename?: 'ProjectPayload'
  clientMutationId?: Maybe<Scalars['ID']>
  project?: Maybe<ProjectConnection>
}

export type ProjectPayloadProjectArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type Query = {
  __typename?: 'Query'
  node?: Maybe<Node>
  attributeInstance?: Maybe<AttributeInstanceConnection>
  attributeInstanceGroupedbyName?: Maybe<Array<Maybe<AttributeInstanceGroupedbyName>>>
  attributeInstanceGroupedbyDatasource?: Maybe<Array<Maybe<AttributeInstanceGroupedbyDatasource>>>
  attributeInstanceGroupedbyDocumentClass?: Maybe<
    Array<Maybe<AttributeInstanceGroupedbyDocumentClass>>
  >
  attributeInstanceGroupedbyProject?: Maybe<Array<Maybe<AttributeInstanceGroupedbyProject>>>
  datasources?: Maybe<DatasourceConnection>
  objects?: Maybe<ObjectConnection>
  userEntities?: Maybe<UserEntityConnection>
  filters?: Maybe<FilterConnection>
  profileMaps?: Maybe<ProfileMapConnection>
  databases?: Maybe<DatabaseConnection>
  schemas?: Maybe<SchemaConnection>
  tables?: Maybe<TableConnection>
  columns?: Maybe<ColumnConnection>
  datasourceProgress?: Maybe<DatasourceProgressConnection>
  policy?: Maybe<PolicyConnection>
  policyType?: Maybe<PolicyTypeConnection>
  alert?: Maybe<AlertConnection>
  subAlert?: Maybe<SubAlertConnection>
  label?: Maybe<LabelConnection>
  permitList?: Maybe<PermitListConnection>
  permitListGroupedbyName?: Maybe<Array<Maybe<PermitListGroupedByPolicy>>>
  graphSeries?: Maybe<Array<Maybe<GraphSeries>>>
  objectGroupByClassificationType?: Maybe<Array<Maybe<ObjectGroupByClassificationType>>>
  alertSummary?: Maybe<Array<Maybe<AlertSummary>>>
  alertGroupByDatasource?: Maybe<AlertGroupByDatasourceConnection>
  attribute?: Maybe<AttributeConnection>
  attributeSet?: Maybe<AttributeSetConnection>
  channel?: Maybe<ChannelConnection>
  mail?: Maybe<MailConnection>
  drive?: Maybe<DriveConnection>
  members?: Maybe<MemberConnection>
  mailGroupBySender?: Maybe<MailGroupBySenderConnection>
  partner?: Maybe<PartnerConnection>
  objectGroupBySender?: Maybe<ObjectGroupBySenderConnection>
  objectGroupByDatasourceType?: Maybe<ObjectGroupByDatasourceTypetConnection>
  ticket?: Maybe<TicketConnection>
  ticketStoreProject?: Maybe<TicketStoreProjectConnection>
  ticketsGroupByReporter?: Maybe<TicketsGroupbyReporterConnection>
  projects?: Maybe<ProjectConnection>
}

export type QueryNodeArgs = {
  id: Scalars['ID']
}

export type QueryAttributeInstanceArgs = {
  alertId?: InputMaybe<Scalars['GrapheneObjectId']>
  entityId?: InputMaybe<Scalars['GrapheneObjectId']>
  filter?: InputMaybe<Array<InputMaybe<AttributeInstanceFilterInput>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  channelIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  attributeSets?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  hasAttachments?: InputMaybe<Scalars['Boolean']>
  sensitivity?: InputMaybe<Array<InputMaybe<SensitivityLabel>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryAttributeInstanceGroupedbyNameArgs = {
  alertId?: InputMaybe<Scalars['GrapheneObjectId']>
  entityId?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  filter?: InputMaybe<Array<InputMaybe<AttributeInstanceFilterInput>>>
}

export type QueryAttributeInstanceGroupedbyDatasourceArgs = {
  alertId?: InputMaybe<Scalars['GrapheneObjectId']>
  entityId?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  filter?: InputMaybe<Array<InputMaybe<AttributeInstanceFilterInput>>>
}

export type QueryAttributeInstanceGroupedbyDocumentClassArgs = {
  alertId?: InputMaybe<Scalars['GrapheneObjectId']>
  entityId?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  filter?: InputMaybe<Array<InputMaybe<AttributeInstanceFilterInput>>>
}

export type QueryAttributeInstanceGroupedbyProjectArgs = {
  alertId?: InputMaybe<Scalars['GrapheneObjectId']>
  entityId?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  filter?: InputMaybe<Array<InputMaybe<AttributeInstanceFilterInput>>>
}

export type QueryDatasourcesArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryObjectsArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryUserEntitiesArgs = {
  isSensitive?: InputMaybe<Scalars['Boolean']>
  database?: InputMaybe<Scalars['String']>
  schema?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<EntityTypes>>>
  toggleFilter?: InputMaybe<EntityToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<EntityFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<EntityBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<UserEntityNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryFiltersArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  filterIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  type?: InputMaybe<FilterTypes>
  dateFilter?: InputMaybe<PolicyFiltersDateFilterInput>
  filter?: InputMaybe<Array<InputMaybe<PolicyFiltersFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<PolicyFiltersNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryProfileMapsArgs = {
  id: Scalars['GrapheneObjectId']
  limit?: InputMaybe<Scalars['Int']>
  conditions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryDatabasesArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  intRangeFilter?: InputMaybe<DatabaseIntRangeFilterInput>
  dateFilter?: InputMaybe<DatabaseDateFilterInput>
  toggleFilter?: InputMaybe<DatabaseToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatabaseFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatabaseNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QuerySchemasArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  databaseQualifiedName?: InputMaybe<Scalars['String']>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<SchemaNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryTablesArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  intRangeFilter?: InputMaybe<TableIntRangeFilterInput>
  dateFilter?: InputMaybe<TableDateFilterInput>
  toggleFilter?: InputMaybe<TableToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<TableBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<TableFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<TableNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryColumnsArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ColumnNode>
  toggleFilter?: InputMaybe<ColumnToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ColumnBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ColumnFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryDatasourceProgressArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<Scalars['String']>
  runId?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Array<InputMaybe<DatasourceProgressFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryPolicyArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['Boolean']>
  type?: InputMaybe<PolicyTypes>
  dateFilter?: InputMaybe<PolicyDateFilterInput>
  filter?: InputMaybe<Array<InputMaybe<PolicyFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<PolicyNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryPolicyTypeArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<PolicyTypes>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryAlertArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  status?: InputMaybe<AlertStatus>
  severity?: InputMaybe<AlertSeverity>
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  dateFilter?: InputMaybe<AlertDateFilterInput>
  filter?: InputMaybe<Array<InputMaybe<AlertFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AlertNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QuerySubAlertArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  status?: InputMaybe<AlertStatus>
  userEntityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  severity?: InputMaybe<AlertSeverity>
  intRangeFilter?: InputMaybe<SubAlertIntRangeFilterInput>
  dateFilter?: InputMaybe<SubAlertDateFilterInput>
  externalUser?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Array<InputMaybe<SubAlertFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<SubAlertNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryLabelArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryPermitListArgs = {
  filter?: InputMaybe<Array<InputMaybe<PermitListFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryPermitListGroupedbyNameArgs = {
  filter?: InputMaybe<Array<InputMaybe<PermitListFilterInput>>>
}

export type QueryGraphSeriesArgs = {
  range: DateRangeType
  type?: InputMaybe<GraphSeriesType>
  datasourceId?: InputMaybe<Scalars['String']>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
}

export type QueryObjectGroupByClassificationTypeArgs = {
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
}

export type QueryAlertSummaryArgs = {
  status?: InputMaybe<AlertStatus>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  filter?: InputMaybe<Array<InputMaybe<AlertSummaryFilterInput>>>
}

export type QueryAlertGroupByDatasourceArgs = {
  status?: InputMaybe<AlertStatus>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryAttributeArgs = {
  id?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  systemDefined?: InputMaybe<Scalars['Boolean']>
  attributeSets?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  sensitivity?: InputMaybe<Array<InputMaybe<SensitivityLabel>>>
  hasInstances?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Array<InputMaybe<AttributeFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<AttributeBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AttributeNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryAttributeSetArgs = {
  id?: InputMaybe<Scalars['String']>
  systemDefined?: InputMaybe<Scalars['Boolean']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  filter?: InputMaybe<Array<InputMaybe<AttributeSetFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AttributeSetNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryChannelArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ChannelTypes>>>
  alertId?: InputMaybe<Scalars['GrapheneObjectId']>
  alertStatus?: InputMaybe<AlertStatus>
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  intRangeFilter?: InputMaybe<ChannelIntRangeFilterInput>
  toggleFilter?: InputMaybe<ChannelToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<ChannelFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ChannelNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryMailArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  riskLevel?: InputMaybe<Array<InputMaybe<EmailRiskLevel>>>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  hasAttachments?: InputMaybe<Scalars['Boolean']>
  intRangeFilter?: InputMaybe<EmailIntRangeFilterInput>
  toggleFilter?: InputMaybe<EmailToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<EmailBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<EmailFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<MailNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryDriveArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DriveTypes>>>
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  intRangeFilter?: InputMaybe<DriveIntRangeFilterInput>
  toggleFilter?: InputMaybe<DriveToggleFilterInput>
  searchQuery?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Array<InputMaybe<DriveFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DriveNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryMembersArgs = {
  filter?: InputMaybe<Array<InputMaybe<MemberFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryMailGroupBySenderArgs = {
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  searchQuery?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Array<InputMaybe<EmailGroupBySenderFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryPartnerArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanPartnerBaseFilterKey>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryObjectGroupBySenderArgs = {
  filter?: InputMaybe<Array<InputMaybe<ObjectGroupBySenderFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryObjectGroupByDatasourceTypeArgs = {
  filter?: InputMaybe<Array<InputMaybe<ObjectGroupByDatasourceTypeFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryTicketArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  ticketId?: InputMaybe<Scalars['String']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  booleanFilter?: InputMaybe<Array<InputMaybe<TicketBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<TicketFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryTicketStoreProjectArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryTicketsGroupByReporterArgs = {
  datasourceId: Scalars['GrapheneObjectId']
  booleanFilter?: InputMaybe<Array<InputMaybe<TicketsGroupbyReporterBooleanFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryProjectsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  filter?: InputMaybe<Array<InputMaybe<ProjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ProjectNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ReassignActionInput = {
  assignees: Array<InputMaybe<Scalars['String']>>
  reassignComment?: InputMaybe<Scalars['String']>
}

export enum Regulations {
  Gdpr = 'GDPR',
  Ccpa = 'CCPA',
  Hipaa = 'HIPAA'
}

export type RelationshipDraftInputObject = {
  version?: InputMaybe<Scalars['String']>
  state?: InputMaybe<DraftObjectStatus>
  leftColumnUrn?: InputMaybe<Scalars['String']>
  rightColumnUrn?: InputMaybe<Scalars['String']>
  operationType?: InputMaybe<DraftObjectOperationType>
}

export type RelationshipDraftOutputObject = {
  __typename?: 'RelationshipDraftOutputObject'
  version?: Maybe<Scalars['String']>
  state?: Maybe<DraftObjectStatus>
  leftColumnUrn?: Maybe<Scalars['String']>
  rightColumnUrn?: Maybe<Scalars['String']>
  operationType?: Maybe<DraftObjectOperationType>
}

export type RelationshipInputObject = {
  leftColumnUrn?: InputMaybe<Scalars['String']>
  rightColumnUrn?: InputMaybe<Scalars['String']>
  operationType?: InputMaybe<ObjectOperationType>
}

export type RelationshipOutputObject = {
  __typename?: 'RelationshipOutputObject'
  leftColumnUrn?: Maybe<Scalars['String']>
  rightColumnUrn?: Maybe<Scalars['String']>
  operationType?: Maybe<ObjectOperationType>
}

export type Reporter = {
  __typename?: 'Reporter'
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type Residency = {
  __typename?: 'Residency'
  id: Scalars['GrapheneObjectId']
  name?: Maybe<Scalars['String']>
  filesCount?: Maybe<Scalars['Int']>
  riskyFilesCount?: Maybe<Scalars['Int']>
  datasource?: Maybe<DatasourceConnection>
  userEntities?: Maybe<UserEntityConnection>
}

export type ResidencyDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ResidencyUserEntitiesArgs = {
  isSensitive?: InputMaybe<Scalars['Boolean']>
  database?: InputMaybe<Scalars['String']>
  schema?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<EntityTypes>>>
  toggleFilter?: InputMaybe<EntityToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<EntityFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<EntityBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<UserEntityNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum ResidencyBaseFilterKey {
  Name = 'NAME',
  Entity = 'ENTITY'
}

export type ResidencyConnection = {
  __typename?: 'ResidencyConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<ResidencyEdge>>
  count?: Maybe<Scalars['Int']>
}

export type ResidencyEdge = {
  __typename?: 'ResidencyEdge'
  node?: Maybe<Residency>
  cursor: Scalars['String']
}

export type ResidencyFilterInput = {
  key: ResidencyBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type ResidencyIntRangeFilterInput = {
  key: ResidencyIntRangeFilterKey
  min?: InputMaybe<Scalars['Int']>
  max?: InputMaybe<Scalars['Int']>
}

export enum ResidencyIntRangeFilterKey {
  ObjectsCount = 'OBJECTS_COUNT',
  RiskyObjectsCount = 'RISKY_OBJECTS_COUNT'
}

export enum ResidencyNode {
  Name = 'NAME',
  ObjectCount = 'OBJECT_COUNT',
  RiskyObjectCount = 'RISKY_OBJECT_COUNT'
}

export type ResolveActionInput = {
  resolvedReason: ResolvedReasons
  comment: Scalars['String']
}

export enum ResolvedReasons {
  FalsePositive = 'FALSE_POSITIVE',
  HandledOffline = 'HANDLED_OFFLINE',
  Other = 'OTHER'
}

export type ReviewStatus = {
  __typename?: 'ReviewStatus'
  classificationsReviewed: Scalars['Boolean']
  relationshipsReviewed: Scalars['Boolean']
  lastReviewedOn?: Maybe<Scalars['BigInt']>
  lastReviewComment?: Maybe<Scalars['String']>
}

export type ReviewStructObjectsInput = {
  parentTableId: Scalars['String']
  databaseId: Scalars['String']
}

export type ReviewStructObjectsOutput = {
  __typename?: 'ReviewStructObjectsOutput'
  success?: Maybe<Scalars['Boolean']>
  message?: Maybe<Scalars['String']>
}

export type ReviewTableClusterOutput = {
  __typename?: 'ReviewTableClusterOutput'
  success?: Maybe<Scalars['Boolean']>
}

export type ReviewTableOutput = {
  __typename?: 'ReviewTableOutput'
  success?: Maybe<Scalars['Boolean']>
}

export type SalesforceDatasourceConfig = {
  __typename?: 'SalesforceDatasourceConfig'
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  refreshToken: Scalars['String']
  url: Scalars['String']
}
export type SmbConfig = {
  __typename?: 'smbConfiguration'
  smbUrl: Scalars['String']
  credentials: Record<
    string,
    {
      username: Scalars['String']
      password: Scalars['String']
      domain: Scalars['String']
    }
  >
  fileShares: Array<Maybe<Record<string, { shareName: Scalars['String'] }>>>
}

export type SampleData = {
  __typename?: 'SampleData'
  header: Array<Maybe<Scalars['String']>>
  rows: Array<Maybe<Array<Maybe<Scalars['String']>>>>
}

export type Schema = Node & {
  __typename?: 'Schema'
  id: Scalars['ID']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  qualifiedName: Scalars['String']
  createdOn: Scalars['String']
  lastModifiedOn?: Maybe<Scalars['String']>
  tables?: Maybe<TableConnection>
  columns?: Maybe<ColumnConnection>
  database?: Maybe<DatabaseConnection>
  datasource?: Maybe<DatasourceConnection>
  size?: Maybe<Scalars['BigInt']>
  rowCount?: Maybe<Scalars['BigInt']>
  createdBy?: Maybe<Scalars['String']>
  modifiedBy?: Maybe<Scalars['String']>
  classifications?: Maybe<EntityClassificationObject>
}

export type SchemaTablesArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  intRangeFilter?: InputMaybe<TableIntRangeFilterInput>
  dateFilter?: InputMaybe<TableDateFilterInput>
  toggleFilter?: InputMaybe<TableToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<TableBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<TableFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<TableNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type SchemaColumnsArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ColumnNode>
  toggleFilter?: InputMaybe<ColumnToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ColumnBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ColumnFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type SchemaDatabaseArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  intRangeFilter?: InputMaybe<DatabaseIntRangeFilterInput>
  dateFilter?: InputMaybe<DatabaseDateFilterInput>
  toggleFilter?: InputMaybe<DatabaseToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatabaseFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatabaseNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type SchemaDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type SchemaConnection = {
  __typename?: 'SchemaConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<SchemaEdge>>
  count?: Maybe<Scalars['Int']>
}

export type SchemaEdge = {
  __typename?: 'SchemaEdge'
  node?: Maybe<Schema>
  cursor: Scalars['String']
}

export enum SchemaNode {
  Name = 'NAME',
  QualifiedName = 'QUALIFIED_NAME',
  CreatedAt = 'CREATED_AT',
  LastModifiedTime = 'LAST_MODIFIED_TIME',
  ModifiedBy = 'MODIFIED_BY'
}

export type SenderDetails = {
  __typename?: 'SenderDetails'
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export enum SensitivityLabel {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH'
}

export type Site = {
  __typename?: 'Site'
  id: Scalars['GrapheneObjectId']
  siteId?: Maybe<Scalars['String']>
  displayName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  parentReferenceId?: Maybe<Scalars['String']>
  webUrl?: Maybe<Scalars['String']>
  createdDateTime?: Maybe<Scalars['DateTime']>
}

export type SiteConnection = {
  __typename?: 'SiteConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<SiteEdge>>
  count?: Maybe<Scalars['Int']>
}

export type SiteEdge = {
  __typename?: 'SiteEdge'
  node?: Maybe<Site>
  cursor: Scalars['String']
}

export type SlackDatasourceConfig = {
  __typename?: 'SlackDatasourceConfig'
  botToken: Scalars['String']
  appToken: Scalars['String']
  workspaceUrl: Scalars['String']
  workspaceName: Scalars['String']
  workspaceId: Scalars['String']
  enterpriseId: Scalars['String']
  autoJoinChannels?: Maybe<Scalars['Boolean']>
  filterDate?: Maybe<Scalars['DateTime']>
  enableHistoricalSync?: Maybe<Scalars['Boolean']>
  enableLiveSync?: Maybe<Scalars['Boolean']>
  postNotifyMessage?: Maybe<Scalars['String']>
  postNotifyMessageChannel?: Maybe<Scalars['String']>
}

export type SnowflakeDatasourceConfig = {
  __typename?: 'SnowflakeDatasourceConfig'
  username: Scalars['String']
  password: Scalars['String']
  accountName: Scalars['String']
  warehouse?: Maybe<Scalars['String']>
}

export type SnowflakeDatasourceStatus = {
  __typename?: 'SnowflakeDatasourceStatus'
  status?: Maybe<Scalars['String']>
}

export type StringMaskingStrategy = {
  __typename?: 'StringMaskingStrategy'
  value: Scalars['String']
}

export type StringMaskingStrategyInput = {
  value: Scalars['String']
}

export type SubAlert = {
  __typename?: 'SubAlert'
  id: Scalars['GrapheneObjectId']
  groupId?: Maybe<Scalars['GrapheneObjectId']>
  groupName?: Maybe<Scalars['String']>
  generatedAt?: Maybe<Scalars['DateTime']>
  triggeredTime: Scalars['DateTime']
  status: AlertStatus
  severity: AlertSeverity
  acknowledged?: Maybe<Scalars['Boolean']>
  attributeName?: Maybe<Array<Maybe<Scalars['String']>>>
  attributeCount?: Maybe<Scalars['Int']>
  resolutionReason?: Maybe<Scalars['String']>
  action?: Maybe<AlertActionTypes>
  policy?: Maybe<PolicyConnection>
  datasource?: Maybe<DatasourceConnection>
  objects?: Maybe<ObjectConnection>
  tables?: Maybe<TableConnection>
  channels?: Maybe<ChannelConnection>
  userEntities?: Maybe<UserEntityConnection>
  alert?: Maybe<AlertConnection>
}

export type SubAlertPolicyArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['Boolean']>
  type?: InputMaybe<PolicyTypes>
  dateFilter?: InputMaybe<PolicyDateFilterInput>
  filter?: InputMaybe<Array<InputMaybe<PolicyFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<PolicyNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type SubAlertDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type SubAlertObjectsArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type SubAlertTablesArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  intRangeFilter?: InputMaybe<TableIntRangeFilterInput>
  dateFilter?: InputMaybe<TableDateFilterInput>
  toggleFilter?: InputMaybe<TableToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<TableBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<TableFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<TableNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type SubAlertChannelsArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type SubAlertUserEntitiesArgs = {
  isSensitive?: InputMaybe<Scalars['Boolean']>
  database?: InputMaybe<Scalars['String']>
  schema?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<EntityTypes>>>
  toggleFilter?: InputMaybe<EntityToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<EntityFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<EntityBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<UserEntityNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type SubAlertAlertArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  status?: InputMaybe<AlertStatus>
  severity?: InputMaybe<AlertSeverity>
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  dateFilter?: InputMaybe<AlertDateFilterInput>
  filter?: InputMaybe<Array<InputMaybe<AlertFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AlertNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type SubAlertActionPayload = {
  __typename?: 'SubAlertActionPayload'
  clientMutationId?: Maybe<Scalars['ID']>
  status?: Maybe<Scalars['Boolean']>
}

export enum SubAlertBaseFilterKey {
  Status = 'STATUS',
  Severity = 'SEVERITY',
  Action = 'ACTION',
  DatabaseId = 'DATABASE_ID',
  SchemaId = 'SCHEMA_ID',
  TableId = 'TABLE_ID'
}

export type SubAlertConnection = {
  __typename?: 'SubAlertConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<SubAlertEdge>>
  count?: Maybe<Scalars['Int']>
}

export type SubAlertDateFilterInput = {
  key: SubAlertDateFilterKey
  start?: InputMaybe<Scalars['BigInt']>
  end?: InputMaybe<Scalars['BigInt']>
}

export enum SubAlertDateFilterKey {
  GeneratedAt = 'GENERATED_AT',
  TriggeredTime = 'TRIGGERED_TIME'
}

export type SubAlertEdge = {
  __typename?: 'SubAlertEdge'
  node?: Maybe<SubAlert>
  cursor: Scalars['String']
}

export type SubAlertFilterInput = {
  key: SubAlertBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type SubAlertIntRangeFilterInput = {
  key: SubAlertIntRangeFilterKey
  min?: InputMaybe<Scalars['Int']>
  max?: InputMaybe<Scalars['Int']>
}

export enum SubAlertIntRangeFilterKey {
  AttributeCount = 'ATTRIBUTE_COUNT'
}

export enum SubAlertNode {
  CreatedAt = 'CREATED_AT',
  TriggeredTime = 'TRIGGERED_TIME',
  Status = 'STATUS',
  Severity = 'SEVERITY'
}

export type SummaryAndDescription = {
  __typename?: 'SummaryAndDescription'
  summary?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

export enum SupportedLanguage {
  En = 'EN',
  Es = 'ES'
}

export type Table = Node & {
  __typename?: 'Table'
  id: Scalars['ID']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  qualifiedName: Scalars['String']
  createdOn: Scalars['String']
  lastModifiedOn?: Maybe<Scalars['String']>
  isView: Scalars['Boolean']
  rowCount?: Maybe<Scalars['BigInt']>
  sampleData?: Maybe<SampleData>
  classifications?: Maybe<EntityClassificationObject>
  schema?: Maybe<SchemaConnection>
  database?: Maybe<DatabaseConnection>
  datasource?: Maybe<DatasourceConnection>
  columns?: Maybe<ColumnConnection>
  size?: Maybe<Scalars['BigInt']>
  createdBy?: Maybe<Scalars['String']>
  modifiedBy?: Maybe<Scalars['String']>
  attributes?: Maybe<AttributeConnection>
  entities?: Maybe<UserEntityConnection>
  isReviewed?: Maybe<Scalars['Boolean']>
  isReviewInDraft?: Maybe<Scalars['Boolean']>
  lastReviewedOn?: Maybe<Scalars['BigInt']>
  clusterRootTableUrn?: Maybe<Scalars['String']>
}

export type TableSchemaArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  databaseQualifiedName?: InputMaybe<Scalars['String']>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<SchemaNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TableDatabaseArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  intRangeFilter?: InputMaybe<DatabaseIntRangeFilterInput>
  dateFilter?: InputMaybe<DatabaseDateFilterInput>
  toggleFilter?: InputMaybe<DatabaseToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatabaseFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatabaseNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TableDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TableColumnsArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ColumnNode>
  toggleFilter?: InputMaybe<ColumnToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ColumnBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ColumnFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TableAttributesArgs = {
  id?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  systemDefined?: InputMaybe<Scalars['Boolean']>
  attributeSets?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  sensitivity?: InputMaybe<Array<InputMaybe<SensitivityLabel>>>
  hasInstances?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Array<InputMaybe<AttributeFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<AttributeBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AttributeNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TableEntitiesArgs = {
  isSensitive?: InputMaybe<Scalars['Boolean']>
  database?: InputMaybe<Scalars['String']>
  schema?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<EntityTypes>>>
  toggleFilter?: InputMaybe<EntityToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<EntityFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<EntityBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<UserEntityNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum TableBaseFilterKey {
  Name = 'NAME',
  CreatedBy = 'CREATED_BY',
  ModifiedBy = 'MODIFIED_BY',
  Schema = 'SCHEMA',
  Database = 'DATABASE',
  Datasource = 'DATASOURCE',
  Classification = 'CLASSIFICATION',
  IsSensitive = 'IS_SENSITIVE',
  IsRisky = 'IS_RISKY',
  ParentTableId = 'PARENT_TABLE_ID'
}

export type TableBooleanFilterInput = {
  key: TableToggleFilterKey
  value: Scalars['Boolean']
}

export type TableCluster = {
  tables?: Maybe<TableConnection>
  columns?: Maybe<ColumnConnection>
}

export type TableClusterTablesArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  intRangeFilter?: InputMaybe<TableIntRangeFilterInput>
  dateFilter?: InputMaybe<TableDateFilterInput>
  toggleFilter?: InputMaybe<TableToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<TableBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<TableFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<TableNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TableClusterColumnsArgs = {
  id?: InputMaybe<Scalars['ID']>
  classification?: InputMaybe<ClassificationType>
  query?: InputMaybe<Scalars['String']>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<ColumnNode>
  toggleFilter?: InputMaybe<ColumnToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ColumnBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ColumnFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TableClusterConnection = {
  __typename?: 'TableClusterConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<TableClusterEdge>>
  count?: Maybe<Scalars['Int']>
}

export type TableClusterEdge = {
  __typename?: 'TableClusterEdge'
  node?: Maybe<TableCluster>
  cursor: Scalars['String']
}

export type TableClusterToggleFilterInput = {
  key: TableClusterToggleFilterKey
}

export enum TableClusterToggleFilterKey {
  IsReviewed = 'IS_REVIEWED'
}

export type TableConnection = {
  __typename?: 'TableConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<TableEdge>>
  count?: Maybe<Scalars['Int']>
}

export type TableDateFilterInput = {
  key: TableDateFilterKey
  start?: InputMaybe<Scalars['BigInt']>
  end?: InputMaybe<Scalars['BigInt']>
}

export enum TableDateFilterKey {
  LastModifiedTime = 'LAST_MODIFIED_TIME',
  CreatedOn = 'CREATED_ON'
}

export type TableEdge = {
  __typename?: 'TableEdge'
  node?: Maybe<Table>
  cursor: Scalars['String']
}

export type TableFilterInput = {
  key: TableBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type TableIntRangeFilterInput = {
  key: TableIntRangeFilterKey
  min?: InputMaybe<Scalars['Int']>
  max?: InputMaybe<Scalars['Int']>
}

export enum TableIntRangeFilterKey {
  Size = 'SIZE',
  RowCount = 'ROW_COUNT'
}

export enum TableNode {
  Name = 'NAME',
  QualifiedName = 'QUALIFIED_NAME',
  CreatedAt = 'CREATED_AT',
  LastModifiedTime = 'LAST_MODIFIED_TIME',
  ModifiedBy = 'MODIFIED_BY'
}

export type TablePrimaryKeyOutput = {
  __typename?: 'TablePrimaryKeyOutput'
  success?: Maybe<Scalars['Boolean']>
  message?: Maybe<Scalars['String']>
}

export type TableToggleFilterInput = {
  key: TableToggleFilterKey
}

export enum TableToggleFilterKey {
  IsSensitive = 'IS_SENSITIVE',
  IsRisky = 'IS_RISKY',
  IsReviewed = 'IS_REVIEWED',
  IncludeDraftChanges = 'INCLUDE_DRAFT_CHANGES'
}

export type TablesConfiguration = {
  __typename?: 'TablesConfiguration'
  completed: Scalars['Int']
  running: Scalars['Int']
  failed: Scalars['Int']
  total: Scalars['Int']
}

export type Template = {
  __typename?: 'Template'
  id: Scalars['GrapheneObjectId']
  name: Scalars['String']
  systemDefined?: Maybe<Scalars['Boolean']>
  lastModified?: Maybe<Scalars['Date']>
  description?: Maybe<Scalars['String']>
  templateClass?: Maybe<Scalars['String']>
  subclass?: Maybe<Scalars['String']>
  objectCount?: Maybe<Scalars['Int']>
  datasource?: Maybe<DatasourceConnection>
  objects?: Maybe<ObjectConnection>
}

export type TemplateDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TemplateObjectsArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum TemplateBaseFilterKey {
  Name = 'NAME',
  Classification = 'CLASSIFICATION',
  SubClassification = 'SUB_CLASSIFICATION'
}

export type TemplateConnection = {
  __typename?: 'TemplateConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<TemplateEdge>>
  count?: Maybe<Scalars['Int']>
}

export type TemplateDateFilterInput = {
  key: TemplateDateFilterKey
  start?: InputMaybe<Scalars['BigInt']>
  end?: InputMaybe<Scalars['BigInt']>
}

export enum TemplateDateFilterKey {
  LastModifiedTime = 'LAST_MODIFIED_TIME'
}

export type TemplateEdge = {
  __typename?: 'TemplateEdge'
  node?: Maybe<Template>
  cursor: Scalars['String']
}

export type TemplateFilterInput = {
  key: TemplateBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type TemplateIntRangeFilterInput = {
  key: TemplateIntRangeFilterKey
  min?: InputMaybe<Scalars['Int']>
  max?: InputMaybe<Scalars['Int']>
}

export enum TemplateIntRangeFilterKey {
  ObjectsCount = 'OBJECTS_COUNT'
}

export enum TemplateNode {
  Name = 'NAME',
  LastModifiedTime = 'LAST_MODIFIED_TIME',
  Class = 'CLASS',
  SubClass = 'SUB_CLASS',
  ObjectCount = 'OBJECT_COUNT'
}

export type TemplateToggleFilterInput = {
  key: TemplateToggleFilterKey
}

export enum TemplateToggleFilterKey {
  Classification = 'CLASSIFICATION',
  SubClassification = 'SUB_CLASSIFICATION'
}

export type Ticket = {
  __typename?: 'Ticket'
  id: Scalars['GrapheneObjectId']
  ticketId?: Maybe<Scalars['String']>
  ticketName?: Maybe<Scalars['String']>
  summaryAndDescription?: Maybe<SummaryAndDescription>
  assignee?: Maybe<Assignee>
  reporter?: Maybe<Reporter>
  comments?: Maybe<Array<Maybe<Comment>>>
  containsPiiData?: Maybe<Scalars['Boolean']>
  hasAttachments?: Maybe<Scalars['Boolean']>
  attachments?: Maybe<Array<Maybe<TicketAttachmentDetails>>>
  lastModifiedTime?: Maybe<Scalars['DateTime']>
  ticketLink?: Maybe<Scalars['String']>
  datasource?: Maybe<DatasourceConnection>
  objects?: Maybe<ObjectConnection>
  attributes?: Maybe<AttributeConnection>
  userEntities?: Maybe<UserEntityConnection>
  alerts?: Maybe<SubAlertConnection>
  ticketStoreProject?: Maybe<TicketStoreProjectConnection>
}

export type TicketDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TicketObjectsArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TicketAttributesArgs = {
  id?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  systemDefined?: InputMaybe<Scalars['Boolean']>
  attributeSets?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  sensitivity?: InputMaybe<Array<InputMaybe<SensitivityLabel>>>
  hasInstances?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Array<InputMaybe<AttributeFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<AttributeBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AttributeNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TicketUserEntitiesArgs = {
  isSensitive?: InputMaybe<Scalars['Boolean']>
  database?: InputMaybe<Scalars['String']>
  schema?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<EntityTypes>>>
  toggleFilter?: InputMaybe<EntityToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<EntityFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<EntityBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<UserEntityNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TicketAlertsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  status?: InputMaybe<AlertStatus>
  userEntityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  severity?: InputMaybe<AlertSeverity>
  intRangeFilter?: InputMaybe<SubAlertIntRangeFilterInput>
  dateFilter?: InputMaybe<SubAlertDateFilterInput>
  externalUser?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Array<InputMaybe<SubAlertFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<SubAlertNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TicketTicketStoreProjectArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TicketAttachmentDetails = {
  __typename?: 'TicketAttachmentDetails'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export enum TicketBaseFilterKey {
  ReporterEmail = 'REPORTER_EMAIL',
  AssigneeEmail = 'ASSIGNEE_EMAIL',
  ProjectId = 'PROJECT_ID',
  UserEntityIds = 'USER_ENTITY_IDS',
  AttributeIds = 'ATTRIBUTE_IDS',
  AlertIds = 'ALERT_IDS'
}

export type TicketBooleanFilterInput = {
  key: TicketBooleanFilterKey
  value: Scalars['Boolean']
}

export enum TicketBooleanFilterKey {
  ContainsPiiData = 'CONTAINS_PII_DATA',
  HasAttachments = 'HAS_ATTACHMENTS',
  HasAlerts = 'HAS_ALERTS'
}

export type TicketConnection = {
  __typename?: 'TicketConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<TicketEdge>>
  count?: Maybe<Scalars['Int']>
}

export type TicketEdge = {
  __typename?: 'TicketEdge'
  node?: Maybe<Ticket>
  cursor: Scalars['String']
}

export type TicketFilterInput = {
  key: TicketBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export type TicketStoreDatasourceStatus = {
  __typename?: 'TicketStoreDatasourceStatus'
  processedCount?: Maybe<Scalars['Int']>
  pendingCount?: Maybe<Scalars['Int']>
  skippedCount?: Maybe<Scalars['Int']>
}

export type TicketStoreProject = {
  __typename?: 'TicketStoreProject'
  id: Scalars['GrapheneObjectId']
  projectId?: Maybe<Scalars['String']>
  projectName?: Maybe<Scalars['String']>
  lead?: Maybe<Lead>
  lastSyncTime?: Maybe<Scalars['DateTime']>
  datasource?: Maybe<DatasourceConnection>
  objects?: Maybe<ObjectConnection>
  attributes?: Maybe<AttributeConnection>
  userEntities?: Maybe<UserEntityConnection>
  alerts?: Maybe<SubAlertConnection>
  tickets?: Maybe<TicketConnection>
}

export type TicketStoreProjectDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TicketStoreProjectObjectsArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TicketStoreProjectAttributesArgs = {
  id?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  systemDefined?: InputMaybe<Scalars['Boolean']>
  attributeSets?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  sensitivity?: InputMaybe<Array<InputMaybe<SensitivityLabel>>>
  hasInstances?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Array<InputMaybe<AttributeFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<AttributeBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AttributeNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TicketStoreProjectUserEntitiesArgs = {
  isSensitive?: InputMaybe<Scalars['Boolean']>
  database?: InputMaybe<Scalars['String']>
  schema?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<EntityTypes>>>
  toggleFilter?: InputMaybe<EntityToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<EntityFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<EntityBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<UserEntityNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TicketStoreProjectAlertsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  status?: InputMaybe<AlertStatus>
  userEntityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  severity?: InputMaybe<AlertSeverity>
  intRangeFilter?: InputMaybe<SubAlertIntRangeFilterInput>
  dateFilter?: InputMaybe<SubAlertDateFilterInput>
  externalUser?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Array<InputMaybe<SubAlertFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<SubAlertNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TicketStoreProjectTicketsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  ticketId?: InputMaybe<Scalars['String']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  booleanFilter?: InputMaybe<Array<InputMaybe<TicketBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<TicketFilterInput>>>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TicketStoreProjectConnection = {
  __typename?: 'TicketStoreProjectConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<TicketStoreProjectEdge>>
  count?: Maybe<Scalars['Int']>
}

export type TicketStoreProjectEdge = {
  __typename?: 'TicketStoreProjectEdge'
  node?: Maybe<TicketStoreProject>
  cursor: Scalars['String']
}

export type TicketsGroupbyReporter = {
  __typename?: 'TicketsGroupbyReporter'
  reporter?: Maybe<Reporter>
  ticketsCount?: Maybe<Scalars['Int']>
}

export type TicketsGroupbyReporterBooleanFilterInput = {
  key: TicketsGroupbyReporterBooleanFilterKey
  value: Scalars['Boolean']
}

export enum TicketsGroupbyReporterBooleanFilterKey {
  ContainsPiiData = 'CONTAINS_PII_DATA'
}

export type TicketsGroupbyReporterConnection = {
  __typename?: 'TicketsGroupbyReporterConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<TicketsGroupbyReporterEdge>>
  count?: Maybe<Scalars['Int']>
}

export type TicketsGroupbyReporterEdge = {
  __typename?: 'TicketsGroupbyReporterEdge'
  node?: Maybe<TicketsGroupbyReporter>
  cursor: Scalars['String']
}

export enum Type {
  Personal = 'PERSONAL',
  Financial = 'FINANCIAL',
  National = 'NATIONAL',
  Medical = 'MEDICAL',
  Legal = 'LEGAL',
  Others = 'OTHERS'
}

export enum UnclassifiedCategory {
  MiscSensitive = 'MISC_SENSITIVE',
  NonSensitive = 'NON_SENSITIVE',
  Skipped = 'SKIPPED'
}

export type UpdateAttributeInput = {
  name?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  sensitivityLabel?: InputMaybe<SensitivityLabel>
  isDlp?: InputMaybe<Scalars['Boolean']>
  enableMasking?: InputMaybe<Scalars['Boolean']>
  maskingStrategyConfiguration?: InputMaybe<MaskingStrategyConfigInput>
  createdBy?: InputMaybe<Scalars['GrapheneObjectId']>
  context?: InputMaybe<Array<InputMaybe<ContextInput>>>
  supportedLanguage?: InputMaybe<SupportedLanguage>
  blacklist?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  patterns?: InputMaybe<Array<InputMaybe<PatternInput>>>
  attributeSets?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
}

export type UpdateAttributeSetInput = {
  name?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Type>
  description?: InputMaybe<Scalars['String']>
  createdBy?: InputMaybe<Scalars['GrapheneObjectId']>
  enabled?: InputMaybe<Scalars['Boolean']>
  attributes?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
}

export type UpdateFiltersInput = {
  type: FilterTypes
  configuration: FiltersTypesConditionsInput
  permitReasons?: InputMaybe<Scalars['String']>
}

export type UpdatePolicyInput = {
  owner: Scalars['String']
  policyFilters?: InputMaybe<Array<InputMaybe<PolicyFiltersInput>>>
  description?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['Boolean']>
  notifyAction?: InputMaybe<NotifyActionInput>
  notifyUsers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  severity?: InputMaybe<AlertSeverity>
  alertSettings?: InputMaybe<AlertSettings>
}

export type UpdateProfileMapPayload = {
  __typename?: 'UpdateProfileMapPayload'
  clientMutationId?: Maybe<Scalars['ID']>
  profileMap?: Maybe<ProfileMapConnection>
}

export type UpdateProfileMapPayloadProfileMapArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type UpdateProjectInput = {
  displayName?: InputMaybe<Scalars['String']>
  storeState?: InputMaybe<Scalars['Boolean']>
  default?: InputMaybe<Scalars['Boolean']>
  coOwners?: InputMaybe<Array<InputMaybe<OwnerInput>>>
  dataStore?: InputMaybe<DataStoreInput>
}

export type UserEntity = Node & {
  __typename?: 'UserEntity'
  id: Scalars['ID']
  name?: Maybe<Array<Maybe<Scalars['String']>>>
  address?: Maybe<Array<Maybe<Scalars['String']>>>
  state?: Maybe<Array<Maybe<Scalars['String']>>>
  country?: Maybe<Array<Maybe<Scalars['String']>>>
  dynamicPiiData?: Maybe<Scalars['JSONString']>
  type?: Maybe<EntityTypes>
  riskStatus?: Maybe<Scalars['Boolean']>
  residencies?: Maybe<ResidencyConnection>
  objects?: Maybe<ObjectConnection>
  attributesCount?: Maybe<Scalars['Int']>
  ticketsCount?: Maybe<Scalars['Int']>
  policyViolationCount?: Maybe<Scalars['Int']>
  alerts?: Maybe<AlertConnection>
  subAlerts?: Maybe<SubAlertConnection>
  datasources?: Maybe<DatasourceConnection>
  labels?: Maybe<LabelConnection>
  ticketStoreProjects?: Maybe<TicketStoreProjectConnection>
}

export type UserEntityResidenciesArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type UserEntityObjectsArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<ObjectTypes>>>
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  datasourceTypes?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<ObjectIntRangeFilterInput>
  dateFilter?: InputMaybe<ObjectDateFilterInput>
  toggleFilter?: InputMaybe<ObjectToggleFilterInput>
  booleanFilter?: InputMaybe<Array<InputMaybe<ObjectBooleanFilterInput>>>
  filter?: InputMaybe<Array<InputMaybe<ObjectFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<ObjectCategory>
  sortField?: InputMaybe<DocumentNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type UserEntityAlertsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  name?: InputMaybe<Scalars['String']>
  datasourceIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  status?: InputMaybe<AlertStatus>
  severity?: InputMaybe<AlertSeverity>
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  dateFilter?: InputMaybe<AlertDateFilterInput>
  filter?: InputMaybe<Array<InputMaybe<AlertFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<AlertNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type UserEntitySubAlertsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  status?: InputMaybe<AlertStatus>
  userEntityIds?: InputMaybe<Array<InputMaybe<Scalars['GrapheneObjectId']>>>
  severity?: InputMaybe<AlertSeverity>
  intRangeFilter?: InputMaybe<SubAlertIntRangeFilterInput>
  dateFilter?: InputMaybe<SubAlertDateFilterInput>
  externalUser?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Array<InputMaybe<SubAlertFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<SubAlertNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type UserEntityDatasourcesArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type UserEntityLabelsArgs = {
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type UserEntityTicketStoreProjectsArgs = {
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type UserEntityConnection = {
  __typename?: 'UserEntityConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<UserEntityEdge>>
  count?: Maybe<Scalars['Int']>
}

export type UserEntityEdge = {
  __typename?: 'UserEntityEdge'
  node?: Maybe<UserEntity>
  cursor: Scalars['String']
}

export enum UserEntityNode {
  Name = 'NAME',
  Address = 'ADDRESS',
  State = 'STATE',
  Country = 'COUNTRY',
  Type = 'TYPE'
}

export type Violation = {
  __typename?: 'Violation'
  id: Scalars['GrapheneObjectId']
  senderName?: Maybe<Scalars['String']>
  dateTime?: Maybe<Scalars['DateTime']>
  datasource?: Maybe<DatasourceConnection>
  userEntities?: Maybe<UserEntityConnection>
}

export type ViolationDatasourceArgs = {
  isRisky?: InputMaybe<Scalars['Boolean']>
  isSensitive?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<DatasourceType>>>
  intRangeFilter?: InputMaybe<DatasourceIntRangeFilterInput>
  dateFilter?: InputMaybe<DatasourceDateFilterInput>
  toggleFilter?: InputMaybe<DatasourceToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<DatasourceFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<DatasourceNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ViolationUserEntitiesArgs = {
  isSensitive?: InputMaybe<Scalars['Boolean']>
  database?: InputMaybe<Scalars['String']>
  schema?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['GrapheneObjectId']>
  datasourceId?: InputMaybe<Scalars['GrapheneObjectId']>
  types?: InputMaybe<Array<InputMaybe<EntityTypes>>>
  toggleFilter?: InputMaybe<EntityToggleFilterInput>
  filter?: InputMaybe<Array<InputMaybe<EntityFilterInput>>>
  booleanFilter?: InputMaybe<Array<InputMaybe<EntityBooleanFilterInput>>>
  sortByAsc?: InputMaybe<Scalars['Boolean']>
  sortField?: InputMaybe<UserEntityNode>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export enum ViolationBaseFilterKey {
  Datasource = 'DATASOURCE',
  UserEntity = 'USER_ENTITY'
}

export type ViolationConnection = {
  __typename?: 'ViolationConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<ViolationEdge>>
  count?: Maybe<Scalars['Int']>
}

export type ViolationDateFilterInput = {
  key: ViolationDateFilterKey
  start?: InputMaybe<Scalars['BigInt']>
  end?: InputMaybe<Scalars['BigInt']>
}

export enum ViolationDateFilterKey {
  CreationDateTime = 'CREATION_DATE_TIME'
}

export type ViolationEdge = {
  __typename?: 'ViolationEdge'
  node?: Maybe<Violation>
  cursor: Scalars['String']
}

export type ViolationFilterInput = {
  key: ViolationBaseFilterKey
  values: Array<InputMaybe<Scalars['String']>>
}

export enum ViolationNode {
  CreatedAt = 'CREATED_AT'
}
export type DsrRequestAssignee = {
  __typename?: 'DSRRequestAssignee'
  name?: Maybe<Scalars['String']>
  email: Scalars['String']
}
export enum DsrRequestStatus {
  IdUnverified = 'ID_UNVERIFIED',
  IdVerified = 'ID_VERIFIED',
  DataDiscovery = 'DATA_DISCOVERY',
  ReportGeneration = 'REPORT_GENERATION',
  Closed = 'CLOSED',
  Rejected = 'REJECTED'
}
export enum DsrRequestType {
  Access = 'ACCESS',
  Erasure = 'ERASURE',
  Rectification = 'RECTIFICATION',
  DoNotSell = 'DO_NOT_SELL',
  Other = 'OTHER'
}
export type DsrDataSubject = {
  __typename?: 'DSRDataSubject'
  attributeName?: Maybe<Scalars['String']>
  attributeValue: Scalars['String']
  formFieldId?: Maybe<Scalars['String']>
}
export enum DsrTicketStatus {
  TicketAssigned = 'TICKET_ASSIGNED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Rejected = 'REJECTED'
}
export type DsrRequestEdge = {
  __typename?: 'DSRRequestEdge'
  node?: Maybe<DsrRequest>
  cursor: Scalars['String']
}
export type DsrRequestConnection = {
  __typename?: 'DSRRequestConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<DsrRequestEdge>>
  count?: Maybe<Scalars['Int']>
}
export enum AttributeAccessActions {
  DataMatchFound = 'DATA_MATCH_FOUND',
  AttributeUpdated = 'ATTRIBUTE_UPDATED',
  AttributeValueNotFound = 'ATTRIBUTE_VALUE_NOT_FOUND',
  DataNoLongerExists = 'DATA_NO_LONGER_EXISTS'
}
export type DsrAttributeAccessActions = {
  __typename?: 'DSRAttributeAccessActions'
  attribute?: Maybe<AttributeConnection>
  attributeInstance?: Maybe<AttributeInstanceConnection>
  action?: Maybe<AttributeAccessActions>
  remark?: Maybe<Scalars['String']>
  dataMismatch?: Maybe<Scalars['Boolean']>
}
export enum AttributeErasureActions {
  Erased = 'ERASED',
  Hold = 'HOLD',
  Drop = 'DROP',
  NoAction = 'NO_ACTION'
}
export type DsrAttributeErasureActions = {
  __typename?: 'DSRAttributeErasureActions'
  attributeId?: Maybe<Scalars['String']>
  attributeInstanceId?: Maybe<Scalars['String']>
  action?: Maybe<AttributeErasureActions>
  remark?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
}
export enum AttributeRectificationActions {
  Rectified = 'RECTIFIED',
  Hold = 'HOLD',
  NotFound = 'NOT_FOUND'
}
export type DsrAttributeRectificationActions = {
  __typename?: 'DSRAttributeRectificationActions'
  attributeId?: Maybe<Scalars['String']>
  attributeInstanceId?: Maybe<Scalars['String']>
  action?: Maybe<AttributeRectificationActions>
  remark?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
}
export type AttributeAction =
  | DsrAttributeAccessActions
  | DsrAttributeErasureActions
  | DsrAttributeRectificationActions

export type DsrAttributesInput = {
  __typename?: 'DSRAttributesInput'
  attribute?: Maybe<AttributeConnection>
  value: Scalars['String']
}
export type DsrTicket = {
  __typename?: 'DSRTicket'
  id: Scalars['GrapheneObjectId']
  name?: Maybe<Scalars['String']>
  dsrRequest: DsrRequestConnection
  datasource: DatasourceConnection
  dueDate: Scalars['DateTime']
  status?: Maybe<DsrTicketStatus>
  isDraft?: Maybe<Scalars['Boolean']>
  createdAt?: Maybe<Scalars['DateTime']>
  completionDate?: Maybe<Scalars['DateTime']>
  actions?: Maybe<Array<Maybe<AttributeAction>>>
  additionalAttributes?: Maybe<Array<Maybe<DsrAttributesInput>>>
  purpose?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
}
export type DsrTicketEdge = {
  __typename?: 'DSRTicketEdge'
  node?: Maybe<DsrTicket>
  cursor: Scalars['String']
}
export type DsrTicketConnection = {
  __typename?: 'DSRTicketConnection'
  pageInfo: PageInfo
  edges: Array<Maybe<DsrTicketEdge>>
  count?: Maybe<Scalars['Int']>
}
export type DataSubject = {
  __typename?: 'DataSubject'
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}
export type DsrRequest = {
  __typename?: 'DSRRequest'
  id?: Maybe<Scalars['GrapheneObjectId']>
  name?: Maybe<Scalars['String']>
  dataSubjectMandatoryDetails?: Maybe<DataSubject>
  formId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  approvedBy?: Maybe<Scalars['String']>
  approvedAt?: Maybe<Scalars['DateTime']>
  updateTimestamp?: Maybe<Scalars['DateTime']>
  dueDate: Scalars['DateTime']
  status?: Maybe<DsrRequestStatus>
  requestType: DsrRequestType
  emailVerificationSentDate?: Maybe<Scalars['DateTime']>
  emailVerificationCompletionDate?: Maybe<Scalars['DateTime']>
  detectedEntity?: Maybe<UserEntityConnection>
  tickets?: Maybe<DsrTicketConnection>
  assignee?: Maybe<Array<Maybe<DsrRequestAssignee>>>
  attachments?: Maybe<Array<Maybe<{ fileName: string; fileContent: string; id: string }>>>
  dataSubjectDetails?: Maybe<Array<Maybe<DsrDataSubject>>>
  submittedValues?: Maybe<Scalars['String']>
}
