import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Header, Label } from 'semantic-ui-react'

interface IProps {
  labels: string[]
  showHeader?: boolean
}

const SearchLabelList = (props: IProps): React.ReactElement => {
  const { labels, showHeader = true } = props

  return (
    <div className="labels-container" data-test-id="search-label-list">
      {showHeader && (
        <Header className="labels-container-header" as="h2">
          <FormattedMessage id="search.results.labels.header" />
        </Header>
      )}
      <div className="labels-container-list">
        {labels.map((l, i) => (
          <Label className="label-item" key={i}>
            {l}
          </Label>
        ))}
      </div>
    </div>
  )
}

export default SearchLabelList
