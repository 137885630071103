import PrintPageHeader from '../components/header'
import { RootState } from '../../rootReducer'
import {
  DataSourcesOverview,
  fetchPrintDataSourcesSummary,
  PrintDataSourceSummary,
  PrintDataSourceSummaryParams
} from '../printSlice'
import PrintDsWidgetsContainer from '../dataSourceWidgets/printDsWidgetsContainer'
import { DATA_SOURCE_TYPES } from '../../constants'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'

interface IProps {
  overview?: DataSourcesOverview
  dataSources?: PrintDataSourceSummary[]
  fetchPrintDataSourcesSummary: (params: PrintDataSourceSummaryParams[]) => void
}
export const getConfiguredDS = (dsList) => {
  return dsList.filter((ds) => {
    if (ds.type === DATA_SOURCE_TYPES.generic && ds.piiFilesCount) {
      return true
    } else if (ds.type !== DATA_SOURCE_TYPES.generic) {
      return true
    }
    return false
  })
}
const PrintMonitoredDataSources = (props: IProps): React.ReactElement => {
  const intl = useIntl()

  const { overview, dataSources = [], fetchPrintDataSourcesSummary } = props
  useEffect(() => {
    overview?.dataSourcesInfo.length &&
      fetchPrintDataSourcesSummary &&
      fetchPrintDataSourcesSummary(overview.dataSourcesInfo)
  }, [overview?.dataSourcesInfo?.length, fetchPrintDataSourcesSummary])

  const configuredDs = getConfiguredDS(dataSources)
  const riskyDs = configuredDs.filter((ds) => !!ds.policiesViolatedCount).length
  const totalDs = configuredDs.length

  return (
    <div
      id="data-sources"
      className="print-page data-sources"
      data-test-id="print-monitored-datasources-page"
    >
      <PrintPageHeader text={intl.formatMessage({ id: 'print.page.title.dataSources' })} />
      <div className="ds-content">
        <div className="risky-container">
          <div className="count risky">{riskyDs}</div>
          &nbsp;
          {intl.formatMessage({ id: 'print.ds.outOf' })}
          &nbsp;
          <div className="count">{totalDs}</div>
          &nbsp;
          {intl.formatMessage({ id: 'print.ds.atRisk' })}
        </div>
        {configuredDs.map((ds) => (
          <PrintDsWidgetsContainer key={ds.id} dataSource={ds} />
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  overview: state.print.overview,
  dataSources: state.print.dataSources
})

const mapDispatchToProps = {
  fetchPrintDataSourcesSummary
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintMonitoredDataSources)
