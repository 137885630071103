import { API_ERROR_STATUS } from './apiStatuses'

export enum ApiStages {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED'
}

export interface IResponseStatus {
  stage: ApiStages
  error: API_ERROR_STATUS | string
}

export const responseStatusInitialState = {
  stage: ApiStages.IDLE,
  error: ''
}
