import './search.less'
import LabelList from './result/labelList'
import ResultList from './result/resultList'
import useDebounce from '../../hooks/useDebounce'
import { GlobalSearchResults } from '../../services/api/apiTypes'
import useOutsideClick from '../../hooks/useOutsideClick'
import { SEARCH_QUERY, URL_ENTITIES } from '../../constants'
import SvgSearchButton from '../../assets/inlineSvg/search-button.svg'
import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Ref } from 'semantic-ui-react'
import { useHistory, useLocation } from 'react-router-dom'
import { SearchField } from '@lightbeamai/design-system'

interface IProps {
  placeholder?: string
  isLoading?: boolean
  className?: string
  onChange?: (value: string) => void
  isShowResults?: boolean
  results?: GlobalSearchResults
  type?: 'expanded' | 'collapsible'
}

const Search = (props: IProps): React.ReactElement => {
  const intl = useIntl()
  const history = useHistory()
  const ref = useRef()
  const { pathname } = useLocation()
  const [value, setValue] = useState('')
  const [isResultsOpened, setIsResultsOpened] = useState(false)
  const [isSearchVisible, setIsSearchVisible] = useState(props.type != 'collapsible')

  const {
    className = '',
    placeholder = intl.formatMessage({ id: 'placeholder.search' }),
    isLoading = false,
    onChange,
    isShowResults = true,
    results
  } = props

  const debouncedSearch = useDebounce(value, 300)

  const labels = results?.labels || null
  const records = results?.results || []

  useEffect(() => {
    if (debouncedSearch && debouncedSearch.length >= 2 && onChange) {
      setIsResultsOpened(true)
      onChange(debouncedSearch)
    }
  }, [debouncedSearch, onChange])

  useEffect(() => {
    setIsResultsOpened(false)
  }, [pathname])

  useOutsideClick(ref, () => {
    setIsResultsOpened(false)
  })

  const handleSearchChange = (value: string) => {
    setValue(value)
    if (!value.length) {
      setIsResultsOpened(false)
    }
  }

  const handleFocus = () => {
    if (records.length > 0 && value.length >= 2) {
      setIsResultsOpened(true)
    }
  }

  const handleBlur = () => {
    // If the search field is collapsible and the results are not opened, hide the search field
    if (props.type == 'collapsible' && !isResultsOpened) {
      setIsSearchVisible(false)
      setIsResultsOpened(false)
    }
  }

  const handleViewAllClick = () => {
    setIsResultsOpened(false)
    history.push({ pathname: URL_ENTITIES, search: `${SEARCH_QUERY}=${debouncedSearch}` })
  }

  return (
    <Ref innerRef={ref}>
      <div className={`search-container ${className}`} data-test-id="search">
        {/* Search Field */}
        {isSearchVisible && (
          <div className="search-box">
            <SearchField
              onChange={(e) => handleSearchChange(e.target.value)}
              onFocus={handleFocus}
              size={24}
              placeholder={placeholder}
              autoFocus
              loading={isLoading}
              onBlur={handleBlur}
              defaultValue={value}
            />
          </div>
        )}

        {/* Search Icon to toggle Search Field View */}
        {props.type == 'collapsible' && !isSearchVisible && (
          <SvgSearchButton onClick={() => setIsSearchVisible(true)} />
        )}

        {/* Results View */}
        {isResultsOpened && isShowResults && (
          <div className="results-container">
            {labels && <LabelList labels={labels} />}
            <ResultList results={records} search={debouncedSearch} />
            <Button basic className="button-view-all" onClick={handleViewAllClick}>
              <FormattedMessage id="search.results.button.view-all" />
            </Button>
          </div>
        )}
      </div>
    </Ref>
  )
}

export default Search
