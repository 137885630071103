import { mapQueryScanStatusWidget, queryScanStatusWidget } from './queries'
import graphqlService from '../../services/graphqlService'
import { DATA_SOURCE_ID, DATA_SOURCE_TYPE, DATA_SOURCE_TYPES } from '../../constants'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface ScanStatusWidget {
  status: string
  scannedCount: number
  pendingCount: number
}

export interface ScanStatusState {
  widget?: ScanStatusWidget
}

export const initialState: ScanStatusState = {
  widget: {
    status: '',
    scannedCount: 0,
    pendingCount: 0
  }
}

export interface ScanStatusParams {
  [DATA_SOURCE_ID]: string
  [DATA_SOURCE_TYPE]: string
}

export const ACTION_SCAN_STATUS_WIDGET = 'scanStatus/widget'
export const fetchScanStatusWidget = createAsyncThunk(
  ACTION_SCAN_STATUS_WIDGET,
  async (params: ScanStatusParams) => {
    const key =
      params[DATA_SOURCE_TYPE] == DATA_SOURCE_TYPES.dynamoDb ||
      params[DATA_SOURCE_TYPE] == DATA_SOURCE_TYPES.mongoDb ||
      params[DATA_SOURCE_TYPE] == DATA_SOURCE_TYPES.cosmosMongoDb ||
      params[DATA_SOURCE_TYPE] == DATA_SOURCE_TYPES.cosmosNoSql
        ? 'tables'
        : ''
    const resultRaw = await graphqlService.execute(queryScanStatusWidget(params, key))
    return mapQueryScanStatusWidget(resultRaw, key)
  }
)

const scanStatusSlice = createSlice({
  name: 'scanStatus',
  initialState,
  reducers: {
    resetWidget: (state) => {
      state.widget = initialState.widget
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScanStatusWidget.fulfilled, (state, action) => {
      state.widget = action.payload
    })
  }
})

export const { resetWidget } = scanStatusSlice.actions

export default scanStatusSlice.reducer
