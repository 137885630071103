import { DATA_SOURCE_TYPES, DATASOURCE_STATUS, SEVERITY_LEVEL } from '../constants'

// const testListDatasources = [{ id: '1111111', type: DATA_SOURCE_TYPES.slack, name: 'lb-slack' }]

// const testListDatasources1 = [
//   { id: '22222222', type: DATA_SOURCE_TYPES.gmail, name: 'lb-gmail' },
//   ...testListDatasources
// ]

// const testListDatasources2 = [
//   { id: '3333333', type: DATA_SOURCE_TYPES.hubspot, name: 'hitano-hubspot' },
//   ...testListDatasources1
// ]

export const definitionInfo = {
  name: 'Manage Customer Service',
  attributesCount: 50,
  attributesFoundCount: 0,
  classificationsCount: 12,
  classificationsFoundCount: 0,
  entityTypesCount: 70,
  entityTypesFoundCount: 0,
  datasourcesCount: 4,
  datasourcesFoundCount: 0,
  processOwnerName: 'Aditya Singh',
  processOwnerEmail: 'aditya.singh@gmail.com',
  reportStatus: 'In Progress',
  dueDate: '16 Feb 2022 12:00 PM',
  dataSources: [
    {
      name: 'aws-s3-workspace',
      id: 'aws-s3-workspace',
      status: 'Connected',
      type: DATA_SOURCE_TYPES.aws,
      owner: 'john.smith@gmail.com',
      dueDate: '10 Feb 2020 09:12',
      requestReceivedAt: '10 Feb 2020 09:12',
      requestSentAt: '10 Feb 2020 09:12',
      isSrc: true,
      isSync: true,
      taskId: 'aws_12_12_2020_1',
      notifications: [
        {
          text:
            '‘aws-s3-workspace’ is missing the Sync. is missing the Sync. is missing the Sync. is missing the Sync.',
          severity: SEVERITY_LEVEL.warning
        }
      ]
    },
    {
      name: 'salesforce-workspace',
      id: 'salesforce-workspace',
      status: 'Connected',
      type: DATA_SOURCE_TYPES.salesForce,
      owner: 'sara.jones@gmail.com',
      dueDate: '10 Feb 2020 09:12',
      isSrc: true,
      resposeStatus: true.valueOf,
      notifications: [
        {
          text:
            '‘sap-workspace’ is missing the Sync. is missing the Sync. is missing the Sync. is missing the Sync. ',
          severity: SEVERITY_LEVEL.warning
        }
      ]
    },
    {
      name: 'hubspot-workspace',
      id: 'hubspot-workspace',
      status: 'Connected',
      type: DATA_SOURCE_TYPES.hubspot,
      owner: 'john.smith@gmail.com',
      dueDate: '10 Feb 2020 09:12',
      isSync: true,
      taskId: 'aws_12_12_2020_3'
    }
  ]
}

export const requestInfo = {
  id: 'dsr_req_22oct20211201',
  definitionName: 'Manage Customer Service',
  attributesCount: 50,
  attributesFoundCount: 0,
  classificationsCount: 12,
  classificationsFoundCount: 0,
  entityTypesCount: 70,
  entityTypesFoundCount: 0,
  datasourcesCount: 4,
  datasourcesFoundCount: 0,
  processOwnerName: 'Aditya Singh',
  processOwnerEmail: 'aditya.singh@gmail.com',
  reportStatus: 'In Progress',
  requestReceivedDate: '16 Feb 2022 12:00 PM',
  requestDueDate: '19 Feb 2022 06:00 PM',

  attributes: [
    { id: '62c80b6f6d9f6ba9ab63ee86', name: 'Email Address', isSystemIdentified: false },
    { id: '62c80b6f6d9f6ba9ab63ee3e', name: 'State', isSystemIdentified: true },
    {
      id: '62c80b706d9f6ba9ab63ef0a',
      name: 'USA Social Security Number (SSN)',
      isSystemIdentified: false
    },
    { id: '62c80b6f6d9f6ba9ab63ee34', name: 'AWS Access Key', isSystemIdentified: true },
    { id: '62c80b706d9f6ba9ab63eef1', name: 'First Name', isSystemIdentified: false },
    { id: '62c80b706d9f6ba9ab63eef2', name: 'Last Name', isSystemIdentified: false }
  ],
  // droppedAttributes: [
  // { id: '62c80b706d9f6ba9ab63eef1', name: 'First Name', isSystemIdentified: false },
  // { id: '62c80b706d9f6ba9ab63eef2', name: 'Last Name', isSystemIdentified: false }
  // ],
  // addedAttributes: [
  //   { id: '62c80b706d9f6ba9ab63ef0c', name: 'Postal/Zip Code', isSystemIdentified: false }
  // ],
  classifications: [
    { id: 'Identity', name: 'Identity', isSystemIdentified: false },
    { id: 'Financial', name: 'Financial', isSystemIdentified: true },
    { id: 'Human Resource', name: 'Human Resource', isSystemIdentified: false },
    { id: 'Unclassified', name: 'Unclassified', isSystemIdentified: false }
  ],
  // droppedClassifications: [
  //   { id: 'Human Resource', name: 'Human Resource', isSystemIdentified: false }
  // ],
  // addedClassifications: [{ id: 'Unclassified', name: 'Unclassified', isSystemIdentified: false }],
  entityTypes: [
    { id: 'Identity', name: 'Identity', isSystemIdentified: false },
    { id: 'Financial', name: 'Financial', isSystemIdentified: true },
    { id: 'Human Resource', name: 'Human Resource', isSystemIdentified: false }
  ],
  // droppedEntityTypes: [{ id: 'Human Resource', name: 'Human Resource', isSystemIdentified: false }]

  dataSource: {
    name: 'aws-s3-workspace',
    id: 'aws-s3-workspace',
    status: 'Connected',
    type: DATA_SOURCE_TYPES.aws,
    owner: 'john.smith@gmail.com',
    dueDate: '10 Feb 2020 09:12'
  }
}

export const ropaRequestsInProgress = [
  {
    id: 'dsr_req_22oct20211201',
    name: 'dsr_req_22oct20211201',
    category: 'Manage Customer Service',
    showAlert: true,
    dpo: 'Aditya Singh',
    receivedAt: '16 Feb 2020 09:12',
    dueDate: '16 Feb 2020 09:12',
    status: 'Draft'
  },
  {
    id: 'dsr_req_22oct20211203',
    name: 'dsr_req_22oct20211203',
    category: 'Develop Vision and Strategy',
    showAlert: false,
    dpo: 'Aditya Singh',
    receivedAt: '16 Feb 2020 09:12',
    dueDate: '16 Feb 2020 09:12',
    status: 'Open'
  }
]

export const ropaRequestsClosed = [
  {
    id: 'dsr_req_22oct20211200',
    name: 'dsr_req_22oct20211200',
    category: 'Manage Customer Service',
    dpo: 'Aditya Singh',
    receivedAt: '16 Feb 2020 09:12',
    dueDate: '16 Feb 2020 09:12',
    status: 'Closed'
  }
]

export const mockedGalleryList = [
  {
    id: '1',
    name: 'Develop vision and strategy',
    description: 'The company processes data in order to provide',
    owner: 'System',
    lawfulBasis: ['Consent'],
    purpose: [
      'Enable customers to call and raise requests or make inquires, so that their issues can be resolved.'
    ],
    departments: ['Operations', 'Human resources'],
    questionnaire: {
      attributes: {
        topicId: '1a',
        description: null,
        questions: [],
        optional: false,
        attributeIds: [],
        allowModification: false
      },
      classificationObjects: {
        topicId: '1b',
        description: null,
        questions: [],
        optional: true,
        classificationIds: [],
        allowModification: true,
        _isComplete: true
      },
      entityTypes: {
        topicId: '1c',
        description: null,
        questions: [],
        optional: false,
        entityIds: [],
        allowModification: true
      },
      purpose: {
        topicId: '1d',
        description: '',
        questions: [],
        optional: false,
        label: '',
        type: '',
        dropdownField: 'type'
      },
      retentionPeriod: {
        topicId: '1e',
        description: '',
        questions: [],
        optional: false,
        label: '',
        retentionPeriod: '',
        dropdownField: 'retentionPeriod'
      },
      dataTransferDetails: {
        topicId: '1h',
        description: null,
        questions: [],
        optional: true,
        transferDetails: [
          {
            name: '',
            address: '',
            categoryOfRecipients: '',
            safeguard: '',
            transferRetentionPeriod: '',
            description: '',

            nameLabel: '',
            addressLabel: '',
            categoryOfRecipientsLabel: '',
            safeguardLabel: '',
            transferRetentionPeriodLabel: '',
            questions: []
          }
        ],
        _isComplete: true
      },
      safetyMeasures: {
        topicId: '1i',
        description: '',
        questions: [],
        optional: false,
        label: '',
        name: '',
        dropdownField: 'name'
      },
      topics: []
    }
  },
  {
    id: '2',
    name: 'Develop and manage product',
    lawfulBasis: ['Consent'],
    purpose: [
      'Enable customers to call and raise requests or make inquires, so that their issues can be resolved.'
    ],
    departments: ['Management'],
    description: 'The company processes data in order to provide',
    owner: 'System'
  },
  {
    id: '3',
    name: 'Market and sell products',
    lawfulBasis: ['Consent'],
    purpose: [
      'Enable customers to call and raise requests or make inquires, so that their issues can be resolved.'
    ],
    departments: ['Operational', 'Management', 'Support'],
    description: 'The company processes data in order to provide',
    owner: 'System'
  },
  {
    id: '4',
    name: 'Deliver physical products',
    lawfulBasis: ['Consent'],
    purpose: [
      'Enable customers to call and raise requests or make inquires, so that their issues can be resolved.'
    ],
    departments: ['Management'],
    description: 'The company processes data in order to provide',
    owner: 'System'
  }
]

export const mockedProcessesList = [
  {
    id: 'customActivity1',
    name: 'Market and sell products',
    lawfulBasis: ['Consent'],
    purpose: [
      'Enable customers to call and raise requests or make inquires, so that their issues can be resolved.'
    ],
    departments: ['Operational', 'Management', 'Support'],
    description: 'The company processes data in order to provide',
    owner: 'System',
    lastModifiedTime: new Date().toString(),
    datasources: [
      {
        id: '62e8b6c5af2aafdc869a82d8',
        status: DATASOURCE_STATUS.scanning,
        createdBy: 'kasim@lightbeam.ai',
        dataSourceType: DATA_SOURCE_TYPES.generic,
        dataSourceName: 'Lightbeam Generic Datasource',
        alertsCount: 1,
        configuration: {
          datasourceType: DATA_SOURCE_TYPES.generic,
          name: 'Lightbeam Generic Datasource'
        },
        progress: 0
      },
      {
        id: '62ebe2315918d29b25cffb92',
        status: DATASOURCE_STATUS.scanning,
        createdBy: 'kasim@lightbeam.ai',
        dataSourceType: DATA_SOURCE_TYPES.outLook,
        dataSourceName: 'Test-outlook',
        alertsCount: 1,
        configuration: {
          datasourceType: DATA_SOURCE_TYPES.outLook,
          name: 'Test-outlook'
        },
        progress: 0
      }
    ]
  }
]

export const mockedGroupsList = [
  {
    id: '1',
    name: 'Operational',
    departments: ['Operational', 'Management', 'Support'],
    description: 'The company processes data in order to provide',
    lawfulBasis: ['Consent'],
    purpose: [
      'Enable customers to call and raise requests or make inquires, so that their issues can be resolved.'
    ],
    owner: 'System',
    lastModifiedTime: new Date().toString(),
    datasources: [
      {
        id: '62bc396dfe33f3f0093522cf',
        status: DATASOURCE_STATUS.scanning,
        createdBy: 'kasim@lightbeam.ai',
        dataSourceType: DATA_SOURCE_TYPES.outLook,
        dataSourceName: 'Tonya Brown',
        alertsCount: 1,
        configuration: {
          datasourceType: 'OUTLOOK',
          name: 'Tonya Brown'
        },
        progress: 0
      },
      {
        id: '62bc3b2e95621979be544d39',
        status: DATASOURCE_STATUS.scanning,
        createdBy: 'kasim@lightbeam.ai',
        dataSourceType: DATA_SOURCE_TYPES.outLook,
        dataSourceName: 'Peter Singh',
        alertsCount: 1,
        configuration: {
          datasourceType: 'OUTLOOK',
          name: 'Peter Singh'
        },
        progress: 0
      },
      {
        id: '62bc60bf8e04ddf46186df82',
        status: DATASOURCE_STATUS.scanning,
        createdBy: 'hussnain@lightbeam.ai',
        dataSourceType: DATA_SOURCE_TYPES.outLook,
        dataSourceName: 'lb-outlook',
        alertsCount: 2,
        configuration: {
          datasourceType: 'OUTLOOK',
          name: 'lb-outlook'
        },
        progress: 0
      }
    ],
    processes: mockedProcessesList
  },
  {
    id: '2',
    name: 'Management and support',
    departments: ['Management', 'Support'],
    description: 'The company processes data in order to provide',
    owner: 'System',
    lawfulBasis: ['Consent'],
    purpose: [
      'Enable customers to call and raise requests or make inquires, so that their issues can be resolved.'
    ],
    lastModifiedTime: new Date().toString(),
    datasources: [
      {
        id: '62bc396dfe33f3f0093522cf',
        status: DATASOURCE_STATUS.scanning,
        createdBy: 'kasim@lightbeam.ai',
        dataSourceType: DATA_SOURCE_TYPES.outLook,
        dataSourceName: 'Tonya Brown',
        alertsCount: 1,
        configuration: {
          datasourceType: 'OUTLOOK',
          name: 'Tonya Brown'
        },
        progress: 0
      }
    ],
    processes: mockedProcessesList
  }
]

export const mockedCompaniesList = [
  {
    id: '1',
    name: 'XYZ process company',
    departments: ['Operational', 'Management', 'Support'],
    description: '',
    lawfulBasis: ['Consent'],
    owner: 'user@gmail.com',
    lastModifiedTime: new Date().toString(),
    datasources: [
      {
        id: '62bc396dfe33f3f0093522cf',
        status: DATASOURCE_STATUS.scanning,
        createdBy: 'kasim@lightbeam.ai',
        dataSourceType: DATA_SOURCE_TYPES.outLook,
        dataSourceName: 'Tonya Brown',
        alertsCount: 1,
        configuration: {
          datasourceType: 'OUTLOOK',
          name: 'Tonya Brown'
        },
        progress: 0
      },
      {
        id: '62bc60bf8e04ddf46186df82',
        status: DATASOURCE_STATUS.scanning,
        createdBy: 'hussnain@lightbeam.ai',
        dataSourceType: DATA_SOURCE_TYPES.outLook,
        dataSourceName: 'lb-outlook',
        alertsCount: 2,
        configuration: {
          datasourceType: 'OUTLOOK',
          name: 'lb-outlook'
        },
        progress: 0
      }
    ],
    processes: mockedProcessesList
  }
]

export const mockedRopaReportsInProgress = [
  {
    id: '1',
    name: 'dsr_req_1',
    dpo: 'user@gmail.com',
    createdOn: new Date().toString(),
    dueDate: new Date().toString(),
    status: 'open',
    process: mockedProcessesList[0],
    datasources: [
      {
        id: '62bc396dfe33f3f0093522cf',
        status: DATASOURCE_STATUS.scanning,
        createdBy: 'kasim@lightbeam.ai',
        dataSourceType: DATA_SOURCE_TYPES.outLook,
        dataSourceName: 'Tonya Brown',
        alertsCount: 1,
        configuration: {
          datasourceType: 'OUTLOOK',
          name: 'Tonya Brown'
        },
        progress: 0
      },
      {
        id: '62bc3b2e95621979be544d39',
        status: DATASOURCE_STATUS.scanning,
        createdBy: 'kasim@lightbeam.ai',
        dataSourceType: DATA_SOURCE_TYPES.outLook,
        dataSourceName: 'Peter Singh',
        alertsCount: 1,
        configuration: {
          datasourceType: 'OUTLOOK',
          name: 'Peter Singh'
        },
        progress: 0
      }
    ],
    respondedDatasources: ['62bc396dfe33f3f0093522cf', '62bc3b2e95621979be544d39']
  },
  {
    id: '2',
    name: 'dsr_req_2',
    dpo: 'user@gmail.com',
    createdOn: new Date().toString(),
    dueDate: new Date().toString(),
    status: 'open',
    process: mockedProcessesList[0],
    datasources: [
      {
        id: '62bc396dfe33f3f0093522cf',
        status: DATASOURCE_STATUS.scanning,
        createdBy: 'kasim@lightbeam.ai',
        dataSourceType: DATA_SOURCE_TYPES.outLook,
        dataSourceName: 'Tonya Brown',
        alertsCount: 1,
        configuration: {
          datasourceType: 'OUTLOOK',
          name: 'Tonya Brown'
        },
        progress: 0
      },
      {
        id: '62bc3b2e95621979be544d39',
        status: DATASOURCE_STATUS.scanning,
        createdBy: 'kasim@lightbeam.ai',
        dataSourceType: DATA_SOURCE_TYPES.outLook,
        dataSourceName: 'Peter Singh',
        alertsCount: 1,
        configuration: {
          datasourceType: 'OUTLOOK',
          name: 'Peter Singh'
        },
        progress: 0
      }
    ],
    respondedDatasources: ['62bc396dfe33f3f0093522cf', '62bc3b2e95621979be544d39']
  }
]

export const mockedRopaReportsClosed = [
  {
    id: '10',
    name: 'dsr_req_10',
    dpo: 'user@gmail.com',
    createdOn: new Date().toString(),
    dueDate: new Date().toString(),
    status: 'completed',
    process: mockedProcessesList[0],
    datasources: [
      {
        id: '62bc396dfe33f3f0093522cf',
        status: DATASOURCE_STATUS.scanning,
        createdBy: 'kasim@lightbeam.ai',
        dataSourceType: DATA_SOURCE_TYPES.outLook,
        dataSourceName: 'Tonya Brown',
        alertsCount: 1,
        configuration: {
          datasourceType: 'OUTLOOK',
          name: 'Tonya Brown'
        },
        progress: 0
      },
      {
        id: '62bc3b2e95621979be544d39',
        status: DATASOURCE_STATUS.scanning,
        createdBy: 'kasim@lightbeam.ai',
        dataSourceType: DATA_SOURCE_TYPES.outLook,
        dataSourceName: 'Peter Singh',
        alertsCount: 1,
        configuration: {
          datasourceType: 'OUTLOOK',
          name: 'Peter Singh'
        },
        progress: 0
      }
    ],
    respondedDatasources: ['62bc396dfe33f3f0093522cf', '62bc3b2e95621979be544d39']
  },
  {
    id: '11',
    name: 'dsr_req_11',
    dpo: 'user@gmail.com',
    createdOn: new Date().toString(),
    dueDate: new Date().toString(),
    status: 'completed',
    process: mockedProcessesList[0],
    datasources: [
      {
        id: '62bc396dfe33f3f0093522cf',
        status: DATASOURCE_STATUS.scanning,
        createdBy: 'kasim@lightbeam.ai',
        dataSourceType: DATA_SOURCE_TYPES.outLook,
        dataSourceName: 'Tonya Brown',
        alertsCount: 1,
        configuration: {
          datasourceType: 'OUTLOOK',
          name: 'Tonya Brown'
        },
        progress: 0
      },
      {
        id: '62bc3b2e95621979be544d39',
        status: DATASOURCE_STATUS.scanning,
        createdBy: 'kasim@lightbeam.ai',
        dataSourceType: DATA_SOURCE_TYPES.outLook,
        dataSourceName: 'Peter Singh',
        alertsCount: 1,
        configuration: {
          datasourceType: 'OUTLOOK',
          name: 'Peter Singh'
        },
        progress: 0
      }
    ],
    respondedDatasources: ['62bc396dfe33f3f0093522cf', '62bc3b2e95621979be544d39']
  },
  {
    id: '12',
    name: 'dsr_req_12',
    dpo: 'user@gmail.com',
    createdOn: new Date().toString(),
    dueDate: new Date().toString(),
    status: 'completed',
    process: mockedProcessesList[0],
    datasources: [
      {
        id: '62bc396dfe33f3f0093522cf',
        status: DATASOURCE_STATUS.scanning,
        createdBy: 'kasim@lightbeam.ai',
        dataSourceType: DATA_SOURCE_TYPES.outLook,
        dataSourceName: 'Tonya Brown',
        alertsCount: 1,
        configuration: {
          datasourceType: 'OUTLOOK',
          name: 'Tonya Brown'
        },
        progress: 0
      },
      {
        id: '62bc3b2e95621979be544d39',
        status: DATASOURCE_STATUS.scanning,
        createdBy: 'kasim@lightbeam.ai',
        dataSourceType: DATA_SOURCE_TYPES.outLook,
        dataSourceName: 'Peter Singh',
        alertsCount: 1,
        configuration: {
          datasourceType: 'OUTLOOK',
          name: 'Peter Singh'
        },
        progress: 0
      }
    ],
    respondedDatasources: ['62bc396dfe33f3f0093522cf', '62bc3b2e95621979be544d39']
  }
]

export const mockedTopics = [
  {
    id: 'attributes',
    systemDefined: true,
    type: 'ATTRIBUTE_SETS'
  },
  {
    id: 'classification',
    systemDefined: true,
    type: 'CLASSIFICATIONS',
    optional: false
  },
  {
    id: 'entityTypes',
    systemDefined: true,
    type: 'ENTITY_TYPES',
    optional: true
  },
  {
    id: 'purpose',
    systemDefined: true,
    type: 'PURPOSE'
  },
  {
    id: 'retention',
    systemDefined: true,
    type: 'RETENTION_PERIOD'
  },
  {
    id: 'dsSource',
    systemDefined: true,
    type: 'DATA_SOURCE_SOURCE'
  },
  {
    id: 'dsTarget',
    systemDefined: true,
    type: 'DATA_SOURCE_TARGET'
  },
  {
    id: 'dataTransfer',
    systemDefined: true,
    type: 'DATA_TRANSFERS'
  },
  {
    id: 'safetyMeasures',
    systemDefined: true,
    type: 'SAFETY_MEASURES'
  }
]

export const mockedQuestionnaire = {
  attributes: {
    topicId: '1a',
    _type: 'attributes',
    description: '',
    questions: [],
    optional: false,
    attributeIds: ['62e8b6c4af2aafdc869a81c8', '62e8b6c4af2aafdc869a81ca'],
    allowModification: false,
    _isComplete: true
  },
  classificationObjects: {
    topicId: '1b',
    _type: 'classificationObjects',
    description: '',
    questions: [],
    optional: false,
    classificationIds: ['62e8b6c5af2aafdc869a82c2'],
    allowModification: true,
    _isComplete: true
  },
  entityTypes: {
    topicId: '1c',
    _type: 'entityTypes',
    description: '',
    questions: [],
    optional: true,
    entityIds: ['62e8b6c5af2aafdc869a82b7', '62e8b6c5af2aafdc869a82b8'],
    allowModification: true
  },
  purpose: {
    topicId: '1d',
    _type: 'purpose',
    description: '',
    questions: [],
    optional: false,
    type: '3',
    _isComplete: true
  },
  retentionPeriod: {
    topicId: '1e',
    _type: 'retentionPeriod',
    description: '',
    questions: [],
    optional: false,
    durationCount: '3',
    durationType: 'day',
    retentionPolicy: 'operational',
    _isComplete: true
  },
  sourceDatasource: {
    topicId: '1f',
    _type: 'sourceDatasource',
    description: '',
    questions: [],
    optional: false,
    datasourceId: '62ebe2315918d29b25cffb92',
    isSourcePoint: true,
    isSyncPoint: false,
    processingStage: '2',
    location: 'dddd',
    _isComplete: true
  },
  targetDatasource: {
    topicId: '1g',
    _type: 'targetDatasource',
    description: '',
    questions: [],
    optional: false,
    datasourceId: '62ebe2315918d29b25cffb92',
    isSourcePoint: true,
    isSyncPoint: false,
    processingStage: '1',
    location: 'usa',
    _isComplete: true
  },
  dataTransferDetails: {
    topicId: '1h',
    _type: 'dataTransferDetails',
    description: '',
    questions: [],
    optional: false,
    address: 'address',
    recipientsCategory: '2',
    dataTransferRecipients: [],
    safeguard: 'safeguard',
    transferRetentionPeriod: '3',
    _isComplete: true,
    name: 'name'
  },
  safetyMeasures: {
    topicId: '1i',
    _type: 'safetyMeasures',
    description: '',
    questions: [],
    optional: false,
    name: '1',
    _isComplete: true
  },
  topics: []
}

export const emptyProcess = {
  id: '',
  owner: '',
  dpoEmail: '',
  templateId: '',

  name: '',
  description: '',
  purpose: [],
  processGroups: [],
  lawfulBasis: [],
  automatedProcessing: true,
  automatedProcessingDescription: ''
}

export const emptyProcessTopics = [
  {
    topicId: 'attributes',
    questions: [],
    optional: false,
    descriptionDeleted: true,
    _type: 'attributes',
    _isComplete: false
  },
  {
    topicId: 'classificationObjects',
    questions: [],
    optional: true,
    descriptionDeleted: true,
    _isComplete: true,
    _type: 'classificationObjects'
  },
  {
    topicId: 'entityTypes',
    questions: [],
    optional: false,
    descriptionDeleted: true,
    _type: 'entityTypes',
    _isComplete: false
  },
  {
    topicId: 'purpose',
    label: 'Select the purpose behind storing data in this Data Source',
    options: [
      'Alumni Outreach',
      'Contract Processing',
      'Customer onboarding',
      'Customer orders',
      'Support support',
      'Email management',
      'HR Onboarding',
      'Marketing event activities',
      'Payroll Management',
      'Sales lead generation',
      'Software development',
      'Staff administration'
    ],
    questions: [],
    optional: false,
    dropdownField: 'type',
    _type: 'purpose',
    _isComplete: false,
    _isUpdated: true
  },
  {
    topicId: 'retentionPeriod',
    label: 'Select Retention Period',
    retentionPolicyOptions: ['Not Applicable', 'Forever'],
    questions: [],
    optional: false,
    dropdownField: 'retentionPolicy',
    _type: 'retentionPeriod',
    _isComplete: false,
    _isUpdated: true
  },
  {
    topicId: 'dataLink',
    sourceDataSource: [],
    targetDataSource: [],
    questions: [],
    optional: true,
    _type: 'dataLink',
    _isComplete: true
  },
  {
    topicId: 'dataTransferDetails',
    details: [
      {
        nameLabel: 'Name',
        name: '',
        addressLabel: 'Address',
        address: '',
        categoryOfRecipientsLabel: 'Category of recipients',
        categoryOfRecipients: '',
        safeguardLabel: 'Safeguard',
        safeguard: '',
        safeguardOptions: [
          'Server security',
          'Raise awareness',
          'Detect spam, malware and virus attacks',
          'Update softwares regularly',
          'Control access to data and systems',
          'Automated data removal and archival'
        ],
        transferRetentionPeriodLabel: 'After how long is the data transferred',
        transferRetentionPeriod: '',
        transferRetentionPeriodOptions: ['Immediately'],
        questions: [],
        description: ''
      }
    ],
    questions: [],
    optional: true,
    _isComplete: true,
    _type: 'dataTransferDetails',
    descriptionDeleted: true,
    label: 'Select Retention Period',
    _isUpdated: true
  },
  {
    topicId: 'safetyMeasures',
    safetyMeasuresOptions: [
      'Server security',
      'Raise awareness',
      'Detect spam, malware and virus attacks',
      'Update softwares regularly',
      'Control access to data and systems',
      'Automated data removal and archival'
    ],
    label: 'Select safety measure',
    questions: [],
    optional: false,
    dropdownField: 'safetyMeasures',
    _type: 'safetyMeasures',
    _isComplete: false,
    _isUpdated: true
  }
]

export const reportsListReponse = {
  ropa: {
    edges: [
      {
        node: {
          id: '656049ccae10b6b77a1762e2',
          name: 'ROPA 3',
          status: 'complete',
          processActivity: {
            edges: [
              {
                node: {
                  id: '655dddb0ae10b6b77a176179',
                  name: 'Develop vision and strategy',
                  description:
                    'The company processes data in order define the business concept and long-term vision.',
                  systemDefined: false,
                  createdBy: 'admin@lightbeam.ai',
                  createdAt: '2023-11-24T06:59:14.486000+00:00',
                  departments: ['OPERATIONAL'],
                  lawfulBasis: ['Public Interest'],
                  purpose: ['Customer orders'],
                  processDetails: {
                    isController: true,
                    processingActivityDetails: [
                      {
                        managerType: 'CONTROLLER',
                        details: [
                          {
                            name: '',
                            email: '',
                            address: '',
                            contact: '',
                            roleType: 'DPO'
                          },
                          {
                            name: '',
                            email: '',
                            address: '',
                            contact: '',
                            roleType: 'REPRESENTATIVE'
                          },
                          {
                            name: 'asdf',
                            email: 'asdf',
                            address: '',
                            contact: '',
                            roleType: 'ORGANISATION'
                          }
                        ]
                      }
                    ]
                  }
                }
              }
            ]
          },
          datasourcesResponse: {
            count: 4,
            edges: [
              {
                node: {
                  ropaDatasource: {
                    id: null,
                    dsoEmail: 'admin@lightbeam.ai',
                    datasource: {
                      edges: [
                        {
                          node: {
                            id: '654a2e665be426c659af175a',
                            name: 'Lightbeam Generic Datasource',
                            type: 'GENERIC',
                            location: 'Local',
                            createdBy: 'admin@lightbeam.ai',
                            lastSyncedTime: '2023-11-09T16:05:55.285000+00:00',
                            state: 'Scanning',
                            ropaDatasourceType: null
                          }
                        }
                      ]
                    }
                  },
                  ropaTopics: {
                    attributes: {
                      edges: [
                        {
                          node: {
                            id: '656049ccae10b6b77a1762e4',
                            description: '',
                            allowModification: false,
                            attributes: {
                              edges: [
                                {
                                  node: {
                                    id: '654a2e635be426c659af1634',
                                    name: 'AWS API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1635',
                                    name: 'AWS Access Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1636',
                                    name: 'MWS Auth Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1638',
                                    name: 'ABA Routing Number',
                                    instanceCount: 8,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1639',
                                    name: 'Address',
                                    instanceCount: 45812,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163a',
                                    name: 'House Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163b',
                                    name: 'Street Name',
                                    instanceCount: 520,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163c',
                                    name: 'Street Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163d',
                                    name: 'City',
                                    instanceCount: 629,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163e',
                                    name: 'County',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163f',
                                    name: 'State',
                                    instanceCount: 528,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1640',
                                    name: 'Country',
                                    instanceCount: 27,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1642',
                                    name: 'National Identity Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1643',
                                    name: 'Australia Bank Account Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1644',
                                    name: 'Australia Business Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1645',
                                    name: 'Australia Company Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1646',
                                    name: 'Australia Medical Account Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1647',
                                    name: 'Australia Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1648',
                                    name: 'Australia Tax File Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1649',
                                    name: 'Austria Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164a',
                                    name: 'Austria National ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164b',
                                    name: 'Austria Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164c',
                                    name: 'Austria Social Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164d',
                                    name: 'Austria Social Security Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164e',
                                    name: 'Austria Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164f',
                                    name: 'Austria VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1650',
                                    name: 'Austria Vehicle Registration Plate',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1651',
                                    name: 'Azure Documentdb Auth Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1652',
                                    name: 'Azure Iothub Connection String',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1653',
                                    name: 'Azure Publish Settings Password',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1654',
                                    name: 'Azure Redis Cache Connection String',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1655',
                                    name: 'Azure Saas Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1656',
                                    name: 'Azure Sql Db Connection String',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1657',
                                    name: 'Azure Service Bus Connection String',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1658',
                                    name: 'Azure Storage Account Key Generic',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1659',
                                    name: 'Azure Storage Account Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165b',
                                    name: 'Belgium National Register ID',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165c',
                                    name: 'Belgium Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165d',
                                    name: 'Belgium Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165e',
                                    name: 'Belgium VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165f',
                                    name: 'Birth Date',
                                    instanceCount: 30209,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1660',
                                    name: 'Brazil CPF Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1661',
                                    name: 'Brazil CNPJ Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1662',
                                    name: 'Brazil National Identification Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1664',
                                    name: 'Bulgaria Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1665',
                                    name: 'Bulgaria Uniform Civil Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1666',
                                    name: 'Bulgaria VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1668',
                                    name: 'Canada Bank Account Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1669',
                                    name: 'Canada Health Identification Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166a',
                                    name: 'Canada Health Service Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166b',
                                    name: 'Canada Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166c',
                                    name: 'Canada Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166d',
                                    name: 'Canada Social Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166e',
                                    name: 'Chile Identity Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166f',
                                    name: 'China Resident Identity Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1673',
                                    name: 'Credit Card',
                                    instanceCount: 272,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1674',
                                    name: 'Croatia Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1675',
                                    name: 'Croatia Identity Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1676',
                                    name: 'Croatia Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1677',
                                    name: 'Croatia Personel Identification Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1678',
                                    name: 'Croatia Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1679',
                                    name: 'Croatia VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af167a',
                                    name: 'Crypto',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af167b',
                                    name: 'Cyprus Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af167c',
                                    name: 'Cyprus Identity Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af167d',
                                    name: 'Cyprus Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af167e',
                                    name: 'Cyprus Tax Identification',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af167f',
                                    name: 'Czech Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1680',
                                    name: 'Czech Personal Identity',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1681',
                                    name: 'Czech Republic Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1682',
                                    name: 'Czech Republic VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1683',
                                    name: 'Czechslovakia Citizen ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1684',
                                    name: 'Denmark CRR Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1685',
                                    name: 'Denmark Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1686',
                                    name: 'Denmark Personal Identification',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1687',
                                    name: 'Denmark Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1688',
                                    name: 'Denmark VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168a',
                                    name: 'Drug Enforcement Agency',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168c',
                                    name: 'Email Address',
                                    instanceCount: 43086,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168d',
                                    name: 'Estonian NIF',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168e',
                                    name: 'Estonia Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168f',
                                    name: 'Estonia Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1690',
                                    name: 'Estonia Personel Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1691',
                                    name: 'Estonia Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1692',
                                    name: 'Estonia VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1694',
                                    name: 'Facebook Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1695',
                                    name: 'Facebook Oauth Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1696',
                                    name: 'Finland Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1697',
                                    name: 'Finland Health Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1698',
                                    name: 'Finland Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1699',
                                    name: 'Finland Personel Identity Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169a',
                                    name: 'Finland VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169b',
                                    name: 'France Health Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169c',
                                    name: 'France Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169d',
                                    name: 'France National ID Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169e',
                                    name: 'France Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169f',
                                    name: 'France Social Security Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a0',
                                    name: 'France Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a1',
                                    name: 'France VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a2',
                                    name: 'GCP Oauth Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a3',
                                    name: 'Google Service Account',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a5',
                                    name: 'Generic API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a6',
                                    name: 'Generic Secret',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a7',
                                    name: 'Germany Identity Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a8',
                                    name: 'Germany Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a9',
                                    name: 'Germany Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16aa',
                                    name: 'Germany Personenkennziffer',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ab',
                                    name: 'Germany Setur ID',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ac',
                                    name: 'Germany Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ad',
                                    name: 'Germany VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ae',
                                    name: 'Germany Vericherungsnummer',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16af',
                                    name: 'Github Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b0',
                                    name: 'Google API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b1',
                                    name: 'Google Oauth Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b2',
                                    name: 'Greece Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b3',
                                    name: 'Greece National ID Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b4',
                                    name: 'Greece Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b5',
                                    name: 'Greece Social Security Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b6',
                                    name: 'Greece Tautotita',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b7',
                                    name: 'Greece Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b8',
                                    name: 'Greece VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b9',
                                    name: 'Heroku API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ba',
                                    name: 'Hungary Personal ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16bb',
                                    name: 'Hungary Social Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16bc',
                                    name: 'Hungary Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16bd',
                                    name: 'Hungary VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16be',
                                    name: 'ICD 10 Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16bf',
                                    name: 'ICD 9 Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c0',
                                    name: 'Ind Aadhar Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c1',
                                    name: 'Ind Voter Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c2',
                                    name: 'Ind Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c3',
                                    name: 'Ind Pan Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c4',
                                    name: 'Ind Passport',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c5',
                                    name: 'Ind Phone Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c6',
                                    name: 'IBAN Code',
                                    instanceCount: 10,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c7',
                                    name: 'ID Number',
                                    instanceCount: 474,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c9',
                                    name: 'IP Address',
                                    instanceCount: 6354,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ca',
                                    name: 'Ireland Personal Public Service Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16cb',
                                    name: 'Ireland Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16cc',
                                    name: 'Ireland VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16cd',
                                    name: 'Italy Codice Fiscale',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ce',
                                    name: 'Italy Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16cf',
                                    name: 'Italy Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d0',
                                    name: 'Italy VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d1',
                                    name: 'Latitude/Longitude',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d2',
                                    name: 'Latitude',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d3',
                                    name: 'Longitude',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d4',
                                    name: 'Latvia Personal Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d5',
                                    name: 'Leuxembourg VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d6',
                                    name: 'Lithuania Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d7',
                                    name: 'Lithuania Personal Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d8',
                                    name: 'Lithuania Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d9',
                                    name: 'Lithuania VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16db',
                                    name: 'MAC Address',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16dc',
                                    name: 'Mailchimp API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16dd',
                                    name: 'Mailgun API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16de',
                                    name: 'Malta Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16df',
                                    name: 'Malta Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e0',
                                    name: 'Malta VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e2',
                                    name: 'Medical License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e3',
                                    name: 'Netherlands Burger Service Nummer',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e4',
                                    name: 'Netherlands Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e5',
                                    name: 'Netherlands Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e6',
                                    name: 'Netherlands Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e7',
                                    name: 'Netherlands VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e8',
                                    name: 'UK NHS',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e9',
                                    name: 'Norway Fodsel Snummer',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ea',
                                    name: 'PGP Private Key Block',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ed',
                                    name: 'Paypal Braintree Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ee',
                                    name: 'Picatic API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ef',
                                    name: 'Poland National ID',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f0',
                                    name: 'RSA Private Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f1',
                                    name: 'Republic Of Cyprus Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f2',
                                    name: 'Republic Of Cyprus Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f3',
                                    name: 'Republic Of Cyprus VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f4',
                                    name: 'Romania Personal Nr',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f5',
                                    name: 'SSH DSA Private Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f6',
                                    name: 'SSH EC Private Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16f7',
                                    name: 'SG NRIC Fin',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16f8',
                                    name: 'Slack Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16f9',
                                    name: 'Slack Webhook',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fa',
                                    name: 'Name',
                                    instanceCount: 39228,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fb',
                                    name: 'First Name',
                                    instanceCount: 6981,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fc',
                                    name: 'Last Name',
                                    instanceCount: 28307,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fd',
                                    name: 'Middle Name',
                                    instanceCount: 229,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fe',
                                    name: 'Org',
                                    instanceCount: 2936,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16ff',
                                    name: 'Location',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1700',
                                    name: 'Spain Documento Nacional Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1701',
                                    name: 'Square Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1702',
                                    name: 'Square Oauth Secret',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1703',
                                    name: 'Stripe API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1704',
                                    name: 'Stripe Restriction API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1705',
                                    name: 'Sweden Personal ID',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1706',
                                    name: 'Switzerland AVS',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1707',
                                    name: 'Twilio API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1708',
                                    name: 'Twitter Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1709',
                                    name: 'Twitter Oauth Secret',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170a',
                                    name: 'National Identification Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170b',
                                    name: 'Uk National Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170c',
                                    name: 'Us Bank Number',
                                    instanceCount: 136,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170d',
                                    name: 'US ITIN',
                                    instanceCount: 30,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170e',
                                    name: 'US Driver License',
                                    instanceCount: 1181,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1710',
                                    name: 'USA Medical Record Number (MRN)',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1711',
                                    name: 'US Passport',
                                    instanceCount: 13,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1712',
                                    name: 'Phone Number',
                                    instanceCount: 37989,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1713',
                                    name: 'USA Social Security Number (SSN)',
                                    instanceCount: 37918,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1714',
                                    name: 'Userid/Password',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1715',
                                    name: 'UserId',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1716',
                                    name: 'Password',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1717',
                                    name: 'Vehicle Identification Number (VIN)',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1718',
                                    name: 'Postal/Zip Code',
                                    instanceCount: 888,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        }
                      ]
                    },
                    classificationObjects: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a1762e5',
                            description: '',
                            allowModification: false,
                            classificationIds: {
                              edges: [
                                {
                                  node: {
                                    id: '654a2e665be426c659af1747',
                                    class: 'Human Resource'
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e665be426c659af1748',
                                    class: 'Medical'
                                  }
                                }
                              ]
                            }
                          }
                        }
                      ]
                    },
                    entityTypes: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a1762e6',
                            description: '',
                            allowModification: false,
                            entityIds: {
                              edges: [
                                {
                                  node: {
                                    id: '654a2e655be426c659af1734',
                                    name: 'Human'
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1738',
                                    name: 'Student'
                                  }
                                }
                              ]
                            }
                          }
                        }
                      ]
                    },
                    purpose: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a1762e7',
                            description: 'ewfw',
                            descriptionDeleted: false,
                            label: 'Select the purpose behind storing data in this Data Source',
                            purpose: ['Contract Processing'],
                            purposeOptions: [
                              'Alumni Outreach',
                              'Contract Processing',
                              'Customer onboarding',
                              'Customer orders',
                              'Support support',
                              'Email management',
                              'HR Onboarding',
                              'Marketing event activities',
                              'Payroll Management',
                              'Sales lead generation',
                              'Software development',
                              'Staff administration'
                            ],
                            selection: [],
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    retentionPeriod: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a1762e9',
                            description: 'sadds',
                            descriptionDeleted: false,
                            label: 'Select Retention Period',
                            retentionPolicy: 'Forever',
                            retentionPolicyOptions: ['Not Applicable', 'Forever'],
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    dataLink: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a1762e8',
                            processingStage: 'Processing',
                            associatedDatasources: {
                              sourceDatasource: [],
                              targetDatasource: []
                            },
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    datatransferDetails: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a1762ea',
                            details: [
                              {
                                description: 'wqeweq',
                                descriptionDeleted: false,
                                name: 'weqeq',
                                address: 'wqwew',
                                safeguard: 'Server security',
                                categoryOfRecipients: 'wwqewe',
                                transferRetentionPeriod: 'Immediately',
                                dataTransferRecipients: null,
                                nameLabel: 'Name',
                                addressLabel: 'Address',
                                safeguardLabel: 'Safeguard',
                                safeguardOptions: [
                                  'Server security',
                                  'Raise awareness',
                                  'Detect spam, malware and virus attacks',
                                  'Update softwares regularly',
                                  'Control access to data and systems',
                                  'Automated data removal and archival'
                                ],
                                categoryOfRecipientsLabel: 'Category of recipients',
                                transferRetentionPeriodLabel:
                                  'After how long is the data transferred',
                                transferRetentionPeriodOptions: ['Immediately']
                              }
                            ],
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    safetyMeasure: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a1762eb',
                            description: 'qew',
                            descriptionDeleted: false,
                            safetyMeasures: 'Raise awareness',
                            label: 'Select safety measure',
                            safetyMeasureOptions: [
                              'Server security',
                              'Raise awareness',
                              'Detect spam, malware and virus attacks',
                              'Update softwares regularly',
                              'Control access to data and systems',
                              'Automated data removal and archival'
                            ],
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    customTopic: {
                      edges: []
                    }
                  }
                }
              },
              {
                node: {
                  ropaDatasource: {
                    id: null,
                    dsoEmail: 'chaitanya@lightbeam.ai',
                    datasource: {
                      edges: [
                        {
                          node: {
                            id: '654cd3882f1ffb9d16a84b31',
                            name: 'lb-gdrive',
                            type: 'GOOGLE_DRIVE',
                            location: 'USA',
                            createdBy: 'chaitanya@lightbeam.ai',
                            lastSyncedTime: '2023-11-09T12:41:44.249000+00:00',
                            state: 'Scanning',
                            ropaDatasourceType: 'CONNECTED'
                          }
                        }
                      ]
                    }
                  },
                  ropaTopics: {
                    attributes: {
                      edges: [
                        {
                          node: {
                            id: '656049ccae10b6b77a1762ed',
                            description: '',
                            allowModification: false,
                            attributes: {
                              edges: [
                                {
                                  node: {
                                    id: '654a2e635be426c659af1634',
                                    name: 'AWS API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1635',
                                    name: 'AWS Access Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1636',
                                    name: 'MWS Auth Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1638',
                                    name: 'ABA Routing Number',
                                    instanceCount: 8,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1639',
                                    name: 'Address',
                                    instanceCount: 45812,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163a',
                                    name: 'House Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163b',
                                    name: 'Street Name',
                                    instanceCount: 520,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163c',
                                    name: 'Street Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163d',
                                    name: 'City',
                                    instanceCount: 629,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163e',
                                    name: 'County',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163f',
                                    name: 'State',
                                    instanceCount: 528,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1640',
                                    name: 'Country',
                                    instanceCount: 27,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1642',
                                    name: 'National Identity Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1643',
                                    name: 'Australia Bank Account Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1644',
                                    name: 'Australia Business Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1645',
                                    name: 'Australia Company Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1646',
                                    name: 'Australia Medical Account Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1647',
                                    name: 'Australia Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1648',
                                    name: 'Australia Tax File Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1649',
                                    name: 'Austria Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164a',
                                    name: 'Austria National ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164b',
                                    name: 'Austria Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164c',
                                    name: 'Austria Social Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164d',
                                    name: 'Austria Social Security Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164e',
                                    name: 'Austria Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164f',
                                    name: 'Austria VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1650',
                                    name: 'Austria Vehicle Registration Plate',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1651',
                                    name: 'Azure Documentdb Auth Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1652',
                                    name: 'Azure Iothub Connection String',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1653',
                                    name: 'Azure Publish Settings Password',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1654',
                                    name: 'Azure Redis Cache Connection String',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1655',
                                    name: 'Azure Saas Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1656',
                                    name: 'Azure Sql Db Connection String',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1657',
                                    name: 'Azure Service Bus Connection String',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1658',
                                    name: 'Azure Storage Account Key Generic',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1659',
                                    name: 'Azure Storage Account Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165b',
                                    name: 'Belgium National Register ID',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165c',
                                    name: 'Belgium Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165d',
                                    name: 'Belgium Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165e',
                                    name: 'Belgium VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165f',
                                    name: 'Birth Date',
                                    instanceCount: 30209,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1660',
                                    name: 'Brazil CPF Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1661',
                                    name: 'Brazil CNPJ Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1662',
                                    name: 'Brazil National Identification Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1664',
                                    name: 'Bulgaria Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1665',
                                    name: 'Bulgaria Uniform Civil Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1666',
                                    name: 'Bulgaria VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1668',
                                    name: 'Canada Bank Account Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1669',
                                    name: 'Canada Health Identification Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166a',
                                    name: 'Canada Health Service Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166b',
                                    name: 'Canada Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166c',
                                    name: 'Canada Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166d',
                                    name: 'Canada Social Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166e',
                                    name: 'Chile Identity Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166f',
                                    name: 'China Resident Identity Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1673',
                                    name: 'Credit Card',
                                    instanceCount: 272,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1674',
                                    name: 'Croatia Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1675',
                                    name: 'Croatia Identity Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1676',
                                    name: 'Croatia Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1677',
                                    name: 'Croatia Personel Identification Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1678',
                                    name: 'Croatia Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1679',
                                    name: 'Croatia VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af167a',
                                    name: 'Crypto',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af167b',
                                    name: 'Cyprus Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af167c',
                                    name: 'Cyprus Identity Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af167d',
                                    name: 'Cyprus Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af167e',
                                    name: 'Cyprus Tax Identification',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af167f',
                                    name: 'Czech Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1680',
                                    name: 'Czech Personal Identity',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1681',
                                    name: 'Czech Republic Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1682',
                                    name: 'Czech Republic VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1683',
                                    name: 'Czechslovakia Citizen ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1684',
                                    name: 'Denmark CRR Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1685',
                                    name: 'Denmark Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1686',
                                    name: 'Denmark Personal Identification',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1687',
                                    name: 'Denmark Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1688',
                                    name: 'Denmark VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168a',
                                    name: 'Drug Enforcement Agency',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168c',
                                    name: 'Email Address',
                                    instanceCount: 43086,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168d',
                                    name: 'Estonian NIF',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168e',
                                    name: 'Estonia Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168f',
                                    name: 'Estonia Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1690',
                                    name: 'Estonia Personel Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1691',
                                    name: 'Estonia Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1692',
                                    name: 'Estonia VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1694',
                                    name: 'Facebook Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1695',
                                    name: 'Facebook Oauth Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1696',
                                    name: 'Finland Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1697',
                                    name: 'Finland Health Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1698',
                                    name: 'Finland Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1699',
                                    name: 'Finland Personel Identity Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169a',
                                    name: 'Finland VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169b',
                                    name: 'France Health Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169c',
                                    name: 'France Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169d',
                                    name: 'France National ID Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169e',
                                    name: 'France Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169f',
                                    name: 'France Social Security Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a0',
                                    name: 'France Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a1',
                                    name: 'France VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a2',
                                    name: 'GCP Oauth Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a3',
                                    name: 'Google Service Account',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a5',
                                    name: 'Generic API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a6',
                                    name: 'Generic Secret',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a7',
                                    name: 'Germany Identity Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a8',
                                    name: 'Germany Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a9',
                                    name: 'Germany Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16aa',
                                    name: 'Germany Personenkennziffer',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ab',
                                    name: 'Germany Setur ID',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ac',
                                    name: 'Germany Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ad',
                                    name: 'Germany VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ae',
                                    name: 'Germany Vericherungsnummer',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16af',
                                    name: 'Github Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b0',
                                    name: 'Google API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b1',
                                    name: 'Google Oauth Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b2',
                                    name: 'Greece Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b3',
                                    name: 'Greece National ID Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b4',
                                    name: 'Greece Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b5',
                                    name: 'Greece Social Security Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b6',
                                    name: 'Greece Tautotita',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b7',
                                    name: 'Greece Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b8',
                                    name: 'Greece VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b9',
                                    name: 'Heroku API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ba',
                                    name: 'Hungary Personal ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16bb',
                                    name: 'Hungary Social Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16bc',
                                    name: 'Hungary Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16bd',
                                    name: 'Hungary VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16be',
                                    name: 'ICD 10 Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16bf',
                                    name: 'ICD 9 Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c0',
                                    name: 'Ind Aadhar Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c1',
                                    name: 'Ind Voter Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c2',
                                    name: 'Ind Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c3',
                                    name: 'Ind Pan Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c4',
                                    name: 'Ind Passport',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c5',
                                    name: 'Ind Phone Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c6',
                                    name: 'IBAN Code',
                                    instanceCount: 10,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c7',
                                    name: 'ID Number',
                                    instanceCount: 474,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c9',
                                    name: 'IP Address',
                                    instanceCount: 6354,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ca',
                                    name: 'Ireland Personal Public Service Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16cb',
                                    name: 'Ireland Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16cc',
                                    name: 'Ireland VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16cd',
                                    name: 'Italy Codice Fiscale',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ce',
                                    name: 'Italy Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16cf',
                                    name: 'Italy Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d0',
                                    name: 'Italy VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d1',
                                    name: 'Latitude/Longitude',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d2',
                                    name: 'Latitude',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d3',
                                    name: 'Longitude',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d4',
                                    name: 'Latvia Personal Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d5',
                                    name: 'Leuxembourg VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d6',
                                    name: 'Lithuania Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d7',
                                    name: 'Lithuania Personal Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d8',
                                    name: 'Lithuania Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d9',
                                    name: 'Lithuania VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16db',
                                    name: 'MAC Address',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16dc',
                                    name: 'Mailchimp API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16dd',
                                    name: 'Mailgun API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16de',
                                    name: 'Malta Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16df',
                                    name: 'Malta Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e0',
                                    name: 'Malta VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e2',
                                    name: 'Medical License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e3',
                                    name: 'Netherlands Burger Service Nummer',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e4',
                                    name: 'Netherlands Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e5',
                                    name: 'Netherlands Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e6',
                                    name: 'Netherlands Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e7',
                                    name: 'Netherlands VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e8',
                                    name: 'UK NHS',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e9',
                                    name: 'Norway Fodsel Snummer',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ea',
                                    name: 'PGP Private Key Block',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ed',
                                    name: 'Paypal Braintree Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ee',
                                    name: 'Picatic API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ef',
                                    name: 'Poland National ID',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f0',
                                    name: 'RSA Private Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f1',
                                    name: 'Republic Of Cyprus Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f2',
                                    name: 'Republic Of Cyprus Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f3',
                                    name: 'Republic Of Cyprus VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f4',
                                    name: 'Romania Personal Nr',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f5',
                                    name: 'SSH DSA Private Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f6',
                                    name: 'SSH EC Private Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16f7',
                                    name: 'SG NRIC Fin',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16f8',
                                    name: 'Slack Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16f9',
                                    name: 'Slack Webhook',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fa',
                                    name: 'Name',
                                    instanceCount: 39228,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fb',
                                    name: 'First Name',
                                    instanceCount: 6981,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fc',
                                    name: 'Last Name',
                                    instanceCount: 28307,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fd',
                                    name: 'Middle Name',
                                    instanceCount: 229,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fe',
                                    name: 'Org',
                                    instanceCount: 2936,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16ff',
                                    name: 'Location',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1700',
                                    name: 'Spain Documento Nacional Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1701',
                                    name: 'Square Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1702',
                                    name: 'Square Oauth Secret',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1703',
                                    name: 'Stripe API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1704',
                                    name: 'Stripe Restriction API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1705',
                                    name: 'Sweden Personal ID',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1706',
                                    name: 'Switzerland AVS',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1707',
                                    name: 'Twilio API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1708',
                                    name: 'Twitter Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1709',
                                    name: 'Twitter Oauth Secret',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170a',
                                    name: 'National Identification Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170b',
                                    name: 'Uk National Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170c',
                                    name: 'Us Bank Number',
                                    instanceCount: 136,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170d',
                                    name: 'US ITIN',
                                    instanceCount: 30,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170e',
                                    name: 'US Driver License',
                                    instanceCount: 1181,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1710',
                                    name: 'USA Medical Record Number (MRN)',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1711',
                                    name: 'US Passport',
                                    instanceCount: 13,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1712',
                                    name: 'Phone Number',
                                    instanceCount: 37989,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1713',
                                    name: 'USA Social Security Number (SSN)',
                                    instanceCount: 37918,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1714',
                                    name: 'Userid/Password',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1715',
                                    name: 'UserId',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1716',
                                    name: 'Password',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1717',
                                    name: 'Vehicle Identification Number (VIN)',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1718',
                                    name: 'Postal/Zip Code',
                                    instanceCount: 888,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        }
                      ]
                    },
                    classificationObjects: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a1762ee',
                            description: '',
                            allowModification: false,
                            classificationIds: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    entityTypes: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a1762ef',
                            description: '',
                            allowModification: false,
                            entityIds: {
                              edges: [
                                {
                                  node: {
                                    id: '654a2e655be426c659af173b',
                                    name: 'Partner'
                                  }
                                }
                              ]
                            }
                          }
                        }
                      ]
                    },
                    purpose: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a1762f0',
                            description: '',
                            descriptionDeleted: false,
                            label: 'Select the purpose behind storing data in this Data Source',
                            purpose: 'Contract Processing',
                            purposeOptions: [
                              'Alumni Outreach',
                              'Contract Processing',
                              'Customer onboarding',
                              'Customer orders',
                              'Support support',
                              'Email management',
                              'HR Onboarding',
                              'Marketing event activities',
                              'Payroll Management',
                              'Sales lead generation',
                              'Software development',
                              'Staff administration'
                            ],
                            selection: [],
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    retentionPeriod: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a1762f2',
                            description: '',
                            descriptionDeleted: false,
                            label: 'Select Retention Period',
                            retentionPolicy: 'Forever',
                            retentionPolicyOptions: ['Not Applicable', 'Forever'],
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    dataLink: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a1762f1',
                            processingStage: 'Collection',
                            associatedDatasources: {
                              sourceDatasource: [],
                              targetDatasource: []
                            },
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    datatransferDetails: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a1762f3',
                            details: [
                              {
                                description: '',
                                descriptionDeleted: false,
                                name: '',
                                address: '',
                                safeguard: '',
                                categoryOfRecipients: '',
                                transferRetentionPeriod: '',
                                dataTransferRecipients: null,
                                nameLabel: 'Name',
                                addressLabel: 'Address',
                                safeguardLabel: 'Safeguard',
                                safeguardOptions: [
                                  'Server security',
                                  'Raise awareness',
                                  'Detect spam, malware and virus attacks',
                                  'Update softwares regularly',
                                  'Control access to data and systems',
                                  'Automated data removal and archival'
                                ],
                                categoryOfRecipientsLabel: 'Category of recipients',
                                transferRetentionPeriodLabel:
                                  'After how long is the data transferred',
                                transferRetentionPeriodOptions: ['Immediately']
                              }
                            ],
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    safetyMeasure: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a1762f4',
                            description: '',
                            descriptionDeleted: false,
                            safetyMeasures: 'Raise awareness',
                            label: 'Select safety measure',
                            safetyMeasureOptions: [
                              'Server security',
                              'Raise awareness',
                              'Detect spam, malware and virus attacks',
                              'Update softwares regularly',
                              'Control access to data and systems',
                              'Automated data removal and archival'
                            ],
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    customTopic: {
                      edges: []
                    }
                  }
                }
              },
              {
                node: {
                  ropaDatasource: {
                    id: null,
                    dsoEmail: 'chaitanya@lightbeam.ai',
                    datasource: {
                      edges: [
                        {
                          node: {
                            id: '654d09f82f1ffb9d16a84b3d',
                            name: 'lb-post',
                            type: 'POSTGRESQL',
                            location: 'USA',
                            createdBy: 'chaitanya@lightbeam.ai',
                            lastSyncedTime: '2023-11-16T00:00:57.180000+00:00',
                            state: 'Scanned',
                            ropaDatasourceType: 'CONNECTED'
                          }
                        }
                      ]
                    }
                  },
                  ropaTopics: {
                    attributes: {
                      edges: [
                        {
                          node: {
                            id: '656049ccae10b6b77a1762ff',
                            description: '',
                            allowModification: false,
                            attributes: {
                              edges: [
                                {
                                  node: {
                                    id: '654a2e635be426c659af1634',
                                    name: 'AWS API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1635',
                                    name: 'AWS Access Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1636',
                                    name: 'MWS Auth Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1638',
                                    name: 'ABA Routing Number',
                                    instanceCount: 8,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1639',
                                    name: 'Address',
                                    instanceCount: 45812,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163a',
                                    name: 'House Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163b',
                                    name: 'Street Name',
                                    instanceCount: 520,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163c',
                                    name: 'Street Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163d',
                                    name: 'City',
                                    instanceCount: 629,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163e',
                                    name: 'County',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163f',
                                    name: 'State',
                                    instanceCount: 528,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1640',
                                    name: 'Country',
                                    instanceCount: 27,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1642',
                                    name: 'National Identity Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1643',
                                    name: 'Australia Bank Account Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1644',
                                    name: 'Australia Business Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1645',
                                    name: 'Australia Company Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1646',
                                    name: 'Australia Medical Account Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1647',
                                    name: 'Australia Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1648',
                                    name: 'Australia Tax File Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1649',
                                    name: 'Austria Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164a',
                                    name: 'Austria National ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164b',
                                    name: 'Austria Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164c',
                                    name: 'Austria Social Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164d',
                                    name: 'Austria Social Security Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164e',
                                    name: 'Austria Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164f',
                                    name: 'Austria VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1650',
                                    name: 'Austria Vehicle Registration Plate',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1651',
                                    name: 'Azure Documentdb Auth Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1652',
                                    name: 'Azure Iothub Connection String',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1653',
                                    name: 'Azure Publish Settings Password',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1654',
                                    name: 'Azure Redis Cache Connection String',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1655',
                                    name: 'Azure Saas Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1656',
                                    name: 'Azure Sql Db Connection String',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1657',
                                    name: 'Azure Service Bus Connection String',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1658',
                                    name: 'Azure Storage Account Key Generic',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1659',
                                    name: 'Azure Storage Account Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165b',
                                    name: 'Belgium National Register ID',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165c',
                                    name: 'Belgium Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165d',
                                    name: 'Belgium Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165e',
                                    name: 'Belgium VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165f',
                                    name: 'Birth Date',
                                    instanceCount: 30209,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1660',
                                    name: 'Brazil CPF Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1661',
                                    name: 'Brazil CNPJ Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1662',
                                    name: 'Brazil National Identification Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1664',
                                    name: 'Bulgaria Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1665',
                                    name: 'Bulgaria Uniform Civil Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1666',
                                    name: 'Bulgaria VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1668',
                                    name: 'Canada Bank Account Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1669',
                                    name: 'Canada Health Identification Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166a',
                                    name: 'Canada Health Service Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166b',
                                    name: 'Canada Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166c',
                                    name: 'Canada Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166d',
                                    name: 'Canada Social Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166e',
                                    name: 'Chile Identity Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166f',
                                    name: 'China Resident Identity Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1673',
                                    name: 'Credit Card',
                                    instanceCount: 272,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1674',
                                    name: 'Croatia Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1675',
                                    name: 'Croatia Identity Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1676',
                                    name: 'Croatia Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1677',
                                    name: 'Croatia Personel Identification Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1678',
                                    name: 'Croatia Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1679',
                                    name: 'Croatia VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af167a',
                                    name: 'Crypto',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af167b',
                                    name: 'Cyprus Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af167c',
                                    name: 'Cyprus Identity Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af167d',
                                    name: 'Cyprus Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af167e',
                                    name: 'Cyprus Tax Identification',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af167f',
                                    name: 'Czech Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1680',
                                    name: 'Czech Personal Identity',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1681',
                                    name: 'Czech Republic Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1682',
                                    name: 'Czech Republic VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1683',
                                    name: 'Czechslovakia Citizen ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1684',
                                    name: 'Denmark CRR Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1685',
                                    name: 'Denmark Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1686',
                                    name: 'Denmark Personal Identification',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1687',
                                    name: 'Denmark Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1688',
                                    name: 'Denmark VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168a',
                                    name: 'Drug Enforcement Agency',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168c',
                                    name: 'Email Address',
                                    instanceCount: 43086,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168d',
                                    name: 'Estonian NIF',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168e',
                                    name: 'Estonia Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168f',
                                    name: 'Estonia Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1690',
                                    name: 'Estonia Personel Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1691',
                                    name: 'Estonia Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1692',
                                    name: 'Estonia VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1694',
                                    name: 'Facebook Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1695',
                                    name: 'Facebook Oauth Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1696',
                                    name: 'Finland Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1697',
                                    name: 'Finland Health Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1698',
                                    name: 'Finland Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1699',
                                    name: 'Finland Personel Identity Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169a',
                                    name: 'Finland VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169b',
                                    name: 'France Health Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169c',
                                    name: 'France Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169d',
                                    name: 'France National ID Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169e',
                                    name: 'France Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169f',
                                    name: 'France Social Security Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a0',
                                    name: 'France Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a1',
                                    name: 'France VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a2',
                                    name: 'GCP Oauth Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a3',
                                    name: 'Google Service Account',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a5',
                                    name: 'Generic API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a6',
                                    name: 'Generic Secret',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a7',
                                    name: 'Germany Identity Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a8',
                                    name: 'Germany Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a9',
                                    name: 'Germany Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16aa',
                                    name: 'Germany Personenkennziffer',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ab',
                                    name: 'Germany Setur ID',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ac',
                                    name: 'Germany Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ad',
                                    name: 'Germany VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ae',
                                    name: 'Germany Vericherungsnummer',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16af',
                                    name: 'Github Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b0',
                                    name: 'Google API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b1',
                                    name: 'Google Oauth Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b2',
                                    name: 'Greece Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b3',
                                    name: 'Greece National ID Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b4',
                                    name: 'Greece Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b5',
                                    name: 'Greece Social Security Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b6',
                                    name: 'Greece Tautotita',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b7',
                                    name: 'Greece Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b8',
                                    name: 'Greece VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b9',
                                    name: 'Heroku API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ba',
                                    name: 'Hungary Personal ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16bb',
                                    name: 'Hungary Social Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16bc',
                                    name: 'Hungary Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16bd',
                                    name: 'Hungary VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16be',
                                    name: 'ICD 10 Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16bf',
                                    name: 'ICD 9 Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c0',
                                    name: 'Ind Aadhar Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c1',
                                    name: 'Ind Voter Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c2',
                                    name: 'Ind Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c3',
                                    name: 'Ind Pan Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c4',
                                    name: 'Ind Passport',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c5',
                                    name: 'Ind Phone Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c6',
                                    name: 'IBAN Code',
                                    instanceCount: 10,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c7',
                                    name: 'ID Number',
                                    instanceCount: 474,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c9',
                                    name: 'IP Address',
                                    instanceCount: 6354,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ca',
                                    name: 'Ireland Personal Public Service Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16cb',
                                    name: 'Ireland Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16cc',
                                    name: 'Ireland VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16cd',
                                    name: 'Italy Codice Fiscale',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ce',
                                    name: 'Italy Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16cf',
                                    name: 'Italy Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d0',
                                    name: 'Italy VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d1',
                                    name: 'Latitude/Longitude',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d2',
                                    name: 'Latitude',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d3',
                                    name: 'Longitude',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d4',
                                    name: 'Latvia Personal Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d5',
                                    name: 'Leuxembourg VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d6',
                                    name: 'Lithuania Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d7',
                                    name: 'Lithuania Personal Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d8',
                                    name: 'Lithuania Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d9',
                                    name: 'Lithuania VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16db',
                                    name: 'MAC Address',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16dc',
                                    name: 'Mailchimp API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16dd',
                                    name: 'Mailgun API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16de',
                                    name: 'Malta Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16df',
                                    name: 'Malta Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e0',
                                    name: 'Malta VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e2',
                                    name: 'Medical License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e3',
                                    name: 'Netherlands Burger Service Nummer',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e4',
                                    name: 'Netherlands Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e5',
                                    name: 'Netherlands Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e6',
                                    name: 'Netherlands Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e7',
                                    name: 'Netherlands VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e8',
                                    name: 'UK NHS',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e9',
                                    name: 'Norway Fodsel Snummer',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ea',
                                    name: 'PGP Private Key Block',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ed',
                                    name: 'Paypal Braintree Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ee',
                                    name: 'Picatic API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ef',
                                    name: 'Poland National ID',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f0',
                                    name: 'RSA Private Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f1',
                                    name: 'Republic Of Cyprus Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f2',
                                    name: 'Republic Of Cyprus Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f3',
                                    name: 'Republic Of Cyprus VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f4',
                                    name: 'Romania Personal Nr',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f5',
                                    name: 'SSH DSA Private Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f6',
                                    name: 'SSH EC Private Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16f7',
                                    name: 'SG NRIC Fin',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16f8',
                                    name: 'Slack Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16f9',
                                    name: 'Slack Webhook',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fa',
                                    name: 'Name',
                                    instanceCount: 39228,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fb',
                                    name: 'First Name',
                                    instanceCount: 6981,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fc',
                                    name: 'Last Name',
                                    instanceCount: 28307,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fd',
                                    name: 'Middle Name',
                                    instanceCount: 229,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fe',
                                    name: 'Org',
                                    instanceCount: 2936,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16ff',
                                    name: 'Location',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1700',
                                    name: 'Spain Documento Nacional Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1701',
                                    name: 'Square Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1702',
                                    name: 'Square Oauth Secret',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1703',
                                    name: 'Stripe API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1704',
                                    name: 'Stripe Restriction API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1705',
                                    name: 'Sweden Personal ID',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1706',
                                    name: 'Switzerland AVS',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1707',
                                    name: 'Twilio API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1708',
                                    name: 'Twitter Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1709',
                                    name: 'Twitter Oauth Secret',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170a',
                                    name: 'National Identification Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170b',
                                    name: 'Uk National Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170c',
                                    name: 'Us Bank Number',
                                    instanceCount: 136,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170d',
                                    name: 'US ITIN',
                                    instanceCount: 30,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170e',
                                    name: 'US Driver License',
                                    instanceCount: 1181,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1710',
                                    name: 'USA Medical Record Number (MRN)',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1711',
                                    name: 'US Passport',
                                    instanceCount: 13,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1712',
                                    name: 'Phone Number',
                                    instanceCount: 37989,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1713',
                                    name: 'USA Social Security Number (SSN)',
                                    instanceCount: 37918,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1714',
                                    name: 'Userid/Password',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1715',
                                    name: 'UserId',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1716',
                                    name: 'Password',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1717',
                                    name: 'Vehicle Identification Number (VIN)',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1718',
                                    name: 'Postal/Zip Code',
                                    instanceCount: 888,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        }
                      ]
                    },
                    classificationObjects: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a176300',
                            description: '',
                            allowModification: false,
                            classificationIds: {
                              edges: [
                                {
                                  node: {
                                    id: '654a2e665be426c659af1748',
                                    class: 'Medical'
                                  }
                                }
                              ]
                            }
                          }
                        }
                      ]
                    },
                    entityTypes: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a176301',
                            description: '',
                            allowModification: false,
                            entityIds: {
                              edges: [
                                {
                                  node: {
                                    id: '654a2e655be426c659af1738',
                                    name: 'Student'
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af173c',
                                    name: 'Ex-Employee'
                                  }
                                }
                              ]
                            }
                          }
                        }
                      ]
                    },
                    purpose: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a176302',
                            description: 'qqew',
                            descriptionDeleted: false,
                            label: 'Select the purpose behind storing data in this Data Source',
                            purpose: ['Alumni Outreach'],
                            purposeOptions: [
                              'Alumni Outreach',
                              'Contract Processing',
                              'Customer onboarding',
                              'Customer orders',
                              'Support support',
                              'Email management',
                              'HR Onboarding',
                              'Marketing event activities',
                              'Payroll Management',
                              'Sales lead generation',
                              'Software development',
                              'Staff administration'
                            ],
                            selection: [],
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    retentionPeriod: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a176304',
                            description: 'qeqw',
                            descriptionDeleted: false,
                            label: 'Select Retention Period',
                            retentionPolicy: 'Forever',
                            retentionPolicyOptions: ['Not Applicable', 'Forever'],
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    dataLink: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a176303',
                            processingStage: 'Exchange',
                            associatedDatasources: {
                              sourceDatasource: [],
                              targetDatasource: []
                            },
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    datatransferDetails: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a176305',
                            details: [
                              {
                                description: 'wqewq',
                                descriptionDeleted: false,
                                name: 'qweq',
                                address: 'eqewe',
                                safeguard: 'Detect spam, malware and virus attacks',
                                categoryOfRecipients: 'qeqw',
                                transferRetentionPeriod: 'Immediately',
                                dataTransferRecipients: null,
                                nameLabel: 'Name',
                                addressLabel: 'Address',
                                safeguardLabel: 'Safeguard',
                                safeguardOptions: [
                                  'Server security',
                                  'Raise awareness',
                                  'Detect spam, malware and virus attacks',
                                  'Update softwares regularly',
                                  'Control access to data and systems',
                                  'Automated data removal and archival'
                                ],
                                categoryOfRecipientsLabel: 'Category of recipients',
                                transferRetentionPeriodLabel:
                                  'After how long is the data transferred',
                                transferRetentionPeriodOptions: ['Immediately']
                              }
                            ],
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    safetyMeasure: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a176306',
                            description: 'weqwqe',
                            descriptionDeleted: false,
                            safetyMeasures: 'Raise awareness',
                            label: 'Select safety measure',
                            safetyMeasureOptions: [
                              'Server security',
                              'Raise awareness',
                              'Detect spam, malware and virus attacks',
                              'Update softwares regularly',
                              'Control access to data and systems',
                              'Automated data removal and archival'
                            ],
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    customTopic: {
                      edges: []
                    }
                  }
                }
              },
              {
                node: {
                  ropaDatasource: {
                    id: null,
                    dsoEmail: 'alex@lightbeam.ai',
                    datasource: {
                      edges: [
                        {
                          node: {
                            id: '655f73f957f2fdb776c411ad',
                            name: 'postgres-2',
                            type: 'POSTGRESQL',
                            location: 'USA',
                            createdBy: 'alex@lightbeam.ai',
                            lastSyncedTime: '2023-11-27T00:01:48.385000+00:00',
                            state: 'Scanned',
                            ropaDatasourceType: 'CONNECTED'
                          }
                        }
                      ]
                    }
                  },
                  ropaTopics: {
                    attributes: {
                      edges: [
                        {
                          node: {
                            id: '656049ccae10b6b77a176308',
                            description: '',
                            allowModification: false,
                            attributes: {
                              edges: [
                                {
                                  node: {
                                    id: '654a2e635be426c659af1634',
                                    name: 'AWS API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1635',
                                    name: 'AWS Access Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1636',
                                    name: 'MWS Auth Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1638',
                                    name: 'ABA Routing Number',
                                    instanceCount: 8,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1639',
                                    name: 'Address',
                                    instanceCount: 45812,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163a',
                                    name: 'House Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163b',
                                    name: 'Street Name',
                                    instanceCount: 520,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163c',
                                    name: 'Street Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163d',
                                    name: 'City',
                                    instanceCount: 629,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163e',
                                    name: 'County',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af163f',
                                    name: 'State',
                                    instanceCount: 528,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1640',
                                    name: 'Country',
                                    instanceCount: 27,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1642',
                                    name: 'National Identity Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1643',
                                    name: 'Australia Bank Account Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1644',
                                    name: 'Australia Business Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1645',
                                    name: 'Australia Company Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1646',
                                    name: 'Australia Medical Account Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1647',
                                    name: 'Australia Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1648',
                                    name: 'Australia Tax File Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1649',
                                    name: 'Austria Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164a',
                                    name: 'Austria National ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164b',
                                    name: 'Austria Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164c',
                                    name: 'Austria Social Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164d',
                                    name: 'Austria Social Security Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164e',
                                    name: 'Austria Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af164f',
                                    name: 'Austria VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1650',
                                    name: 'Austria Vehicle Registration Plate',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1651',
                                    name: 'Azure Documentdb Auth Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1652',
                                    name: 'Azure Iothub Connection String',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1653',
                                    name: 'Azure Publish Settings Password',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1654',
                                    name: 'Azure Redis Cache Connection String',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1655',
                                    name: 'Azure Saas Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1656',
                                    name: 'Azure Sql Db Connection String',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1657',
                                    name: 'Azure Service Bus Connection String',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1658',
                                    name: 'Azure Storage Account Key Generic',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1659',
                                    name: 'Azure Storage Account Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165b',
                                    name: 'Belgium National Register ID',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165c',
                                    name: 'Belgium Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165d',
                                    name: 'Belgium Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165e',
                                    name: 'Belgium VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af165f',
                                    name: 'Birth Date',
                                    instanceCount: 30209,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1660',
                                    name: 'Brazil CPF Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1661',
                                    name: 'Brazil CNPJ Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1662',
                                    name: 'Brazil National Identification Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1664',
                                    name: 'Bulgaria Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1665',
                                    name: 'Bulgaria Uniform Civil Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1666',
                                    name: 'Bulgaria VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1668',
                                    name: 'Canada Bank Account Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1669',
                                    name: 'Canada Health Identification Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166a',
                                    name: 'Canada Health Service Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166b',
                                    name: 'Canada Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166c',
                                    name: 'Canada Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166d',
                                    name: 'Canada Social Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166e',
                                    name: 'Chile Identity Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af166f',
                                    name: 'China Resident Identity Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1673',
                                    name: 'Credit Card',
                                    instanceCount: 272,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1674',
                                    name: 'Croatia Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1675',
                                    name: 'Croatia Identity Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1676',
                                    name: 'Croatia Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1677',
                                    name: 'Croatia Personel Identification Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1678',
                                    name: 'Croatia Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af1679',
                                    name: 'Croatia VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af167a',
                                    name: 'Crypto',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e635be426c659af167b',
                                    name: 'Cyprus Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af167c',
                                    name: 'Cyprus Identity Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af167d',
                                    name: 'Cyprus Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af167e',
                                    name: 'Cyprus Tax Identification',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af167f',
                                    name: 'Czech Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1680',
                                    name: 'Czech Personal Identity',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1681',
                                    name: 'Czech Republic Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1682',
                                    name: 'Czech Republic VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1683',
                                    name: 'Czechslovakia Citizen ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1684',
                                    name: 'Denmark CRR Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1685',
                                    name: 'Denmark Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1686',
                                    name: 'Denmark Personal Identification',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1687',
                                    name: 'Denmark Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1688',
                                    name: 'Denmark VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168a',
                                    name: 'Drug Enforcement Agency',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168c',
                                    name: 'Email Address',
                                    instanceCount: 43086,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168d',
                                    name: 'Estonian NIF',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168e',
                                    name: 'Estonia Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af168f',
                                    name: 'Estonia Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1690',
                                    name: 'Estonia Personel Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1691',
                                    name: 'Estonia Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1692',
                                    name: 'Estonia VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1694',
                                    name: 'Facebook Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1695',
                                    name: 'Facebook Oauth Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1696',
                                    name: 'Finland Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1697',
                                    name: 'Finland Health Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1698',
                                    name: 'Finland Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af1699',
                                    name: 'Finland Personel Identity Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169a',
                                    name: 'Finland VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169b',
                                    name: 'France Health Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169c',
                                    name: 'France Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169d',
                                    name: 'France National ID Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169e',
                                    name: 'France Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af169f',
                                    name: 'France Social Security Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a0',
                                    name: 'France Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a1',
                                    name: 'France VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a2',
                                    name: 'GCP Oauth Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a3',
                                    name: 'Google Service Account',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a5',
                                    name: 'Generic API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a6',
                                    name: 'Generic Secret',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a7',
                                    name: 'Germany Identity Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a8',
                                    name: 'Germany Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16a9',
                                    name: 'Germany Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16aa',
                                    name: 'Germany Personenkennziffer',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ab',
                                    name: 'Germany Setur ID',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ac',
                                    name: 'Germany Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ad',
                                    name: 'Germany VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ae',
                                    name: 'Germany Vericherungsnummer',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16af',
                                    name: 'Github Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b0',
                                    name: 'Google API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b1',
                                    name: 'Google Oauth Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b2',
                                    name: 'Greece Driver License Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b3',
                                    name: 'Greece National ID Card Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b4',
                                    name: 'Greece Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b5',
                                    name: 'Greece Social Security Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b6',
                                    name: 'Greece Tautotita',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b7',
                                    name: 'Greece Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b8',
                                    name: 'Greece VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16b9',
                                    name: 'Heroku API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ba',
                                    name: 'Hungary Personal ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16bb',
                                    name: 'Hungary Social Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16bc',
                                    name: 'Hungary Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16bd',
                                    name: 'Hungary VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16be',
                                    name: 'ICD 10 Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16bf',
                                    name: 'ICD 9 Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c0',
                                    name: 'Ind Aadhar Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c1',
                                    name: 'Ind Voter Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c2',
                                    name: 'Ind Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c3',
                                    name: 'Ind Pan Card',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c4',
                                    name: 'Ind Passport',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c5',
                                    name: 'Ind Phone Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c6',
                                    name: 'IBAN Code',
                                    instanceCount: 10,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c7',
                                    name: 'ID Number',
                                    instanceCount: 474,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16c9',
                                    name: 'IP Address',
                                    instanceCount: 6354,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ca',
                                    name: 'Ireland Personal Public Service Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16cb',
                                    name: 'Ireland Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16cc',
                                    name: 'Ireland VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16cd',
                                    name: 'Italy Codice Fiscale',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ce',
                                    name: 'Italy Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16cf',
                                    name: 'Italy Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d0',
                                    name: 'Italy VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d1',
                                    name: 'Latitude/Longitude',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d2',
                                    name: 'Latitude',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d3',
                                    name: 'Longitude',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d4',
                                    name: 'Latvia Personal Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d5',
                                    name: 'Leuxembourg VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d6',
                                    name: 'Lithuania Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d7',
                                    name: 'Lithuania Personal Code',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d8',
                                    name: 'Lithuania Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16d9',
                                    name: 'Lithuania VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16db',
                                    name: 'MAC Address',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16dc',
                                    name: 'Mailchimp API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16dd',
                                    name: 'Mailgun API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16de',
                                    name: 'Malta Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16df',
                                    name: 'Malta Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e0',
                                    name: 'Malta VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e2',
                                    name: 'Medical License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e3',
                                    name: 'Netherlands Burger Service Nummer',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e4',
                                    name: 'Netherlands Driver License',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e5',
                                    name: 'Netherlands Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e6',
                                    name: 'Netherlands Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e7',
                                    name: 'Netherlands VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e8',
                                    name: 'UK NHS',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16e9',
                                    name: 'Norway Fodsel Snummer',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ea',
                                    name: 'PGP Private Key Block',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ed',
                                    name: 'Paypal Braintree Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ee',
                                    name: 'Picatic API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16ef',
                                    name: 'Poland National ID',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f0',
                                    name: 'RSA Private Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f1',
                                    name: 'Republic Of Cyprus Passport Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f2',
                                    name: 'Republic Of Cyprus Tax ID Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f3',
                                    name: 'Republic Of Cyprus VAT Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f4',
                                    name: 'Romania Personal Nr',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f5',
                                    name: 'SSH DSA Private Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e645be426c659af16f6',
                                    name: 'SSH EC Private Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16f7',
                                    name: 'SG NRIC Fin',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16f8',
                                    name: 'Slack Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16f9',
                                    name: 'Slack Webhook',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fa',
                                    name: 'Name',
                                    instanceCount: 39228,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fb',
                                    name: 'First Name',
                                    instanceCount: 6981,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fc',
                                    name: 'Last Name',
                                    instanceCount: 28307,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fd',
                                    name: 'Middle Name',
                                    instanceCount: 229,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16fe',
                                    name: 'Org',
                                    instanceCount: 2936,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af16ff',
                                    name: 'Location',
                                    instanceCount: 0,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1700',
                                    name: 'Spain Documento Nacional Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1701',
                                    name: 'Square Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1702',
                                    name: 'Square Oauth Secret',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1703',
                                    name: 'Stripe API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1704',
                                    name: 'Stripe Restriction API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1705',
                                    name: 'Sweden Personal ID',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1706',
                                    name: 'Switzerland AVS',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1707',
                                    name: 'Twilio API Key',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1708',
                                    name: 'Twitter Access Token',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1709',
                                    name: 'Twitter Oauth Secret',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170a',
                                    name: 'National Identification Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170b',
                                    name: 'Uk National Insurance Number',
                                    instanceCount: 0,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170c',
                                    name: 'Us Bank Number',
                                    instanceCount: 136,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170d',
                                    name: 'US ITIN',
                                    instanceCount: 30,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af170e',
                                    name: 'US Driver License',
                                    instanceCount: 1181,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1710',
                                    name: 'USA Medical Record Number (MRN)',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1711',
                                    name: 'US Passport',
                                    instanceCount: 13,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1712',
                                    name: 'Phone Number',
                                    instanceCount: 37989,
                                    sensitivityLabel: 'MEDIUM',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1713',
                                    name: 'USA Social Security Number (SSN)',
                                    instanceCount: 37918,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654d09f82f1ffb9d16a84b3d',
                                                name: 'lb-post'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1714',
                                    name: 'Userid/Password',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1715',
                                    name: 'UserId',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1716',
                                    name: 'Password',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1717',
                                    name: 'Vehicle Identification Number (VIN)',
                                    instanceCount: 0,
                                    sensitivityLabel: 'HIGH',
                                    datasourceCounts: []
                                  }
                                },
                                {
                                  node: {
                                    id: '654a2e655be426c659af1718',
                                    name: 'Postal/Zip Code',
                                    instanceCount: 888,
                                    sensitivityLabel: 'LOW',
                                    datasourceCounts: [
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '654cd3882f1ffb9d16a84b31',
                                                name: 'lb-gdrive'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        datasource: {
                                          edges: [
                                            {
                                              node: {
                                                id: '65633dff3de51cde8c8ceae8',
                                                name: 'postgres-3'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        }
                      ]
                    },
                    classificationObjects: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a176309',
                            description: '',
                            allowModification: false,
                            classificationIds: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    entityTypes: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a17630a',
                            description: '',
                            allowModification: false,
                            entityIds: {
                              edges: [
                                {
                                  node: {
                                    id: '654a2e655be426c659af173a',
                                    name: 'Employee'
                                  }
                                }
                              ]
                            }
                          }
                        }
                      ]
                    },
                    purpose: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a17630b',
                            description: '',
                            descriptionDeleted: false,
                            label: 'Select the purpose behind storing data in this Data Source',
                            purpose: ['Alumni Outreach'],
                            purposeOptions: [
                              'Alumni Outreach',
                              'Contract Processing',
                              'Customer onboarding',
                              'Customer orders',
                              'Support support',
                              'Email management',
                              'HR Onboarding',
                              'Marketing event activities',
                              'Payroll Management',
                              'Sales lead generation',
                              'Software development',
                              'Staff administration'
                            ],
                            selection: [],
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    retentionPeriod: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a17630d',
                            description: '',
                            descriptionDeleted: false,
                            label: 'Select Retention Period',
                            retentionPolicy: 'Forever',
                            retentionPolicyOptions: ['Not Applicable', 'Forever'],
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    dataLink: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a17630c',
                            processingStage: 'Collection',
                            associatedDatasources: {
                              sourceDatasource: [],
                              targetDatasource: []
                            },
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    datatransferDetails: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a17630e',
                            details: [
                              {
                                description: '',
                                descriptionDeleted: false,
                                name: '',
                                address: '',
                                safeguard: '',
                                categoryOfRecipients: '',
                                transferRetentionPeriod: '',
                                dataTransferRecipients: null,
                                nameLabel: 'Name',
                                addressLabel: 'Address',
                                safeguardLabel: 'Safeguard',
                                safeguardOptions: [
                                  'Server security',
                                  'Raise awareness',
                                  'Detect spam, malware and virus attacks',
                                  'Update softwares regularly',
                                  'Control access to data and systems',
                                  'Automated data removal and archival'
                                ],
                                categoryOfRecipientsLabel: 'Category of recipients',
                                transferRetentionPeriodLabel:
                                  'After how long is the data transferred',
                                transferRetentionPeriodOptions: ['Immediately']
                              }
                            ],
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    safetyMeasure: {
                      edges: [
                        {
                          node: {
                            topicId: '656049ccae10b6b77a17630f',
                            description: 'ewq',
                            descriptionDeleted: false,
                            safetyMeasures: 'Raise awareness',
                            label: 'Select safety measure',
                            safetyMeasureOptions: [
                              'Server security',
                              'Raise awareness',
                              'Detect spam, malware and virus attacks',
                              'Update softwares regularly',
                              'Control access to data and systems',
                              'Automated data removal and archival'
                            ],
                            questions: {
                              edges: []
                            }
                          }
                        }
                      ]
                    },
                    customTopic: {
                      edges: []
                    }
                  }
                }
              }
            ]
          }
        }
      }
    ]
  }
}
