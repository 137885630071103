import { FileShare, FileShareParams } from './fileSharesSlice'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { LIMIT_DEFAULT } from '../../constants'
import { gql } from 'graphql-request'

export const querySharesList = (params: FileShareParams): string => {
  const { page, pageSize = LIMIT_DEFAULT, datasourceId, filters } = params

  const cursor = getAfterCursor(page, pageSize)

  let booleanFilter = ''
  if (filters?.booleanFilter?.length) {
    booleanFilter = `, booleanFilter: ${parameterizeArrayofObjects(filters.booleanFilter)}`
  }

  return gql`
    {
      fileShare(first: ${pageSize}, after: "${cursor}", datasourceId: "${datasourceId}"${booleanFilter}) {
        count
        edges {
          node {
            id
            name
            objects {
              count
            }
            impactedObjects: objects(booleanFilter: [{key: IS_RISKY, value: true}]) {
              count
            }
          }
        }
      }
    }
  `
}

export const mapQuerySharesList = (raw: any): { list: FileShare[]; total: number } => {
  try {
    const list = raw.fileShare.edges.map(({ node: share }) => ({
      id: share.id,
      name: share.name,
      objectsCount: share.objects?.count | 0,
      impactedObjectsCount: share.impactedObjects?.count | 0
    }))

    const total = raw.fileShare.count || 0
    return { list, total }
  } catch (error) {
    console.error(error)
    throw error
  }
}
