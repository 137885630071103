import { RootState } from '../../rootReducer'
import { sortByOrder } from '../../utils/sortUtil'
import { createSelector } from 'reselect'

const databasesAllListSelector = (state: RootState) => state.databases.all.list
const databasesAllColumnSelector = (state: RootState) => state.databases.all.sort.column
const databasesAllDirectionSelector = (state: RootState) => state.databases.all.sort.direction

const databasesSensitiveListSelector = (state: RootState) => state.databases.sensitive.list
const databasesSensitiveColumnSelector = (state: RootState) => state.databases.sensitive.sort.column
const databasesSensitiveDirectionSelector = (state: RootState) =>
  state.databases.sensitive.sort.direction

const databasesRiskListSelector = (state: RootState) => state.databases.risk.list
const databasesRiskColumnSelector = (state: RootState) => state.databases.risk.sort.column
const databasesRiskDirectionSelector = (state: RootState) => state.databases.risk.sort.direction

export const getDatabasesAllListSorted = createSelector(
  [databasesAllListSelector, databasesAllColumnSelector, databasesAllDirectionSelector],
  (list, column, direction) => {
    return list?.length && column ? sortByOrder(list, column, direction) : list
  }
)

export const getDatabasesSensitiveListSorted = createSelector(
  [
    databasesSensitiveListSelector,
    databasesSensitiveColumnSelector,
    databasesSensitiveDirectionSelector
  ],
  (list, column, direction) => {
    return list?.length && column ? sortByOrder(list, column, direction) : list
  }
)

export const getDatabasesRiskListSorted = createSelector(
  [databasesRiskListSelector, databasesRiskColumnSelector, databasesRiskDirectionSelector],
  (list, column, direction) => {
    return list?.length && column ? sortByOrder(list, column, direction) : list
  }
)

const databasesConnectionStatsListSelector = (state: RootState) =>
  state.databases.connectionStats.list
const databasesConnectionStatsColumnSelector = (state: RootState) =>
  state.databases.connectionStats.sort.column
const databasesConnectionStatsDirectionSelector = (state: RootState) =>
  state.databases.connectionStats.sort.direction

export const getDatabasesConnectionStatsListSorted = createSelector(
  [
    databasesConnectionStatsListSelector,
    databasesConnectionStatsColumnSelector,
    databasesConnectionStatsDirectionSelector
  ],
  (list, column, direction) => {
    return list?.length && column ? sortByOrder(list, column, direction) : list
  }
)
