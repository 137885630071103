import { isNumeric } from './stringUtil'
import { SORT_ORDER } from '../interfaces'

export interface SortParams {
  column: string
  direction: SORT_ORDER
}

export const defaultSortParams: Readonly<SortParams> = {
  column: '',
  direction: SORT_ORDER.DESC
}

export const getSortDirection = (currentSort: SortParams, column: string): SortParams => {
  if (currentSort.column === column) {
    const direction = currentSort.direction === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC

    return { column, direction }
  }

  return { column, direction: defaultSortParams.direction }
}

export const sortByOrder = <T>(array: Array<T>, prop: string, order: SORT_ORDER): Array<T> => {
  return [...array].sort((a, b) => {
    if (isNumeric(a[prop]) && isNumeric(b[prop])) {
      return order === SORT_ORDER.DESC ? +b[prop] - +a[prop] : +a[prop] - +b[prop]
    }

    const keyA = a[prop] + ''
    const keyB = b[prop] + ''

    return order === SORT_ORDER.DESC ? keyB.localeCompare(keyA) : keyA.localeCompare(keyB)
  })
}

export const sortByKey = <T>(list: T[], key: string) =>
  list.sort((obj1, obj2) =>
    obj1[key].toLowerCase() < obj2[key].toLowerCase()
      ? -1
      : Number(obj1[key].toLowerCase() > obj2[key].toLowerCase())
  )
