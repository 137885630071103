import service from '../../services/api/apiService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface RulesState {
  total: number | null
}

export const initialState: RulesState = {
  total: null
}

export const fetchRules = createAsyncThunk('rules/fetchRules', async () => await service.getRules())

const rulesSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRules.fulfilled, (state, action) => {
      state.total = action.payload.total
    })
    builder.addCase(fetchRules.rejected, (state) => {
      state.total = 0
    })
  }
})

export default rulesSlice.reducer
