import { ConsentManagementConsent } from './../consentManagementSlice'

import { ConsentOptStatus } from '../constants'
import { gql } from 'graphql-request'

export const formatDate = (date, isEnd = false) => {
  if (isEnd) {
    date.setUTCHours(23, 59, 59, 999)
  } else {
    date.setUTCHours(0, 0, 0, 0)
  }
  return date.toISOString().split('.')[0] + 'Z'
}

export type DateRange = {
  name: string
  start: string
  end: string
}

export enum DateRangeOption {
  Last4Weeks = 'Last4Weeks',
  Last4Months = 'Last4Months',
  Last12Months = 'Last12Months',
  Quarterly = 'Quarterly',
  Yearly = 'Yearly'
}

export type DateRangeChartData = {
  name: string
  totalOptIns: number
  totalOptOuts: number
  totalConsents: number
  additionalData?: {
    [ConsentManagementConsent.optIn]?: {
      label: string
      value: number
      icon?: string
    }[]
    [ConsentManagementConsent.optOut]?: {
      label: string
      value: number
      icon?: string
    }[]
  }
  dateRange?: DateRange
}

export const getWeeklyDateRanges = (): DateRange[] => {
  const now = new Date()
  const result: DateRange[] = []
  const dayOfWeek = now.getDay() // 0 (Sunday) to 6 (Saturday)

  // Function to format date ranges with "week" prefix
  const formatRange = (start: Date, end: Date): string => {
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' }
    const startDate = start.toLocaleDateString('en-US', options)
    const endDate = end.toLocaleDateString('en-US', options)
    const startFormatted = `${startDate.split(' ')[1]} ${startDate.split(' ')[0]}`
    const endFormatted = `${endDate.split(' ')[1]} ${endDate.split(' ')[0]}`
    return `week ${startFormatted} - ${endFormatted}`
  }

  // Current week
  const currentWeekStart = new Date(now)
  currentWeekStart.setDate(now.getDate() - dayOfWeek)
  const currentWeekEnd = new Date(currentWeekStart)
  currentWeekEnd.setDate(currentWeekStart.getDate() + 6)

  result.push({
    name: formatRange(currentWeekStart, currentWeekEnd),
    start: formatDate(currentWeekStart),
    end: formatDate(currentWeekEnd, true)
  })

  // Last week
  const lastWeekEnd = new Date(currentWeekStart)
  lastWeekEnd.setDate(currentWeekStart.getDate() - 1)
  const lastWeekStart = new Date(lastWeekEnd)
  lastWeekStart.setDate(lastWeekEnd.getDate() - 6)

  result.push({
    name: formatRange(lastWeekStart, lastWeekEnd),
    start: formatDate(lastWeekStart),
    end: formatDate(lastWeekEnd, true)
  })

  // Last 3 weeks
  for (let i = 1; i <= 2; i++) {
    const weekEnd = new Date(lastWeekStart)
    weekEnd.setDate(lastWeekStart.getDate() - 7 * i + 6)
    const weekStart = new Date(weekEnd)
    weekStart.setDate(weekEnd.getDate() - 6)

    result.push({
      name: formatRange(weekStart, weekEnd),
      start: formatDate(weekStart),
      end: formatDate(weekEnd, true)
    })
  }

  return result
}

export const getMonthlyDateRanges = (months: number): DateRange[] => {
  const now = new Date()
  const result: DateRange[] = []

  for (let i = 0; i < months; i++) {
    const end = new Date(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth() - i + 1, 0, 23, 59, 59, 999)
    )
    const start = new Date(Date.UTC(end.getUTCFullYear(), end.getUTCMonth(), 1, 0, 0, 0, 0))

    result.push({
      name: `${start.toLocaleString('default', {
        month: 'short',
        timeZone: 'UTC'
      })} ${start.getUTCFullYear()}`,
      start: formatDate(start),
      end: formatDate(end, true)
    })
  }
  return result
}

export const buildCMDateRangeGraphQLQuery = (
  dateRanges: DateRange[],
  datasourceTypes: string[]
) => {
  return gql`{
    ${dateRanges
      .map(
        ({ name, start, end }) => `
      ${name.replace(/[^a-zA-Z0-9]/g, '_')}optIn: consentHistoryGroupByRequestId(
        dateFilter: {
          key: TIMESTAMP
          start: "${start}"
          end: "${end}"
        }
        filter: { key: OPT_STATUS, values: ["${ConsentOptStatus.Subscribed}"] }
      ) {
        count
      }
      ${name.replace(/[^a-zA-Z0-9]/g, '_')}optOut: consentHistoryGroupByRequestId(
        dateFilter: {
          key: TIMESTAMP
          start: "${start}"
          end: "${end}"
        }
        filter: { key: OPT_STATUS, values: ["${ConsentOptStatus.UnSubscribed}"] }
      ) {
        count
      }
      ${name.replace(/[^a-zA-Z0-9]/g, '_')}TotalConsents: consentHistoryGroupByRequestId(
        dateFilter: {
          key: TIMESTAMP
          start: "${start}"
          end: "${end}"
        }
      ) {
        count
      }
      ${name.replace(/[^a-zA-Z0-9]/g, '_')}preferenceCenter: preferenceCenter {
        edges {
          node {
            id
            name
            optInConsent: consentHistoryGroupByRequestId(
              dateFilter: {
                key: TIMESTAMP
                start: "${start}"
                end: "${end}"
              }
              filter: { key: OPT_STATUS, values: ["${ConsentOptStatus.Subscribed}"] }
            ) {
              count
            }
            optOutConsent: consentHistoryGroupByRequestId(
              dateFilter: {
                key: TIMESTAMP
                start: "${start}"
                end: "${end}"
              }
              filter: { key: OPT_STATUS, values: ["${ConsentOptStatus.UnSubscribed}"] }
            ) {
              count
            }
          }
        }
      }
      ${
        datasourceTypes && name.replace(/[^a-zA-Z0-9]/g, '_')
      }datasource: consentManagementSettings {
        edges {
          node {
            datasource {
              edges {
                node {
                  id
                  name
                  type
                  optInConsent: consentHistory(
                    dateFilter: {
                      key: TIMESTAMP
                      start: "${start}"
                      end: "${end}"
                    }
                    filter: [
                      { key: OPT_STATUS, values: ["${ConsentOptStatus.Subscribed}"] },
                      ${
                        datasourceTypes.length
                          ? `{ key: CREATED_BY, values: ${JSON.stringify(datasourceTypes)} }`
                          : ``
                      }
                    ]
                  ) {
                    count
                  }
                  optOutConsent: consentHistory(
                    dateFilter: {
                      key: TIMESTAMP
                      start: "${start}"
                      end: "${end}"
                    }
                    filter: [
                      { key: OPT_STATUS, values: ["${ConsentOptStatus.UnSubscribed}"] },
                      ${
                        datasourceTypes.length
                          ? `{ key: CREATED_BY, values: ${JSON.stringify(datasourceTypes)} }`
                          : ``
                      }
                    ]
                  ) {
                    count
                  }
                }
              }
            }
          }
        }
      }
    `
      )
      .join('')}}`
}

export const mapCMDateRangeGraphQLResponse = (response: any, dateRanges?: DateRange[]) => {
  const result: DateRangeChartData[] = []
  const data = response

  let count = 0

  Object.keys(data).forEach((key) => {
    if (key.includes('optIn')) {
      // Extract name and format for weekly ranges
      let name = key.replace('optIn', '').replace(/_/g, ' ').trim()
      if (name.startsWith('week')) {
        name = name
          .replace('week', '')
          .trim()
          .replace(/(\d+\s\w+)\s+(\d+\s\w+)/, '$1-$2')
      } else {
        name = name.replace(/(\d+\s\w+)\s+(\d+\s\w+)/, '$1-$2')
      }

      const optIns = data[key].count
      const optOuts = data[`${key.replace('optIn', 'optOut')}`]?.count || 0
      const totalConsents = data[`${key.replace('optIn', 'TotalConsents')}`]?.count || 0

      const preferenceCenterKey = `${key.replace('optIn', 'preferenceCenter')}`
      const preferenceCenterData = data?.[preferenceCenterKey]?.edges || []

      const additionalData: {
        [ConsentManagementConsent.optIn]: { label: string; value: number; icon?: string }[]
        [ConsentManagementConsent.optOut]: { label: string; value: number; icon?: string }[]
      } = {
        [ConsentManagementConsent.optIn]: [],
        [ConsentManagementConsent.optOut]: []
      }

      preferenceCenterData?.forEach((edge) => {
        if (edge.node.optInConsent.count) {
          additionalData?.[ConsentManagementConsent.optIn].push({
            label: edge.node.name,
            value: edge.node.optInConsent.count
          })
        }
        if (edge.node.optOutConsent.count) {
          additionalData?.[ConsentManagementConsent.optOut].push({
            label: edge.node.name,
            value: edge.node.optOutConsent.count
          })
        }
      })

      const datasourceKey = `${key.replace('optIn', 'datasource')}`
      const datasourceData = data?.[datasourceKey]?.edges || []

      datasourceData.forEach((edge) => {
        const sources = edge.node.datasource.edges || []
        sources.forEach((source) => {
          if (source.node.optInConsent.count) {
            additionalData[ConsentManagementConsent.optIn].push({
              label: source.node.name,
              value: source.node.optInConsent.count,
              icon: source.node.type
            })
          }
          if (source.node.optOutConsent.count) {
            additionalData[ConsentManagementConsent.optOut].push({
              label: source.node.name,
              value: source.node.optOutConsent.count,
              icon: source.node.type
            })
          }
        })
      })

      if (dateRanges) {
        result.push({
          name,
          totalConsents: totalConsents,
          totalOptIns: optIns,
          totalOptOuts: optOuts,
          additionalData,
          dateRange: dateRanges[count]
        })
      } else {
        result.push({
          name,
          totalConsents: totalConsents,
          totalOptIns: optIns,
          totalOptOuts: optOuts,
          additionalData
        })
      }

      count++
    }
  })

  return result
}

export const buildCMRegionGraphQLQuery = (regions: string[], datasourceTypes: string[]) => {
  if (!regions.length) return ''
  return gql`{
    ${regions
      .map(
        (name) => `
      ${name.replace(/[^a-zA-Z0-9]/g, '_')}optIn: consentHistoryGroupByRequestId(
        filter: [{ key: OPT_STATUS, values: ["${
          ConsentOptStatus.Subscribed
        }"]},{key: LOCATION, values: ["${name}"] }]
      ) {
        count
      }
      ${name.replace(/[^a-zA-Z0-9]/g, '_')}optOut: consentHistoryGroupByRequestId(

        filter: [{ key: OPT_STATUS, values: ["${
          ConsentOptStatus.UnSubscribed
        }"]},{key: LOCATION, values: ["${name}"] }]
      ) {
        count
      }
      ${name.replace(/[^a-zA-Z0-9]/g, '_')}TotalConsents: consentHistoryGroupByRequestId(
        filter: [{key: LOCATION, values: ["${name}"] }]
      ) {
        count
      }
      ${name.replace(/[^a-zA-Z0-9]/g, '_')}preferenceCenter: preferenceCenter {
        edges {
          node {
            id
            name
            optInConsent: consentHistoryGroupByRequestId(

              filter: [{ key: OPT_STATUS, values: ["${
                ConsentOptStatus.Subscribed
              }"]},{key: LOCATION, values: ["${name}"] }]
            ) {
              count
            }
            optOutConsent: consentHistoryGroupByRequestId(

              filter: [{ key: OPT_STATUS, values: ["${
                ConsentOptStatus.UnSubscribed
              }"]},{key: LOCATION, values: ["${name}"] }]
            ) {
              count
            }
          }
        }
      }
      ${
        datasourceTypes && name.replace(/[^a-zA-Z0-9]/g, '_')
      }datasource: consentManagementSettings {
        edges {
          node {
            datasource {
              edges {
                node {
                  id
                  name
                  type
                  optInConsent: consentHistory(

                    filter: [
                      { key: OPT_STATUS, values: ["${ConsentOptStatus.Subscribed}"] },
                      ${
                        datasourceTypes.length
                          ? `{ key: CREATED_BY, values: ${JSON.stringify(datasourceTypes)} }`
                          : ``
                      },
                       { key: LOCATION, values: ["${name}"] }
                    ]
                  ) {
                    count
                  }
                  optOutConsent: consentHistory(

                    filter: [
                      { key: OPT_STATUS, values: ["${ConsentOptStatus.UnSubscribed}"] },
                      ${
                        datasourceTypes.length
                          ? `{ key: CREATED_BY, values: ${JSON.stringify(datasourceTypes)} }`
                          : ``
                      },
                       { key: LOCATION, values: ["${name}"] }
                    ]
                  ) {
                    count
                  }
                }
              }
            }
          }
        }
      }
    `
      )
      .join('')}}`
}
