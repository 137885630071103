import './accessDenied.less'
import SVGSecurity from '../../assets/inlineSvg/icon-security.svg'
import { FormattedMessage } from 'react-intl'
import React from 'react'

const AccessDenied = (): React.ReactElement => {
  return (
    <div className="access-denied" data-test-id="access-denied">
      <div className="denied-content">
        <div className="xs-mb-32">
          <SVGSecurity />
        </div>
        <p className="error-code">403</p>
        <p className="error-title">
          <FormattedMessage id="page.accessDenied.title" />
        </p>
        <p className="error-desc">
          <FormattedMessage id="page.accessDenied.help" />
        </p>
      </div>
    </div>
  )
}

export default AccessDenied
