import { PrivacyAssessmentsWidget, PrivacyRightsWidget } from './privacyOpsWidgetsSlice'
import { parameterizeArrayofObjects } from '../../../../../utils/graphqlUtil'
import { mapDsrStatusToApi } from '../../../../dsr/requestList/dsrRequestSlice'
import { PiaProcessFilter, ProcessReviewStatus } from '../../../../../constants'
import { ConsentOptStatus } from '../../../../consentManagement/constants'
import { gql } from 'graphql-request'

export const queryPrivacyOpsWidgetDsrSummary = (): string => {
  const dsrOpenedParams = parameterizeArrayofObjects([
    { key: 'STATUS', values: mapDsrStatusToApi.inProgress.split(',') }
  ])
  return gql`
    {
      dsrTotal: dsrRequest {
        count
      }
      dsrOpen: dsrRequest(filter: ${dsrOpenedParams}){
        count
      }
    }
  `
}
export const mapQueryPrivacyOpsWidgetDsrSummary = (raw: any): PrivacyRightsWidget => {
  return {
    dsrTotal: raw.dsrTotal?.count || 0,
    dsrOpen: raw.dsrOpen?.count || 0
  }
}

export const queryPrivacyOpsWidgetSummary = (): string => {
  return gql`
    {
      piaTotal: process(booleanFilter: [{ key: IS_SYSTEM_DEFINED, value: false }]) {
        count
      }
      piaNeedReview: process(
          filter: [{key: ASSESSMENT_REVIEW, values: ["${PiaProcessFilter.reviewPending}"]}],
          booleanFilter: [{ key: IS_SYSTEM_DEFINED,value: false }]
      ) {
        count
      }
      ropaTotal: ropaProcess(booleanFilter: [{ key: IS_SYSTEM_DEFINED, value: false }]) {
        count
      }
      ropaNeedReview: ropaProcess(
        filter: [{key: REVIEW_STATUS, values: ["${ProcessReviewStatus.reviewPastDue}", "${ProcessReviewStatus.reviewDue}"]}],
        booleanFilter: [{ key: IS_SYSTEM_DEFINED,value: false }]
      ) {
        count
      }
    }
  `
}
export const mapQueryPrivacyOpsWidgetSummary = (raw: any): PrivacyAssessmentsWidget => {
  return {
    piaAssessmentsTotal: raw.piaTotal?.count || 0,
    piaAssessmentsNeedReview: raw.piaNeedReview?.count || 0,
    ropaProcessesTotal: raw.ropaTotal?.count || 0,
    ropaProcessesNeedReview: raw.ropaNeedReview?.count || 0
  }
}

export const queryConsentManagementWidgetSummary = (): string => {
  return gql`
    query consentManagementTopSummary {
      optInConsents: consentHistoryGroupByRequestId(filter: [{ key: OPT_STATUS, values: ["${ConsentOptStatus.Subscribed}"] }]) {
        count
      }
      optOutConsents: consentHistoryGroupByRequestId(filter: [{ key: OPT_STATUS, values: ["${ConsentOptStatus.UnSubscribed}"] }]) {
        count
      }
      totalConsents: consentHistoryGroupByRequestId {
        count
      }
    }
  `
}

export const mapConsentManagementWidgetSummary = (raw: any) => {
  return {
    optInConsentsCount: raw?.optInConsents?.count || 0,
    optOutConsentsCount: raw?.optOutConsents?.count || 0,
    totalConsentsCount: raw?.totalConsents?.count || 0
  }
}
