import { notUndefined } from './common'
import { IPolicyType } from '../interfaces'
import { IProfileFilter } from '../features/policies/FilterCreateEdit'
import {
  FIQLOperatorsMapper,
  IMAGE_FILENAME_CCPA,
  IMAGE_FILENAME_GDPR,
  IMAGE_FILENAME_HIPAA,
  POLICY_TYPES_MAPPER,
  REGULATIONS
} from '../constants'

export const mapListToDropdownOptions = (
  list: { id: string; name: string }[]
): Array<{
  text: string
  key: string
  value: string
}> => list.map(({ id, name }) => ({ text: name, key: id, value: id }))

export const mapTextOptionsToDropdownOptions = (
  list: string[]
): Array<{
  text: string
  key: string
  value: string
}> => list.map((item) => ({ text: item, key: item, value: item }))

export const convertConditionToFIQL = (filter: IProfileFilter): string => {
  let conditionHumanReadable = ''
  let combinator = ''
  let condition = ''

  filter.info.forEach((info) => {
    Object.keys(FIQLOperatorsMapper).forEach((key) => {
      if (FIQLOperatorsMapper[key] === info.condition) {
        condition = key
      }

      if (info.combinator && FIQLOperatorsMapper[key] === info.combinator) {
        combinator = key
      }
    })
    conditionHumanReadable += `${info.column}${condition}${info.value}${combinator}`
    combinator = ''
    condition = ''
  })

  return conditionHumanReadable
}

// sorts the policyTypes in same order as POLICY_TYPES_MAPPER enum
export const getSortedPolicyTypes = (policyTypes: IPolicyType[]): IPolicyType[] => {
  return Object.keys(POLICY_TYPES_MAPPER)
    .map((typeName) => policyTypes.find(({ name }) => typeName === name))
    .filter(notUndefined)
}

export const getRegulationImage = (type: REGULATIONS): string => {
  let image = ''

  if (type === REGULATIONS.CCPA) {
    image = IMAGE_FILENAME_CCPA
  } else if (type === REGULATIONS.GDPR) {
    image = IMAGE_FILENAME_GDPR
  } else if (type === REGULATIONS.HIPAA) {
    image = IMAGE_FILENAME_HIPAA
  }
  return image
}
