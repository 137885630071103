import service from '../../services/api/apiService'
import { SentimentAnalysisItem } from '../../services/api/apiTypes'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface SentimentAnalysisState {
  list: Array<SentimentAnalysisItem> | null
}

export const initialState: SentimentAnalysisState = {
  list: null
}

export const fetchSentimentAnalysisList = createAsyncThunk(
  'sentimentAnalysis/fetchSentimentAnalysisList',
  async () => await service.getSentimentAnalysisList()
)

const sentimentAnalysisSlice = createSlice({
  name: 'sentimentAnalysis',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSentimentAnalysisList.fulfilled, (state, action) => {
      state.list = action.payload
    })
    builder.addCase(fetchSentimentAnalysisList.rejected, (state) => {
      state.list = []
    })
  }
})

export default sentimentAnalysisSlice.reducer
