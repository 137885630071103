import PrintPageHeader from '../components/header'
import Table from '../../components/Table/table'
import RegularColumn from '../../components/Table/regularColumn'
import TableLegend from '../../components/TableLegend'
import LoaderView from '../../components/Loader/loader'
import SvgNoData from '../../assets/inlineSvg/empty_sensitiveData_print.svg'
import { DataSourcesOverview } from '../printSlice'
import { RootState } from '../../rootReducer'
import { IS_ALL, PAGE, URL_ATTRIBUTES } from '../../constants'
import {
  ACTION_ATTRIBUTES_TABLE_FETCH,
  AttributeItem,
  IGetAttributesFilterParams,
  IGetAttributesTableListParams,
  fetchAttributesTableSensitivitySorted,
  resetAttributesAll
} from '../../features/attributes/attributesSlice'
import useUrlContext from '../../hooks/useUrlContext'
import { getAttributesListAllSorted } from '../../features/attributes/attributesSelector'
import { getShowLoader } from '../../reducers/requestReducer'
import { sortAttributesByInstanceCountAndSensitivity } from '../../utils/attributesUtil'
import { SORT_ORDER } from '../../interfaces'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import React, { useEffect } from 'react'

interface IProps {
  overview?: DataSourcesOverview
  list?: AttributeItem[]
  showLoader: boolean
  totalAttributes?: number
  resetAttributesAll: () => void
  fetchAttributesTableSensitivitySorted: (
    filters: IGetAttributesTableListParams & IGetAttributesFilterParams
  ) => void
}

const PrintAttributeListPage = ({
  overview,
  showLoader,
  totalAttributes = 0,
  list = [],
  resetAttributesAll,
  fetchAttributesTableSensitivitySorted
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const context = useUrlContext({ pageName: URL_ATTRIBUTES })
  const page = context.pageFilters[IS_ALL][PAGE]

  useEffect(() => {
    /**No pagination and only non instance attributes for print */
    fetchAttributesTableSensitivitySorted({ page, print: true, hasInstances: true })

    return () => resetAttributesAll()
  }, [page, resetAttributesAll, fetchAttributesTableSensitivitySorted])

  const legendText = intl.formatMessage({ id: 'table.legend.attributes' })
  const columns = [
    {
      title: intl.formatMessage({ id: 'attributes.list.attribute' }),
      dataKey: 'name',
      cellRenderer: function TitleRenderer({ name }) {
        return (
          <RegularColumn
            title={intl.formatMessage({ id: 'attributes.list.attribute' })}
            content={name}
          />
        )
      }
    },
    {
      title: intl.formatMessage({ id: 'attributes.list.sensitivity' }),
      dataKey: 'sensitivityLabel',
      cellRenderer: function TitleRenderer({ sensitivityLabel = '' }) {
        return (
          <RegularColumn
            title={intl.formatMessage({ id: 'attributes.list.sensitivity' })}
            content={sensitivityLabel.toLowerCase()}
            className="text-capitalize sensitivity-level"
          />
        )
      }
    },
    {
      title: intl.formatMessage({ id: 'attributes.list.noOfAttributeInstances' }),
      dataKey: 'instanceCount',
      className: 'number',
      cellRenderer: function TitleRenderer({ instanceCount = 0 }) {
        return (
          <RegularColumn
            title={intl.formatMessage({ id: 'attributes.list.noOfAttributeInstances' })}
            content={intl.formatNumber(instanceCount, { notation: 'compact' }) || 0}
            className="number"
          />
        )
      }
    },
    {
      title: intl.formatMessage({ id: 'attributes.list.noOfDataSources' }),
      dataKey: 'datasourceCounts',
      className: 'number',
      cellRenderer: function TitleRenderer({ dataSourcesCount = 0 }) {
        return (
          <RegularColumn
            title={intl.formatMessage({ id: 'attributes.list.noOfDataSources' })}
            content={intl.formatNumber(dataSourcesCount, { notation: 'compact' }) || 0}
            className="number"
          />
        )
      }
    }
  ]

  const listSorted = sortAttributesByInstanceCountAndSensitivity(
    list.map((item) => ({
      ...(item as any),
      instanceCount: item.attributesInstanceCount || 0,
      sensitivityLabel: item.sensitivityLabel || ''
    })),
    SORT_ORDER.DESC
  )

  return (
    <div className="print-page attribute-page" data-test-id="print-attribute-list-page" id="pii">
      <PrintPageHeader text={<strong>Sensitive Data Detected</strong>} />
      <div className="risky-container">
        <div className="count">{overview?.attributesCount || 0}</div>
        &nbsp; attributes found out of which &nbsp;
        <div className="count risky">{overview?.attributesPiiCount || 0}</div>
        &nbsp; {(overview?.attributesPiiCount || 0) > 1 ? 'are' : 'is'} Highly Sensitive
      </div>
      <div className="text-tag">
        <div className="label">Tracking {overview?.attributeEnabledTotal || 0} attributes</div>
      </div>
      <LoaderView showLoader={showLoader} />
      {list.length === 0 ? (
        <div className="no-data">
          <SvgNoData />
        </div>
      ) : (
        <div className="attributes attributes-set print">
          <TableLegend
            total={totalAttributes}
            showing={list.length}
            page={page}
            name={legendText}
            className="no-outer-spaces"
          />
          <Table
            isCellRenderer
            columns={columns}
            data={listSorted}
            scrollable={false}
            tableProps={{
              classes: {
                table: 'data-list-table',
                thead: 'mobile hidden'
              }
            }}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  overview: state.print.overview,
  list: getAttributesListAllSorted(state),
  totalAttributes: state.attributes.attributes.total,
  showLoader: getShowLoader(ACTION_ATTRIBUTES_TABLE_FETCH)
})

const mapDispatchToProps = {
  resetAttributesAll,
  fetchAttributesTableSensitivitySorted
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintAttributeListPage)
