import { RootState } from '../../rootReducer'
import { sortByOrder } from '../../utils/sortUtil'
import { createSelector } from 'reselect'

const dataSourcesAllListSelector = (state: RootState) => state.dataSources.all.list
const dataSourcesAllColumnSelector = (state: RootState) => state.dataSources.all.sort.column
const dataSourcesAllDirectionSelector = (state: RootState) => state.dataSources.all.sort.direction

export const getDataSourcesAllListSorted = createSelector(
  [dataSourcesAllListSelector, dataSourcesAllColumnSelector, dataSourcesAllDirectionSelector],
  (list, column, direction) => {
    return list?.length && column ? sortByOrder(list, column, direction) : list
  }
)
export const getSourceofTruthDataSources = createSelector(
  [dataSourcesAllListSelector, dataSourcesAllColumnSelector, dataSourcesAllDirectionSelector],
  (list) => {
    return list?.filter((ds) => !!ds.isSourceOfTruth)
  }
)
/** Data sources by entity selectors */
const dataSourceByEntityListSelector = (state: RootState) => state.dataSources.listByEntity.list
const dataSourceByEntityColumnSelector = (state: RootState) =>
  state.dataSources.listByEntity.sort.column
const dataSourceByEntityDirectionSelector = (state: RootState) =>
  state.dataSources.listByEntity.sort.direction

export const getDataSourcesByEntityListSorted = createSelector(
  [
    dataSourceByEntityListSelector,
    dataSourceByEntityColumnSelector,
    dataSourceByEntityDirectionSelector
  ],
  (list, column, direction) => {
    return list && column ? sortByOrder(list, column, direction) : list
  }
)
