import apiService from '../../../services/api/apiService'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export interface EmailNotification {
  id?: string
  domain?: string
  emailIds: string[]
}

export interface EmailNotificationSettings {
  settings: EmailNotification[]
}

export const ACTION_EMAIL_NOTIFICATIONS_SETTINGS_SAVE = 'emailNotifications/settingsSave'
export const saveEmailNotificationSettings = createAsyncThunk(
  ACTION_EMAIL_NOTIFICATIONS_SETTINGS_SAVE,
  async (data: EmailNotification[]) => {
    await apiService.postDomainNotificationSettings(data)
  }
)

const initialState: EmailNotificationSettings = {
  settings: []
}

const settingsSlice = createSlice({
  name: 'emailNotificationSettings',
  initialState: initialState,
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload
    }
  }
})

export default settingsSlice.reducer
