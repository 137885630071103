import {
  DocumentsScannedParams,
  ScannedDataGraphItem,
  ScannedDataParams,
  SCANNED_TYPE
} from './scannedDataSlice'
import { DATA_SOURCE_ID } from '../../constants'
import { gql } from 'graphql-request'

export const queryScannedDataGraph = (params: ScannedDataParams): string => {
  const dataSourceFilter = params[DATA_SOURCE_ID]
    ? `, datasourceId: "${params[DATA_SOURCE_ID]}"`
    : ''

  return gql`
    {
      attributes: graphSeries(type: ${SCANNED_TYPE.attribute}, range: ${params.range}, ${dataSourceFilter}) {
        timestamp
        value
      }
      objects: graphSeries(type: ${SCANNED_TYPE.document}, range: ${params.range}, ${dataSourceFilter}) {
        timestamp
        value
      }
    }
  `
}

// TODO: add type for graphql response
export const mapQueryScannedDataGraph = (
  raw: any
): { attributes: ScannedDataGraphItem[]; documents: ScannedDataGraphItem[] } => {
  return { attributes: raw.attributes, documents: raw.objects }
}

export const queryTotalDataScanned = (params: DocumentsScannedParams): string => {
  const dataSourceFilter = params[DATA_SOURCE_ID] ? `(id: "${params[DATA_SOURCE_ID]}")` : ''

  return gql`
    {
      datasources${dataSourceFilter}{
        edges{
          node{
            totalBytes
            totalSkippedBytes
            totalProcessedBytes
          }
        }
      }
    }
  `
}

export const mapQueryTotalDataScanned = (raw: any): { total: number } => {
  const processedBytes = raw?.datasources?.edges.reduce((acc, { node }) => {
    return acc + parseInt(node?.totalBytes) || 0
  }, 0)
  return { total: processedBytes }
}
