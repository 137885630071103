import React from 'react'
import { LeftMenu } from '@lightbeamai/design-system'

const LightBeamDesignSystem: React.FC = () => {
  return (
    <>
      <LeftMenu
        items={[
          {
            key: 'Summary',
            label: 'Summary'
          },
          {
            key: 'Databases',
            label: 'Databases'
          },
          {
            key: 'Tables & Columns',
            label: 'Tables & Columns'
          },
          {
            key: 'Table Cluster',
            label: 'Table Cluster'
          },
          {
            key: 'Entities',
            label: 'Entities'
          },
          {
            key: 'Attributes',
            label: 'Attributes'
          },
          {
            key: 'Configuration',
            label: 'Configuration'
          }
        ]}
        collapsed={true}
      />
    </>
  )
}

export default LightBeamDesignSystem
