import '../print.less'
import React from 'react'
import dayjs from 'dayjs'

interface IProps {
  isTitlePage?: boolean
  page?: number
  date: string
  unbranded?: boolean
}

const PrintPageFooter = ({
  isTitlePage = false,
  page,
  date,
  unbranded = false
}: IProps): React.ReactElement => {
  const todayDate = dayjs(date).format('MMMM D, YYYY')

  return (
    <div className={`footer ${isTitlePage ? 'title-styles' : ''}`} data-test-id="print-page-footer">
      {isTitlePage && !unbranded && (
        <div className="icon-box">
          <img src="../assets/images/lightbeam-logo2.svg" />
        </div>
      )}
      <div className="divider" />
      <div className="row">
        <div className="generated-at">Generated on {todayDate}</div>
        <div>Confidential Document{page && ', Page ' + page}</div>
      </div>
    </div>
  )
}

export default PrintPageFooter
