import { RootState } from '../../../../rootReducer'
import { DATASOURCE_CONNECTION } from '../../../../constants'
import { setConnectionTest, testDataSourceConnection } from '../../dataSourcesSlice'
import { DataSource } from '../../../../services/api/apiTypes'
import { Button, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import './testConnectionButton.less'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import { CheckCircle as IconCheckCircle, WarningCircle as IconWarningCircle } from 'phosphor-react'
import React from 'react'

interface iProps {
  data: DataSource
  disabled?: boolean
  testConnection: DATASOURCE_CONNECTION | null
  testDataSourceConnection: (config: DataSource) => void
  setConnectionTest: (status: DATASOURCE_CONNECTION | null) => void
}

const TestConnectionButton = ({
  disabled = false,
  data,
  testConnection,
  testDataSourceConnection,
  setConnectionTest
}: iProps): JSX.Element => {
  const testDSConnection = async () => {
    setConnectionTest(DATASOURCE_CONNECTION.inProgress)
    testDataSourceConnection(data)
  }

  const success = testConnection === DATASOURCE_CONNECTION.success
  const loading = testConnection === DATASOURCE_CONNECTION.inProgress
  const error = testConnection === DATASOURCE_CONNECTION.failed

  return (
    <span className="test-connection" data-test-id="datasources-test-connection-button">
      <Button
        basic
        type="button"
        onClick={testDSConnection}
        {...(disabled ? { disabled } : {})}
        className={cx('btn-test-connection xs-mr-8', { success, error })}
      >
        {loading ? (
          <>
            <Loader size="tiny" inline active className="xs-mr-4" />
            <FormattedMessage id="dataSources.form.text.testingConnection" />
          </>
        ) : (
          <FormattedMessage id="dataSources.form.testConnection.text" />
        )}
      </Button>
      <p className={cx('status-message', { success, error })}>
        {success && (
          <>
            <IconCheckCircle
              size="20"
              color="var(--color-success)"
              weight="fill"
              className="xs-mr-4"
            />{' '}
            <FormattedMessage id="dataSources.form.testConnection.success" />
          </>
        )}
        {error && (
          <>
            <IconWarningCircle
              size="20"
              color="var(--color-error)"
              weight="fill"
              className="xs-mr-4"
            />{' '}
            <FormattedMessage id="dataSources.form.testConnection.error" />
          </>
        )}
      </p>
    </span>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    testConnection: state.dataSources.testConnection
  }
}

const mapDispatchToProps = {
  setConnectionTest,
  testDataSourceConnection
}

export default connect(mapStateToProps, mapDispatchToProps)(TestConnectionButton)
