export const anomaliesList = [
  {
    type: 'error',
    dateTime: 'On Aug 06, 2020 @ 13:00 GMT',
    text: 'Salesforce data not replicated.'
  },
  {
    type: 'warning',
    dateTime: 'On Aug 02, 2020 @ 11:00 GMT',
    text: '3 overprivileged users have not used granted permissions.'
  },
  {
    type: 'warning',
    dateTime: 'On Aug 06, 2020 @ 13:00 GMT',
    text: 'Salesforce data not replicated.'
  },
  {
    type: 'warning',
    dateTime: 'On Aug 02, 2020 @ 11:00 GMT',
    text: '3 overprivileged users have not used granted permissions.'
  },
  {
    type: 'error',
    dateTime: 'On Aug 06, 2020 @ 13:00 GMT',
    text: 'Salesforce data not replicated.'
  },
  {
    type: 'error',
    dateTime: 'On Aug 02, 2020 @ 11:00 GMT',
    text: '3 overprivileged users have not used granted permissions.'
  },
  {
    type: 'warning',
    dateTime: 'On Aug 06, 2020 @ 13:00 GMT',
    text: 'Salesforce data not replicated.'
  },
  {
    type: 'warning',
    dateTime: 'On Aug 02, 2020 @ 11:00 GMT',
    text: '3 overprivileged users have not used granted permissions.'
  }
]

export const anomaliesChart = [
  { timestamp: 1610928000.0, value: 3.0, units: null },
  { timestamp: 1611014400.0, value: 2.0, units: null },
  { timestamp: 1611100800.0, value: 1.0, units: null },
  { timestamp: 1611187200.0, value: 9.0, units: null },
  { timestamp: 1611273600.0, value: 9.0, units: null },
  { timestamp: 1611360000.0, value: 9.0, units: null },
  { timestamp: 1611446400.0, value: 9.0, units: null }
]
