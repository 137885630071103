import {
  TimestampRequestParams,
  SummaryWidgetData,
  NamedTimeSeries
} from '../../../services/api/apiTypes'
import { AlertsListItem } from '../../../interfaces'
import { responseStatusInitialState, IResponseStatus } from '../../../services/api/apiStages'
import dayjs from 'dayjs'
import { createSlice } from '@reduxjs/toolkit'

export interface IAlertsInfo {
  info: Array<AlertsListItem>
  count: number | string
  data: Array<{
    weekDay: string
    count: number
  }>
}

interface DashboardState {
  summaryWidgetData: SummaryWidgetData | null
  responseStatusInitialState: any
  mapWidgetData: Array<NamedTimeSeries>
  anomalies: any
  alerts: IAlertsInfo
  dateRange: TimestampRequestParams
  getAnomalies: IResponseStatus
}

export const initialState: DashboardState = {
  summaryWidgetData: null,
  mapWidgetData: [],
  responseStatusInitialState: null,
  anomalies: [
    {
      message: 'Oregon_2.png accessed by Tina M 35 times',
      time: 'On Dec 03, 2020 @ 20:42'
    },
    {
      message: 'Salesforce data not replicated',
      time: 'On Dec 02, 2020 @ 13:54'
    },
    {
      message: '3 overprivileged users have not used granted permissions',
      time: 'On Dec 02, 2020 @ 11:07'
    }
  ],
  alerts: {
    count: '27',
    info: [
      {
        type: 'warn',
        message: 'CCPA potential violation Data for US resident replicated to Asia'
      },
      {
        type: 'warn',
        message: 'Suspicious user activity for PII data of John Smith'
      },
      {
        type: 'warn',
        message: '3 Connected Apps Reading PII Data from Google Drive'
      }
    ],
    data: [
      {
        weekDay: 'Mon',
        count: 3
      },
      {
        weekDay: 'Tue',
        count: 2
      },
      {
        weekDay: 'Wed',
        count: 13
      },
      {
        weekDay: 'Thu',
        count: 7
      },
      {
        weekDay: 'Fri',
        count: 4
      },
      {
        weekDay: 'Sat',
        count: 5
      },
      {
        weekDay: 'Sun',
        count: 3
      }
    ]
  },
  getAnomalies: responseStatusInitialState,

  dateRange: {
    endDate: dayjs().unix(),
    startDate: dayjs().subtract(1, 'week').unix()
  }
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDateRange: (state, action) => {
      state.dateRange = action.payload
    }
  }
})

export const { setDateRange } = dashboardSlice.actions

export default dashboardSlice.reducer
