export const auditTrailList = [
  {
    activity: 'Give Access',
    entity: 'Louis Smith',
    dateTime: 'May 18, 2020 @ 18:30'
  },
  {
    activity: 'Download',
    entity: 'Louis Smith',
    dateTime: 'May 18, 2020 @ 18:30'
  },
  {
    activity: 'Uploaded heavy files',
    entity: 'Louis Smith',
    dateTime: 'May 18, 2020 @ 18:30'
  },
  {
    activity: 'Give Access',
    entity: 'Louis Smith',
    dateTime: 'May 18, 2020 @ 18:30'
  },
  {
    activity: 'Give Access',
    entity: 'Louis Smith',
    dateTime: 'May 18, 2020 @ 18:30'
  },
  {
    activity: 'Give Access',
    entity: 'Louis Smith',
    dateTime: 'May 18, 2020 @ 18:30'
  }
]
