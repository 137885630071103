import './itemsArea.less'
import React, { useRef, useState } from 'react'
import { Input, Popup } from 'semantic-ui-react'
import { PlusCircle as IconPlusCircle, MinusCircle as IconMinusCircle } from 'phosphor-react'
import { Typography } from '@lightbeamai/design-system'

interface IProps {
  items: string[]
  textAddNewLabel: string
  textAddNewPlaceholder: string
  disabled?: boolean
  className?: string
  onItemsChange: (items: string[]) => void
  onTouch?: () => void
}

const ItemsArea = (props: IProps): React.ReactElement => {
  const {
    items,
    textAddNewLabel,
    textAddNewPlaceholder,
    disabled = false,
    className = '',
    onTouch,
    onItemsChange
  } = props

  const inputRef = useRef<Input>(null)
  const [newItem, setNewItem] = useState('')
  const handleChangeNewItem = (_, { value = '' }) => setNewItem(value)

  const [isPopupOpened, setPopupOpened] = useState(false)

  const handleAddItem = (event) => {
    if (disabled) return

    if (event.key === 'Enter') {
      onItemsChange([...items, newItem])
      setNewItem('')
      handleClose()
    }
  }
  const handleOpen = () => {
    if (disabled) return
    onTouch && onTouch()
    setPopupOpened(true)
    // hack to assure that input rendered and received ref
    setTimeout(() => inputRef?.current?.focus())
  }
  const handleClose = () => setPopupOpened(false)

  return (
    <div
      className={`items-area ${className} ${disabled ? 'disabled' : ''}`}
      data-test-id="items-area"
    >
      {items.map((currentItem, i) => {
        const handleRemoveItem = () => {
          if (disabled) return
          onItemsChange(items.filter((item, index) => !(index === i && item === currentItem)))
        }

        return (
          <div className="item" key={i}>
            {currentItem}
            <div className="icon-box">
              <IconMinusCircle
                size={14}
                weight="fill"
                className="btn"
                color="#586069"
                onClick={handleRemoveItem}
                data-test-id="items-area-btn-remove"
              />
            </div>
          </div>
        )
      })}
      <Popup
        position="bottom left"
        className="items-area-popup"
        on="click"
        open={isPopupOpened}
        onClose={handleClose}
        onOpen={handleOpen}
        disabled={disabled}
        content={
          <Input
            placeholder={textAddNewLabel}
            value={newItem}
            onChange={handleChangeNewItem}
            onKeyPress={handleAddItem}
            ref={inputRef}
            data-test-id="items-area-input"
          />
        }
        trigger={
          <Typography
            as="div"
            variant="baseMinus2"
            className="item add-new btn-text"
            data-test-id="items-area-btn-add"
          >
            <div className="icon-box">
              <IconPlusCircle size={14} weight="fill" color="#7D8791" />
            </div>
            {textAddNewPlaceholder}
          </Typography>
        }
      />
    </div>
  )
}

export default ItemsArea
