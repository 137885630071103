import {
  fetchDsrSelfServeAssignTicketDsList,
  getDatasourceOptionForManualAttributes,
  mapAttributeDetailsforWarning,
  mapDatasourceOptionsForManualAttributes,
  mapDsrSelfServeAssignTicketDsList,
  mapQueryDsrDataSourcesList,
  mapQueryDsrDefaultDataSources,
  mutationSelfServeAssignNewTicket,
  mutationSelfServeMarkAsValidated,
  queryAttributeDetailsforWarning,
  queryDefaultDataSources,
  queryDsrDataSourcesList,
  queryMutationSelfServeAddAttributesManually
} from './queries'
import { AssigTicketParams } from './components/assignTicket/assignTicket'
import {
  attributeDetailsParams,
  MarkValidatedParams
} from './components/markAsValidated/markAsValidatedModal'
import { PAGE } from '../../../constants'
import { FilterParams } from '../../../interfaces'
import graphqlService from '../../../services/graphqlService'
import { DataSourceSettings } from '../../dataSources/dataSourcesSlice'
import { defaultSortParams } from '../../../utils/sortUtil'
import { DataSource } from '../../../services/api/apiTypes'
import { AddAttributeManuallyParams } from '../tickets/dsrTicketsSlice'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export type CountOutOfTotal = {
  count: number
  total: number
}

export type ReportData = {
  status?: string
  statusDate?: string
  dataCollected: CountOutOfTotal
  dataSources: CountOutOfTotal
  generatedOn?: string
  sharingStatus?: string
  sharedOn?: string
  scheduledOn?: string
  reportTemplate?: string
  showIdentifiers?: boolean
}

export type ClosureData = {
  createdOn?: string
  status?: string
}

export type User = {
  id?: string
  firstName?: string
  lastName?: string
  email?: string
  residency?: string
  address?: string
  phoneNumber?: string
  uniqueID?: string
  dob?: string
}

export interface DSRRequestDetails {
  dataSources: DataSourceSettings
  report: ReportData
  closure: ClosureData
  defaultDatasources: (DataSource & { instancesDetectedCount?: number })[] | null
  defaultDatasourcesCount: number
  dataSourceOptionsForManualAttributes?: DataSource[]
  dataSourceListforAssignTicketModal?: DataSource[]
}
const initialList: DataSourceSettings = {
  sort: defaultSortParams
}
export const initialState: DSRRequestDetails = {
  dataSources: initialList,
  report: {
    dataCollected: {
      count: 0,
      total: 0
    },
    dataSources: {
      count: 0,
      total: 0
    }
  },
  closure: {},
  defaultDatasources: null,
  defaultDatasourcesCount: 0
}

export interface DsrDataSourceFilters {
  attributeValidated?: boolean
  attributeDetected?: boolean
  assignee?: string[]
  dataValidationStatus?: string[]
}

export interface FetchDsrDataSourcesParams {
  entityId: string
  filters?: FilterParams
  dsrRequestId?: string
  dsrDataSourceFilters?: DsrDataSourceFilters
  [PAGE]?: number
  searchQuery?: string
}
export const ACTION_DSR_DATA_SOURCES = 'dataSources/dsr'
export const fetchDsrDataSourcesList = createAsyncThunk(
  ACTION_DSR_DATA_SOURCES,
  async (params: FetchDsrDataSourcesParams) => {
    const resultRaw = await graphqlService.execute(queryDsrDataSourcesList(params))
    return mapQueryDsrDataSourcesList(resultRaw)
  }
)

export const FETCH_DEFAULT_DATA_SOURCES = 'fetch/dsr/data-discovery/default-data-sources'
export const fetchDefaultDataSources = createAsyncThunk(
  FETCH_DEFAULT_DATA_SOURCES,
  async (params: FetchDsrDataSourcesParams) => {
    const resultRaw = await graphqlService.execute(queryDefaultDataSources(params))
    return mapQueryDsrDefaultDataSources(resultRaw)
  }
)

export const FETCH_DATA_SOURCE_OPTIONS_FOR_MANUAL_ATTRIBUTES =
  'fetch/dsr/data-discovery/data-source-options-for-manual-attributes'
export const fetchDataSourceOptionsForManualAttributes = createAsyncThunk(
  FETCH_DATA_SOURCE_OPTIONS_FOR_MANUAL_ATTRIBUTES,
  async (requestId: string) => {
    const resultRaw = await graphqlService.execute(
      getDatasourceOptionForManualAttributes(requestId)
    )
    return mapDatasourceOptionsForManualAttributes(resultRaw)
  }
)

export const SELF_SERVE_ADD_ATTRIBUTES_MANUALLY = 'dsr/selfServe/addAttributesManually'
export const setAddAttributesManually = createAsyncThunk(
  SELF_SERVE_ADD_ATTRIBUTES_MANUALLY,
  async (params: AddAttributeManuallyParams) => {
    await graphqlService.execute(queryMutationSelfServeAddAttributesManually(params))
  }
)

export const ACTION_SELF_SERVE_ASSIGN_NEW_TICKET = 'dsr/selfServe/assignNewTicket'
export const handleSelfServeAssignNewTicket = createAsyncThunk(
  ACTION_SELF_SERVE_ASSIGN_NEW_TICKET,
  async (params: AssigTicketParams) => {
    await graphqlService.execute(mutationSelfServeAssignNewTicket(params))
  }
)

export const ACTION_SELF_SERVE_ASSIGN_NEW_TICKET_DS_LIST = 'dsr/dsr/selfServe/assignNewTicket'
export const fetchSelfServeAssignNewTicketDsList = createAsyncThunk(
  ACTION_SELF_SERVE_ASSIGN_NEW_TICKET_DS_LIST,
  async (params) => {
    const raw = await graphqlService.execute(fetchDsrSelfServeAssignTicketDsList(params))
    return mapDsrSelfServeAssignTicketDsList(raw)
  }
)

export const ACTION_SELF_SERVE_MARK_AS_VALIDATED = 'dsr/selfServe/markAsValidated'
export const handleSelfServeMarkAsValidated = createAsyncThunk(
  ACTION_SELF_SERVE_MARK_AS_VALIDATED,
  async (params: MarkValidatedParams) => {
    await graphqlService.execute(mutationSelfServeMarkAsValidated(params))
  }
)

export const ACTION_SELF_SERVE_GET_ATTRIBUTE_DETAILS_FOR_WARNING =
  'dsr/selfServe/getAttributeDetailsForWarning'
export const handleSelfServeGetAttributeDetailsForWarning = createAsyncThunk(
  ACTION_SELF_SERVE_GET_ATTRIBUTE_DETAILS_FOR_WARNING,
  async (params: attributeDetailsParams) => {
    const resultRaw = await graphqlService.execute(queryAttributeDetailsforWarning(params))
    return mapAttributeDetailsforWarning(resultRaw)
  }
)

const requestListSlice = createSlice({
  name: 'dsrRequestDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDsrDataSourcesList.fulfilled, (state, { payload }) => {
      state.dataSources.list = payload.list
      state.dataSources.total = payload.total
    })
    builder.addCase(fetchDefaultDataSources.fulfilled, (state, { payload }) => {
      state.defaultDatasources = payload.defaultDatasources
      state.defaultDatasourcesCount = payload.defaultDatasourcesCount
    })
    builder.addCase(fetchDataSourceOptionsForManualAttributes.fulfilled, (state, { payload }) => {
      state.dataSourceOptionsForManualAttributes = payload
    })
    builder.addCase(fetchSelfServeAssignNewTicketDsList.fulfilled, (state, { payload }) => {
      state.dataSourceListforAssignTicketModal = payload
    })
  }
})

export default requestListSlice.reducer
