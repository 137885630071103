import './header.less'
import React from 'react'
import { Header } from 'semantic-ui-react'

interface IProps {
  content: string | React.ReactElement
  children?: React.ReactElement
  className?: string
  SubheaderIconComponent?: React.ReactElement
  subheaderText?: string | React.ReactElement
}

const PageHeader = (props: IProps): React.ReactElement => {
  const {
    content,
    children,
    className = '',
    SubheaderIconComponent = '',
    subheaderText = ''
  } = props

  return (
    <Header as="header" className={`page-header ${className}`} data-test-id="page-header">
      <Header as="h1">{content}</Header>

      {subheaderText && (
        <div className="subheader">
          <Header as="h1">
            {SubheaderIconComponent}
            {subheaderText}
          </Header>
        </div>
      )}
      {children}
    </Header>
  )
}

export default PageHeader
