export interface PolicySettingsRouteParams {
  subPage: string
  id: string
  alertSubPage: string
}
export enum SYSTEM_DATA_TYPES {
  DATE = 'DATE'
}

export enum PatternOptions {
  BASIC = 'basic',
  ADVANCED = 'advanced'
}

export type BasicDetails = {
  name: string
  description?: string
  enabled?: boolean
  dataType: SYSTEM_DATA_TYPES
}

export type Detection = {
  detectionType: PatternOptions
  regexValues: string[]
  contextWords: string[]
  regexPatternTypes?: SYSTEM_DATA_TYPES[]
}

export interface PolicyParametersContext {
  basicDetails: BasicDetails
  patchBasicDetails: (fields: Partial<BasicDetails>) => void
  detection: Detection
  patchDetection: (fields: Partial<Detection>) => void
  navigateToCreateOrEditParameter: (id?: string, forEdit?: boolean) => void
  isFormValid: boolean
  resetForm: () => void
  testText: string
  setTestText: (text: string) => void
  mode: {
    isCreate: boolean
    isEdit: boolean
    isView: boolean
  }
  policyParamIdFromUrl?: string
  handleDetectionTypeChange: (detectionType: PatternOptions) => void
}

export type PolicyParametersData = Pick<PolicyParametersContext, 'basicDetails' | 'detection'> & {
  id?: string
}

export type PolicyParameterListItem = {
  name: string
  id: string
  enabled: boolean
  isUsedInPolicy: boolean
}

export type PolicyParameter = {
  id: string
  name: string
  description?: string
  enabled: boolean
  dataType: SYSTEM_DATA_TYPES
  context: {
    keyword: string
  }[]
  patterns: {
    regex: string
  }[]
  regexPatternTypes?: SYSTEM_DATA_TYPES[]
  attributeSetId: string
}
