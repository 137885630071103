import { DatabasesAndPoliciesParams } from './databasesAndPoliciesSlice'
import { gql } from 'graphql-request'

export const queryDatabasesAndPolicies = (params: DatabasesAndPoliciesParams): string => gql`
  {
    datasources(id: "${params.datasourceId}", first: 1) {
      edges {
        node {
          databases(first: 1){
            count
          }
          policies(first: 1){
            count
          }
        }
      }
    }
  }
`

// TODO: add type for graphql response
export const mapQueryDatabasesAndPolicies = (
  raw: any
): { databasesCount: number; policiesCount: number } => {
  try {
    return {
      databasesCount: raw.datasources.edges[0]?.node?.databases?.count || 0,
      policiesCount: raw.datasources.edges[0]?.node?.policies?.count || 0
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
