import {
  SchemaTablesParams,
  Table,
  TableCompact,
  TableCompactCluster,
  TableCompactSchema,
  TableFilterSchema,
  TableFilterSchemaParams,
  TableListsCountParams,
  TableMetaData,
  TableSamples,
  TableSamplesParams,
  TablesListCompactParams,
  TablesListParams,
  TablesWidget,
  TablesWidgetParams
} from './tablesSlice'
import {
  FILTER_PII_VALUES,
  GRAPHQL_API_FILTERS,
  GRAPHQL_CLASSIFICATION_TYPE,
  IS_ALL,
  IS_AT_RISK,
  IS_SENSITIVE,
  LIMIT_DEFAULT,
  ORPHAN_CLUSTER_TYPE_NAME,
  PAGE,
  SEARCH_QUERY
} from '../../constants'
import { getAfterCursor } from '../../utils/graphqlUtil'
import { MAX_SCHEMA_TABLES_COUNT } from '../tableRelationships/tableRelationshipsSlice'
import { ColumnsParams } from '../columns/columnsSlice'
import { gql } from 'graphql-request'

const mapPiiDetectionFilterToQuery = (filter: FILTER_PII_VALUES[]): string => {
  if (
    filter.includes(FILTER_PII_VALUES.pii) &&
    filter.includes(FILTER_PII_VALUES.maybePii) &&
    filter.includes(FILTER_PII_VALUES.nonPii)
  ) {
    return ''
  }

  if (filter.includes(FILTER_PII_VALUES.pii) && filter.includes(FILTER_PII_VALUES.maybePii)) {
    return `toggleFilter: { key: ${GRAPHQL_API_FILTERS.isPiiOrMaybePii} },`
  }

  // TODO: this case is broken because lack of API
  if (filter.includes(FILTER_PII_VALUES.pii) && filter.includes(FILTER_PII_VALUES.nonPii)) {
    return `classification: ${GRAPHQL_CLASSIFICATION_TYPE.NOT_PII},`
  }

  if (filter.includes(FILTER_PII_VALUES.maybePii) && filter.includes(FILTER_PII_VALUES.nonPii)) {
    return (
      `toggleFilter: { key: ${GRAPHQL_API_FILTERS.isMaybePII} },` +
      `classification: ${GRAPHQL_CLASSIFICATION_TYPE.NOT_PII},`
    )
  }

  if (filter.includes(FILTER_PII_VALUES.pii)) {
    return `classification: ${GRAPHQL_CLASSIFICATION_TYPE.PII},`
  }
  if (filter.includes(FILTER_PII_VALUES.maybePii)) {
    return `toggleFilter: { key: ${GRAPHQL_API_FILTERS.isMaybePII} },`
  }
  if (filter.includes(FILTER_PII_VALUES.nonPii)) {
    return `classification: ${GRAPHQL_CLASSIFICATION_TYPE.NOT_PII},`
  }

  return ''
}

export const queryTableListsCount = (params: TableListsCountParams): string => {
  const rootQuery = params.databaseId
    ? `databases(id: "${params.databaseId}")`
    : `datasources(id: "${params.datasourceId}")`

  let filter = ''
  if (params.piiDetection) {
    filter = mapPiiDetectionFilterToQuery(params.piiDetection)
  }
  const piiTablesFilter = params.piiDetection
    ? ''
    : `toggleFilter: { key: ${GRAPHQL_API_FILTERS.isPiiOrMaybePii} },`

  if (params.filters && !!Object.values(params.filters).length) {
    const filtersStr = Object.entries(params.filters).map(([key, values]) => {
      return `{key: ${key}, values: ${JSON.stringify(values)}}`
    })
    filter += `filter: [${filtersStr}],`
  }

  let searchQuery = ''
  if (params[SEARCH_QUERY]) {
    searchQuery = `query: "${params[SEARCH_QUERY]}", `
  }

  const tablesArg = filter + searchQuery ? `(${filter} ${searchQuery})` : ''
  const tablesPiiArg =
    piiTablesFilter + filter + searchQuery ? `(${piiTablesFilter} ${filter} ${searchQuery})` : ''
  const tablesToReviewArg = filter + searchQuery ? `, ${filter} ${searchQuery}` : ''

  return gql`
    {
      ${rootQuery} {
        edges {
          node {
            tables${tablesArg} { count }
            tablesPii: tables${tablesPiiArg}{
              count
            }
            tablesNeedReview: tables(booleanFilter: [{key: ${GRAPHQL_API_FILTERS.isReviewed}, value: false }] ${tablesToReviewArg}){
              count
            }
          }
        }
      }
    }
  `
}

// TODO: add type for graphql response
export const mapQueryTableListsCount = (
  raw: any
): { [IS_ALL]: number; [IS_SENSITIVE]: number; [IS_AT_RISK]: number } => {
  const root = raw.databases || raw.datasources

  return {
    [IS_ALL]: root.edges[0].node.tables?.count || 0,
    [IS_SENSITIVE]: root.edges[0].node.tablesPii?.count || 0,
    [IS_AT_RISK]: root.edges[0].node.tablesNeedReview?.count || 0
  }
}

export const queryTables = (params: TablesListParams): string => {
  const { includeColumnCounts = true, pageSize = LIMIT_DEFAULT, page = 1 } = params
  const rootQuery = params.databaseId
    ? `databases(id: "${params.databaseId}")`
    : `datasources(id: "${params.datasourceId}")`

  const cursor = getAfterCursor(page, pageSize)

  let filter = ''
  if (params.isSensitive && !params.piiDetection) {
    filter = `toggleFilter: { key: ${GRAPHQL_API_FILTERS.isPiiOrMaybePii} },`
  }
  if (params.piiDetection) {
    filter = mapPiiDetectionFilterToQuery(params.piiDetection)
  }
  if (params.isAtRisk) {
    filter += `booleanFilter: [{key: ${GRAPHQL_API_FILTERS.isReviewed}, value: false }],`
  }

  if (params.filters && !!Object.values(params.filters).length) {
    const filtersStr = Object.entries(params.filters).map(([key, values]) => {
      return `{key: ${key}, values: ${JSON.stringify(values)}}`
    })
    filter += `filter: [${filtersStr}],`
  }

  let searchQuery = ''
  if (params[SEARCH_QUERY]) {
    searchQuery = `query: "${params[SEARCH_QUERY]}", `
  }

  const patterColumnsCount = `\
    columnsSensitiveCount: columns(classification: ${GRAPHQL_CLASSIFICATION_TYPE.PII}) {\
      count\
    }
    columns {\
      count\
    }`

  return gql`
    {
      ${rootQuery} {
        edges {
          node {
            tables(first: ${pageSize}, after: "${cursor}", ${filter} ${searchQuery}) {
              edges {
                node {
                  id
                  name
                  rowCount
                  createdOn
                  lastModifiedOn
                  createdBy
                  modifiedBy
                  isReviewed
                  changeType
                  ${includeColumnCounts ? patterColumnsCount : ''}
                  schema {
                    edges {
                      node {
                        name
                      }
                    }
                  }
                  database {
                    edges {
                      node {
                        name
                      }
                    }
                  }
                  classifications {
                    pii {
                      numRowsPII
                      attributeId
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

// TODO: add type for graphql response
export const mapQueryTables = (raw: any): Table[] => {
  const root = raw.databases || raw.datasources

  try {
    return root.edges[0].node.tables.edges.map(({ node: table }) => {
      return {
        tableId: table.id,
        tableName: table.name,
        rowsCount: table.rowCount,
        lastModified: table.lastModifiedOn || table.createdOn || '',
        modifiedBy: table.modifiedBy || table.createdBy || '',
        changeType: table.changeType || '',
        isReviewed: table.isReviewed || false,
        confidence: table.classifications?.pii?.numRowsPII || 0,
        columnsSensitiveCount: table.columnsSensitiveCount?.count || 0,
        columnsCount: table.columns?.count || 0,
        schemaName: table.schema?.edges[0]?.node?.name || '',
        databaseName: table.database?.edges[0]?.node?.name || ''
      }
    })
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryTableCompactSchemas = (params: TablesListCompactParams): string => {
  return gql`
    {
      datasources(id: "${params.datasourceId}") {
        edges {
          node {
            schemas {
              count
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryTableCompactSchemas = (
  raw: any
): { total: number; schemas: TableCompactSchema[] } => {
  try {
    const schemas = raw.datasources?.edges[0]?.node?.schemas?.edges?.map(({ node: schema }) => ({
      schemaId: decodeURIComponent(schema.id),
      schemaName: schema.name
    }))

    return { total: raw.datasources?.edges[0]?.node?.schemas?.count || 0, schemas }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const querySchemaTables = (params: SchemaTablesParams): string => {
  const cursor = getAfterCursor(params[PAGE], MAX_SCHEMA_TABLES_COUNT)

  return `
  {
    datasources(first: 1, id: "${params.datasourceId}") {
      edges {
        node {
          schemas(first:1, id:"${params.schemaId}") {
            edges {
              node {
                tables(first: ${MAX_SCHEMA_TABLES_COUNT}, after: "${cursor}") {
                  count
                  edges {
                    node {
                      id
                      name
                      rowCount
                      isReviewed
                      changeType
                      clusterRootTableUrn
                      columns {
                        count
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`
}
export const mapQuerySchemaTables = (
  raw: any
): {
  tables: TableCompact[]
  total: number
} => {
  try {
    const tables =
      raw.datasources.edges[0].node?.schemas?.edges[0]?.node?.tables?.edges?.map(
        ({ node: table }) => {
          return {
            tableId: decodeURIComponent(table.id),
            tableName: table.name,
            changeType: table.changeType,
            rowsCount: table.rowCount || 0,
            columnsCount: table.columns?.count || 0,
            parentId: decodeURIComponent(table.clusterRootTableUrn)
          }
        }
      ) || []

    return {
      total: raw.datasources?.edges[0].node?.schemas?.edges[0]?.node?.tables?.count || 0,
      tables
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryTableCompactClusters = (params: TablesListCompactParams): string => {
  return gql`
    {
      datasources(id: "${params.datasourceId}") {
        edges {
          node {
            tables(classification: ${GRAPHQL_CLASSIFICATION_TYPE.IDENTITY}) {
              count
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryTableCompactClusters = (
  raw: any
): { total: number; clusters: TableCompactCluster[] } => {
  try {
    const clusters = raw.datasources?.edges[0]?.node?.tables?.edges?.map(({ node: table }) => ({
      clusterId: decodeURIComponent(table.id),
      clusterName: table.name
    }))

    return { total: raw.datasources?.edges[0]?.node?.tables?.count || 0, clusters }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryTableClusterMetaData = (params: ColumnsParams): string => {
  return gql`
  query tableClusterMeta {
    databases(id: "${params.databaseId}")  {
      edges {
        node {
          tableClusters(id: "${params.tableId}") {
            edges {
              node {
                metadata {
                  timestampColumnID
                  primaryIdentifierColumnID
                  piiColumnIDsToScan
                  labels {
                    edges {
                        node {
                          labelSetId
                          id
                        }
                      }
                    }
                }
              }
            }
          }
        }
      }
    }
  }`
}

export const mapQueryTableClusterMetaData = (raw: any): TableMetaData => {
  try {
    const clusterMetaData =
      raw.databases?.edges[0]?.node?.tableClusters?.edges?.[0]?.node?.metadata || {}
    return {
      piiColumnIDsToScan: clusterMetaData?.piiColumnIDsToScan || [],
      identityTableTimestampColumnID: clusterMetaData?.timestampColumnID || '',
      primaryIdentifierColumnID: clusterMetaData?.primaryIdentifierColumnID || '',
      labels: clusterMetaData?.labels?.edges?.map?.(({ node }) => ({
        labelId: node?.id,
        labelSetId: node?.labelSetId
      }))
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryTablesWidget = (params: TablesWidgetParams): string => {
  return gql`
    {
      databases(first: 1, id: "${params.databaseId}") {
        edges {
          node {
            tables(first: 1) {
              count
            }
            tablesPii: tables(first: 1, toggleFilter: { key: ${GRAPHQL_API_FILTERS.isSensitive} }) {
              count
            }
            tablesNeedReview: tables(first: 1, booleanFilter: {key: ${GRAPHQL_API_FILTERS.isReviewed}, value: false}) {
              count
            }
            tablesNeedReviewPii: tables(
              first: 1,
              booleanFilter: [{key: ${GRAPHQL_API_FILTERS.isReviewed}, value: false}, {key: ${GRAPHQL_API_FILTERS.isSensitive}, value: true}]
            ){
              count
            }
            tableClusters(first: 999) {
              edges {
                node {
                  __typename
                  ... on AnchorTableCluster {
                    tables {
                      count
                    }
                    tablesPii: tables(first: 1, toggleFilter: { key: IS_SENSITIVE }) {
                      count
                    }
                    tablesNeedReview: tables(first: 1, booleanFilter: {key: ${GRAPHQL_API_FILTERS.isReviewed}, value: false}) {
                      count
                    },
                    columnsNeedReview: columns(first: 1, booleanFilter: {key: ${GRAPHQL_API_FILTERS.isReviewed}, value: false}) {
                      count
                    },
                  }
                }
              }
            }
            orphanTables: tables(first: 1, filter:{key: ${GRAPHQL_API_FILTERS.parentTableId}, values: ""}) {
              count
            }
            orphanPiiTables: tables(
              first: 1,
              filter:{ key: ${GRAPHQL_API_FILTERS.parentTableId}, values: ""},
              toggleFilter: { key: ${GRAPHQL_API_FILTERS.isSensitive} }
            ) {
              count
            }
            orphanTablesNeedReview: tables(
              first: 1,
              filter:{key:${GRAPHQL_API_FILTERS.parentTableId}, values:""},
              booleanFilter: {key: ${GRAPHQL_API_FILTERS.isReviewed}, value: false}
            ) {
              count
            }
          }
        }
      }
    }
  `
}

// TODO: add type for graphql response
export const mapQueryTablesWidget = (raw: any): TablesWidget => {
  try {
    let clusterTablesCount = 0
    let clusterTablesPiiCount = 0
    let clustersImpactedCount = 0

    const tableClusters = raw.databases.edges[0]?.node?.tableClusters?.edges?.filter(
      ({ node: cluster }) => cluster.__typename !== ORPHAN_CLUSTER_TYPE_NAME
    )

    tableClusters.forEach(({ node: cluster }) => {
      const isImpacted = !!cluster.tablesNeedReview?.count || !!cluster.columnsNeedReview?.count

      clusterTablesCount += cluster?.tables?.count || 0
      clusterTablesPiiCount += cluster?.tablesPii?.count || 0
      clustersImpactedCount += isImpacted ? 1 : 0
    })

    return {
      tablesCount: raw.databases.edges[0]?.node?.tables?.count || 0,
      tablesPiiCount: raw.databases.edges[0]?.node?.tablesPii?.count || 0,
      tablesNeedReviewCount: raw.databases.edges[0]?.node?.tablesNeedReview?.count || 0,
      tablesNeedReviewPiiCount: raw.databases.edges[0]?.node?.tablesNeedReviewPii?.count || 0,
      clustersCount: tableClusters.length || 0,
      clusterTablesCount,
      clusterTablesPiiCount,
      clustersImpactedCount,
      orphanTablesCount: raw.databases.edges[0]?.node?.orphanTables?.count || 0,
      orphanTablesPiiCount: raw.databases.edges[0]?.node?.orphanPiiTables?.count || 0,
      orphanTablesNeedReviewCount: raw.databases.edges[0]?.node?.orphanTablesNeedReview?.count || 0
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryTableSamples = (params: TableSamplesParams): string => {
  return gql`
    {
      tables(id: "${params.tableId}") {
        edges {
          node {
            id
            name
            columns {
              edges {
                node {
                  id
                  name
                  classifications {
                    pii {
                      numRowsPII
                    }
                  }
                }
              }
            }
            sampleData {
              header
              rows
            }
          }
        }
      }
    }
  `
}
export const mapQueryTableSamples = (raw: any): TableSamples => {
  try {
    const columnsHash = {}
    raw.tables?.edges[0]?.node?.columns?.edges.forEach(({ node: column }) => {
      columnsHash[column.name] = {
        columnId: column.id,
        columnName: column.name,
        isPii: !!Number.isInteger(column.classifications?.pii?.numRowsPII)
      }
    })

    const sampleData = raw.tables?.edges[0]?.node?.sampleData

    return {
      tableId: raw.tables?.edges[0]?.node?.id || '',
      tableName: raw.tables?.edges[0]?.node?.name || '',
      columns: sampleData?.header?.map((h) => columnsHash[h] || '') || [],
      rows: sampleData?.rows || []
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryTableFilterAttributes = (): string => {
  return gql`
    {
      attribute {
        edges {
          node {
            id
            name
            internalName
            attributeSets {
              edges {
                node {
                  id
                  name
                  enabled
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryTableFilterAttributes = (raw: any) => {
  try {
    return raw.attribute?.edges?.map(({ node: attr }) => ({
      id: attr.id,
      name: attr.name,
      internalName: attr.internalName,
      attributeSets: attr.attributeSets?.edges?.map(({ node: set }) => ({
        id: set.id,
        name: set.name,
        enabled: set.enabled
      }))
    }))
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryTableFilterSchemas = (params: TableFilterSchemaParams): string => {
  return gql`
    {
      datasources(id: "${params.datasourceId}") {
        edges {
          node {
            schemas {
              edges {
                node {
                  id
                  name
                  database {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryTableFilterSchemas = (raw: any): TableFilterSchema[] => {
  try {
    return raw.datasources?.edges[0]?.node?.schemas?.edges?.map(({ node: schema }) => ({
      id: schema.id,
      name: schema.name,
      databases: schema.database?.edges?.map(({ node: db }) => ({
        id: db.id,
        name: db.name
      }))
    }))
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const mutationReviewTables = (tableIds: string[]): string => {
  return gql`
    mutation {
      reviewTables(
        tableIds: ${JSON.stringify(tableIds)}
      ) {
        success
        message
      }
    }
  `
}
