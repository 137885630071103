import {
  mapQueryDSRAttributesList,
  mapQueryDSRDataDiscoveryAttributesListing,
  queryDSRAttributesList,
  queryDSRDataDiscoveryAttributesTable
} from './queries'
import {
  PAGE,
  DATA_SOURCE_TYPE,
  FILTER_ATTRIBUTE_NAME,
  FILTER_IDENTIFIER,
  FILTER_NO_OF_DATA_SOURCES,
  PAGE_SIZE
} from '../../../../../constants'
import { FilterParams } from '../../../../../interfaces'
import { defaultSortParams, getSortDirection, SortParams } from '../../../../../utils/sortUtil'
import graphqlService from '../../../../../services/graphqlService'
import { getGlobalParams } from '../../../../../utils/urlUtil'
import { RootState } from '../../../../../rootReducer'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export type Attribute = {
  id: string
  name: string
  dataSourcesCount?: number
  identifier?: string
  datasources?: string[]
  attribute?: string[]
}

export interface AttributeTypeInfo {
  name: string
  type: string
}

export type FilterOptions = {
  [filterKey: string]: string[]
}

type AttributesState = {
  list: Attribute[]
  total: number
  sort: SortParams
  filterOptions: FilterOptions
  allAttributesList: Attribute[]
  totalAttributes: number
}

export const initialState: AttributesState = {
  list: [],
  total: 0,
  allAttributesList: [],
  totalAttributes: 0,
  sort: defaultSortParams,
  filterOptions: {
    [FILTER_ATTRIBUTE_NAME]: [],
    [FILTER_IDENTIFIER]: [],
    [FILTER_NO_OF_DATA_SOURCES]: []
  }
}

export type AttributeTableResponse = {
  list: Attribute[]
  total: number
}

export type IGetAttributesFilterParams = {
  id?: string
  filters?: FilterParams
}

export type IGetAttributesTableListParams = {
  [PAGE]: number
  [PAGE_SIZE]?: number
  [DATA_SOURCE_TYPE]?: string
  entityId?: string
  filters?: FilterParams
}

export const ACTION_FETCH_ATTRIBUTES = 'fetch/dsr/data-discovery/attribute-list'
export const fetchAttributesTable = createAsyncThunk(
  ACTION_FETCH_ATTRIBUTES,
  async (params: IGetAttributesTableListParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const resultRaw = await graphqlService.execute(
      queryDSRDataDiscoveryAttributesTable(queryParams)
    )
    return mapQueryDSRDataDiscoveryAttributesListing(resultRaw)
  }
)

export const ACTION_FETCH_ALL_ATTRIBUTES = 'fetch/dsr/data-discovery/all-attributes-list'
export const fetchAllAttributesList = createAsyncThunk(
  ACTION_FETCH_ALL_ATTRIBUTES,
  async (params: IGetAttributesTableListParams) => {
    const resultRaw = await graphqlService.execute(queryDSRAttributesList(params))
    return mapQueryDSRAttributesList(resultRaw)
  }
)

const dsrDataDiscoveryAttributeListSlice = createSlice({
  name: 'dsrDataDiscoveryAttributeList',
  initialState,
  reducers: {
    reset: (state) => {
      state.list = initialState.list
      state.total = initialState.total
      state.sort = initialState.sort
      state.filterOptions = initialState.filterOptions
    },
    setSort: (state, { payload }) => {
      state.sort = getSortDirection(state.sort, payload.column)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAttributesTable.fulfilled, (state, { payload }) => {
      state.list = payload.list
      state.total = payload.total
    }),
      builder.addCase(fetchAllAttributesList.fulfilled, (state, { payload }) => {
        state.allAttributesList = payload.attributesList
        state.totalAttributes = payload.totalAttributes
      })
  }
})

export const { setSort, reset } = dsrDataDiscoveryAttributeListSlice.actions

export default dsrDataDiscoveryAttributeListSlice.reducer
