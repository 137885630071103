import { LIMIT_DEFAULT } from '../../../constants'
import {
  getAfterCursor,
  parameterizeArrayofObjects,
  stringifyParams
} from '../../../utils/graphqlUtil'
import { IGetRequestListParams } from '../requestList/dsrRequestSlice'
import { gql } from 'graphql-request'

export const fetchDsrReportsList = (params: IGetRequestListParams) => {
  const { page, pageSize = LIMIT_DEFAULT, ...listFilters } = params

  const cursor = getAfterCursor(page, pageSize)

  const filterString = parameterizeArrayofObjects(listFilters.filters?.filter || [])

  const dateFilterString = listFilters.filters?.dateFilter?.map((dateFilter) => {
    return `{${stringifyParams(dateFilter)}},`
  })

  const searchQuery = listFilters.filters?.searchQuery || ''

  let commonParamString = ''
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }
  if (dateFilterString && dateFilterString?.length > 0) {
    commonParamString += `,dateFilter:[${dateFilterString}]`
  }
  if (searchQuery) {
    commonParamString += `,dataSubjectNameOrEmail:"${searchQuery}"`
  }

  return gql`
    {
      dsrReport( first: ${pageSize}, after: "${cursor}", ${commonParamString},sortField: UPDATED_AT, sortByAsc: false)
       {
        count
        edges {
          node {
            id
            status
            request {
              edges {
                node {
                  id
                  name
                  requestType
                  updateTimestamp
                  dataSubjectDetails {
                    attributeName
                    attributeValue
                  }
                  status
                  dueDate
                  createdAt
                  assignee {
                    name
                    email
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

// mapper for the fetchDsrReportsList query
export const mapFetchDsrReportsList = (raw: any) => {
  const totalReports = raw.dsrReport.count
  const reportList = raw.dsrReport.edges.map(({ node }) => {
    const requestNode = node.request.edges[0].node // Destructure first request for easy access
    const assignee = requestNode.assignee[0] // Access the first assignee
    const firstName = requestNode.dataSubjectDetails.find(
      (detail: any) => detail.attributeName === 'First Name'
    )?.attributeValue
    const lastName = requestNode.dataSubjectDetails.find(
      (detail: any) => detail.attributeName === 'Last Name'
    )?.attributeValue

    return {
      reportId: node.id,
      reportStatus: node.status,
      requestId: requestNode.id,
      requestName: requestNode.name,
      requestType: requestNode.requestType,
      updateTimestamp: requestNode.updateTimestamp,
      dataSubjectName: `${firstName} ${lastName}`, // Concatenate the full name
      requestStatus: requestNode.status,
      dueDate: requestNode.dueDate,
      createdAt: requestNode.createdAt,
      assigneeName: assignee?.name,
      assigneeEmail: assignee?.email,
      firstName,
      lastName
    }
  })

  return {
    report: reportList,
    count: totalReports
  }
}

export const fetchReportGroupByRequestType = (params: IGetRequestListParams) => {
  const { page, pageSize = LIMIT_DEFAULT, ...listFilters } = params

  const filterString = parameterizeArrayofObjects(listFilters.filters?.filter || [])

  const dateFilterString = listFilters.filters?.dateFilter?.map((dateFilter) => {
    return `{${stringifyParams(dateFilter)}},`
  })

  const searchQuery = listFilters.filters?.searchQuery || ''
  let commonParamString = ''
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }
  if (dateFilterString && dateFilterString?.length > 0) {
    commonParamString += `,dateFilter:[${dateFilterString}]`
  }
  if (!commonParamString) {
    commonParamString = `filter:[]`
  }
  if (searchQuery) {
    commonParamString += `,dataSubjectNameOrEmail:"${searchQuery}"`
  }

  return gql`
    {
      dsrReportGroupByRequestType {
        edges {
          node {
            requestType
            dsrReport(${commonParamString})
            {
              count
            }
          }
        }
      }
    }
  `
}

export const mapFetchReportGroupByRequestType = (raw: any) => {
  return raw.dsrReportGroupByRequestType.edges
    .map(({ node }) => ({
      requestType: node.requestType,
      count: node.dsrReport.count
    }))
    .filter((item) => item.count > 0)
}

export const downloadDsrReport = (params) => {
  const { selectedReports, currentUserEmail, actionType, appliedFilters } = params

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const filterString = parameterizeArrayofObjects(appliedFilters?.filter || [])

  const dateFilterString = appliedFilters?.dateFilter?.map((dateFilter) => {
    return `{${stringifyParams(dateFilter)}},`
  })

  const searchQuery = appliedFilters?.searchQuery || ''
  let commonParamString = '{'
  if (filterString) {
    commonParamString += `filter:${filterString}`
  }
  if (dateFilterString && dateFilterString?.length > 0) {
    commonParamString += `,dateFilter:[${dateFilterString}]`
  }
  if (searchQuery) {
    commonParamString += `,dataSubjectNameOrEmail:"${searchQuery}"`
  }
  commonParamString += '}'

  return gql`
    mutation {
      createDsrBulkReportAction(
        clientMutationId: "kjhgv"
        input: {
          createdBy: ${JSON.stringify(currentUserEmail)}
          timezone: ${JSON.stringify(timezone)}
          tableFilters: ${commonParamString}
          dsrRequestIds: ${JSON.stringify(selectedReports)}
          unbranded: true
          actionType: ${actionType}
        }
      ) {
        clientMutationId
      }
    }
  `
}

// function that fetches requestIds using dsrReport query
export const fetchAllRequestIds = (params) => {
  const appliedFilters = params

  const searchQuery = appliedFilters?.searchQuery || ''

  const filterString = parameterizeArrayofObjects(appliedFilters?.filter || [])

  const dateFilterString = appliedFilters?.dateFilter?.map((dateFilter) => {
    return `{${stringifyParams(dateFilter)}},`
  })

  let commonParamString = ''
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }
  if (dateFilterString && dateFilterString?.length > 0) {
    commonParamString += `,dateFilter:[${dateFilterString}]`
  }
  if (searchQuery) {
    commonParamString += `,dataSubjectNameOrEmail:"${searchQuery}"`
  }

  if (!commonParamString) {
    commonParamString = `filter:[],dateFilter:[],dataSubjectNameOrEmail:""`
  }

  return gql`
    {
      dsrReport(${commonParamString}) {
        count
        edges {
          node {
            request {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  `
}

//Mapper function that maps the response from fetchAllRequestIds to an array of requestIds
export const mapFetchAllRequestIds = (raw: any) => {
  return raw.dsrReport.edges.map(({ node }) => node.request.edges[0].node.id)
}
