import { ScanStatusParams, ScanStatusWidget } from './scanStatusSlice'
import { gql } from 'graphql-request'

export const queryScanStatusWidget = (
  { datasourceId }: ScanStatusParams,
  key: string
): string => gql`
  {
    datasources(id: "${datasourceId}") {
      edges {
        node {
          id
          name
          type
          status: state
          datasourceProgress {
            edges {
              node {
                configuration {
                  ${key || 'columns'} {
                    total
                    completed
                    failed
                    running
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const mapQueryScanStatusWidget = (raw: any, key: string): ScanStatusWidget => {
  try {
    const ds = raw.datasources.edges[0].node

    const totals = ds.datasourceProgress.edges.reduce(
      (acc, current) => {
        const columns = current?.node.configuration
          ? current?.node.configuration[key || 'columns']
          : {}
        const scanned = (columns?.completed || 0) + (columns?.failed || 0)
        acc.scannedCount += scanned
        acc.pendingCount += columns.total - scanned

        return acc
      },
      { scannedCount: 0, pendingCount: 0, skippedCount: 0 }
    )

    return {
      status: ds.status,
      scannedCount: totals.scannedCount,
      pendingCount: totals.pendingCount
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
