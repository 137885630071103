import PrintPageHeader from './header'
import { ContentsItem, IPropsPageContents, SubmenuItem } from './pageContents'
import { Table } from 'semantic-ui-react'
import React, { Fragment } from 'react'
import classNames from 'classnames'

const MultiPagePrintContents = (props: IPropsPageContents): React.ReactElement => {
  const { tableContents, className = '' } = props
  const headerText = 'Table of Contents'

  return (
    <div
      className={classNames('print-page page-contents', { [className]: !!className })}
      data-test-id="print-contents-page"
    >
      <Table className="multi-page-table-contents">
        <Table.Header>
          <Table.Row className="wrapper">
            <Table.HeaderCell>
              <PrintPageHeader text={headerText} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tableContents.map(({ title, urlAnchor, className = '', subMenu = [] }, i) => (
            <Fragment key={i}>
              <Table.Row>
                <Table.Cell className="xs-pl-76 xs-pt-1rem xs-pb-0">
                  <ContentsItem
                    key={i}
                    title={title}
                    urlAnchor={urlAnchor}
                    className={classNames('xs-ml-0', { [className]: !!className })}
                  />
                </Table.Cell>
              </Table.Row>
              {subMenu &&
                subMenu.map(({ title, urlAnchor, className = '' }, index) => (
                  <Table.Row key={index}>
                    <Table.Cell className="xs-pl-92 xs-pt-quarter-rem xs-pb-0">
                      <SubmenuItem
                        key={index}
                        className={classNames('xs-ml-0', { [className]: !!className })}
                        title={title}
                        urlAnchor={urlAnchor}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Fragment>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

export default MultiPagePrintContents
