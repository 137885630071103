import type { AxiosResponseHeaders } from 'axios'

export function getFilenameFromHeaders(headers: AxiosResponseHeaders) {
  const disposition = headers['content-disposition']

  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(disposition)

    if (matches?.[1]) {
      return matches[1].replace(/['"]/g, '')
    }
  }

  return ''
}
