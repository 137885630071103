export const getMaskedString = (str = ''): string => {
  if (!str) return ''

  if (str.length < 2) {
    return '*'.repeat(str.length)
  }

  const maskLength = str.length - 1
  return str[0] + '*'.repeat(maskLength) + str[str.length - 1]
}

export const isNumeric = (n: string | number): boolean => {
  return !isNaN(parseFloat(n + '')) && isFinite(+n)
}

export const capitalizeString = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

export const escapeNewlineandTab = (text: string) =>
  text.replace(/\n/gm, '\\n').replace(/\t/gm, '\\t')

export const stringEscape = (val: string) => {
  if (typeof val != 'string') return val
  return val
    .replace(/[\\]/gm, '\\\\')
    .replace(/[\b]/gm, '\\b')
    .replace(/[\f]/gm, '\\f')
    .replace(/[\n]/gm, '\\n')
    .replace(/[\r]/gm, '\\r')
    .replace(/[\t]/gm, '\\t')
    .replace(/\\'/gm, "\\'")
}

export const escapeHtml = (unsafe) => {
  return unsafe.replace(/[&<>"'\\/]/g, function (match) {
    switch (match) {
      case '&':
        return '&amp;'
      case '<':
        return '&lt;'
      case '>':
        return '&gt;'
      case '"':
        return '&quot;'
      case "'":
        return '&#39;'
      case '/':
        return '&#x2F;'
      /**TODO: Temporary Hack for handling back slash in  email template footer. LA-8053*/
      case '\\':
        return ''
      default:
        return match
    }
  })
}
export const unescapeHtml = (text) => {
  const tempElement = document.createElement('textarea')
  tempElement.innerHTML = text
  return tempElement.value
}

export function upperSnakeCasetoTitleCase(str: string): string {
  return str
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
