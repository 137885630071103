import { RootState } from '../../rootReducer'
import { sortByOrder } from '../../utils/sortUtil'
import { createSelector } from 'reselect'

const attributesListSelector = (state: RootState) => state.attributes.list.list
const attributesColumnSelector = (state: RootState) => state.attributes.list.sort.column
const attributesDirectionSelector = (state: RootState) => state.attributes.list.sort.direction

export const getAttributesListSorted = createSelector(
  [attributesListSelector, attributesColumnSelector, attributesDirectionSelector],
  (list, column, direction) => {
    return list?.length && column ? sortByOrder(list, column, direction) : list
  }
)

const attributesSetListSelector = (state: RootState) => state.attributes.attributeSets.list
const attributesSetColumnSelector = (state: RootState) => state.attributes.attributeSets.sort.column
const attributesSetDirectionSelector = (state: RootState) =>
  state.attributes.attributeSets.sort.direction

export const getAttributesSetListSorted = createSelector(
  [attributesSetListSelector, attributesSetColumnSelector, attributesSetDirectionSelector],
  (list, column, direction) => {
    return list?.length && column ? sortByOrder(list, column, direction) : list
  }
)

const attributesSetCardsSelector = (state: RootState) => state.attributes.attributeSets.cards
export const getAttributesSetCardsSorted = createSelector(
  [attributesSetCardsSelector, attributesSetColumnSelector, attributesSetDirectionSelector],
  (list, column, direction) => {
    return list?.length && column ? sortByOrder(list, column, direction) : list
  }
)

const attributesListAllSelector = (state: RootState) => state.attributes.attributes.list
const attributesAllColumnSelector = (state: RootState) => state.attributes.attributes.sort.column
const attributesAllDirectionSelector = (state: RootState) =>
  state.attributes.attributes.sort.direction

export const getAttributesListAllSorted = createSelector(
  [attributesListAllSelector, attributesAllColumnSelector, attributesAllDirectionSelector],
  (list, column, direction) => {
    return list?.length && column ? sortByOrder(list, column, direction) : list
  }
)
