import { RootState } from '../../../../rootReducer'
import { DATASOURCE_CREATE_OR_UPDATE_STATUS } from '../../../../constants'
import { setRegisterOrUpdate, createDatasource, updateDatasource } from '../../dataSourcesSlice'
import { DataSource } from '../../../../services/api/apiTypes'
import { Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import './registerOrUpdateButton.less'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import React, { useEffect } from 'react'
import { Button, Typography } from '@lightbeamai/design-system'

interface iProps {
  data: DataSource
  dataSourceId?: string
  registerOrUpdateStatus: DATASOURCE_CREATE_OR_UPDATE_STATUS | null
  createDatasource: (config: DataSource) => void
  updateDatasource: ({ datasourceId: string, datasource: DataSource }) => void
  setRegisterOrUpdate: (status: DATASOURCE_CREATE_OR_UPDATE_STATUS | null) => void
}

const RegisterOrUpdateButton = ({
  data,
  registerOrUpdateStatus,
  createDatasource,
  setRegisterOrUpdate,
  updateDatasource,
  dataSourceId
}: iProps): JSX.Element => {
  useEffect(() => {
    setRegisterOrUpdate(null)
  }, [setRegisterOrUpdate])

  const registerOrUpdateDS = async () => {
    if (dataSourceId) {
      setRegisterOrUpdate(DATASOURCE_CREATE_OR_UPDATE_STATUS.updateInProgress)
      updateDatasource({ datasourceId: dataSourceId, datasource: data })
    } else {
      setRegisterOrUpdate(DATASOURCE_CREATE_OR_UPDATE_STATUS.createInProgress)
      createDatasource(data)
    }
  }

  const success =
    registerOrUpdateStatus === DATASOURCE_CREATE_OR_UPDATE_STATUS.created ||
    registerOrUpdateStatus === DATASOURCE_CREATE_OR_UPDATE_STATUS.updated
  const error =
    registerOrUpdateStatus === DATASOURCE_CREATE_OR_UPDATE_STATUS.createFailed ||
    registerOrUpdateStatus === DATASOURCE_CREATE_OR_UPDATE_STATUS.updateFailed

  const getButtonContent = () => {
    if (registerOrUpdateStatus === DATASOURCE_CREATE_OR_UPDATE_STATUS.createInProgress) {
      return (
        <>
          <Loader size="tiny" inline active className="xs-mr-4" />
          <FormattedMessage id="dataSources.form.text.registering" />
        </>
      )
    } else if (registerOrUpdateStatus === DATASOURCE_CREATE_OR_UPDATE_STATUS.updateInProgress) {
      return (
        <>
          <Loader size="tiny" inline active className="xs-mr-4" />
          <FormattedMessage id="dataSources.form.text.updating" />
        </>
      )
    } else {
      if (dataSourceId) {
        return <FormattedMessage id="dataSources.form.update.text" />
      } else {
        return <FormattedMessage id="dataSources.form.register.text" />
      }
    }
  }

  const getMessageContent = () => {
    if (registerOrUpdateStatus === DATASOURCE_CREATE_OR_UPDATE_STATUS.created) {
      return <FormattedMessage id="dataSources.form.register.success" />
    } else if (registerOrUpdateStatus === DATASOURCE_CREATE_OR_UPDATE_STATUS.updated) {
      return <FormattedMessage id="dataSources.form.update.success" />
    } else if (registerOrUpdateStatus === DATASOURCE_CREATE_OR_UPDATE_STATUS.createFailed) {
      return <FormattedMessage id="dataSources.form.register.error" />
    } else if (registerOrUpdateStatus === DATASOURCE_CREATE_OR_UPDATE_STATUS.updateFailed) {
      return <FormattedMessage id="dataSources.form.update.error" />
    } else {
      return <></>
    }
  }

  return (
    <span className="register-or-update" data-test-id="datasources-register-or-update-button">
      <Button
        variant="secondary"
        htmlType="button"
        onClick={registerOrUpdateDS}
        className="btn-register-or-update xs-mr-8"
      >
        {getButtonContent()}
      </Button>
      <Typography as="p" className={cx('status-message', { success, error })}>
        {getMessageContent()}
      </Typography>
    </span>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    registerOrUpdateStatus: state.dataSources.registerOrUpdateStatus
  }
}

const mapDispatchToProps = {
  setRegisterOrUpdate,
  createDatasource,
  updateDatasource
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterOrUpdateButton)
