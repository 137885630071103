const multiplier = 2
export const RowSpan = {
  1: multiplier * 1,
  2: multiplier * 2,
  3: multiplier * 3,
  4: multiplier * 4,
  5: multiplier * 5,
  6: multiplier * 6,
  7: multiplier * 7,
  8: multiplier * 8,
  9: multiplier * 9,
  10: multiplier * 10,
  11: multiplier * 11,
  12: multiplier * 12
}

export enum ChartColors {
  Purple = '#7457F7',
  Orange = '#F25E13',
  Blue = '#009DFF',
  SecondaryText = '#001529cc',
  LightPurple = '#AC8CFF',
  LightBlue = '#72C9FF',
  Yellow = '#F7D060',
  Green = '#5ED1B1',
  DarkGreen = '#00B383',
  Indigo = '#2E55E5',
  DarkRed = '#C03046',
  Gray = '#C5C5C5',
  Red = '#E73550'
}

export const DOC_CLASSIFICATION_COLORS = [
  ChartColors.Purple,
  ChartColors.LightPurple,
  ChartColors.Blue,
  ChartColors.LightBlue,
  ChartColors.Yellow,
  ChartColors.Green,
  ChartColors.DarkGreen,
  ChartColors.Indigo
]

export const STATUS_SCAN_COLORS = [ChartColors.Purple, ChartColors.Blue, ChartColors.Red]

export const GCP_DOCUMENTATION_LINK =
  'https://docs.lightbeam.ai/lxqobxw6ak7CTnsQjikH/core-features/spectra-ai/data-sources/cloud-platforms/gcp-auto-discovery#appendix'

export const AWS_DOCUMENTATION_LINK =
  'https://docs.lightbeam.ai/lxqobxw6ak7CTnsQjikH/core-features/spectra-ai/data-sources/cloud-platforms/aws-auto-discovery#appendix'
