import {
  FetchRequestsParams,
  ISendRopaRequestParams,
  RopaEntityTypesParams,
  RopaProcess,
  RopaProcessesParams,
  RopaQuestionnaireTopic,
  RopaQuestionnaireTopicQuestion,
  RopaReport,
  RopaRequest,
  RopaRequestConversation,
  RopaRequestInfo,
  SaveRopaProcessParams,
  SendRopaMessageParams,
  StepCollaborateInfo,
  RopaProcessingActivityDetails,
  RopaRetentionPeriod,
  RopaPurpose,
  RopaDataTransfer,
  RopaSafetyMeasures,
  RopaCustomTopic,
  RopaEntityTypes,
  RopaClassifications,
  RopaAttributes,
  RopaQuestionnaire,
  RopaReportPrint,
  RopaClassificationsParams,
  RopaDataLink,
  RopaDataSourceDetails,
  RopaProcessDataSourcesParams,
  RopaDataSourceInfo,
  RopaDatasourceRequest,
  RejectRequestParams,
  RemoveRequestParams,
  FetchDatasourceRequestsParams,
  RopaAttributesParams,
  RopaRequestStatuses,
  RopaProcessOverviewParams,
  RopaProcessOverview,
  RopaFetchReportsParams,
  RopaProcessCollaborationScope,
  ProcessCollaborationScope,
  RopaTicketsParams,
  RopaTicket
} from './ropaSlice'
import { getProcessStats, generateReportName } from './ropaUtil'
import type {
  DataSourceDataPropertyFieldTypeEnum,
  RopaQuestionnaireOptionalTopics,
  SimplifiedAttributeSetAttribute
} from './ropaSliceV2'
import { AttributeSet, AttributeSetAttribute } from '../attributes/attributesSlice'
import { GRAPHQL_API_FILTERS, LIMIT_DEFAULT, PAGE, SENSITIVE_LABEL } from '../../constants'
import { CreateRopaParams } from '../../interfaces'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { ClassificationGroupedItem } from '../classifications/classificationsSlice'
import { escapeNewlineandTab } from '../../utils/stringUtil'
import { randHex } from '../../utils/mathUtils'
import type { GqlConnection } from '../../utils/types'
import { mapDataPropertyFieldTypeToStateKey } from '../pia/piaSlice'
import { gql } from 'graphql-request'

export const mutationStartROPA = ({ processId, dueDate, name, owner }: CreateRopaParams) => {
  return gql`
    mutation {
      createRopa(clientMutationId: "clientMutationId", ropaData: {
        name: "${name}"
        owner: "${owner}"
        processActivityId: "${processId}"
        dueDate: "${dueDate}"
      }) {
        clientMutationId
        reportId
      }
    }
  `
}
export const queryRopaAttributes = (params: RopaAttributesParams): string => {
  let queryParam = ''
  if (params.dataSourceIds.length) {
    queryParam += `(datasourceIds:${JSON.stringify(params.dataSourceIds)})`
  }
  return gql`
    {
      attribute${queryParam} {
        count
        edges {
          node {
            id
            name
            sensitivityLabel
            ${
              params.forDataElements
                ? `
              datasourceCounts {
                count
                datasource {
                  edges {
                    node {
                      id
                      name
                      type
                    }
                  }
                }
              }`
                : ''
            }
            attributeSets(first: 1) {
              count
            }
          }
        }
      }
    }
  `
}
export const mapQueryRopaAttributes = (
  raw: any,
  { dataSourceIds, print, forDataElements }: RopaAttributesParams
): { attributes: AttributeSetAttribute[] } => {
  try {
    const distinctAttributes = {}
    raw.attribute?.edges?.forEach(({ node: attr }) => {
      const datasources = new Set<string>()
      const instanceCount =
        dataSourceIds.length > 0
          ? attr.datasourceCounts?.reduce((total, datasourceCount) => {
              if (
                dataSourceIds.find(
                  (datasourceId) => datasourceCount.datasource?.edges[0]?.node?.id == datasourceId
                ) &&
                datasourceCount?.count
              ) {
                datasources.add(datasourceCount.datasource?.edges[0]?.node?.id || '')
                return datasourceCount.count + total
              } else {
                return total
              }
            }, 0) || 0
          : 0
      const attribute = {
        id: attr.id,
        name: attr.name,
        sensitivityLabel: attr.sensitivityLabel,
        instanceCount,
        ...(forDataElements
          ? {
              datasources: attr.datasourceCounts?.map((ds) => ({
                count: ds?.count,
                name: ds?.datasource?.edges?.[0]?.node?.name,
                id: ds?.datasource?.edges?.[0]?.node?.id,
                type: ds?.datasource?.edges?.[0]?.node?.type
              }))
            }
          : {}),
        ...(print ? { totalDataSources: datasources.size } : {})
      }
      distinctAttributes[attr.id] = attribute
    }) || []

    const attrs = Object.values(distinctAttributes) as AttributeSetAttribute[]
    const sortMapper = (a, b) =>
      (b.datasources?.reduce((total, ds) => (total += ds?.count || 0), 0) || 0) -
      (a.datasources?.reduce((total, ds) => (total += ds?.count || 0), 0) || 0)
    const highSensitiveAttrs = attrs
      .filter(({ sensitivityLabel }) => sensitivityLabel === SENSITIVE_LABEL.HIGH)
      .sort(sortMapper)
    const mediumSensitiveAttrs = attrs
      .filter(({ sensitivityLabel }) => sensitivityLabel === SENSITIVE_LABEL.MEDIUM)
      .sort(sortMapper)
    const lowSensitiveAttrs = attrs
      .filter(({ sensitivityLabel }) => sensitivityLabel === SENSITIVE_LABEL.LOW)
      .sort(sortMapper)

    return {
      attributes: [...highSensitiveAttrs, ...mediumSensitiveAttrs, ...lowSensitiveAttrs]
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

type QueryRopaOverviewAttributesInstanceCountResponse = {
  attribute: GqlConnection<{
    id: string
    name: string
    sensitivityLabel: string
    datasourceCounts: {
      count: number
      datasource: GqlConnection<{ id: string }>
    }[]
  }>
}
export const mapQueryRopaOverviewAttributesInstanceCount = (
  raw: QueryRopaOverviewAttributesInstanceCountResponse,
  params: RopaAttributesParams
): SimplifiedAttributeSetAttribute[] => {
  try {
    const { dataSourceIds } = params
    const distinctAttributes = new Map<string, SimplifiedAttributeSetAttribute>()

    raw.attribute?.edges?.forEach(({ node: attr }) => {
      const datasources = new Set<string>()
      const instanceCount =
        dataSourceIds.length > 0
          ? attr.datasourceCounts?.reduce((total, datasourceCount) => {
              if (
                dataSourceIds.find(
                  (datasourceId) => datasourceCount.datasource?.edges[0]?.node?.id == datasourceId
                ) &&
                datasourceCount?.count
              ) {
                datasources.add(datasourceCount.datasource?.edges[0]?.node?.id || '')
                return datasourceCount.count + total
              } else {
                return total
              }
            }, 0) || 0
          : 0

      distinctAttributes.set(attr.id, {
        id: attr.id,
        name: attr.name,
        sensitivityLabel: attr.sensitivityLabel,
        instanceCount,
        datasources: attr.datasourceCounts?.map((ds) => ds.datasource.edges[0].node.id)
      })
    })

    return [...distinctAttributes.values()]
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }
}

export const queryRopaClassifications = (params: RopaClassificationsParams): string => {
  const filterDsIds =
    params.dataSourceIds.length > 0
      ? `(filter: [{key: ${GRAPHQL_API_FILTERS.dataSourceIds}, values: ${JSON.stringify(
          params.dataSourceIds
        )}}])`
      : ''
  return gql`
  {
    objectGroupByClassificationType${filterDsIds}{
      classification
      subclass
      objectCount
    }
  }
  `
}
export const mapQueryRopaClassifications = (raw: any): ClassificationGroupedItem[] => {
  try {
    return (
      raw.objectGroupByClassificationType?.map((cl) => ({
        name: cl.classification,
        count: cl.objectCount,
        subClass: cl.subclass
      })) || []
    )
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryRopaEntityTypes = (params?: RopaEntityTypesParams): string => {
  params
  return gql`
    {
      entityType(first: 999) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `
}

export const mapQueryRopaEntityTypes = (raw: any): AttributeSet[] => {
  try {
    const entityTypes = raw.entityType.edges.map(({ node: entityType }) => ({
      id: entityType.id,
      name: entityType.name
    }))

    return entityTypes
  } catch (error) {
    console.error(error)
    throw error
  }
}

const getQuestionsQuery = (questions: RopaQuestionnaireTopicQuestion[] = []) => {
  return questions
    .map(({ statement, isOptional, questionId, responseType, responses, value = '' }) => {
      return `{
        ${!questionId || questionId?.length <= 6 ? `` : `questionId: "${questionId}"`}
        question: "${statement}"
        options: ${JSON.stringify(responses)}
        questionResponse: ${value ? `["${value}"]` : `[]`}
        responseType: ${responseType}
        isMandatory: ${!isOptional}
      }`
    })
    .join()
}

/** Questionnaire topic fragments */
export const FRAGMENT_TOPIC_PROCESS_DETAILS = gql`processDetails {
  isController
  processingActivityDetails {
    managerType
    details {
      name
      email
      address
      contact
      roleType
    }
  }
}`

export const FRAGMENT_TOPIC_QUESTION = gql`questions {
  edges {
    node {
      questionId
      question
      options
      responseType
      isMandatory
      questionResponse
    }
  }
}`

export const FRAGMENT_QUESTIONNAIRE = gql`ropaTopics {
  attributes {
    edges {
      node {
        id
        description
        allowModification
        attributes {
          edges {
            node {
              id
              name
              instanceCount
              sensitivityLabel
              datasourceCounts {
                datasource {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  classificationObjects {
    edges {
      node {
        topicId
        description
        allowModification
        classificationIds {
          edges {
            node {
              id
              class
            }
          }
        }
      }
    }
  }
  entityTypes {
    edges{
      node {
        topicId
        description
        allowModification
        entityIds {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
  purpose {
    edges {
      node {
        topicId
        description
        descriptionDeleted
        label
        purpose
        purposeOptions
        selection
        ${FRAGMENT_TOPIC_QUESTION}
      }
    }
  }
  retentionPeriod {
    edges {
      node {
        topicId
        description
        descriptionDeleted
        label
        retentionPolicy
        retentionPolicyOptions
        ${FRAGMENT_TOPIC_QUESTION}
      }
    }
  }
  dataLink {
    edges {
      node {
        topicId
        processingStage
        associatedDatasources {
          sourceDatasource {
            sourceId {
              edges {
                node {
                  id
                  name
                  type
                  lastModifiedTime
                  lastSyncedTime
                }
              }
            }
            processingStage
            datasourceLocation
            description
            descriptionDeleted
          }
          targetDatasource {
            sourceId {
              edges {
                node {
                  id
                  name
                  type
                  lastModifiedTime
                  lastSyncedTime
                }
              }
            }
            processingStage
            datasourceLocation
            description
            descriptionDeleted
          }
        }
        ${FRAGMENT_TOPIC_QUESTION}
      }
    }
  }
  datatransferDetails {
    edges {
      node {
        topicId
        details {
          description
          descriptionDeleted
          name
          address
          safeguard
          categoryOfRecipients
          transferRetentionPeriod
          dataTransferRecipients
          nameLabel
          addressLabel
          safeguardLabel
          safeguardOptions
          categoryOfRecipientsLabel
          transferRetentionPeriodLabel
          transferRetentionPeriodOptions
        }
        ${FRAGMENT_TOPIC_QUESTION}
      }
    }
  }
  safetyMeasure {
    edges {
      node {
        topicId
        description
        descriptionDeleted
        safetyMeasures
        label
        safetyMeasureOptions
        ${FRAGMENT_TOPIC_QUESTION}
      }
    }
  }
  customTopic {
    edges {
      node {
        topicId
        name
        description
        optional
        ${FRAGMENT_TOPIC_QUESTION}
      }
    }
  }
}`

export const FRAGMENT_DATA_SOURCES_INFO = gql`
  {
    id
    dsoEmail
    datasource {
      edges {
        node {
          id
          name
          type
          location
          createdBy
          lastSyncedTime
          state
          ropaDatasourceType
        }
      }
    }
  }
`

export const FRAGMENT_QUESTIONS_INFO = gql`
  edges{
    node{
      question
      questionResponse
      processQuestionId
    }
  }
`

export const FRAGMENT_QUESTIONS = gql`
  edges{
    node{
      questionId
      question
      isMandatory
      processQuestionId
    }
  }
`
const getProcessActivityDetailsFragment = (processActivity: RopaProcessingActivityDetails) => {
  const detailsQuery = processActivity.details
    .map(
      (manager) => `{
        name: "${manager.name}",
        email: "${manager.email}",
        contact: "${manager.contact}",
        address: "${manager.address}",
        roleType: ${manager.roleType}
      }`
    )
    .join()

  return `{\
    managerType: ${processActivity.managerType},\
    details: [${detailsQuery}]
  }`
}
const getAttributesFragment = (attributes?: RopaAttributes) => {
  if (!attributes) return ''

  const attributeIds = attributes?.attributeIds || []
  const questionsQuery = getQuestionsQuery(attributes?.questions)

  return `attributes: {
      ${attributes.topicId ? 'topicId: "' + attributes.topicId + '"' : ''}
      attributeIds: ${JSON.stringify(attributeIds)}
      description: ""
      questions: [${questionsQuery}]
    }`
}
const getClassificationsFragment = (classificationObjects?: RopaClassifications) => {
  if (!classificationObjects) return ''

  const questionsQuery = getQuestionsQuery(classificationObjects?.questions)
  return `classificationObjects: {
      ${classificationObjects.topicId ? 'topicId: "' + classificationObjects.topicId + '"' : ''}
      classificationIds: ${JSON.stringify(classificationObjects?.classificationIds || [])}
      description: ""
      questions: [${questionsQuery}]
    }`
}
const getEntityTypesFragment = (entityTypes?: RopaEntityTypes) => {
  if (!entityTypes) return ''

  const questionsQuery = getQuestionsQuery(entityTypes?.questions)

  return `entityTypes: {
    ${entityTypes.topicId ? 'topicId: "' + entityTypes.topicId + '"' : ''}
    entityIds: ${JSON.stringify(entityTypes?.entityIds || [])}
    description: ""
    questions: [${questionsQuery}]
  }`
}
const getPurposeFragment = (purpose?: RopaPurpose) => {
  if (!purpose) return ''

  const {
    topicId = '',
    type = '',
    customValue = '',
    label = '',
    options = [],
    questions = [],
    description = '',
    descriptionDeleted = false
  } = purpose

  const questionsQuery = getQuestionsQuery(questions)
  return `purpose: {
    ${topicId ? 'topicId: "' + topicId + '"' : ''}
    purpose: "${customValue || type}"
    descriptionDeleted: ${descriptionDeleted}
    description: "${descriptionDeleted ? '' : escapeNewlineandTab(description)}"
    label: "${label}"
    purposeOptions:${JSON.stringify(options)}
    questions: [${questionsQuery}]
  }`
}
const getRetentionPeriodFragment = (retentionPeriod?: RopaRetentionPeriod) => {
  if (!retentionPeriod) return ''

  const {
    topicId = '',
    retentionPolicy = '',
    customValue = '',
    label = '',
    retentionPolicyOptions = [],
    questions = [],
    description = '',
    descriptionDeleted = false
  } = retentionPeriod
  const questionsQuery = getQuestionsQuery(questions)

  return `retentionPeriod: {
    ${topicId ? 'topicId: "' + topicId + '"' : ''}
    retentionPolicy: "${customValue || retentionPolicy}"
    descriptionDeleted: ${descriptionDeleted}
    description: "${descriptionDeleted ? '' : escapeNewlineandTab(description)}"
    questions: [${questionsQuery}]
    retentionPolicyOptions:${JSON.stringify(retentionPolicyOptions)}
    label: "${label}"
  }`
}
const getDataLinkFragment = (dataLink?: RopaDataLink) => {
  if (!dataLink) return ''

  const { sourceDataSource = [], targetDataSource = [], topicId = '', processingStage } = dataLink
  const questionsQuery = getQuestionsQuery(dataLink?.questions)

  const getDsQuery = (ds: RopaDataSourceDetails) => {
    return `{
              sourceId: "${ds.dataSourceId}",
              processingStage: "${ds.processingStage}",
              datasourceLocation: "${ds.datasourceLocation}",
              description: "${
                ds.descriptionDeleted ? '' : escapeNewlineandTab(ds.description || '')
              }",
              descriptionDeleted: ${!!ds.descriptionDeleted}
            }`
  }

  const sourceDataSourceQuery = sourceDataSource
    .filter((ds) => ds.dataSourceId && ds.processingStage)
    .map((ds) => getDsQuery(ds))
    .join()
  const targetDataSourceQuery = targetDataSource
    .filter((ds) => ds.dataSourceId && ds.processingStage)
    .map((ds) => getDsQuery(ds))
    .join()

  return `dataLink: {
    ${topicId ? 'topicId: "' + topicId + '"' : ''}
    processingStage: "${processingStage}"
    associatedDatasources: {
      sourceDatasource: [${sourceDataSourceQuery}]
      targetDatasource: [${targetDataSourceQuery}]
    }
    questions: [${questionsQuery}]
  }`
}
const getDataTransferDetailsFragment = (dataTransferDetails?: RopaDataTransfer) => {
  if (!dataTransferDetails) return ''

  const { details = [], topicId = '' } = dataTransferDetails
  const questionsQuery = getQuestionsQuery(dataTransferDetails?.questions)

  const detailsQuery = details
    .map(
      (d) => `{
                name: "${d.name}",
                address: "${d.address}",
                safeguardOptions: ${JSON.stringify([])}
              }`
    )
    .join()

  return `dataTransferDetails: {
    ${topicId ? 'topicId: "' + topicId + '"' : ''}
    details: [${detailsQuery}]
    questions: [${questionsQuery}]
  }`
}
const getSafetyMeasuresFragment = (safetyMeasuresParams?: RopaSafetyMeasures) => {
  if (!safetyMeasuresParams) return ''

  const {
    topicId = '',
    safetyMeasures = '',
    customValue = '',
    label = '',
    safetyMeasuresOptions = [],
    questions = [],
    description = '',
    descriptionDeleted = false
  } = safetyMeasuresParams
  const questionsQuery = getQuestionsQuery(questions)

  return `safetyMeasures: {
    ${topicId ? 'topicId: "' + topicId + '"' : ''}
    safetyMeasures:  "${customValue || safetyMeasures}"
    descriptionDeleted: ${descriptionDeleted}
    description: "${descriptionDeleted ? '' : escapeNewlineandTab(description)}"
    questions: [${questionsQuery}]
    label: "${label}"
    safetyMeasureOptions:${JSON.stringify(safetyMeasuresOptions)}
  }`
}
const getCustomTopicFragment = (customTopic: RopaCustomTopic) => {
  return `{\
    ${customTopic.topicId ? 'topicId: "' + customTopic.topicId + '",' : ''}
    name: "${customTopic.name}", \
    description: "${escapeNewlineandTab(customTopic.description || '')}",\
    optional: ${!!customTopic.optional}, \
    questions: [${getQuestionsQuery(customTopic.questions)}]\
  }`
}
const getProcessDataSourcesInfoFragment = (datasourcesInfo?: RopaDataSourceInfo[]) => {
  if (!datasourcesInfo?.length) return ''

  return datasourcesInfo
    ?.map((item) => {
      const email = item.dsoEmail ? `dsoEmail: "${item.dsoEmail || ''}",` : ''
      const datasourceId = item.datasource?.id
        ? `datasourceId: "${item.datasource?.id || ''}",`
        : ''
      return `{${email}${datasourceId}}`
    })
    .join()
}

const mapQuestionnaireToUiSchema = (topics): RopaQuestionnaire => {
  if (!topics) return {}

  const questionnaireUi = {
    processDetails: {
      topicId: topics.processDetails.topicId || '',
      processName: {
        label: topics.processDetails.processName?.label || ''
      },
      processDescription: {
        label: topics.processDetails?.processDescription?.label || ''
      },
      processGroup: {
        label: topics.processDetails?.processGroup?.label || '',
        options: topics.processDetails?.processGroup?.options || []
      },
      purpose: {
        label: topics.processDetails?.purpose?.label || '',
        options: topics.processDetails?.purpose?.options || []
      },
      lawfulBasis: {
        label: topics.processDetails?.lawfulBasis?.label || '',
        options: topics.processDetails?.lawfulBasis?.options || []
      },
      isAutomatedProcessingEnabled: {
        label: topics.processDetails?.isAutomatedProcessingEnabled?.label || ''
      },
      automatedProcessingDescription: {
        label: topics.processDetails?.automatedProcessingDescription.label || ''
      }
    },

    retentionPeriod: {
      topicId: topics.retentionPeriod?.edges[0]?.node?.topicId || '',
      label: topics.retentionPeriod?.edges[0]?.node?.label || '',
      retentionPolicy: topics.retentionPeriod?.edges[0]?.node?.retentionPolicy || '',
      retentionPolicyOptions: topics.retentionPeriod?.edges[0]?.node?.retentionPolicyOptions || [],
      description: topics.retentionPeriod?.edges[0]?.node?.description || '',
      descriptionDeleted: topics.retentionPeriod?.edges[0]?.node?.descriptionDeleted || false,
      questions: mapQuestionnaireTopicQuestionsToUI(
        topics.retentionPeriod?.edges[0]?.node?.questions
      )
    },

    dataTransferDetails: {
      topicId: topics.datatransferDetails?.edges[0]?.node?.topicId || '',
      details:
        topics.datatransferDetails?.edges[0]?.node?.details.map((details) => ({
          name: details.name,
          address: details.address,
          safeguard: details.safeguard,
          safeguardOptions: details.safeguardOptions,
          categoryOfRecipients: details.categoryOfRecipients,
          transferRetentionPeriod: details.transferRetentionPeriod,
          transferRetentionPeriodOptions: details.transferRetentionPeriodOptions,
          dataTransferRecipients: details.dataTransferRecipients,
          description: details.description,
          descriptionDeleted: details.descriptionDeleted,
          nameLabel: details.nameLabel,
          addressLabel: details.addressLabel,
          safeguardLabel: details.safeguardLabel,
          categoryOfRecipientsLabel: details.categoryOfRecipientsLabel,
          transferRetentionPeriodLabel: details.transferRetentionPeriodLabel
        })) || [],
      questions: mapQuestionnaireTopicQuestionsToUI(
        topics.datatransferDetails?.edges[0]?.node?.questions
      )
    },
    safetyMeasures: {
      topicId: topics.safetyMeasure?.edges[0]?.node?.topicId || '',
      safetyMeasures: topics.safetyMeasure?.edges[0]?.node?.safetyMeasures || '',
      safetyMeasuresOptions: topics.safetyMeasure?.edges[0]?.node?.safetyMeasureOptions || [],
      label: topics.safetyMeasure?.edges[0]?.node?.label || '',
      description: topics.safetyMeasure?.edges[0]?.node?.description || '',
      descriptionDeleted: topics.safetyMeasure?.edges[0]?.node?.descriptionDeleted || false,
      questions: mapQuestionnaireTopicQuestionsToUI(topics.safetyMeasure?.edges[0]?.node?.questions)
    },
    topics: topics.customTopic?.edges?.map(({ node: topic }) => ({
      topicId: topic.topicId || '',
      name: topic.name,
      description: topic.description,
      optional: topic.optional,
      questions: mapQuestionnaireTopicQuestionsToUI(topic.questions)
    }))
  }

  return questionnaireUi
}

const mapDataSourceInfoToUi = (datasources): RopaDataSourceInfo[] => {
  if (!datasources?.length) return []

  return (
    datasources.map((item) => {
      let datasource: RopaDataSourceInfo['datasource'] | null = null

      if (item.datasource?.edges[0]?.node?.id) {
        datasource = {
          id: item.datasource?.edges[0]?.node?.id,
          name: item.datasource?.edges[0]?.node?.name || '',
          type: item.datasource?.edges[0]?.node?.type || '',
          location: item.datasource?.edges[0]?.node?.location || '',
          createdBy: item.datasource?.edges[0]?.node?.createdBy || '',
          lastSyncedTime: item.datasource?.edges[0]?.node?.lastSyncedTime || '',
          status: item.datasource?.edges[0]?.node?.state || '',
          ropaDatasourceType: item.datasource?.edges[0]?.node?.ropaDatasourceType || ''
        }
      }

      return {
        id: item.id || randHex(5),
        dsoEmail: item.dsoEmail,
        ...(datasource ? { datasource } : {})
      }
    }) || []
  )
}

/**Queries to update questionnaire topics */
export const queryUpdateQuestionnaireAttributeSets = (process: SaveRopaProcessParams) => {
  const attributeSetsQuery = getAttributesFragment(process.questionnaire?.attributes)

  return gql`
    mutation {
      updateRopaProcessTopic(
        clientMutationId: "clientMutationId",
        processActivityId: "${process.id}",
        processTopicId: "${process.questionnaire?.attributes?.topicId}",
        topicData: { ${attributeSetsQuery} }
      ) {
        clientMutationId
      }
    }
  `
}

export const queryUpdateQuestionnaireClassifications = (process: SaveRopaProcessParams) => {
  const classificationsQuery = getClassificationsFragment(
    process.questionnaire?.classificationObjects
  )

  return gql`
    mutation {
      updateRopaProcessTopic(
        clientMutationId: "clientMutationId",
        processActivityId: "${process.id}",
        processTopicId: "${process.questionnaire?.classificationObjects?.topicId}",
        topicData: { ${classificationsQuery} }
      ) {
        clientMutationId
      }
    }
  `
}

export const queryUpdateQuestionnaireEntityTypes = (process: SaveRopaProcessParams) => {
  const entityTypesQuery = getEntityTypesFragment(process.questionnaire?.entityTypes)

  return gql`
    mutation {
      updateRopaProcessTopic(
        clientMutationId: "clientMutationId",
        processActivityId: "${process.id}",
        processTopicId: "${process.questionnaire?.entityTypes?.topicId}",
        topicData: { ${entityTypesQuery} }
      ) {
        clientMutationId
      }
    }
  `
}

export const queryUpdateQuestionnairePurpose = (process: SaveRopaProcessParams) => {
  const purposeQuery = getPurposeFragment(process.questionnaire?.purpose)

  return gql`
    mutation {
      updateRopaProcessTopic(
        clientMutationId: "clientMutationId",
        processActivityId: "${process.id}",
        processTopicId: "${process.questionnaire?.purpose?.topicId}",
        topicData: { ${purposeQuery} }
      ) {
        clientMutationId
      }
    }
  `
}

export const queryUpdateQuestionnaireRetentionPeriod = (process: SaveRopaProcessParams) => {
  const retentionPeriodQuery = getRetentionPeriodFragment(process.questionnaire?.retentionPeriod)

  return gql`
    mutation {
      updateRopaProcessTopic(
        clientMutationId: "clientMutationId",
        processActivityId: "${process.id}",
        processTopicId: "${process.questionnaire?.retentionPeriod?.topicId}",
        topicData: { ${retentionPeriodQuery} }
      ) {
        clientMutationId
      }
    }
  `
}

export const queryUpdateQuestionnaireDataLink = (process: SaveRopaProcessParams) => {
  const query = getDataLinkFragment(process.questionnaire?.dataLink)

  return gql`
    mutation {
      updateRopaProcessTopic(
        clientMutationId: "clientMutationId",
        processActivityId: "${process.id}",
        processTopicId: "${process.questionnaire?.dataLink?.topicId}",
        topicData: { ${query} }
      ) {
        clientMutationId
      }
    }
  `
}

export const queryUpdateQuestionnaireDataTransferDetails = (process: SaveRopaProcessParams) => {
  const query = getDataTransferDetailsFragment(process.questionnaire?.dataTransferDetails)

  return gql`
    mutation {
      updateRopaProcessTopic(
        clientMutationId: "clientMutationId",
        processActivityId: "${process.id}",
        processTopicId: "${process.questionnaire?.dataTransferDetails?.topicId}",
        topicData: { ${query} }
      ) {
        clientMutationId
      }
    }
  `
}

export const queryUpdateQuestionnaireSafetyMeasures = (process: SaveRopaProcessParams) => {
  const query = getSafetyMeasuresFragment(process.questionnaire?.safetyMeasures)

  return gql`
    mutation {
      updateRopaProcessTopic(
        clientMutationId: "clientMutationId",
        processActivityId: "${process.id}",
        processTopicId: "${process.questionnaire?.safetyMeasures?.topicId}",
        topicData: { ${query} }
      ) {
        clientMutationId
      }
    }
  `
}

export const queryUpdateQuestionnaireCustomTopic = (
  processId: string,
  topic: RopaQuestionnaireTopic
) => {
  const query = `topic: ${getCustomTopicFragment(topic)}`

  return gql`
    mutation {
      updateRopaProcessTopic(
        clientMutationId: "clientMutationId",
        processActivityId: "${processId}",
        processTopicId: "${topic.topicId}",
        topicData: { ${query} }
      ) {
        clientMutationId
      }
    }
  `
}

/** Delete topic question */
export const queryDeleteTopicQuestion = (questionId: string) => {
  return gql`
    mutation {
      deleteRopaProcessTopicQuestion(clientMutationId: "clientMutationId", questionId: "${questionId}") {
        clientMutationId
      }
    }
  `
}

/** CRUD queries for process activity */
export const queryCreateRopaProcess = (process: SaveRopaProcessParams) => {
  const processDetailsQuery =
    process.processDetails?.processingActivityDetails
      ?.map((details) => getProcessActivityDetailsFragment(details))
      .join() || ''

  getAttributesFragment(process.questionnaire?.attributes)
  const attributeSetsQuery = getAttributesFragment(process.questionnaire?.attributes)
  const classificationsQuery = getClassificationsFragment(
    process.questionnaire?.classificationObjects
  )
  const entityTypesQuery = getEntityTypesFragment(process.questionnaire?.entityTypes)
  const purposeQuery = getPurposeFragment(process.questionnaire?.purpose)
  const retentionQuery = getRetentionPeriodFragment(process.questionnaire?.retentionPeriod)
  const dataLinkQuery = getDataLinkFragment(process.questionnaire?.dataLink)
  const dataTransferDetailsQuery = getDataTransferDetailsFragment(
    process.questionnaire?.dataTransferDetails
  )
  const safetyMeasuresQuery = getSafetyMeasuresFragment(process.questionnaire?.safetyMeasures)
  const topicsQuery = process.questionnaire?.topics
    ?.filter(({ _isDeleted }) => !_isDeleted)
    .map((t) => getCustomTopicFragment(t))
    .join()

  const datasources = getProcessDataSourcesInfoFragment(process.datasourcesInfo)

  return gql`
    mutation {
      createRopaProcess(
        clientMutationId: "clientMutationId",
        processData: {
          name: "${process.name}"
          owner: "${process.owner}"
          description: "${escapeNewlineandTab(process.description || '')}"
          purpose: "${process.customPurpose || process.purpose}"
          lawfulBasis: "${process.customLawfulBasis || process.lawfulBasis}"
          departments: ${JSON.stringify(process.departments)}
          processDetails: {
            isController: ${process.processDetails?.isController || true}
            processingActivityDetails: [${processDetailsQuery}]
          }
          datasources: [${datasources}]
          questionnaire: {
            ${attributeSetsQuery}
            ${classificationsQuery}
            ${entityTypesQuery}
            ${purposeQuery}
            ${retentionQuery}
            ${dataLinkQuery}
            ${dataTransferDetailsQuery}
            ${safetyMeasuresQuery}
            topics:  [${topicsQuery}]
          }
          isDraft: ${process.isDraft}
        }
      ) {
        clientMutationId
        processActivityId
      }
    }
  `
}

export const queryUpdateRopaProcess = (process: SaveRopaProcessParams) => {
  const processDetailsQuery = process.processDetails?.processingActivityDetails
    ?.map((details) => getProcessActivityDetailsFragment(details))
    .join()

  const datasources = getProcessDataSourcesInfoFragment(process.datasourcesInfo)

  const nameQuery = process.name ? `name: "${process.name}"` : ''
  const descriptionQuery = process.description
    ? `description: "${escapeNewlineandTab(process.description)}"`
    : ''
  const ownerQuery = process.owner ? `owner: "${process.owner}"` : ''
  const departmentsQuery = process.departments
    ? `departments: ${JSON.stringify(process.departments)}`
    : ''
  const purposeQuery =
    process.customPurpose || process.purpose
      ? `purpose: "${process.customPurpose || process.purpose}"`
      : ''
  const lawfulQuery =
    process.customLawfulBasis || process.lawfulBasis
      ? `lawfulBasis: "${process.customLawfulBasis || process.lawfulBasis}"`
      : ''
  const dsQuery = datasources ? `datasources: [${datasources}]` : ''

  return gql`
    mutation {
      updateRopaProcess(
        clientMutationId: "clientMutationId",
        processActivityId: "${process.id}"
        processData: {
          processDetails: {
            isController: ${process.processDetails?.isController}
            processingActivityDetails: [${processDetailsQuery}]
          }
          isDraft: ${process.isDraft}
          ${nameQuery}
          ${descriptionQuery}
          ${ownerQuery}
          ${purposeQuery}
          ${lawfulQuery}
          ${departmentsQuery}
          ${dsQuery}
        }
      ) {
        clientMutationId
        processActivityId
      }
    }
  `
}

export const queryDeleteRopaProcess = (id: string) => {
  return gql`
    mutation {
      deleteRopaProcess(clientMutationId: "deleteProcess", processActivityId: "${id}") {
        clientMutationId
        isDeleted
        message
      }
    }
  `
}

export const queryListProcesses = (params: RopaProcessesParams) => {
  const cursor = getAfterCursor(params[PAGE] || 1, LIMIT_DEFAULT)
  const filters = params.id
    ? `id: "${params.id}"`
    : `booleanFilter: [{
    key: IS_SYSTEM_DEFINED,
    value: ${params.isSystemDefined}
  },{key: IS_DELETED, value: false}]`
  const pageParams =
    params[PAGE] === undefined ? '' : `first: ${LIMIT_DEFAULT}, after: "${cursor}",`

  return gql`{
    ropaProcess ( ${pageParams} ${filters}) {
      count
      edges {
        node {
          id
          name
          updatedAt
          createdAt
          createdBy
          processGroups {
            edges {
              node {
                value
              }
            }
          }
        }
      }
    }
  }`
}

export const mapQueryProcessesGallery = (raw: any): { list: RopaProcess[]; total: number } => {
  try {
    const list: RopaProcess[] =
      raw.ropaProcess?.edges?.map(({ node: process }) => {
        return {
          id: process.id,
          name: process.name,
          description: process.description || '',
          owner: process.createdBy || '',
          updatedAt: process.updatedAt || '',
          createdAt: process.createdAt || '',
          processGroups:
            process.processGroups.edges.map((processGroup) => processGroup.node.value || '') || [],
          systemDefined: process.systemDefined || false
        }
      }) || []
    return { list, total: raw.ropaProcess.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryRopaListValues = (fieldTypes: Array<DataSourceDataPropertyFieldTypeEnum>) => {
  return gql`
    {
      datasourceDataProperty(fieldTypes: [${fieldTypes.join(',')}]) {
        edges {
          node {
            id
            value
            fieldType
          }
        }
      }
    }
  `
}

export const mapQueryRopaListValues = (raw: any): any => {
  try {
    const listValues = {}
    raw.datasourceDataProperty?.edges?.forEach(({ node }) => {
      const key = mapDataPropertyFieldTypeToStateKey[node.fieldType]
      if (node.value) {
        if (listValues[key])
          listValues[key] = [
            ...listValues[key],
            {
              id: node.id || '',
              name: node.value || ''
            }
          ]
        else
          listValues[key] = [
            {
              id: node.id || '',
              name: node.value || ''
            }
          ]
      }
    })
    return listValues
  } catch (error) {
    console.error(error)
    throw error
  }
}

/** Fetch processes queries */
export const queryRopaProcesses = (params: RopaProcessesParams) => {
  const cursor = getAfterCursor(params[PAGE] || 1, LIMIT_DEFAULT)
  const filters = params.id
    ? `id: "${params.id}"`
    : `booleanFilter: [{
    key: IS_SYSTEM_DEFINED,
    value: ${params.isSystemDefined || false}
  },{key: IS_DELETED, value: false}]`
  const pageParams =
    params[PAGE] === undefined ? '' : `first: ${LIMIT_DEFAULT}, after: "${cursor}",`
  return gql`{
    ropaProcess ( ${pageParams} ${filters}) {
      count
      edges {
        node {
          id
          name
          description
          isDraft
          systemDefined
          departments
          updatedAt
          createdBy
          lawfulBasis
          purpose
          ${FRAGMENT_TOPIC_PROCESS_DETAILS}
          datasources ${FRAGMENT_DATA_SOURCES_INFO}
          ${FRAGMENT_QUESTIONNAIRE}
        }
      }
    }
  }`
}

// map API response to UI
const mapQuestionnaireTopicQuestionsToUI = (
  questionsResponse
): RopaQuestionnaireTopicQuestion[] => {
  if (!questionsResponse) return []

  return questionsResponse.edges.map(({ node: question }) => ({
    questionId: question.questionId,
    responses: question.options,
    responseType: question.responseType,
    isOptional: !question.isMandatory,
    value: question.questionResponse ? question.questionResponse[0] : '',
    statement: question.question
  }))
}

export const getRopaCollaborateInfo = (id: string) => gql`{
  ropa(id: "${id}") {
    edges {
      node {
        name
        status
        dueDate
        processActivity {
          edges {
            node {
              id
              name
              createdBy
              createdAt
              description
              departments
              updatedAt
              isDraft
              datasources ${FRAGMENT_DATA_SOURCES_INFO}
              ${FRAGMENT_TOPIC_PROCESS_DETAILS}
            }
          }
        }
        datasourcesResponse {
          count
          edges {
            node {
              id
              taskId
              reportId
              dueDate
              status
              responseReceiveDate
              requestSentDate
              reportSubmittedByRole
              ${FRAGMENT_QUESTIONNAIRE}
              alerts {
                message
                severity
              }
              ropaDatasource ${FRAGMENT_DATA_SOURCES_INFO}
            }
          }
        }
      }
    }
  }
}`

export const mapGetRopaCollaborateInfo = (raw: any): StepCollaborateInfo => {
  try {
    const ropa = raw.ropa.edges[0]?.node || {}
    const process = ropa?.processActivity.edges[0]?.node || {}
    const processDatasources = mapDataSourceInfoToUi(process.datasources)

    const attributes = [
      ...new Map(
        (
          ropa?.datasourcesResponse?.edges?.flatMap(
            (dataSource) =>
              dataSource.node?.ropaTopics?.attributes?.edges[0]?.node?.attributes?.edges?.map(
                (attr) => attr.node
              ) || []
          ) || []
        ).map((item) => [item.id, item])
      ).values()
    ]

    const entityTypes = [
      ...new Map(
        (
          ropa?.datasourcesResponse?.edges?.flatMap(
            (dataSource) =>
              dataSource.node?.ropaTopics?.entityTypes?.edges[0]?.node?.entityIds?.edges?.map(
                (entity) => entity.node
              ) || []
          ) || []
        ).map((item) => [item.id, item])
      ).values()
    ]

    const classifications = [
      ...new Map(
        (
          ropa?.datasourcesResponse?.edges?.flatMap(
            (dataSource) =>
              dataSource.node?.ropaTopics?.classificationObjects?.edges[0]?.node?.classificationIds?.edges?.map(
                (classification) => classification.node
              ) || []
          ) || []
        ).map((item) => [item.id, item])
      ).values()
    ]

    const datasourcesResponse = ropa?.datasourcesResponse?.edges?.map(({ node }) => ({
      dataSourceResponseId: node.id || '',
      responseReceiveDate: node.responseReceiveDate || '',
      reportSubmittedByRole: node.reportSubmittedByRole || '',
      requestSentDate: node.requestSentDate || '',
      taskId: node.taskId || '',
      dueDate: node.dueDate || '',
      notifications: node.alerts || [],
      status: node.status || '',
      questionnaire: mapQuestionnaireToUiSchema(node.ropaTopics),
      datasourceInfo: mapDataSourceInfoToUi([node.ropaDatasource])[0]
    }))

    const processDatasourceIds = (
      processDatasources?.map(({ datasource }) => datasource?.id || '') || []
    ).filter((id) => !!id)
    const responseDatasourceIds = (
      datasourcesResponse?.map(({ datasourceInfo }) => datasourceInfo?.datasource?.id || '') || []
    ).filter((id) => !!id)
    const datasourceIdsCombinedCount = new Set([...processDatasourceIds, ...responseDatasourceIds])
      .size
    const isRopaUpdated =
      datasourceIdsCombinedCount > responseDatasourceIds.length ||
      datasourceIdsCombinedCount > processDatasourceIds.length

    const result = {
      status: ropa?.status || '',
      ropaName: ropa?.name || '',
      isRopaUpdated: isRopaUpdated,
      dueDate: ropa?.dueDate || '',
      attributesCount: attributes.length,
      classificationsCount: classifications.length,
      entityTypesCount: entityTypes.length,
      datasourcesCount: ropa?.datasourcesResponse?.count
        ? ropa?.datasourcesResponse?.count > 1
          ? ropa?.datasourcesResponse?.count
          : ropa?.datasourcesResponse?.edges[0]?.node?.taskId
          ? 1
          : 0
        : 0,
      datasourcesRespondedCount: (
        ropa?.datasourcesResponse?.edges?.filter(({ node }) => node.status == 'complete') || []
      ).length,
      datasourceResponseId:
        ropa?.datasourcesResponse?.edges[0]?.ropaDatasource?.edges[0]?.node?.datasource?.id || '',
      reportId: ropa?.datasourcesResponse?.edges[0]?.node?.reportId || '',
      processSummary: {
        id: process.id,
        name: process.name,
        owner: process.createdBy,
        createdAt: process.createdAt,
        description: process.description,
        departments: process.departments,
        updatedAt: process.updatedAt,
        datasourcesInfo: processDatasources,
        processDetails: process.processDetails,
        isDraft: process.isDraft
      },
      alerts: ropa?.alerts || [],
      datasourcesResponse,
      datasourceInfoCombined: [
        ...processDatasources.filter((processDs) => {
          return !datasourcesResponse.find(
            ({ datasourceInfo }) => processDs?.dsoEmail === datasourceInfo?.dsoEmail
          )
        }),
        ...datasourcesResponse.map(({ datasourceInfo }) => datasourceInfo).flat()
      ]
    }
    return result
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getListRopaRequests = ({ [PAGE]: page, complete, dsoEmail }: FetchRequestsParams) => {
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  const dsoEmailFilter = dsoEmail ? `dsoEmail: "${dsoEmail}",` : ''
  const dsoFilter = `,dsoInfo: {${dsoEmailFilter} drStatus: ${
    complete ? 'COMPLETE' : 'IN_PROGRESS'
  }}`
  const datasourcesResponseFilter = dsoEmail
    ? `(filter: [{key: DSO_EMAIL, values: ["${dsoEmail}"]}])`
    : ''

  return gql`
    {
      ropa(first: ${LIMIT_DEFAULT}, after: "${cursor}"${dsoFilter}) {
        count
        edges {
          node {
            id
            name
            createdAt
            dueDate
            processActivity {
              edges {
                node {
                  name
                }
              }
            }
            datasourcesResponse${datasourcesResponseFilter} {
              count
              edges {
                node {
                  id
                  status
                  taskId
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapGetListRopaRequests = (raw: any): { total: number; list: RopaRequest[] } => {
  try {
    const ropas = raw.ropa?.edges || []
    return {
      total: raw.ropa?.count || 0,
      list:
        ropas?.map((ropa) => ({
          id: ropa?.node?.id || '',
          name: ropa?.node?.name || '',
          receivedAt: ropa?.node?.createdAt || '',
          dueDate: ropa?.node?.dueDate || '',
          process: ropa?.node?.processActivity?.edges[0]?.node?.name || '',
          totalDatasources: ropa?.node?.datasourcesResponse?.count
            ? ropa?.node?.datasourcesResponse?.count > 1
              ? ropa?.node?.datasourcesResponse?.count
              : ropa?.node?.datasourcesResponse?.edges[0]?.node?.taskId
              ? 1
              : 0
            : 0,
          totalDatasourcesCompleted: (
            ropa?.node?.datasourcesResponse?.edges?.filter(({ node }) =>
              [
                RopaRequestStatuses.revoked,
                RopaRequestStatuses.complete,
                RopaRequestStatuses.rejected
              ].find((closedStatus) => closedStatus == node.status)
            ) || []
          ).length
        })) || []
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getRopaDatasourceRequests = ({
  reportId,
  dsoEmail
}: FetchDatasourceRequestsParams) => {
  const dsoFilter = dsoEmail ? `, dsoInfo: {dsoEmail: "${dsoEmail}"}` : ''
  const datasourcesResponseFilter = dsoEmail
    ? `(filter: [{key: DSO_EMAIL, values: ["${dsoEmail}"]}])`
    : ''

  return gql`{
    ropa(id: "${reportId}"${dsoFilter}) {
      edges {
        node {
          name
          dueDate
          createdAt
          processActivity {
            edges {
              node {
                name
              }
            }
          }
          datasourcesResponse${datasourcesResponseFilter} {
            count
            edges {
              node {
                id
                status
                ropaDatasource ${FRAGMENT_DATA_SOURCES_INFO}
              }
            }
          }
        }
      }
    }
  }`
}

export const mapGetRopaDatasourceRequests = (
  raw: any
): { list: RopaDatasourceRequest[]; total: number; ropa: string; process: string } => {
  try {
    const ropa = raw.ropa?.edges[0]?.node || {}
    const datasources = ropa.datasourcesResponse || []
    const list = datasources?.edges?.map(({ node }) => {
      return {
        id: node.id || '',
        dsoEmail: node.ropaDatasource?.dsoEmail || '',
        datasourceId: node.ropaDatasource?.datasource?.edges[0]?.node?.id || '',
        datasourceName: node.ropaDatasource?.datasource?.edges[0]?.node?.name || '',
        datasourceType: node.ropaDatasource?.datasource?.edges[0]?.node?.type || '',
        receivedAt: ropa.createdAt || '',
        dueDate: ropa.dueDate || '',
        status: node.status || ''
      }
    })
    return {
      list,
      total: datasources.count,
      ropa: ropa.name || '',
      process: ropa.processActivity?.edges[0]?.node?.name || ''
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const sendRopaDatasourceRequest = ({
  reportId,
  datasources,
  emailBody,
  dueDate,
  role
}: ISendRopaRequestParams): any => {
  const datasourcesQuery = datasources
    ?.map((ds) => {
      const email = `dsoEmail: "${ds.dsoEmail || ''}",`
      const datasourceId = ds.datasourceId ? `datasourceId: "${ds?.datasourceId || ''}"` : ''
      return `{${email}${datasourceId}}`
    })
    .join()

  const emailBodyQuery = emailBody ? `emailBody: "${emailBody}"` : ''
  const dueDateQuery = emailBody ? `dueDate: "${dueDate}"` : ''

  return gql`
  mutation {
    createRopaDatasourceOwnerRequest(
      clientMutationId: "clientMutationId"
      reportId: "${reportId}"
      requestData: {
        datasources: [${datasourcesQuery}]
        role: ${role}
        ${emailBodyQuery}
        ${dueDateQuery}
      }
    ) {
      clientMutationId
    }
  }
`
}

export const queryRopaDataSourceResponse = (dsResponseId: string) => {
  return gql`
    {
      ropaDatasourceResponse(id: "${dsResponseId}") {
        edges {
          node {
            id
            reportId
            dueDate
            createdAt
            taskId
            status
            isDraft
            reportSubmittedByRole
            processActivity {
              edges {
                node {
                  id
                  name
                  createdBy
                }
              }
            }
            ropaDatasource ${FRAGMENT_DATA_SOURCES_INFO}
            ${FRAGMENT_QUESTIONNAIRE}
          }
        }
      }
    }
  `
}

export const mapQueryRopaDataSourceResponse = (raw: any): RopaRequestInfo => {
  try {
    const response = raw.ropaDatasourceResponse.edges[0]?.node
    const topics = response?.ropaTopics || {}
    const { name: definitionName = '', createdBy: definitionOwner = '', id: definitionId } =
      response?.processActivity.edges[0]?.node || {}
    const datasource = response?.ropaDatasource?.datasource?.edges[0]?.node || {}

    const result = {
      id: response.id,
      reportId: response.reportId || '',
      isDraft: response.isDraft || false,
      definitionId,
      definitionName,
      definitionOwner,
      submittedBy: response.reportSubmittedByRole,
      requestDueDate: response?.dueDate || '',
      status: response?.status || '',
      requestReceivedAt: response?.createdAt || '',
      taskId: response?.taskId || '',

      questionnaire: mapQuestionnaireToUiSchema(topics),

      attributes: topics.attributes?.edges[0]?.node.attributes.edges.map((attr) => attr.node) || [],
      attributesTopicId: topics.attributes?.edges[0]?.node.id || '',
      classifications:
        topics.classificationObjects?.edges[0]?.node.classificationIds.edges.map(
          (classification) => ({
            name: classification.node.class,
            id: classification.node.id
          })
        ) || [],
      classificationsTopicId: topics.classificationObjects?.edges[0]?.node.topicId || '',
      entityTypes:
        topics.entityTypes?.edges[0]?.node.entityIds.edges.map((entity) => entity.node) || [],
      entityTypesTopicId: topics.entityTypes?.edges[0]?.node.topicId || '',

      purpose: topics.purpose?.edges[0]?.node.purpose || '',
      purposeOptions: topics.purpose?.edges[0]?.node.purposeOptions || [],
      purposeLabel: topics.purpose?.edges[0]?.node.label || '',
      purposeDescription: topics.purpose?.edges[0]?.node.description,
      safetyMeasure: topics.safetyMeasure?.edges[0]?.node.safetyMeasures || '',
      safetyMeasureOptions: topics.safetyMeasure?.edges[0]?.node.safetyMeasureOptions || '',
      safetyMeasureLabel: topics.safetyMeasure?.edges[0]?.node.label || '',
      safetyMeasureDescription: topics.safetyMeasure?.edges[0]?.node.description,
      retentionPeriod: topics.retentionPeriod?.edges[0]?.node?.retentionPolicy || '',
      retentionPeriodOptions: topics.retentionPeriod?.edges[0]?.node?.retentionPolicyOptions || [],
      retentionPeriodLabel: topics.retentionPeriod?.edges[0]?.node?.label || '',
      retentionPeriodDescription: topics.retentionPeriod?.edges[0]?.node?.description,

      purposeTopicId: topics.purpose?.edges[0]?.node.topicId || '',
      retentionPeriodTopicId: topics.retentionPeriod?.edges[0]?.node?.topicId || '',
      dataLinkTopicId: '',
      safetyMeasureTopicId: topics.safetyMeasure?.edges[0]?.node.topicId || '',

      dataTransferDetails: topics.datatransferDetails?.edges[0]?.node.details || [],
      datatransferDetailsTopicId: topics.datatransferDetails?.edges[0]?.node?.topicId || '',

      dataSourceId: datasource.id,
      dataSourceName: datasource.name,
      dataSourceType: datasource.type,
      dataSourceOwner: datasource.createdBy,
      name: '',
      process: ''
    }
    return result
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getRopaReports = ({ [PAGE]: page, filters }: FetchRequestsParams) => {
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  let paramStr = ''
  if (filterString) {
    paramStr += `, filter: ${filterString}`
  }

  return gql`
    {
      ropa {
        count
      }
      reports: ropa(first: ${LIMIT_DEFAULT}, after: "${cursor}"${paramStr}) {
        count
        edges {
          node {
            id
            name
            status
            createdAt
            updateTimestamp
            processActivity {
              edges {
                node {
                  id
                  name
                  createdBy
                }
              }
            }
            datasourcesResponse {
              edges {
                node {
                  status
                  ropaDatasource ${FRAGMENT_DATA_SOURCES_INFO}
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapGetRopaReports = (
  raw: any
): { totalReports: number; totalActiveReports: number; list: RopaReport[] } => {
  try {
    const filteredReports = raw.reports?.edges.filter(
      ({ node: report }) => !!report?.processActivity?.edges[0]?.node?.id
    )

    return {
      totalReports: filteredReports.length || 0,
      totalActiveReports: filteredReports?.length || 0,
      list:
        filteredReports.map((report) => {
          return {
            id: report?.node.id || '',
            name: report?.node.name || '',
            status: report?.node.status || '',
            createdAt: report?.node.createdAt || '',
            generatedOn: report?.node.status == 'complete' ? report?.node.updateTimestamp : '',
            processName: report?.node.processActivity.edges[0]?.node?.name || '',
            processOwner: report?.node.processActivity.edges[0]?.node?.createdBy || '',
            dataSources: report?.node.datasourcesResponse.edges.map((ds) => {
              const ropaDatasource = ds.node.ropaDatasource
              const datasource = ropaDatasource?.datasource?.edges[0]?.node || {}
              return {
                dataSourceType: datasource.type,
                dataSourceName: datasource.name,
                status: ds.node.status || ''
              }
            })
          }
        }) || []
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getRopaNameById = (id: string) => {
  return gql`
    {
      ropa(id: "${id}") {
        edges {
          node {
            name
          }
        }
      }
    }
  `
}

export const mapGetRopaNameById = (raw: any): string => {
  try {
    return raw.ropa?.edges[0]?.node?.name || ''
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDeleteRopaReport = (id: string) => {
  return gql`
    mutation {
      deleteRopaReport(clientMutationId: "deleteRopaReport", ropaReportId: "${id}") {
        clientMutationId
      }
    }
  `
}

export const queryGenerateRopaReport = (processId: string) => {
  return gql`
    mutation {
      createRopaReport(clientMutationId: "createRopaReport", ropaProcessId: "${processId}") {
        clientMutationId
        report{
          edges {
            node {
              id
          createdAt
          processDetails {
            id
            name
            owner
            dpoEmail
            description
            processGroups {
              edges {
                node {
                  value
                  isDeleted
                }
              }
            }
            automatedProcessing
            automatedProcessingDescription
            purpose {
              edges {
                node {
                  value
                  isDeleted
                }
              }
            }
            lawfulBasis {
              edges {
                node {
                  value
                  isDeleted
                }
              }
            }
            nameProperties {
              key
              value
            }
            descriptionProperties {
              key
              value
            }
            processGroupsProperties {
              key
              value
            }
            purposeProperties {
              key
              value
            }
            processGroupsProperties {
              key
              value
            }
            automatedProcessingProperties {
              key
              value
            }
            automatedProcessingDescriptionProperties {
              key
              value
            }
            lawfulBasisProperties {
              key
              value
            }
            processQuestions {
              ${FRAGMENT_QUESTIONS_INFO}
            }
            controllerQuestions {
              ${FRAGMENT_QUESTIONS_INFO}
            }
            processDetails{
              processingActivityDetails{
                managerType
                details{
                  email
                  name
                  address
                  contact
                  roleType
                  emailProperties{
                    key
                    value
                  }
                  nameProperties{
                    key
                    value
                  }
                  addressProperties{
                    key
                    value
                  }
                  contactProperties{
                    key
                    value
                  }
                }
              }
            }
            ropaTopics{
              dataElements{
                name
                attributes{
                  count
                  edges{
                    node{
                      name
                      attributeSets{
                        edges{
                          node{
                            name
                            enabled
                          }
                        }
                      }
                      sensitivityLabel
                    }
                  }
                }
                specialElements{
                  sensitivity
                  attributeName
                }
                lawfulBases{
                  count
                  edges{
                    node{
                      value
                      isDeleted
                    }
                  }
                }
                questions{
                  edges{
                    node{
                      question
                      questionResponse
                    }
                  }
                }
              }
              dataSubjects{
                name
                dataSubjects{
                  edges{
                    node{
                      value
                      isDeleted
                    }
                  }
                }
                dataSubjectProperties{
                  key
                  value
                }
                specialDataSubject
                specialDataSubjects{
                  edges{
                    node{
                      value
                      isDeleted
                    }
                  }
                }
                specialDataSubjectProperties{
                  key
                  value
                }
                legalBases{
                  edges{
                    node{
                      value
                      isDeleted
                    }
                  }
                }
                legalBasisProperties{
                  key
                  value
                }
                questions{
                  ${FRAGMENT_QUESTIONS_INFO}
                }
              }
              dataRetention{
                name
                dataRetentionInfo{
                  edges{
                    node{
                      durationType
                      durationCount
                      triggerEvent
                      attributeSet{
                        edges{
                          node{
                            name
                          }
                        }
                      }
                    }
                  }
                }
                questions{
                  ${FRAGMENT_QUESTIONS_INFO}
                }
              }
              safeguards{
                name
                safeguards{
                  edges{
                    node{
                      value
                      isDeleted
                    }
                  }
                }
                safeguardProperties{
                  key
                  value
                }
                questions{
                  ${FRAGMENT_QUESTIONS_INFO}
                }
              }
              transfers{
                name
                crossBorder{
                  isCrossBorder
                  details{
                    organisation
                    email
                    country
                    countryProperties {
                      key
                      value
                    }
                    emailProperties {
                      key
                      value
                    }
                    organisationProperties {
                      key
                      value
                    }
                    safeguards{
                      edges{
                        node{
                          value
                        }
                      }
                    }
                  }
                }
                thirdParty{
                  isThirdParty
                  details{
                    name
                    email
                    address
                     nameProperties {
                      key
                      value
                    }
                    emailProperties {
                      key
                      value
                    }
                    addressProperties {
                      key
                      value
                    }
                    safeguards{
                      edges{
                        node{
                          value
                        }
                      }
                    }
                  }
                }
              }
              customTopics{
                name
                questions{
                  ${FRAGMENT_QUESTIONS_INFO}
                }
              }
              dataFlow {
                ropaDataFlow {
                  edges {
                    node {
                      id
                      ropaProcessId
                      ropaTopicId
                      stage
                      datasource {
                        edges {
                          node {
                            id
                            name
                            type
                          }
                        }
                      }
                      sourceDatasources {
                        stage
                        location
                        datasource {
                          edges {
                            node {
                              id
                              name
                              type
                            }
                          }
                        }
                      }
                      targetDatasources {
                        stage
                        location
                        datasource {
                          edges {
                            node {
                              id
                              name
                              type
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
            }
          }
        }
      }
    }
  `
}

export const mapQueryGenerateRopaReport = (raw: any): RopaReportPrint => {
  const filterDeleted = (list) => list.filter(({ isDeleted }) => !isDeleted)
  const mapQuestions = (questions) =>
    questions?.map(({ node }) => ({
      question: node?.question || '',
      answer: node?.questionResponse || ''
    }))

  try {
    const ropa = raw.createRopaReport.report?.edges[0]?.node || {}
    const process = ropa.processDetails || {}

    const result = {
      id: ropa.id,
      name: generateReportName(ropa.createdAt || '', process?.name || ''),
      process: {
        id: process.id || '',
        name: isQuestionDeleted(process?.nameProperties || []) ? '' : process?.name || '',
        nameLabel: getPropertyLabelValue(process?.nameProperties || []),
        owner: process?.owner || '',
        dpoEmail: process?.dpoEmail || '',
        description: isQuestionDeleted(process?.descriptionProperties || [])
          ? ''
          : process?.description || '',
        descriptionLabel: getPropertyLabelValue(process?.descriptionProperties || []),
        processGroups: isQuestionDeleted(process?.processGroupsProperties || [])
          ? ''
          : filterDeleted(process?.processGroups?.edges || []).map(({ node }) => node.value || ''),
        processGroupsLabel: getPropertyLabelValue(process?.processGroupsProperties || []),
        purpose: isQuestionDeleted(process?.purposeProperties || [])
          ? undefined
          : filterDeleted(process?.purpose?.edges || []).map(({ node }) => node.value || ''),
        purposeLabel: getPropertyLabelValue(process?.purposeProperties || []),
        lawfulBasis: isQuestionDeleted(process?.lawfulBasisProperties || [])
          ? undefined
          : filterDeleted(process?.lawfulBasis?.edges || []).map(({ node }) => node.value || ''),
        lawfulBasisLabel: getPropertyLabelValue(process?.lawfulBasisProperties || []),
        automatedProcessing: isQuestionDeleted(process?.automatedProcessingProperties || [])
          ? undefined
          : process?.automatedProcessing,
        automatedProcessingLabel: getPropertyLabelValue(
          process?.automatedProcessingProperties || []
        ),
        automatedProcessingDescription: isQuestionDeleted(
          process?.automatedProcessingDescriptionProperties || []
        )
          ? ''
          : process?.automatedProcessingDescription || '',
        automatedProcessingDescriptionLabel: getPropertyLabelValue(
          process?.automatedProcessingDescriptionProperties || []
        ),
        controllerQuestions: mapQuestions(process?.controllerQuestions?.edges || []),
        questions: mapQuestions(process?.processQuestions?.edges || [])
      },
      controllers:
        process?.processDetails?.processingActivityDetails
          ?.filter(
            ({ managerType }) => managerType == 'CONTROLLER' || managerType == 'JOINT_CONTROLLER'
          )
          .map(({ details }) => details || []) || [],
      processors: process?.processDetails?.processingActivityDetails
        ?.filter(({ managerType }) => managerType == 'PROCESSOR' || managerType == 'SUB_PROCESSOR')
        .map(({ details }) => details || []),
      topics: {
        dataElements: process?.ropaTopics?.dataElements
          ? {
              name: process?.ropaTopics?.dataElements?.name || '',
              attributes:
                process?.ropaTopics?.dataElements?.attributes?.edges?.map(({ node }) => ({
                  attributeName: node?.name || '',
                  attributeSets:
                    node?.attributeSets?.edges
                      ?.filter(({ node }) => node.enabled)
                      ?.map(({ node }) => node.name || '') || [],
                  sensitivity: node?.sensitivityLabel || ''
                })) || [],
              specialDataElements:
                process?.ropaTopics?.dataElements?.specialElements?.map((specialElement) => ({
                  attributeName: specialElement?.attributeName || '',
                  sensitivity: specialElement?.sensitivity || ''
                })) || [],
              lawfulBasis:
                filterDeleted(process?.ropaTopics?.dataElements?.lawfulBases?.edges || [])?.map(
                  ({ node }) => node?.value || ''
                ) || [],
              questions: mapQuestions(process?.ropaTopics?.dataElements?.questions?.edges || [])
            }
          : {},
        dataSubjects: process?.ropaTopics?.dataSubjects
          ? {
              name: process?.ropaTopics?.dataSubjects?.name || '',
              categories: isQuestionDeleted(process?.dataSubjectProperties || [])
                ? undefined
                : filterDeleted(process?.ropaTopics?.dataSubjects?.dataSubjects?.edges || [])?.map(
                    ({ node }) => node?.value || ''
                  ) || [],
              categoriesLabel: getPropertyLabelValue(
                process?.ropaTopics?.dataSubjects?.dataSubjectProperties || []
              ),
              special: process?.ropaTopics?.dataSubjects?.specialDataSubject || false,
              specialCategories:
                !process?.ropaTopics?.dataSubjects?.specialDataSubject ||
                isQuestionDeleted(process?.specialDataSubjectProperties || [])
                  ? undefined
                  : filterDeleted(
                      process?.ropaTopics?.dataSubjects?.specialDataSubjects?.edges || []
                    )?.map(({ node }) => node?.value || '') || [],
              specialCategoriesLabel: getPropertyLabelValue(
                process?.ropaTopics?.dataSubjects?.specialDataSubjectProperties || []
              ),
              lawfulBasis:
                !process?.ropaTopics?.dataSubjects?.specialDataSubject ||
                isQuestionDeleted(process?.legalBasisProperties || [])
                  ? undefined
                  : filterDeleted(process?.ropaTopics?.dataSubjects?.legalBases?.edges || [])?.map(
                      ({ node }) => node?.value || ''
                    ) || [],
              lawfulBasisLabel: getPropertyLabelValue(
                process?.ropaTopics?.dataSubjects?.legalBasisProperties || []
              ),
              questions: mapQuestions(process?.ropaTopics?.dataSubjects?.questions?.edges || [])
            }
          : {},
        safeguards: process?.ropaTopics?.safeguards
          ? {
              name: process?.ropaTopics?.safeguards?.name || '',
              safeguards: isQuestionDeleted(process?.safeguardProperties || [])
                ? []
                : filterDeleted(process?.ropaTopics?.safeguards?.safeguards?.edges || [])?.map(
                    ({ node }) => node?.value || ''
                  ) || [],
              questions: mapQuestions(process?.ropaTopics?.safeguards?.questions?.edges || []),
              safeguardsLabel: getPropertyLabelValue(
                process?.ropaTopics?.safeguards?.safeguardProperties || []
              )
            }
          : {},
        dataRetention: process?.ropaTopics?.dataRetention
          ? {
              name: process?.ropaTopics?.dataRetention?.name || '',
              dataRetention:
                process?.ropaTopics?.dataRetention?.dataRetentionInfo?.edges?.map(({ node }) => ({
                  durationType: node?.durationType || '',
                  durationCount: node?.durationCount,
                  triggerEvent: node?.triggerEvent || '',
                  attributeSets:
                    node?.attributeSet?.edges?.map(({ node }) => node?.name || '') || []
                })) || [],
              questions: mapQuestions(process?.ropaTopics?.dataRetention?.questions?.edges || [])
            }
          : {},
        transfers: process?.ropaTopics?.transfers
          ? {
              name: process?.ropaTopics?.transfers?.name || '',
              crossBorder: process?.ropaTopics?.transfers?.crossBorder?.isCrossBorder
                ? process?.ropaTopics?.transfers?.crossBorder?.details?.map((detail) => ({
                    organisation: detail?.organisation || '',
                    organisationProperties: detail?.organisationProperties || [],
                    email: detail?.email || '',
                    emailProperties: detail?.emailProperties || [],
                    country: detail?.country || '',
                    countryProperties: detail?.countryProperties || [],
                    safeguards: detail?.safeguards?.edges?.map(({ node }) => node?.value || ''),
                    safeguardsProperties: detail?.safeguardsProperties || []
                  }))
                : undefined,
              thirdParty: process?.ropaTopics?.transfers?.thirdParty?.isThirdParty
                ? process?.ropaTopics?.transfers?.thirdParty?.details?.map((detail) => ({
                    name: detail?.name || '',
                    nameProperties: detail?.nameProperties || [],
                    email: detail?.email || '',
                    emailProperties: detail?.emailProperties || [],
                    address: detail?.address || '',
                    addressProperties: detail?.addressProperties || [],
                    safeguards: detail?.safeguards?.edges?.map(({ node }) => node.value || ''),
                    safeguardsProperties: detail?.safeguardsProperties || []
                  }))
                : undefined
            }
          : {},
        dataFlow: process?.ropaTopics?.dataFlow?.ropaDataFlow
          ? process?.ropaTopics?.dataFlow?.ropaDataFlow.edges?.map(({ node: dtfItem }) => ({
              id: dtfItem.datasource?.edges[0]?.node?.id,
              name: dtfItem.datasource?.edges[0]?.node?.name,
              type: dtfItem.datasource?.edges[0]?.node?.type,
              processingStage: dtfItem.stage,
              dataFlowId: dtfItem.id,
              ropaProcessId: dtfItem.ropaProcessId,
              ropaTopicId: dtfItem.ropaTopicId,
              isValid: true,
              sources: dtfItem.sourceDatasources?.map((ds) => ({
                id: ds.datasource?.edges[0]?.node?.id,
                name: ds.datasource?.edges[0]?.node?.name,
                type: ds.datasource?.edges[0]?.node?.type,
                processingStage: ds.stage,
                location: ds.location,
                isValid: true
              })),
              targets: dtfItem.targetDatasources?.map((ds) => ({
                id: ds.datasource?.edges[0]?.node?.id,
                name: ds.datasource?.edges[0]?.node?.name,
                type: ds.datasource?.edges[0]?.node?.type,
                processingStage: ds.stage,
                location: ds.location,
                isValid: true
              }))
            }))
          : [],
        customTopics:
          process?.ropaTopics?.customTopics?.map((topic) => ({
            name: topic?.name || '',
            questions: mapQuestions(topic?.questions?.edges || [])
          })) || []
      }
    }

    return result as RopaReportPrint
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryRevokeAccess = (requestId: string) => {
  return gql`
    mutation {
      revokeDatasourceRequest(clientMutationId: "revokeAccess", requestId: "${requestId}") {
        clientMutationId
      }
    }
  `
}

export const queryRejectRequest = ({ reportId, dsoEmail, reason = '' }: RejectRequestParams) => {
  return gql`
    mutation {
      rejectDatasourceRequest(clientMutationId: "rejectRequest", reportId: "${reportId}", dsoEmail: "${dsoEmail}", emailBody: "${reason}") {
        clientMutationId
      }
    }
  `
}

export const queryRemoveRequest = ({ requestId, reason = '' }: RemoveRequestParams) => {
  return gql`
    mutation {
      deleteDatasourceRequest(clientMutationId: "removeRequest", requestId: "${requestId}", emailBody: "${reason}") {
        clientMutationId
      }
    }
  `
}

export const queryDeleteCustomTopic = (id: string) => {
  return gql`
    mutation {
      deleteRopaProcessTopic(clientMutationId: "deleteTopic", processTopicId: "${id}") {
        clientMutationId
        isDeleted
      }
    }
  `
}

export const querySaveCustomTopic = (
  { name, description, optional, questions }: RopaCustomTopic,
  processActivityId: string
) => {
  return gql`
    mutation {
      createRopaProcessTopic(clientMutationId: "deleteTopic", processActivityId: "${processActivityId}", topicData: {
        name: "${name}", \
        description: "${escapeNewlineandTab(description || '')}",\
        optional: ${!!optional}, \
        questions: [${getQuestionsQuery(questions)}]\
      }) {
        clientMutationId
      }
    }
  `
}

export const getRopaRequestConversation = (dataSourceResponseId: string) => {
  return gql`
    {
      ropaDatasourceResponse(id: "${dataSourceResponseId}") {
        edges {
          node {
            ropaTopics {
              attributes {
                edges {
                  node {
                    comments {
                      edges {
                        node {
                          createdBy
                          comment
                          createdAt
                        }
                      }
                    }
                  }
                }
              }
              entityTypes {
                edges {
                  node {
                    comments {
                      edges {
                        node {
                          createdBy
                          comment
                          createdAt
                        }
                      }
                    }
                  }
                }
              }
              classificationObjects {
                edges {
                  node {
                    comments {
                      edges {
                        node {
                          createdBy
                          comment
                          createdAt
                        }
                      }
                    }
                  }
                }
              }
              retentionPeriod {
                edges {
                  node {
                    comments {
                      edges {
                        node {
                          createdBy
                          comment
                          createdAt
                        }
                      }
                    }
                  }
                }
              }
              dataLink {
                edges{
                  node{
                    comments {
                      edges {
                        node {
                          createdBy
                          comment
                          createdAt
                        }
                      }
                    }
                  }
                }
              }
              purpose {
                edges {
                  node {
                    comments {
                      edges {
                        node {
                          createdBy
                          comment
                          createdAt
                        }
                      }
                    }
                  }
                }
              }
              datatransferDetails {
                edges {
                  node {
                    comments {
                      edges {
                        node {
                          createdBy
                          comment
                          createdAt
                        }
                      }
                    }
                  }
                }
              }
              safetyMeasure {
                edges {
                  node {
                    comments {
                      edges {
                        node {
                          createdBy
                          comment
                          createdAt
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapGetRopaRequestConversation = (raw: any): RopaRequestConversation[] => {
  try {
    const topics = raw.ropaDatasourceResponse.edges[0]?.node.ropaTopics || {}
    const mapSectionComments = (sectionName: string) =>
      topics[sectionName]?.edges[0]?.node?.comments?.edges?.map((comment) => comment?.node || {}) ||
      []

    return [
      ...mapSectionComments('retentionPeriod'),
      ...mapSectionComments('dataLink'),
      ...mapSectionComments('purpose'),
      ...mapSectionComments('datatransferDetails'),
      ...mapSectionComments('safetyMeasure'),
      ...mapSectionComments('classificationObjects'),
      ...mapSectionComments('entityTypes'),
      ...mapSectionComments('attributes')
    ].sort((x, y) => +new Date(x.createdAt) - +new Date(y.createdAt))
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const createRopaMessage = ({
  topicId,
  comment,
  createdBy
}: SendRopaMessageParams): any => gql`
  mutation {
    createRopaTopicComment(
      clientMutationId: "${topicId}"
      commentData: {
        comment: "${comment}"
        processTopicId: "${topicId}"
        createdBy: "${createdBy}"
      }
    ) {
      clientMutationId
    }
  }
`

export const getReportById = (id: string) => gql`
  {
    ropa(id: "${id}"){
      edges {
        node {
          id
          createdAt
          processDetails {
            name
            owner
            dpoEmail
            description
            processGroups {
              edges {
                node {
                  value
                  isDeleted
                }
              }
            }
            automatedProcessing
            automatedProcessingDescription
            purpose {
              edges {
                node {
                  value
                  isDeleted
                }
              }
            }
            lawfulBasis {
              edges {
                node {
                  value
                  isDeleted
                }
              }
            }
            nameProperties {
              key
              value
            }
            descriptionProperties {
              key
              value
            }
            processGroupsProperties {
              key
              value
            }
            purposeProperties {
              key
              value
            }
            processGroupsProperties {
              key
              value
            }
            automatedProcessingProperties {
              key
              value
            }
            automatedProcessingDescriptionProperties {
              key
              value
            }
            lawfulBasisProperties {
              key
              value
            }
            processQuestions {
              ${FRAGMENT_QUESTIONS_INFO}
            }
            controllerQuestions {
              ${FRAGMENT_QUESTIONS_INFO}
            }
            processDetails{
              processingActivityDetails{
                managerType
                details{
                  email
                  name
                  address
                  contact
                  roleType
                  emailProperties{
                    key
                    value
                  }
                  nameProperties{
                    key
                    value
                  }
                  addressProperties{
                    key
                    value
                  }
                  contactProperties{
                    key
                    value
                  }
                }
              }
            }
            ropaTopics{
              dataElements{
                name
                attributes{
                  count
                  edges{
                    node{
                      name
                      attributeSets{
                        edges{
                          node{
                            name
                            enabled
                          }
                        }
                      }
                      sensitivityLabel
                    }
                  }
                }
                specialElements{
                  sensitivity
                  attributeName
                }
                lawfulBases{
                  count
                  edges{
                    node{
                      value
                      isDeleted
                    }
                  }
                }
                questions{
                  edges{
                    node{
                      question
                      questionResponse
                    }
                  }
                }
              }
              dataSubjects{
                name
                dataSubjects{
                  edges{
                    node{
                      value
                      isDeleted
                    }
                  }
                }
                dataSubjectProperties{
                  key
                  value
                }
                specialDataSubject
                specialDataSubjects{
                  edges{
                    node{
                      value
                      isDeleted
                    }
                  }
                }
                specialDataSubjectProperties{
                  key
                  value
                }
                legalBases{
                  edges{
                    node{
                      value
                      isDeleted
                    }
                  }
                }
                legalBasisProperties{
                  key
                  value
                }
                questions{
                  ${FRAGMENT_QUESTIONS_INFO}
                }
              }
              dataRetention{
                name
                dataRetentionInfo{
                  edges{
                    node{
                      durationType
                      durationCount
                      triggerEvent
                      attributeSet{
                        edges{
                          node{
                            name
                          }
                        }
                      }
                    }
                  }
                }
                questions{
                  ${FRAGMENT_QUESTIONS_INFO}
                }
              }
              safeguards{
                name
                safeguards{
                  edges{
                    node{
                      value
                      isDeleted
                    }
                  }
                }
                safeguardProperties{
                  key
                  value
                }
                questions{
                  ${FRAGMENT_QUESTIONS_INFO}
                }
              }
              transfers{
                name
                crossBorder{
                  isCrossBorder
                  details{
                    organisation
                    email
                    country
                    countryProperties {
                      key
                      value
                    }
                    emailProperties {
                      key
                      value
                    }
                    organisationProperties {
                      key
                      value
                    }
                    safeguards{
                      edges{
                        node{
                          value
                        }
                      }
                    }
                  }
                }
                thirdParty{
                  isThirdParty
                  details{
                    name
                    email
                    address
                     nameProperties {
                      key
                      value
                    }
                    emailProperties {
                      key
                      value
                    }
                    addressProperties {
                      key
                      value
                    }
                    safeguards{
                      edges{
                        node{
                          value
                        }
                      }
                    }
                  }
                }
              }
              customTopics{
                name
                questions{
                  ${FRAGMENT_QUESTIONS_INFO}
                }
              }
              dataFlow {
                ropaDataFlow {
                  edges {
                    node {
                      id
                      ropaProcessId
                      ropaTopicId
                      stage
                      datasource {
                        edges {
                          node {
                            id
                            name
                            type
                          }
                        }
                      }
                      sourceDatasources {
                        stage
                        location
                        datasource {
                          edges {
                            node {
                              id
                              name
                              type
                            }
                          }
                        }
                      }
                      targetDatasources {
                        stage
                        location
                        datasource {
                          edges {
                            node {
                              id
                              name
                              type
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const isQuestionDeleted = (properties) => {
  const property = properties.find(({ key }) => key == 'isDeleted')
  return property?.value?.length ? property.value[0] == 'true' : false
}

export const getPropertyLabelValue = (properties) =>
  (properties || []).find(({ key = '' }) => key == 'label')?.value || ''
export const getPropertyIsMandatory = (properties) =>
  (properties || []).find(({ key = '' }) => key == 'optional')?.value[0] == 'true' || false
export const isFieldOptional = (properties) =>
  (properties || []).find(({ key = '' }) => key == 'optional')?.value === 'true' || ''
export const mapGetReportById = (raw: any): RopaReportPrint => {
  const filterDeleted = (list) => list.filter(({ isDeleted }) => !isDeleted)
  const mapQuestions = (questions) =>
    questions?.map(({ node }) => ({
      question: node?.question || '',
      answer: node?.questionResponse || ''
    }))

  try {
    const ropa = raw.ropa?.edges[0]?.node || {}
    const process = ropa.processDetails || {}

    const result = {
      id: ropa.id,
      name: generateReportName(ropa.createdAt || '', process?.name || ''),
      process: {
        name: isQuestionDeleted(process?.nameProperties || []) ? '' : process?.name || '',
        nameLabel: getPropertyLabelValue(process?.nameProperties || []),
        owner: process?.owner || '',
        dpoEmail: process?.dpoEmail || '',
        description: isQuestionDeleted(process?.descriptionProperties || [])
          ? ''
          : process?.description || '',
        descriptionLabel: getPropertyLabelValue(process?.descriptionProperties || []),
        processGroups: isQuestionDeleted(process?.processGroupsProperties || [])
          ? ''
          : filterDeleted(process?.processGroups?.edges || []).map(({ node }) => node.value || ''),
        processGroupsLabel: getPropertyLabelValue(process?.processGroupsProperties || []),
        purpose: isQuestionDeleted(process?.purposeProperties || [])
          ? undefined
          : filterDeleted(process?.purpose?.edges || []).map(({ node }) => node.value || ''),
        purposeLabel: getPropertyLabelValue(process?.purposeProperties || []),
        lawfulBasis: isQuestionDeleted(process?.lawfulBasisProperties || [])
          ? undefined
          : filterDeleted(process?.lawfulBasis?.edges || []).map(({ node }) => node.value || ''),
        lawfulBasisLabel: getPropertyLabelValue(process?.lawfulBasisProperties || []),
        automatedProcessing: isQuestionDeleted(process?.automatedProcessingProperties || [])
          ? undefined
          : process?.automatedProcessing,
        automatedProcessingLabel: getPropertyLabelValue(
          process?.automatedProcessingProperties || []
        ),
        automatedProcessingDescription: isQuestionDeleted(
          process?.automatedProcessingDescriptionProperties || []
        )
          ? ''
          : process?.automatedProcessingDescription || '',
        automatedProcessingDescriptionLabel: getPropertyLabelValue(
          process?.automatedProcessingDescriptionProperties || []
        ),
        controllerQuestions: mapQuestions(process?.controllerQuestions?.edges || []),
        questions: mapQuestions(process?.processQuestions?.edges || [])
      },
      controllers:
        process?.processDetails?.processingActivityDetails
          ?.filter(
            ({ managerType }) => managerType == 'CONTROLLER' || managerType == 'JOINT_CONTROLLER'
          )
          .map(({ details }) => details || []) || [],
      processors: process?.processDetails?.processingActivityDetails
        ?.filter(({ managerType }) => managerType == 'PROCESSOR' || managerType == 'SUB_PROCESSOR')
        .map(({ details }) => details || []),
      topics: {
        dataElements: process?.ropaTopics?.dataElements
          ? {
              name: process?.ropaTopics?.dataElements?.name || '',
              attributes:
                process?.ropaTopics?.dataElements?.attributes?.edges?.map(({ node }) => ({
                  attributeName: node?.name || '',
                  attributeSets:
                    node?.attributeSets?.edges
                      ?.filter(({ node }) => node.enabled)
                      ?.map(({ node }) => node.name || '') || [],
                  sensitivity: node?.sensitivityLabel || ''
                })) || [],
              specialDataElements:
                process?.ropaTopics?.dataElements?.specialElements?.map((specialElement) => ({
                  attributeName: specialElement?.attributeName || '',
                  sensitivity: specialElement?.sensitivity || ''
                })) || [],
              lawfulBasis:
                filterDeleted(process?.ropaTopics?.dataElements?.lawfulBases?.edges || [])?.map(
                  ({ node }) => node?.value || ''
                ) || [],
              questions: mapQuestions(process?.ropaTopics?.dataElements?.questions?.edges || [])
            }
          : {},
        dataSubjects: process?.ropaTopics?.dataSubjects
          ? {
              name: process?.ropaTopics?.dataSubjects?.name || '',
              categories: isQuestionDeleted(process?.dataSubjectProperties || [])
                ? undefined
                : filterDeleted(process?.ropaTopics?.dataSubjects?.dataSubjects?.edges || [])?.map(
                    ({ node }) => node?.value || ''
                  ) || [],
              categoriesLabel: getPropertyLabelValue(
                process?.ropaTopics?.dataSubjects?.dataSubjectProperties || []
              ),
              special: process?.ropaTopics?.dataSubjects?.specialDataSubject || false,
              specialCategories:
                !process?.ropaTopics?.dataSubjects?.specialDataSubject ||
                isQuestionDeleted(process?.specialDataSubjectProperties || [])
                  ? undefined
                  : filterDeleted(
                      process?.ropaTopics?.dataSubjects?.specialDataSubjects?.edges || []
                    )?.map(({ node }) => node?.value || '') || [],
              specialCategoriesLabel: getPropertyLabelValue(
                process?.ropaTopics?.dataSubjects?.specialDataSubjectProperties || []
              ),
              lawfulBasis:
                !process?.ropaTopics?.dataSubjects?.specialDataSubject ||
                isQuestionDeleted(process?.legalBasisProperties || [])
                  ? undefined
                  : filterDeleted(process?.ropaTopics?.dataSubjects?.legalBases?.edges || [])?.map(
                      ({ node }) => node?.value || ''
                    ) || [],
              lawfulBasisLabel: getPropertyLabelValue(
                process?.ropaTopics?.dataSubjects?.legalBasisProperties || []
              ),
              questions: mapQuestions(process?.ropaTopics?.dataSubjects?.questions?.edges || [])
            }
          : {},
        safeguards: process?.ropaTopics?.safeguards
          ? {
              name: process?.ropaTopics?.safeguards?.name || '',
              safeguards: isQuestionDeleted(process?.safeguardProperties || [])
                ? []
                : filterDeleted(process?.ropaTopics?.safeguards?.safeguards?.edges || [])?.map(
                    ({ node }) => node?.value || ''
                  ) || [],
              questions: mapQuestions(process?.ropaTopics?.safeguards?.questions?.edges || []),
              safeguardsLabel: getPropertyLabelValue(
                process?.ropaTopics?.safeguards?.safeguardProperties || []
              )
            }
          : {},
        dataRetention: process?.ropaTopics?.dataRetention
          ? {
              name: process?.ropaTopics?.dataRetention?.name || '',
              dataRetention:
                process?.ropaTopics?.dataRetention?.dataRetentionInfo?.edges?.map(({ node }) => ({
                  durationType: node?.durationType || '',
                  durationCount: node?.durationCount,
                  triggerEvent: node?.triggerEvent || '',
                  attributeSets:
                    node?.attributeSet?.edges?.map(({ node }) => node?.name || '') || []
                })) || [],
              questions: mapQuestions(process?.ropaTopics?.dataRetention?.questions?.edges || [])
            }
          : {},
        transfers: process?.ropaTopics?.transfers
          ? {
              name: process?.ropaTopics?.transfers?.name || '',
              crossBorder: process?.ropaTopics?.transfers?.crossBorder?.isCrossBorder
                ? process?.ropaTopics?.transfers?.crossBorder?.details?.map((detail) => ({
                    organisation: detail?.organisation || '',
                    organisationProperties: detail?.organisationProperties || [],
                    email: detail?.email || '',
                    emailProperties: detail?.emailProperties || [],
                    country: detail?.country || '',
                    countryProperties: detail?.countryProperties || [],
                    safeguards: detail?.safeguards?.edges?.map(({ node }) => node?.value || ''),
                    safeguardsProperties: detail?.safeguardsProperties || []
                  }))
                : undefined,
              thirdParty: process?.ropaTopics?.transfers?.thirdParty?.isThirdParty
                ? process?.ropaTopics?.transfers?.thirdParty?.details?.map((detail) => ({
                    name: detail?.name || '',
                    nameProperties: detail?.nameProperties || [],
                    email: detail?.email || '',
                    emailProperties: detail?.emailProperties || [],
                    address: detail?.address || '',
                    addressProperties: detail?.addressProperties || [],
                    safeguards: detail?.safeguards?.edges?.map(({ node }) => node.value || ''),
                    safeguardsProperties: detail?.safeguardsProperties || []
                  }))
                : undefined
            }
          : {},
        dataFlow: process?.ropaTopics?.dataFlow?.ropaDataFlow
          ? process?.ropaTopics?.dataFlow?.ropaDataFlow.edges
              ?.map(({ node: dtfItem }) => ({
                id: dtfItem.datasource?.edges[0]?.node?.id,
                name: dtfItem.datasource?.edges[0]?.node?.name,
                type: dtfItem.datasource?.edges[0]?.node?.type,
                processingStage: dtfItem.stage,
                dataFlowId: dtfItem.id,
                ropaProcessId: dtfItem.ropaProcessId,
                ropaTopicId: dtfItem.ropaTopicId,
                isValid: true,
                sources: dtfItem.sourceDatasources?.map((ds) => ({
                  id: ds.datasource?.edges[0]?.node?.id,
                  name: ds.datasource?.edges[0]?.node?.name,
                  type: ds.datasource?.edges[0]?.node?.type,
                  processingStage: ds.stage,
                  location: ds.location,
                  isValid: true
                })),
                targets: dtfItem.targetDatasources?.map((ds) => ({
                  id: ds.datasource?.edges[0]?.node?.id,
                  name: ds.datasource?.edges[0]?.node?.name,
                  type: ds.datasource?.edges[0]?.node?.type,
                  processingStage: ds.stage,
                  location: ds.location,
                  isValid: true
                }))
              }))
              .filter((item) => !!item.id)
          : [],
        customTopics:
          process?.ropaTopics?.customTopics?.map((topic) => ({
            name: topic?.name || '',
            questions: mapQuestions(topic?.questions?.edges || [])
          })) || []
      }
    }

    return result as RopaReportPrint
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryRopaProcessReports = ({
  processId,
  page,
  pageSize = LIMIT_DEFAULT
}: RopaFetchReportsParams) => {
  const cursor = getAfterCursor(page || 1, pageSize)
  return gql`
  {
    ropa(ropaProcessId: "${processId}",first: ${pageSize}, after: "${cursor}", sortField: CREATED_AT) {
      count
      edges {
        node {
          id
          createdAt
          processDetails{
            name
          }
        }
      }
    }
  }
`
}

export const mapQueryRopaProcessReports = (raw: any): { list: RopaReport[]; total: number } => {
  try {
    const reports =
      raw.ropa?.edges?.map(({ node }) => {
        return {
          id: node.id,
          name: generateReportName(node.createdAt || '', node.processDetails?.name || ''),
          createdAt: node.createdAt
        }
      }) || []
    return { list: reports, total: raw?.ropa?.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const mapGetReports = (raw: any): { list: RopaReportPrint[]; total: number } => {
  try {
    const reports =
      raw.ropa?.edges?.map((ropa) => {
        const data = ropa.node
        const process = data.processActivity?.edges[0]?.node || {}
        const result = {
          id: data.id,
          name: data.name,
          status: data.status,
          process: {
            id: process.id,
            name: process.name,
            owner: process.createdBy,
            createdAt: process.createdAt,
            description: process.description,
            departments: process.departments,
            processDetails: process.processDetails,
            lawfulBasis: process.lawfulBasis || '',
            purpose: process.purpose || ''
          },
          dataSourcesCount: data.datasourcesResponse?.count || 0,
          dataSources: data.datasourcesResponse?.edges?.map(({ node: dsRes }) => ({
            questionnaire: mapQuestionnaireToUiSchema(dsRes.ropaTopics),
            id: dsRes.ropaDatasource.datasource?.edges[0]?.node?.id,
            name: dsRes.ropaDatasource.datasource?.edges[0]?.node?.name,
            type: dsRes.ropaDatasource.datasource?.edges[0]?.node?.type,
            ropaDatasourceType: dsRes.ropaDatasource.datasource?.edges[0]?.node?.ropaDatasourceType,
            datasourceInfo: mapDataSourceInfoToUi([dsRes.ropaDatasource])[0]
          }))
        }
        return result
      }) || []

    return { list: reports, total: reports.length }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const markRopaAsComplete = (id: string): any => gql`
  mutation {
    completeRopa(
      clientMutationId: "${id}"
      reportId: "${id}"
    ) {
      clientMutationId
    }
  }`

export const queryProcessDataSources = (params: RopaProcessDataSourcesParams) => {
  return gql`{
    ropaProcess (id: "${params.id}") {
      count
      edges {
        node {
          datasources ${FRAGMENT_DATA_SOURCES_INFO}
        }
      }
    }
  }`
}
export const mapQueryProcessDataSources = (raw: any): RopaDataSourceDetails[] => {
  try {
    const datasourcesInfo = mapDataSourceInfoToUi(raw.ropaProcess?.edges[0]?.node?.datasources)
    return datasourcesInfo
      .filter((item) => !!item.datasource)
      .map((item) => ({
        dataSourceId: item.datasource?.id || '',
        dataSourceType: item.datasource?.type || '',
        dataSourceName: item.datasource?.name || ''
      }))
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryProcessOverview = ({ processId }: RopaProcessOverviewParams) => {
  return gql`{
    processOverview: ropaProcess (id: "${processId}") {
      edges {
        node {
          updatedAt
          name
          description
          automatedProcessing
          automatedProcessingDescription
          purpose{
            count
          }
          processGroups{
            count
          }
          lawfulBasis{
            count
          }
          descriptionProperties {
            key
            value
          }
          nameProperties {
            key
            value
          }
          purposeProperties {
            key
            value
          }
          processGroupsProperties {
            key
            value
          }
          automatedProcessingProperties {
            key
            value
          }
          automatedProcessingDescriptionProperties {
            key
            value
          }
          lawfulBasisProperties {
            key
            value
          }
          processDetails {
            creatorRole
            processingActivityDetails {
              managerType
              details {
                name
                email
                address
                contact
                nameProperties {
                  key
                  value
                }
                emailProperties {
                  key
                  value
                }
                contactProperties {
                  key
                  value
                }
                addressProperties {
                  key
                  value
                }
              }
            }
          }
          processQuestions{
            count
            ${FRAGMENT_QUESTIONS_INFO}
          }
          collaboration{
            edges{
              node{
                emailId
                status
              }
            }
          }
          ropaTopics {
            dataElements {
              questions {
                count
                ${FRAGMENT_QUESTIONS_INFO}
              }
              associatedDatasources {
                count
                edges {
                  node {
                    id
                    name
                    type
                  }
                }
              }
              specialElements{
                attributeName
              }
              attributes{
                count
                edges{
                  node{
                    internalName
                    datasourceCounts {
                      datasource {
                        edges {
                          node {
                            id
                            name
                            type
                          }
                        }
                      }
                    }
                    attributeSets{
                      edges{
                        node{
                          name
                          id
                          enabled
                        }
                      }
                    }
                  }
                }
              }
            }
            dataSubjects{
              dataSubjects{
                count
              }
              specialDataSubjects{
                count
              }
              legalBases{
                count
              }
              dataSubjectProperties{
                key
                value
              }
              specialDataSubjectProperties{
                key
                value
              }
              legalBasisProperties{
                key
                value
              }
              questions {
                count
                ${FRAGMENT_QUESTIONS_INFO}
              }
            }
            dataRetention{
              questions{
                count
                ${FRAGMENT_QUESTIONS_INFO}
              }
            }
            safeguards{
              safeguards {
                count
              }
              safeguardProperties{
                key
                value
              }
              questions{
                count
                ${FRAGMENT_QUESTIONS_INFO}
              }
            }
            transfers {
              thirdParty {
                isThirdParty
                details {
                  name
                  email
                  address
                  nameProperties {
                    key
                    value
                  }
                  emailProperties {
                    key
                    value
                  }
                  addressProperties {
                    key
                    value
                  }
                  safeguardsProperties {
                    key
                    value
                  }
                  safeguards {
                    edges {
                      node {
                        value
                      }
                    }
                  }
                }
              }
              crossBorder {
                isCrossBorder
                details {
                  organisation
                  email
                  country
                  countryProperties {
                    key
                    value
                  }
                  emailProperties {
                    key
                    value
                  }
                  organisationProperties {
                    key
                    value
                  }
                  safeguards {
                    edges {
                      node {
                        value
                      }
                    }
                  }
                  safeguardsProperties {
                    key
                    value
                  }
                }
              }
            }
            customTopics{
              questions{
                count
                ${FRAGMENT_QUESTIONS_INFO}
              }
            }
          }
        }
      }
    }
    ropa(ropaProcessId: "${processId}") {
      count
      edges {
        node {
          createdAt
        }
      }
    }
    attributeInstanceGroupedbyName {
      name
      internalName
      sensitivity
      attributeInstanceCount
    }
  }`
}

export const isFieldAvailable = (process, key) => {
  if (process) {
    const isFieldDeleted = process[key]?.find(({ key }) => key == 'isDeleted')
    const hasField = isFieldDeleted?.value?.length
      ? (isFieldDeleted.value[0] || 'false') == 'false'
      : false
    return hasField
  } else {
    return false
  }
}

export const isFieldAvailableAndRequired = (process, key) => {
  if (process) {
    const isFieldDeleted = process[key]?.find(({ key }) => key == 'isDeleted')
    const isFieldOptional = process[key]?.find(({ key }) => key == 'optional')
    const hasField = isFieldDeleted?.value?.length
      ? (isFieldDeleted.value[0] || 'false') == 'false'
      : false
    const isRequired = isFieldOptional?.value?.length
      ? (isFieldOptional.value[0] || 'false') == 'false'
      : false

    return hasField && isRequired
  } else {
    return false
  }
}

export const isFieldAvailableAndAssigned = (process, key) => {
  if (process) {
    const isFieldDeleted = process[key]?.find(({ key }) => key == 'isDeleted')
    const isFieldAssigned = process[key]?.find(({ key }) => key == 'isAssigned')
    const hasField = isFieldDeleted?.value?.length
      ? (isFieldDeleted.value[0] || 'false') == 'false'
      : false
    const isAssigned = isFieldAssigned?.value?.length
      ? (isFieldAssigned.value[0] || 'false') == 'true'
      : false

    return hasField && isAssigned
  } else {
    return false
  }
}

export const mapQueryProcessOverview = (raw: any): RopaProcessOverview => {
  try {
    const ropaProcess = raw.processOverview?.edges[0]?.node
    const ropaTopics = ropaProcess?.ropaTopics
    const dataCategories =
      ropaTopics?.dataElements?.attributes?.edges?.map(
        ({ node }) =>
          node.attributeSets?.edges
            ?.filter(({ node }) => node.enabled)
            ?.map(({ node }) => node.id) || []
      ) || []
    const dataCategoriesSet = new Set<string>()
    dataCategories.forEach((dataCategory) => {
      dataCategory.forEach((category) => {
        dataCategoriesSet.add(category)
      })
    })
    const attributeInternalNames =
      ropaTopics?.dataElements?.attributes?.edges?.map(({ node }) => node.internalName) || []
    const completionPercentage = getProcessStats(ropaProcess).percentage

    const data = {
      processUpdatedAt: ropaProcess.updatedAt || '',
      totalDataElements: ropaTopics?.dataElements?.attributes?.count || 0,
      totalSpecialDataElements: ropaTopics?.dataElements?.specialElements?.length || 0,
      totalDataCategories: dataCategoriesSet.size,
      totalDataSubjects: ropaTopics?.dataSubjects?.dataSubjects?.count || 0,
      totalSpecialDataSubjects: ropaTopics?.dataSubjects?.specialDataSubjects?.count || 0,
      totalReports: raw?.ropa?.count || 0,
      oldestReportDate:
        (raw?.ropa?.edges?.map(({ node }) => node.createdAt) || []).sort(
          (dateA, dateB) => new Date(dateA).getTime() - new Date(dateB).getTime()
        )[0] || '',
      newestReportDate:
        (raw?.ropa?.edges?.map(({ node }) => node.createdAt) || []).sort(
          (dateB, dateA) => new Date(dateA).getTime() - new Date(dateB).getTime()
        )[0] || '',
      dataSources: ropaTopics?.dataElements?.associatedDatasources?.edges?.map(
        ({ node: associatedDatasource }) => {
          let total = 0
          ropaTopics?.dataElements?.attributes?.edges?.forEach(({ node }) => {
            if (
              node.datasourceCounts.find(
                ({ datasource }) => datasource?.edges[0]?.node?.id == associatedDatasource?.id
              )
            ) {
              total++
            }
          })
          return {
            ...associatedDatasource,
            totalAttributes: total
          }
        }
      ),
      attributes: raw?.attributeInstanceGroupedbyName?.filter(
        ({ internalName }) => !!attributeInternalNames.find((name) => name == internalName)
      ),
      collaborators: ropaProcess.collaboration?.edges?.map(({ node }) => ({
        email: node.emailId || '',
        status: node.status || ''
      })),
      completionPercentage
    }
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

const CollaboratorQuestionAndScopeFragment = gql`
  edges {
        node {
          ropaTopics {
            dataElements {
              id
              name
              description
              optional
              associatedDatasources {
                edges {
                  node {
                    id
                  }
                }
              }
              specialElements{
                attributeName
                sensitivity
              }
              attributes{
                count
                edges{
                  node{
                    id
                  }
                }
              }
              lawfulBases{
                edges{
                  node{
                    id
                  }
                }
              }
              specialElementProperties{
                key
                value
              }
              attributeProperties{
                key
                value
              }
              lawfulBasisProperties{
                key
                value
              }
              questions {
                count
                ${FRAGMENT_QUESTIONS}
              }
            }
            dataSubjects{
              id
              name
              description
              optional
              specialDataSubject
              dataSubjectProperties{
                key
                value
              }
              specialDataSubjectProperties{
                key
                value
              }
              specialDataSubjectsProperties{
                key
                value
              }
              legalBasisProperties{
                key
                value
              }
              questions {
                count
                ${FRAGMENT_QUESTIONS}
              }
            }
            dataRetention{
              id
              name
              description
              optional
              dataRetentionProperties{
                key
                value
              }
              questions{
                count
                ${FRAGMENT_QUESTIONS}
              }
            }
            safeguards{
              id
              name
              description
              optional
              safeguardProperties{
                key
                value
              }
              questions{
                count
                ${FRAGMENT_QUESTIONS}
              }
            }
            transfers {
              id
              name
              description
              optional
              thirdParty {
                isThirdParty
                thirdPartyProperties{
                  key
                  value
                }
                details {
                  nameProperties {
                    key
                    value
                  }
                  emailProperties {
                    key
                    value
                  }
                  addressProperties {
                    key
                    value
                  }
                  safeguardsProperties {
                    key
                    value
                  }
                }
              }
              crossBorder {
                isCrossBorder
                crossBorderProperties{
                  key
                  value
                }
                details {
                  countryProperties {
                    key
                    value
                  }
                  emailProperties {
                    key
                    value
                  }
                  organisationProperties {
                    key
                    value
                  }
                  safeguardsProperties {
                    key
                    value
                  }
                }
              }
              questions{
                count
                ${FRAGMENT_QUESTIONS}
              }
            }
            dataFlow {
              id
              isAssigned
            }
            customTopics{
              id
              name
              description
              optional
              questions{
                count
                ${FRAGMENT_QUESTIONS}
              }
            }
          }
        }
      }
`

export const queryProcessDetails = (id: string) => {
  return gql`{
    ropaProcess (id: "${id}") {
     ${CollaboratorQuestionAndScopeFragment}
    }
  }`
}

export const queryCollaborationDetails = (id: string) => {
  return gql`{
    ropaCollaboration (id: "${id}") {
      ${CollaboratorQuestionAndScopeFragment}
    }
  }`
}

export const mapQueryCollaborationDetails = (raw: any): RopaProcessCollaborationScope => {
  const mapQuestions = (topic) =>
    topic.questions?.edges?.map(({ node }) => ({
      questionId: node.questionId || '',
      question: node.question || '',
      isMandatory: node.isMandatory || false
    })) || []
  try {
    const ropaProcess = raw.ropaProcess?.edges[0]?.node || raw.ropaCollaboration?.edges[0]?.node
    const ropaTopics = ropaProcess?.ropaTopics
    const topics: RopaQuestionnaireOptionalTopics = {}
    let totalTopics = 0
    const dataElements = ropaTopics?.dataElements
    if (dataElements) {
      totalTopics++
      topics.dataElements = {
        id: dataElements.id || '',
        name: dataElements.name || '',
        description: dataElements.description || '',
        optional: dataElements.optional || false,
        attributeProperties: dataElements.attributeProperties || [],
        specialElementProperties: dataElements.specialElementProperties || [],
        lawfulBasisProperties: dataElements.lawfulBasisProperties || [],
        questions: mapQuestions(dataElements)
      }
    }

    const dataSubjects = ropaTopics?.dataSubjects
    if (dataSubjects) {
      totalTopics++
      topics.dataSubjects = {
        optional: dataSubjects.optional || false,
        id: dataSubjects.id || '',
        name: dataSubjects.name || '',
        description: dataSubjects.description || '',
        dataSubjectProperties: dataSubjects.dataSubjectProperties || [],
        specialDataSubjectProperties: dataSubjects.specialDataSubjectProperties || [],
        specialDataSubjectsProperties: dataSubjects.specialDataSubjectsProperties || [],
        legalBasisProperties: dataSubjects.legalBasisProperties || [],
        questions: mapQuestions(dataSubjects)
      }
      const questions: ProcessCollaborationScope[] = []
      if (isFieldAvailable(dataSubjects, 'dataSubjectProperties')) {
        const dataSubjectLabel = getPropertyLabelValue(dataSubjects?.dataSubjectProperties)
        questions.push({
          id: 'dataSubjects',
          question: dataSubjectLabel.length ? dataSubjectLabel[0] || '' : '',
          isMandatory: getPropertyIsMandatory(dataSubjects?.dataSubjectProperties)
        })
      }
      if (dataSubjects.specialDataSubject) {
        if (isFieldAvailable(dataSubjects, 'specialDataSubjectsProperties')) {
          const specialDataSubjectLabel = getPropertyLabelValue(
            dataSubjects?.specialDataSubjectProperties
          )
          questions.push({
            id: 'specialDataSubjects',
            question: specialDataSubjectLabel.length ? specialDataSubjectLabel[0] || '' : '',
            isMandatory: getPropertyIsMandatory(dataSubjects?.specialDataSubjectProperties)
          })

          if (isFieldAvailable(dataSubjects, 'legalBasisProperties')) {
            const legalBasisLabel = getPropertyLabelValue(dataSubjects?.legalBasisProperties)
            questions.push({
              id: 'legalBasis',
              question: legalBasisLabel.length ? legalBasisLabel[0] || '' : '',
              isMandatory: getPropertyIsMandatory(dataSubjects?.legalBasisProperties)
            })
          }
        }
      }
    }

    const dataRetention = ropaTopics?.dataRetention
    if (dataRetention) {
      const questions: ProcessCollaborationScope[] = []
      totalTopics++
      topics.dataRetention = {
        id: dataRetention.id || '',
        name: dataRetention.name || '',
        description: dataRetention.description || '',
        optional: dataRetention.optional || false,
        dataRetentionProperties: dataRetention.dataRetentionProperties || [],
        questions: mapQuestions(dataRetention)
      }
      if (isFieldAvailable(dataRetention, 'dataRetentionProperties')) {
        const dataRetentionLabel = getPropertyLabelValue(dataRetention?.dataRetentionProperties)
        questions.push({
          id: 'dataRetention',
          question: dataRetentionLabel.length ? dataRetentionLabel[0] || '' : '',
          isMandatory: getPropertyIsMandatory(dataRetention?.dataRetentionProperties)
        })
      }
    }

    const safeguards = ropaTopics?.safeguards
    if (safeguards) {
      const questions: ProcessCollaborationScope[] = []
      totalTopics++
      topics.safeguards = {
        id: safeguards.id || '',
        name: safeguards.name || '',
        description: safeguards.description || '',
        optional: safeguards.optional || false,
        safeguardProperties: safeguards.safeguardProperties || [],
        questions: mapQuestions(safeguards)
      }
      if (isFieldAvailable(safeguards, 'safeguardProperties')) {
        const safeguardLabel = getPropertyLabelValue(safeguards?.safeguardProperties)
        questions.push({
          id: 'safeguards',
          question: safeguardLabel.length ? safeguardLabel[0] || '' : '',
          isMandatory: getPropertyIsMandatory(safeguards?.safeguardProperties)
        })
      }
    }

    const transfers = ropaTopics?.transfers
    if (transfers) {
      totalTopics++
      topics.transfers = {
        id: transfers.id || '',
        name: transfers.name || '',
        description: transfers.description || '',
        optional: transfers.optional || false,
        crossBorder: {
          isCrossBorder: ropaTopics?.transfers?.crossBorder?.isCrossBorder || false,
          crossBorderProperties: ropaTopics?.transfers?.crossBorder?.crossBorderProperties || [],
          details: ropaTopics?.transfers?.crossBorder?.details || []
        },
        thirdParty: {
          isThirdParty: ropaTopics?.transfers?.thirdParty?.isThirdParty || false,
          thirdPartyProperties: ropaTopics?.transfers?.thirdParty?.thirdPartyProperties || [],
          details: ropaTopics?.transfers?.thirdParty?.details || []
        },
        questions: mapQuestions(transfers)
      }
    }

    const dataFlow = ropaTopics?.dataFlow
    if (dataFlow) {
      totalTopics++
      topics.dataFlow = {
        id: dataFlow.id || '',
        isAssigned: dataFlow.isAssigned || false
      }
    }
    if (ropaTopics?.customTopics.length) {
      topics.customTopics =
        ropaTopics?.customTopics?.map((topic) => {
          totalTopics++

          return {
            optional: false,
            name: topic.name || '',
            questions: mapQuestions(topic)
          }
        }) || []
    }

    return {
      totalTopics,
      topics
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryRopaTickets = ({
  page = 1,
  pageSize = LIMIT_DEFAULT,
  filters
}: RopaTicketsParams) => {
  const cursor = getAfterCursor(page || 1, pageSize)
  const filtersParameterized = parameterizeArrayofObjects(filters?.filter || [])
  let filterParams = ''
  if (filtersParameterized) {
    filterParams = `, filter:${filtersParameterized}`
  }

  return gql`
    {
      ropaCollaboration(sortField: UPDATE_TIMESTAMP, first:${pageSize}, after:"${cursor}"${filterParams}) {
        count
        edges {
          node {
            id
            dueDate
            status
            name
            updateTimestamp
            createdAt
            emailId
            ropaProcess{
              edges{
                node{
                  name
                  processGroups {
                    edges {
                      node {
                        value
                      }
                    }
                  }
                  owner
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryRopaTickets = (raw: any): { list: RopaTicket[]; total: number } => {
  try {
    const list =
      raw.ropaCollaboration?.edges?.map(({ node }) => ({
        id: node.id || '',
        ticketId: node.name || '',
        processName: node.ropaProcess?.edges[0]?.node?.name || '',
        processGroups:
          node.ropaProcess?.edges[0]?.node?.processGroups?.edges?.map(
            ({ node }) => node.value || ''
          ) || [],
        processOwner: node.ropaProcess?.edges[0]?.node?.owner || '',
        dueDate: node.dueDate || '',
        status: node.status || '',
        updatedAt: node.updateTimestamp || '',
        createdAt: node.createdAt || '',
        assignee: node.emailId || ''
      })) || []

    return { list, total: raw.ropaCollaboration?.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const mutationDeleteCollaboration = (id: string) => {
  return gql`
    mutation {
      ropaDeleteCollaborationRequest(
        clientMutationId: "ropaDeleteCollaborationRequest"
        collaboratorId: "${id}"
      ) {
        clientMutationId
      }
    }
  `
}
