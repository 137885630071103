import {
  PatternOptions,
  PolicyParameter,
  PolicyParameterListItem,
  PolicyParametersData
} from './types'
import { SENSITIVE_LABEL } from '../../../constants'
import { gql } from 'graphql-request'

export const queryPolicyParameters = (): string => {
  return gql`
    {
      attribute(isInformationExtraction: true) {
        count
        edges {
          node {
            id
            name
            enabled
            isUsedInPolicy
          }
        }
      }
    }
  `
}

export const mapQueryPolicyParameters = (
  raw: any
): { list: PolicyParameterListItem[]; total: number } => {
  try {
    const list = raw.attribute.edges.map(({ node: attr }) => ({
      id: attr.id,
      name: attr.name,
      enabled: attr.enabled,
      isUsedInPolicy: attr.isUsedInPolicy
    }))

    return { list, total: raw.attribute.count }
  } catch (error) {
    console.error(error)
    throw error
  }
}

function escapeForRegex(input: string): string {
  return input.replace(/\\/g, '\\\\')
}

const getParamsFragmentForPolicyParams = (params: PolicyParametersData, forEdit?: boolean) => {
  const { basicDetails, detection } = params
  const contextFragment = detection.contextWords
    ? `context: [${detection.contextWords.map((word) => `{ keyword: "${word}" }`).join(',')}]`
    : ''

  const patternsFragment =
    detection.detectionType === PatternOptions.BASIC
      ? `regexPatternTypes: [${detection.regexPatternTypes?.join(',')}]`
      : `patterns: [${detection.regexValues
          .map((value) => `{ regex: "${escapeForRegex(value)}"}`)
          .join(',')}]`

  const basicDetailsFragment = `
    name: "${basicDetails.name}"
    ${basicDetails.description ? `description: "${basicDetails.description}"` : ''}
    ${forEdit ? '' : `dataType: ${basicDetails.dataType}`}
    ${basicDetails.enabled !== undefined ? `enabled: ${basicDetails.enabled}` : 'enabled: true'}
    
  `

  return {
    contextFragment,
    patternsFragment,
    basicDetailsFragment
  }
}

export const createPolicyParameterMutation = (params: PolicyParametersData) => {
  const {
    contextFragment,
    patternsFragment,
    basicDetailsFragment
  } = getParamsFragmentForPolicyParams(params)
  return gql`
  mutation {
    createAttribute(
      attributeData: {
        ${basicDetailsFragment}
        ${contextFragment}
        isInformationExtraction: true,
        sensitivityLabel: ${SENSITIVE_LABEL.MEDIUM}
        ${patternsFragment}
      }
      clientMutationId: "1"
    ) {
      clientMutationId
      attribute {
        edges {
          node {
            id
          }
        }
      }
    }
  }
  `
}

export const updatePolicyParameterMutation = (params: PolicyParametersData) => {
  const {
    contextFragment,
    patternsFragment,
    basicDetailsFragment
  } = getParamsFragmentForPolicyParams(params, true)
  return gql`
  mutation {
    updateAttribute(
      id: "${params.id}"
      attributeData: {
        ${basicDetailsFragment}
        ${contextFragment}
        isInformationExtraction: true,
        ${patternsFragment}
      }
      clientMutationId: "1"
    ) {
      clientMutationId
      attribute {
        edges {
          node {
            id
          }
        }
      }
    }
  }
  `
}

export const deletePolicyParameterMutation = (id: string): string => {
  return gql`
    mutation {
      deleteAttribute(
        clientMutationId: "1"
        id: "${id}"
        isInformationExtraction: true
      ) {
        clientMutationId
      }
    }
`
}

export const queryPolicyParameterById = (id: string): string => {
  return gql`
    {
      attribute(id: "${id}", isInformationExtraction: true) {
        edges {
          node {
            id
            name
            description
            dataType
            enabled
            context {
              keyword
            }
            patterns {
              regex
            }
            regexPatternTypes
          }
        }
      }
    }

  `
}

export const mapPolicyParameterById = (data: any): PolicyParameter => {
  return data.attribute.edges[0].node
}

export const enableDisablePolicyParam = (params: PolicyParameter): string => {
  const contextFragment = params.context
    ? `context: [${params.context.map((word) => `{ keyword: "${word.keyword}" }`).join(',')}]`
    : ''

  const patternsFragment = params.regexPatternTypes
    ? `regexPatternTypes: [${params.regexPatternTypes?.join(',')}]`
    : `patterns: [${params.patterns
        .map((value) => `{ regex: "${escapeForRegex(value.regex)}"}`)
        .join(',')}]`

  const basicDetailsFragment = `
  name: "${params.name}"
  ${params.description ? `description: "${params.description}"` : ''}
  ${params.enabled !== undefined ? `enabled: ${params.enabled}` : 'enabled: true'}
  
`
  return gql`
  mutation {
    updateAttribute(
      id: "${params.id}"
      attributeData: {
        ${basicDetailsFragment}
        ${contextFragment}
        enableMasking: true
        isInformationExtraction: true,
        ${patternsFragment}
      }
      clientMutationId: "1"
    ) {
      clientMutationId
      attribute {
        edges {
          node {
            id
          }
        }
      }
    }
  }
  `
}
