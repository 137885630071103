import { IGetWidgetStatusParams } from './statusSlice'
import { DATA_SOURCE_TYPES, MAIL_DATASOURCES } from '../../../../constants'
import { gql } from 'graphql-request'

export const queryWidgetStatus = (params: IGetWidgetStatusParams): string => {
  const { datasourceId, datasourceType } = params
  const isMailDatasource = MAIL_DATASOURCES.find((type) => type == datasourceType)
  // TODO: temporary solution. to do: optimze node name selection
  const isJira = datasourceType === DATA_SOURCE_TYPES.jira
  const isSlack = datasourceType === DATA_SOURCE_TYPES.slack
  const isTeams = datasourceType === DATA_SOURCE_TYPES.teams
  const isAwsS3 = datasourceType === DATA_SOURCE_TYPES.aws
  const isGoogleCloudStorage = datasourceType === DATA_SOURCE_TYPES.googleCloudStorage
  const isAzureBlob = datasourceType === DATA_SOURCE_TYPES.azureBlob
  const isGeneric = datasourceType === DATA_SOURCE_TYPES.generic
  const isSMB = datasourceType === DATA_SOURCE_TYPES.smb
  const nodeName = isMailDatasource
    ? 'MailDatasourceStatus'
    : isJira
    ? 'TicketStoreDatasourceStatus'
    : isSlack
    ? 'SlackDatasourceStatus'
    : isTeams
    ? 'MSTeamsDatasourceStatus'
    : isAwsS3 || isAzureBlob || isGoogleCloudStorage
    ? 'AWSS3DatasourceStatus'
    : isGeneric
    ? 'GenericDatasourceStatus'
    : isSMB
    ? 'NfsSmbDatasourceStatus'
    : 'DriveDatasourceStatus'

  return gql`
  {
    datasources(id: "${datasourceId}"){
      edges{
        node{
          id
          type
          status: state,
          objectsCount: status{
            ... on ${nodeName} {
              processedCount
              skippedCount
              pendingCount
            }
          }
        }
      }
    }
  }`
}

export const mapQueryWidgetStatus = (
  raw: any
): {
  status?: string
  objectsCount?: { processedCount: number; skippedCount: number; pendingCount: number }
} => {
  const { node } = raw.datasources.edges[0]
  if (node) {
    const { status, objectsCount } = node || {}
    return {
      ...(status ? { status } : {}),
      ...(objectsCount ? { objectsCount } : {})
    }
  } else {
    return {}
  }
}
