import { createSlice } from '@reduxjs/toolkit'

export enum MODALS {
  annotationFeedback = 'annotationFeedback'
}

export type IModal = {
  name: MODALS
}

interface IModalsState {
  openedModal?: IModal
}

export const initialState: IModalsState = {}

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openUserFeedback: (state) => {
      state.openedModal = { name: MODALS.annotationFeedback }
    },
    resetModals: () => initialState
  }
})

export const { openUserFeedback, resetModals } = modalsSlice.actions

export default modalsSlice.reducer
