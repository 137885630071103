export const sentimentAnalysisList = [
  {
    file: 'File name',
    uploadedBy: 'Louis Smith',
    sentimentLevel: 'medium'
  },
  {
    file: 'File name',
    uploadedBy: 'Louis Smith',
    sentimentLevel: 'low'
  },
  {
    file: 'File name',
    uploadedBy: 'Louis Smith',
    sentimentLevel: 'medium'
  },
  {
    file: 'File name',
    uploadedBy: 'Louis Smith',
    sentimentLevel: 'low'
  },
  {
    file: 'File name',
    uploadedBy: 'Louis Smith',
    sentimentLevel: 'strong'
  },
  {
    file: 'File name',
    uploadedBy: 'Louis Smith',
    sentimentLevel: 'low'
  },
  {
    file: 'File name',
    uploadedBy: 'Louis Smith',
    sentimentLevel: 'medium'
  },
  {
    file: 'File name',
    uploadedBy: 'Louis Smith',
    sentimentLevel: 'strong'
  }
]
