import IconHelp from '../../assets/inlineSvg/icon-help-doc.svg'
import PopupInfo from '../PopupInfo/popupInfo'
import { URL_SITE_HELP } from '../../constants'
import { useIntl } from 'react-intl'
import React from 'react'

const WidgetHelp = (): React.ReactElement => {
  const intl = useIntl()

  return (
    <PopupInfo
      on="hover"
      text={intl.formatMessage({ id: 'siteHelp' })}
      trigger={
        <a
          href={URL_SITE_HELP}
          rel="noreferrer"
          target="_blank"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <IconHelp />
        </a>
      }
    />
  )
}

export default WidgetHelp
