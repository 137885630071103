import { FeatureFlags } from '../configs/featureFlagSlice'
import {
  URL_DOCUMENTS_CLASSIFIED,
  URL_DOCUMENTS_UNCLASSIFIED_WITH_ENTITIES,
  URL_DOCUMENTS_ALL,
  URL_DOCUMENT,
  URL_ATTRIBUTES,
  URL_CONVERSATIONS,
  URL_EMAILS,
  URL_DOCUMENTS_UNCLASSIFIED_WITHOUT_ENTITIES,
  URL_TICKETS,
  ANNOTATION,
  URL_SETTINGS,
  UserRoles,
  AccessLevelRoles,
  URL_POLICIES,
  URL_FILES,
  URL_MANAGE_ACCOUNT,
  URL_ALERTS,
  URL_ROPA,
  URL_PIA,
  URL_DASHBOARD,
  URL_DSR,
  URL_DSR_FORM_BUILDER,
  URL_COOKIES_CONSENT,
  URL_ACCESS_CONTROL,
  URL_CONSENT_MANAGEMENT,
  URL_DSR_WORKFLOW_AUTOMATION
} from '../constants'
import { UserDetails } from '../interfaces'
const mapAccessLevelToPath = {
  [ANNOTATION]: [
    `${URL_DOCUMENT}`,
    `${URL_DOCUMENTS_ALL}/:documentId`,
    `${URL_DOCUMENTS_CLASSIFIED}/:documentId`,
    `${URL_DOCUMENTS_UNCLASSIFIED_WITH_ENTITIES}/:documentId`,
    `${URL_DOCUMENTS_UNCLASSIFIED_WITHOUT_ENTITIES}/:documentId`,
    `${URL_CONVERSATIONS}/:documentId`,
    `${URL_ATTRIBUTES}/:documentId`,
    `${URL_EMAILS}/:documentId`,
    `${URL_TICKETS}/:documentId`,
    `${URL_SETTINGS}/:subPage` // settings page do not have separate access level
  ]
}

export const isMaskingEnabled = () => {
  return true
}
export const hasRouteAccess = (userDetails: UserDetails | undefined, path: string | string[]) => {
  if (userDetails && userDetails.roles && userDetails.roles.length) {
    const userRole = userDetails?.roles[0]
    const mergedUserAccess = {
      ...(userRole.accessLevels?.reduce((acc, userAccess) => {
        acc[userAccess.accessLevel] = userAccess.enabled
        return acc
      }, {}) || [])
    }
    let isPathMatch = false
    let hasAccess = true
    Object.keys(mergedUserAccess).forEach((accessLevel) => {
      isPathMatch = mapAccessLevelToPath[accessLevel]?.some((accessPath) => {
        return path.includes(accessPath)
      })
      if (isPathMatch) {
        hasAccess =
          mergedUserAccess[accessLevel] && userRole && userRole.name !== UserRoles.readonly
      }
      isPathMatch = false
    })
    return hasAccess
  }
  return true
}

export const hasFeatureAccess = (path: string, featureFlags: FeatureFlags) => {
  const featureAccessMapper = {
    [URL_PIA]: featureFlags.piaFeature,
    [URL_COOKIES_CONSENT]: featureFlags.cookieConsent,
    [URL_ACCESS_CONTROL]: true,
    [URL_CONSENT_MANAGEMENT]: featureFlags.consentManagement
  }
  const featureBaseUrls = Object.keys(featureAccessMapper)
  for (let j = 0; j < featureBaseUrls.length; j++) {
    if (path.includes(featureBaseUrls[j])) {
      return featureAccessMapper[featureBaseUrls[j]] !== false
    }
  }

  return true
}
export const hasActionAccess = (userDetails: UserDetails | undefined, action: AccessLevelRoles) => {
  if (userDetails && userDetails.roles && userDetails.roles.length) {
    const userRole = userDetails?.roles[0]

    if (userRole && userRole?.name === UserRoles.readonly) {
      return false
    }
    const mergedUserAccess = {
      ...(userRole.accessLevels?.reduce((acc, userAccess) => {
        acc[userAccess.accessLevel] = userAccess.enabled
        return acc
      }, {}) || [])
    }
    return mergedUserAccess[action]
  }
  return true
}

const ALLOWED_ACTIONS_ALERT_OWNER = [
  URL_POLICIES,
  URL_FILES,
  URL_MANAGE_ACCOUNT,
  URL_DOCUMENT,
  URL_DOCUMENTS_ALL,
  URL_DOCUMENTS_CLASSIFIED,
  URL_DOCUMENTS_UNCLASSIFIED_WITH_ENTITIES,
  URL_DOCUMENTS_UNCLASSIFIED_WITHOUT_ENTITIES,
  URL_CONVERSATIONS,
  URL_ATTRIBUTES,
  URL_EMAILS,
  URL_TICKETS
]
const ALLOWED_ACTIONS_COLLABORATOR = [URL_ROPA + URL_TICKETS, URL_PIA + URL_TICKETS]
const ALLOWED_ACTIONS_PROCESS_OWNER = [URL_ROPA, URL_PIA]

/* List of not allowed actions for View Only user */
/* TODO: Convert it to allowed list OR add all other not allowed actions */
const NOT_ALLOWED_ACTIONS_VIEW_ONLY_USER = [URL_DSR + URL_DSR_FORM_BUILDER]

const RESTRICTED_PATHS_FOR_NON_ADMIN_DPO = [
  URL_DSR + URL_DSR_WORKFLOW_AUTOMATION
  // Add more paths as needed
]

export const redirectByRole = (
  userDetails: UserDetails | undefined,
  path: string[],
  pathname: string
) => {
  const redirectPath = ''
  if (userDetails && userDetails.roles && userDetails.roles.length) {
    const isAlertOwner = !!userDetails?.roles?.find(({ name }) => name === UserRoles.alertOwner)
    const isCollaborator = !!userDetails?.roles?.find(({ name }) => name === UserRoles.collaborator)
    const isViewOnlyUser = !!userDetails?.roles?.find(({ name }) => name === UserRoles.readonly)
    const isProcessOwner = !!userDetails?.roles?.find(({ name }) => name === UserRoles.processOwner)
    const isAdmin = !!userDetails?.roles?.find(({ name }) => name === UserRoles.admin)
    const isDPO = !!userDetails?.roles?.find(({ name }) => name === UserRoles.dpo)

    if (
      !isAdmin &&
      !isDPO &&
      RESTRICTED_PATHS_FOR_NON_ADMIN_DPO.some((path) => pathname.startsWith(path))
    ) {
      return URL_DASHBOARD // Redirect to the dashboard
    }

    if (
      isAlertOwner &&
      !path.find((url) => !!ALLOWED_ACTIONS_ALERT_OWNER.find((allowed) => allowed.startsWith(url)))
    ) {
      return URL_POLICIES + URL_ALERTS
    } else if (
      isCollaborator &&
      !path.find((url) => !!ALLOWED_ACTIONS_COLLABORATOR.find((allowed) => allowed.startsWith(url)))
    ) {
      return URL_PIA + URL_TICKETS
    }

    if (
      isProcessOwner &&
      !path.find(
        (url) => !!ALLOWED_ACTIONS_PROCESS_OWNER.find((allowed) => allowed.startsWith(url))
      )
    ) {
      return URL_PIA
    }

    if (
      isViewOnlyUser &&
      NOT_ALLOWED_ACTIONS_VIEW_ONLY_USER.find((url) => pathname.startsWith(url))
    ) {
      return URL_DASHBOARD
    }
  }

  return redirectPath
}
