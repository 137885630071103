import { mapQueryWidgetStatus, queryWidgetStatus } from './queries'
import graphqlService from '../../../../services/graphqlService'
import { ScanStatus } from '../../../../interfaces'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface WidgetStatus {
  status?: string
  objectsCount?: {
    processedCount: number
    skippedCount: number
    pendingCount: number
  }
}

export interface widgetSummaryCountsState {
  widget: WidgetStatus
}

export const initialState: widgetSummaryCountsState = {
  widget: {}
}

export interface IGetWidgetStatusParams {
  datasourceId: string
  datasourceType: string
}
export const ACTION_WIDGET_STATUS = 'widget/status'
export const fetchWidgetStatus = createAsyncThunk(
  ACTION_WIDGET_STATUS,
  async (params: IGetWidgetStatusParams) => {
    const resultRaw = await graphqlService.execute(queryWidgetStatus(params))
    return mapQueryWidgetStatus(resultRaw)
  }
)

const membersSlice = createSlice({
  name: 'widgetStatus',
  initialState,
  reducers: {
    resetWidget: (state) => {
      state.widget = initialState.widget
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWidgetStatus.fulfilled, (state, action) => {
      state.widget.objectsCount = action.payload.objectsCount
      state.widget.status = action.payload.status || ScanStatus.registering
    })
  }
})

export const { resetWidget } = membersSlice.actions

export default membersSlice.reducer
