import { CookieConsentDomain } from '../domains/domainsSlice'
import apiService from '../../../services/api/apiService'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export enum CookieConsentTypes {
  accept = 'accept',
  reject = 'reject',
  acceptReject = 'accept-reject',
  acceptDoNotSell = 'accept-do-not-sell',
  doNotSell = 'do-not-sell'
}

export enum RegulationTypes {
  gdpr = 'GDPR',
  ccpa = 'CCPA',
  others = 'Others'
}

export const mapCookieConsentTypesToText = (type: string | CookieConsentTypes) => {
  if (type === CookieConsentTypes.accept) {
    return 'cookieConsent.accept'
  }
  if (type === CookieConsentTypes.reject) {
    return 'cookieConsent.reject'
  }
  if (type === CookieConsentTypes.acceptReject) {
    return 'cookieConsent.acceptReject'
  }
  if (type === CookieConsentTypes.doNotSell) {
    return 'cookieConsent.doNotsell'
  }
  if (type === CookieConsentTypes.acceptDoNotSell) {
    return 'cookieConsent.acceptDoNotSell'
  }
  return ''
}

export enum CookieConsentBannerStatuses {
  active = 'ACTIVE',
  inactive = 'INACTIVE'
}
export const mapCookieConsentBannerStatusToText = (type: string | CookieConsentBannerStatuses) => {
  if (type === CookieConsentBannerStatuses.active) {
    return 'cookieConsent.active'
  }
  if (type === CookieConsentBannerStatuses.inactive) {
    return 'cookieConsent.inactive'
  }
  return ''
}

export enum CookieConsentBannerStyleTypes {
  classic = 'classic',
  floating = 'floating',
  popup = 'popup'
}
export enum CookieConsentBannerPositions {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
  center = 'center'
}

export type CookieConsentBannerButtonStyles = {
  backgroundColor?: string
  borderColor?: string
  color?: string
  text?: string
}

export enum BannerConfigTabs {
  main = 'main',
  preferences = 'preferences'
}
export enum BannerConfigPreviewTabs {
  code = 'code',
  preview = 'preview'
}

export type CookieConsentBanner = {
  consentType: CookieConsentTypes
  createdBy?: string
  createdAt?: string
  description?: string
  id?: string
  name: string
  updatedBy?: string
  updatedAt?: string
  layout?: {
    type?: CookieConsentBannerStyleTypes
    position?: CookieConsentBannerPositions[]
    banner?: {
      backgroundColor?: string
      borderColor?: string
      body?: string
      bodyTextColor?: string
      policy?: string
      policyUrl?: string
      policyTextColor?: string
      acceptAllButton?: CookieConsentBannerButtonStyles
      rejectAllButton?: CookieConsentBannerButtonStyles
      actionButton?: CookieConsentBannerButtonStyles
      doNotSellButton?: CookieConsentBannerButtonStyles
    }
    preferences?: {
      title?: string
      titleTextColor?: string
      body?: string
      bodyTextColor?: string
      category?: {
        checkboxType?: 'toggle' | 'checkbox'
        checkboxColorOn?: string
        checkboxColorOff?: string
        checkboxColorAlwaysOn?: string
        checkboxCheckMarkColor?: string
        colorTitle?: string
        colorDescription?: string
        showCookies?: boolean
      }
      acceptAllButton?: CookieConsentBannerButtonStyles
      rejectAllButton?: CookieConsentBannerButtonStyles
      actionButton?: CookieConsentBannerButtonStyles
      doNotSellButton?: CookieConsentBannerButtonStyles
    }
  }
  rawJSON?: string
  domains?: CookieConsentDomain[]
  customizable?: boolean
  linkDoNotSell?: boolean
  regulationType?: string
}

// CRUD
export const ACTION_COOKIE_CONSENT_BANNERS = 'banners/list'
export const fetchCookieConsentBanners = createAsyncThunk(
  ACTION_COOKIE_CONSENT_BANNERS,
  async () => await apiService.getCookieConsentBanners()
)

export const ACTION_COOKIE_CONSENT_BANNER_BY_ID = 'banners/bannerById'
export const fetchCookieConsentBannerById = createAsyncThunk(
  ACTION_COOKIE_CONSENT_BANNER_BY_ID,
  async (id: string) => await apiService.getCookieConsentBannerById(id)
)

export const ACTION_COOKIE_CONSENT_BANNER_SAVE = 'banners/bannerSave'
export const saveCookieConsentBanner = createAsyncThunk(
  ACTION_COOKIE_CONSENT_BANNER_SAVE,
  async (banner: CookieConsentBanner) => await apiService.postCookieConsentBanner(banner)
)

export const ACTION_COOKIE_CONSENT_BANNER_UPDATE = 'banners/bannerUpdate'
export const updateCookieConsentBanner = createAsyncThunk(
  ACTION_COOKIE_CONSENT_BANNER_UPDATE,
  async (banner: CookieConsentBanner) => await apiService.putCookieConsentBanner(banner)
)

export const ACTION_COOKIE_CONSENT_BANNER_DELETE = 'banners/delete'
export const deleteCookieConsentBanner = createAsyncThunk(
  ACTION_COOKIE_CONSENT_BANNER_DELETE,
  async (id: string) => await apiService.deleteCookieConsentBannerById(id)
)

/** Initial state */
type DsrEmailTemplatesState = {
  banners: {
    list: CookieConsentBanner[]
    total: number
  }
  config: {
    selectedBanner?: CookieConsentBanner
    showErrors: boolean
    validSteps: number[]
    activeTab: BannerConfigTabs
  }
}

const initialDsrFormsList = {
  list: [],
  total: 0
}

export const initialState: DsrEmailTemplatesState = {
  banners: initialDsrFormsList,
  config: {
    showErrors: false,
    validSteps: [],
    activeTab: BannerConfigTabs.main
  }
}

export const parseCookieConsentBannerLayout = (banner?: CookieConsentBanner) => {
  if (!banner) return undefined

  let layout
  try {
    layout = JSON.parse(banner?.rawJSON || '{}')
  } catch (e) {
    console.error(e)
  }

  const handledBanner = { ...(banner || {}) }
  if (layout) handledBanner.layout = layout

  return handledBanner
}

const bannersSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    resetCookieConsentBanners: (state) => {
      state.banners = initialState.banners
    },
    resetConfig: (state) => {
      state.config = initialState.config
    },
    setValidSteps: (state, { payload }) => {
      state.config.validSteps = payload
    },
    setShowConfigErrors: (state, { payload }) => {
      state.config.showErrors = payload
    },
    setSelectedBanner: (state, { payload }) => {
      state.config.selectedBanner = payload
    },
    setActiveConfigTab: (state, { payload }) => {
      state.config.activeTab = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCookieConsentBanners.fulfilled, (state, { payload }) => {
      state.banners.list = payload.map((b) => parseCookieConsentBannerLayout(b))
      state.banners.total = payload.length || 0
    })
    builder.addCase(fetchCookieConsentBannerById.fulfilled, (state, { payload }) => {
      state.config.selectedBanner = parseCookieConsentBannerLayout(payload)
    })
  }
})

export const {
  resetCookieConsentBanners,
  resetConfig,
  setValidSteps,
  setShowConfigErrors,
  setSelectedBanner,
  setActiveConfigTab
} = bannersSlice.actions

export default bannersSlice.reducer
