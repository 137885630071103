import TrendWidget from '../../../components/TrendSummaryWidget/trendSummaryWidget'
import { ChartBarDataItem } from '../../../components/ChartArea/chartArea'
import ChartBarSimple from '../../../components/ChartBarSimple/chartBarSimple'
import { TrendItemData } from '../../../components/TrendSummaryWidget/trendSummaryItem'
import PercentageIndicator from '../../../components/PercentageIndicator/percentage'
import { percentageDeltaCalculator } from '../deltaReportUtil'
import React from 'react'
import { FormattedNumber, useIntl } from 'react-intl'
import { Table } from 'semantic-ui-react'

interface IProps {
  chartData: ChartBarDataItem[]
  barData: { name: string; added: number; deleted: number }[]
  trendWidgetData: TrendItemData[]
  legendText: string
  tableHeaders: string[]
  tableData: {
    dateRange: string
    startTotal: number
    startAdded: number
    startDeleted?: number
    added: number
    deleted?: number
    total: number
  }[]
  areaTitleText: string
  barTitleText: string
  barLegendPrefix: string
}

const TrendPage = ({
  chartData,
  barData,
  trendWidgetData,
  tableData,
  areaTitleText,
  barTitleText,
  tableHeaders,
  barLegendPrefix
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const yLabelFormatter = (value) => intl.formatNumber(value, { notation: 'compact' })
  const renderChartLegend = () => (
    <div
      className="recharts-legend-wrapper"
      style={{
        position: 'absolute',
        width: '973px',
        height: 'auto',
        left: '50px'
      }}
    >
      <ul
        className="recharts-default-legend xs-mt-12"
        style={{ padding: '0px', margin: '0px', textAlign: 'left' }}
      >
        <li
          className="recharts-legend-item legend-item-1"
          style={{ display: 'inline-block', marginRight: '10px' }}
        >
          <svg
            className="recharts-surface"
            width="40"
            height="14"
            viewBox="0 0 32 32"
            version="1.1"
            style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '12px' }}
          >
            <path
              stroke="none"
              fill="#6941c6"
              d="M0,4h100v24h-100z"
              className="recharts-legend-icon"
            ></path>
          </svg>
          <span className="recharts-legend-item-text">Total {barLegendPrefix}</span>
        </li>
      </ul>
    </div>
  )
  const renderBarLegend = () => (
    <div
      className="recharts-legend-wrapper"
      style={{
        position: 'absolute',
        width: '973px',
        height: 'auto',
        left: '50px'
      }}
    >
      <ul
        className="recharts-default-legend xs-mt-12"
        style={{ padding: '0px', margin: '0px', textAlign: 'left' }}
      >
        <li
          className="recharts-legend-item legend-item-0"
          style={{ display: 'inline-block', marginRight: '10px' }}
        >
          <svg
            className="recharts-surface"
            width="40"
            height="14"
            viewBox="0 0 32 32"
            version="1.1"
            style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '12px' }}
          >
            <path
              stroke="none"
              fill="#13C2C2"
              d="M0,4h100v24h-100z"
              className="recharts-legend-icon"
            ></path>
          </svg>
          <span className="recharts-legend-item-text">{barLegendPrefix} added</span>
        </li>
        <li
          className="recharts-legend-item legend-item-1"
          style={{ display: 'inline-block', marginRight: '10px' }}
        >
          <svg
            className="recharts-surface"
            width="40"
            height="14"
            viewBox="0 0 32 32"
            version="1.1"
            style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '12px' }}
          >
            <path
              stroke="none"
              fill="#CF1322"
              d="M0,4h100v24h-100z"
              className="recharts-legend-icon"
            ></path>
          </svg>
          <span className="recharts-legend-item-text">{barLegendPrefix} deleted</span>
        </li>
      </ul>
    </div>
  )

  return (
    <div className="trend-template">
      <div className="xs-m-48 border">
        <TrendWidget data={trendWidgetData} weeksCount={4} />
      </div>
      <div className="xs-mx-48 xs-mt-36 xs-mb-48 border">
        <p className="widget-title widget-title-uppercase xs-mb-28">{areaTitleText}</p>
        <ChartBarSimple
          data={chartData}
          chartResponsiveHeight={250}
          showAxisLine={true}
          cartesianGridHorrizontal={true}
          cartesianGridVertical={true}
          barSize={33}
          print={true}
          xLabelAngle={0}
          xAxisAngle={0}
          tickFontSize={{ fontSize: '12px' }}
          barColors={[{ key: 'value', color: '#6941c6' }]}
          yLabelFormatter={yLabelFormatter}
          YTickFormatter={yLabelFormatter}
          defaultTooltip={true}
          renderLegend={renderChartLegend}
          isAnimationActive={false}
        />
      </div>

      <div className="xs-m-48">
        <p className="widget-title widget-title-uppercase xs-mb-16">{barTitleText}</p>
        <ChartBarSimple
          data={barData}
          chartResponsiveHeight={250}
          showAxisLine={true}
          cartesianGridHorrizontal={true}
          cartesianGridVertical={true}
          barSize={33}
          print={true}
          xLabelAngle={0}
          xAxisAngle={0}
          tickFontSize={{ fontSize: '12px' }}
          barColors={[
            { key: 'added', color: '#13C2C2' },
            { key: 'deleted', color: '#CF1322' }
          ]}
          yLabelFormatter={yLabelFormatter}
          YTickFormatter={yLabelFormatter}
          defaultTooltip={true}
          renderLegend={renderBarLegend}
          isAnimationActive={false}
        />
      </div>
      <div className="xs-mt-64 xs-mb-48 xs-mx-48 border">
        <Table very="simple" celled>
          <Table.Header>
            <Table.Row>
              {tableHeaders.map((text, index) => (
                <Table.HeaderCell key={index} textAlign="center" className="xs-py-16">
                  <p className="text-title">{text}</p>
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tableData.map((data, index) => (
              <Table.Row key={index}>
                <Table.Cell>{data.dateRange}</Table.Cell>
                <Table.Cell>
                  <FormattedNumber value={data.startTotal} notation="compact" />
                  <PercentageIndicator size="sm" />
                </Table.Cell>
                <Table.Cell>
                  <FormattedNumber value={data.added} notation="compact" />
                  <PercentageIndicator
                    size="sm"
                    percentage={percentageDeltaCalculator(data.startAdded || 0, data.added || 0)}
                  />
                </Table.Cell>
                <Table.Cell>
                  {data.deleted ? (
                    <>
                      <FormattedNumber value={data.deleted || 0} notation="compact" />
                      <PercentageIndicator
                        size="sm"
                        percentage={percentageDeltaCalculator(
                          data.startDeleted || 0,
                          data.deleted || 0
                        )}
                      />
                    </>
                  ) : (
                    '-'
                  )}
                </Table.Cell>
                <Table.Cell>
                  <FormattedNumber value={data.total} notation="compact" />
                  <PercentageIndicator
                    size="sm"
                    percentage={percentageDeltaCalculator(data.startTotal, data.total, true)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  )
}

export default TrendPage
