import { EmailFilters } from './emailsListSlice'
import { LIMIT_DEFAULT } from '../../../../../constants'
import { IGetEmailParams, IMailInfo } from '../../../../../interfaces'
import { getAfterCursor, parameterizeArrayofObjects } from '../../../../../utils/graphqlUtil'
import { Email } from '../../../../../services/api/apiTypes'
import { distinctArrayOfObjects } from '../../../../../utils'
import { gql } from 'graphql-request'

type Filter = {
  key: string
  values: string[]
}[]
type BooleanFilter = {
  key: string
  value: boolean
}[]

export const queryEmailCards = ({ datasourceId, filter }: IGetEmailParams): string => {
  let filterParamString = ''
  const filterString = parameterizeArrayofObjects(filter?.filter || [])
  const datasourceIdStr = datasourceId ? `datasourceId: "${datasourceId}"` : ''
  if (filterString) {
    filterParamString += `,filter:${filterString}`
  }
  let mailFilterParams = ''
  if (datasourceIdStr || filterParamString) {
    mailFilterParams = `(${datasourceIdStr}${filterParamString})`
  }
  // adding filter to connection as duplicate datasource can create issues for non mongo ids (objects ids)
  return gql`
    {
      mailGroupBySender${mailFilterParams}{
        count
        edges{
          node{
            sender{
              name
              email
            }
            piiEmails: mails(${datasourceIdStr}, booleanFilter: [{key: HAS_PII_DATA, value: true}]){
              count
            }
            sharedWithExternalMembers: mails(${datasourceIdStr}, booleanFilter: [{key: SHARED_WITH_EXTERNAL_MEMBERS, value: true}, {key: HAS_PII_DATA, value: true}]){
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryEmailCards = (raw: any): { list: Email[]; total: number } => {
  let list = []
  let total = 0

  if (raw && raw.mailGroupBySender) {
    if (raw.mailGroupBySender.edges && Array.isArray(raw.mailGroupBySender.edges)) {
      list = raw.mailGroupBySender.edges.map(
        ({ node: { sender, piiEmails, sharedWithExternalMembers } }) => ({
          senderName: sender.name || '',
          senderEmail: sender.email || '',
          sharedExternallyCount: sharedWithExternalMembers.count || 0,
          piiCount: piiEmails.count || 0
        })
      )
    }

    total = raw.mailGroupBySender.count || 0
  }

  return { list, total }
}

export const queryEmailList = ({
  datasourceId,
  page,
  filter,
  activeTab,
  pageSize = LIMIT_DEFAULT
}: IGetEmailParams): string => {
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  //filter
  let filterParamString = ''
  let filterArr: Filter = []
  if (activeTab === 'recieved-emails-individual' || activeTab === 'recieved-emails-groups') {
    filterArr.push({ key: 'MAIL_FOLDER_TYPE', values: ['INBOX'] })
  } else {
    filterArr.push({ key: 'MAIL_FOLDER_TYPE', values: ['SENT'] })
  }
  if (filter?.filter && filter.filter.length) {
    filterArr = [...filter?.filter, ...filterArr]
  }
  const filterString = parameterizeArrayofObjects(filterArr || [])
  const datasourceIdStr = datasourceId ? `datasourceId: "${datasourceId}"` : ''
  if (filterString) {
    filterParamString += `,filter:${filterString}`
  }

  //boolean filter
  let booleanFilterParamString = ''
  const booleanFilterArr: BooleanFilter = []
  if (activeTab === 'recieved-emails-groups') {
    booleanFilterArr.push({ key: 'HAS_GROUP_EMAIL', value: true })
  }
  if (filter?.booleanFilter && filter.booleanFilter.length) {
    booleanFilterArr.push(...filter.booleanFilter)
  }
  const booleanFilterString = parameterizeArrayofObjects(booleanFilterArr || [])
  if (booleanFilterString) {
    booleanFilterParamString += `,booleanFilter:${booleanFilterString}`
  }
  const objectFilterStr = datasourceId ? `(datasourceIds: ["${datasourceId}"])` : ''
  // adding filter to connection as duplicate datasource can create issues for non mongo ids (objects ids)
  return gql`
  {
    mail(first: ${pageSize}, after: "${cursor}", ${datasourceIdStr}
     ${filterParamString} ${booleanFilterParamString}, sortField: TIMESTAMP) {
      edges{
        node{
          id
          messageId
          timestamp
          isDeleted
          subject
          sender{
            email
          }
          recipients{
            email
          }
          folder{
            edges{
              node{
                folderName
              }
            }
          }
          inboxMailFolders{
            isTrashFolder
            emailId
          }  
          attachments: objects(category: FILE) {
            edges {
              node {
                id
                name
                type
              }
            }
          }
          attributeInstance{
            count
          }
          attributes{
            count
            edges{
              node{
                name
                internalName
              }
            }
          }
          objects${objectFilterStr}{
            edges{
              node{
                alert(first: 10, status: ACTIVE_STATUS){
                  edges{
                    node{
                      name
                      severity
                      id
                    }
                  }
                }
              }
            }
          }
          userEntities{
            count
            edges{
              node{
                name
                id
              }
            }
          }
        }
      }
    }
  }
    `
}

export const mapQueryEmailList = (raw: any): { list: IMailInfo[] } => {
  let list = []
  try {
    if (raw && raw.mail) {
      if (raw.mail.edges && Array.isArray(raw.mail.edges)) {
        list = raw.mail.edges.map(
          ({
            node: {
              id,
              sender,
              messageId,
              recipients = [],
              subject = '',
              attachments,
              folder,
              inboxMailFolders = [],
              timestamp,
              userEntities,
              objects,
              isDeleted = false,
              attributeInstance,
              attributes
            }
          }) => {
            const uniqueAttributes = distinctArrayOfObjects(
              attributes?.edges?.map(({ node }) => node).flat(),
              'internalName'
            )
            return {
              mailObjectId: id,
              messageId,
              subject,
              attachments: attachments?.edges?.map(({ node }) => node) || [],
              folder: folder?.edges?.map(({ node }) => node) || [],
              inboxMailFolders: inboxMailFolders || [],
              sender: sender?.email || '',
              recipients: recipients.map(({ email }) => email),
              entitiesCount: userEntities.count || 0,
              entities: userEntities.edges?.map(({ node }) => node),
              attributeInstanceCount: attributeInstance?.count || 0,
              attributesCount: uniqueAttributes.length,
              attributes: uniqueAttributes,
              alerts: distinctArrayOfObjects(
                objects.edges
                  .map(({ node }) => node?.alert?.edges?.map(({ node: alert }) => alert))
                  .flat(),
                'id'
              ),
              isDeleted,
              sharedAt: timestamp || ''
            }
          }
        )
      }
    }

    return { list }
  } catch (e) {
    console.error(e)
    return { list }
  }
}

export const queryEmailsCount = ({ datasourceId, filter }: EmailFilters): string => {
  const datasourceIdStr = datasourceId ? `datasourceId: "${datasourceId}"` : ''
  //senders filters
  let mailParamStr = ''
  let mailFilterArr: Filter = [{ key: 'MAIL_FOLDER_TYPE', values: ['SENT'] }]
  if (filter?.filter && filter.filter.length) {
    mailFilterArr = [...mailFilterArr, ...filter.filter]
  }
  const mailFilterStr = parameterizeArrayofObjects(mailFilterArr || [])
  const mailBooleanFilterStr = parameterizeArrayofObjects(filter?.booleanFilter || [])
  if (mailFilterStr || datasourceIdStr || mailBooleanFilterStr) {
    mailParamStr += `${datasourceIdStr}, booleanFilter: ${mailBooleanFilterStr}`
    if (mailFilterStr) {
      mailParamStr += `filter:${mailFilterStr}`
    }
  }
  return gql`
    {
      mail(${mailParamStr}){
        count
      }
    }
    `
}

export const mapQueryEmailsCount = ({ mail }: any): { senderTotal: number } => {
  const senderTotal = mail?.count || 0
  return { senderTotal }
}

export const queryRecipientIndividualCount = ({ datasourceId, filter }: EmailFilters): string => {
  const datasourceIdStr = datasourceId ? `datasourceId: "${datasourceId}"` : ''

  let recipientParamStr = ''
  let recipientFilterArr: Filter = [{ key: 'MAIL_FOLDER_TYPE', values: ['INBOX'] }]
  if (filter?.filter && filter.filter.length) {
    recipientFilterArr = [...recipientFilterArr, ...filter.filter]
  }
  const recipientFilterStr = parameterizeArrayofObjects(recipientFilterArr || [])
  const recipientBooleanFilterStr = parameterizeArrayofObjects(filter?.booleanFilter || [])
  if (recipientFilterStr || datasourceIdStr || recipientBooleanFilterStr) {
    recipientParamStr += `${datasourceIdStr}, booleanFilter: ${recipientBooleanFilterStr}`
    if (recipientFilterStr) {
      recipientParamStr += `, filter:${recipientFilterStr}`
    }
  }

  return gql`
    {
      recipientIndividual: mail(${recipientParamStr}){
        count
      }
    }
    `
}

export const mapQueryRecipientIndividualCount = ({
  recipientIndividual
}: any): { recipientIndividualTotal: number } => {
  const recipientIndividualTotal = recipientIndividual?.count || 0

  return { recipientIndividualTotal }
}

export const queryRecipientsGroupCount = ({ datasourceId, filter }: EmailFilters): string => {
  const datasourceIdStr = datasourceId ? `datasourceId: "${datasourceId}"` : ''
  let recipientFilterArr: Filter = [{ key: 'MAIL_FOLDER_TYPE', values: ['INBOX'] }]
  if (filter?.filter && filter.filter.length) {
    recipientFilterArr = [...recipientFilterArr, ...filter.filter]
  }
  const recipientFilterStr = parameterizeArrayofObjects(recipientFilterArr || [])

  //recipient group filter
  let groupFilterParamStr = ''
  let groupBooleanFilterArr: BooleanFilter = [{ key: 'HAS_GROUP_EMAIL', value: true }]
  if (filter?.booleanFilter && filter.booleanFilter.length) {
    groupBooleanFilterArr = [...groupBooleanFilterArr, ...filter.booleanFilter]
  }
  const groupBooleanFilterStr = parameterizeArrayofObjects(groupBooleanFilterArr || [])
  //recipient individual filter.filter is same as recipient group, there is just one extra boolean filter here
  if (recipientFilterStr || datasourceIdStr || groupBooleanFilterStr) {
    groupFilterParamStr += `${datasourceIdStr}, booleanFilter: ${groupBooleanFilterStr}`
    if (recipientFilterStr) {
      groupFilterParamStr += `, filter:${recipientFilterStr}`
    }
  }

  return gql`
    {
      recipientGroups: mail(${groupFilterParamStr}){
        count
      }
    }
    `
}

export const mapQueryRecipientGroupCount = ({
  recipientGroups
}: any): { recipientGroupTotal: number } => {
  const recipientGroupTotal = recipientGroups?.count || 0

  return { recipientGroupTotal }
}
export const queryAllSenders = ({ datasourceId, filter }: EmailFilters): string => {
  let mailFilterStr = ''
  const filterString =
    filter && filter.filter && filter?.filter.length
      ? parameterizeArrayofObjects(filter.filter)
      : ''
  const datasourceIdStr = datasourceId ? `datasourceId: "${datasourceId}"` : ''
  const filterParam = filterString ? `,filter:${filterString}` : ''
  if (filterString || datasourceIdStr) {
    mailFilterStr = `(${datasourceIdStr}${filterParam})`
  }

  return gql`
  {
    mailGroupBySender${mailFilterStr} {
      edges{
        node{
          sender{
            email
          }
        }
      }
    }
  }
    `
}

export const mapQueryAllSenders = (raw: any): { list: string[] } => {
  let list = []
  if (raw && raw.mailGroupBySender) {
    if (raw.mailGroupBySender.edges && Array.isArray(raw.mailGroupBySender.edges)) {
      list = raw.mailGroupBySender.edges.map(({ node: { sender } }) => sender.email)
    }
  }

  return { list }
}

export const queryAllRecipientsIndividual = ({ datasourceId, filter }: EmailFilters): string => {
  let mailFilterStr = ''
  let filterArr: Filter = [{ key: 'MAIL_FOLDER_TYPE', values: ['INBOX'] }]
  if (filter?.filter && filter.filter.length) {
    filterArr = [...filterArr, ...filter.filter]
  }
  const filterString = parameterizeArrayofObjects(filterArr)
  const datasourceIdStr = datasourceId ? `datasourceId: "${datasourceId}"` : ''
  const filterParam = filterString ? `,filter:${filterString}` : ''
  if (filterString || datasourceIdStr) {
    mailFilterStr = `(${datasourceIdStr}${filterParam})`
  }

  return gql`
  {
    mailGroupByUser${mailFilterStr} {
      edges{
        node{
          mailbox{
            email
          }
        }
      }
    }
  }
    `
}

export const mapQueryAllRecipientsIndividual = (raw: any): { list: string[] } => {
  let list = []
  if (raw && raw.mailGroupByUser) {
    if (raw.mailGroupByUser.edges && Array.isArray(raw.mailGroupByUser.edges)) {
      list = raw.mailGroupByUser.edges.map(({ node: { mailbox } }) => mailbox.email)
    }
  }

  return { list }
}

export const queryAllRecipientsGroups = ({ datasourceId, filter }: EmailFilters): string => {
  let mailFilterStr = ''
  const filterString =
    filter && filter.filter && filter?.filter.length
      ? parameterizeArrayofObjects(filter.filter)
      : ''
  const datasourceIdStr = datasourceId ? `datasourceId: "${datasourceId}"` : ''
  const filterParam = filterString ? `,filter:${filterString}` : ''
  if (filterString || datasourceIdStr) {
    mailFilterStr = `(${datasourceIdStr}${filterParam})`
  }

  return gql`
  {
    groupSummary${mailFilterStr} {
      edges{
        node{
          email
        }
      }
    }
  }
    `
}

export const mapQueryAllRecipientsGroups = (raw: any): { list: string[] } => {
  let list = []
  if (raw && raw.groupSummary) {
    if (raw.groupSummary.edges && Array.isArray(raw.groupSummary.edges)) {
      list = raw.groupSummary.edges.map(({ node: { email } }) => email)
    }
  }

  return { list }
}

export const queryEmailRecipientIndividualCard = ({
  datasourceId,
  filter
}: IGetEmailParams): string => {
  let filterParamString = ''
  let filterArr: Filter = [{ key: 'MAIL_FOLDER_TYPE', values: ['INBOX'] }]
  if (filter?.filter && filter.filter.length) {
    filterArr = [...filterArr, ...filter.filter]
  }
  const filterString = parameterizeArrayofObjects(filterArr || [])
  const datasourceIdStr = datasourceId ? `datasourceId: "${datasourceId}"` : ''
  if (filterString) {
    filterParamString += `,filter:${filterString}`
  }
  let mailFilterParams = ''
  if (datasourceIdStr || filterParamString) {
    mailFilterParams = `(${datasourceIdStr}${filterParamString})`
  }
  // adding filter to connection as duplicate datasource can create issues for non mongo ids (objects ids)
  return gql`
    {
      mailGroupByUser${mailFilterParams}{
        count
        edges{
          node{
            mailbox{
              name
              email
            }
            piiEmails: mails(${datasourceIdStr}, booleanFilter: [{key: HAS_PII_DATA, value: true}], filter: {key: MAIL_FOLDER_TYPE, values: ["INBOX"]}){
              count
            }
            sharedWithExternalMembers: mails(${datasourceIdStr}, booleanFilter: [{key: SHARED_WITH_EXTERNAL_MEMBERS, value: true}, {key: HAS_PII_DATA, value: true}], filter: {key: MAIL_FOLDER_TYPE, values: ["INBOX"]}){
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryEmailRecipientIndividualCard = (
  raw: any
): { list: Email[]; total: number } => {
  let list = []
  let total = 0

  if (raw && raw.mailGroupByUser) {
    if (raw.mailGroupByUser.edges && Array.isArray(raw.mailGroupByUser.edges)) {
      list = raw.mailGroupByUser.edges.map(
        ({ node: { mailbox, piiEmails, sharedWithExternalMembers } }) => ({
          senderName: mailbox.name || '',
          senderEmail: mailbox.email || '',
          sharedExternallyCount: sharedWithExternalMembers.count || 0,
          piiCount: piiEmails.count || 0
        })
      )
    }

    total = raw.mailGroupByUser.count || 0
  }

  return { list, total }
}

export const queryEmailRecipientGroupsCard = ({
  datasourceId,
  filter
}: IGetEmailParams): string => {
  let filterParamString = ''
  let filterArr: Filter = [{ key: 'MAIL_FOLDER_TYPE', values: ['INBOX'] }]
  if (filter?.filter && filter.filter.length) {
    filterArr = [...filterArr, ...filter.filter]
  }
  const filterString = parameterizeArrayofObjects(filterArr || [])
  const datasourceIdStr = datasourceId ? `datasourceId: "${datasourceId}"` : ''
  if (filterString) {
    filterParamString += `,filter:${filterString}`
  }
  let mailFilterParams = ''
  if (datasourceIdStr || filterParamString) {
    mailFilterParams = `(${datasourceIdStr}${filterParamString})`
  }
  // adding filter to connection as duplicate datasource can create issues for non mongo ids (objects ids)
  return gql`
    {
      groupSummary${mailFilterParams}{
        count
        edges{
          node{
            email
            piiDataMailCount
          }
        }
      }
    }
  `
}

export const mapQueryEmailRecipientGroupsCard = (raw: any): { list: Email[]; total: number } => {
  let list = []
  let total = 0

  if (raw && raw.groupSummary) {
    if (raw.groupSummary.edges && Array.isArray(raw.groupSummary.edges)) {
      list = raw.groupSummary.edges.map(({ node: { email, piiDataMailCount } }) => ({
        senderEmail: email || '',
        piiCount: piiDataMailCount || 0
      }))
    }

    total = raw.groupSummary.count || 0
  }

  return { list, total }
}
