import {
  queryRelatedColumns,
  mapQueryRelatedColumns,
  queryUpdateColumnsLinkage,
  queryStartEntityScan,
  queryClusterEntityTags,
  mapQueryClusterEntityTags,
  queryCreateClusterEntityTag,
  queryUpdateClusterEntityTag,
  queryDatabaseClusters,
  mapQueryDatabaseClusters,
  queryReviewCluster,
  queryDeleteEntityTags,
  queryRelatedSchemas,
  mapQueryRelatedSchemas,
  queryRelatedSchemaTables,
  mapQueryRelatedSchemaTables,
  queryClusterTables,
  mapQueryClusterTables
} from './queries'
import {
  ATTRIBUTE_IDS,
  ATTRIBUTE_SENSITIVITY,
  ATTRIBUTE_SET_IDS,
  CLASSIFICATION,
  CLUSTER_ID,
  ConstraintTypes,
  DATABASE_ID,
  DATA_SOURCE_ID,
  DATA_SOURCE_TYPES,
  EntityTypes,
  ORPHAN_CLUSTER_ID,
  PAGE,
  SCHEMA,
  SEARCH_QUERY,
  TABLE_ID
} from '../../constants'
import graphqlService from '../../services/graphqlService'
import apiService from '../../services/api/apiService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export type RelatedTable = {
  clusterId: string
  clusterName: string
  tableId: string
  tableName: string
  isReviewed: boolean
  columnsCount: number
  columnsPiiCount: number
  schemaId: string
  schemaName: string
  parentId: string
  isParent: boolean
  isPrevParent?: boolean
  isPrevChild?: boolean
  isUnderCluster?: boolean
}

export type RelatedTableColumn = {
  columnId: string
  columnName: string
  constraint: ConstraintTypes
  qualifiedName: string
  isPii: boolean
  linkedColumns: string[]
  linkColumnQualifiedNamesAdd: string[]
  linkColumnQualifiedNamesDelete: string[]
}

export type RelatedTableWithColumns = {
  tableId: string
  tableName: string
  linkedTables: string[]
  columns: RelatedTableColumn[]
}

export type RelatedSchema = {
  schemaId: string
  schemaName: string
}

export interface RelatedSchemasParams {
  [DATABASE_ID]: string
  [CLUSTER_ID]: string
}
export const ACTION_RELATED_SCHEMAS = 'tableRelationships/schemas'
export const fetchRelatedSchemas = createAsyncThunk(
  ACTION_RELATED_SCHEMAS,
  async (params: RelatedSchemasParams) => {
    const raw = await graphqlService.execute(queryRelatedSchemas(params))
    return mapQueryRelatedSchemas(raw)
  }
)

export enum ReviewFilterTypes {
  notReviewed = 'not_reviewed',
  reviewed = 'reviewed'
}
export interface RelatedSchemaTablesParams {
  parentTableId: string
  [DATABASE_ID]: string
  [PAGE]: number
  [SEARCH_QUERY]?: string
  reviewFilter?: string
  filters: {
    [ATTRIBUTE_SET_IDS]?: string
    [ATTRIBUTE_IDS]?: string
    [SCHEMA]?: string
    [CLASSIFICATION]?: string
    [ATTRIBUTE_SENSITIVITY]?: string
  }
}
export const MAX_SCHEMA_TABLES_COUNT = 25
export const ACTION_RELATED_SCHEMA_TABLES = 'tableRelationships/schemaTables'
export const fetchRelatedSchemaTables = createAsyncThunk(
  ACTION_RELATED_SCHEMA_TABLES,
  async (params: RelatedSchemaTablesParams) => {
    const parentTableId = decodeURIComponent(params.parentTableId)

    const raw = await graphqlService.execute(
      queryRelatedSchemaTables(params),
      ACTION_RELATED_SCHEMA_TABLES
    )
    const relatedTables = mapQueryRelatedSchemaTables(raw, parentTableId)

    // sometimes BE gives duplicate tables after cluster created. Cause is unclear. How to reproduce is unclear. This is UI workaround
    // const addedTableIds = {}
    // relatedTables.tables = [...relatedTables.tables].filter((t) => !addedTableIds[t.tableId])

    if (parentTableId !== ORPHAN_CLUSTER_ID) {
      const linkedTablesResponse = await graphqlService.execute(
        queryRelatedColumns({ [TABLE_ID]: parentTableId })
      )
      const { linkedTables } = mapQueryRelatedColumns(linkedTablesResponse)

      relatedTables.tables = relatedTables.tables.map((t) => {
        return linkedTables.includes(t.tableId) ? { ...t, parentId: parentTableId } : t
      })
    }

    return relatedTables
  }
)

/** Optimization to get graph tables in fast way and without waiting until all the schema tables fetched  */
export interface ClusterTablesParams {
  [TABLE_ID]: string
}
export const ACTION_CLUSTER_TABLES = 'tableRelationships/graphTables'
export const fetchClusterTables = createAsyncThunk(
  ACTION_CLUSTER_TABLES,
  async (params: ClusterTablesParams) => {
    const raw = await graphqlService.execute(
      queryClusterTables({ tableId: decodeURIComponent(params.tableId) })
    )
    return mapQueryClusterTables(raw)
  }
)

export type LinkedTableType = 'candidate' | 'parent'

export interface RelatedTableColumnsParams {
  [TABLE_ID]: string
  tableType: LinkedTableType
}

export const ACTION_RELATED_TABLE_COLUMNS = 'tableRelationships/tableColumns'
export const fetchRelatedTableColumns = createAsyncThunk(
  ACTION_RELATED_TABLE_COLUMNS,
  async (params: RelatedTableColumnsParams) => {
    const linkedTablesResponse = await graphqlService.execute(queryRelatedColumns(params))
    return { ...params, table: mapQueryRelatedColumns(linkedTablesResponse) }
  }
)

export interface CreateParentTableParams {
  [TABLE_ID]: string
  entityType: EntityTypes
}

export type IdentityTableParams = {
  typeName: string
  classifications: Array<{
    identity: {
      entityType: EntityTypes
    }
    qualifiedName: string
  }>
}

export const ACTION_CREATE_PARENT_TABLE = 'tableRelationships/createCluster'
export const createCluster = createAsyncThunk(
  ACTION_CREATE_PARENT_TABLE,
  async (params: CreateParentTableParams, { rejectWithValue }) => {
    try {
      const apiParams = {
        typeName: 'table',
        classifications: [
          {
            identity: { entityType: EntityTypes.customer },
            qualifiedName: params[TABLE_ID].replaceAll('+', ' ')
          }
        ]
      }

      await apiService.postIdentityTable(apiParams)
      return {
        statusMessage: 'tableRelationships.createCluster.success',
        [TABLE_ID]: params[TABLE_ID]
      }
    } catch (error) {
      return rejectWithValue({
        statusMessage: 'tableRelationships.createCluster.error',
        [TABLE_ID]: params[TABLE_ID]
      })
    }
  }
)

export interface SaveTableRelationshipsParams {
  candidateTable: RelatedTableWithColumns
  parentTable: RelatedTableWithColumns
}
export const ACTION_TABLE_RELATIONSHIPS_SAVE = 'tableRelationships/saveTableRelationships'
export const saveTableRelationships = createAsyncThunk(
  ACTION_TABLE_RELATIONSHIPS_SAVE,
  async (params: SaveTableRelationshipsParams) => {
    for (const parentColumn of params.parentTable.columns) {
      if (parentColumn.linkColumnQualifiedNamesAdd.length > 0) {
        await Promise.all(
          parentColumn.linkColumnQualifiedNamesAdd.map(async (candidateColumnQualifiedName) => {
            await graphqlService.execute(
              queryUpdateColumnsLinkage({
                parentColumnQualifiedName: parentColumn.qualifiedName,
                candidateColumnQualifiedName,
                action: 'CREATE'
              })
            )
          })
        )
      }
    }

    return params
  }
)

export interface DeleteTableRelationshipsParams {
  candidateTable: RelatedTable
  parentTable: RelatedTable
}
export const ACTION_TABLE_RELATIONSHIPS_DELETE = 'tableRelationships/deleteTableRelationships'
export const deleteTableRelationships = createAsyncThunk(
  ACTION_TABLE_RELATIONSHIPS_DELETE,
  async (params: DeleteTableRelationshipsParams) => {
    // TODO: mock!
    // const parentTableRaw = await graphqlService.execute(
    //   queryRelatedColumns({ tableId: params.parentTable.tableId })
    // )
    // const parentTableWithColumns = mapQueryRelatedColumns(parentTableRaw)

    // const candidateTableRaw = await graphqlService.execute(
    //   queryRelatedColumns({ tableId: params.candidateTable.tableId })
    // )
    // const candidateTableWithColumns = mapQueryRelatedColumns(candidateTableRaw)

    // for (const parentColumn of parentTableWithColumns.columns) {
    //   if (parentColumn.linkColumnQualifiedNamesAdd.length > 0) {
    //     await Promise.all(
    //       parentColumn.linkColumnQualifiedNamesAdd.map(async (candidateColumnQualifiedName) => {
    //         await graphqlService.execute(
    //           queryUpdateColumnsLinkage({
    //             parentColumnQualifiedName: parentColumn.qualifiedName,
    //             candidateColumnQualifiedName,
    //             action: 'DELETE'
    //           })
    //         )
    //       })
    //     )
    //   }
    // }

    return params
  }
)

export interface StartEntityScanParams {
  [CLUSTER_ID]: string
}
export const ACTION_TABLE_RELATIONSHIPS_START_ENTITY_SCAN = 'tableRelationships/startEntityScan'
export const triggerEntityScan = createAsyncThunk(
  ACTION_TABLE_RELATIONSHIPS_START_ENTITY_SCAN,
  async (params: StartEntityScanParams) => {
    await graphqlService.execute(queryReviewCluster(params))
    const entityScanRes = await graphqlService.execute(queryStartEntityScan(params))
    return entityScanRes
  }
)

export interface RemoveClusterParams {
  [CLUSTER_ID]: string
}
export const ACTION_REMOVE_CLUSTER = 'tableRelationships/removeTableCluster'
export const removeCluster = createAsyncThunk(
  ACTION_REMOVE_CLUSTER,
  async (params: RemoveClusterParams) => {
    const removedTableId = await apiService.deleteTableCluster(params[CLUSTER_ID])
    return removedTableId
  }
)

export enum TableLinksModals {
  pii = 'pii',
  links = 'links',
  columnsClassification = 'columnsClassification'
}

export type ParentTableUpdates = {
  newParentTableId: string
}

export type ClusterEntityTag = {
  id: string
  name: string
}
export type ClusterEntityTagsParams = {
  [CLUSTER_ID]: string
  [DATA_SOURCE_ID]?: string
  entityTagNames?: string[]
  filterId?: string
  filterLayer?: 'datasource' | 'cluster'
  filterOwner?: string
  filterName?: string
}

export const ACTION_CLUSTER_ENTITY_TAGS_FETCH = 'tableRelationships/entityTagsFetch'
export const fetchClusterEntityTags = createAsyncThunk(
  ACTION_CLUSTER_ENTITY_TAGS_FETCH,
  async (params: ClusterEntityTagsParams) => {
    let filters: { tags: ClusterEntityTag[]; filterId: string } = { filterId: '', tags: [] }

    const clusterRes = await graphqlService.execute(
      queryClusterEntityTags({ ...params, filterLayer: 'cluster' })
    )
    filters = mapQueryClusterEntityTags(clusterRes)

    if (!filters.filterId) {
      const dsRes = await graphqlService.execute(
        queryClusterEntityTags({ ...params, filterLayer: 'datasource' })
      )
      filters = mapQueryClusterEntityTags(dsRes)
    }

    return filters
  }
)
export const ACTION_CLUSTER_ENTITY_TAGS_CREATE = 'tableRelationships/entityTagsCreate'
export const createClusterEntityTag = createAsyncThunk(
  ACTION_CLUSTER_ENTITY_TAGS_CREATE,
  async (params: ClusterEntityTagsParams) => {
    await graphqlService.execute(queryCreateClusterEntityTag(params))
    const raw = await graphqlService.execute(
      queryClusterEntityTags({ ...params, filterLayer: 'cluster' })
    )
    return mapQueryClusterEntityTags(raw)
  }
)
export const ACTION_CLUSTER_ENTITY_TAGS_UPDATE = 'tableRelationships/entityTagsUpdate'
export const updateClusterEntityTag = createAsyncThunk(
  ACTION_CLUSTER_ENTITY_TAGS_UPDATE,
  async (params: ClusterEntityTagsParams) => {
    await graphqlService.execute(queryUpdateClusterEntityTag(params))
    const raw = await graphqlService.execute(
      queryClusterEntityTags({ ...params, filterLayer: 'cluster' })
    )
    return mapQueryClusterEntityTags(raw)
  }
)
export type ClusterEntityTagsDeleteParams = {
  filterId: string
}
export const ACTION_CLUSTER_ENTITY_TAGS_DELETE = 'tableRelationships/entityTagsDelete'
export const deleteClusterEntityTags = createAsyncThunk(
  ACTION_CLUSTER_ENTITY_TAGS_DELETE,
  async (params: ClusterEntityTagsDeleteParams) => {
    return await graphqlService.execute(queryDeleteEntityTags(params))
  }
)

export type Cluster = {
  clusterId: string
  clusterName: string
  isReviewed: boolean
}
export type DatabaseClustersParams = {
  [DATABASE_ID]: string
}
export const ACTION_DATABASE_CLUSTERS = 'tableRelationships/databaseClusters'
export const fetchDatabaseClusters = createAsyncThunk(
  ACTION_DATABASE_CLUSTERS,
  async (params: DatabaseClustersParams) => {
    const raw = await graphqlService.execute(queryDatabaseClusters(params))
    return mapQueryDatabaseClusters(raw)
  }
)

interface TableRelationshipsState {
  dataSourceId?: string
  dataSourceName?: string
  dataSourceOwner?: string
  dataSourceType?: DATA_SOURCE_TYPES
  databaseId?: string
  databaseName?: string
  clusterId?: string
  clusterName?: string
  clusterSchemaId?: string
  tables?: RelatedTable[]
  tablesTotal?: number
  tablesFetchedCount: number
  schemas?: RelatedSchema[]
  selectedTable: RelatedTable | null
  showModal: TableLinksModals | null
  relationships: {
    candidate: RelatedTableWithColumns | null
    parent: RelatedTableWithColumns | null
  }
  clusterEntityTags?: {
    filterId: string
    tags: ClusterEntityTag[]
  }
  parentTableUpdates?: ParentTableUpdates
  clusters?: Cluster[]
  showAllChildTables: boolean
  readonly: boolean
}

const initialState: TableRelationshipsState = {
  selectedTable: null,
  showModal: null,
  relationships: {
    candidate: null,
    parent: null
  },
  showAllChildTables: false,
  readonly: true,
  tablesFetchedCount: 0
}

const tableRelationshipsSlice = createSlice({
  name: 'tableRelationships',
  initialState,
  reducers: {
    resetState: () => initialState,
    setSelectedTable: (state, { payload }) => {
      state.selectedTable = payload
    },
    setModal: (state, { payload }) => {
      state.showModal = payload
    },
    updateLinks: (state, action) => {
      const { tableType, column } = action.payload

      state.relationships[tableType].columns = state.relationships[tableType].columns.map((c) => {
        return c.columnId === column.columnId ? { ...c, ...column } : c
      })
    },
    updateTables: (state, { payload }) => {
      if (state.tables) {
        state.tables = state.tables.map((t) => {
          return t.tableId === payload.tableId
            ? { ...t, columnsPiiCount: payload.columnsPiiCount }
            : t
        })
      }
    },
    setParentTableUpdates: (state, { payload }) => {
      state.parentTableUpdates = payload
    },
    setShowAllChildTables: (state, { payload }) => {
      state.showAllChildTables = payload
    },
    setReadonly: (state, { payload }) => {
      state.readonly = payload
    },
    setTablesFetchedCount: (state, { payload }) => {
      state.tablesFetchedCount = payload
    },
    resetParentTableUpdates: (state) => {
      state.parentTableUpdates = initialState.parentTableUpdates
    },
    resetClusterEntityTags: (state) => {
      state.clusterEntityTags = initialState.clusterEntityTags
    },
    resetSchemaTables: (state) => {
      state.tables = initialState.tables
      state.tablesTotal = initialState.tablesTotal
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRelatedSchemas.fulfilled, (state, { payload }) => {
      state.dataSourceId = payload.dataSourceId
      state.dataSourceName = payload.dataSourceName
      state.dataSourceType = payload.dataSourceType
      state.dataSourceOwner = payload.dataSourceOwner
      state.databaseId = payload.databaseId
      state.databaseName = payload.databaseName
      state.clusterId = payload.clusterId
      state.clusterName = payload.clusterName
      state.schemas = payload.schemas
    })
    builder.addCase(fetchRelatedSchemaTables.fulfilled, (state, { payload }) => {
      state.tables = payload.tables.sort((a) => (a.isParent ? -1 : 1))
      state.tablesTotal = payload.total
    })
    builder.addCase(fetchClusterTables.fulfilled, (state, { payload }) => {
      state.tables = payload.sort((a) => (a.isParent ? -1 : 1))
      state.clusterSchemaId = payload[0].schemaId
    })
    // TODO add right types for payload later
    builder.addCase(createCluster.fulfilled, (state, { payload }: { payload: any }) => {
      state.tables = state.tables?.map((t) => {
        if (t.tableId === payload[TABLE_ID]) {
          return {
            ...t,
            isParent: true,
            parentId: payload[TABLE_ID],
            isPrevParent: false,
            clusterId: t.tableId,
            clusterName: t.tableName
          }
        } else {
          return {
            ...t,
            isPrevChild: t.isPrevChild || t.isPrevParent,
            isParent: false,
            isPrevParent: false
          }
        }
      })
    })
    builder.addCase(removeCluster.fulfilled, (state) => {
      const prevParent = state.tables?.find((t) => t.isParent)

      state.tables = state.tables?.map((t) => ({
        ...t,
        isPrevChild:
          (t.clusterId === prevParent?.tableId && !t.isParent) ||
          (t.isPrevChild && !t.isPrevParent && !t.isParent),
        isPrevParent: t.tableId === prevParent?.tableId,
        isParent: false,
        clusterId: '',
        clusterName: '',
        isReviewed: false,
        parentId: ''
      }))
    })
    builder.addCase(fetchRelatedTableColumns.fulfilled, (state, { payload }) => {
      state.relationships[payload.tableType] = payload.table
    })
    builder.addCase(saveTableRelationships.fulfilled, (state, { payload }) => {
      const parentId = payload.parentTable.tableId
      const candidateId = payload.candidateTable.tableId
      state.tables = state.tables?.map((t) => ({
        ...t,
        parentId: t.tableId === candidateId ? parentId : t.parentId
      }))

      state.selectedTable = initialState.selectedTable
      state.relationships = initialState.relationships
      state.showModal = initialState.showModal
    })
    builder.addCase(deleteTableRelationships.fulfilled, (state, { payload }) => {
      const candidateId = payload.candidateTable.tableId

      state.tables = state.tables?.map((t) => ({
        ...t,
        parentId: t.tableId === candidateId ? '' : t.parentId
      }))
    })
    builder.addCase(fetchClusterEntityTags.fulfilled, (state, { payload }) => {
      state.clusterEntityTags = payload
    })
    builder.addCase(createClusterEntityTag.fulfilled, (state, { payload }) => {
      state.clusterEntityTags = payload
    })
    builder.addCase(updateClusterEntityTag.fulfilled, (state, { payload }) => {
      state.clusterEntityTags = payload
    })
    builder.addCase(deleteClusterEntityTags.fulfilled, (state) => {
      state.clusterEntityTags = initialState.clusterEntityTags
    })
    builder.addCase(fetchDatabaseClusters.fulfilled, (state, { payload }) => {
      state.clusters = payload
    })
  }
})

export const {
  resetState,
  setSelectedTable,
  setModal,
  updateLinks,
  setParentTableUpdates,
  resetParentTableUpdates,
  resetClusterEntityTags,
  setShowAllChildTables,
  setReadonly,
  updateTables,
  resetSchemaTables,
  setTablesFetchedCount
} = tableRelationshipsSlice.actions

export default tableRelationshipsSlice.reducer
