import { mapQuerySharesList, querySharesList } from './queries'
import graphqlService from '../../services/graphqlService'
import { DATA_SOURCE_ID, PAGE, PAGE_SIZE } from '../../constants'
import { FilterParams } from '../../interfaces'
import { RootState } from '../../rootReducer'
import { getGlobalParams } from '../../utils/urlUtil'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export interface FileShare {
  id: string
  name: string
  objectsCount?: number
  impactedObjectsCount?: number
  entitiesCount?: number
}

export interface FileShareObjectValue {
  shareName: string
  path: string
}

export interface BucketState {
  list: FileShare[]
  total: number
}

const initialState: BucketState = {
  list: [],
  total: 0
}

export type FileShareParams = {
  [PAGE]: number
  [PAGE_SIZE]?: number
  [DATA_SOURCE_ID]: string
  filters?: FilterParams
}
export const ACTION_FETCH_FILE_SHARES_LIST = 'fileShares/list'
export const fetchFileSharesList = createAsyncThunk(
  ACTION_FETCH_FILE_SHARES_LIST,
  async (params: FileShareParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const resultRaw = await graphqlService.execute(querySharesList(queryParams))
    return mapQuerySharesList(resultRaw)
  }
)

const fileSharesSlice = createSlice({
  name: 'fileShares',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFileSharesList.fulfilled, (state, { payload }) => {
      state.list = payload.list
      state.total = payload.total
    })
  }
})

export default fileSharesSlice.reducer
