import {
  BaseRopaQuestionnaire,
  DataElementsQuestionnaire,
  DataRetentionQuestionnaire,
  DataSubjectsQuestionnaire,
  RopaProcessV2,
  RopaQuestion,
  RopaSystemTopics,
  SafeguardsQuestionnaire,
  TicketStatus,
  TransfersQuestionnaire
} from './ropaSliceV2'
import {
  SYSTEM_TOPICS,
  TotalQuestionsEvaluatorMapper,
  getCustomQuestionsAndAnswersCount,
  getFieldProperty,
  mapTopicsToQuestionnaire,
  removeDuplicates
} from './ropaUtil'
import { RopaQuestionnaireTopicNames } from '../../constants'

export const deepClone = (obj) => typeof obj === 'object' && JSON.parse(JSON.stringify(obj))

export const getCompactCollaboratorDetails = (ropaCollaboration) => {
  const isFieldAssigned = (properties) => getFieldProperty('isAssigned', properties)[0] === 'true'
  const mapQuestionIds = (topic) =>
    topic.questions?.edges?.map(({ node }) => node.processQuestionId) || []
  return ropaCollaboration?.edges?.map(({ node }) => {
    const dataElements = node.ropaTopics?.dataElements
    const dataSubjects = node.ropaTopics?.dataSubjects
    const dataRetention = node.ropaTopics?.dataRetention
    const safeguards = node.ropaTopics?.safeguards
    const transfers = node.ropaTopics?.transfers
    const dataFlow = node.ropaTopics?.dataFlow
    const customTopics = node.ropaTopics?.customTopics || []
    const customQuestionIds: Set<string> = new Set([])
    customTopics.forEach((customTopic) => {
      const ids = mapQuestionIds(customTopic)
      ids.forEach((id) => {
        customQuestionIds.add(id)
      })
    })

    const data = {
      id: node.id || '',
      name: node.name || '',
      email: node.emailId || '',
      parentCollaboratorId: node?.parentCollaboratorId || '',
      status: node.status || '',
      createdAt: node.createdAt || '',
      updateTimestamp: node.updateTimestamp || '',

      totalSections:
        Object.keys(node.ropaTopics || {}).filter(
          (key) => node.ropaTopics?.[key] && key !== RopaQuestionnaireTopicNames.customTopics
        ).length + (node?.ropaTopics?.customTopics?.length || 0),
      totalQuestions:
        getTicketOrProcessStats(mapTopicsToQuestionnaire({ id: '' }, node)).questions +
        (node?.ropaTopics?.customTopics?.reduce(
          (total, topic) => (total += topic.questions?.edges?.length),
          0
        ) || 0),
      dataElementsQuestionIds: dataElements
        ? [
            ...mapQuestionIds(dataElements),
            ...(isFieldAssigned(dataElements.attributeProperties) ? ['attribute'] : [])
          ]
        : [],
      dataFlowQuestionIds: dataFlow ? [...(dataFlow ? ['dfd'] : [])] : [],
      dataSubjectsQuestionIds: dataSubjects
        ? [
            ...mapQuestionIds(dataSubjects),
            ...(isFieldAssigned(dataSubjects.dataSubjectProperties) ? ['dataSubjects1'] : []),
            ...(isFieldAssigned(dataSubjects.specialDataSubjectProperties)
              ? ['dataSubjects2']
              : []),
            ...(isFieldAssigned(dataSubjects.legalBasisProperties) ? ['dataSubjects3'] : [])
          ]
        : [],
      dataRetentionQuestionIds: dataRetention
        ? [
            ...mapQuestionIds(dataRetention),
            ...(isFieldAssigned(dataRetention.dataRetentionProperties) ? ['dataRetention'] : [])
          ]
        : [],
      safeguardsQuestionIds: safeguards
        ? [
            ...mapQuestionIds(safeguards),
            ...(isFieldAssigned(safeguards.safeguardProperties) ? ['safeguards'] : [])
          ]
        : [],
      transfersQuestionIds: transfers
        ? [
            ...mapQuestionIds(transfers),
            ...(isFieldAssigned(transfers.thirdParty.thirdPartyProperties)
              ? ['thirdParty-transfers']
              : []),
            ...(isFieldAssigned(transfers.crossBorder.crossBorderProperties)
              ? ['crossBorder-transfers']
              : [])
          ]
        : [],
      customQuestionIds: [...customQuestionIds],
      topics: {
        dataElements,
        dataSubjects,
        dataRetention,
        safeguards,
        transfers,
        dataFlow,
        customTopics:
          customTopics?.map((topic) => ({
            optional: false,
            name: topic.name || '',
            questions:
              topic.questions?.edges?.map(({ node }) => ({
                questionId: node.questionId || '',
                question: node.question || '',
                isMandatory: node.isMandatory || false
              })) || []
          })) || []
      }
    }
    return data
  })
}

const filterSubmittedTickets = (t) => t && t?.collaboratorDetails?.status === TicketStatus.Submitted

const collateCustomQuestionResponse = (
  collaboratorTopics: any[],
  customQuestions?: RopaQuestion[] | []
): RopaQuestion[] => {
  if (!collaboratorTopics || !customQuestions) return []
  let clonedQuestions = [...customQuestions]

  const collatedQuestions = collaboratorTopics
    .filter(filterSubmittedTickets)
    ?.reduce((questions, topic) => {
      questions = [...questions, ...(topic.questions?.edges?.map((edge) => edge?.node) || [])]
      return questions
    }, [])

  clonedQuestions = clonedQuestions.map((question) => {
    const questionsInCollatedList = collatedQuestions.filter(
      (q) =>
        question.questionId === q?.processQuestionId && question.responseType === q.responseType
    )
    question.questionResponse = [
      ...new Set([
        ...(question.questionResponse || []),
        ...(questionsInCollatedList.map((q) => q?.questionResponse) || []).flat()
      ])
    ]
    return question
  })
  return clonedQuestions
}

const collateCustomTopicsResponse = (
  customTopics: BaseRopaQuestionnaire[],
  collaboratorTopics: any[]
): BaseRopaQuestionnaire[] => {
  if (!collaboratorTopics || !collaboratorTopics.length) return customTopics
  return customTopics.map((topic) => {
    topic.questions = collateCustomQuestionResponse(collaboratorTopics, topic.questions)
    return topic
  })
}

export const collateDataElementsResponse = (
  parentTopic: DataElementsQuestionnaire,
  collaboratorTopics: any[]
): DataElementsQuestionnaire => {
  if (!collaboratorTopics || !collaboratorTopics.length) return parentTopic
  const clonedTopic = { ...parentTopic }

  const collatedAnswers = collaboratorTopics.filter(filterSubmittedTickets)?.reduce(
    (answers, topic) => {
      answers.attributes = [
        ...answers.attributes,
        ...(topic.attributes?.edges?.map(({ node }) => node.id) || [])
      ]
      answers.lawfulBases = [
        ...answers.lawfulBases,
        ...(topic.lawfulBases?.edges?.map(({ node }) => node.id) || [])
      ]
      answers.specialElements = [
        ...answers.specialElements,
        ...(topic.specialElements?.map((specialElement) => ({
          attributeName: specialElement.attributeName,
          sensitivity: specialElement.sensitivity
        })) || [])
      ]
      answers.associatedDatasources = [
        ...answers.associatedDatasources,
        ...(topic.associatedDatasources?.edges?.map(({ node }) => node.id) || [])
      ]
      return answers
    },
    {
      attributes: parentTopic.attributes || [],
      lawfulBases: parentTopic.lawfulBases || [],
      specialElements: parentTopic.specialElements || [],
      associatedDatasources: parentTopic.associatedDatasources || []
    }
  )

  clonedTopic.attributes = removeDuplicates(collatedAnswers.attributes)
  clonedTopic.lawfulBases = removeDuplicates(collatedAnswers.lawfulBases)
  clonedTopic.specialElements = removeDuplicates(collatedAnswers.specialElements, 'attributeName')
  clonedTopic.associatedDatasources = removeDuplicates(collatedAnswers.associatedDatasources)
  clonedTopic.questions = collateCustomQuestionResponse(collaboratorTopics, clonedTopic.questions)
  clonedTopic.collaboratorDetails = collaboratorTopics.map((t) => t.collaboratorDetails)

  return clonedTopic
}

export const collateDataSubjectsResponse = (
  parentTopic: DataSubjectsQuestionnaire,
  collaboratorTopics: any[]
): DataSubjectsQuestionnaire => {
  if (!collaboratorTopics || !collaboratorTopics.length) return parentTopic
  const clonedTopic = { ...parentTopic }

  const collatedAnswers = collaboratorTopics.filter(filterSubmittedTickets)?.reduce(
    (answers, topic) => {
      answers.categories = [
        ...answers.categories,
        ...(topic?.dataSubjects?.edges?.map(({ node }) => node.id) || [])
      ]
      answers.lawfulBasis = [
        ...answers.lawfulBasis,
        ...(topic.legalBases?.edges?.map(({ node }) => node.id) || [])
      ]
      answers.specialCategories = [
        ...answers.specialCategories,
        ...(topic.specialDataSubjects?.edges?.map(({ node }) => node.id) || [])
      ]
      answers.specialDataSubject = topic?.specialDataSubject || false
      return answers
    },
    {
      categories: parentTopic.categories || [],
      specialCategories: parentTopic.specialCategories || [],
      lawfulBasis: parentTopic.lawfulBasis || [],
      specialDataSubject: parentTopic.specialDataSubject || false
    }
  )

  clonedTopic.categories = removeDuplicates(collatedAnswers.categories)
  clonedTopic.lawfulBasis = removeDuplicates(collatedAnswers.lawfulBasis)
  clonedTopic.specialCategories = removeDuplicates(collatedAnswers.specialCategories)
  clonedTopic.specialDataSubject = collatedAnswers.specialDataSubject
  clonedTopic.questions = collateCustomQuestionResponse(collaboratorTopics, clonedTopic.questions)
  clonedTopic.collaboratorDetails = collaboratorTopics.map((t) => t.collaboratorDetails)
  return clonedTopic
}

export const collateDataRetentionResponse = (
  parentTopic: DataRetentionQuestionnaire,
  collaboratorTopics: any[]
): DataRetentionQuestionnaire => {
  if (!collaboratorTopics || !collaboratorTopics.length) return parentTopic
  const clonedTopic = { ...parentTopic }

  const collatedAnswers = collaboratorTopics.filter(filterSubmittedTickets)?.reduce(
    (answers, topic) => {
      answers.details = [
        ...answers.details,
        ...(topic?.dataRetentionInfo?.edges?.map(({ node }) => ({
          id: node.id,
          durationType: node.durationType,
          durationCount: node.durationCount,
          triggerEvent: node.triggerEvent,
          attributeSets: node.attributeSet?.edges?.map(({ node }) => node.id) || []
        })) || [])
      ]

      return answers
    },
    {
      details: parentTopic.details || []
    }
  )

  clonedTopic.details = collatedAnswers.details

  clonedTopic.collaboratorDetails = collaboratorTopics.map((t) => t.collaboratorDetails)

  return clonedTopic
}

export const collateSafeguardsResponse = (
  parentTopic: SafeguardsQuestionnaire,
  collaboratorTopics: any[]
): SafeguardsQuestionnaire => {
  if (!collaboratorTopics || !collaboratorTopics.length) return parentTopic
  const clonedTopic = { ...parentTopic }

  const collatedAnswers = collaboratorTopics.filter(filterSubmittedTickets)?.reduce(
    (answers, topic) => {
      answers.safeguards = [
        ...answers.safeguards,
        ...(topic.safeguards?.edges?.map(({ node }) => node.id) || [])
      ]

      return answers
    },
    {
      safeguards: parentTopic.safeguards || []
    }
  )

  clonedTopic.safeguards = removeDuplicates(collatedAnswers.safeguards)
  clonedTopic.questions = collateCustomQuestionResponse(collaboratorTopics, clonedTopic.questions)
  clonedTopic.collaboratorDetails = collaboratorTopics.map((t) => t.collaboratorDetails)

  return clonedTopic
}

export const collateTransfersResponse = (
  parentTopic: TransfersQuestionnaire,
  collaboratorTopics: any[]
): TransfersQuestionnaire => {
  if (!collaboratorTopics || !collaboratorTopics.length) return parentTopic
  const clonedTopic = { ...parentTopic }

  const collatedAnswers = collaboratorTopics.filter(filterSubmittedTickets)?.reduce(
    (answers, topic) => {
      answers.thirdPartyDetails = [
        ...answers.thirdPartyDetails,
        ...(topic?.thirdParty?.details?.map((detail) => ({
          safeguards: detail.safeguards?.edges?.map(({ node }) => node.id),
          safeguardsProperties: detail.safeguardsProperties,
          email: detail.email,
          emailProperties: detail.emailProperties,
          name: detail.name,
          nameProperties: detail.nameProperties,
          address: detail.address,
          addressProperties: detail.addressProperties
        })) || [])
      ]
      answers.crossBorderDetails = [
        ...answers.crossBorderDetails,
        ...(topic?.crossBorder?.details?.map((detail) => ({
          safeguards: detail.safeguards?.edges?.map(({ node }) => node.id),
          safeguardsProperties: detail.safeguardsProperties,
          email: detail.email,
          emailProperties: detail.emailProperties,
          organisation: detail.organisation,
          organisationProperties: detail.organisationProperties,
          country: detail.country,
          countryProperties: detail.countryProperties
        })) || [])
      ]
      answers.isThirdParty = topic?.thirdParty?.isThirdParty || false
      answers.isCrossBorder = topic?.crossBorder?.isCrossBorder || false

      return answers
    },
    {
      thirdPartyDetails: parentTopic.thirdParty.details || [],
      crossBorderDetails: parentTopic.crossBorder.details || [],
      isThirdParty: parentTopic.thirdParty.isThirdParty || false,
      isCrossBorder: parentTopic.crossBorder.isCrossBorder || false
    }
  )

  clonedTopic.thirdParty.details = removeDuplicates(collatedAnswers.thirdPartyDetails, 'email')
  clonedTopic.thirdParty.isThirdParty = collatedAnswers.isThirdParty
  clonedTopic.crossBorder.details = removeDuplicates(collatedAnswers.crossBorderDetails, 'email')
  clonedTopic.crossBorder.isCrossBorder = collatedAnswers.isCrossBorder

  clonedTopic.collaboratorDetails = collaboratorTopics.map((t) => t.collaboratorDetails)
  clonedTopic.questions = collateCustomQuestionResponse(collaboratorTopics, clonedTopic.questions)
  return clonedTopic
}

export const collateCollaboratorResponses = (process: RopaProcessV2, node): RopaProcessV2 => {
  if (!node) return process
  if (node && node?.edges) {
    const allTopics = node?.edges?.reduce(
      (topics, { node }) => {
        if (node?.ropaTopics?.dataElements) {
          topics.allDataElementsTopics = [
            ...topics.allDataElementsTopics,
            {
              ...node?.ropaTopics?.dataElements,
              collaboratorDetails: {
                email: node?.emailId,
                status: node?.status
              }
            }
          ]
        }
        if (node?.ropaTopics?.dataSubjects) {
          topics.allDataSubjectTopics = [
            ...topics.allDataSubjectTopics,
            {
              ...node?.ropaTopics?.dataSubjects,
              collaboratorDetails: {
                email: node?.emailId,
                status: node?.status
              }
            }
          ]
        }
        if (node?.ropaTopics?.dataRetention) {
          topics.allDataRetentionTopics = [
            ...topics.allDataRetentionTopics,
            {
              ...node?.ropaTopics?.dataRetention,
              collaboratorDetails: {
                email: node?.emailId,
                status: node?.status
              }
            }
          ]
        }
        if (node?.ropaTopics?.safeguards) {
          topics.allSafeguardsTopics = [
            ...topics.allSafeguardsTopics,
            {
              ...node?.ropaTopics?.safeguards,
              collaboratorDetails: {
                email: node?.emailId,
                status: node?.status
              }
            }
          ]
        }
        if (node?.ropaTopics?.transfers) {
          topics.allTransfersTopics = [
            ...topics.allTransfersTopics,
            {
              ...node?.ropaTopics?.transfers,
              collaboratorDetails: {
                email: node?.emailId,
                status: node?.status
              }
            }
          ]
        }
        if (node?.ropaTopics?.customTopics.length) {
          topics.allCustomTopics = [
            ...topics.allCustomTopics,
            ...node?.ropaTopics?.customTopics.map((topic) => ({
              ...topic,
              collaboratorDetails: {
                email: node?.emailId,
                status: node?.status
              }
            }))
          ]
        }

        return topics
      },
      {
        allDataElementsTopics: [],
        allDataSubjectTopics: [],
        allDataRetentionTopics: [],
        allSafeguardsTopics: [],
        allTransfersTopics: [],
        allCustomTopics: []
      }
    )
    const {
      allDataElementsTopics = [],
      allDataSubjectTopics = [],
      allDataRetentionTopics = [],
      allSafeguardsTopics = [],
      allTransfersTopics = [],
      allCustomTopics = []
    } = allTopics
    if (process.questionnaire?.dataElements) {
      process.questionnaire.dataElements = collateDataElementsResponse(
        deepClone(process.questionnaire.dataElements),
        allDataElementsTopics.filter((t) => t)
      )
    }
    if (process.questionnaire?.dataSubjects) {
      process.questionnaire.dataSubjects = collateDataSubjectsResponse(
        deepClone(process.questionnaire.dataSubjects),
        allDataSubjectTopics.filter((t) => t)
      )
    }
    if (process.questionnaire?.dataRetention) {
      process.questionnaire.dataRetention = collateDataRetentionResponse(
        deepClone(process.questionnaire.dataRetention),
        allDataRetentionTopics.filter((t) => t)
      )
    }
    if (process.questionnaire?.safeguards) {
      process.questionnaire.safeguards = collateSafeguardsResponse(
        deepClone(process.questionnaire.safeguards),
        allSafeguardsTopics.filter((t) => t)
      )
    }
    if (process.questionnaire?.transfers) {
      process.questionnaire.transfers = collateTransfersResponse(
        deepClone(process.questionnaire.transfers),
        allTransfersTopics.filter((t) => t)
      )
    }
    if (process.questionnaire?.customTopics) {
      process.questionnaire.customTopics = collateCustomTopicsResponse(
        deepClone(process.questionnaire.customTopics),
        allCustomTopics.filter((t) => t)
      )
    }
  }
  return process
}

export const getTicketOrProcessStats = (
  process: RopaProcessV2,
  isTicket?
): { questions: number; answers: number; status: TicketStatus } => {
  let questions = 0
  let answers = 0
  const topics = [...SYSTEM_TOPICS, 'dataFlow']
  topics.forEach((topicKey) => {
    if (process.questionnaire?.[topicKey]) {
      const { totalQuestions, totalQuestionsAnswered } = TotalQuestionsEvaluatorMapper[topicKey](
        topicKey === RopaSystemTopics.processDetails ? process : process.questionnaire[topicKey],
        isTicket !== undefined ? isTicket : true
      )
      questions += totalQuestions
      answers += totalQuestionsAnswered
    }
  })

  if (process.questionnaire?.customTopics?.length) {
    process.questionnaire.customTopics.forEach((topic) => {
      const { answers: cAnswers, questions: cQuestions } = getCustomQuestionsAndAnswersCount(
        topic.questions
      )
      questions += cQuestions
      answers += cAnswers
    })
  }

  if (answers < questions) return { status: TicketStatus.InProgress, questions, answers }
  if (answers === questions) return { status: TicketStatus.Submitted, questions, answers }

  return { status: TicketStatus.NotStarted, questions, answers }
}
