import service from '../../services/api/apiService'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import { IGetLocationsParams } from '../../interfaces'
import { Location } from '../../services/api/apiTypes'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface LocationsState {
  list: {
    list: Location[]
    total: number
    sort: SortParams
  }
}

export const initialState: LocationsState = {
  list: {
    list: [],
    total: 0,
    sort: defaultSortParams
  }
}

export const fetchLocationsList = createAsyncThunk(
  'locations/list',
  async (filters: IGetLocationsParams) => await service.getLocationsList(filters)
)

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setSort: (state, action) => {
      state.list.sort = getSortDirection(state.list.sort, action.payload.column)
    },
    clearList: (state) => {
      state.list = initialState.list
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLocationsList.fulfilled, (state, action) => {
      state.list.list = action.payload.list
      state.list.total = action.payload.total
    })
  }
})

export const { setSort, clearList } = locationsSlice.actions

export default locationsSlice.reducer
