import './print.less'
import PrintFinalPage from './components/pageFinal'
import React from 'react'

const PrintClosingPage = (): React.ReactElement => {
  return (
    <div>
      <PrintFinalPage />
    </div>
  )
}

export default PrintClosingPage
