import service from '../../services/api/apiService'
import {
  AnomaliesInfoItem,
  NamedTimeSeriesItem,
  TimestampRequestParams
} from '../../services/api/apiTypes'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

interface AnomaliesState {
  list: Array<AnomaliesInfoItem> | null
  chart: Array<NamedTimeSeriesItem> | null
  dateRange: TimestampRequestParams
  total: number
}

export const initialState: AnomaliesState = {
  dateRange: {
    endDate: dayjs().unix(),
    startDate: dayjs().subtract(1, 'week').unix()
  },
  list: null,
  chart: null,
  total: 0
}

export const fetchAnomaliesList = createAsyncThunk(
  'anomalies/fetchAnomaliesList',
  async () => await service.getAnomaliesList()
)

export const fetchAnomaliesChart = createAsyncThunk(
  'anomalies/fetchAnomaliesChart',
  async () => await service.getAnomaliesChart()
)

const anomaliesSlice = createSlice({
  name: 'anomalies',
  initialState,
  reducers: {
    setDateRange: (state, action) => {
      state.dateRange = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnomaliesList.fulfilled, (state, action) => {
      state.list = action.payload.list
      state.total = action.payload.total
    })
    builder.addCase(fetchAnomaliesList.rejected, (state) => {
      state.list = []
    })
    builder.addCase(fetchAnomaliesChart.fulfilled, (state, action) => {
      state.chart = action.payload
    })
    builder.addCase(fetchAnomaliesChart.rejected, (state) => {
      state.chart = []
    })
  }
})

export const { setDateRange } = anomaliesSlice.actions
export default anomaliesSlice.reducer
