import service from '../../services/api/apiService'
import { Violation } from '../../services/api/apiTypes'
import { IGetViolationsParams } from '../../interfaces'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface AuditTrailWidgetState {
  list: Violation[]
  total: number
  sort: SortParams
}

export const initialState: AuditTrailWidgetState = {
  list: [],
  total: 0,
  sort: defaultSortParams
}

export const fetchViolations = createAsyncThunk(
  'violations/list',
  async (filters: IGetViolationsParams) => await service.getViolations(filters)
)

const violationsSlice = createSlice({
  name: 'violations',
  initialState,
  reducers: {
    setSort: (state, action) => {
      state.sort = getSortDirection(state.sort, action.payload.column)
    },
    clearList: (state) => {
      state.list = initialState.list
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchViolations.fulfilled, (state, { payload }) => {
      state.list = Array.isArray(payload.list) ? payload.list : []
      state.total = payload.total
    })
  }
})

export const { setSort, clearList } = violationsSlice.actions

export default violationsSlice.reducer
