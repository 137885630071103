import './status.less'
import SvgIconStatus from '../../../../assets/inlineSvg/icon-status.svg'
import { ScanStatus } from '../../../../interfaces'
import { STRUCTURED_DATASOURCES } from '../../../../constants'
import { mapDataSourceGraphQlStatus } from '../../../../utils/dataSourceUtil'
import { Label } from 'semantic-ui-react'
import React from 'react'
import { ArrowsClockwise } from 'phosphor-react'
import classNames from 'classnames'
import { Typography } from '@lightbeamai/design-system'

export const statusMapper = {
  [ScanStatus.connected]: 'Connected',
  [ScanStatus.paused]: 'Connected',
  [ScanStatus.pausing]: 'Connected',
  [ScanStatus.resuming]: 'Connected',
  [ScanStatus.registering]: 'Registering',
  [ScanStatus.disconnected]: 'Disconnected',
  [ScanStatus.deleting]: 'Disconnected'
}

interface IProps {
  status?: string
  dataSourceType?: string
  className?: string
}
const StatusLabel = ({
  status = ScanStatus.registering,
  dataSourceType,
  className = ''
}: IProps) => {
  const statusLowercase = status.toLowerCase() as ScanStatus
  const isStructuredDataSource = STRUCTURED_DATASOURCES.find(
    (structuredType) => structuredType === dataSourceType
  )

  if (isStructuredDataSource) {
    const statusTranslated = mapDataSourceGraphQlStatus[status]
    return (
      <div
        className={classNames('ds-status-label structured', {
          [className]: !!className,
          [statusLowercase]: true
        })}
      >
        {statusTranslated !== ScanStatus.completed && (
          <div className="icon-box">
            <ArrowsClockwise size={14} color="var(--color-primary-light)" />
          </div>
        )}
        <Typography variant="baseMinus2" color="custom">
          {statusTranslated}
        </Typography>
      </div>
    )
  } else {
    return status ? (
      <Label
        className={classNames(`ds-status-label xs-px-8 ${statusLowercase}`, {
          [className]: !!className
        })}
      >
        <SvgIconStatus />
        <Typography variant="baseMinus2" color="custom">
          {statusMapper[statusLowercase]}
        </Typography>
      </Label>
    ) : (
      <></>
    )
  }
}

export default StatusLabel
