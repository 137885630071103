import {
  mapQueryAttributeInstancesByProject,
  mapQueryProjectCards,
  mapQueryProjectsList,
  mapQueryProjectSummary,
  queryAttributeInstancesByProject,
  queryProjectCards,
  queryProjectsList,
  queryProjectSummary
} from './queries'
import service from '../../services/api/apiService'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import { FilterParams } from '../../interfaces'
import graphqlService from '../../services/graphqlService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export type Project = {
  projectId?: string
  projectName: string
  totalAttributes: number
  totalEntities: number
  totalObjects?: number
  totalRiskyObjects?: number
  totalProjects?: number
  objectId?: string
  objectName?: string
  fileType: string
  scanTime: string
  attributes?: {
    name: string
    internalName: string
    instanceCount: number
  }[]
}

export interface FetchProjectsListParams {
  entityId?: string
  datasourceId?: string
  filters?: FilterParams
  page?: number
}

export const ACTION_PROJECTS_CARDS_FETCH = 'projects/cards'
export const fetchProjectCards = createAsyncThunk(
  ACTION_PROJECTS_CARDS_FETCH,
  async (params: FetchProjectsListParams) => {
    const result = await graphqlService.execute(queryProjectCards(params))
    return mapQueryProjectCards(result)
  }
)

export const ACTION_PROJECTS_LIST_FETCH = 'projects/list'
export const fetchProjectsList = createAsyncThunk(
  ACTION_PROJECTS_LIST_FETCH,
  async (params: FetchProjectsListParams) => {
    const result = await graphqlService.execute(queryProjectsList(params))
    return mapQueryProjectsList(result)
  }
)

export interface FetchAttributeInstancesByProjectParams {
  entityId: string
  datasourceId: string
}
export const ACTION_PROJECTS_ATTRIBUTE_INSTANCES_FETCH = 'projects/attribute/instances'
export const fetchAttributeInstancesByProject = createAsyncThunk(
  ACTION_PROJECTS_ATTRIBUTE_INSTANCES_FETCH,
  async (params: FetchAttributeInstancesByProjectParams) => {
    const result = await graphqlService.execute(queryAttributeInstancesByProject(params))
    return mapQueryAttributeInstancesByProject(result)
  }
)

export interface FetchProjectSummaryParams {
  projectId: string
}
export const ACTION_PROJECT_SUMMARY_FETCH = 'projects/summary'
export const fetchProjectSummary = createAsyncThunk(
  ACTION_PROJECT_SUMMARY_FETCH,
  async (params: FetchProjectSummaryParams) => {
    const result = await graphqlService.execute(queryProjectSummary(params))
    return mapQueryProjectSummary(result)
  }
)

export const ACTION_PROJECT_DELETE = 'projects/delete'
export const deleteProjectById = createAsyncThunk(
  ACTION_PROJECT_DELETE,
  async (id: string, { rejectWithValue }) => {
    try {
      await service.deleteProjectById(id)
      return
    } catch (error) {
      return rejectWithValue({ statusMessage: 'project.delete.error' })
    }
  }
)

export type ProjectsListSettings = {
  list?: Project[]
  sort: SortParams
}

export interface ProjectAttributeInstanceInfo {
  name: string
  id: string
  count: number
}

export interface ProjectToDelete {
  name: string | undefined
  id: string
}

interface ProjectsState {
  cards?: Project[]
  attributeInstancesByProject?: ProjectAttributeInstanceInfo[]
  objects: ProjectsListSettings
  totalProjects?: number
  totalObjects?: number
  riskyObjects?: number
  summaryData?: Project[]
  projectToDelete: ProjectToDelete | null
}
const initialProjectsList: ProjectsListSettings = { sort: defaultSortParams }
export const initialState: ProjectsState = {
  objects: initialProjectsList,
  projectToDelete: null
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setSort: (state, { payload }) => {
      state.objects.sort = getSortDirection(state.objects.sort, payload.column)
    },
    resetTopProjectsWidget: (state) => {
      state.attributeInstancesByProject = initialState.attributeInstancesByProject
    },
    setProjectToDelete: (state, action) => {
      state.projectToDelete = action.payload
    },
    resetProjectToDelete: (state) => {
      state.projectToDelete = initialState.projectToDelete
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectCards.fulfilled, (state, action) => {
      state.cards = action.payload.list
      state.totalProjects = action.payload.totalProjects
      state.totalObjects = action.payload.totalObjects
    })
    builder.addCase(fetchProjectsList.fulfilled, (state, action) => {
      state.objects.list = action.payload.list
      state.totalObjects = action.payload.totalObjects
      state.totalProjects = action.payload.totalProjects
    })
    builder.addCase(fetchAttributeInstancesByProject.fulfilled, (state, action) => {
      state.attributeInstancesByProject = action.payload.list
    })
    builder.addCase(fetchProjectSummary.fulfilled, (state, action) => {
      state.summaryData = action.payload.summary
    })
    builder.addCase(deleteProjectById.fulfilled, (state) => {
      state.projectToDelete = initialState.projectToDelete
    })
    builder.addCase(deleteProjectById.rejected, (state) => {
      state.projectToDelete = initialState.projectToDelete
    })
  }
})

export const {
  setSort,
  setProjectToDelete,
  resetProjectToDelete,
  resetTopProjectsWidget
} = projectsSlice.actions

export default projectsSlice.reducer
