import {
  Consent,
  ConsentCategoryListParams,
  ConsentCategoryPayload,
  ConsentManagementLog,
  ConsentManagementLogsParams,
  SubscriptionPayload,
  SystemLog
} from './consentManagementSlice'
import { LIGHTBEAM_CONSENT_SOURCE } from './constants'
import { DATA_SOURCE_TYPES, LIMIT_DEFAULT } from '../../constants'
import {
  getAfterCursor,
  parameterizeArrayofObjects,
  stringifyParams
} from '../../utils/graphqlUtil'
import { gql } from 'graphql-request'
import { uniq } from 'underscore'

const mapConsentLogBaseEntry = (edge) => {
  const node = edge.node
  const entityEdge = node.entity?.edges[0]?.node
  return {
    id: node.id,
    email: node.communicationTypeValue,
    name: entityEdge?.name[0],
    entityId: entityEdge?.id,
    createdAt: node.consentInfo?.timestamp,
    location: node.responseMetadata?.location,
    ipAddress: node.responseMetadata?.ipAddress,
    type: entityEdge?.type.edges[0]?.node.name,
    createdBy: node.createdBy,
    consentSource:
      node.createdBy === LIGHTBEAM_CONSENT_SOURCE
        ? node.preferenceCenter?.edges[0]?.node
        : edge.node.datasource?.edges[0]?.node,
    comment: '',
    consents: [] as Consent[]
  }
}

const mapConsentLogs = (edge: any): ConsentManagementLog => {
  try {
    const consentHistoryNode = edge.node.consentHistory
    const consentHistoryBaseNode = mapConsentLogBaseEntry(consentHistoryNode.edges[0])
    consentHistoryNode.edges.forEach(({ node }) => {
      const createdBy = node.createdBy
      let consentInfo
      if (createdBy === LIGHTBEAM_CONSENT_SOURCE) {
        const consentInfoNode = node.preferenceCategory.edges[0]?.node
        consentInfo = {
          name: consentInfoNode.name,
          description: consentInfoNode.displayDescription,
          id: consentInfoNode.id
        }
      } else if (createdBy === DATA_SOURCE_TYPES.hubspot) {
        const consentInfoNode = node.preferences.edges[0]?.node
        if (consentInfoNode && consentInfoNode.configuration) {
          consentInfo = {
            name: consentInfoNode.configuration.name,
            description: consentInfoNode.configuration.description
          }
        }
      }
      consentHistoryBaseNode.comment ||= node.comment
      consentHistoryBaseNode.consents.push({
        syncStatus: node.syncStatus,
        consentEvent: node.consentInfo.change.toLowerCase(),
        consentInfo
      })
    })
    return consentHistoryBaseNode
  } catch (error) {
    console.error(error)
    throw error
  }
}

const mapSystemLogs = (rawResult: any) => {
  return rawResult.consentHistory.edges.map((edge: any) => {
    const node = edge.node
    const entityEdge = node.entity?.edges[0]?.node
    return {
      id: node.id,
      email: node.communicationTypeValue,
      name: entityEdge?.name[0],
      entityId: entityEdge?.id,
      createdAt: node.consentInfo?.timestamp,
      location: node.responseMetadata?.location,
      ipAddress: node.responseMetadata?.ipAddress,
      syncStatus: node.syncStatus,
      consentSource: edge.node.datasource.edges[0]?.node.name,
      consentEvent: node.consentInfo?.change.toLowerCase(),
      type: entityEdge?.type.edges[0]?.node.name,
      consentInfo: node.preferences.edges.map((edge: any) => {
        const node = edge.node
        return {
          name: node.configuration.name,
          description: node.configuration.description,
          ...(node.id ? { id: node.id } : {})
        }
      })
    }
  })
}

export const mapQuerySystemLogs = (rawResult: any): { list: SystemLog[]; count: number } => ({
  count: rawResult.consentHistory.count,
  list: mapSystemLogs(rawResult)
})

export const mapQueryConsentManagementLogs = (
  rawResult: any
): { list: ConsentManagementLog[]; count: number } => {
  const mainNode = rawResult.consentHistoryGroupByRequestId
  const consentLogs = mainNode.edges || []
  return { count: mainNode.count, list: consentLogs.map(mapConsentLogs) }
}

const consentHistoryDetailedFragment = ({ cursorParam, filterParams, dateFilter }) => `
  consentHistory(sortByAsc: false, sortField: CREATED_AT, first: ${LIMIT_DEFAULT} ${cursorParam} ${dateFilter} ${filterParams}) {
    count
    edges {
      node {
        id
        communicationTypeValue
        createdBy
        type
        syncStatus
        comment
        responseMetadata {
          location
          ipAddress
        }
        consentInfo {
          ... on HubspotConsentInfo {
            timestamp
            change
          }
        }
        entity {
          edges {
            node {
              id
              name
              type {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        datasource {
          edges {
            node {
              id
              name
            }
          }
        }
        preferenceCenter {
          edges {
            node {
              id
              name
            }
          }
        }
        preferenceCategory {
          edges {
            node {
              id
              name
            }
          }
        }
        preferences {
          edges {
            node {
              configuration {
                ... on HubspotCommunicationPreference {
                  name
                  description
                }
              }
            }
          }
        }
      }
    }
  }
`

function parseConsentLogsParams(params: ConsentManagementLogsParams) {
  const cursor = getAfterCursor(params.page || 1, LIMIT_DEFAULT)
  const cursorParam = cursor ? `, after: "${cursor}"` : ''
  const filtersParameterized = parameterizeArrayofObjects(params.filters || [])
  const filterParams = filtersParameterized ? `, filter:${filtersParameterized}` : ''
  let dateFilter = ''
  if (params.start && params.end) {
    const start = `"${params.start}"`
    const end = `"${params.end}"`
    const key = 'TIMESTAMP'
    dateFilter = `dateFilter: {${stringifyParams({ start, end, key })}}`
  }
  return { cursorParam, filterParams, dateFilter }
}

export const queryConsentMangementLogs = (params: ConsentManagementLogsParams) => {
  const { cursorParam, filterParams, dateFilter } = parseConsentLogsParams(params)
  return gql`
    query consentLogs {
      consentHistoryGroupByRequestId(sortByAsc: false, sortField: TIMESTAMP, first: ${LIMIT_DEFAULT} ${cursorParam} ${dateFilter} ${filterParams}){
        count
        edges {
          node {
            requestId
            ${consentHistoryDetailedFragment({ cursorParam: '', filterParams: '', dateFilter: '' })}
          }
        }
      }
    }
  `
}

export const querySystemLogs = (params: ConsentManagementLogsParams) => {
  const { cursorParam, filterParams, dateFilter } = parseConsentLogsParams(params)
  return gql`
    query consentLogs{
      ${consentHistoryDetailedFragment({ cursorParam, filterParams, dateFilter })}
    }
  `
}

export const mapQueryConsentManagementLogsByUser = (rawResult: any) => {
  const consentHistoryNode = rawResult.userEntities.edges[0].node
  return mapQueryConsentManagementLogs(consentHistoryNode)
}

export const queryConsentLogsForUser = (params: ConsentManagementLogsParams) => {
  const { entityId, ...consentLogParams } = params
  const { cursorParam, filterParams, dateFilter } = parseConsentLogsParams(consentLogParams)
  return gql`
    query consentHistoryForUserEntity {
      userEntities(entityIds: ["${entityId}"]) {
        edges {
          node {
            consentHistoryGroupByRequestId(sortByAsc: false, sortField: CREATED_AT, first: ${LIMIT_DEFAULT} ${cursorParam} ${dateFilter} ${filterParams}){
              count
              edges {
                node {
                  requestId
                  ${consentHistoryDetailedFragment({
                    cursorParam: '',
                    filterParams: '',
                    dateFilter: ''
                  })}
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryConsentManagementLogsByUserSummary = (rawResult: any) => {
  const count = rawResult.latestConsentHistoryByEntity.count
  const list = rawResult.latestConsentHistoryByEntity.edges.map((edge) => {
    const node = edge.node
    const base: ConsentManagementLog = {
      id: node.id,
      createdAt: node.consentInfo.timestamp,
      consents: [
        {
          consentEvent: node.consentInfo.change.toLowerCase(),
          consentInfo: node.preferences.edges[0]?.node
        }
      ]
    }
    if (node.createdBy === LIGHTBEAM_CONSENT_SOURCE) {
      base.consentSource = node.preferenceCenter.edges[0]?.node
    }
    if (node.createdBy === DATA_SOURCE_TYPES.hubspot) {
      base.consentSource = node.datasource.edges[0]?.node
    }
    return base as ConsentManagementLog
  })
  return { list, count }
}

export const queryConsentLogsForUserSummary = (entityId: string) => {
  return gql`
    {
      latestConsentHistoryByEntity(entityId: "${entityId}") {
        count
        edges {
          node {
            id
            communicationTypeValue
            createdBy
            consentInfo {
              ... on HubspotConsentInfo {
                change
                timestamp
              }
            }
            preferenceCenter {
              edges {
                node {
                  id
                  name
                  preferenceCenterGroupId
                }
              }
            }
            datasource {
              edges {
                node {
                  id
                  name
                }
              }
            }
            preferences {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryConsentSourceByDatasource = (rawResult: any) => {
  return (
    rawResult.consentManagementSettings.edges
      .map((edge) => edge.node.datasource.edges[0]?.node)
      .filter(Boolean) || []
  )
}

export const queryConsentSourceByDatasource = gql`
  {
    consentManagementSettings {
      edges {
        node {
          datasource {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const mapQueryConsentSourceByPreferenceCenter = (rawResult: any) => {
  return rawResult.preferenceCenter.edges
    .map((edge) => edge.node)
    .map((item) => ({ name: item.name, id: item.preferenceCenterGroupId }))
}

export const queryConsentSourceByPreferenceCenter = gql`
  {
    preferenceCenter {
      edges {
        node {
          name
          preferenceCenterGroupId
        }
      }
    }
  }
`

export const mapQueryEntityTypes = (rawResult: any) => {
  const entityTypeNode = rawResult.consentHistoryGroupByEntityType.edges[0]?.node
  if (!entityTypeNode || entityTypeNode?.consentHistory.count === 0) return []
  return entityTypeNode.entityType.edges.map((edge) => edge.node) || []
}

export const queryEntityTypes = gql`
  query entityType {
    consentHistoryGroupByEntityType {
      edges {
        node {
          entityType {
            edges {
              node {
                id
                name
              }
            }
          }
          consentHistory {
            count
          }
        }
      }
    }
  }
`

export const mapQueryLocations = (rawResult: any) => {
  return (
    rawResult.consentHistoryGroupByLocation.edges
      .map((edge: any) => edge.node.location)
      .filter(Boolean) || []
  )
}

export const queryLocations = gql`
  {
    consentHistoryGroupByLocation {
      edges {
        node {
          location
        }
      }
    }
  }
`

export const mapQuerySystemLogsCount = (key, rawResult) => {
  return {
    [key]: rawResult.consentHistory.count
  }
}

export const querySystemLogsCount = (params) => {
  const filtersParameterized = parameterizeArrayofObjects(params.filters || [])
  let filters = ''
  if (filtersParameterized) {
    filters = `(filter:${filtersParameterized})`
  }
  return gql`
    {
      consentHistory ${filters} {
        count
      }
    }
  `
}

export const mapQueryConsentCategories = (rawResult: any) => {
  return rawResult.preferenceCategory.edges.map((edge) => edge.node) || []
}

export const queryConsentCategories = gql`
  {
    preferenceCategory {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const mutateCreateConsentCategory = (params: ConsentCategoryPayload) => {
  return gql`
    mutation {
      createPreferenceCategory(
        clientMutationId: "create-category"
        preferenceCategoryData: {
          name: "${params.name}"
          owner: "${params.currentUserEmail}"
          displayTitle: "${params.displayTitle}"
          displayDescription: "${encodeURIComponent(params.displayDescription)}"
        }
      ) {
        clientMutationId
        preferenceCategoryId
      }
    }
  `
}

export const mutateUpdateConsentCategory = (params: ConsentCategoryPayload) => {
  return gql`
    mutation {
      updatePreferenceCategory(
        clientMutationId: "update-category"
        preferenceCategoryId: "${params.id}"
        preferenceCategoryData: {
          owner: "${params.currentUserEmail}"
          name: "${params.name}"
          displayTitle: "${params.displayTitle}"
          displayDescription: "${encodeURIComponent(params.displayDescription)}"
        }
      ) {
        clientMutationId
        preferenceCategoryId
      }
    }`
}

export const mutateDeleteConsentCategory = (categoryId: string) => {
  return gql`
    mutation {
      deletePreferenceCategory(
        clientMutationId: "delete-category"
        preferenceCategoryId: "${categoryId}"
      ) {
        clientMutationId
      }
    }
  `
}

export const mapFetchCategory = (rawResult: any) => {
  const consentCategory = rawResult.preferenceCategory?.edges[0]?.node
  if (consentCategory.displayDescription) {
    consentCategory.displayDescription = decodeURIComponent(consentCategory.displayDescription)
  }
  return rawResult.preferenceCategory?.edges[0]?.node
}

export const queryFetchCategory = (categoryId: string) => {
  return gql`
    query fetchPreferenceCategory {
      preferenceCategory(id: "${categoryId}") {
        edges{
          node{
            id
            name
            displayTitle
            displayDescription
          }
        }
      }
    }
  `
}

export const mapFetchConsentCategoriesList = (rawResult: any) => {
  const preferenceCategory = rawResult.preferenceCategory
  return {
    count: preferenceCategory.count,
    list: preferenceCategory.edges.map((edge) => {
      const { preferenceCenter, ...consentCategoryNode } = edge.node
      consentCategoryNode.preferenceCenter = preferenceCenter.edges.map((edge) => edge.node)
      consentCategoryNode.displayDescription = decodeURIComponent(
        consentCategoryNode.displayDescription
      )
      return consentCategoryNode
    })
  }
}

export const queryConsentCategoriesList = (params: ConsentCategoryListParams) => {
  const cursor = getAfterCursor(params.page || 1, LIMIT_DEFAULT)
  const cursorParam = cursor ? `, after: "${cursor}"` : ''
  const filtersParameterized = parameterizeArrayofObjects(params.filters || [])
  const filterParams = filtersParameterized ? `, filter:${filtersParameterized}` : ''
  return gql`
    query fetchPreferenceCategory {
      preferenceCategory(sortField: UPDATED_AT, sortByAsc: false ${cursorParam} ${filterParams}) {
        count
        edges {
          node {
            id
            name
            displayTitle
            displayDescription
            createdBy
            preferenceCenter {
              count
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapFetchPreferenceCenterForConsentCategories = (rawResult: any) => {
  const preferenceCenters = rawResult.preferenceCategory.edges
    .map((item) => item.node.preferenceCenter.edges)
    .filter((item) => item.length)
    .flat(1)
    .map((item) => item.node)

  return uniq(preferenceCenters, (item) => item.preferenceCenterGroupId).map((item) => ({
    id: item.preferenceCenterGroupId,
    name: item.name
  }))
}

export const queryPreferenceCenterForConsentCategories = gql`
  query fetchPreferenceCenter {
    preferenceCategory {
      edges {
        node {
          preferenceCenter {
            edges {
              node {
                preferenceCenterGroupId
                name
              }
            }
          }
        }
      }
    }
  }
`

function getStringifiedCommunicationMethods(communicationMethods) {
  return communicationMethods
    .map((method) => {
      const currentMethod: string[] = []
      Object.keys(method).forEach((key) => {
        currentMethod.push(`${key}: "${method[key]}"`)
      })
      return `{${currentMethod.join(',')}}`
    })
    .join(',')
}

export const mutateCreateSubscription = (params: SubscriptionPayload) => {
  return gql`
    mutation {
      createPreferences(
        clientMutationId: "create-subscription"
        preferencesData: [
          {
            name: "${params.name}"
            datasourceId: "${params.datasourceId}"
            communicationMethods: [${getStringifiedCommunicationMethods(
              params.communicationMethods
            )}]
            description: "${params.description || ''}"
          }
        ]
      ) {
        clientMutationId
        preferenceIds
      }
    }
  `
}

export const mutateUpdateSubscription = (params: SubscriptionPayload) => {
  return gql`
    mutation {
      updatePreferences(
        clientMutationId: "update-subscription"
        preferencesData: [
          {
            id: "${params.id}"
            name: "${params.name}"
            communicationMethods: [${getStringifiedCommunicationMethods(
              params.communicationMethods
            )}]
            datasourceId: "${params.datasourceId}"
            description: "${params.description || ''}"
          }
        ]
      ) {
        clientMutationId
        preferenceIds
      }
    }
  `
}

export const mutationDeleteSubscription = (subscriptionId: string) => {
  return gql`
    mutation{
      deletePreferences(
        clientMutationId:"delete-subscription"
        preferencesIds:"${subscriptionId}"
      ){
        clientMutationId
        preferenceIds
      }
    }
  `
}

export const mapQueryModesOfCommunication = (rawResult: any) => {
  return rawResult.consentHistoryGroupByCommunicationType.edges.map(
    (edge: any) => edge.node.communicationType
  )
}

export const queryModesOfCommunication = gql`
  {
    consentHistoryGroupByCommunicationType {
      edges {
        node {
          communicationType
        }
      }
    }
  }
`

export const mapQueryCreatedByDatasources = (rawResult: any) => {
  return rawResult.consentManagementSettings.edges[0].node.datasourceTypes
}

export const queryCreatedByDatasources = gql`
  {
    consentManagementSettings {
      edges {
        node {
          datasourceTypes
        }
      }
    }
  }
`
