import './annotationFeedback.less'
import { ACTION_ANNOTATION_FEEDBACK, sendFeedback } from './annotationFeedbackSlice'
import ModalTemplate from '../../../modals/components/modalTemplate'
import { RootState } from '../../../../rootReducer'
import { IModal, MODALS, resetModals } from '../../../modals/modalsSlice'
import MultilineInput from '../../../../components/MultiLineInput/multiLineInput'
import { addNotification, INotificationParams } from '../../../notification/notificationSlice'
import { AnnotationFeedbackParams, WrongAnnotationItems } from '../../../../services/api/apiTypes'
import { STATES } from '../../../../interfaces'
import { getAction, RequestState, resetAction } from '../../../../reducers/requestReducer'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Checkbox, CheckboxProps } from 'semantic-ui-react'
import { Typography } from '@lightbeamai/design-system'

interface IProps {
  documentId?: string
  openedModal?: IModal
  feedbackAction?: RequestState
  sendFeedback: (params: { documentId: string; data: AnnotationFeedbackParams }) => void
  resetModals: () => void
  addNotification: (notification: INotificationParams) => void
  resetAction: (actionName: string) => void
}

const ModalAnnotationFeedback = (props: IProps): React.ReactElement => {
  const {
    documentId = '',
    openedModal,
    feedbackAction,
    sendFeedback,
    resetModals,
    resetAction,
    addNotification
  } = props
  const { error = '', success = '' } = feedbackAction || {}

  const intl = useIntl()

  const [remarks, setRemarks] = useState('')
  const [checkboxes, setCheckboxes] = useState<WrongAnnotationItems[] | string[]>([])

  useEffect(() => {
    if (feedbackAction?.state === STATES.success || feedbackAction?.state === STATES.failed) {
      resetModals()
      setCheckboxes([])
      setRemarks('')
      addNotification({ error, success })
      resetAction(ACTION_ANNOTATION_FEEDBACK)
    }
  }, [error, success, feedbackAction?.state, resetAction, resetModals, addNotification])

  const handleSubmit = () => {
    if (checkboxes.length > 0) {
      const wrongAnnotationItems = checkboxes as WrongAnnotationItems[]
      sendFeedback({ documentId, data: { remarks, wrongAnnotationItems } })
    }
  }
  const handleClose = () => resetModals()
  const handleCheckboxChange = (_, { name = '', checked }: CheckboxProps) => {
    setCheckboxes((prev) => {
      return checked ? [...prev, ...[name]] : prev.filter((value) => value !== name)
    })
  }

  return (
    <ModalTemplate
      title={<FormattedMessage id="annotationFeedback.title" />}
      isOpened={openedModal?.name === MODALS.annotationFeedback}
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitText={<FormattedMessage id="annotationFeedback.submit" />}
      size="large"
      isSubmitDisabled={checkboxes.length === 0}
    >
      <div className="annotation-feedback-container" data-test-id="annotator-annotation-feedback">
        <div className="section checkbox">
          <Typography as="div" variant="basePlus2" className="title show-required">
            <FormattedMessage id="annotationFeedback.checkbox.title" />
          </Typography>
          <div className="checkbox-container">
            <Checkbox
              onChange={handleCheckboxChange}
              name={WrongAnnotationItems.classification}
              label={intl.formatMessage({ id: 'annotationFeedback.checkbox.classification' })}
            />
            <Checkbox
              onChange={handleCheckboxChange}
              name={WrongAnnotationItems.subClassification}
              label={intl.formatMessage({ id: 'annotationFeedback.checkbox.subClassification' })}
            />
            <Checkbox
              onChange={handleCheckboxChange}
              name={WrongAnnotationItems.entityIncorrect}
              label={intl.formatMessage({ id: 'annotationFeedback.checkbox.entityIncorrect' })}
            />
            <Checkbox
              onChange={handleCheckboxChange}
              name={WrongAnnotationItems.entityMissing}
              label={intl.formatMessage({ id: 'annotationFeedback.checkbox.entityMissing' })}
            />
            <Checkbox
              onChange={handleCheckboxChange}
              name={WrongAnnotationItems.identifierIncorrect}
              label={intl.formatMessage({ id: 'annotationFeedback.checkbox.identifierIncorrect' })}
            />
            <Checkbox
              onChange={handleCheckboxChange}
              name={WrongAnnotationItems.identiferMissing}
              label={intl.formatMessage({ id: 'annotationFeedback.checkbox.identifierMissing' })}
            />
          </div>
        </div>

        <div className="section textarea">
          <Typography as="div" variant="basePlus2" className="title font-600">
            <FormattedMessage id="annotationFeedback.textarea.title" />
          </Typography>
          <MultilineInput value={remarks} onChange={setRemarks} />
          <Typography as="div" variant="baseMinus2" className="note">
            <FormattedMessage id="annotationFeedback.textarea.note" />
          </Typography>
        </div>
      </div>
    </ModalTemplate>
  )
}

const mapStateToProps = (state: RootState) => ({
  documentId: state.document.selectedDocument?.id,
  openedModal: state.modals.openedModal,
  feedbackAction: getAction(ACTION_ANNOTATION_FEEDBACK)
})

const mapDispatchToProps = { resetModals, sendFeedback, addNotification, resetAction }

export default connect(mapStateToProps, mapDispatchToProps)(ModalAnnotationFeedback)
