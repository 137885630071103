export enum ConsentOptStatus {
  Subscribed = 'SUBSCRIBED',
  UnSubscribed = 'UNSUBSCRIBED'
}

export enum ConsentTypes {
  optIn = 'Opt-In',
  optOut = 'Opt-Out'
}

export enum SystemLogsStatus {
  success = 'success',
  failed = 'failed',
  all = 'all'
}

export const UNKNOWN = 'Unknown'
export const REMOVE = 'REMOVE'

export enum EntityIdentifyStatus {
  Identified = 'Identified',
  Unidentified = 'Unidentified'
}

export enum SystemAlertQueryStatus {
  status = 'Consent Management Sync Failed'
}

export enum ConsentManagementSettingsTab {
  DATASOURCE = 'Data Sources',
  EMAIL_TEMPLATES = 'Email Templates'
}

export enum SettingsDatasourceTabkeys {
  Consents = 'consents',
  Alerts = 'alerts',
  Subscription = 'subscriptions',
  LinkedPreferenceCenter = 'linked-Preference-centers'
}

export enum SettingsEmailTemplateEventType {
  emailVerification = 'CM_EMAIL_VERIFICATION',
  consentRenewal = 'CM_CONSENT_RENEWAL'
}

export const CMSettingsEmailTemplateEventTypeValues = [
  'CM_EMAIL_VERIFICATION',
  'CM_CONSENT_RENEWAL'
]

export enum SettingsEmailTemplateRequestType {
  ConsentManagement = 'CONSENT_MANAGEMENT',
  ConsentManagementLowercase = 'consentManagement'
}

export enum ConsentLogView {
  ConsentLogs = 'consentLogs',
  EntityLogs = 'entityLogs',
  EntityLogsWidget = 'entityLogsWidget',
  DataSourceLogs = 'dataSourceLogs',
  PrefenceCenter = 'preferenceCenter'
}

export const pageTitleMap = {
  [ConsentLogView.ConsentLogs]: 'consentManagement.consentLogs',
  [ConsentLogView.EntityLogs]: '',
  [ConsentLogView.DataSourceLogs]: '',
  [ConsentLogView.PrefenceCenter]: ''
}

const getCount = (count) => `(${count})`

const systemLogFilterLabel = ({ count, label }: { count?: number; label: string }) =>
  `${label} ${count === undefined ? '' : getCount(count)}`

// TODO: remove dependency of systemLogFilterLabelMap
export const systemLogFilterLabelMap = {
  [SystemLogsStatus.all]: systemLogFilterLabel,
  [SystemLogsStatus.success]: systemLogFilterLabel,
  [SystemLogsStatus.failed]: systemLogFilterLabel
}

export const LIGHTBEAM_CONSENT_SOURCE = 'LIGHTBEAM'

export enum languageKeyMap {
  en = 'en',
  fr = 'fr',
  es = 'es'
}

export const frDefaulteBodyValue =
  '<p style="text-align:center;"><span style="font-size: 24px;"><strong>Centre de préférences LightBeam</strong></span></p>\n<p style="text-align:center;">Votre vie privée est importante pour nous. Ici vous pouvez gérer<br>vos préférences de consentement sur la manière dont nous utilisons <br>vos données.</p>\n'
export const enDefaulteBodyValue =
  '<p style="text-align:center;"><span style="font-size: 24px;"><strong>LightBeam Preference Center</strong></span></p>\n<p style="text-align:center;">Your privacy is important to us. Here you can manage<br>your consent preferences on how we use your data.</p>\n'
export const esDefaultBodyValue =
  '<p style="text-align:center;"><span style="font-size: 24px;"><strong>Centro de preferencias LightBeam</strong></span></p>\n<p style="text-align:center;">Tu privacidad es importante para nosotros. Aquí puedes gestionar<br>tus preferencias de consentimiento sobre cómo utilizamos tus datos.</p>\n'

export const languageContentMapper = {
  [languageKeyMap.en]: enDefaulteBodyValue,
  [languageKeyMap.fr]: frDefaulteBodyValue,
  [languageKeyMap.es]: esDefaultBodyValue
}

export const showConsentLogsExportScreens = [
  ConsentLogView.ConsentLogs,
  ConsentLogView.DataSourceLogs
]
