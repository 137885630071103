import './loader.less'
import { Dimmer } from 'semantic-ui-react'
import React, { useState, useEffect } from 'react'
import { SemanticSIZES } from 'semantic-ui-react/dist/commonjs/generic'

interface IProps {
  showLoader: boolean
  size?: SemanticSIZES
  once?: boolean
  className?: string
}

const CustomLoader = (props: IProps): React.ReactElement => {
  const { showLoader = false, once = false, className = '' } = props
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [timerId, setTimerId] = useState(0)

  // TODO: find better approach to handle first load
  useEffect(() => {
    showLoader && setTimerId(window.setTimeout(() => setIsFirstLoad(false), 5000))
  }, [showLoader])

  useEffect(() => () => clearTimeout(timerId), [timerId])

  return (
    <Dimmer
      data-test-id="custom-loader"
      inverted
      active={once ? isFirstLoad && showLoader : showLoader}
      page={true}
      className={className}
    >
      <img src="/assets/images/pageLoader.gif" className="lb-loader" alt="Loading..." />
    </Dimmer>
  )
}

export default CustomLoader
