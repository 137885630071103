import {
  mapQueryAttributeInstanceValues,
  mapQueryHistoricalInsights,
  queryAttributeInstanceValues,
  queryHistoricalInsights
} from './queries'
import graphqlService from '../../services/graphqlService'
import { FilterParams } from '../../interfaces'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export type HistoricalInsightsParams = {
  startTimestamp: number
  endTimestamp: number
  filters?: FilterParams
}
export const ACTION_FETCH_HISTORICAL_INSIGHTS = 'ropa/fetch/historical-insights'
export const fetchHistoricalInsights = createAsyncThunk(
  ACTION_FETCH_HISTORICAL_INSIGHTS,
  async (params: HistoricalInsightsParams) => {
    const raw = await graphqlService.execute(queryHistoricalInsights(params))
    return mapQueryHistoricalInsights(raw)
    return mapQueryHistoricalInsights({
      historicalInsights: {
        edges: [
          {
            node: {
              lastSyncTime: '2022-12-20T16:12:18.683947',
              insights: [
                {
                  timeStamp: 1672048392,
                  datasource: {
                    edges: [
                      {
                        node: {
                          id: '63aa651cdb66a74619e29856',
                          name: 'Lightbeam Generic Datasource',
                          type: 'GENERIC'
                        }
                      }
                    ]
                  },
                  createdAttribute: {
                    count: 852
                  },
                  deletedAttribute: {
                    count: 4
                  },
                  attribute: {
                    count: 852
                  }
                },
                {
                  timeStamp: 1672134792,
                  datasource: {
                    edges: [
                      {
                        node: {
                          id: '63aaad78ec1307a1cfaeabf2',
                          name: 'Posgress',
                          type: 'POSTGRESQL'
                        }
                      }
                    ]
                  },
                  createdAttribute: {
                    count: 69
                  },
                  deletedAttribute: {
                    count: 20
                  },
                  attribute: {
                    count: 69
                  }
                },
                {
                  timeStamp: 1672221192,
                  datasource: {
                    edges: [
                      {
                        node: {
                          id: '63aab46c343313308504086b',
                          name: 'Test',
                          type: 'POSTGRESQL'
                        }
                      }
                    ]
                  },
                  createdAttribute: {
                    count: 100
                  },
                  deletedAttribute: {
                    count: 0
                  },
                  attribute: {
                    count: 100
                  }
                }
              ]
            }
          }
        ]
      }
    })
  }
)

export type AttributeInstanceValuesParams = {
  timestamp: number
  attributeId: string
  datasourceId: string
  page: number
}
export const ACTION_FETCH_INSTANCE_VALUES = 'ropa/fetch/instance-values'
export const fetchAttributeInstanceValues = createAsyncThunk(
  ACTION_FETCH_INSTANCE_VALUES,
  async (params: AttributeInstanceValuesParams) => {
    const raw = await graphqlService.execute(queryAttributeInstanceValues(params))
    return mapQueryAttributeInstanceValues(raw)
    return Promise.resolve({
      total: 3,
      list: [
        { id: '123', instanceValue: '123', instanceState: 'DELETED', objectName: 'obj-1' },
        { id: '234', instanceValue: '234', instanceState: '', objectName: 'obj-2' },
        { id: '456', instanceValue: '456', instanceState: 'CREATED', objectName: 'obj-3' }
      ]
    })
  }
)

export interface TimestampInsight {
  id: string
  name: string
  type: string
  createdAttributesCount: number
  deletedAttributesCount: number
  timestamp: number
}
export interface InstanceValue {
  instanceValue: string
  objectName: string
  instanceState: string
}
export interface ListInstanceValues {
  list: InstanceValue[]
  total: number
}
type TimeMachineState = {
  insights?: TimestampInsight[]
  instanceValues?: ListInstanceValues
}

export const initialState: TimeMachineState = {}

const timeMachineSlice = createSlice({
  name: 'timeMachine',
  initialState,
  reducers: {
    resetAttributeInstanceValues: (state) => {
      state.instanceValues = initialState.instanceValues
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHistoricalInsights.fulfilled, (state, { payload }) => {
      state.insights = payload
    })
    builder.addCase(fetchAttributeInstanceValues.fulfilled, (state, { payload }) => {
      state.instanceValues = payload
    })
  }
})

export const { resetAttributeInstanceValues } = timeMachineSlice.actions

export default timeMachineSlice.reducer
