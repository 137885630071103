import {
  mapQueryDatabases,
  mapQueryDatabasesByPiiTables,
  mutationSaveDatabaseOwners,
  queryDatabaseAlerts,
  queryDatabases,
  queryDatabasesByPiiTables,
  queryReviewDatabase
} from './queries'
import { mapDbStatsToConnectionStats } from './databaseUtils'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import { DATABASE_ID, DATA_SOURCE_ID, RISK_LEVELS } from '../../constants'
import graphqlService from '../../services/graphqlService'
import {
  ConnectionStats,
  CosmosPostgresConnectionStats,
  DataSource,
  GlueConnectionStats
} from '../../services/api/apiTypes'
import apiService from '../../services/api/apiService'
import { DownloadListParams, ScanStatus } from '../../interfaces'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export type Database = {
  databaseId: string
  databaseName: string
  status?: ScanStatus
  alertsCount: number
  objectsCount?: number
  attributesCount?: number
  entitiesCount?: number
  noScanCount?: number
  risk?: RISK_LEVELS
  columnsSensitiveCount: number
  schemasCount: number
  schemasCompletedCount: number
  tablesCount: number
  tablesCompletedCount: number
  columnsCount: number
  columnsCompletedCount: number
}

export type ConnectionStatsList = {
  list?: ConnectionStats
  total?: number
  sort: SortParams
  isRestricted: boolean
}

export type DatabasesList = {
  list?: Database[]
  total?: number
  sort: SortParams
}

export interface DatabasesListParams {
  [DATA_SOURCE_ID]: string
}

export interface DatabasesWidgetParams {
  [DATA_SOURCE_ID]: string
}

export const ACTION_DATABASES_LIST_FETCH = 'databases/list'
export const fetchDatabasesList = createAsyncThunk(
  ACTION_DATABASES_LIST_FETCH,
  async (params: DatabasesListParams) => {
    const resultRaw = await graphqlService.execute(queryDatabases(params))
    const { list, total } = mapQueryDatabases(resultRaw)
    const dbsWithAlerts = await Promise.all(
      list
        .filter((db) => db.databaseId)
        .map(async (db) => {
          const response = await graphqlService.execute(queryDatabaseAlerts(db.databaseId))
          return { ...db, alertsCount: response?.alert?.count || 0 }
        })
    )
    return { list: dbsWithAlerts, total }
  }
)

export type ConnectionStatsParams = DataSource | { id: string }
export const ACTION_DATABASES_STATS_LIST_FETCH = 'databases/listConnectionStats'
export const fetchDatabasesConnectionStatsList = createAsyncThunk(
  ACTION_DATABASES_STATS_LIST_FETCH,
  async (
    params: ConnectionStatsParams
  ): Promise<GlueConnectionStats | ConnectionStats | CosmosPostgresConnectionStats> => {
    return await apiService.postConnectionStats(params)
  }
)

export interface ReviewDatabaseParams {
  [DATABASE_ID]: string
}
export const ACTION_REVIEW_DATABASE = 'databases/markReviewed'
export const reviewDatabase = createAsyncThunk(
  ACTION_REVIEW_DATABASE,
  async (params: ReviewDatabaseParams) => {
    const response = await graphqlService.execute(queryReviewDatabase(params))
    return response
  }
)

/**TODO: change function name */
export const ACTION_SAVE_CSV = 'databases/downloadFile'
export const downloadFile = createAsyncThunk(
  ACTION_SAVE_CSV,
  async (params: DownloadListParams): Promise<{ data: string; fileName: string }> => {
    const { data, headers } = await apiService.downloadFile(params)
    const disposition = headers['content-disposition']
    let fileName = ''
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '')
      }
    }

    return { data, fileName }
  }
)

export type DatabaseByPiiTables = {
  databaseId: string
  database: string
  piiTablesCount: number
}

export type WidgetDatabaseByPiiTables = {
  datasourceId: string
  list: DatabaseByPiiTables[]
}
export type WidgetDatabaseByPiiTablesParams = {
  [DATA_SOURCE_ID]: string
}
export const ACTION_DATABASES_BY_PII_TABLES = 'databases/byPiiTables'
export const fetchDatabasesByPiiTables = createAsyncThunk(
  ACTION_DATABASES_BY_PII_TABLES,
  async (params: WidgetDatabaseByPiiTablesParams) => {
    const resultRaw = await graphqlService.execute(queryDatabasesByPiiTables(params))
    const list = mapQueryDatabasesByPiiTables(resultRaw)
    return { datasourceId: params[DATA_SOURCE_ID], list }
  }
)

// save database notification
export type DatabaseOwnersParams = {
  [DATABASE_ID]: string
  notificationEnabled: boolean
  notificationOwners: string[]
}
export const ACTION_DATABASE_SAVE_OWNERS = 'databases/saveOwners'
export const saveDatabaseOwners = createAsyncThunk(
  ACTION_DATABASE_SAVE_OWNERS,
  async (params: DatabaseOwnersParams) => {
    return await graphqlService.execute(mutationSaveDatabaseOwners(params))
  }
)

const initialDatabasesList: DatabasesList = {
  sort: defaultSortParams
}
const initialConnectionStatsList: ConnectionStatsList = {
  sort: defaultSortParams,
  isRestricted: false
}

type DatabasesState = {
  all: DatabasesList
  sensitive: DatabasesList
  risk: DatabasesList
  connectionStats: ConnectionStatsList
  workgroups?: string[]
  downloadFileResult?: { data: string; fileName: string }
  widgetDatabasesByPiiTables: WidgetDatabaseByPiiTables[]
  databaseName?: string
}

export const initialState: DatabasesState = {
  all: initialDatabasesList,
  sensitive: initialDatabasesList,
  risk: initialDatabasesList,
  connectionStats: initialConnectionStatsList,
  widgetDatabasesByPiiTables: [],
  workgroups: []
}

const databasesSlice = createSlice({
  name: 'databases',
  initialState,
  reducers: {
    setSort: (state, { payload }) => {
      state[payload.list].sort = getSortDirection(state[payload.list].sort, payload.column)
    },
    resetLists: (state) => {
      state.all = initialState.all
      state.sensitive = initialState.sensitive
      state.risk = initialState.risk
    },
    resetConnectionStatsList: (state) => {
      state.connectionStats = initialState.connectionStats
    },
    resetDownloadFileData: (state) => {
      state.downloadFileResult = undefined
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDatabasesList.fulfilled, (state, { payload }) => {
      const { list, total } = payload

      state.all.list = list
      state.all.total = total

      state.sensitive.list = list.filter((db) => db.columnsSensitiveCount > 0)
      state.sensitive.total = state.sensitive.list.length

      state.risk.list = list.filter((db) => db.risk !== RISK_LEVELS.noRisk)
      state.risk.total = state.risk.list.length
    })
    builder.addCase(fetchDatabasesConnectionStatsList.fulfilled, (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.connectionStats.list = payload
        state.connectionStats.total = payload.length
      } else if (payload.workgroup) {
        // special handling for glue ds
        state.connectionStats.list = mapDbStatsToConnectionStats(payload.database)
        state.workgroups = payload.workgroup
        state.connectionStats.total = payload.database.length
      } else {
        // special handling for cosmos postgres
        const databaseName = Object.keys(payload)?.[0]
        state.databaseName = databaseName
        state.connectionStats.list = mapDbStatsToConnectionStats(payload?.[databaseName])
        state.connectionStats.total = payload?.[databaseName]?.length
      }
    })
    builder.addCase(fetchDatabasesConnectionStatsList.rejected, (state) => {
      state.connectionStats.isRestricted = true
    })
    builder.addCase(downloadFile.fulfilled, (state, { payload }) => {
      state.downloadFileResult = payload
    })
    builder.addCase(fetchDatabasesByPiiTables.fulfilled, (state, { payload }) => {
      state.widgetDatabasesByPiiTables.push(payload)
    })
  }
})

export const {
  setSort,
  resetLists,
  resetConnectionStatsList,
  resetDownloadFileData
} = databasesSlice.actions

export default databasesSlice.reducer
