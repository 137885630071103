import { Bucket, IFetchParams } from './bucketsSlice'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { LIMIT_DEFAULT } from '../../constants'
import { gql } from 'graphql-request'

export const queryBucketsList = ({
  page = 1,
  filters,
  pageSize = LIMIT_DEFAULT
}: IFetchParams): string => {
  const filter = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const filterString = filter ? `, filter:${filter}` : ''
  const filterFlag =
    filters && filters.booleanFilter ? parameterizeArrayofObjects(filters.booleanFilter) : ''

  const booleanFilterString = filterFlag ? `, booleanFilter:${filterFlag}` : ''
  const cursor = getAfterCursor(page, pageSize)
  return gql`
    {
      bucket(first: ${pageSize}, after: "${cursor}" ${filterString}, ${booleanFilterString}) {
        count
        edges {
          node {
            name
            type
            displayName
            storageAccountName
            objects {
              count
            }
            impactedObjects: objects(booleanFilter: [{key: IS_RISKY, value: true}]) {
              count
            }
            size
            ownerName
            ownerId
            entities {
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryBucketsList = (raw: any): { list: Bucket[]; total: number } => {
  try {
    const list = raw.bucket.edges.map(({ node: bucket }) => ({
      id: bucket.name,
      bucketName: bucket.name,
      bucketAccess: bucket.type,
      objectsTotal: bucket.objects?.count | 0,
      impactedObjects: bucket.impactedObjects?.count | 0,
      entities: bucket.entities?.count | 0,
      bucketSize: bucket.size,
      bucketOwner: bucket.ownerName,
      displayName: bucket?.displayName || '',
      storageAccountName: bucket?.storageAccountName || ''
    }))

    const total = raw.bucket.count || 0
    return { list, total }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryBucketsCount = (datasourceId: string): string => {
  return gql`
  {
    bucket(filter: {key: DATASOURCE_IDS, values: [${JSON.stringify(datasourceId)}] }){
        count
      }
  }
  `
}

export const mapQueryBucketsCount = (raw: any): number => {
  try {
    return raw.bucket?.count || 0
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryImpactedObjectsCountByBucket = (params: IFetchParams) => {
  const { datasourceId, filters } = params
  let filtersQuery = ''
  const filterString = parameterizeArrayofObjects(filters?.filter || [])
  filterString ? (filtersQuery += `,filter:${filterString}`) : ''
  const filterFlag = parameterizeArrayofObjects(filters?.booleanFilter || [])
  filtersQuery += filterFlag ? `,booleanFilter:${filterFlag}` : ''
  return gql`
  {
    impactedObjects: objects(datasourceIds: "${datasourceId}"${filtersQuery}) {
                  count
                }
  }
  `
}

export const mapImpactedObjectsCountByBucket = (raw: any) => {
  try {
    return raw.impactedObjects.count
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryEntitiesCountByBucket = (params: IFetchParams) => {
  const { datasourceId, filters } = params
  let filtersQuery = ''
  const filterString = parameterizeArrayofObjects(filters?.filter || [])
  filterString ? (filtersQuery += `,filter:${filterString}`) : ''
  return gql`
  {
    userEntities(datasourceId: "${datasourceId}"${filtersQuery}) {
      count
    }
  }
  `
}

export const mapQueryEntitiesCountByBucket = (raw: any) => {
  try {
    return raw.userEntities.count
  } catch (error) {
    console.error(error)
    throw error
  }
}
