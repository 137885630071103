import ElasticSearchForm from './elasticSearchForm'
import Page from '../../../../../components/Page/page'
import PageHeader from '../../../../../components/Header/header'
import { URL_DATA_SOURCES } from '../../../../../constants'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Typography } from '@lightbeamai/design-system'

const ElasticSearchCreateEdit = (): React.ReactElement => {
  const intl = useIntl()
  const history = useHistory()
  const onCancel = () => {
    history.push(URL_DATA_SOURCES)
  }
  return (
    <Page
      showBreadcrumbs={false}
      header={
        <PageHeader
          content={
            <Typography variant="basePlus2" className="font-500 xs-mb-16">
              {intl.formatMessage({ id: 'elasticSearch.settings.header' })}
            </Typography>
          }
        />
      }
    >
      <ElasticSearchForm onCancelClick={onCancel} />
    </Page>
  )
}

export default ElasticSearchCreateEdit
