import PrintWidgetDsDetails from './printWidgetDsDetails'
import StructutedDSAttributesDashboardWidget from './structuredDSAttributeWidget'
import AttributesDashboardWidget from '../../features/attributes/attributesDashboardWidget'
import ClassificationsGroupedWidget from '../../features/classifications/classificationsGroupedWidget'
import { PrintDataSourceSummary } from '../printSlice'
import DataSourceTypeItem from '../../components/DataSourceTypeItem/dataSourceTypeItem'
import { DATA_SOURCE_TYPES, STRUCTURED_DATASOURCES } from '../../constants'
import WidgetDatabasesByPiiTables from '../../features/databases/widgetDatabasesByPiiTables'
import React from 'react'
import { useIntl } from 'react-intl'

interface IProps {
  dataSource: PrintDataSourceSummary
}

const PrintDsWidgetsContainer = (props: IProps): React.ReactElement => {
  const intl = useIntl()

  const { dataSource } = props
  const { name, type, policiesViolatedCount = 0 } = dataSource || {}

  const isStructured = STRUCTURED_DATASOURCES.find((dsType) => dsType === dataSource.type)

  return (
    <div className="ds-widgets-container" data-test-id="print-ds-widgets-container">
      <div className="ds-header">
        <div className="right">
          <DataSourceTypeItem dataSourceType={type} content={name} sizePx={32} />
          {policiesViolatedCount > 0 && (
            <div className="risk-label">{intl.formatMessage({ id: 'print.atRisk' })}</div>
          )}
        </div>
        {type !== DATA_SOURCE_TYPES.remote && (
          <div className="ds-connected">{intl.formatMessage({ id: 'print.connected' })}</div>
        )}
      </div>
      <PrintWidgetDsDetails dataSource={dataSource} />
      <div className="widgets-row">
        <div className="print-widget print-widget-ds">
          {isStructured ? (
            <StructutedDSAttributesDashboardWidget
              dataSourceId={dataSource.id}
              legendPosition="right"
              chartSize={140}
              isAnimated={false}
            />
          ) : (
            <AttributesDashboardWidget
              dataSourceId={dataSource.id}
              legendPosition="right"
              chartSize={140}
              isAnimated={false}
            />
          )}
        </div>
        <div className="print-widget print-widget-ds">
          {isStructured ? (
            <StructutedDSAttributesDashboardWidget
              dataSourceId={dataSource.id}
              legendPosition="right"
              chartSize={140}
              isAnimated={false}
              isHighSensitive={true}
            />
          ) : (
            <AttributesDashboardWidget
              dataSourceId={dataSource.id}
              legendPosition="right"
              chartSize={140}
              isAnimated={false}
              isHighSensitive={true}
            />
          )}
        </div>
        <div className="print-widget print-widget-ds">
          {isStructured ? (
            <WidgetDatabasesByPiiTables
              dataSourceId={dataSource.id}
              legendPosition="right"
              chartSize={140}
              isAnimated={false}
            />
          ) : (
            <ClassificationsGroupedWidget
              dataSourceId={dataSource.id}
              legendPosition="right"
              chartSize={140}
              isAnimated={false}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default PrintDsWidgetsContainer
