import './globalSearch.less'
import { setIsShowResults } from './globalSearchSlice'
import { getIsLoading } from './globalSearchSelectors'
import { RootState } from '../../rootReducer'
import { GlobalSearchResults } from '../../services/api/apiTypes'
import Header from '../../components/Header/header'
import LabelList from '../../components/Search/result/labelList'
import ResultList from '../../components/Search/result/resultList'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

interface IProps {
  search: string
  results: GlobalSearchResults
  setIsShowResults: (isShowResults: boolean) => void
}

const GlobalSearch = (props: IProps): React.ReactElement => {
  const { search, results, setIsShowResults } = props

  useEffect(() => {
    setIsShowResults(false)
    return () => setIsShowResults(true)
  })

  const headerContent = (
    <span>
      {results.results.length} <FormattedMessage id="search.results.total" /> &lsquo;{search}&rsquo;
    </span>
  )

  const labels = results?.labels || []
  const records = results?.results || []

  return (
    <div
      className="page-global-search xs-px-8 md-px-28 xs-py-32"
      data-test-id="globalsearch-global-search"
    >
      {search.length > 1 && <Header content={headerContent}></Header>}
      <div className="page-global-search-results">
        {labels.length > 0 && <LabelList labels={labels} showHeader={false} />}
        {records.length > 0 && <ResultList results={records} search={search} />}
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  setIsShowResults
}

const mapStateToProps = (state: RootState) => ({
  results: state.globalSearch.result,
  search: state.globalSearch.search,
  isLoading: getIsLoading(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch)
