import TestConnectionButton from '../../../TestConnectionButton'
import RegisterOrUpdateButton from '../../../RegisterOrUpdateButton'
import {
  ElasticSearchRegisterValues,
  ELASTIC_SEARCH_API_KEY_ID,
  ELASTIC_SEARCH_API_KEY_SECRET,
  ELASTIC_SEARCH_AUTH_METHOD,
  ELASTIC_SEARCH_HOSTNAME,
  ELASTIC_SEARCH_INSTANCE_NAME,
  ELASTIC_SEARCH_PASSWORD,
  ELASTIC_SEARCH_USER_NAME,
  API_KEY_METHOD,
  BASIC_AUTH_METHOD,
  ElasticSearchParams
} from '../elasticSearchSettingsSlice'
import { elasticSearchStep1ValidationSchema } from '../../../../../../utils/validators/elasticSearchValidator'
import { RootState } from '../../../../../../rootReducer'
import React, { FormEvent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useFormik } from 'formik'
import { CheckboxProps, Form, Icon, Label } from 'semantic-ui-react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@lightbeamai/design-system'
interface IProps {
  values: ElasticSearchRegisterValues
  handleFormChange: (values: ElasticSearchRegisterValues) => void
  handleNextStatus: (isValid: boolean) => void
  mapValuesToApiParams: (values: ElasticSearchRegisterValues) => ElasticSearchParams
  dataSourceId: string
}

const Step1 = (props: IProps): React.ReactElement => {
  const { values, handleFormChange, mapValuesToApiParams, dataSourceId, handleNextStatus } = props
  const [isPasswordHidden, setIsPasswordHidden] = useState(true)
  const [authMethod, setAuthMethod] = useState<string>(values[ELASTIC_SEARCH_AUTH_METHOD])
  const formik = useFormik({
    initialValues: {
      [ELASTIC_SEARCH_INSTANCE_NAME]: values[ELASTIC_SEARCH_INSTANCE_NAME] || '',
      [ELASTIC_SEARCH_HOSTNAME]: values[ELASTIC_SEARCH_HOSTNAME] || '',
      [ELASTIC_SEARCH_USER_NAME]: values[ELASTIC_SEARCH_USER_NAME] || '',
      [ELASTIC_SEARCH_PASSWORD]: values[ELASTIC_SEARCH_PASSWORD] || '',
      [ELASTIC_SEARCH_API_KEY_ID]: values[ELASTIC_SEARCH_API_KEY_ID] || '',
      [ELASTIC_SEARCH_API_KEY_SECRET]: values[ELASTIC_SEARCH_API_KEY_SECRET] || ''
    },
    validationSchema: elasticSearchStep1ValidationSchema,
    onSubmit: async () => ''
  })
  const handleAuthMethodChange = (_: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    values[ELASTIC_SEARCH_AUTH_METHOD] = data.value as string
    setAuthMethod(data.value as string)
  }
  const isValid = () => {
    return (
      formik.values[ELASTIC_SEARCH_INSTANCE_NAME].length > 0 &&
      formik.values[ELASTIC_SEARCH_HOSTNAME].length > 0 &&
      !formik.errors[ELASTIC_SEARCH_INSTANCE_NAME] &&
      !formik.errors[ELASTIC_SEARCH_HOSTNAME]
    )
  }
  useEffect(() => {
    handleFormChange(formik.values as ElasticSearchRegisterValues)
  }, [formik.values, formik.errors])

  useEffect(() => {
    handleNextStatus(dataSourceId !== '')
  }, [dataSourceId])

  const togglePassword = () => setIsPasswordHidden(!isPasswordHidden)

  return (
    <Form className="register-form" data-test-id="datasources-elasticsearch-step1">
      <div className="form-wrapper">
        <div className="side">
          {dataSourceId && (
            <div className="form-item">
              <Label>
                <FormattedMessage id="dataSources.form.label.id" />: {dataSourceId}
              </Label>
            </div>
          )}
          <div className="form-item">
            <Form.Input
              {...formik.getFieldProps(ELASTIC_SEARCH_INSTANCE_NAME)}
              label={
                <Typography as="span" variant="baseMinus2" className="label show-required">
                  <FormattedMessage id="dataSources.form.field.Name" />
                </Typography>
              }
              data-test-id="ds-register-elastic-instance-name"
              error={
                formik.touched[ELASTIC_SEARCH_INSTANCE_NAME] &&
                formik.errors[ELASTIC_SEARCH_INSTANCE_NAME] && {
                  content: <FormattedMessage id={formik.errors[ELASTIC_SEARCH_INSTANCE_NAME]} />,
                  pointing: 'above',
                  'data-test-id': 'ds-register-elastic-instance-name-error'
                }
              }
            />
          </div>
          <div className="form-item">
            <Form.Input
              {...formik.getFieldProps(ELASTIC_SEARCH_HOSTNAME)}
              label={
                <Typography as="span" variant="baseMinus2" className="label show-required">
                  <FormattedMessage id="dataSources.form.field.InstanceUrl" />
                </Typography>
              }
              data-test-id="ds-register-elastic-host-name"
              error={
                formik.touched[ELASTIC_SEARCH_HOSTNAME] &&
                formik.errors[ELASTIC_SEARCH_HOSTNAME] && {
                  content: <FormattedMessage id={formik.errors[ELASTIC_SEARCH_HOSTNAME]} />,
                  pointing: 'above',
                  'data-test-id': 'ds-register-elastic-host-name-error'
                }
              }
            />
          </div>
          <div className="title">
            <Form.Group inline>
              <Form.Radio
                label="Basic Authentication"
                value={BASIC_AUTH_METHOD}
                checked={authMethod === BASIC_AUTH_METHOD}
                onChange={handleAuthMethodChange}
              />
              <Form.Radio
                label="API Key"
                value={API_KEY_METHOD}
                checked={authMethod === API_KEY_METHOD}
                onChange={handleAuthMethodChange}
              />
            </Form.Group>
          </div>
          {authMethod == BASIC_AUTH_METHOD && (
            <>
              <div className="form-item">
                <Form.Input
                  {...formik.getFieldProps(ELASTIC_SEARCH_USER_NAME)}
                  label={
                    <Typography as="span" variant="baseMinus2" className="label">
                      <FormattedMessage id="dataSources.form.field.Username" />
                    </Typography>
                  }
                  data-test-id="ds-register-elastic-search-user"
                  error={
                    formik.touched[ELASTIC_SEARCH_USER_NAME] &&
                    formik.errors[ELASTIC_SEARCH_USER_NAME] && {
                      content: <FormattedMessage id={formik.errors[ELASTIC_SEARCH_USER_NAME]} />,
                      pointing: 'above',
                      'data-test-id': 'ds-register-elastic-search-user-error'
                    }
                  }
                />
              </div>
              <div className="form-item password">
                <Form.Input
                  {...formik.getFieldProps(ELASTIC_SEARCH_PASSWORD)}
                  type={isPasswordHidden ? 'password' : 'text'}
                  label={
                    <Typography as="span" variant="baseMinus2" className="label">
                      <FormattedMessage id="dataSources.form.field.Password" />
                    </Typography>
                  }
                  data-test-id="ds-register-elastic-password"
                  error={
                    formik.touched[ELASTIC_SEARCH_PASSWORD] &&
                    formik.errors[ELASTIC_SEARCH_PASSWORD] && {
                      content: <FormattedMessage id={formik.errors[ELASTIC_SEARCH_PASSWORD]} />,
                      pointing: 'above',
                      'data-test-id': 'ds-register-elastic-password-error'
                    }
                  }
                />
                <Icon
                  onClick={togglePassword}
                  name={isPasswordHidden ? 'unhide' : 'hide'}
                  className="toggle-password"
                />
              </div>
            </>
          )}
          {authMethod == API_KEY_METHOD && (
            <>
              <div className="form-item">
                <Form.Input
                  {...formik.getFieldProps(ELASTIC_SEARCH_API_KEY_ID)}
                  label={
                    <Typography as="span" variant="baseMinus2" className="label">
                      <FormattedMessage id="dataSources.form.field.ApiKey" />
                    </Typography>
                  }
                  data-test-id="ds-register-elastic-api-key"
                  error={
                    formik.touched[ELASTIC_SEARCH_API_KEY_ID] &&
                    formik.errors[ELASTIC_SEARCH_API_KEY_ID] && {
                      content: <FormattedMessage id={formik.errors[ELASTIC_SEARCH_API_KEY_ID]} />,
                      pointing: 'above',
                      'data-test-id': 'ds-register-elastic-api-key-error'
                    }
                  }
                />
              </div>
              <div className="form-item">
                <Form.Input
                  {...formik.getFieldProps(ELASTIC_SEARCH_API_KEY_SECRET)}
                  label={
                    <Typography as="span" variant="baseMinus2" className="label">
                      <FormattedMessage id="dataSources.form.field.ApiSecret" />
                    </Typography>
                  }
                  data-test-id="ds-register-elastic-api-secret"
                  error={
                    formik.touched[ELASTIC_SEARCH_API_KEY_SECRET] &&
                    formik.errors[ELASTIC_SEARCH_API_KEY_SECRET] && {
                      content: (
                        <FormattedMessage id={formik.errors[ELASTIC_SEARCH_API_KEY_SECRET]} />
                      ),
                      pointing: 'above',
                      'data-test-id': 'ds-register-elastic-api-secret-error'
                    }
                  }
                />
              </div>
            </>
          )}
          {isValid() && (
            <div className="form-item">
              <Form.Group inline>
                <div className="test-connection-status">
                  <TestConnectionButton data={mapValuesToApiParams(values)} />
                </div>
                <div className="register-or-update-status">
                  <RegisterOrUpdateButton
                    data={mapValuesToApiParams(values)}
                    dataSourceId={dataSourceId}
                  />
                </div>
              </Form.Group>
            </div>
          )}
        </div>
      </div>
    </Form>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    dataSourceId: state.dataSources.dataSourceId
  }
}

export default connect(mapStateToProps)(Step1)
