const CURSOR_PREFIX = 'arrayconnection:'

export const getAfterCursor = (nextPage: number, recordsPerPage: number): string => {
  // don't need the "after" cursor for the first set of records
  if (nextPage < 2) {
    return ''
  }
  const curPos = (nextPage - 1) * recordsPerPage - 1
  return window.btoa(unescape(encodeURIComponent(CURSOR_PREFIX + curPos)))
}

export const calculateSkipAndLimit = (page: number, limit: number): number => {
  const skip = (page - 1) * limit
  return skip
}
export const decodeCursorVal = (cursor: string): number => {
  if (cursor) {
    return +window.atob(cursor).replace(CURSOR_PREFIX, '')
  }
  return 0
}

export const stringifyParams = (params: {
  [key: string]: string | string[] | boolean | undefined | number
}): string =>
  Object.entries(params).reduce((acc, [key, value]) => {
    if (value != undefined) {
      if (Array.isArray(value)) {
        return acc ? `${acc},${key}:${JSON.stringify(value)}` : `${key}:${JSON.stringify(value)}`
      } else {
        return acc ? `${acc},${key}:${value}` : `${key}:${value}`
      }
    }
    return acc
  }, '')

export const parameterizeArrayofObjects = (
  params: { key: string; values?: string | string[]; value?: boolean }[]
): string => {
  // check if filters are not empty
  if (!params.find((p) => p.value != undefined || (p.values || [])?.length > 0)) return ''

  return params.reduce((acc, { key, values, value }, index) => {
    if (value != undefined || values != undefined) {
      acc = acc
        ? `${acc},{key:${key},${value != undefined ? 'value' : 'values'}:${JSON.stringify(
            value != undefined ? value : values
          )}}`
        : `[{key:${key},${value != undefined ? 'value' : 'values'}:${JSON.stringify(
            value != undefined ? value : values
          )}}`
    }
    if (index === params.length - 1) {
      acc += ']'
    }
    return acc
  }, '')
}
