import { getMessageByApiStatus } from '../services/api/apiStatuses'
import { RootState } from '../rootReducer'
import { ACTION_BREADCRUMBS_GET, ACTION_TEXT_LABELS_GET, ACTION_USER_INFO } from '../constants'
import { STATES } from '../interfaces'
import { store } from '../index'
import { ACTION_COLUMNS_LIST_FETCH } from '../features/columns/columnsSlice'
import { ACTION_TABLES_LIST_FETCH } from '../features/tables/tablesSlice'
import { ACTION_DATABASES_LIST_FETCH } from '../features/databases/databasesSlice'
import { ACTION_API_REQUESTS_WIDGET_FETCH } from '../features/apiRequests/apiRequestsSlice'
import { ACTION_DOCUMENTS_UNCLASSIFIED_FETCH } from '../features/classifications/classificationsSlice'
import { AsyncThunk, AnyAction, createSelector, createSlice } from '@reduxjs/toolkit'

const excludeActions = [
  ACTION_API_REQUESTS_WIDGET_FETCH,
  ACTION_DOCUMENTS_UNCLASSIFIED_FETCH,
  ACTION_BREADCRUMBS_GET,
  ACTION_USER_INFO,
  ACTION_TEXT_LABELS_GET
]

const isActionAllowed = (fullActionName, actionStatus) => {
  return !excludeActions.find((action) => action + actionStatus === fullActionName)
}

const excludeErrorActions = [
  ACTION_COLUMNS_LIST_FETCH,
  ACTION_TABLES_LIST_FETCH,
  ACTION_DATABASES_LIST_FETCH
]

const isErrorNotificationAllowed = (fullActionName, actionStatus) => {
  return !excludeErrorActions.find((action) => action + actionStatus === fullActionName)
}

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>

type PendingAction = ReturnType<GenericAsyncThunk['pending']>
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>

const isPendingAction = (action: AnyAction): action is PendingAction => {
  return isActionAllowed(action.type, '/pending') && action.type.endsWith('/pending')
}
const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return isActionAllowed(action.type, '/rejected') && action.type.endsWith('/rejected')
}
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return isActionAllowed(action.type, '/fulfilled') && action.type.endsWith('/fulfilled')
}

const actionsSelector = (state: RootState) => state.request.actions

export const getShowLoader = (actionName?: string): boolean => {
  return createSelector(actionsSelector, (actions) => {
    if (actionName) {
      return actions[actionName]?.state === STATES.pending
    } else {
      return !!Object.values(actions).find(({ state }) => state === STATES.pending)
    }
  })(store.getState())
}

export const getActionState = (actionName: string): STATES => {
  return createSelector(actionsSelector, (actions) => actions[actionName]?.state)(store.getState())
}
export const getAction = (actionName: string): RequestState => {
  return createSelector(actionsSelector, (actions) => actions[actionName])(store.getState())
}

export type RequestState = { state: STATES; success: ''; error: ''; data?: any }
export type RequestActions = {
  [actionName: string]: RequestState
}

export interface LoadingState {
  actions: RequestActions
  success: string
  error: string
}

export const initialState: LoadingState = {
  actions: {},
  success: '',
  error: ''
}

const parseableResponseKeys = ['message', 'statusMessage']
const requestSlice = createSlice({
  name: 'requestSlice',
  initialState,
  reducers: {
    resetAction: (state, { payload }) => {
      delete state.actions[payload]
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(isPendingAction, (state, action) => {
      const actionName = action.type.split('/').slice(0, -1).join('/')
      state.actions[actionName] = { state: STATES.pending, success: '', error: '' }
      state.success = ''
      state.error = ''
    })
    builder.addMatcher(isRejectedAction, (state, action: AnyAction) => {
      const actionName = action.type.split('/').slice(0, -1).join('/')
      state.actions[actionName].state = STATES.failed
      if (isErrorNotificationAllowed(action.type, '/rejected')) {
        const error = action.payload?.statusMessage || getMessageByApiStatus(action.error?.message)

        if (state.actions[actionName]) {
          state.actions[actionName].error = error
        }
        state.error = error
      }
    })
    builder.addMatcher(isFulfilledAction, (state, action: AnyAction) => {
      const { type, payload } = action
      const actionName = type.split('/').slice(0, -1).join('/')
      const messageKey = payload
        ? parseableResponseKeys.find((responseKey) => payload[responseKey])
        : ''
      const success = messageKey ? payload[messageKey] : ''

      if (state.actions[actionName]) {
        state.actions[actionName].state = STATES.success
        state.actions[actionName].success = state.success = success
        if (payload?.data) state.actions[actionName].data = payload.data
      }
    })
  }
})

export const { resetAction } = requestSlice.actions

export default requestSlice.reducer
