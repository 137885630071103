import {
  mapQueryScannedDataGraph,
  mapQueryTotalDataScanned,
  queryScannedDataGraph,
  queryTotalDataScanned
} from './queries'
import { DATA_SOURCE_ID } from '../../constants'
import graphqlService from '../../services/graphqlService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export enum SCANNED_TYPE {
  attribute = 'ATTRIBUTE',
  document = 'DOCUMENT'
}
export enum SCANNED_RANGE {
  day = 'DAY',
  week = 'WEEK'
}

export interface ScannedDataParams {
  range: SCANNED_RANGE
  [DATA_SOURCE_ID]?: string
}

export interface ScannedDataGraphItem {
  timestamp: string
  value: number
}
export const ACTION_SCANNED_DATA_GRAPH_FETCH = 'scannedData/widgets'
export const fetchScannedDataWidgets = createAsyncThunk(
  ACTION_SCANNED_DATA_GRAPH_FETCH,
  async (params: ScannedDataParams) => {
    const result = await graphqlService.execute(queryScannedDataGraph(params))
    return mapQueryScannedDataGraph(result)
  }
)

export interface DocumentsScannedParams {
  datasourceId?: string
}
export const ACTION_SCANNED_DATA_COUNT_FETCH = 'scannedData/count'
export const fetchScannedDataCount = createAsyncThunk(
  ACTION_SCANNED_DATA_COUNT_FETCH,
  async (params: DocumentsScannedParams) => {
    const result = await graphqlService.execute(queryTotalDataScanned(params))
    return mapQueryTotalDataScanned(result)
  }
)

interface ScannedDataState {
  graphAttributes?: ScannedDataGraphItem[]
  graphDocuments?: ScannedDataGraphItem[]
  totalDataScanned?: number
}

export const initialState: ScannedDataState = {}

const scannedDataSlice = createSlice({
  name: 'scannedData',
  initialState,
  reducers: {
    resetWidgets: (state) => {
      state.graphAttributes = initialState.graphAttributes
      state.graphDocuments = initialState.graphDocuments
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScannedDataWidgets.fulfilled, (state, { payload }) => {
      state.graphAttributes = payload.attributes
      state.graphDocuments = payload.documents
    })
    builder.addCase(fetchScannedDataCount.fulfilled, (state, { payload }) => {
      state.totalDataScanned = payload.total
    })
  }
})

export const { resetWidgets } = scannedDataSlice.actions
export default scannedDataSlice.reducer
