import apiService from '../../services/api/apiService'
import { LBVersionDetails } from '../../services/api/apiTypes'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const ACTION_FETCH_VERSION_DETAILS = 'about/versionDetails'
export const fetchVersionDetails = createAsyncThunk(
  ACTION_FETCH_VERSION_DETAILS,
  async (): Promise<LBVersionDetails> => {
    return await apiService.getVersionDetails()
  }
)

interface AboutState {
  versionDetails?: LBVersionDetails
  versionDetailsError: boolean
}
const initialState: AboutState = {
  versionDetails: undefined,
  versionDetailsError: false
}

const aboutSlice = createSlice({
  name: 'about',
  initialState,
  reducers: {
    resetVersionDetails: (state) => {
      state.versionDetails = initialState.versionDetails
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVersionDetails.fulfilled, (state, { payload }) => {
      state.versionDetails = payload
      state.versionDetailsError = false
    }),
      builder.addCase(fetchVersionDetails.rejected, (state) => {
        state.versionDetailsError = true
      })
  }
})

export const { resetVersionDetails } = aboutSlice.actions
export default aboutSlice.reducer
