import { IContextFilters, IContextDisplayFilters } from '../interfaces'
import { createSlice } from '@reduxjs/toolkit'

export interface IContextState {
  pageName: string
  pageUrl: string
  filters: IContextFilters | null // TODO undefined type instead of null: filters?: IContextFilters
  isContextSet: boolean
  dataSourceName?: string
  displayFilters?: IContextDisplayFilters
}

export const initialState: IContextState = {
  pageName: '',
  pageUrl: '',
  filters: null,
  isContextSet: false,
  displayFilters: undefined
}

const contextSlice = createSlice({
  name: 'context',
  initialState,
  reducers: {
    setContext: (state, action) => {
      state.pageName = action.payload.pageName
      state.pageUrl = action.payload.pageUrl
      state.filters = action.payload.filters
      state.isContextSet = true
      state.displayFilters = action.payload.displayFilters
    },
    updateDisplayFilters: (state, { payload }) => {
      state.displayFilters = { ...state.displayFilters, ...payload }
    },
    resetContext: () => initialState
  }
})

export const { setContext, updateDisplayFilters, resetContext } = contextSlice.actions

export default contextSlice.reducer
