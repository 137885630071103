import {
  mapQueryPermitListCards,
  mapQueryPermitListTable,
  queryPermitListCards,
  queryPermitListTable
} from './queries'
import { FilterParams, SORT_ORDER } from '../../interfaces'
import { defaultSortParams, getSortDirection, sortByOrder, SortParams } from '../../utils/sortUtil'
import graphqlService from '../../services/graphqlService'
import { getGlobalParams } from '../../utils/urlUtil'
import { RootState } from '../../rootReducer'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const ACTION_PERMIT_LIST_CARD_FETCH = 'permit-list/cards'
export const fetchPermitListCards = createAsyncThunk(
  ACTION_PERMIT_LIST_CARD_FETCH,
  async (params: FilterParams) => {
    const resultRaw = await graphqlService.execute(queryPermitListCards(params))
    return mapQueryPermitListCards(resultRaw)
  }
)

export interface IGetPermitTableListParams extends FilterParams {
  page: number
  pageSize?: number
}
export const ACTION_PERMIT_LIST_TABLE_FETCH = 'permit-list/table'
export const fetchPermitListTable = createAsyncThunk(
  ACTION_PERMIT_LIST_TABLE_FETCH,
  async (params: IGetPermitTableListParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const resultRaw = await graphqlService.execute(queryPermitListTable(queryParams))
    return mapQueryPermitListTable(resultRaw)
  }
)

export interface IPermitListItem {
  name?: string
  objectCount?: number
  objectName?: string
  objectType?: string
  objectId?: string
  ruleSetName?: string
  datasourceName?: string
  datasourceId?: string
  datasourceType?: string
  channelName?: string
  channelId?: string
  addedBy?: string
  addedOn?: string
  emailMessageId?: string
}

interface IPermitListCardData {
  list?: IPermitListItem[]
  totalRuleSets?: number
  totalObjects?: number
}

interface IPermitListTableData {
  sort: SortParams
  list?: IPermitListItem[]
  total?: number
}
const initialList = { sort: defaultSortParams }
interface PermitListState {
  cards: IPermitListCardData
  list: IPermitListTableData
}

export const initialState: PermitListState = {
  cards: {},
  list: { ...initialList }
}

const permitListSlice = createSlice({
  name: 'permitList',
  initialState,
  reducers: {
    setSort: (state, { payload }) => {
      state.list.sort = getSortDirection(state.list.sort, payload.column)
    },
    resetLists: (state) => {
      state.list = { ...initialList }
      state.cards = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPermitListCards.fulfilled, (state, { payload }) => {
      state.cards.list = sortByOrder(payload.list, 'name', SORT_ORDER.ASC)
      state.cards.totalRuleSets = payload.total
      state.cards.totalObjects = payload.objects
    })
    builder.addCase(fetchPermitListTable.fulfilled, (state, { payload }) => {
      state.list.list = payload.list
      state.list.total = payload.total
    })
  }
})

export const { setSort, resetLists } = permitListSlice.actions
export default permitListSlice.reducer
