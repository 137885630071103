import './trendSummaryWidget.less'
import TrendItem, { TrendItemData } from './trendSummaryItem'
import React from 'react'

interface IProps {
  weeksCount: number
  data: TrendItemData[]
}

const TrendWidget = ({ data, weeksCount }: IProps): React.ReactElement => {
  return (
    <div className="trend-widget">
      <p className="widget-title xs-mb-12">{weeksCount} weeks Trend</p>
      <div className="list-trend xs-pl-28">
        {data.map((data) => (
          <TrendItem key={data.text} data={data} />
        ))}
      </div>
    </div>
  )
}

export default TrendWidget
