import './policyDashboard.less'
import {
  fetchRulsetSummaryForPrint,
  fetchRulsetDataSources,
  FetchRulsetDataSourcesParams
} from './policiesSlice'
import Table from '../../components/Table/table'
import { RootState } from '../../rootReducer'
import { IPolicyTypeForPrint } from '../../interfaces'
import {
  URL_RULE_SET,
  POLICY_TYPES_PREFIX_MAPPER,
  POLICY_TYPES_MAPPER,
  URL_POLICIES,
  URL_DETAIL,
  POLICY_TYPE,
  REFERRER
} from '../../constants'
import { getShowLoader } from '../../reducers/requestReducer'
import TitleColumn from '../../components/Table/titleColumn'
import RegularColumn from '../../components/Table/regularColumn'
import { getDataSourceImage } from '../../utils/dataSourceUtil'
import SvgNoData from '../../assets/inlineSvg/empty_policies_print.svg'
import { getDataSourcesAllListSorted } from '../dataSources/dataSourceSelectors'
import { DataSource } from '../../services/api/apiTypes'
import { NameIdSummary } from '../ropa/ropaSliceV2'
import { FormattedNumber, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import React, { useEffect } from 'react'

interface IProps {
  rulesetsDataSources?: { [key: string]: NameIdSummary[] }
  listDataSources?: DataSource[]
  policyTypes?: IPolicyTypeForPrint[]
  fetchRulsetSummaryForPrint: () => void
  fetchRulsetDataSources: (params: FetchRulsetDataSourcesParams[]) => void
}

const RulesetsPrint = ({
  rulesetsDataSources,
  listDataSources = [],
  policyTypes = [],
  fetchRulsetDataSources,
  fetchRulsetSummaryForPrint
}: IProps): React.ReactElement => {
  const intl = useIntl()

  useEffect(() => {
    if (policyTypes.length) {
      const params = policyTypes.map(({ id, filterIds }) => ({ filterIds, policyId: id }))
      fetchRulsetDataSources(params)
    }
  }, [policyTypes, fetchRulsetDataSources])

  useEffect(() => {
    fetchRulsetSummaryForPrint()
  }, [fetchRulsetSummaryForPrint])
  const printColumns = [
    {
      title: intl.formatMessage({ id: 'ruleset.list.name' }),
      dataKey: 'name',
      cellRenderer: function TitleRenderer({ name, id, type }) {
        const linkDetail = `${URL_POLICIES}${URL_DETAIL}/${id}?${POLICY_TYPE}=${type}&${REFERRER}=${URL_RULE_SET}`
        return (
          <TitleColumn
            link={linkDetail}
            title={intl.formatMessage({ id: 'ruleset.list.name' })}
            content={name}
          />
        )
      }
    },
    {
      title: intl.formatMessage({ id: 'ruleset.list.type' }),
      dataKey: 'type',
      cellRenderer: function TitleRenderer({ type = '', prefix = '' }) {
        const prefixkey = POLICY_TYPES_PREFIX_MAPPER[prefix] || ''
        const typeKey = POLICY_TYPES_MAPPER[type] || ''
        const transPrefix = prefixkey ? intl.formatMessage({ id: prefixkey }) : ''
        const transType = typeKey ? intl.formatMessage({ id: typeKey }) : ''
        const policyTypeText = `${transPrefix}: ${transType}`

        return (
          <RegularColumn
            title={intl.formatMessage({ id: 'ruleset.list.type' })}
            content={policyTypeText}
          />
        )
      }
    },
    {
      title: intl.formatMessage({ id: 'dataSources.table.dataSource' }),
      dataKey: 'datasources',
      cellRenderer: function RegularRenderer({ id }) {
        const imagesBasePath = '../../../../assets/images/dataSourceTypes/'
        const dataSources =
          rulesetsDataSources && rulesetsDataSources[id] ? rulesetsDataSources[id] || [] : []
        const data =
          dataSources.length > 0
            ? dataSources
            : listDataSources.map(({ dataSourceName, dataSourceType, id }) => ({
                name: dataSourceName,
                type: dataSourceType,
                id
              }))

        return (
          <RegularColumn
            title={intl.formatMessage({ id: 'dataSources.table.dataSource' })}
            content={
              <div className="ds-name-files">
                {data.map((ds, index) => (
                  <div key={index} className="ds-logo-name">
                    <img src={getDataSourceImage(ds.type, imagesBasePath)} className="xs-mr-12" />
                    <p className="title truncate">{ds.name}</p>
                  </div>
                ))}
              </div>
            }
          />
        )
      }
    },
    {
      title: intl.formatMessage({ id: 'alerts.list.objects' }),
      dataKey: 'objectsCount',
      className: 'number',
      cellRenderer: function TitleRenderer({ objectsCount }) {
        return (
          <RegularColumn
            title={intl.formatMessage({ id: 'alerts.list.objects' })}
            className="number"
            content={
              <>
                {objectsCount > 0 ? (
                  <div>
                    <span>
                      <FormattedNumber value={objectsCount} notation="compact" />
                    </span>
                  </div>
                ) : (
                  0
                )}
              </>
            }
          />
        )
      }
    },
    {
      title: intl.formatMessage({ id: 'alerts.tab.entitiesImpacted' }),
      dataKey: 'entitiesCount',
      className: 'number',
      cellRenderer: function TitleRenderer({ entitiesCount = 0 }) {
        return (
          <RegularColumn
            title={intl.formatMessage({ id: 'alerts.tab.entitiesImpacted' })}
            className="number"
            content={
              <>
                {entitiesCount > 0 ? (
                  <div>
                    <span>
                      <FormattedNumber value={entitiesCount} notation="compact" />
                    </span>
                  </div>
                ) : (
                  0
                )}
              </>
            }
          />
        )
      }
    }
  ]
  const isEmptyRuleSet = policyTypes.length === 0
  return (
    <>
      {isEmptyRuleSet ? (
        <div className="no-data">
          <SvgNoData />
        </div>
      ) : (
        <div className="policy-dashboard xs-py-20" data-test-id="policies-rulesets-print">
          <div className="xs-my-0 ruleset-content">
            <div className="wrapper types-list xs-px-20 xs-py-24">
              <Table
                columns={printColumns}
                data={policyTypes}
                isCellRenderer
                scrollable={false}
                tableProps={{
                  classes: {
                    table: `actions-hover-table rulesets-table table-ruleset-policies print`,
                    thead: 'mobile hidden'
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapDispatchToProps = {
  fetchRulsetDataSources,
  fetchRulsetSummaryForPrint
}

const mapStateToProps = (state: RootState) => {
  return {
    policyTypes: state.policies.policyTypeForPrint,
    rulesetsDataSources: state.policies.rulesetsDataSources,
    listDataSources: getDataSourcesAllListSorted(state),
    showLoader: getShowLoader()
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RulesetsPrint)
