import type {
  CloudAccountsListObject,
  CloudAccountsListResponseType,
  CloudPlatformByIdResponseType,
  CloudPlatformsListObject,
  CloudPlatformsListResponseType,
  CloudResourcesCountResponseType,
  CloudResourcesListObject,
  CloudResourcesListResponseType,
  DeleteCloudResourceParams,
  FetchCloudPlatformByIdParams,
  FetchCloudPlatformsParams,
  FetchCloudResourcesParams,
  FetchRegionsListParams,
  FetchResourceTypeFilterListParams,
  GcpProjectsListObject,
  QueryGcpProjectListParams,
  RefreshCloudPlatformParams,
  RegionListObject,
  RegionListResponseType,
  RegisterCloudPlatformParams,
  ResourceTypeFilterListObject,
  ResourceListFilterResponseType,
  UpdateCloudPlatformParams,
  VerifyCloudPlatformCredsParams
} from './types'
import { CLOUD_PLATFORM_TYPE } from './types'
import { LIMIT_DEFAULT } from '../../constants'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { gql } from 'graphql-request'

// Fetch Cloud Resources
export const queryCloudResourcesList = (params: FetchCloudResourcesParams): string => {
  const { filters, page = 1, sortField, statusOrder } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  let paramStr = ''
  if (statusOrder) {
    paramStr += `, statusOrder: [${statusOrder}]`
  }
  if (sortField) {
    paramStr += `, sortField: ${sortField}`
  }
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const booleanFilterString =
    filters && filters.booleanFilter ? parameterizeArrayofObjects(filters.booleanFilter) : ''

  if (filterString) {
    paramStr += `, filter: ${filterString}`
  }
  if (booleanFilterString) {
    paramStr += `, booleanFilter: ${booleanFilterString} `
  }
  if (paramStr) {
    paramStr = `(first: ${LIMIT_DEFAULT}${paramStr},after:"${cursor}")`
  }
  return gql`
  {
    cloudResource${paramStr}{
      edges {
        node {
          id
          name
          type
          datasourceType
          datasourceId
          status
          region
          datasourceOwner
          addedOnTimestamp
          isRetry
          isDelete
          errorDescription
          cloudAccount {
            id
            name
          }
          cloudPlatform {
              id
              name
              platformType
              owner
          }
        }
      }
    }
  }

      `
}
export const mapQueryCloudResourcesList = (queryResponse: any): CloudResourcesListResponseType => {
  try {
    const list: CloudResourcesListObject[] =
      queryResponse?.cloudResource?.edges.map(({ node }) => ({
        id: node?.id,
        name: node?.name,
        type: node?.type,
        status: node?.status || '',
        region: node?.region || '',
        addedOn: node?.addedOnTimestamp || '',
        datasourceOwner: node?.datasourceOwner || '',
        datasourceType: node?.datasourceType || '',
        datasourceId: node?.datasourceId || '',
        isRetry: node?.isRetry || false,
        isDelete: node?.isDelete || false,
        errorDescription: node?.errorDescription || '',
        cloudAccount: node?.cloudAccount || {},
        cloudPlatform: node?.cloudPlatform || {}
      })) || []

    return { list }
  } catch (e) {
    return { list: [] }
  }
}

//Total Cloud resources
export const queryCloudResourcesCount = (params: FetchCloudResourcesParams): string => {
  const { filters, page = 1 } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  let paramStr = ''
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const booleanFilterString =
    filters && filters.booleanFilter ? parameterizeArrayofObjects(filters.booleanFilter) : ''

  if (filterString) {
    paramStr += `, filter: ${filterString}`
  }
  if (booleanFilterString) {
    paramStr += `, booleanFilter: ${booleanFilterString} `
  }
  if (paramStr) {
    paramStr = `(first: ${LIMIT_DEFAULT}${paramStr},after:"${cursor}")`
  }
  return gql`
    {
      cloudResource${paramStr}{
        count
      }
    }

        `
}
export const mapQueryCloudResourcesCount = (
  queryResponse: any
): CloudResourcesCountResponseType => {
  try {
    const total = queryResponse?.cloudResource?.count || 0

    return { total }
  } catch (e) {
    return { total: 0 }
  }
}

// Fetch Cloud Platforms
export const queryCloudPlatformsList = (params: FetchCloudPlatformsParams): string => {
  const { filters } = params
  let paramStr = ''
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  if (filterString) {
    paramStr += `filter: ${filterString}`
  }

  if (paramStr) {
    paramStr = `(${paramStr})`
  }

  return gql`
    {
      cloudPlatform${paramStr} {
        edges {
          node {
            id
            name
            workflowStatus
            platformType
            lastSyncedOn
            owner
          }
        }
      }
    }
  `
}
export const mapQueryCloudPlatformsList = (queryResponse: any): CloudPlatformsListResponseType => {
  try {
    const list: CloudPlatformsListObject[] =
      queryResponse?.cloudPlatform?.edges.map(({ node }) => ({
        id: node?.id,
        name: node?.name,
        platformType: node?.platformType,
        workflowStatus: node?.workflowStatus,
        lastSyncedOn: node?.lastSyncedOn,
        owner: node?.owner
      })) || []
    return { list }
  } catch (e) {
    return { list: [] }
  }
}

export const queryCloudPlatformById = ({ cloudPlatformId }: FetchCloudPlatformByIdParams) => {
  return gql`
    {
      cloudPlatform(
        first: 1
        after: ""
        filter: [{ key: ID, values: ["${cloudPlatformId}"] }]
      ) {
        edges {
          node {
            id
            name
            description
            connectionScope
            platformType
            owner
            coOwner
            frequency {
                type
                value
            }
            autoScanDatasources
            updateTimestamp
            scanConditions {
              objectValues
            }
          }
        }
      }
    }
  `
}
export const mapQueryCloudPlatformById = (queryResponse: any): CloudPlatformByIdResponseType => {
  const platformEdge = queryResponse.cloudPlatform.edges[0]
  const platformNode = platformEdge.node

  return {
    id: platformNode.id,
    name: platformNode.name,
    platformType: platformNode.platformType,
    owner: platformNode.owner,
    coOwner: platformNode.coOwner ?? '',
    description: platformNode.description ?? '',
    connectionScope: platformNode.connectionScope,
    frequency: platformNode.frequency,
    autoScanDatasources: platformNode.autoScanDatasources,
    updateTimestamp: platformNode.updateTimestamp,
    gcpProjectIds: platformNode.scanConditions?.[0]?.objectValues ?? []
  }
}

export const queryGcpProjectsList = ({ serviceAccountKey }: QueryGcpProjectListParams) => {
  return gql`
    query {
      cloudPlatformProject(
        type: GCP
        ${serviceAccountKey ? `serviceAccountKey: "${serviceAccountKey}"` : ''}
      ) {
        id
        name
      }
    }
  `
}
export const mapQueryGcpProjectsList = (queryResponse: any): GcpProjectsListObject[] => {
  try {
    const projects: GcpProjectsListObject[] =
      queryResponse?.cloudPlatformProject?.map(({ id, name }) => ({
        id,
        name
      })) || []
    return projects
  } catch (e) {
    return []
  }
}

// Fetch Cloud Accounts
export const queryCloudAccountsList = (params: FetchCloudPlatformsParams): string => {
  const { filters, page = 1 } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  let paramStr = ''
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const booleanFilterString =
    filters && filters.booleanFilter ? parameterizeArrayofObjects(filters.booleanFilter) : ''

  if (filterString) {
    paramStr += `, filter: ${filterString}`
  }
  if (booleanFilterString) {
    paramStr += `, booleanFilter: ${booleanFilterString} `
  }
  if (paramStr) {
    paramStr = `(first: ${LIMIT_DEFAULT}${paramStr},after:"${cursor}")`
  }

  return gql`
  {
    cloudAccount${paramStr}{
      edges {
        node {
          id
          name
          accountId
          registeredResourceCount
          unRegisteredResourceCount
          datasourceCount
          uniqueDatasourceTypes
        }
      }
    }
  }
  `
}
export const mapQueryCloudAccountsList = (queryResponse: any): CloudAccountsListResponseType => {
  try {
    const list: CloudAccountsListObject[] =
      queryResponse?.cloudAccount?.edges.map(({ node }) => ({
        id: node?.id,
        name: node?.name,
        accountId: node?.accountId || '',
        registeredResourceCount: node?.registeredResourceCount,
        unRegisteredResourceCount: node?.unRegisteredResourceCount,
        datasourceCount: node?.datasourceCount,
        uniqueDatasourceTypes: node?.uniqueDatasourceTypes || []
      })) || []
    return { list }
  } catch (e) {
    return { list: [] }
  }
}

// Fetch Cloud Regions
export const queryRegionsList = (params: FetchRegionsListParams): string => {
  const { type } = params
  const paramStr = `(type: ${type})`
  return gql`
  {
    cloudPlatformRegion${paramStr}{
      code
    }
  }
  `
}
export const mapQueryRegionsList = (queryResponse: any): RegionListResponseType => {
  try {
    const list: RegionListObject[] = queryResponse?.cloudPlatformRegion || []
    return { list }
  } catch (e) {
    return { list: [] }
  }
}

// Fetch Resource Type Filter List
export const queryResourceTypeFilterList = (params: FetchResourceTypeFilterListParams): string => {
  const { type, filters } = params
  let paramStr = ''
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const booleanFilterString =
    filters && filters.booleanFilter ? parameterizeArrayofObjects(filters.booleanFilter) : ''

  if (filterString) {
    paramStr += `, filter: ${filterString}`
  }
  if (booleanFilterString) {
    paramStr += `, booleanFilter: ${booleanFilterString} `
  }
  if (paramStr) {
    paramStr = `(type: ${type}${paramStr})`
  }
  return gql`
  {
    cloudResourceType${paramStr} {
      name
    }
  }
  `
}
export const mapQueryResourceTypeFilterList = (
  queryResponse: any
): ResourceListFilterResponseType => {
  try {
    const list: ResourceTypeFilterListObject[] = queryResponse?.cloudResourceType || []
    return { list }
  } catch (e) {
    return { list: [] }
  }
}

// Register Cloud Platform
export const mutationRegisterCloudPlatform = (params: RegisterCloudPlatformParams) => {
  const {
    name,
    ownerName,
    coOwnerName,
    awsCloudAccountId,
    frequencyUnit,
    frequencyAmount,
    awsAccessKey,
    awsSecretKey,
    awsSessionKey,
    clientMutationId,
    autoScanDatasources,
    cloudPlatformType,
    gcpServiceAccountKey,
    gcpProjectIds = []
  } = params

  let cloudPlatformSpecificFields = ''

  if (cloudPlatformType === CLOUD_PLATFORM_TYPE.AWS) {
    cloudPlatformSpecificFields = `
          cloudAccountId: "${awsCloudAccountId}"
          awsCredentials: {
            accessKey: "${awsAccessKey}"
            secretKey: "${awsSecretKey}"
            sessionKey: "${awsSessionKey}"
          }
          connectionScope: ACCOUNT`
  } else if (cloudPlatformType === CLOUD_PLATFORM_TYPE.GCP) {
    cloudPlatformSpecificFields = `
          gcpCredentials: {
            serviceAccountKey: "${gcpServiceAccountKey}"
          }
          connectionScope: ORGANIZATION
          scanConditions: [
            {
                filterType: INCLUDE
                objectType: PROJECT
                objectValues: ${JSON.stringify(gcpProjectIds)}
            }
          ]`
  }

  return gql`
    mutation {
      registerCloudPlatform(
        actionInput: {
          clientMutationId: "${clientMutationId}"
          name: "${name}"
          owner: "${ownerName}"
          coOwner: "${coOwnerName}"
          platformType: ${cloudPlatformType}
          frequency: { type: ${frequencyUnit}, value: ${frequencyAmount} }
          autoScanDatasources: [${autoScanDatasources}]
          ${cloudPlatformSpecificFields}
        }
      ) {
        clientMutationId
      }
    }
  `
}

export const mutationUpdateCloudPlatform = (params: UpdateCloudPlatformParams) => {
  let cloudPlatformSpecificFields = ''

  if (params.cloudPlatformType === CLOUD_PLATFORM_TYPE.GCP) {
    cloudPlatformSpecificFields = `
      scanConditions: [
        {
            filterType: INCLUDE
            objectType: PROJECT
            objectValues: ${JSON.stringify(params.gcpProjectIds)}
        }
      ]
    `
  }

  return gql`
    mutation {
      updateCloudPlatform(
        actionInput: {
          clientMutationId: "${params.clientMutationId}"
          cloudPlatformId: "${params.cloudPlatformId}"
          name: "${params.name}"
          owner: "${params.ownerName}"
          coOwner: "${params.coOwnerName}"
          description: "${params.description}"
          platformType: ${params.cloudPlatformType}
          frequency: { type: ${params.frequencyUnit}, value: ${params.frequencyAmount} }
          autoScanDatasources: ${params.autoScanDatasources}
          ${cloudPlatformSpecificFields}
        }
      ) {
        clientMutationId
      }
    }
  `
}

export const mutationVerifyCloudPlatformCreds = (params: VerifyCloudPlatformCredsParams) => {
  const { clientMutationId, cloudPlatformType } = params

  let credentialsPayload = ''

  if (cloudPlatformType === CLOUD_PLATFORM_TYPE.AWS) {
    credentialsPayload = `
      awsCredentials: {
        accessKey: "${params.accessKey}"
        secretKey: "${params.secretKey}"
        accountId: "${params.accountId}"
      }
    `
  } else if (cloudPlatformType === CLOUD_PLATFORM_TYPE.GCP) {
    credentialsPayload = `
      gcpCredentials: {
        serviceAccountKey: "${params.serviceAccountKey}"
      }
    `
  }

  return gql`
    mutation {
      testCloudPlatformCreds(
        actionInput: {
          clientMutationId: "${clientMutationId}"
          platformType: ${cloudPlatformType}
          ${credentialsPayload}
        }
      ) {
        status,
        clientMutationId
      }
    }
  `
}

//Delete Cloud Resource
export const mutationDeleteCloudResource = ({
  clientMutationId,
  cloudResourceId
}: DeleteCloudResourceParams) => {
  return gql`
    mutation {
      deleteCloudResource(
        actionInput: { clientMutationId: "${clientMutationId}", cloudResourceId: "${cloudResourceId}" }
      ) {
        clientMutationId
      }
    }
  `
}

//Refresh Cloud Platform Config
export const mutationRefreshCloudPlatform = ({
  clientMutationId,
  cloudPlatformId
}: RefreshCloudPlatformParams) => {
  return gql`
    mutation {
      refreshCloudPlatform(
        actionInput: { clientMutationId: "${clientMutationId}", cloudPlatformId: "${cloudPlatformId}" }
      ) {
        clientMutationId
      }
    }
  `
}
