import {
  mapQueryDsrDefaultDatasources,
  mapQueryDsrSettings,
  mutationSaveDsrDefaultAssignee,
  mutationSaveDsrDefaultDatasources,
  mutationSaveDsrFormPlaceholder,
  mutationSaveDsrNotificationEmails,
  queryDsrSettings,
  queryGetDsrDefaultDatasources
} from './queries'

import graphqlService from '../../../services/graphqlService'
import { API_DSR_REQUEST_TYPES } from '../../../constants'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const ACTION_DSR_SETTINGS = 'dsrSettings/fetch'
export const fetchDsrSettings = createAsyncThunk(ACTION_DSR_SETTINGS, async () => {
  const settingsRaw = await graphqlService.execute(queryDsrSettings())
  return mapQueryDsrSettings(settingsRaw)
})

export const ACTION_DSR_PLACEHOLDER_SAVE = 'dsrSettings/save-placeholder-text'
export const saveDsrFormPlaceholder = createAsyncThunk(
  ACTION_DSR_PLACEHOLDER_SAVE,
  async (text: string) => {
    await graphqlService.execute(mutationSaveDsrFormPlaceholder(text))
  }
)
export const ACTION_DSR_NOTIFICATION_EMAIL_SAVE = 'dsrSettings/notification/email'
export const saveDsrNotificationEmails = createAsyncThunk(
  ACTION_DSR_NOTIFICATION_EMAIL_SAVE,
  async (notificationsEmails: string[]) => {
    await graphqlService.execute(mutationSaveDsrNotificationEmails(notificationsEmails))
  }
)

export const ACTION_DSR_DEFAULT_ASSIGNEE_SAVE = 'dsrSettings/defaultAssignee/save'
export const saveDsrDefaultAssignee = createAsyncThunk(
  ACTION_DSR_DEFAULT_ASSIGNEE_SAVE,
  async (assigneeValues) => {
    await graphqlService.execute(mutationSaveDsrDefaultAssignee(assigneeValues))
  }
)

export const ACTION_DSR_DEFAULT_DATASOURCES_QUERY = 'dsrSettings/defaultDatasources/query'
export const queryDsrDefaultDatasources = createAsyncThunk(
  ACTION_DSR_DEFAULT_DATASOURCES_QUERY,
  async () => {
    const raw = await graphqlService.execute(queryGetDsrDefaultDatasources())
    return mapQueryDsrDefaultDatasources(raw)
  }
)

export const ACTION_DSR_DEFAULT_DATASOURCES_SAVE = 'dsrSettings/defaultDatasources/save'
export const saveDsrDefaultDatasources = createAsyncThunk(
  ACTION_DSR_DEFAULT_DATASOURCES_SAVE,
  async (updatedDefaultDatasources: string[]) => {
    await graphqlService.execute(mutationSaveDsrDefaultDatasources(updatedDefaultDatasources))
  }
)

export type DsrDefaultAssignee = {
  requestType: API_DSR_REQUEST_TYPES
  assigneeEmail: string
}

export interface DefaultAssigneeFormValues {
  [API_DSR_REQUEST_TYPES.ACCESS]?: string
  [API_DSR_REQUEST_TYPES.ERASURE]?: string
  [API_DSR_REQUEST_TYPES.RECTIFICATION]?: string
  [API_DSR_REQUEST_TYPES.DO_NOT_SELL]?: string
  [API_DSR_REQUEST_TYPES.OTHER]?: string
}

export type DsrSettingsDefaultDatasource = {
  id: string
  name: string
  type: string
  owner: string
}

/** Initial state */
type DsrSettingsState = {
  formPlaceholderText?: string
  notificationEmails: string[]
  defaultAssignee?: DefaultAssigneeFormValues
  defaultDatasources?: DsrSettingsDefaultDatasource[]
  includedDatasourcesInDsrFlow?: string[]
}

export const initialState: DsrSettingsState = { notificationEmails: [] }

const dsrSettingsSlice = createSlice({
  name: 'dsrSettings',
  initialState,
  reducers: {
    resetDsrFormPlaceholderText: (state) => {
      state.formPlaceholderText = initialState.formPlaceholderText
    },
    setIncludedDatasourcesInDsrFlow: (state, action) => {
      state.includedDatasourcesInDsrFlow = action.payload || []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDsrSettings.fulfilled, (state, { payload }) => {
      state.formPlaceholderText = payload.placeholderText
      state.notificationEmails = payload.notificationEmails
      state.defaultAssignee = payload.defaultAssignee
    })
    builder.addCase(queryDsrDefaultDatasources.fulfilled, (state, { payload }) => {
      state.defaultDatasources = payload.defaultDatasources
      state.includedDatasourcesInDsrFlow = payload.includedDatasourcesInDsrFlow
    })
  }
})

export const {
  resetDsrFormPlaceholderText,
  setIncludedDatasourcesInDsrFlow
} = dsrSettingsSlice.actions
export default dsrSettingsSlice.reducer
