import { IBlob, IGetBLOBSParams, IGetBlocksParams } from './blobSlice'
import { FilterParams } from '../../interfaces'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { DATASOURCES_LIMIT_DEFAULT, LIMIT_DEFAULT } from '../../constants'
import { NameIdSummary } from '../ropa/ropaSlice'
import { gql } from 'graphql-request'

export const queryBLOBSGrouped = ({ filters }: IGetBLOBSParams): string => {
  let filter = ''
  let booleanFilter = ''
  if (filters?.filter?.length) {
    filter = `filter: ${parameterizeArrayofObjects(filters.filter)},`
  }
  if (filters?.booleanFilter?.length) {
    booleanFilter = `booleanFilter: ${parameterizeArrayofObjects(filters.booleanFilter)}`
  }
  return gql`
    {
      objectGroupByParent(${filter}${booleanFilter}){
        count
        edges {
          node {
            id
            name
            entities {
              count
            }
            attribute {
              count
            }
            attributeInstance {
              count
            }
            objects(category: BLOB) {
              count
            }
            piiBlobs: objects(category: BLOB, isSensitive: true){
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryBLOBSGrouped = (raw: any): { list: IBlob[]; total: number } => {
  try {
    const list =
      raw.objectGroupByParent?.edges?.map(({ node: obj }) => ({
        id: obj?.id || '',
        name: obj?.name || '',
        attribuesCount: obj?.attribute?.count || 0,
        entitiesCount: obj?.entities?.count || 0,
        attribueInstancesCount: obj?.attributeInstance?.count || 0,
        blobsCount: obj?.objects?.count || 0,
        blobsWithPIICount: obj?.piiBlobs?.count || 0
      })) || []

    return {
      list,
      total: list.length
    }
  } catch (error) {
    console.error(error)
    return {
      list: [],
      total: 0
    }
  }
}

export const queryBLOBS = ({
  datasourceId,
  fileCategory,
  page,
  pageSize = LIMIT_DEFAULT,
  filters
}: IGetBLOBSParams): string => {
  const cursor = getAfterCursor(page || 1, pageSize)
  let filter = ''
  let booleanFilter = ''
  if (filters?.filter?.length) {
    filter = `, filter: ${parameterizeArrayofObjects(filters.filter)}`
  }
  if (filters?.booleanFilter?.length) {
    booleanFilter = `, booleanFilter: ${parameterizeArrayofObjects(filters.booleanFilter)}`
  }

  const fileCategoryQuery = fileCategory ? 'category: ' + fileCategory : ''

  return gql`
    {
      objects(first: ${pageSize}, after: "${cursor}", ${fileCategoryQuery}, datasourceIds: ["${datasourceId}"]${filter}${booleanFilter}) {
        count
        edges {
          node {
            id
            name
            sharedOn
            objectLink
            metadata {
              ... on ServiceNowMetadata {
                incidentNumber
              }
            }
            entity {
              count
            }
            attribute {
              count
            }
            attributeInstance {
              count
            }
          }
        }
      }
    }
    `
}
export const mapQueryBLOBS = (raw: any): { list: IBlob[]; total: number } => {
  try {
    const list =
      raw.objects?.edges?.map(({ node: obj }) => ({
        id: obj?.id || '',
        name: obj?.name || '',
        objectLink: obj?.objectLink || '',
        metadataId: obj?.metadata?.incidentNumber || '',
        attribuesCount: obj?.attribute?.count || 0,
        entitiesCount: obj?.entity?.count || 0,
        attribueInstancesCount: obj?.attributeInstance?.count || 0,
        blobsCount: obj?.objects?.count || 0,
        sharedWhen: obj?.sharedOn || ''
      })) || []
    return {
      list,
      total: raw.objects.count || 0
    }
  } catch (error) {
    console.error(error)
    return {
      list: [],
      total: 0
    }
  }
}

export const queryUnstructuredBLOBSList = ({
  datasourceId,
  fileCategory,
  page,
  filters
}: IGetBLOBSParams): string => {
  const cursor = getAfterCursor(page || 1, LIMIT_DEFAULT)
  let filter = ''
  let booleanFilter = ''
  if (filters?.filter?.length) {
    filter = `, filter: ${parameterizeArrayofObjects(filters.filter)}`
  }
  if (filters?.booleanFilter?.length) {
    booleanFilter = `, booleanFilter: ${parameterizeArrayofObjects(filters.booleanFilter)}`
  }

  const fileCategoryQuery = fileCategory ? 'category: ' + fileCategory : ''

  return gql`
    {
      objects(first: ${LIMIT_DEFAULT}, after: "${cursor}", ${fileCategoryQuery}, datasourceIds: ["${datasourceId}"]${filter}${booleanFilter}) {
        edges {
          node {
            id
            name
            sharedOn
            objectLink
            metadata {
              ... on ServiceNowMetadata {
                incidentNumber
              }
            }
            entity {
              count
            }
            attribute {
              count
            }
            attributeInstance {
              count
            }
          }
        }
      }
    }
    `
}
export const mapQueryUnstructuredBLOBSList = (raw: any): { list: IBlob[] } => {
  try {
    const list =
      raw.objects?.edges?.map(({ node: obj }) => ({
        id: obj?.id || '',
        name: obj?.name || '',
        objectLink: obj?.objectLink || '',
        metadataId: obj?.metadata?.incidentNumber || '',
        attribuesCount: obj?.attribute?.count || 0,
        entitiesCount: obj?.entity?.count || 0,
        attribueInstancesCount: obj?.attributeInstance?.count || 0,
        blobsCount: obj?.objects?.count || 0,
        sharedWhen: obj?.sharedOn || ''
      })) || []
    return {
      list
    }
  } catch (error) {
    console.error(error)
    return {
      list: []
    }
  }
}

export const queryUnstructuredBLOBSCount = ({
  datasourceId,
  fileCategory,
  page,
  filters
}: IGetBLOBSParams): string => {
  const cursor = getAfterCursor(page || 1, LIMIT_DEFAULT)
  let filter = ''
  let booleanFilter = ''
  if (filters?.filter?.length) {
    filter = `, filter: ${parameterizeArrayofObjects(filters.filter)}`
  }
  if (filters?.booleanFilter?.length) {
    booleanFilter = `, booleanFilter: ${parameterizeArrayofObjects(filters.booleanFilter)}`
  }

  const fileCategoryQuery = fileCategory ? 'category: ' + fileCategory : ''

  return gql`
    {
      objects(first: ${LIMIT_DEFAULT}, after: "${cursor}", ${fileCategoryQuery}, datasourceIds: ["${datasourceId}"]${filter}${booleanFilter}) {
        count
      }
    }
    `
}
export const mapQueryUnstructuredBLOBSCount = (raw: any): { total: number } => {
  try {
    return {
      total: raw.objects.count || 0
    }
  } catch (error) {
    console.error(error)
    return {
      total: 0
    }
  }
}

// for structured datasources
export const queryStructuredBLOBS = ({
  datasourceId,
  fileCategory,
  page,
  pageSize = LIMIT_DEFAULT,
  filters,
  searchQuery
}: IGetBLOBSParams): string => {
  const cursor = getAfterCursor(page || 1, pageSize)
  let filter = ''
  let booleanFilter = ''
  if (filters?.filter?.length) {
    filter = `, filter: ${parameterizeArrayofObjects(filters.filter)}`
  }
  if (filters?.booleanFilter?.length) {
    booleanFilter = `, booleanFilter: ${parameterizeArrayofObjects(filters.booleanFilter)}`
  }
  const fileCategoryQuery = fileCategory ? 'category: ' + fileCategory : ''
  fileCategoryQuery
  const searchParamString = searchQuery ? `, query: "${searchQuery}", ` : ''

  return gql`
    {
      datasources(id: "${datasourceId}") {
        edges {
          node {
            id
            name
            columns(
              first: ${pageSize},
              after: "${cursor}",
              sortField: TABLE_ID
              sortByAsc: true
              ${filter},
              ${booleanFilter}
              ${searchParamString}
            ) {
              count
              edges {
                node {
                  id
                  name
                  blobScanStatus
                  blobCell(first: 1) {
                    count
                  }
                  qualifiedName
                  attributeInstance {
                    count
                  }
                  table {
                    edges {
                      node {
                        name
                      }
                    }
                  }
                  database {
                    edges {
                      node {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryStructuredBLOBS = (raw: any): { list: IBlob[]; total: number } => {
  try {
    const list =
      raw.datasources?.edges[0].node?.columns?.edges?.map(({ node: column }) => ({
        id: column?.id || '',
        name: column?.name || '',
        tableName: column?.table?.edges[0].node?.name || '',
        databaseName: column.database.edges[0]?.node.name,
        qualifiedName: column?.qualifiedName || '',
        blobScanStatus: column.blobScanStatus || '',
        blobCellCount: column.blobCell.count || 0,
        attribueInstancesCount: column?.attributeInstance?.count || 0
      })) || []
    const result = {
      list,
      total: raw.datasources?.edges[0].node?.columns?.count || 0
    }
    return result
  } catch (error) {
    console.error(error)
    return {
      list: [],
      total: 0
    }
  }
}

export const queryBLOBSParentsSummary = (params: FilterParams): string => {
  let filter = ''
  if (params?.filter?.length) {
    filter = `(filter: ${parameterizeArrayofObjects(params.filter)})`
  }
  return gql`
    {
      objectGroupByParent${filter} {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `
}

export const mapQueryBLOBSParentsSummary = (raw: any): { id: string; name: string }[] => {
  try {
    const list =
      raw.objectGroupByParent?.edges?.map(({ node: obj }) => ({
        id: obj?.id || '',
        name: obj?.name || ''
      })) || []
    return list
  } catch (error) {
    console.error(error)
    return []
  }
}

export const queryObjectType = (datasourceId: string): string => gql`
  {
    datasources(id: "${datasourceId}", first: 1) {
      edges {
        node {
          parentObjectType
        }
      }
    }
  }
`

export const mapQueryObjectType = (raw: any): { parentObjectType: string } => {
  try {
    return {
      parentObjectType: raw.datasources.edges[0]?.node?.parentObjectType || ''
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryBlocksList = ({ blobId, page = 1, filters }: IGetBlocksParams): string => {
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  let filter = ''
  if (filters && !!Object.values(filters).length) {
    const filtersStr = Object.entries(filters).map(([key, values]) => {
      return `{key: ${key}, values: ${JSON.stringify(values)}}`
    })
    filter += `filter: [${filtersStr}],`
  }

  return gql`
    {
      columns(id: "${blobId}") {
        edges {
          node {
            name
            blobCellCount: blobCell(first: 1,${filter}) { count }
            blobCell(first: ${LIMIT_DEFAULT}, after:"${cursor}",${filter}) {
              edges {
                node {
                  rowId
                  attributes
                }
              }
            }
          }
        }
      }
   }`
}

export const mapQueryBlocksList = (raw: any): any => {
  try {
    const list =
      raw && raw?.columns && raw?.columns?.edges
        ? raw?.columns?.edges[0]?.node?.blobCell?.edges?.map((item) => ({
            rowId: item?.node?.rowId || '',
            attributes: item?.node?.attributes
          }))
        : []
    return {
      list,
      total: raw?.columns?.edges[0]?.node?.blobCellCount?.count || 0
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDatabasesSummary = (datasourceId: string): string => {
  return gql`
    {
      datasources(first: 1, datasourceIds:["${datasourceId}"]) {
        edges {
          node {
            databases(first: ${DATASOURCES_LIMIT_DEFAULT}) {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryDatabasesSummary = (raw: any): NameIdSummary[] => {
  try {
    return (
      raw.datasources?.edges[0]?.node?.databases?.edges.map(({ node }) => ({
        id: node.id || '',
        name: node.name || ''
      })) || []
    )
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryTablesSummary = (datasourceId: string): string => {
  return gql`
    {
      datasources(first: 1, datasourceIds:["${datasourceId}"]) {
        edges {
          node {
            tables {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryTablesSummary = (raw: any): NameIdSummary[] => {
  try {
    return (
      raw.datasources?.edges[0]?.node?.tables?.edges.map(({ node }) => ({
        id: node.id || '',
        name: node.name || ''
      })) || []
    )
  } catch (error) {
    console.error(error)
    throw error
  }
}
