import {
  mapQueryTicketsCards,
  mapQueryTicketsList,
  queryTicketsCards,
  queryTicketsList,
  mapQueryEntityTicketsCards,
  queryEntityTicketsCards,
  queryTicket,
  mapQueryTicket
} from './queries'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import { SEVERITY_LEVEL } from '../../constants'
import { FilterParams } from '../../interfaces'
import graphqlService from '../../services/graphqlService'
import { RootState } from '../../rootReducer'
import { getGlobalParams } from '../../utils/urlUtil'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export type Ticket = {
  id?: string
  ticketId?: string
  projectId?: string
  projectName: string
  totalAttributes: number
  totalEntities: number
  totalObjects?: number
  totalObjectsImpacted?: number
  totalAlerts?: number
  totalTicketsWithPII?: number
  sharedWhen?: string
  ticketLink?: string
  assignee?: string
  reporter?: string
  hasAlerts?: boolean
  hasAttachments?: boolean
  attachments?: {
    id: string
    name: string
    type: string
  }[]
  entities?: {
    name: string
    id: string
  }[]
  attributes?: {
    name: string
    internalName: string
  }[]
  alerts?: {
    id: string
    name: string
    severity: SEVERITY_LEVEL
  }[]
}

export interface TicketSummary {
  comments?: {
    authorName: string
    body: string
    updateTimestamp: string
  }[]
  ticketName?: string
  ticketLink?: string
  summary?: string
  description?: string
  lastModifiedTime?: string
}

export interface FetchTicketsListParams {
  entityId?: string
  datasourceId?: string
  projectId?: string
  filters?: FilterParams
  page?: number
  pageSize?: number
}

export const ACTION_JIRA_PROJECT_CARDS_FETCH = 'jira/project/cards'
export const fetchTicketCards = createAsyncThunk(
  ACTION_JIRA_PROJECT_CARDS_FETCH,
  async (params: FetchTicketsListParams) => {
    const result = await graphqlService.execute(queryTicketsCards(params))
    return mapQueryTicketsCards(result)
  }
)

export const ACTION_JIRA_ENTITY_PROJECT_CARDS_FETCH = 'jira/entity/project/cards'
export const fetchEntityTicketCards = createAsyncThunk(
  ACTION_JIRA_ENTITY_PROJECT_CARDS_FETCH,
  async (params: FetchTicketsListParams) => {
    const result = await graphqlService.execute(queryEntityTicketsCards(params))
    return mapQueryEntityTicketsCards(result)
  }
)

export const ACTION_TICKETS_LIST_FETCH = 'tickets/list'
export const fetchTicketsList = createAsyncThunk(
  ACTION_TICKETS_LIST_FETCH,
  async (params: FetchTicketsListParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const result = await graphqlService.execute(queryTicketsList(queryParams))
    return mapQueryTicketsList(result)
  }
)

export const ACTION_JIRA_TICKET_FETCH = 'datasource/jira/ticket'
export const fetchJiraTicket = createAsyncThunk(ACTION_JIRA_TICKET_FETCH, async (id: string) => {
  const results = await graphqlService.execute(queryTicket(id))
  return mapQueryTicket(results)
})

export type TicketsListSettings = {
  list?: Ticket[]
  sort: SortParams
}

export interface ProjectAttributeInstanceInfo {
  name: string
  id: string
  count: number
}

interface TicketsState {
  cards?: Ticket[]
  list: TicketsListSettings
  ticketSummary?: TicketSummary
  totalTickets?: number
  totalProjects?: number
}

const initialTicketsList: TicketsListSettings = { sort: defaultSortParams }
export const initialState: TicketsState = {
  list: initialTicketsList
}

const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setSort: (state, { payload }) => {
      state.list.sort = getSortDirection(state.list.sort, payload.column)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTicketCards.fulfilled, (state, action) => {
      state.cards = action.payload.list
      state.totalTickets = action.payload.totalTickets
      state.totalProjects = action.payload.totalProjects
    })
    builder.addCase(fetchEntityTicketCards.fulfilled, (state, action) => {
      state.cards = action.payload.list
      state.totalTickets = action.payload.totalTickets
      state.totalProjects = action.payload.totalProjects
    })
    builder.addCase(fetchTicketsList.fulfilled, (state, action) => {
      state.list.list = action.payload.list
      state.totalTickets = action.payload.totalTickets
    })
    builder.addCase(fetchJiraTicket.fulfilled, (state, action) => {
      state.ticketSummary = action.payload.data
    })
  }
})

export const { setSort } = ticketsSlice.actions
export default ticketsSlice.reducer
