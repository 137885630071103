import { Folder, IFetchParams } from './foldersSlice'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { LIMIT_DEFAULT } from '../../constants'
import { gql } from 'graphql-request'

export const queryFoldersList = ({
  page = 1,
  pageSize = LIMIT_DEFAULT,
  filters
}: IFetchParams): string => {
  const filter = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const filterString = filter ? `, filter:${filter}` : ''
  const cursor = getAfterCursor(page, pageSize)
  return gql`
    {
      folder(first: ${pageSize}, after: "${cursor}" ${filterString}) {
        edges {
          node {
            id
            folderName
            folderId
            entities {
              count
            }
            documentCountInfo {
              totalCount
            }
            metadata {
              ... on Bucket {
                name
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryFoldersList = (raw: any): Folder[] => {
  try {
    return (
      raw.folder?.edges?.map(({ node: folder }) => ({
        id: folder.id || '',
        name: folder.folderName || '',
        path: folder.folderId || '',
        bucketName: folder.metadata?.name || '',
        totalEntities: folder.entities?.count || 0,
        objectsTotal: folder.totalCount?.count || 0
      })) || []
    )
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryFoldersCount = (datasourceId: string): string => {
  return gql`
  {
    folder(filter: {key: DATASOURCE_IDS, values: [${JSON.stringify(datasourceId)}] }){
      count
    }
  }
  `
}

export const mapQueryFoldersCount = (raw: any): number => {
  try {
    return raw.folder?.count || 0
  } catch (error) {
    console.error(error)
    throw error
  }
}
