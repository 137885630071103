import {
  queryPrivacyOpsWidgetDsrSummary,
  mapQueryPrivacyOpsWidgetDsrSummary,
  queryPrivacyOpsWidgetSummary,
  mapQueryPrivacyOpsWidgetSummary,
  queryConsentManagementWidgetSummary,
  mapConsentManagementWidgetSummary
} from './queries'
import graphqlService from '../../../../../services/graphqlService'
import apiService from '../../../../../services/api/apiService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Privacy Rights widget
export type PrivacyRightsWidget = {
  dsrOpen?: number
  dsrTotal?: number
  consentManagementOptIns?: number
  consentManagementOptOuts?: number
  consentManagementTotalConsents?: number
  cookieConsentNew?: number
  cookieConsentTotal?: number
  cookieConsentDomainsTotal?: number
}

export const ACTION_PRIVACY_RIGHTS = 'privacyOpsWidgets/rights'
export const fetchPrivacyRights = createAsyncThunk(ACTION_PRIVACY_RIGHTS, async () => {
  let privacyRights = {}

  await Promise.allSettled([
    graphqlService.execute(queryPrivacyOpsWidgetDsrSummary()),
    apiService.getCookieConsentDomainsCounters(),
    graphqlService.execute(queryConsentManagementWidgetSummary())
  ]).then((results) => {
    const [dsrResult, cookieConsentResult, consentManagementSummaryResult] = results

    if (dsrResult.status === 'fulfilled') {
      privacyRights = { ...privacyRights, ...mapQueryPrivacyOpsWidgetDsrSummary(dsrResult.value) }
    }

    if (cookieConsentResult.status === 'fulfilled') {
      const cookieConsentDomains = cookieConsentResult.value
      const cookieConsentSummary = cookieConsentDomains.reduce(
        (acc, current) => {
          return {
            cookiesCount: acc.cookiesCount + (current.cookiesCount || 0),
            newCookiesCount: acc.newCookiesCount + (current.newCookiesCount || 0)
          }
        },
        { cookiesCount: 0, newCookiesCount: 0 }
      )
      privacyRights = {
        ...privacyRights,
        consentManagementOptIns: 0,
        consentManagementOptOuts: 0,
        // TODO: App cookieConsentNew from BE
        cookieConsentNew: cookieConsentSummary.cookiesCount > 0 ? 3 : 0,
        cookieConsentTotal: cookieConsentSummary.cookiesCount,
        cookieConsentDomainsTotal: cookieConsentDomains.length
      }
    }
    if (consentManagementSummaryResult.status === 'fulfilled') {
      const consentManagementSummary = mapConsentManagementWidgetSummary(
        consentManagementSummaryResult.value
      )
      privacyRights = {
        ...privacyRights,
        consentManagementOptIns: consentManagementSummary.optInConsentsCount,
        consentManagementOptOuts: consentManagementSummary.optOutConsentsCount,
        consentManagementTotalConsents: consentManagementSummary.totalConsentsCount
      }
    }
  })

  return privacyRights
})

// Privacy Assessments widget
export type PrivacyAssessmentsWidget = {
  aiGovernanceAssessmentsNeedReview?: number
  aiGovernanceAssessmentsTotal?: number
  piaAssessmentsNeedReview?: number
  piaAssessmentsTotal?: number
  ropaProcessesNeedReview?: number
  ropaProcessesTotal?: number
}

export const ACTION_PRIVACY_ASSESSMENTS = 'privacyOpsWidgets/assessments'
export const fetchPrivacyAssessments = createAsyncThunk(ACTION_PRIVACY_ASSESSMENTS, async () => {
  // AI governance
  // TODO: add api call
  const aiGovernanceSummary = {
    aiGovernanceAssessmentsNeedReview: 2,
    aiGovernanceAssessmentsTotal: 4
  }

  // PIA and ROPA
  const raw = await graphqlService.execute(queryPrivacyOpsWidgetSummary())
  const summary = mapQueryPrivacyOpsWidgetSummary(raw)

  return {
    ...aiGovernanceSummary,
    ...summary
  }
})

interface ScannedDataState {
  privacyRights?: PrivacyRightsWidget
  privacyAssessments?: PrivacyAssessmentsWidget
}

export const initialState: ScannedDataState = {}

const privacyOpsWidgetsSlice = createSlice({
  name: 'privacyOpsWidgets',
  initialState,
  reducers: {
    resetPrivacyRights: (state) => {
      state.privacyRights = initialState.privacyRights
    },
    resetPrivacyAssessments: (state) => {
      state.privacyAssessments = initialState.privacyAssessments
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPrivacyRights.fulfilled, (state, { payload }) => {
      state.privacyRights = payload
    })
    builder.addCase(fetchPrivacyAssessments.fulfilled, (state, { payload }) => {
      state.privacyAssessments = payload
    })
  }
})

export const { resetPrivacyRights, resetPrivacyAssessments } = privacyOpsWidgetsSlice.actions
export default privacyOpsWidgetsSlice.reducer
