import './notifications.less'
import {
  ACTION_NOTIFICATIONS_LIST_COMPACT_FETCH,
  clearListCompact,
  fetchNotificationsListCompact,
  IGetListParams,
  Notification
} from './notificationsSlice'
import IconNoNotifications from '../../assets/inlineSvg/noNotifications.svg'
import { getShowLoader } from '../../reducers/requestReducer'
import {
  notificationModules,
  NOTIFICATION_MODULE,
  URL_NOTIFICATIONS,
  URL_POLICIES
} from '../../constants'
import { FilterParams } from '../../interfaces'
import { mapSeverityLevelToIcon } from '../policies/components/PolicySteps/Step3'
import { RootState } from '../../rootReducer'
import { getNotificationUrl } from '../../utils/urlUtil'
import {
  iconNameToIconFile,
  NotificationIcon
} from '../../components/NotificationIcon/NotificationIcon'
import calendar from 'dayjs/plugin/calendar'
import { Icon, Label, List, ListItem, Placeholder, Popup } from 'semantic-ui-react'
import cx from 'classnames'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import Scrollbars from 'react-custom-scrollbars'
import { FormattedMessage } from 'react-intl'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

interface IProps {
  text: JSX.Element
  showLoader: boolean
  list?: Notification[]
  fetchNotificationsListCompact: (params: IGetListParams) => void
  clearListCompact: () => void
}

dayjs.extend(calendar)

const WidgetNotifications = ({
  list,
  text,
  showLoader,
  clearListCompact,
  fetchNotificationsListCompact
}: IProps): React.ReactElement => {
  const [moduleType, setModuleType] = useState<NOTIFICATION_MODULE | undefined>()
  const [isOpen, setIsOpen] = useState(false)
  const listLoaded = list !== undefined
  const isListEmpty = listLoaded && list.length === 0
  const linkViewAll = window.location.href.includes(URL_NOTIFICATIONS)
    ? '#'
    : URL_POLICIES + URL_NOTIFICATIONS

  const loadNotifications = useCallback(() => {
    const filters: FilterParams = { filter: [] }
    moduleType && filters.filter?.push({ key: 'MODULE', values: [moduleType] })
    const params: IGetListParams = {
      ...(moduleType ? { filters } : {}),
      startDate: dayjs().subtract(1, 'week').unix()
    }
    fetchNotificationsListCompact(params)
  }, [moduleType, fetchNotificationsListCompact])

  useEffect(() => {
    moduleType && loadNotifications()
  }, [listLoaded, moduleType, loadNotifications])

  const handleClose = () => {
    clearListCompact()
    setModuleType(undefined)
    setIsOpen(false)
  }

  const handleOpenChange = () => {
    if (!isOpen) {
      loadNotifications()
      setIsOpen(true)
    } else {
      handleClose()
    }
  }

  return (
    <Popup
      basic
      className="popup-list-notifications xs-p-0"
      on="click"
      open={isOpen}
      onClose={handleClose}
      onOpen={handleOpenChange}
      closeOnEscape
      trigger={text}
      wide
      data-test-id="notifications-widget-notififcations"
    >
      <>
        <header className="xs-p-24">
          <p className="xs-m-0">
            <FormattedMessage id="notifications.quickFilter" />
          </p>
          <List className="xs-m-0">
            {notificationModules.map((module) => (
              <ListItem key={module} className="x-p-0 xs-mr-12">
                <Label
                  onClick={() => setModuleType(module)}
                  className={`${cx('xs-py-4 xs-px-8', { active: module === moduleType })}`}
                >
                  <FormattedMessage
                    id={`notifications.${module.toLocaleLowerCase().replace(/\s/g, '')}`}
                  />
                </Label>
              </ListItem>
            ))}
          </List>
        </header>
        <div className="separator" />
        <div className="notifications-summary">
          {showLoader ? (
            <Placeholder style={{ minWidth: '95%' }} className="xs-m-16">
              <Placeholder.Header image>
                <Placeholder.Line length="long" />
                <Placeholder.Line length="short" />
              </Placeholder.Header>
              <Placeholder.Header image>
                <Placeholder.Line length="long" />
                <Placeholder.Line length="short" />
              </Placeholder.Header>
            </Placeholder>
          ) : isListEmpty ? (
            <div className="no-results xs-p-16">
              <IconNoNotifications />
              <FormattedMessage id="text.noNotifications"></FormattedMessage>
            </div>
          ) : (
            <Scrollbars autoHeight autoHeightMax={'50vh'} autoHide hideTracksWhenNotNeeded>
              <List className="list-notifications">
                {list?.map(({ title, updateTimestamp, module, icon }, i) => (
                  <ListItem
                    className="separator xs-p-16"
                    key={title + i}
                    onClick={() => setIsOpen(false)}
                  >
                    <Link to={getNotificationUrl(module)}>
                      {icon ? (
                        <NotificationIcon>{iconNameToIconFile(icon)}</NotificationIcon>
                      ) : (
                        <div className="module-thumbnail xs-mr-16">
                          {module.severity && mapSeverityLevelToIcon[module.severity]}
                        </div>
                      )}
                      <div>
                        <p className="title xs-m-0">{title}</p>
                        <p className="date-time">{dayjs(updateTimestamp).calendar(undefined)}</p>
                      </div>
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Scrollbars>
          )}
        </div>
        <div className="separator" />
        <footer className="xs-p-24">
          <p className="legend xs-mt-0">
            <FormattedMessage id="notifications.footer.text" />
          </p>
          <p className="link-details xs-m-0">
            <a href={linkViewAll}>
              <FormattedMessage id="notifications.viewAll" />{' '}
              <Icon name="chevron right" className="xs-ml-8" />
            </a>
          </p>
        </footer>
      </>
    </Popup>
  )
}

const mapDispatchToProps = {
  fetchNotificationsListCompact,
  clearListCompact
}

const mapStateToProps = (state: RootState) => {
  return {
    list: state.notifications.listCompact,
    showLoader: getShowLoader(ACTION_NOTIFICATIONS_LIST_COMPACT_FETCH)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetNotifications)
