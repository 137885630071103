import { Typography, Button as LbButton } from '@lightbeamai/design-system'
import React from 'react'
import { Header, Button, Modal, StrictModalProps } from 'semantic-ui-react'

interface IProps {
  isOpened: boolean
  title: string | React.ReactNode
  submitText: string | React.ReactNode
  className?: string
  children?: React.ReactNode
  size?: StrictModalProps['size']
  isSubmitDisabled?: boolean
  onSubmit: () => void
  onClose: () => void
}

const ModalTemplate = (props: IProps): React.ReactElement => {
  const {
    title,
    isOpened,
    className,
    children,
    submitText,
    size = 'small',
    isSubmitDisabled = false,
    onSubmit,
    onClose
  } = props

  const submitBtnParams = {
    className: 'btn-save',
    onClick: onSubmit,
    'data-test-id': 'modal-button-button-submit',
    content: submitText
  }

  return (
    <Modal basic open={isOpened} size={size} data-test-id="modals-modal-template">
      <Modal.Content>
        <div className={`modal-container ${className}`}>
          <Header>
            <Typography as="span" variant="basePlus2" className="font-600">
              {title}
            </Typography>
            <Button className="btn-close" onClick={onClose} data-test-id="modal-button-close">
              &#10005;
            </Button>
          </Header>
          <div className="body">{children}</div>
          <div className="footer">
            {isSubmitDisabled ? (
              <LbButton disabled {...submitBtnParams}>
                {submitBtnParams.content}
              </LbButton>
            ) : (
              <LbButton variant="primary" {...submitBtnParams}>
                {submitBtnParams.content}
              </LbButton>
            )}
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default ModalTemplate
