import {
  Alert,
  AlertCritical,
  AlertsByDatabaseParams,
  AlertsList,
  AlertsSummaryParams,
  AlertStaled,
  DriveAlertsParams,
  IGetAlertsListByBucketParams,
  IGetAlertsListByEntityParams
} from './alertsSlice'
import {
  AlertStatuses,
  ALERT_STATUS,
  API_FILTER_ALERTS,
  DATABASE_ID,
  DATA_SOURCE_ID,
  EMPTY_CONTENT,
  ENTITY_ID,
  GRAPHQL_API_FILTERS,
  LIMIT_DEFAULT,
  PAGE,
  SEVERITY_LEVEL,
  PAGE_SIZE
} from '../../constants'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { formatDateTime } from '../../utils/timeUtil'
import { gql } from 'graphql-request'

export const queryAlertsListByEntity = (params: IGetAlertsListByEntityParams): string => {
  const cursor = getAfterCursor(params[PAGE], params.pageSize || LIMIT_DEFAULT)
  const optionalFilters = params.datasourceId ? `, datasourceId: "${params.datasourceId}"` : ''
  const alertFilters = params.datasourceId ? `, datasourceIds: "${params.datasourceId}"` : ''

  return gql`
  {
    userEntities(id: "${params[ENTITY_ID]}"${optionalFilters}) {
      edges {
        node {
          alerts(first: ${params.pageSize || LIMIT_DEFAULT}, after: "${cursor}", status: ${
    ALERT_STATUS.active
  } ${alertFilters}) {
            count
            edges {
              node {
                id
                objectCount
                name
                generatedAt
                severity
                assignees
                datasource{
                  edges{
                    node{
                      id
                      type
                    }
                  }
                }
                policy {
                  edges {
                    node {
                      policyType {
                        edges {
                          node {
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `
}

export const mapQueryAlertsListByEntity = (raw: any): AlertsList => {
  const list = raw.userEntities.edges[0].node.alerts.edges.map(({ node: alert }) => ({
    objectsCount: alert.objectCount || 0,
    assignee: alert.assignees[0] || '',
    policyType: alert.policy?.edges[0]?.node?.policyType?.edges[0]?.node?.name || '',
    datasource: alert.datasource?.edges[0]?.node || {},
    generatedAt: alert.generatedAt,
    severity: alert.severity,
    alertId: alert.id,
    alertName: alert.name
  }))

  return { list, total: raw.userEntities.edges[0].node.alerts.count }
}

export const queryAlertsListCompactByEntity = (params: IGetAlertsListByEntityParams): string => {
  const optionalFilters = params.datasourceId ? `, datasourceId: "${params.datasourceId}"` : ''
  const alertFilter = params.datasourceId ? `,datasourceIds: "${params.datasourceId}"` : ''

  return gql`
  {
    userEntities(id: "${params[ENTITY_ID]}"${optionalFilters}) {
      edges {
        node {
          alerts(first: 20, status: ${ALERT_STATUS.active} ${alertFilter} ) {
            count
            edges {
              node {
                id
                name
                generatedAt
                status
                severity
              }
            }
          }
        }
      }
    }
  }
  `
}

export const mapQueryAlertsListCompactByEntity = (raw: any): AlertsList => {
  const list =
    raw.userEntities.edges[0]?.node?.alerts?.edges.map(({ node: alert }) => ({
      generatedAt: alert.generatedAt,
      status: alert.status,
      severity: alert.severity,
      alertId: alert.id,
      alertName: alert.name
    })) || []

  return { list, total: raw.userEntities.edges[0].node.alerts.count }
}

export const queryAlertsListByBucket = (params: IGetAlertsListByBucketParams): string => {
  const cursor = getAfterCursor(params[PAGE], params[PAGE_SIZE] || LIMIT_DEFAULT)
  const alertFilters = params.datasourceId ? `, datasourceIds: ["${params.datasourceId}"]` : ''
  let filters = ''
  const filterString = parameterizeArrayofObjects(params.filters?.filter || [])
  if (filterString) {
    filters += `,filter:${filterString}`
  }
  return gql`
  {
    alert(first: ${
      params[PAGE_SIZE] || LIMIT_DEFAULT
    }, after: "${cursor}"${alertFilters}${filters}) {
      count
      edges {
        node {
          id
          name
          policy{
            edges{
              node{
                policyType {
                  edges {
                    node {
                      name
                    }
                  }
                }
              }
            }
          }
          assignees
          lastOccurred
          severity
        }
      }
    }
  }
  `
}

export const queryAlertsListCompactByBucket = (params: IGetAlertsListByBucketParams): string => {
  const alertFilters = params.datasourceId ? `, datasourceIds: ["${params.datasourceId}"]` : ''
  let filters = ''
  const filterString = parameterizeArrayofObjects(params.filters?.filter || [])
  if (filterString) {
    filters += `,filter:${filterString}`
  }
  return gql`
  {
    alert(first: 20, ${alertFilters}${filters}) {
      count
      edges {
        node {
          id
          name
          lastOccurred
          severity
        }
      }
    }
  }
  `
}

export const mapQueryAlertsListByBucket = (raw: any): AlertsList => {
  const list =
    raw.alert.edges.map(({ node }) => ({
      generatedAt: node.lastOccurred,
      status: node.status,
      severity: node.severity,
      alertId: node.id,
      alertName: node.name,
      assignee: node.assignees[0] || EMPTY_CONTENT
    })) || []

  return { list, total: raw.alert.count }
}

export const queryStaledAlertsSummary = (params: AlertsSummaryParams): string => {
  const dataSourceFilter = params[DATA_SOURCE_ID] ? `datasourceId: "${params[DATA_SOURCE_ID]}"` : ''
  let filters = ''
  const filterString = parameterizeArrayofObjects(params.filters?.filter || [])
  if (filterString) {
    filters += `,filter:${filterString}`
  }

  return gql`
    {
      alertSummary(${dataSourceFilter} ${filters}) {
        range
        value
      }
    }
  `
}

// TODO: add type for graphql response
export const mapQueryStaledAlertsSummary = (raw: any): AlertStaled[] => {
  return raw.alertSummary.map((a) => ({
    name: a.range,
    value: a.value
  }))
}

export const queryAlertsCount = (params: AlertsSummaryParams): string => {
  const basicQuery = gql`
    {
      alertsActive: alert(first: 1, status: ACTIVE_STATUS) {
        count
      }
      alerts: alert(first: 1) {
        count
      }
    }
  `
  const query = params[DATA_SOURCE_ID]
    ? `{
      datasources(id: "${params[DATA_SOURCE_ID]}"){
        edges {
          node {
            ${basicQuery}
          }
        }
      }
    }`
    : basicQuery

  return gql`
    ${query}
  `
}

// TODO: add type for graphql response
export const mapQueryAlertsCount = (raw: any): { total: number; active: number } => {
  const root = raw.datasources ? raw.datasources.edges[0].node : raw

  return { total: root.alerts.count, active: root.alertsActive.count }
}

export const queryCriticalAlerts = (params: AlertsSummaryParams): string => {
  const dataSourceFilter = params[DATA_SOURCE_ID]
    ? `,datasourceIds: "${params[DATA_SOURCE_ID]}"`
    : ''
  let filters = ''
  const filterString = parameterizeArrayofObjects(params.filters?.filter || [])
  if (filterString) {
    filters += `,filter:${filterString}`
  }

  return gql`
    {
      alert(first: ${LIMIT_DEFAULT}, severity: ${SEVERITY_LEVEL.critical}${dataSourceFilter}${filters}) {
        edges {
          node {
            id
            name
            status
            severity
            generatedAt
            assignees
            datasource{
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  `
}

// TODO: add type for graphql response
export const mapQueryCriticalAlerts = (raw: any): AlertCritical[] => {
  return raw.alert.edges.map(({ node: alert }) => ({
    id: alert.id,
    name: alert.name,
    status: alert.status,
    severity: alert.severity,
    assignees: alert.assignees,
    generatedAt: alert.generatedAt,
    dataSourceId: alert.datasource?.edges[0]?.node?.id || ''
  }))
}

export const queryCriticalDatabaseAlerts = (params: AlertsSummaryParams): string => {
  return gql`
    {
      alert(
        first: 1,
        severity: ${SEVERITY_LEVEL.critical},
        filter: [
        { key: ${GRAPHQL_API_FILTERS.databaseIds}, values: ["${params[DATABASE_ID]}"] },
        { key: ${API_FILTER_ALERTS.STATUS}, values: "${AlertStatuses.active}" }
      ]) {
        edges {
          node {
            id
            name
            status
            severity
            generatedAt
            datasource{
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  `
}

// TODO: add type for graphql response
export const mapQueryCriticalDatabaseAlerts = (raw: any): AlertCritical[] => {
  try {
    return raw.alert.edges.map(({ node: alert }) => ({
      id: alert.id,
      name: alert.name,
      status: alert.status,
      severity: alert.severity,
      generatedAt: alert.generatedAt,
      assignees: alert.assignees || [],
      dataSourceId: alert.datasource?.edges[0]?.node?.id || ''
    }))
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDriveAlerts = (params: DriveAlertsParams): string => {
  const cursor = getAfterCursor(params[PAGE], params[PAGE_SIZE] || LIMIT_DEFAULT)
  const dataSourceFilter = params[DATA_SOURCE_ID]
    ? `,datasourceIds: "${params[DATA_SOURCE_ID]}"`
    : ''

  const filterString = parameterizeArrayofObjects([
    ...(params.filters?.filter || []),
    { key: API_FILTER_ALERTS.STATUS, values: [AlertStatuses.active] }
  ])
  const filters = `,filter:${filterString}`

  return gql`
    {
      alert(first: ${
        params[PAGE_SIZE] || LIMIT_DEFAULT
      }, after: "${cursor}" ${dataSourceFilter}${filters}) {
        count
        edges {
          node {
            id
            name
            policy{
              edges{
                node{
                  policyType {
                    edges {
                      node {
                        name
                      }
                    }
                  }
                }
              }
            }
            assignees
            lastOccurred
            severity
          }
        }
      }
    }
  `
}

export const mapQueryDriveAlerts = (
  raw: any
): {
  list: Alert[]
  total: number
} => {
  let list = []
  let total = 0

  if (raw && raw.alert && raw.alert.edges && Array.isArray(raw.alert.edges)) {
    list = raw.alert.edges.map(({ node: alert }) => ({
      alertId: alert.id || '',
      alertName: alert.name || '',
      severity: alert.severity || '',
      lastOccurred: alert.lastOccurred ? formatDateTime(alert.lastOccurred) : '',
      assignee: alert.assignees[0] || '',
      policyType: alert.policy?.edges[0]?.node?.policyType?.edges[0]?.node?.name || ''
    }))
    total = raw.alert.count
  }

  return {
    list,
    total
  }
}

export const queryAlertsByDatabase = (params: AlertsByDatabaseParams): string => {
  const cursor = getAfterCursor(params[PAGE], params[PAGE_SIZE] || LIMIT_DEFAULT)

  return gql`
    {
      alert(
          first: ${params[PAGE_SIZE] || LIMIT_DEFAULT},
          after: "${cursor}",
          severity: ${SEVERITY_LEVEL.critical}
          filter: [
            { key: ${GRAPHQL_API_FILTERS.databaseIds}, values: ["${params.databaseId}"] },
            { key: ${API_FILTER_ALERTS.STATUS}, values: "${AlertStatuses.active}" }
          ]
        ) {
        count
        edges {
          node {
            id
            name
            objectCount
            assignees
            generatedAt
            subAlerts(first: 1) {
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryAlertsByDatabase = (raw: any): { list: Alert[]; total: number } => {
  try {
    const list = raw.alert?.edges?.map(({ node: alert }) => ({
      alertId: alert?.id,
      alertName: alert?.name,
      objectsCount: alert.objectCount?.count || 0,
      assignee: alert?.assignees[0] || '',
      generatedAt: alert.generatedAt,
      tablesImpactedCount: alert?.subAlerts?.count || 0
    }))

    return { list, total: raw.alert?.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}
