const set = (name: string, value: string, days?: number): void => {
  let expires = ''

  if (days) {
    const date = new Date()
    date.setDate(date.getDate() + days)
    expires = '; expires=' + date.toUTCString()
  }

  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

const get = (name: string): string | null => {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }

  return null
}

const remove = (name: string): void => {
  document.cookie = name + '=; Max-Age=-99999999;'
}

export default { set, get, remove }
