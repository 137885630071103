import {
  DS_ACCOUNT_NAME,
  DS_API_TOKEN,
  DS_EMAIL,
  DS_HOST,
  DS_INSTANCE_NAME,
  DS_OWNER,
  DS_PASSWORD,
  DS_PORT,
  DS_START_DATE,
  DS_SUB_DOMAIN,
  DS_USER_NAME,
  DS_WAREHOUSE,
  DS_CLIENT_ID,
  DS_CLIENT_SECRET,
  DS_GRANT_TYPE,
  DS_CLIENT_URL,
  DS_SSL,
  DS_SSL_CERTIFICATE,
  DS_SSL_KEY,
  DS_SSL_CA_CERTIFICATE,
  DS_AUTH_MECHANISM,
  DS_AWS_REGION,
  DS_AWS_ACCESS_KEY,
  DS_AWS_SECRET_KEY,
  DS_SCHEDULE,
  DS_URL,
  DS_BASE_DS_ID,
  DS_FOLDER_URI,
  DS_SERVER_VERSION,
  DS_ROLE,
  DS_ACCESS_TOKEN,
  DS_HUBSPOT_HUB_ID,
  DS_HUBSPOT_CRENDENTIALS_TITLE,
  DS_ACCOUNT_JSON,
  DS_DATA_CENTER,
  DS_REFRESH_TOKEN,
  DS_DOMAIN_URL,
  DS_QUERY_ENGINE,
  DS_WORKGROUP,
  DS_ENTRA_AUTH,
  DS_DOMAIN_NAME,
  DS_WORKSPACE_URL,
  DS_TOKEN
} from '../../features/dataSourcesMixed/slice'
import { DATA_SOURCE_TYPE } from '../../constants'
import * as Yup from 'yup'
import { isValidCron } from 'cron-validator'

export const stepBasicValidationSchema = Yup.object().shape({
  [DS_INSTANCE_NAME]: Yup.string()
    .min(2, 'dataSources.structured.register.instanceName.error')
    .max(50, 'dataSources.structured.register.instanceName.error')
    .required('dataSources.structured.register.instanceName.error'),

  [DS_OWNER]: Yup.string()
    .min(2, 'dataSources.structured.register.owner.error')
    .max(50, 'dataSources.structured.register.owner.error')
    .required('dataSources.structured.register.owner.error'),

  [DATA_SOURCE_TYPE]: Yup.string().required('dataSources.structured.register.dsType.error')
})

export const stepConnectionValidationSchema = Yup.object().shape({
  [DS_PORT]: Yup.string()
    .min(2, 'dataSources.structured.register.port.error')
    .max(50, 'dataSources.structured.register.port.error')
    .required('dataSources.structured.register.port.error'),

  [DS_USER_NAME]: Yup.string()
    .min(2, 'dataSources.structured.register.userName.error')
    .max(50, 'dataSources.structured.register.userName.error')
    .required('dataSources.structured.register.userName.error'),

  [DS_PASSWORD]: Yup.string()
    .min(8, 'dataSources.structured.register.password.error')
    .max(50, 'dataSources.structured.register.password.error')
    .required('dataSources.structured.register.password.error'),

  [DS_HOST]: Yup.string()
    .min(2, 'dataSources.structured.register.host.error')
    .required('dataSources.structured.register.host.error'),

  [DS_SCHEDULE]: Yup.string()
    .required('dataSources.form.field.cron.error')
    .test({
      name: 'cronPattern',
      exclusive: true,
      message: 'dataSources.form.field.cron.error',
      test: (value = '') => isValidCron(value)
    }),

  [DS_SSL]: Yup.boolean(),
  [DS_ENTRA_AUTH]: Yup.boolean(),
  [DS_SSL_CERTIFICATE]: Yup.string(),
  [DS_SSL_KEY]: Yup.string(),
  [DS_SSL_CA_CERTIFICATE]: Yup.string(),
  [DS_AUTH_MECHANISM]: Yup.string(),
  [DS_AWS_REGION]: Yup.string(),
  [DS_AWS_ACCESS_KEY]: Yup.string().required('connectionSetting.auth.accessKey.error'),
  [DS_AWS_SECRET_KEY]: Yup.string().required('connectionSetting.auth.secretKey.error'),
  [DS_QUERY_ENGINE]: Yup.string()
})

export const stepConnectionSnowflakeValidationSchema = Yup.object().shape({
  [DS_ACCOUNT_NAME]: Yup.string()
    .min(2, 'dataSources.structured.register.accountName.error')
    .max(50, 'dataSources.structured.register.accountName.error')
    .required('dataSources.structured.register.accountName.error'),

  [DS_USER_NAME]: Yup.string()
    .min(2, 'dataSources.structured.register.userName.error')
    .max(50, 'dataSources.structured.register.userName.error')
    .required('dataSources.structured.register.userName.error'),

  [DS_PASSWORD]: Yup.string()
    .min(2, 'dataSources.structured.register.password.error')
    .max(50, 'dataSources.structured.register.password.error')
    .required('dataSources.structured.register.password.error'),

  [DS_WAREHOUSE]: Yup.string()
    .min(2, 'dataSources.structured.register.warehouse.error')
    .max(50, 'dataSources.structured.register.warehouse.error')
    .required('dataSources.structured.register.warehouse.error'),

  [DS_ROLE]: Yup.string()
    .min(2, 'dataSources.structured.register.warehouse.error')
    .max(50, 'dataSources.structured.register.warehouse.error')
})

export const workgroupValidationSchema = Yup.object().shape({
  [DS_WORKGROUP]: Yup.string()
    .min(2, 'dataSources.structured.register.workgroup.error')
    .required('dataSources.structured.register.workgroup.error')
})

export const stepConnectionZenDeskSupportApiTokenValidationSchema = Yup.object().shape({
  [DS_START_DATE]: Yup.string()
    .min(2, 'dataSources.structured.register.startDate.error')
    .max(50, 'dataSources.structured.register.startDate.error')
    .required('dataSources.structured.register.startDate.error'),

  [DS_SUB_DOMAIN]: Yup.string()
    .min(2, 'dataSources.structured.register.subDomain.error')
    .max(50, 'dataSources.structured.register.subDomain.error')
    .required('dataSources.structured.register.subDomain.error'),

  [DS_EMAIL]: Yup.string()
    .min(2, 'dataSources.structured.register.email.error')
    .max(50, 'dataSources.structured.register.email.error')
    .required('dataSources.structured.register.email.error'),

  [DS_API_TOKEN]: Yup.string()
    .min(2, 'dataSources.structured.register.apiToken.error')
    .max(500, 'dataSources.structured.register.apiToken.error')
    .required('dataSources.structured.register.apiToken.error')
})
export const stepConnectionHubspotApiTokenValidationSchema = Yup.object().shape({
  [DS_ACCESS_TOKEN]: Yup.string().required('dataSources.structured.register.accessToken.error'),
  [DS_HUBSPOT_HUB_ID]: Yup.string().required('dataSources.structured.register.hubId.error'),
  [DS_HUBSPOT_CRENDENTIALS_TITLE]: Yup.string().required(
    'dataSources.structured.register.credentialsTitle.error'
  )
})
export const stepConnectionInnovaccerValidationSchema = Yup.object().shape({
  [DS_CLIENT_ID]: Yup.string()
    .min(2, 'dataSources.structured.register.clientId.error')
    .max(50, 'dataSources.structured.register.clientId.error')
    .required('dataSources.structured.register.clientId.error'),

  [DS_CLIENT_SECRET]: Yup.string()
    .min(2, 'dataSources.structured.register.clientSecret.error')
    .max(128, 'dataSources.structured.register.clientSecret.error')
    .required('dataSources.structured.register.clientSecret.error'),

  [DS_GRANT_TYPE]: Yup.string()
    .min(2, 'dataSources.structured.register.grantType.error')
    .max(50, 'dataSources.structured.register.grantType.error')
    .required('dataSources.structured.register.grantType.error'),

  [DS_CLIENT_URL]: Yup.string()
    .min(2, 'dataSources.structured.register.clientUrl.error')
    .max(50, 'dataSources.structured.register.clientUrl.error')
    .required('dataSources.structured.register.clientUrl.error')
})

export const stepConnectionAdpValidationSchema = Yup.object().shape({
  [DS_CLIENT_ID]: Yup.string()
    .min(2, 'dataSources.structured.register.clientId.error')
    .max(50, 'dataSources.structured.register.clientId.error')
    .required('dataSources.structured.register.clientId.error'),
  [DS_CLIENT_SECRET]: Yup.string()
    .min(2, 'dataSources.structured.register.clientSecret.error')
    .max(128, 'dataSources.structured.register.clientSecret.error')
    .required('dataSources.structured.register.clientSecret.error'),
  [DS_SSL_CERTIFICATE]: Yup.string().required(),
  [DS_SSL_KEY]: Yup.string().required()
})

export const stepConnectionServiceNowValidationSchema = Yup.object().shape({
  [DS_START_DATE]: Yup.string()
    .min(2, 'dataSources.structured.register.startDate.error')
    .max(50, 'dataSources.structured.register.startDate.error')
    .required('dataSources.structured.register.startDate.error'),

  [DS_USER_NAME]: Yup.string()
    .min(2, 'dataSources.structured.register.userName.error')
    .max(50, 'dataSources.structured.register.userName.error')
    .required('dataSources.structured.register.userName.error'),

  [DS_PASSWORD]: Yup.string()
    .min(8, 'dataSources.structured.register.password.error')
    .max(50, 'dataSources.structured.register.password.error')
    .required('dataSources.structured.register.password.error'),

  [DS_URL]: Yup.string()
    .min(2, 'dataSources.structured.register.url.error')
    .max(50, 'dataSources.structured.register.url.error')
    .required('dataSources.structured.register.url.error'),

  [DS_SCHEDULE]: Yup.string()
    .required('dataSources.form.field.cron.error')
    .test({
      name: 'cronPattern',
      exclusive: true,
      message: 'dataSources.form.field.cron.error',
      test: (value = '') => isValidCron(value)
    })
})

export const stepConnectionOfflineValidationSchema = Yup.object().shape({
  [DS_BASE_DS_ID]: Yup.string().required('dataSources.structured.register.baseDs.error'),

  [DS_FOLDER_URI]: Yup.string()
    .required('dataSources.structured.register.schemaUrl.error')
    .min(2, 'dataSources.structured.register.schemaUrl.error'),

  [DS_SCHEDULE]: Yup.string()
    .required('dataSources.form.field.cron.error')
    .test({
      name: 'cronPattern',
      exclusive: true,
      message: 'dataSources.form.field.cron.error',
      test: (value = '') => isValidCron(value)
    }),

  [DS_SERVER_VERSION]: Yup.string().required('dataSources.structured.register.serverVersion.error')
})

export const stepConnectionBigQueryValidationSchema = Yup.object().shape({
  [DS_ACCOUNT_JSON]: Yup.string().required('dataSources.structured.register.accountJson.error')
})

export const stepConnectionIterableValidationSchema = Yup.object().shape({
  [DS_BASE_DS_ID]: Yup.string().required('dataSources.structured.register.apiKey.error'),
  [DS_DATA_CENTER]: Yup.string().required('dataSources.structured.register.dataCenter.error')
})

export const stepConnectionSalesForceValidationSchema = Yup.object().shape({
  [DS_CLIENT_ID]: Yup.string().required('dataSources.structured.register.clientId.error'),
  [DS_CLIENT_SECRET]: Yup.string().required('dataSources.structured.register.clientSecret.error'),
  [DS_URL]: Yup.string().required('dataSources.structured.register.url.error'),
  [DS_REFRESH_TOKEN]: Yup.string().required('dataSources.structured.register.refreshToken.error')
})
export const stepConnectionMarketoValidationSchema = Yup.object().shape({
  [DS_CLIENT_ID]: Yup.string().required('dataSources.structured.register.clientId.error'),
  [DS_CLIENT_SECRET]: Yup.string().required('dataSources.structured.register.clientSecret.error'),
  [DS_DOMAIN_URL]: Yup.string().required('dataSources.structured.register.domainURL.error'),
  [DS_START_DATE]: Yup.string().required('dataSources.structured.register.startDate.error')
})

export const stepConnectionLookerValidationSchema = Yup.object().shape({
  [DS_URL]: Yup.string().required('dataSources.structured.register.url.error'),
  [DS_CLIENT_ID]: Yup.string().required('dataSources.structured.register.clientId.error'),
  [DS_CLIENT_SECRET]: Yup.string().required('dataSources.structured.register.clientSecret.error')
})

export const stepConnectionConfluenceValidationSchema = Yup.object().shape({
  [DS_EMAIL]: Yup.string()
    .min(2, 'dataSources.structured.register.email.error')
    .max(50, 'dataSources.structured.register.email.error')
    .required('dataSources.structured.register.email.error'),
  [DS_API_TOKEN]: Yup.string()
    .min(2, 'dataSources.structured.register.apiToken.error')
    .max(500, 'dataSources.structured.register.apiToken.error')
    .required('dataSources.structured.register.apiToken.error'),
  [DS_DOMAIN_NAME]: Yup.string().required('dataSources.structured.register.domainName.error')
})

export const stepConnectionDatabricksValidationSchema = Yup.object().shape({
  [DS_WORKSPACE_URL]: Yup.string()
    .min(2, 'dataSources.structured.register.url.error')
    .max(50, 'dataSources.structured.register.url.error')
    .required('dataSources.structured.register.url.error'),
  [DS_TOKEN]: Yup.string()
    .min(2, 'dataSources.structured.register.apiToken.error')
    .max(500, 'dataSources.structured.register.apiToken.error')
    .required('dataSources.structured.register.apiToken.error'),
  [DS_SCHEDULE]: Yup.string()
    .required('dataSources.form.field.cron.error')
    .test({
      name: 'cronPattern',
      exclusive: true,
      message: 'dataSources.form.field.cron.error',
      test: (value = '') => isValidCron(value)
    })
})
