import { queryWhatsNewWidget, mapQueryWhatsNewWidget } from './queries'
import { DATA_SOURCE_ID } from '../../constants'
import graphqlService from '../../services/graphqlService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export type WhatsNewDatabase = {
  databaseId: string
  databaseName: string
  modifiedOn?: string
  modifiedBy?: string
  tablesNeedReviewCount: number
  columnsNeedReviewCount: number
}

export interface WhatsNewWidgetParams {
  [DATA_SOURCE_ID]: string
}

export const ACTION_WHATS_NEW_WIDGET = 'whatsNew/list'
export const fetchWhatsNewWidget = createAsyncThunk(
  ACTION_WHATS_NEW_WIDGET,
  async (params: WhatsNewWidgetParams) => {
    const resultRaw = await graphqlService.execute(queryWhatsNewWidget(params))
    return mapQueryWhatsNewWidget(resultRaw)
  }
)

type WhatsNewState = {
  widget?: WhatsNewDatabase[]
}

export const initialState: WhatsNewState = {}

const whatsNewSlice = createSlice({
  name: 'whatsNew',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWhatsNewWidget.fulfilled, (state, { payload }) => {
      state.widget = payload
    })
  }
})

export default whatsNewSlice.reducer
