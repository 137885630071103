import {
  mapQueryExternalMembers,
  mapQueryExternalMembersEmailsCount,
  queryExternalMembers,
  queryExternalMembersEmailsCount
} from './queries'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import { PAGE, PAGE_SIZE } from '../../constants'
import { FilterParams } from '../../interfaces'
import graphqlService from '../../services/graphqlService'
import { RootState } from '../../rootReducer'
import { getGlobalParams } from '../../utils/urlUtil'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export type IExternalMembers = {
  name: string
  piiFilesCount: number
  piiDataMailCount: number
  email: string
}

export type ListSettings = {
  list?: IExternalMembers[]
  total?: number
  sentEmailsTotal?: number
  receivedEmailsTotal?: number
  sort: SortParams
}

export interface MembersState {
  externalMembers: ListSettings
}

const initialList = {
  sort: defaultSortParams
}

export const initialState: MembersState = {
  externalMembers: { ...initialList }
}

export type IGetExternalMembersParams = {
  [PAGE]?: number
  [PAGE_SIZE]?: number
  filters?: FilterParams
  isEmail?: boolean
}
export const ACTION_EXTERNAL_MEMBERS_FETCH = 'members/external/list'
export const fetchExternalMembers = createAsyncThunk(
  ACTION_EXTERNAL_MEMBERS_FETCH,
  async (params: IGetExternalMembersParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const resultRaw = await graphqlService.execute(queryExternalMembers(queryParams))
    return mapQueryExternalMembers(resultRaw)
  }
)

export const ACTION_EXTERNAL_MEMBERS_EMAIL_COUNT = 'members/external/count'
export const fetchExternalMembersMailCount = createAsyncThunk(
  ACTION_EXTERNAL_MEMBERS_EMAIL_COUNT,
  async (params?: IGetExternalMembersParams) => {
    const resultRaw = await graphqlService.execute(queryExternalMembersEmailsCount(params))
    return mapQueryExternalMembersEmailsCount(resultRaw)
  }
)

const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    setSort: (state, { payload }) => {
      state.externalMembers.sort = getSortDirection(state.externalMembers?.sort, payload.column)
    },
    clearMembers: (state) => {
      state.externalMembers = { ...initialList }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExternalMembers.fulfilled, (state, action) => {
      state.externalMembers.list = action.payload.list
    })
    builder.addCase(fetchExternalMembersMailCount.fulfilled, (state, action) => {
      state.externalMembers.sentEmailsTotal = action.payload.sentEmailsTotal
      state.externalMembers.receivedEmailsTotal = action.payload.receivedEmailsTotal
    })
  }
})

export const { setSort, clearMembers } = membersSlice.actions
export default membersSlice.reducer
