import { PAGE } from '../../constants'
import { FilterParams } from '../../interfaces'
import { Dispatch, SetStateAction } from 'react'

export const BUTTON_TEXTS = {
  RETRY: 'Retry',
  DELETE: 'Delete',
  REGISTER: 'Register'
}

export const SORT_FIELD_CLOUD_RESOURCES = 'ADDED_ON_TIMESTAMP'

export const GCP_PROJECTS_LIST_PAGE_SIZE = 10

//Enums
export enum CLOUD_RESOURCES_STATUS {
  REGISTERED = 'Registered',
  ERROR = 'Error',
  UNREGISTERED = 'Unregistered',
  ACTION_REQUIRED = 'Action Required'
}
export enum CLOUD_RESOURCE_LISTING_FILTER_KEYS {
  CLOUD_PLATFORM_ID = 'CLOUD_PLATFORM_ID',
  CLOUD_ACCOUNT_ID = 'CLOUD_ACCOUNT_ID',
  STATUS = 'STATUS',
  REGION = 'REGION',
  TYPE = 'TYPE',
  IS_SUPPORTED = 'IS_SUPPORTED'
}
export enum AWS_RESOURCE_TYPES {
  AWS = 'AWS S3',
  GLUE = 'Glue',
  DYNAMO = 'DynamoDB',
  RDS = 'RDS',
  REDSHIFT = 'Redshift',
  SMB = 'SMB'
}

export enum GCP_RESOURCE_TYPES {
  BIG_QUERY = 'BigQuery',
  GOOGLE_CLOUD_STORAGE = 'Google Cloud Storage',
  CLOUD_SQL = 'Cloud SQL',
  FIRESTORE = 'Firestore',
  CLOUD_SPANNER = 'Cloud Spanner',
  GCE = 'GCE'
}

export enum AWS_SUPPORTED_DATA_SOURCE_TYPES {
  MSSQL = 'MsSQL',
  MYSQL = 'MySQL',
  ORACLE = 'Oracle',
  POSTGRESQL = 'PostgreSQL'
}

export enum GCP_SUPPORTED_DATA_SOURCE_TYPES {
  BIG_QUERY = 'BigQuery'
}

export enum CLOUD_PLATFORM_TYPE {
  AWS = 'AWS',
  GCP = 'GCP',
  AZURE = 'AZURE'
}

export enum DATASOURCES_TABS {
  SCANNING = 'Scanning',
  UNREGISTERED = 'Unregistered'
}

export enum CLOUD_PLATFORM_TABS {
  SUPPORTED = 'Supported',
  UNSUPPORTED = 'Unsupported'
}

export enum CLOUD_PLATFORM_WORKFLOW_STATUS {
  RUNNING = 'RUNNING',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED'
}

export enum CONNECTION_VERIFICATION_STATE {
  VERIFICATION_FAILED,
  VERIFIED,
  PENDING,
  IDLE
}

//Interfaces
export interface FetchCloudResourcesParams {
  filters?: FilterParams
  [PAGE]?: number
  sortField?: string
  statusOrder?: string[]
}
export interface FetchCloudPlatformsParams {
  filters?: FilterParams
  [PAGE]?: number
}

export interface FetchCloudAccountsParams {
  filters?: FilterParams
}

export interface FetchRegionsListParams {
  type: CLOUD_PLATFORM_TYPE
}

export interface FetchResourceTypeFilterListParams {
  type: CLOUD_PLATFORM_TYPE
  filters?: FilterParams
}

export type QueryGcpProjectListParams = {
  serviceAccountKey?: string
}

//Types

export type FetchCloudPlatformByIdParams = {
  cloudPlatformId: string
}

export type RegisterCloudPlatformParams = {
  clientMutationId: string
  description: string
  autoScanDatasources: string[]
  name: string
  ownerName: string
  coOwnerName: string
  awsCloudAccountId?: string
  frequencyUnit: FrequencyUnit
  frequencyAmount: number
  awsAccessKey?: string
  awsSecretKey?: string
  awsSessionKey?: string
  gcpServiceAccountKey?: string
  gcpProjectIds?: string[]
  cloudPlatformType: CLOUD_PLATFORM_TYPE
}

export type UpdateCloudPlatformParams = Omit<
  RegisterCloudPlatformParams,
  'secretKey' | 'accessKey' | 'sessionKey' | 'cloudAccountId'
> & {
  cloudPlatformId: string
}

export type RegisterCloudPlatformResponse = {
  registerCloudPlatform: {
    clientMutationId: string
  }
}

export type UpdateCloudPlatformResponse = {
  updateCloudPlatform: {
    clientMutationId: string
  }
}

export type VerifyCloudPlatformCredsParams = {
  accessKey?: string
  secretKey?: string
  sessionKey?: string
  accountId?: string
  cloudPlatformType: CLOUD_PLATFORM_TYPE
  serviceAccountKey?: string
  clientMutationId: string
}
export type VerifyCloudPlatformCredsResponse = {
  testCloudPlatformCreds: {
    status: boolean
    clientMutationId: string
  }
}

export type CloudResourcesListObject = {
  id: string
  name: string
  type: AWS_RESOURCE_TYPES
  status?: CLOUD_RESOURCES_STATUS
  region?: string
  addedOn?: string
  datasourceType: AWS_SUPPORTED_DATA_SOURCE_TYPES
  datasourceId?: string
  isRetry?: boolean
  isDelete?: boolean
  cloudAccount?: CloudAccountsListObject
  cloudPlatform?: CloudPlatformsListObject
}
export type CloudResourcesListResponseType = {
  list: CloudResourcesListObject[]
}
export type CloudResourcesCountResponseType = {
  total: number
}
export type CloudPlatformsListObject = {
  id: string
  name: string
  platformType: CLOUD_PLATFORM_TYPE
  workflowStatus: CLOUD_PLATFORM_WORKFLOW_STATUS
  owner: string
  lastSyncedOn?: string
}
export type CloudPlatformsListResponseType = {
  list: CloudPlatformsListObject[]
}
export type GcpProjectsListObject = {
  id: string
  name: string
}

export type CloudPlatformByIdResponseType = {
  id: string
  name: string
  description: string | null
  platformType: string
  owner: string
  coOwner: string
  connectionScope: string
  frequency: {
    type: string
    value: number
  }
  autoScanDatasources: string[]
  updateTimestamp: string
  gcpProjectIds: string[]
}

export type CloudAccountsListObject = {
  id: string
  name: string
  accountId?: string
  registeredResourceCount?: number
  unRegisteredResourceCount?: number
  datasourceCount?: number
  //Todo: This can be made a strict Type by only allowing supported DS
  uniqueDatasourceTypes?: string[]
}
export type CloudAccountsListResponseType = {
  list: CloudAccountsListObject[]
}

export type RegionListObject = {
  code: string
}
export type RegionListResponseType = {
  list: RegionListObject[]
}

export type ResourceTypeFilterListObject = {
  name: string
}
export type ResourceListFilterResponseType = {
  list: ResourceTypeFilterListObject[]
}

export type DeleteCloudResourceParams = {
  cloudResourceId: string
  clientMutationId: string
}

export type RefreshCloudPlatformParams = {
  clientMutationId: string
  cloudPlatformId: string
}

export type ConnectionType = {
  name: string
  description: string
  primaryOwner: string
  isPrimaryOwnerValid: boolean
  coOwner: string
  gcpServiceAccountKey: string
  accountId: string
  accessKey: string
  secretKey: string
  sessionKey: string
}

export type FrequencyUnit = 'DAY' | 'WEEK' | 'MONTH'

export type DiscoveryType = {
  frequencyUnit: FrequencyUnit
  frequencyAmount: number
  dataSourceTypes: string[]
  gcpProjectIds: string[]
}

export type CloudPlatformsSettingsContextType = {
  cloudPlatformId?: string
  cloudPlatformType: CLOUD_PLATFORM_TYPE
  isEditMode: boolean
  connection: ConnectionType
  discovery: DiscoveryType
  activeStep: number
  connectionVerificationState: CONNECTION_VERIFICATION_STATE
  isPageReady(page: number): boolean
  patchConnection(fields: Partial<ConnectionType>)
  patchDiscovery(fields: Partial<DiscoveryType>)
  setActiveStep: Dispatch<SetStateAction<number>>
  savedCloudPlatformResponse?: CloudPlatformByIdResponseType
}
