import './chartTooltip.less'
import { TooltipProps } from 'recharts'
import React from 'react'
import { FormattedNumber } from 'react-intl'

const ChartTooltip = ({ active, payload, label, formatter }: TooltipProps<number, string>) => {
  const [item] = payload || []
  const text = item?.payload?.key || ''
  const labelText = text || label
  const count = item?.payload?.value || 0

  if (active && labelText) {
    return (
      <div className="chart-tooltip" data-test-id="chart-tooltip">
        <span className="tooltip-content">
          {labelText}:{' '}
          {formatter ? formatter(count) : <FormattedNumber value={count} notation="compact" />}
        </span>
      </div>
    )
  } else {
    return null
  }
}

export default ChartTooltip
