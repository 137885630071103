export const classificationCountries = [
  {
    title: 'Austria',
    nationalId: 10,
    medical: 10,
    finance: 10,
    humanResources: 10,
    taxForms: 10,
    vehicleRegistration: 10
  },
  {
    title: 'Belgium',
    nationalId: 2,
    medical: 12,
    finance: 9,
    humanResources: 2,
    taxForms: 2,
    vehicleRegistration: 9
  },
  {
    title: 'Bulgaria',
    nationalId: 3,
    medical: 12,
    finance: 5,
    humanResources: 3,
    taxForms: 3,
    vehicleRegistration: 3
  },
  {
    title: 'Croatia',
    nationalId: 4,
    medical: 5,
    finance: 5,
    humanResources: 4,
    taxForms: 4,
    vehicleRegistration: 5
  }
]
