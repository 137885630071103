import {
  mapQuerySchemasCompact,
  mapQuerySchemasList,
  mapQuerySchemaTables,
  querySchemasCompact,
  querySchemasList,
  querySchemaTables
} from './queries'
import { DATABASE_ID, DATA_SOURCE_ID, SCHEMA_ID } from '../../constants'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import graphqlService from '../../services/graphqlService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export type SchemaCompactTable = {
  tableId: string
  tableName: string
}

export type SchemaCompact = {
  schemaId: string
  schemaName: string
  isCollapsed: boolean
  tablesCount: boolean
  tables?: SchemaCompactTable[]
}

export type SchemaCompactSettings = {
  list?: SchemaCompact[]
}

export type Schema = {
  schemaId: string
  schemaName: string
  tablesCount: number
  columnsSensitiveCount: number
  lastModified?: number
}

export type SchemasListSettings = {
  list?: Schema[]
  total?: number
  sort: SortParams
}

const initialSchemasCompact: SchemaCompactSettings = {}
const initialByDatabaseList: SchemasListSettings = { sort: defaultSortParams }

export interface SchemasCompactParams {
  [DATA_SOURCE_ID]: string
  [DATABASE_ID]?: string
}

export const ACTION_SCHEMAS_LIST_COMPACT_FETCH = 'schemas/listCompact'
export const fetchSchemasCompact = createAsyncThunk(
  ACTION_SCHEMAS_LIST_COMPACT_FETCH,
  async (params: SchemasCompactParams) => {
    const resultRaw = await graphqlService.execute(querySchemasCompact(params))
    return { ...params, list: mapQuerySchemasCompact(resultRaw) }
  }
)

export interface SchemasListParams {
  [DATABASE_ID]: string
  [DATA_SOURCE_ID]: string
}
export const ACTION_SCHEMAS_LIST_FETCH = 'schemas/list'
export const fetchSchemasList = createAsyncThunk(
  ACTION_SCHEMAS_LIST_FETCH,
  async (params: SchemasListParams) => {
    const resultRaw = await graphqlService.execute(querySchemasList(params))
    const result = mapQuerySchemasList(resultRaw)

    return { ...params, list: result.list, total: result.total }
  }
)

export interface SchemaTablesParams {
  [SCHEMA_ID]: string
}
export const ACTION_SCHEMAS_TABLES_FETCH = 'schemas/tables'
export const fetchSchemaTables = createAsyncThunk(
  ACTION_SCHEMAS_TABLES_FETCH,
  async (params: SchemaTablesParams) => {
    const resultRaw = await graphqlService.execute(querySchemaTables(params))
    return mapQuerySchemaTables(resultRaw)
  }
)

interface SchemasState {
  dataSourceId?: string
  databaseId?: string
  compact: SchemaCompactSettings
  list: SchemasListSettings
}

export const initialState: SchemasState = {
  compact: initialSchemasCompact,
  list: initialByDatabaseList
}

const schemasSlice = createSlice({
  name: 'schemas',
  initialState,
  reducers: {
    setSort: (state, { payload }) => {
      state.list.sort = getSortDirection(state.list.sort, payload.column)
    },
    resetLists: () => initialState,
    toggleCollapsed: (state, action) => {
      const schemaId = action.payload.schemaId + ''

      state.compact.list = state.compact.list?.map((schema) => {
        return schema.schemaId === schemaId
          ? { ...schema, isCollapsed: !schema.isCollapsed }
          : schema
      })
    },
    toggleAll: (state) => {
      const isAllCollapsed = !state.compact.list?.find((schema) => schema.isCollapsed === false)
      state.compact.list = state.compact.list?.map((schema) => ({
        ...schema,
        isCollapsed: !isAllCollapsed
      }))
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSchemasCompact.fulfilled, (state, { payload }) => {
      state.dataSourceId = payload.datasourceId
      state.databaseId = payload.databaseId
      state.compact.list = payload.list
    })
    builder.addCase(fetchSchemaTables.fulfilled, (state, { payload }) => {
      const schemaId = payload.schemaId
      const tables = payload.list

      state.compact.list = state.compact.list?.map((schema) => {
        return schema.schemaId === schemaId ? { ...schema, tables } : schema
      })
    })

    builder.addCase(fetchSchemasList.fulfilled, (state, { payload }) => {
      const { list, total, databaseId } = payload

      state.databaseId = databaseId
      state.list.list = list
      state.list.total = total
    })
  }
})

export const { resetLists, toggleCollapsed, toggleAll, setSort } = schemasSlice.actions

export default schemasSlice.reducer
