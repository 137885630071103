import { LIMIT_DEFAULT } from '../../../../../../constants'
import { getAfterCursor, parameterizeArrayofObjects } from '../../../../../../utils/graphqlUtil'
import { gql } from 'graphql-request'

export const fetchDsrRequestAllAttributes = (params?: any) => {
  const filterString = parameterizeArrayofObjects(params.filters?.filter || [])

  let commonParamString = ''
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }

  return gql`
    {
      dsrRequestAttribute(${commonParamString}) {
        edges {
          node {
            id
            attributeId
          }
        }
      }
    }
  `
}

export const mapFetchDsrRequestAllAttributes = (raw: any) => {
  return raw.dsrRequestAttribute.edges.map(({ node }) => node.id)
}

export const fetchDsrRequestAttribute = (params?: any) => {
  const { page, pageSize = LIMIT_DEFAULT, groupingFields, ...listFilters } = params

  const cursor = getAfterCursor(page, pageSize)

  const filterString = parameterizeArrayofObjects(listFilters.filters?.filter || [])

  let commonParamString = ''
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }

  let groupingFieldsString = ''
  if (groupingFields) {
    groupingFieldsString = `,groupingFields:[${groupingFields}]`
  }
  return gql`
    {
      dsrRequestAttribute(first: ${pageSize}, after: "${cursor}", ${commonParamString} ${groupingFieldsString}) {
        count
        edges {
          node {
            id
            attributeInstanceId
            attributeValidatedId
            attributeId
            attributeTypeName
            attributeValue
            remark
            reason
            previousValue
            action
            assignee
            dataMismatch
            datasource {
              edges {
                node {
                  id
                  name
                  type
                }
              }
            }
            dsrTicket {
              edges {
                node {
                  id
                  assigneeEmail
                  status
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapFetchDsrRequestAttribute = (raw: any) => {
  const count = raw.dsrRequestAttribute.count

  const mappedData = raw.dsrRequestAttribute.edges.map((edge) => {
    const node = edge.node
    return {
      id: node.id,
      attributeValue: node.attributeValue || '',
      attributeInstanceId: node.attributeInstanceId || '',
      attributeValidatedId: node.attributeValidatedId || '',
      attributeId: node.attributeId || '',
      attributeTypeName: node.attributeTypeName || '',
      identifier: node.attributeValue || '',
      remark: node.remark || '',
      reason: node.reason || [],
      previousValue: node.previousValue || 'null',
      action: node.action || '',
      assignee: node.assignee || '',
      dataMismatch: node.dataMismatch || false,
      datasource: node.datasource.edges.map((ds) => ({
        id: ds.node.id || '',
        name: ds.node.name || '',
        type: ds.node.type
      })),
      dsrTickets: node.dsrTicket.edges.map((ticket) => ({
        id: ticket.node.id,
        assigneeEmail: ticket.node.assigneeEmail,
        status: ticket.node.status
      }))
    }
  })

  return {
    count,
    data: mappedData
  }
}
