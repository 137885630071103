import { FilterParams } from '../../interfaces'
import { parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { NameIdSummary } from '../ropa/ropaSlice'
import { gql } from 'graphql-request'

export const queryDataSourceNames = (params: FilterParams): string => {
  const { ...listFilters } = params
  const filterString = listFilters.filter ? parameterizeArrayofObjects(listFilters.filter) : ''
  const paramString = filterString ? `(filter: ${filterString})` : ''

  return gql`
    {
      datasources${paramString} {
        edges {
          node {
            id
            name
            type
          }
        }
      }
    }
  `
}
export const mapQueryDataSourceNames = (raw: any): { list: NameIdSummary[] } => {
  const list = raw.datasources.edges.map(({ node: attr }) => ({
    name: attr.name,
    id: attr.id,
    type: attr.type
  }))

  return { list }
}

export interface IGetPoliciesByTypeParams {
  policyType: string
}
export const queryPoliciesIDNamesByType = (params: IGetPoliciesByTypeParams): string => gql`
{
  policy(
    first: 1,
    type: ${params.policyType}
  ) {
    edges {
      node {
        id
        name
      }
    }
  }
}
`

export const mapQueryPoliciesIDNamesByType = (
  raw: any
): { list: { id: string; name: string }[] } => {
  const list = raw.policy.edges?.map(({ node }) => ({ ...node }))
  return { list }
}

export const queryDrivesNames = (datasourceId?: string): string => {
  const paramsStr = datasourceId ? `(datasourceId:"${datasourceId}")` : ''
  return gql`{
  drive${paramsStr} {
    edges {
      node {
        driveId
        driveName
      }
    }
  }
}
`
}

export const mapQueryDrivesNames = (raw: any): { list: { id: string; driveName: string }[] } => {
  const list = raw.drive?.edges?.map(({ node }) => ({
    id: node.driveId,
    driveName: node.driveName
  }))
  return { list }
}

export const queryProjectIdNames = (): string => {
  return gql`
    {
      projects {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `
}

export const mapQueryProjectIdNames = (raw: any): { list: { id: string; name: string }[] } => {
  try {
    return { list: raw.projects.edges.map(({ node: { name, id } }) => ({ name, id })) }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAwsBucketOwners = (filters?: FilterParams): string => {
  const filterString = filters?.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const paramString = filterString ? `(filter: ${filterString})` : ''

  return gql`
    {
      bucket${paramString} {
        edges {
          node {
            ownerName
            ownerId
          }
        }
      }
    }
  `
}

export const mapQueryAwsBucketOwners = (raw: any): { list: { id: string; name: string }[] } => {
  try {
    const list = raw.bucket.edges.map(({ node }) => ({ id: node.ownerId, name: node.ownerName }))

    return {
      list: [...new Map(list.map((item) => [item.id, item])).values()] as {
        id: string
        name: string
      }[]
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryRopaProcessOwners = (): string => {
  return gql`
    {
      ropaProcess(booleanFilter: [{ key: IS_SYSTEM_DEFINED, value: false }]) {
        edges {
          node {
            owner
          }
        }
      }
    }
  `
}

export const mapQueryRopaProcessOwners = (raw: any): string[] => {
  try {
    const list = (raw.ropaProcess?.edges?.map(({ node }) => node.owner || '') || []) as string[]
    return [...new Set(list)]
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAwsBucketFilters = (filters?: FilterParams): string => {
  const filterString = filters?.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const paramString = filterString ? `(filter: ${filterString})` : ''

  return gql`
    {
      bucket${paramString} {
        edges {
          node {
            name
          }
        }
      }
    }
  `
}

export const mapQueryAwsBucketFilters = (raw: any): string[] => {
  try {
    const list = raw.bucket.edges.map(({ node }) => node.name || '')
    return [...new Set<string>(list)]
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryTemplates = (datasourceId?: string): string => {
  const paramsStr = datasourceId ? `(datasourceId:"${datasourceId}")` : ''
  return gql`{
  template${paramsStr} {
    edges {
      node {
        id
        templateName
      }
    }
  }
}
`
}

export const mapQueryTemplates = (raw: any): { list: { id: string; name: string }[] } => {
  const list = raw.template?.edges?.map(({ node }) => ({
    id: node.id,
    name: node.templateName
  }))
  return { list }
}

export const queryJiraProjectIdNames = (dataSourceId: string): string => {
  return gql`
    {
      ticketStoreProject(datasourceId: "${dataSourceId}") {
        edges {
          node {
            id
            projectName
          }
        }
      }
    }
  `
}

export const mapQueryJiraProjectIdNames = (raw: any): { list: { id: string; name: string }[] } => {
  try {
    return {
      list: raw.ticketStoreProject.edges.map(({ node: { projectName, id } }) => ({
        name: projectName,
        id
      }))
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryReportersNameEmail = (): string => {
  return gql`
    {
      ticket {
        edges {
          node {
            reporter {
              name
              email
            }
          }
        }
      }
    }
  `
}

export const mapQueryReportersNameEmail = (
  raw: any
): { list: { email: string; name: string }[] } => {
  try {
    const list = raw.ticket.edges.map(({ node: { reporter } }) => ({
      name: reporter.name,
      email: reporter.email
    }))

    const uniqueReporters = [...new Map(list.map((item) => [item.email, item])).values()] as {
      email: string
      name: string
    }[]
    return {
      list: uniqueReporters
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAssigneesNameEmail = (): string => {
  return gql`
    {
      ticket {
        edges {
          node {
            assignee {
              name
              email
            }
          }
        }
      }
    }
  `
}

export const mapQueryAssigneesNameEmail = (
  raw: any
): { list: { email: string; name: string }[] } => {
  try {
    const list = raw.ticket.edges
      .filter(({ node: { assignee } }) => assignee?.email)
      .map(({ node: { assignee } }) => ({
        name: assignee.name,
        email: assignee.email
      }))

    const uniqueAssignees = [...new Map(list.map((item) => [item.email, item])).values()] as {
      email: string
      name: string
    }[]
    return {
      list: uniqueAssignees
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
