import {
  DataDiscoveryActionTakenBy,
  DataDiscoveryActionTypes,
  dsrWFDefaultDatasourceType,
  Workflow
} from './workflowAutomationSlice'
import type {
  DataSubjectRequestForm,
  DetailedWorkflow,
  WorkflowListItem
} from './workflowAutomationSlice'
import { API_DSR_REQUEST_TYPES, DATA_SOURCE_TYPES, LIMIT_DEFAULT } from '../../../constants'
import { DsrRequestType } from '../../../services/graphqlSchemaTypes'
import { getAfterCursor } from '../../../utils/graphqlUtil'
import { DsrRequestApiKeys } from '../dsrForms/dsrFormsSlice'
import { gql } from 'graphql-request'

export const queryDsrWFEmailTemplates = (params: {
  eventType: string
  requestType: API_DSR_REQUEST_TYPES
}) => {
  const { eventType, requestType } = params
  const formattedRequestType =
    requestType === API_DSR_REQUEST_TYPES.DO_NOT_SELL
      ? DsrRequestApiKeys.doNotSell
      : requestType.toLowerCase()

  return gql`
    {
      dsrEmailTemplate(
        filter: [
          { key: REQUEST_TYPE, values: [${JSON.stringify(formattedRequestType)}] }
          { key: EVENT_TYPE, values: [${JSON.stringify(eventType)}] }
        ]
      ) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `
}

export const mapQueryDsrWFEmailTemplates = (raw: any): any => {
  try {
    const emailTemplates = raw.dsrEmailTemplate.edges.map(({ node }) => node)
    return emailTemplates
  } catch (error) {
    return []
  }
}

export const queryDsrDefaultDatasources = () => {
  return gql`
    {
      dsrSetting {
        edges {
          node {
            defaultDsrDatasource {
              edges {
                node {
                  id
                  name
                  type
                  createdBy
                  dsrErasureEnabled
                  dsrRectificationEnabled
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryDsrDefaultDatasources = (
  raw: any,
  requestType?: DsrRequestType
): dsrWFDefaultDatasourceType[] => {
  try {
    const defaultDatasources = raw.dsrSetting.edges[0].node.defaultDsrDatasource.edges.map(
      ({ node }) => {
        let actionToBeTaken: DataDiscoveryActionTypes =
          DataDiscoveryActionTypes.MANUALLY_DISCOVER_AND_VALIDATE

        // Determine actionToBeTaken based on node type and request type
        if (node.type === DATA_SOURCE_TYPES.remote) {
          actionToBeTaken = DataDiscoveryActionTypes.MANUALLY_DISCOVER_AND_VALIDATE
        } else if (node?.dsrErasureEnabled) {
          if (requestType === DsrRequestType.Erasure) {
            actionToBeTaken = DataDiscoveryActionTypes.AUTO_DISCOVER_AND_ERASE
          } else {
            actionToBeTaken = DataDiscoveryActionTypes.AUTO_DISCOVER_AND_VALIDATE
          }
        } else if (node?.dsrRectificationEnabled) {
          if (requestType === DsrRequestType.Rectification) {
            actionToBeTaken = DataDiscoveryActionTypes.AUTO_DISCOVER_AND_RECTIFY
          } else {
            actionToBeTaken = DataDiscoveryActionTypes.AUTO_DISCOVER_AND_VALIDATE
          }
        } else {
          actionToBeTaken = DataDiscoveryActionTypes.AUTO_DISCOVER_AND_VALIDATE
        }

        const actionToBeTakenBy =
          node.type === DATA_SOURCE_TYPES.remote
            ? DataDiscoveryActionTakenBy.DATA_SOURCE_OWNER
            : 'SYSTEM'

        return {
          id: node.id,
          name: node.name,
          type: node.type,
          owner: node.createdBy,
          actionToBeTaken,
          actionToBeTakenBy,
          dsrErasureSupported: node?.dsrErasureEnabled || false,
          dsrRecitificationSupported: node?.dsrRectificationEnabled || false
        }
      }
    )
    if (defaultDatasources.length > 0) {
      return defaultDatasources
    } else {
      return []
    }
  } catch (error) {
    return []
  }
}

export const queryDsrWorkflows = (page: number, pageSize: number = LIMIT_DEFAULT) => {
  return gql`
    {
      dsrWorkflowConfig(
        first: ${pageSize},
        after: "${getAfterCursor(page, pageSize)}",
        sortField: UPDATED_AT
      ) {
        edges {
          node {
            id
            name
            createdAt
            updatedAt
            createdBy
            requestType
            forms (
              filter: [
                { key: FORM_VERSION, values: ["latest"] }
              ],
              booleanFilter: [
                { key: IS_TEMPLATE, value: false },
                { key: IS_DELETED, value: false}
              ]
            ) {
              count
              edges {
                node {
                  id
                  name
                  type
                  formGroupId
                }
              }
            }
          }
        }
        count
      }
    }
  `
}

export const mapDsrWorkflows = (res: any) => {
  try {
    const list: WorkflowListItem[] = res.dsrWorkflowConfig.edges.map((edge) => {
      return {
        id: edge.node.id,
        name: edge.node.name,
        createdAt: edge.node.createdAt,
        lastUpdated: edge.node.updatedAt,
        createdBy: edge.node.createdBy,
        requestType: edge.node.requestType,
        dataSubjectRequestForms: {
          count: edge.node.forms.count,
          list: edge.node.forms.edges.map((edge) => {
            const form: DataSubjectRequestForm = {
              id: edge.node.id,
              name: edge.node.name,
              formGroupId: edge.node.formGroupId
            }

            return form
          })
        }
      }
    })

    return {
      list,
      totalCount: res.dsrWorkflowConfig.count
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error in mapDsrWorkflows', error)
    return {
      list: [],
      totalCount: 0
    }
  }
}

export const queryDsrDetailedWorkflowById = (workflowId: string) => {
  return gql`
    {
      dsrWorkflowConfig(id: "${workflowId}") {
        edges {
          node {
            id
            name
            createdAt
            requestType
            createdBy
            updatedAt
            forms (
              filter: [
                { key: FORM_VERSION, values: ["latest"] }
              ],
              booleanFilter: [
                { key: IS_TEMPLATE, value: false }
              ]
            ) {
              count
            }
            idVerificationConfig {
              sendIdVerificationAndSubmissionEmail
              requestSubmissionEmailTemplateID
              IdVerificationEmailTemplateID
            }
            requestApprovalConfig {
              autoApprove
              sendApprovalMail
              approvalEmailTemplateID
            }
            dataDiscoveryAndValidationConfig {
              dataSourceConfigs {
                dataSourceId
                actionToBeTaken
                actionToBeTakenBy
              }
            }
            reportSharingAndClosureConfig {
              autoShareReportWithDataSubject
              autoCloseRequest
              sendClosureEmail
              reportSharingEmailTemplateID
              passwordSharingEmailTemplateID
              closureEmailTemplateID
            }
          }
        }
        count
      }
      dsrSetting {
        edges {
          node {
            defaultDsrDatasource {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
      datasources {
        edges {
          node {
            id
            name
            type
            createdBy
            dsrErasureEnabled
            dsrRectificationEnabled
          }
        }
      }
    }
  `
}

export const mapDsrDetailedWorkflowById = (res: any): DetailedWorkflow | null => {
  try {
    const item = res.dsrWorkflowConfig.edges[0].node

    const dsrDefaultDatasourceIds: string[] = res.dsrSetting.edges[0].node.defaultDsrDatasource.edges.map(
      (edge) => edge.node.id
    )
    const datasources = res.datasources.edges.map((edge) => edge.node)

    const dataSourceConfigs: dsrWFDefaultDatasourceType[] = item.dataDiscoveryAndValidationConfig.dataSourceConfigs.map(
      (dsConfig: any) => {
        const matchingDs = datasources.find((d) => d.id === dsConfig.dataSourceId)

        const dsrWfDefaultDatasource: dsrWFDefaultDatasourceType = {
          id: dsConfig.dataSourceId,
          name: matchingDs?.name || '',
          type: matchingDs?.type || '',
          owner: matchingDs?.createdBy || '',
          actionToBeTaken: dsConfig.actionToBeTaken,
          actionToBeTakenBy: dsConfig.actionToBeTakenBy,
          dsrErasureSupported: matchingDs?.dsrErasureEnabled || false,
          dsrRecitificationSupported: matchingDs?.dsrRectificationEnabled || false,
          dsEnabled: !!dsrDefaultDatasourceIds.includes(dsConfig.dataSourceId)
        }

        return dsrWfDefaultDatasource
      }
    )

    const workflow: DetailedWorkflow = {
      workflowAutomationId: item.id,
      workflowAutomationName: item.name,
      requestType: item.requestType,
      currenUserEmailId: item.createdBy,
      updatedAt: item.updatedAt,
      createdAt: item.createdAt,
      createdBy: item.createdBy,
      associatedFormsCount: item.forms.count,
      stepEmailIdVerification: {
        sendAcknowledgement: item.idVerificationConfig.sendIdVerificationAndSubmissionEmail,
        requestSubmittedEmailTemplateId: item.idVerificationConfig.requestSubmissionEmailTemplateID,
        emailVerificationSuccessEmailTemplateId:
          item.idVerificationConfig.IdVerificationEmailTemplateID
      },
      stepRequestApproval: {
        requestApprovalEmailTemplateId: item.requestApprovalConfig.approvalEmailTemplateID,
        approveRequest: item.requestApprovalConfig.autoApprove,
        sendApprovalEmail: item.requestApprovalConfig.sendApprovalMail
      },
      stepDataDiscoveryValidation: {
        dsrDefaultDatasources: dataSourceConfigs
      },
      stepReportGeneration: {
        shareReportWithDataSubject:
          item.reportSharingAndClosureConfig.autoShareReportWithDataSubject,
        automaticallyCloseRequest: item.reportSharingAndClosureConfig.autoCloseRequest,
        sendClosureEmail: item.reportSharingAndClosureConfig.sendClosureEmail,
        shareReportEmailTemplateId: item.reportSharingAndClosureConfig.reportSharingEmailTemplateID,
        reportPasswordEmailTemplateId:
          item.reportSharingAndClosureConfig.passwordSharingEmailTemplateID,
        closureEmailTemplateId: item.reportSharingAndClosureConfig.closureEmailTemplateID
      }
    }

    return workflow
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error in mapDsrWorkflowById', error)
    return null
  }
}

function mapWorkflowDataSourceConfigs(dataSourceConfigs: dsrWFDefaultDatasourceType[]) {
  return dataSourceConfigs
    .map(
      (ds) => `{
      dataSourceId: "${ds.id}",
      actionToBeTaken: ${ds.actionToBeTaken},
      actionToBeTakenBy: ${ds.actionToBeTakenBy}
    }`
    )
    .join(',')
}

export const mutationUpdateWorkflow = (workflowData: Workflow) => {
  const dataSourceConfigs = mapWorkflowDataSourceConfigs(
    workflowData.stepDataDiscoveryValidation.dsrDefaultDatasources
  )
  return gql`
    mutation {
      updateDsrWorkflowConfig(
        workflowConfigId: "${workflowData.workflowAutomationId}"
        clientMutationId: "update-dsr-workflow"
        workflowConfigData: {
          name: "${workflowData.workflowAutomationName}"
          idVerificationConfig: {
            sendIdVerificationAndSubmissionEmail: ${JSON.stringify(
              workflowData.stepEmailIdVerification.sendAcknowledgement
            )}
            ${
              workflowData.stepEmailIdVerification.requestSubmittedEmailTemplateId
                ? `requestSubmissionEmailTemplateID: ${JSON.stringify(
                    workflowData.stepEmailIdVerification.requestSubmittedEmailTemplateId
                  )}`
                : ''
            }
            ${
              workflowData.stepEmailIdVerification.emailVerificationSuccessEmailTemplateId
                ? `IdVerificationEmailTemplateID: ${JSON.stringify(
                    workflowData.stepEmailIdVerification.emailVerificationSuccessEmailTemplateId
                  )}`
                : ''
            }
          }
          requestApprovalConfig: {
            autoApprove: ${JSON.stringify(workflowData.stepRequestApproval.approveRequest)}
            sendApprovalMail: ${JSON.stringify(workflowData.stepRequestApproval.sendApprovalEmail)}
            ${
              workflowData.stepRequestApproval.requestApprovalEmailTemplateId
                ? `approvalEmailTemplateID: ${JSON.stringify(
                    workflowData.stepRequestApproval.requestApprovalEmailTemplateId
                  )}`
                : ''
            }
          }
          dataDiscoveryAndValidationConfig: {
            dataSourceConfigs: [${dataSourceConfigs}]
          }
          reportSharingAndClosureConfig: {
            autoShareReportWithDataSubject: ${JSON.stringify(
              workflowData.stepReportGeneration.shareReportWithDataSubject
            )}
            ${
              workflowData.stepReportGeneration.shareReportEmailTemplateId
                ? `reportSharingEmailTemplateID: ${JSON.stringify(
                    workflowData.stepReportGeneration.shareReportEmailTemplateId
                  )}`
                : ''
            }
            ${
              workflowData.stepReportGeneration.reportPasswordEmailTemplateId
                ? `passwordSharingEmailTemplateID: ${JSON.stringify(
                    workflowData.stepReportGeneration.reportPasswordEmailTemplateId
                  )}`
                : ''
            }
            sendClosureEmail: ${JSON.stringify(workflowData.stepReportGeneration.sendClosureEmail)}
            autoCloseRequest: ${JSON.stringify(
              workflowData.stepReportGeneration.automaticallyCloseRequest
            )}
            ${
              workflowData.stepReportGeneration.closureEmailTemplateId
                ? `closureEmailTemplateID: ${JSON.stringify(
                    workflowData.stepReportGeneration.closureEmailTemplateId
                  )}`
                : ''
            }
          }
        }
      ) {
        clientMutationId
        dsrWorkflowConfigId
      }
    }
  `
}

export const mutationSaveWorkflow = (workflowData: Workflow) => {
  const dataSourceConfigs = mapWorkflowDataSourceConfigs(
    workflowData.stepDataDiscoveryValidation.dsrDefaultDatasources
  )

  return gql`
    mutation {
      createDsrWorkflowConfig(
        clientMutationId: "save-dsr-workflow"
        workflowConfigData: {
          name: ${JSON.stringify(workflowData?.workflowAutomationName)}
          requestType: ${workflowData?.requestType}
          createdBy: ${JSON.stringify(workflowData?.currenUserEmailId)}
          idVerificationConfig: {
            sendIdVerificationAndSubmissionEmail: ${JSON.stringify(
              workflowData.stepEmailIdVerification.sendAcknowledgement
            )}
            ${
              workflowData.stepEmailIdVerification.requestSubmittedEmailTemplateId
                ? `requestSubmissionEmailTemplateID: ${JSON.stringify(
                    workflowData.stepEmailIdVerification.requestSubmittedEmailTemplateId
                  )}`
                : ''
            }
            ${
              workflowData.stepEmailIdVerification.emailVerificationSuccessEmailTemplateId
                ? `IdVerificationEmailTemplateID: ${JSON.stringify(
                    workflowData.stepEmailIdVerification.emailVerificationSuccessEmailTemplateId
                  )}`
                : ''
            }
          }
          requestApprovalConfig: {
            autoApprove: ${JSON.stringify(workflowData.stepRequestApproval.approveRequest)}
            sendApprovalMail: ${JSON.stringify(workflowData.stepRequestApproval.sendApprovalEmail)}
            ${
              workflowData.stepRequestApproval.requestApprovalEmailTemplateId
                ? `approvalEmailTemplateID: ${JSON.stringify(
                    workflowData.stepRequestApproval.requestApprovalEmailTemplateId
                  )}`
                : ''
            }
          }
          dataDiscoveryAndValidationConfig: {
            dataSourceConfigs: [${dataSourceConfigs}]
          }
          reportSharingAndClosureConfig: {
            autoShareReportWithDataSubject: ${JSON.stringify(
              workflowData.stepReportGeneration.shareReportWithDataSubject
            )}
            ${
              workflowData.stepReportGeneration.shareReportEmailTemplateId
                ? `reportSharingEmailTemplateID: ${JSON.stringify(
                    workflowData.stepReportGeneration.shareReportEmailTemplateId
                  )}`
                : ''
            }
            ${
              workflowData.stepReportGeneration.reportPasswordEmailTemplateId
                ? `passwordSharingEmailTemplateID: ${JSON.stringify(
                    workflowData.stepReportGeneration.reportPasswordEmailTemplateId
                  )}`
                : ''
            }
            sendClosureEmail: ${JSON.stringify(workflowData.stepReportGeneration.sendClosureEmail)}
            autoCloseRequest: ${JSON.stringify(
              workflowData.stepReportGeneration.automaticallyCloseRequest
            )}
            ${
              workflowData.stepReportGeneration.closureEmailTemplateId
                ? `closureEmailTemplateID: ${JSON.stringify(
                    workflowData.stepReportGeneration.closureEmailTemplateId
                  )}`
                : ''
            }
          }
        }
      ) {
        clientMutationId
        dsrWorkflowConfigId
      }
    }
  `
}

export const mutationDeleteWorkflow = (workflowId: string) => {
  return gql`
    mutation {
      deleteDsrWorkflowConfig(
        clientMutationId: "delete-dsr-workflow"
        workflowConfigId: "${workflowId}"
      ) {
        clientMutationId
        isDeleted
      }
    }
  `
}
