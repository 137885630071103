export const SPECIAL_ATTRIBUTES = [
  {
    id: '1',
    owner: 'LB',
    name: 'Racial / Ethnic Origin',
    sensitivityLabel: 'High',
    risk: 'HIGH_RISK'
  },
  {
    id: '2',
    owner: 'LB',
    name: 'Political Opinions',
    sensitivityLabel: 'High',
    risk: 'HIGH_RISK'
  },
  {
    id: '3',
    owner: 'LB',
    name: 'Religious / Philosophical Beliefs',
    sensitivityLabel: 'High',
    risk: 'HIGH_RISK'
  },
  {
    id: '4',
    owner: 'LB',
    name: 'Trade Union Membership',
    sensitivityLabel: 'High',
    risk: 'HIGH_RISK'
  },
  {
    id: '5',
    owner: 'LB',
    name: 'Genetic Data',
    sensitivityLabel: 'High',
    risk: 'HIGH_RISK'
  },
  {
    id: '6',
    owner: 'LB',
    name: 'Biometric Data',
    sensitivityLabel: 'High',
    risk: 'HIGH_RISK'
  },
  {
    id: '7',
    owner: 'LB',
    name: 'Health Data',
    sensitivityLabel: 'High',
    risk: 'HIGH_RISK'
  },
  {
    id: '8',
    owner: 'LB',
    name: 'Sexual Orientation / Sexual Life',
    sensitivityLabel: 'High',
    risk: 'HIGH_RISK'
  }
]
