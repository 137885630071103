import { isAirByteDatasource } from './datasourcesList'
import StatusLabel from '../../../features/dataSources/components/StatusWidget/statusLabel'
import TrendTableWidgetStructured, {
  TrendSummaryTypes
} from '../../../components/trendTableWidget/trendTableWidget'
import PrintPageHeader from '../../components/header'
import {
  DatasourceAtrributeDelta,
  stats,
  fetchEntitiesWidgetSummary,
  fetchPolicyViolationPerDS
} from '../../printSlice'
import { RootState } from '../../../rootReducer'
import { getDataSourceImage } from '../../../utils/dataSourceUtil'
import { DATA_SOURCE_TYPES, STRUCTURED_DATASOURCES } from '../../../constants'
import { EntitiesSummaryWidget } from '../../../features/entities/entitiesSlice'
import ClassificationsGroupedWidget from '../../../features/classifications/classificationsGroupedWidget'
import AttributesDashboardWidget from '../../../features/attributes/attributesDashboardWidget'
import SensitiveTablesWidget from '../../../features/dataSources/sensitiveTablesWidget'
import { getDate4WeeksBefore, getYesterday } from '../../../utils/timeUtil'
import {
  getDeltaReportAggregation,
  getDeltaReportAttributeInstanceAggregation,
  getDeltaReportFormattedDate,
  percentageDeltaCalculator
} from '../deltaReportUtil'
import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { ShieldWarning, User } from 'phosphor-react'
import { FormattedNumber } from 'react-intl'
import { Label } from 'semantic-ui-react'
type IProps = {
  fetchEntitiesWidgetSummary: (datasourceId: string) => void
  datasourceType: DATA_SOURCE_TYPES
  files?: {
    [datasourceId: string]: stats[]
  }
  tables?: {
    [datasourceId: string]: stats[]
  }
  columns?: {
    [datasourceId: string]: stats[]
  }
  highSensitivityAttributes?: DatasourceAtrributeDelta
  totalAttributeInstances?: DatasourceAtrributeDelta
  datasourceId?: string
  datasourceName?: string
  datasourceGroup: string
  datasourceOwner?: string
  risky?: boolean
  status?: string
  entityWidgetData?: {
    [datasourceId: string]: EntitiesSummaryWidget
  }
  fetchPolicyViolationPerDS: (datasourceId: string) => void
  policyViolation?: { [datasourceId: string]: number }
}
const DatasourceOverview = ({
  datasourceType,
  datasourceId = '',
  files,
  risky,
  highSensitivityAttributes,
  totalAttributeInstances,
  datasourceGroup,
  datasourceName,
  datasourceOwner = '-',
  status,
  fetchEntitiesWidgetSummary,
  entityWidgetData,
  tables,
  columns,
  fetchPolicyViolationPerDS,
  policyViolation
}: IProps) => {
  useEffect(() => {
    if (datasourceId) {
      fetchEntitiesWidgetSummary(datasourceId)
      fetchPolicyViolationPerDS(datasourceId)
    }
  }, [datasourceId, fetchEntitiesWidgetSummary, fetchPolicyViolationPerDS])
  const agreegatedAttrStats = getDeltaReportAttributeInstanceAggregation(
    totalAttributeInstances?.[datasourceId] || []
  )
  const agreegatedHighSensitiveAttrStats = getDeltaReportAttributeInstanceAggregation(
    highSensitivityAttributes?.[datasourceId] || []
  )
  const agreegatedFileStats = getDeltaReportAggregation(files?.[datasourceId] || [])
  const agreegatedTableStats = getDeltaReportAggregation(tables?.[datasourceId] || [])
  const agreegatedColumnsStats = getDeltaReportAggregation(columns?.[datasourceId] || [])
  const showDeleted = !isAirByteDatasource(datasourceType)

  return (
    <div
      id={'datasource-overview' + datasourceId + datasourceGroup}
      className="delta-print-page delta-page-overview"
    >
      <PrintPageHeader text={'Monitored Data Sources - ' + datasourceGroup} />
      <div className="widget-title overview-title">
        <span className="bold-text">4 Weeks Trend</span>(
        {getDeltaReportFormattedDate(getDate4WeeksBefore())} -{' '}
        {getDeltaReportFormattedDate(getYesterday())})
      </div>
      <div className="datasource-overview">
        <header>
          <div className="truncate ds-name-files xs-mt-24 xs-mb-24">
            <img
              src={getDataSourceImage(datasourceType, '../../../assets/images/dataSourceTypes/')}
              className="xs-mr-12"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null // prevents looping
                currentTarget.src = getDataSourceImage(
                  datasourceType,
                  '../../../assets/images/dataSourceTypes/',
                  true
                )
              }}
            />
            {datasourceName && <p className="title truncate xs-m-0">{datasourceName}</p>}
            {risky && <Label className="ds-at-risk xs-px-8 xs-py-4 xs-ml-20">At Risk</Label>}
          </div>
          <StatusLabel status={status} dataSourceType={datasourceType} />
        </header>
        <div className="xs-mb-24">
          <span>Data source owner: </span>
          <span className="bold">{datasourceOwner}</span>
        </div>
        <TrendTableWidgetStructured
          headers={[
            ...(datasourceGroup === 'Unstructured'
              ? [
                  {
                    type: TrendSummaryTypes.FILES_SENSITIVE_DATA,
                    total: agreegatedFileStats.total
                  }
                ]
              : []),
            ...(datasourceGroup === 'Unstructured'
              ? [
                  {
                    type: TrendSummaryTypes.SENSITIVE_DATA_TOTAL,
                    total: agreegatedAttrStats.total
                  }
                ]
              : []),
            ...(datasourceGroup === 'Structured'
              ? [
                  {
                    type: TrendSummaryTypes.TABLES_SENSITIVE_DATA,
                    total: agreegatedTableStats.total
                  }
                ]
              : []),
            ...(datasourceGroup === 'Structured'
              ? [
                  {
                    type: TrendSummaryTypes.COLUMNS_SENSITIVE_DATA,
                    total: agreegatedColumnsStats.total
                  }
                ]
              : []),
            {
              type: TrendSummaryTypes.SENSITIVE_DATA_HIGH,
              total: agreegatedHighSensitiveAttrStats.total
            }
          ]}
          addedData={{
            ...(datasourceGroup === 'Unstructured'
              ? {
                  [TrendSummaryTypes.FILES_SENSITIVE_DATA]: {
                    added: agreegatedFileStats.added,
                    percentageChange: percentageDeltaCalculator(
                      agreegatedFileStats.startAdded,
                      agreegatedFileStats.added
                    )
                  }
                }
              : {}),
            ...(datasourceGroup === 'Unstructured'
              ? {
                  [TrendSummaryTypes.SENSITIVE_DATA_TOTAL]: {
                    added: agreegatedAttrStats.added,
                    percentageChange: percentageDeltaCalculator(
                      agreegatedAttrStats.startAdded,
                      agreegatedAttrStats.added
                    )
                  }
                }
              : {}),
            ...(datasourceGroup === 'Structured'
              ? {
                  [TrendSummaryTypes.TABLES_SENSITIVE_DATA]: {
                    added: agreegatedTableStats.added,
                    percentageChange: percentageDeltaCalculator(
                      agreegatedTableStats.startAdded,
                      agreegatedTableStats.added
                    )
                  }
                }
              : {}),
            ...(datasourceGroup === 'Structured'
              ? {
                  [TrendSummaryTypes.COLUMNS_SENSITIVE_DATA]: {
                    added: agreegatedColumnsStats.added,
                    percentageChange: percentageDeltaCalculator(
                      agreegatedColumnsStats.startAdded,
                      agreegatedColumnsStats.added
                    )
                  }
                }
              : {}),
            [TrendSummaryTypes.SENSITIVE_DATA_HIGH]: {
              added: agreegatedHighSensitiveAttrStats.added,
              percentageChange: percentageDeltaCalculator(
                agreegatedHighSensitiveAttrStats.startAdded,
                agreegatedHighSensitiveAttrStats.added
              )
            }
          }}
          deletedData={
            showDeleted
              ? {
                  ...(datasourceGroup === 'Unstructured'
                    ? {
                        [TrendSummaryTypes.FILES_SENSITIVE_DATA]: {
                          deleted: agreegatedFileStats.deleted,
                          percentageChange: percentageDeltaCalculator(
                            agreegatedFileStats.startDeleted,
                            agreegatedFileStats.deleted
                          )
                        }
                      }
                    : {}),
                  ...(datasourceGroup === 'Unstructured'
                    ? {
                        [TrendSummaryTypes.SENSITIVE_DATA_TOTAL]: {
                          deleted: agreegatedAttrStats.deleted,
                          percentageChange: percentageDeltaCalculator(
                            agreegatedAttrStats.startDeleted,
                            agreegatedAttrStats.deleted
                          )
                        }
                      }
                    : {}),
                  ...(datasourceGroup === 'Structured'
                    ? {
                        [TrendSummaryTypes.TABLES_SENSITIVE_DATA]: {
                          deleted: agreegatedTableStats.deleted,
                          percentageChange: percentageDeltaCalculator(
                            agreegatedTableStats.startDeleted,
                            agreegatedTableStats.deleted
                          )
                        }
                      }
                    : {}),
                  ...(datasourceGroup === 'Structured'
                    ? {
                        [TrendSummaryTypes.COLUMNS_SENSITIVE_DATA]: {
                          deleted: agreegatedColumnsStats.deleted,
                          percentageChange: percentageDeltaCalculator(
                            agreegatedColumnsStats.startDeleted,
                            agreegatedColumnsStats.deleted
                          )
                        }
                      }
                    : {}),
                  [TrendSummaryTypes.SENSITIVE_DATA_HIGH]: {
                    deleted: agreegatedHighSensitiveAttrStats.deleted,
                    percentageChange: percentageDeltaCalculator(
                      agreegatedHighSensitiveAttrStats.startDeleted,
                      agreegatedHighSensitiveAttrStats.deleted
                    )
                  }
                }
              : {}
          }
        />
        <div className="entites-overview">
          <div className="sections section-1">
            <p className="xs-mb-8">
              <User size={18} weight="light" />
            </p>
            <p className="xs-mb-4 text-count bold">
              <FormattedNumber
                value={entityWidgetData?.[datasourceId]?.totalRisky || 0}
                notation="compact"
              />
            </p>
            <p className="text-title">Entities at Risk</p>
          </div>
          <div className="sections">
            <p className="xs-mb-8">
              <ShieldWarning size={18} weight="light" />
            </p>
            <p className="xs-mb-4 text-count bold">
              <FormattedNumber value={policyViolation?.[datasourceId] || 0} notation="compact" />
            </p>
            <p className="text-title">Rule Set Violated</p>
          </div>
        </div>
        <div className="top-5-widgets">
          <div className="attribute-high dashboard-container xs-px-24 xs-pt-24 xs-pb-40">
            <AttributesDashboardWidget
              isHighSensitive={true}
              dataSourceId={datasourceId}
              legendPosition="right"
              title="Sensitive Data Count"
              help="(Top 5)"
              showListLegend={false}
              isAnimated={false}
              showAllAttributes={true}
              isClickable={false}
            />
          </div>
          <div className="attribute dashboard-container xs-px-24 xs-pt-24 xs-pb-40">
            <AttributesDashboardWidget
              dataSourceId={datasourceId}
              legendPosition="right"
              title="Sensitive Data Count"
              help="(Top 5)"
              showListLegend={false}
              isAnimated={false}
              isClickable={false}
            />
          </div>
          {STRUCTURED_DATASOURCES.find((structuredType) => structuredType === datasourceType) ? (
            <div className="attribute-high dashboard-container xs-px-24 xs-pt-24 xs-pb-40">
              <SensitiveTablesWidget dataSourceId={datasourceId} isClickable={false} />
            </div>
          ) : (
            <div className="classification dashboard-container xs-px-24 xs-pt-24 xs-pb-40 xs-mr">
              <ClassificationsGroupedWidget
                dataSourceId={datasourceId}
                legendPosition="right"
                isAnimated={false}
                isClickable={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  highSensitivityAttributes: state.print.datasourceHighSensitivityAttributeInstanceStats,
  totalAttributeInstances: state.print.datasourceTotalAttributeInstanceStats,
  files: state.print.deltaFilesPerDatasource,
  entityWidgetData: state.print.datasourceEntitySummary,
  tables: state.print.deltaTablesPerDatasource,
  columns: state.print.deltaColumnsPerDatasource,
  policyViolation: state.print.datasourcePolicyViolation
})

const mapDispatchToProps = {
  fetchEntitiesWidgetSummary,
  fetchPolicyViolationPerDS
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasourceOverview)
