import { gql } from 'graphql-request'

export const queryBreadcrumbsDatabase = (databaseId: string): string => gql`
  { databases(id: "${databaseId}") {  edges {  node { id, name } } } }
`

// TODO: add type for graphql response
export const mapQueryBreadcrumbsDatabase = (
  raw: any
): { databaseId: string; databaseName: string } => ({
  databaseId: raw.databases.edges[0].node.id,
  databaseName: raw.databases.edges[0].node.name
})

export const queryBreadcrumbsTable = (tableId: string): string => gql`
  { tables(id: "${tableId}") {  edges {  node { id, name } } } }
`

// TODO: add type for graphql response
export const mapQueryBreadcrumbsTable = (raw: any): { tableId: string; tableName: string } => ({
  tableId: raw.tables.edges[0].node.id,
  tableName: raw.tables.edges[0].node.name
})
